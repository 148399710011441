import { createApiEffect } from '@/features/api/common/create-api-effect'
import {
  GetLessonType,
  LessonUpdateAssigmentsType,
} from '@/pages/learning/learning-lessons/create/model/types'
import { UpdateCourseFxParams } from '@/features/api/learning/types'

export const getLessonByIdFx = createApiEffect<string, GetLessonType>({
  requestMapper: (id) => ({
    method: 'GET',
    url: `/api/lesson-app/lessons/${id}/`,
  }),
})

export const updateLessonBulkFx = createApiEffect<
  { body: LessonUpdateAssigmentsType; id: string },
  UpdateCourseFxParams
>({
  requestMapper: ({ body, id }) => ({
    method: 'PUT',
    url: `/api/lesson-app/lessons/${id}/`,
    body,
  }),
})
