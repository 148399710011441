




























import Vue from 'vue'
import {
  $inputs,
  $scale,
  replaceInput,
  removeInput,
} from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/moving-images-on-image-answer-form.model'
import { DroppableInput } from '@/pages/common/parts/tasks/types'
import { ResizableElementSizes } from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/types'
import ImageContextMenu from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/ImageContextMenu.vue'
import ResizableElement from './resizable/ResizableElement.vue'

export default Vue.extend({
  name: `DraggableInputs`,
  components: {
    ImageContextMenu,
    ResizableElement,
  },
  effector: {
    $inputs,
    $scale,
  },
  data: () => ({
    contextKey: 0,
    contextCoords: {
      x: 0,
      y: 0,
    },
  }),
  computed: {
    currentContextInput(): DroppableInput | undefined {
      return this.$inputs && this.$inputs.find((input) => input.id === this.contextKey)
    },
  },
  methods: {
    removeInput,
    changeContext(key: keyof DroppableInput, value: DroppableInput[keyof DroppableInput]) {
      const contextElement = this.currentContextInput
      if (contextElement) {
        replaceInput({
          ...contextElement,
          [key]: value,
        })
      }
    },
    closeContext() {
      this.contextKey = 0
    },
    openContext(e: MouseEvent, key: number) {
      this.contextCoords.x = e.x
      this.contextCoords.y = e.y
      this.contextKey = key
    },
    sizesToResizer(input: DroppableInput) {
      return {
        ...input.size,
        ...{
          left: input.position.x,
          top: input.position.y,
        },
      }
    },
    updateSizes(input: DroppableInput, sizes: ResizableElementSizes) {
      replaceInput({
        ...input,
        position: {
          x: sizes.left,
          y: sizes.top,
        },
        size: {
          width: sizes.width,
          height: sizes.height,
        },
      })
    },
  },
  destroyed() {
    window.removeEventListener('mousedown', this.closeContext)
    document.getElementById('app')?.removeEventListener('scroll', this.closeContext)
  },
  mounted() {
    window.addEventListener('mousedown', this.closeContext)
    document.getElementById('app')?.addEventListener('scroll', this.closeContext)
  },
})
