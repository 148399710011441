import { createEvent, createStore, guard } from 'effector-root'
import { resetFields } from '@/pages/users/groups/create/model/form-fileds.model'
import { getGroupIdFx } from '@/features/api/groups/get-group-dy-id'

export const changeGroupId = createEvent<string | never>()

export const $editedGroupId = createStore<string>('')
  .on(changeGroupId, (state, payload) => payload || state)
  .reset(resetFields)

guard({
  source: changeGroupId,
  filter: (payload) => !!payload,
  target: getGroupIdFx,
})
