import { createApiEffect } from '@/features/api/common/create-api-effect'
import { UpdateCourseFxParams, Courses } from '@/features/api/learning/types'

export const editCoursesFx = createApiEffect<UpdateCourseFxParams, Courses>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: `/api/course-app/courses/${body.id}/`,
    body,
  }),
})
