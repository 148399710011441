import {
  attach,
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector-root'
import { getThemesTreeListFx } from '@/features/api/subject/get-themes-tree-list'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import {
  $selectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { formatData } from '@/features/lib'
import { debounce } from 'patronum'

export const themesDropdownModule = createFilter()

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

export const $themes = createStore<DropdownItem[]>([])

export const isPrerequisiteChanged = createEvent<boolean>()
export const $isPrerequisite = restore(isPrerequisiteChanged, false)

const getThemesTreeList = attach({
  effect: getThemesTreeListFx,
})

forward({
  from: getThemesTreeList.doneData.map(({ body }) => formatData(body)),
  to: $themes,
})

export const setThemeToSelect = createEvent<DropdownItem[]>()
export const $themesToSelect = restore(setThemeToSelect, []).reset(componentDestroyed)

export const setSelectedThemes = createEvent<DropdownItem[]>()
export const resetSelectedThemes = createEvent()
export const $selectedThemes = restore<DropdownItem[]>(setSelectedThemes, []).reset(
  resetSelectedThemes
)
export const deleteTheme = createEvent<string>()

forward({
  from: deleteTheme,
  to: themesDropdownModule.methods.resetItem,
})

sample({
  source: $selectedThemes,
  clock: deleteTheme,
  fn: (list, id: string) => list.filter((el: any) => el.name !== id),
  target: $selectedThemes,
})

sample({
  source: $selectedThemes,
  clock: deleteTheme,
  fn: (selected) => (selected.length > 0 ? selected[0] : null),
  target: themesDropdownModule.methods.itemChanged,
})

const debounced = debounce({
  source: $selectedSubject,
  timeout: 150,
})

guard({
  clock: debounced,
  source: $selectedSubject,
  filter: combine(
    $isPrerequisite,
    $isComponentMounted,
    $selectedSubject,
    (isPrerequisite, isMounted, selectedSubject) => {
      return isPrerequisite && isMounted && !!selectedSubject
    }
  ),
  target: [
    getThemesTreeList.prepend((subject: DropdownItem | null) => ({
      subject: +subject?.name! || undefined,
      is_not_prerequisite: true,
    })),
  ],
})

guard({
  source: $themesToSelect,
  clock: getThemesTreeList.doneData,
  filter: (themes) => !!themes.length,
  target: [
    setSelectedThemes.prepend((themes: DropdownItem[]) =>
      themes.map((theme) => ({
        name: `${theme.id}`,
        title: theme.name,
      }))
    ),
    setThemeToSelect.prepend(() => []),
  ],
})

forward({
  from: subjectsDropdownModel.methods.itemChanged,
  to: [resetSelectedThemes, themesDropdownModule.methods.resetItem],
})
