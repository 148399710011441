import { coursesNameDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/courses-name-dropdown-filter/courses-filter-dropdown.model'
import { courseTypeDropdownModule } from '@/pages/common/dropdowns/courses/course-type-filter/coursesType.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/subject-filter-dropdown.model'
import { classesDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/classes-filter-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { statusDropdownModule } from '@/pages/common/dropdowns/courses/status-dropdown/status-dropdown.model'

export const dropdownModules = {
  coursesNameDropdownModel,
  courseTypeDropdownModule,
  subjectsDropdownModel,
  classesDropdownModel,
  groupsDropdownModel,
  statusDropdownModule,
}
