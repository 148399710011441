import { createApiEffect } from '@/features/api/common/create-api-effect'
import { Banner } from '@/features/api/content/types'

export const createBannerFx = createApiEffect<Partial<Banner>, Banner>({
  requestMapper: (params) => ({
    method: 'POST',
    url: '/api/content-app/banner/',
    body: params,
  }),
})
