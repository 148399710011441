<template>
  <div class="wrap">
    <div class='audio-file-input' :class="{ 'hide-input': image && image.length }">
      <div ref="dropContainer" class="drop-container">
        <span>{{ placeholder }}&nbsp;
          <span ref="triggerText" class='trigger'>файлов</span>
        </span>

      </div>
      <input
        ref="fileInput"
        class="file-input"
        type="file"
        accept="image/*"
      >
    </div>
    <div v-if="image && image.length" class="image-wrap">
      <div
        class="image-essence"
        :style="{ backgroundImage: `url(${image})` }"
      >
        <Icon
          class="close-icon"
          type="close"
          size="10"
          @click="remove"
        />
      </div>
      <div class="edit" @click="handleClick">
        <Icon
          type="spinner-arrow"
          size="24"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'ImageFileInput',
  components: {
    Icon,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Перетащите аудиофайл в поле или выберите из',
    },
    change: {
      type: Function,
    },
    remove: {
      type: Function,
    },
    image: {
      type: String,
      default: '',
    },
  },
  methods: {
    preventDefault(event) {
      event.preventDefault()
    },
    dropHandle(event) {
      this.change(event.dataTransfer.files)
      event.preventDefault()
    },
    inputHandle(event) {
      const { fileInput } = this.$refs
      this.change(fileInput.files)
      event.preventDefault()
    },
    handleClick(event) {
      event.preventDefault()
      const { fileInput } = this.$refs
      fileInput.click()
    },
  },
  mounted() {
    const { dropContainer, fileInput, triggerText } = this.$refs
    dropContainer.addEventListener('dragover', this.preventDefault)
    dropContainer.addEventListener('dragenter', this.preventDefault)
    dropContainer.addEventListener('drop', this.dropHandle)
    fileInput.addEventListener('change', this.inputHandle)
    triggerText.addEventListener('click', this.handleClick)
  },
  beforeDestroy() {
    const { dropContainer, fileInput, triggerText } = this.$refs
    dropContainer.removeEventListener('dragover', this.preventDefault)
    dropContainer.removeEventListener('dragenter', this.preventDefault)
    dropContainer.removeEventListener('drop', this.dropHandle)
    fileInput.removeEventListener('change', this.inputHandle)
    triggerText.removeEventListener('click', this.handleClick)
  },
})
</script>

<style scoped>
.audio-file-input {
  display: flex;
  flex-direction: column;
}
.drop-container {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--c-grey-5);
  border: 1px dashed var(--c-grey-3);
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 17px;
  color: var(--base-text-secondary);
  padding: 15px;
}
.trigger {
  cursor: pointer;
  @mixin underline-text;
  color: var(--base-text-primary);
}
.file-input {
  visibility: hidden;
  height: 10px;
}

.close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 6;
  fill: #fff;
}

.image-essence {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 5;
  }
}
.wrap {
  position: relative;
}
.hide-input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0.01;
}
.image-wrap {
  display: flex;
}
.edit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background: #fff;
  border: 1px dashed #bcc2ca;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
