

















import Vue from 'vue'

export default Vue.extend({
  name: 'MultipleChoiceOneAnswer',
  props: {
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    correctAnswer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    currentAnswer() {
      return this.answerType === 'student' ? this.answer : this.correctAnswer
    },
    answerData() {
      try {
        if (this.answerType === 'student') {
          const arr: { title: string; bold: boolean }[] = this.question.map(
            (qs: { title: string; number: number }) =>
              this.answer.findIndex((el: number) => el === qs.number) > -1
                ? { title: qs.title, bold: true }
                : { title: qs.title, bold: false }
          )
          return arr
        }
        if (this.answerType === 'correct') {
          const arr: { title: string; bold: boolean }[] = this.question.map(
            (qs: { title: string; number: number }) =>
              this.correctAnswer.findIndex((el: number) => el === qs.number) > -1
                ? { title: qs.title, bold: true }
                : { title: qs.title, bold: false }
          )
          return arr
        }
        return this.answerType === 'student' ? this.answer : this.correctAnswer
      } catch (e) {
        console.error(e)
        return []
      }
    },
  },
})
