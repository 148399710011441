import { createEvent, createStore, combine } from 'effector-root'
import { spread } from 'patronum'
import { getQuestionByIdFx } from '@/features/api/content/faq/get-question-by-id'

export const clearFields = createEvent<void>()

export const $question = createStore<string>('').reset(clearFields)

export const $answer = createStore<string>('').reset(clearFields)

export const changeFieldValue = createEvent<{ type: 'question' | 'answer'; value: string }>()

spread({
  source: changeFieldValue.map((data) => {
    const obj = {}
    obj[data.type] = data.value
    return obj
  }),
  targets: {
    question: $question,
    answer: $answer,
  },
})

spread({
  source: getQuestionByIdFx.doneData.map((res) => ({
    ...res.body,
  })),
  targets: {
    question: $question,
    answer: $answer,
  },
})

export const $disabledSaveButtons = combine(
  $question,
  $answer,
  (question, answer) => !question || !answer
)

export const $createQuestionsPageForm = combine($question, $answer, (question, answer) => ({
  question,
  answer,
}))
