






























































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import {
  studentPageMounted,
  $isLoading,
  requestDeleteStudents,
  $refreshPage,
} from '@/pages/users/students/list/model/users-students-page.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { loadRequestDeleteModal } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { $permissions } from '@/features/session'
import {
  RefsType,
  HttpOptionsType,
  DisplayContextMenuPayload,
  CommonInteractedItemParams,
} from '@/pages/common/types'
import { navigatePush } from '@/features/navigation/navigationMethods'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { TableHeaderItem } from '@/pages/common/parts/table-header/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import { StudentsTableFields, searchFieldsData } from '@/pages/users/students/list/model/constants'
import StudentsHeader from '@/pages/users/students/list/parts/StudentsHeader.vue'
import TooltipFilter from '@/pages/users/students/list/parts/TooltipFilter.vue'
import {
  studentsFilters,
  studentsPageParams,
  taskLoader,
} from '@/pages/users/students/list/model/users-students-filters.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import {
  toggleVisibility,
  $visibility,
} from '@/pages/users/students/list/model/tooltip-filter.model'
import { axiosClient } from '@/lib/request'
import { getActionsDisplayConditions } from '@/pages/common'
import { getStudentsActions } from '@/pages/users/students/list/constants'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'StudentsPage',
  components: {
    Vuetable,
    VuetablePagination,
    StudentsHeader,
    TableHeader,
    GeneralFilter,
    TooltipFilter,
    TooltipCell,
    ContextMenu,
    ActionsButton,
    NoDataContent,
    ConfirmDeleteModal,
    RequestDeleteModal,
    LoaderBig,
  },
  effector: {
    $visibility,
    $isLoading,
    $refreshPage,
    $filterParams: studentsFilters.store.$filterParams,
    $pageParams: studentsPageParams.store.$pageParams,
    $treeView: studentsPageParams.store.treeView,
    $currentPage: studentsPageParams.store.currentPage,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      searchFields: searchFieldsData,
      fields: StudentsTableFields,
      total: 0,
      selectedRows: [] as number[],
      showDeleteModalType: 'students',
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/user-app/student/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length && !this.$treeView) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): TableHeaderItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getStudentsActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $refreshPage: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.removeSelection()
      },
    },
  },
  methods: {
    changeFilter: studentsFilters.methods.changeFilter,
    resetFilters: studentsFilters.methods.resetFilters,
    applyFilters: studentsFilters.methods.applyFilters,
    toggleTreeView: studentsPageParams.methods.toggleTreeView,
    changePage: studentsPageParams.methods.changePage,
    queryToParams: studentsPageParams.methods.queryToParams,
    toggleVisibility,
    exportTable() {
      taskLoader.effect.exportFx(null)
      taskLoader.methods.setTaskLoading(true)
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      return axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      toggleVisibility(false)
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onRemoveStudents() {
      const payload = this.selectedIds
      this.showDeleteModalType = 'students'
      this.$permissions!.users_student_students.can_edit
        ? loadConfirmDeleteModal(payload)
        : loadRequestDeleteModal(payload)
    },
    onEditStudent() {
      const payload = this.selectedIds[0]
      navigatePush({
        name: 'edit-student',
        params: { id: `${payload}` },
      })
    },
    async handleConfirmDelete(ids: number[], type: string) {
      if (type === 'students') {
        await requestDeleteStudents({ student_ids: ids })
        this.$refs.vuetable.reload()
        this.removeSelection()
      }
    },
    async sendRequestDeleteLesson(comment: string, ids: number[]) {
      await requestDeleteStudents({ student_ids: ids, ticket_comment: comment })
      this.$refs.vuetable.reload()
      this.removeSelection()
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    headerActionsButtonClick(event: MouseEvent) {
      this.handleActionsClick({
        data: { id: this.selectedIds[0] },
        event,
        type: 'header-actions',
      })
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: { id: number }) {
      this.interactedItemId = data.id
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
  },
  mounted() {
    studentPageMounted()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
