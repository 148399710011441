import { render, staticRenderFns } from "./EditStudentFooter.vue?vue&type=template&id=17eef27e&scoped=true&"
import script from "./EditStudentFooter.vue?vue&type=script&lang=ts&"
export * from "./EditStudentFooter.vue?vue&type=script&lang=ts&"
import style0 from "./EditStudentFooter.vue?vue&type=style&index=0&id=17eef27e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17eef27e",
  null
  
)

export default component.exports