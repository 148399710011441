import { createEvent, forward, attach, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { debounce } from 'patronum'
import { TestList } from '@/features/api/test/types'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { getTestsFiltersFx } from '@/features/api/test/get-tests-filters'

const getTests = attach({
  effect: getTestsFiltersFx,
})

export const testsDropdownModel = createDropdownModel<TestList>(getTests)

export const loadTests = createEvent<void>()

export const searchTests = createEvent<void>()

sample({
  clock: loadTests,
  source: {
    $nextPage: testsDropdownModel.store.$nextPage,
    search: testsDropdownModel.store.$searchString,
    subject: subjectsDropdownModel.store.$item,
    study_year: classesDropdownModule.store.$item,
  },
  fn: (params): GetListQueryParams => {
    return {
      page: params.$nextPage,
      ...(params.search && { search: params.search }),
      ...(params.subject?.name && { subject: Number(params.subject?.name) }),
      ...(params.study_year?.name && { study_year: Number(params.study_year?.name) }),
    }
  },
  target: getTests,
})

sample({
  clock: searchTests,
  source: {
    search: testsDropdownModel.store.$searchString,
    subject: subjectsDropdownModel.store.$item,
    study_year: classesDropdownModule.store.$item,
  },
  fn: (params): GetListQueryParams => {
    return {
      page: 1,
      ...(params.search && { search: params.search }),
      ...(params.subject?.name && { subjects: params.subject?.name }),
      ...(params.study_year?.name && { study_year: Number(params.study_year?.name) }),
    }
  },
  target: getTests,
})

const debounced = debounce({
  source: testsDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchTests,
})

forward({
  from: [
    testsDropdownModel.methods.canLoadNextPage,
    subjectsDropdownModel.store.$item,
    classesDropdownModule.store.$item,
  ],
  to: loadTests,
})

sample({
  clock: getTests.doneData,
  source: {
    items: testsDropdownModel.store.$items,
    $nextPage: testsDropdownModel.store.$nextPage,
    search: testsDropdownModel.store.$searchString,
  },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((field) => ({
      name: `${field.id}`,
      title: `(${field.id}) ${field.name}`,
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: testsDropdownModel.store.$items,
})
