import { createApiEffect } from '@/features/api/common/create-api-effect'
import IAddFileError from '@/pages/results/certificates-generator/create-certificates/model/interfaces/IAddFileError'
import IDeleteResultParams from '@/features/api/results/results-courses-students/types/IDeleteResultParams'

export const deleteResultFx = createApiEffect<
  IDeleteResultParams,
  Blob,
  { row_errors: IAddFileError[] }
>({
  requestMapper: (params) => ({
    method: 'DELETE',
    url: `/api/results-app/results/courses/${params.course}/${params.lesson}/${params.student}/delete/`,
  }),
})
