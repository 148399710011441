import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteStudents } from '@/pages/users/students/list/model/types'

export const requestDeleteStudentsFx = createApiEffect<RequestDeleteStudents, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/user-app/student/list/bulk-delete/',
    body,
  }),
})
