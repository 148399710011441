import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import DifficultyDropdown from '@/pages/common/dropdowns/bank/test-tasks/difficulty-dropdown/DifficultyDropdown.vue'
import ThemesDropdown from '@/pages/common/dropdowns/themes-tree/ThemesDropdown.vue'
import StatusDropdown from '@/pages/common/dropdowns/bank/test-tasks/status-dropdown/StatusDropdown.vue'
import TypeDropdown from '@/pages/common/dropdowns/bank/test-tasks/type-dropdown/TypeDropdown.vue'
import LanguageDropdown from '@/pages/common/dropdowns/bank/test-tasks/language-dropdown/LanguageDropdown.vue'
import LabelsDropdown from '@/pages/common/dropdowns/labels/LabelsDropdown.vue'

export const dropdownComponents = {
  ClassesDropdown,
  SubjectsDropdown,
  DifficultyDropdown,
  ThemesDropdown,
  StatusDropdown,
  TypeDropdown,
  LanguageDropdown,
  LabelsDropdown,
}
