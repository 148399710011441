import { TableField } from '@/pages/applications/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const coursesLearningDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: '№',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
    formatter(id: number) {
      return id || '-'
    },
  },
  {
    name: 'name',
    sortField: 'name',
    title: 'Название',
    width: '140px',
  },
  {
    name: 'difficulty',
    sortField: 'difficulty',
    title: 'Сложность',
    width: '140px',
  },
  {
    name: 'students_type',
    sortField: 'students_type',
    title: 'Для кого',
    width: '200px',
  },
  {
    name: 'subjects',
    sortField: 'subjects',
    title: 'Предмет',
    width: '140px',
  },
  {
    name: 'with_ticket',
    sortField: 'with_ticket',
    title: 'Тип курса',
    width: '140px',
    formatter(with_ticket) {
      return with_ticket ? 'С заявкой' : 'Назначаемый'
    },
  },
  {
    name: 'task_check_type',
    sortField: 'task_check_type',
    title: 'Тип проверки заданий',
    width: '200px',
    formatter(data) {
      return data ? 'Автоматизированный' : 'С проверкой'
    },
  },
  {
    name: 'classes',
    sortField: 'classes',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Класс',
    width: '100px',
  },
  {
    name: 'lessons_count',
    sortField: 'lessons_count',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Кол-во уроков',
    width: '160px',
    formatter(count: number) {
      return count || '-'
    },
  },
  {
    name: 'groups',
    sortField: 'groups',
    dataClass: 'groups',
    title: 'Группы',
    width: '300px',
  },
  {
    name: 'is_tickets_acceptable',
    sortField: 'is_tickets_acceptable',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Прием заявок',
    width: '200px',
    formatter(is_tickets_acceptable) {
      return is_tickets_acceptable ? 'открыт' : '-'
    },
  },
  {
    name: 'tickets_count',
    sortField: 'tickets_count',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Кол-во заявок',
    width: '200px',
    formatter(tickets_count: number) {
      return tickets_count || '-'
    },
  },
  {
    name: 'students_count',
    sortField: 'students_count',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Кол-во учеников',
    width: '200px',
    formatter(students_count: number) {
      return students_count || '-'
    },
  },
  {
    name: 'commentary',
    sortField: 'commentary',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Комментарий CMS',
    width: '200px',
    formatter(commentary) {
      return commentary || '-'
    },
  },
  {
    name: 'tickets_check_type',
    sortField: 'tickets_check_type',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Автомат. принятие заявок',
    width: '230px',
    formatter(tickets_check_type) {
      return tickets_check_type ? 'да' : '-'
    },
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '130px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    width: '130px',
    formatter: (created_by) => {
      return created_by && created_by.first_name && created_by.last_name
        ? `${created_by.first_name} ${created_by.last_name}`
        : '–'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Обновлено',
    width: '130px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Посл. изм',
    width: '130px',
    formatter: (updated_by) => {
      return updated_by && updated_by.first_name && updated_by.last_name
        ? `${updated_by.first_name} ${updated_by.last_name}`
        : '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '50px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'name', title: 'Название' },
  { name: 'subject', title: 'Предмет' },
  { name: 'description', title: 'Описание' },
  { name: 'created_by', title: 'Создатель' },
]

export const mapApplicationsCoursesType = [
  {
    name: 'Показать все',
    title: 'Показать все',
  },
  {
    name: 'Подача заявок открыта',
    title: 'Подача заявок открыта',
  },
  {
    name: 'Подача заявок закрыта',
    title: 'Подача заявок закрыта',
  },
]

export const mapCoursesType = [
  {
    name: 'С заявкой',
    title: 'С заявкой',
  },
  {
    name: 'Назначаемый',
    title: 'Назначаемый',
  },
]

export const mapCoursesTypeFilter = [
  {
    name: 'Показать все',
    title: 'Показать все',
  },
  ...mapCoursesType,
]

export const mapCourseTaskCheckType = [
  {
    name: 'Автоматизированный',
    title: 'Автоматизированный',
  },
  {
    name: 'С проверкой',
    title: 'С проверкой',
  },
]
export const mapCourseTaskCheckTypeFilter = [
  {
    name: 'Показать все',
    title: 'Показать все',
  },
  ...mapCourseTaskCheckType,
]

export const getCoursesActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions?.courses_course_courses?.can_edit,
    },
    {
      name: 'delete',
      title: 'Удалить',
      action: 'onDelete',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.courses_course_courses.can_edit,
    },
    {
      name: 'onRemoveSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
