











import Vue from 'vue'
import ThemeHeader from '@/pages/dictionary/themes/edit/parts/header/Header.vue'
import ThemeContent from '@/pages/dictionary/themes/edit/parts/Content.vue'
import {
  $formToSend,
  $formToSendPrerequisite,
  clearFields,
  redirectAfterSaveChanged,
  getThemeToUpdate,
  edit,
} from '@/pages/dictionary/themes/edit/theme-edition-page.model'
import { $isPrerequisite } from '@/pages/dictionary/themes/edit/parts/prerequisites/prerequisites.model'
import { $selectedSubject } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'

export default Vue.extend({
  name: 'ThemeEditionPage',
  effector: {
    $formToSend,
    $formToSendPrerequisite,
    $isPrerequisite,
    $selectedSubject,
  },
  components: {
    ThemeHeader,
    ThemeContent,
  },
  computed: {
    correctTitle() {
      return `Редактирование ${this.$isPrerequisite ? 'пререквизита' : 'темы'}`
    },
  },
  methods: {
    redirectAfterSaveChanged,
    clearFields,
    getThemeToUpdate,
    edit,
    editWithRedirect() {
      redirectAfterSaveChanged(true)
      edit()
    },
  },
  mounted() {
    this.$nextTick(() => getThemeToUpdate(+this.$route.params.id))
  },
  beforeDestroy() {
    redirectAfterSaveChanged(false)
    clearFields()
  },
})
