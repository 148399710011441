











import Vue from 'vue'
import FileInput from '@/ui/audio-file-input/AudioFileInput.vue'
import FormLabel from '@/ui/label/FormLabel.vue'
import { uploadDraggableImage } from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'

export default Vue.extend({
  name: `ImagesUploader`,
  components: {
    FileInput,
    FormLabel,
  },
  methods: {
    uploadDraggableImage,
  },
})
