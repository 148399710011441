import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GroupCreateFxParams, GroupCreateType } from '@/features/api/groups/types'

export const updateGroupFx = createApiEffect<
  { body: GroupCreateType; id: string },
  GroupCreateFxParams
>({
  requestMapper: ({ body, id }) => ({
    method: 'PATCH',
    url: `/api/user-app/groups/${id}/`,
    body,
  }),
})
