import { createEvent, forward, restore, sample, split } from 'effector-root'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { condition } from 'patronum'
import {
  $editedFullAppointmentId,
  changeFullAppointmentsId,
} from '@/pages/exams/full-appointments/create/model/edit-full-appointments.model'
import { $fullAppointmentSendForm } from '@/pages/exams/full-appointments/create/model/form-fileds.model.ts'
import { createFullAppointmentsFx } from '@/features/api/full-appointments/post-new-appointments'
import { updateFullAppointmentsFx } from '@/features/api/full-appointments/patch-edit-appointment'

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  createFullAppointmentsFx.fail,
])

export const getPageParams = createEvent()

const createFullAppointment = createEvent()
const updateFullAppointment = createEvent<string>()

const appointmentId = sample({
  source: $editedFullAppointmentId,
  clock: getPageParams,
  fn: (id) => (id.length > 0 ? id : false),
})

split({
  source: appointmentId,
  match: {
    post: (payload) => payload === false,
    patch: (payload) => payload !== false,
  },
  cases: {
    post: createFullAppointment,
    patch: updateFullAppointment,
  },
})

sample({
  source: $fullAppointmentSendForm,
  clock: createFullAppointment,
  fn: (form) => form,
  // @ts-ignore
  target: createFullAppointmentsFx,
})

// @ts-ignore
sample({
  source: $fullAppointmentSendForm,
  clock: updateFullAppointment,
  fn: (form, id) => ({ body: form, id }),
  // @ts-ignore
  target: updateFullAppointmentsFx,
})

forward({
  from: createFullAppointmentsFx.done,
  to: successToastEvent('Назначение создано'),
})

forward({
  from: updateFullAppointmentsFx.done,
  to: successToastEvent('Назначение изменено'),
})

forward({
  from: [createFullAppointmentsFx.fail, updateFullAppointmentsFx.fail],
  to: errorToastEvent('Что-то пошло не так'),
})

const $redirectHandler = sample({
  clock: [
    createFullAppointmentsFx.doneData.map((res) => res.body.id),
    updateFullAppointmentsFx.doneData.map((res) => res.body.id),
  ],
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'full-appointment-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => {
    changeFullAppointmentsId(String(payload.id))
    return {
      name: 'full-appointment-edit',
      params: { id: `${payload.id}` },
    }
  }),
})
