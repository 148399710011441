



























import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'TooltipCell',
  components: {
    Icon,
  },
  props: {
    title: { type: String },
    rowId: { type: Number, required: true },
    assignmentId: { type: Number, required: true },
    iconType: { type: String },
  },
  computed: {
    options() {
      return {
        content: this.title.length > 250 ? `${this.title.slice(0, 250)}...` : this.title,
      }
    },
  },
  methods: {
    handleRightClick(event: any) {
      event.preventDefault()
      this.$emit('onRightClick', {
        data: { id: this.rowId, test_assignment: { id: this.assignmentId } },
        event,
      })
    },
  },
  mounted() {
    const container = document.querySelector(`#cell-${this.rowId}`)
    container && container.addEventListener('contextmenu', this.handleRightClick)
  },
  beforeDestroy() {
    const container = document.querySelector(`#cell-${this.rowId}`)
    container && container.removeEventListener('contextmenu', this.handleRightClick)
  },
})
