import { createStaticFiltersModel } from '@/pages/common/filters/create-static-filters-model'
import { createEvent } from 'effector'
import { forward } from 'effector-root'

export const setPointsData = createEvent<number>()

export const pointVcDropdownModel = createStaticFiltersModel([])

forward({
  from: setPointsData,
  to: pointVcDropdownModel.methods.setItems.prepend((maxPoints) =>
    [...Array(maxPoints + 1)].map((e, i) => ({
      name: `${i}`,
      title: `${i}`,
    }))
  ),
})
