import { TableField } from '@/pages/applications/types'
import { DropdownItem } from '@/pages/common/types'
import { Employee } from '@/features/api/employee/types'
import { FilterName } from '@/features/api/results/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { ApplicationsCourseStatus } from '@/pages/learning/learning-courses-application/types'
import { getStatusName } from '@/pages/learning/learning-courses-application/constants'
import { StudyYearRelated } from '@/features/api/subject/types'

const roundingNumber = (value: number | null): number => {
  return value ? Math.round(value * 100) / 100 : 0
}

export const coursesResultDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'studentId',
    sortField: 'student_id',
    title: 'ID ученика',
    width: '120px',
    formatter(student: Employee | null) {
      return student ? student.id : '-'
    },
  },
  {
    name: 'studentElkId',
    sortField: 'studentElkId',
    title: 'ID ЕЛК',
    width: '90px',
  },
  {
    name: 'student',
    sortField: 'student',
    title: 'ФИО',
    width: '140px',
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    title: 'Класс',
    dataClass: 'center aligned',
    width: '90px',
    formatter(study: StudyYearRelated) {
      return study.name || '-'
    },
  },
  {
    name: 'subjects',
    sortField: 'subjects',
    dataClass: 'center aligned',
    title: 'Предмет',
    width: '140px',
    formatter(subjects: { id: number; name: string }[]) {
      return subjects ? subjects.map((s) => s.name).join(', ') : '-'
    },
  },
  {
    name: 'group',
    sortField: 'group',
    title: 'Группа',
    width: '180px',
    formatter(group: string) {
      return group || '-'
    },
  },
  {
    name: 'course_type',
    sortField: 'course_type',
    title: 'Тип курса',
    width: '115px',
    formatter(with_ticket) {
      return with_ticket ? 'С заявкой' : 'Назначаемый'
    },
  },
  {
    name: 'course',
    sortField: 'course',
    title: 'Название курса',
    width: '180px',
    formatter(course: FilterName | null) {
      return course ? course.name : '-'
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус участия',
    width: '170px',
    formatter(status: ApplicationsCourseStatus) {
      return getStatusName(status)
    },
  },
  {
    name: 'total_score',
    sortField: 'total_score',
    title: 'Max кол-во баллов',
    titleClass: 'wrap',
    dataClass: 'center aligned',
    width: '210px',
    formatter(total: number) {
      return total || 0
    },
  },
  {
    name: 'lessons_count',
    sortField: 'lessons_count',
    title: 'Кол-во уроков в курсе',
    titleClass: 'wrap',
    dataClass: 'center aligned',
    width: '160px',
    formatter(count) {
      return count || 0
    },
  },
  {
    name: 'student_participated_lessons',
    sortField: 'student_participated_lessons',
    title: 'Участвовал в уроках (кол-во)',
    titleClass: 'wrap',
    dataClass: 'center aligned',
    width: '170px',
    formatter(count) {
      return count || '-'
    },
  },
  {
    name: 'task_completed_score',
    sortField: 'task_completed_score',
    title: 'Кол-во набранных баллов',
    titleClass: 'wrap',
    dataClass: 'center aligned',
    width: '170px',
    formatter(count) {
      return count || 0
    },
  },
  {
    name: 'task_completed_percent',
    sortField: 'task_completed_percent',
    title: '% выполн-х заданий (от общего кол-ва баллов)',
    titleClass: 'wrap',
    dataClass: 'center aligned',
    width: '290px',
    formatter(percent) {
      return roundingNumber(percent)
    },
  },
  {
    name: 'task_right_completed_percent',
    sortField: 'task_right_completed_percent',
    title: '% верно выполн-х заданий (от max кол-ва баллов)',
    titleClass: 'wrap',
    dataClass: 'center aligned',
    width: '300px',
    formatter(percent) {
      return roundingNumber(percent)
    },
  },
  {
    name: 'commentary',
    sortField: 'commentary',
    title: 'Финальный комментарий',
    width: '300px',
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '50px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'student', title: 'Ученик' },
  { name: 'groups', title: 'Группа' },
]

export const getResultCoursesAllActions = (displayOptions: ActionsDisplayConditions) => {
  const actions = [
    {
      name: 'lessonsResult',
      title: 'Результаты по урокам',
      action: 'onLessonsResult',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
    {
      name: 'studentResult',
      title: 'Сводные результаты по ученику',
      action: 'onStudentResult',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
    {
      name: 'onRemoveSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}

export const mapStatusCourse = [
  {
    name: 'accepted',
    title: 'Проверен',
  },
  {
    name: 'waiting',
    title: 'Не проверен',
  },
]
