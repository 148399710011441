import { DropdownItem } from '@/pages/common/types'

export const formatSelectedItems = (
  list: { selected: DropdownItem[]; all: DropdownItem[]; searchString: string },
  element: DropdownItem | null
): DropdownItem[] => {
  if (!element) return list.selected
  if (!list.all.length) return []
  const arr = list.selected.slice()
  if (element && !arr.find(((el: DropdownItem) => el.name === element.name) || !arr.length)) {
    const elem = list.all.find((full) => full.name === element.name)
    arr.push(elem!)
  }
  return [...arr]
}
