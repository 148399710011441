import { createEvent, restore, forward } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { typeDropdownModule } from '@/pages/common/dropdowns/bank/lesson-tasks/type-dropdown/type-dropdown.model'
import { foldersDropdownModule } from '@/pages/common/dropdowns/bank/lesson-tasks/position-dropdown/position-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { languagesDropdownModule } from '@/pages/common/dropdowns/bank/test-tasks/language-dropdown/language-dropdown.model'

const dropdownModules = {
  typeDropdownModule,
  foldersDropdownModule,
  subjectsDropdownModel,
  languagesDropdownModule,
}

export const examTasksFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
    created_by_me: false,
  },
  dropdownModules
)
export const reset = createEvent<void>()

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

forward({
  from: reset,
  to: [
    typeDropdownModule.methods.resetDropdown,
    foldersDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    languagesDropdownModule.methods.resetDropdown,
  ],
})
