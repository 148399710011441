import { createEvent, attach, createStore, forward, restore, sample, split } from 'effector-root'
import { throttle } from 'patronum'
import { ApiEffect } from '@/features/api/common/create-api-effect'
import { Response } from '@/lib/request'
import { checkExportProgressFx } from '@/features/api/tasks/check-progress'
import { addToast } from '@/features/toasts/toasts.model'
import fileDownload from 'js-file-download'
import { downloadFile, getFileForPreview } from '@/features/lib'
import { loadModal } from '@/ui/preview-file/preview-file.model'

export function createTaskModule(
  loader: ApiEffect<unknown, { task_id: string }, any>,
  fileName: string,
  isArchive?: boolean,
  dontOpenModal?: boolean // Загружать файлы без открытия модального окна
) {
  const checkProgressFx = attach({
    effect: checkExportProgressFx,
    mapParams: (id: string): any => ({
      id,
      isArchive,
    }),
  })

  const exportFx = attach({
    effect: loader,
  })

  const stillLoading = createEvent<Response>()

  const doneLoading = createEvent<Response>()

  const $taskID = createStore<string>('')

  const checkProgress = createEvent<any>()

  const setTaskLoading = createEvent<boolean>()

  const $exportTaskLoading = restore(setTaskLoading, false)

  forward({
    from: exportFx.doneData.map(({ body }) => body.task_id),
    to: [$taskID, checkProgress],
  })

  sample({
    source: $taskID,
    clock: throttle({ source: checkProgress, timeout: 1000 }),
    target: checkProgressFx,
  })

  forward({
    from: exportFx.failData.map((res) => res.body),
    to: [
      setTaskLoading.prepend(() => false),
      addToast.prepend((data) => ({
        type: 'error',
        message: data?.detail || '',
      })),
    ],
  })

  split({
    source: checkProgressFx.doneData,
    match: {
      stillLoading: ({ status }) => status === 202,
      doneLoading: ({ status }) => status === 200,
    },
    cases: {
      stillLoading,
      doneLoading,
      __: setTaskLoading.prepend(() => false),
    },
  })

  forward({
    from: checkProgressFx.fail,
    to: [
      setTaskLoading.prepend(() => false),
      addToast.prepend(() => ({
        type: 'error',
        message: 'Ошибка экспорта',
      })),
    ],
  })

  forward({
    from: stillLoading,
    to: [checkProgress, setTaskLoading.prepend(() => true)],
  })

  forward({
    from: doneLoading,
    to: setTaskLoading.prepend((res) => {
      if (isArchive) {
        if (res.body.archives) {
          res.body.archives.forEach((archive: string) => {
            const file_name = archive.slice(archive.lastIndexOf('/') + 1, archive.lastIndexOf('.'))
            const splitFileName = file_name.split('.')

            if (dontOpenModal) {
              downloadFile(archive, file_name, true)
            } else {
              getFileForPreview(archive, true).then((blob) => {
                if (!blob) {
                  return
                }

                loadModal({
                  file: blob,
                  format: splitFileName[splitFileName.length - 1],
                  filename: splitFileName.slice(0, -1).join(''),
                })
              })
            }
          })
        }
      } else {
        fileDownload(res.body, `${fileName}.xlsx`)
      }
      return false
    }),
  })

  return {
    store: {
      $exportTaskLoading,
      $taskID,
    },
    effect: {
      checkProgressFx,
      exportFx,
    },
    methods: {
      setTaskLoading,
    },
  }
}
