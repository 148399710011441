import { createEvent, forward, restore } from 'effector-root'
import { resetFullAppointmentsFilters } from '@/pages/exams/full-appointments/list/model/full-appointments-filters.model'
import { classesDropdownModel } from '@/pages/users/students/list/parts/classes-dropdown/classes-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/exams/exams-full-tests/exams-full-test-list-dropdown.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import ExamsFullTestsListDropdown from '@/pages/common/dropdowns/exams/exams-full-tests/ExamsFullTestsListDropdown.vue'

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(resetFullAppointmentsFilters)

export const dropdownModules = {
  classesDropdownModel,
  subjectsDropdownModel,
  examTestsDropdownModel,
}

export const dropdownComponents = {
  SubjectsDropdown,
  ClassesDropdown,
  ExamsFullTestsListDropdown,
}

forward({
  from: reset,
  to: resetFullAppointmentsFilters,
})

forward({
  from: [resetFullAppointmentsFilters],
  to: [
    examTestsDropdownModel.methods.resetDropdown,
    classesDropdownModel.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
  ],
})
