

























































































import Vue from 'vue'
import ImageFileInput from '@/ui/image-file-input/ImageFileInput.vue'
import FormInput from '@/ui/input/FormInput.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import CourseTaskCheckTypeDropdown from '@/pages/learning/learning-courses/parts/inputs/course-task-check-type/CourseTaskCheckTypeDropdown.vue'
import {
  $courseSeoDescription,
  $courseSeoTitle,
  $coverCourse,
  $iconCourse,
  $taskCheckTypeCourse,
  $ticketsCheckTypeCourse,
  $withAgreementCourse,
  courseSeoDescriptionChanged,
  courseSeoTitleChanged,
  resetCoverCourseId,
  resetIconCourseId,
  toggleCheckTypeCourse,
  toggleTicketsCheckTypeCourse,
  toggleWithAgreementCourse,
  uploadCoverCourse,
  uploadIconCourse,
  $courseWithTicket,
  courseSeoTitleClear,
  courseSeoDescriptionClear,
  $courseIsAvailableByLink,
  setIsAvailableByLink,
  admissionTextChanged,
  $admissionText,
} from '@/pages/learning/learning-courses/create/courses-create-page.model'

export default Vue.extend({
  components: {
    FormInput,
    BaseSwitch,
    CourseTaskCheckTypeDropdown,
    ImageFileInput,
    Wysiwyg,
  },
  effector: {
    $taskCheckTypeCourse,
    $withAgreementCourse,
    $ticketsCheckTypeCourse,
    $courseSeoTitle,
    $courseSeoDescription,
    $coverCourse,
    $iconCourse,
    $courseWithTicket,
    $courseIsAvailableByLink,
    $admissionText,
  },
  methods: {
    toggleCheckTypeCourse,
    toggleWithAgreementCourse,
    toggleTicketsCheckTypeCourse,
    courseSeoTitleChanged,
    courseSeoDescriptionChanged,
    uploadCoverCourse,
    resetCoverCourseId,
    uploadIconCourse,
    resetIconCourseId,
    courseSeoTitleClear,
    courseSeoDescriptionClear,
    setIsAvailableByLink,
    admissionTextChanged,
  },
})
