import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams } from '@/features/api/types'
import { FullExamAssignment } from '@/features/api/full-appointments/types'

export const getFullExamsListFx = createApiEffect<GetListQueryParams, FullExamAssignment[]>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/tests/filter/names/',
    query,
  }),
})
