






























import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { redirectToResourceCreate } from '@/pages/dictionary/themes/edit/theme-edition-page.model'
import { $selectedSubject } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { $selectedClass } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  deleteTeachingResource,
  setTeachingResources,
  setThemeId,
  $teachingResources,
} from '@/pages/dictionary/themes/edit/parts/teaching-resources/teaching-resources-page.model'
import { ResourceType } from '@/features/api/media/types'
import { removeHtmlTags } from '@/features/lib'

export default Vue.extend({
  components: {
    Icon,
  },
  effector: {
    $selectedSubject,
    $selectedClass,
    $teachingResources,
  },
  methods: {
    redirectToResourceCreate,
    setTeachingResources,
    deleteTeachingResource,
    title(resource: ResourceType) {
      const type = resource.resource_type
      if (type === 'text') {
        return removeHtmlTags(resource.text)
      }
      if (type === 'file') {
        return resource.media?.file_name
      }
      return resource.text ? removeHtmlTags(resource.text) : resource.link
    },
  },
  mounted() {
    setThemeId(+this.$route.params.id)
  },
})
