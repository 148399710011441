import { createApiEffect } from '@/features/api/common/create-api-effect'

export const createCertificatesPreviewFx = createApiEffect<FormData, Blob, ArrayBuffer>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/certificate-app/create/',
    body,
    responseType: 'arraybuffer',
  }),
})
