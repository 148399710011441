import { render, staticRenderFns } from "./TasksUpdateModal.vue?vue&type=template&id=0fa028ba&scoped=true&"
import script from "./TasksUpdateModal.vue?vue&type=script&lang=ts&"
export * from "./TasksUpdateModal.vue?vue&type=script&lang=ts&"
import style0 from "./TasksUpdateModal.vue?vue&type=style&index=0&id=0fa028ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa028ba",
  null
  
)

export default component.exports