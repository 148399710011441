
































import Vue, { PropType } from 'vue'

import FormInput from '@/ui/input/FormInput.vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'DropdownActivator',
  components: {
    FormInput,
    Icon,
  },
  props: {
    isOpen: { type: Boolean as PropType<boolean> },
    disabled: { type: Boolean as PropType<boolean> },
    value: { type: [String, Number] as PropType<string | number>, required: true },
    errorMessage: { type: String as PropType<string>, default: '' },
    placeholder: { type: String as PropType<string>, default: '' },
    label: { type: String as PropType<string>, default: '' },
    readOnlyDropdown: { type: Boolean as PropType<boolean> },
    clearBtn: { type: Boolean, default: true },
  },
})
