import { DropdownItem } from '@/pages/common/types'

export const signMapData: DropdownItem[] = [
  {
    name: '>',
    title: 'больше',
  },
  {
    name: '<',
    title: 'меньше',
  },
  {
    name: '=',
    title: 'равно',
  },
  {
    name: '>=',
    title: 'больше или равно',
  },
  {
    name: '<=',
    title: 'меньше или равно',
  },
]
