import { createFiltersModel } from '@/pages/common/filters/create-filters-multiselect-model'

export const mapAttemptsStatus = [
  {
    name: 'yes',
    title: 'Да',
  },
  {
    name: 'no',
    title: 'Нет',
  },
  {
    name: 'error',
    title: 'Ошибка',
  },
]

export const attemptsResultStatusDropdownModule = createFiltersModel()
