import { createApiEffect } from '@/features/api/common/create-api-effect'
import { LoadingCourseApplications, SendCourseApplications } from '@/features/api/learning/types'

export const updateApplicationsCoursesFx = createApiEffect<
  SendCourseApplications,
  LoadingCourseApplications
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: `/api/ticket-app/course-ticket/${body.id}/`,
    body,
  }),
})
