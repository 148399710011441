






































import Vue, { PropType } from 'vue'
import BaseInput from '@/ui/input/BaseInput.vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'NumericInput',
  components: {
    BaseInput,
    Icon,
  },
  props: {
    value: { type: Number as PropType<number>, default: 0 },
    label: { type: String as PropType<string>, default: '' },
    type: { type: String as PropType<string>, default: 'text' },
    placeholder: { type: String as PropType<string> },
    maxLength: { type: Number },
    errorMessage: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean> },
  },
  model: {
    event: 'input',
    prop: 'value',
  },
  methods: {
    handleInput(evt: any) {
      const res = evt.replace(/\D*/, '')
      this.$emit('input', res)
    },
    increment() {
      this.$emit('input', this.$props.value + 1)
    },
    decrement() {
      if (this.$props.value > 0) this.$emit('input', this.$props.value - 1)
    },
    handleKeyDown(event: any) {
      if ((event.keyCode < 48 && event.keyCode !== 8) || event.keyCode > 57) event.preventDefault()
    },
  },
})
