import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { FilterName } from '@/features/api/results/types'

export const coursesNameListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<FilterName[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/course-app/courses/list/all/',
    query,
  }),
})
