













import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  forWhoDropdownModel,
  loadForWho,
  setSelectedForWho,
} from '@/pages/common/dropdowns/courses/tags-dropdown/ForWhoDropdown/for-who-dropdown'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...forWhoDropdownModel.store,
  },
  methods: {
    ...forWhoDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedForWho(item || null)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (this.$items.length === 0) loadForWho()
  },
})
