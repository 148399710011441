import { createApiEffect } from '@/features/api/common/create-api-effect'
import { UpdateCertificatesGeneratorQueryBody } from '@/features/api/results/certificates-generator/types'

export const patchCertificateFX = createApiEffect<
  { body: UpdateCertificatesGeneratorQueryBody },
  null
>({
  requestMapper: ({ body }) => ({
    method: 'PATCH',
    url: '/api/certificate-app/certificates/list/bulk-patch/',
    body,
  }),
})
