











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  certUsersDropdownModel,
  setSelectedUser,
  loadCertUsers,
  $certUsers,
} from '@/pages/common/dropdowns/cert-users/cert-users-dropdown/cert-users-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...certUsersDropdownModel.store,
    $certUsers,
  },
  methods: {
    ...certUsersDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedUser(item || null)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    loadCertUsers()
  },
})
