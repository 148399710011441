













import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  difficultDropdownModel,
  loadDifficult,
  setSelectedDifficult,
} from '@/pages/common/dropdowns/courses/tags-dropdown/DificultDropdown/difficult-dropdown'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...difficultDropdownModel.store,
  },
  methods: {
    ...difficultDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedDifficult(item || null)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (this.$items.length === 0) loadDifficult()
  },
})
