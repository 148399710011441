






















import Vue, { PropType } from 'vue'
import TreeNode from '@/pages/tags/parts/tree/TreeNode.vue'
import { $tagsTree, $isLoading } from '@/pages/tags/tags-page.model'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { FiltersParams } from '@/pages/common/types'

export default Vue.extend({
  name: 'TagsTree',
  components: {
    NoDataContent,
    TreeNode,
  },
  props: {
    filters: { type: Object as PropType<FiltersParams> },
  },
  effector: { $tagsTree, $isLoading },
})
