












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  $difficultys,
  difficultyDropdownModule,
  loadDifficultys,
  setSelectedDifficulty,
} from '@/pages/common/dropdowns/bank/test-tasks/difficulty-dropdown/difficulty-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    $difficultys,
    ...difficultyDropdownModule.store,
  },
  methods: {
    loadDifficultys,
    ...difficultyDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
      setSelectedDifficulty(item)
    },
  },
  mounted() {
    loadDifficultys()
  },
})
