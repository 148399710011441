import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import { condition } from 'patronum'
import {
  $selectedSubject,
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import {
  $selectedThemes,
  themeDropdownModule,
} from '@/pages/dictionary/themes/create/parts/themes/themes.model'
import {
  $selectedClass,
  setSelectedClass,
  classesDropdownModule,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { themesDropdownModule as positionDropdownModule } from '@/pages/dictionary/themes/create/parts/position/position.model'
import {
  $selectedPrerequisites,
  prerequisitesDropdownModel,
  resetSelectedPrerequisites,
} from '@/pages/dictionary/themes/create/parts/prerequisites/prerequisites.model'
import { createThemeFx } from '@/features/api/subject/create-theme'
import { CreateThemeType } from '@/features/api/subject/types'
import { DEFAULT_ID } from '@/pages/common/constants'
import {
  $isPrerequisite,
  isPrerequisiteChanged,
} from '@/pages/dictionary/themes/create/parts/header/header.model'
import { createError } from '@/lib/effector/error-generator'
import { getUserStudyYearFx } from '@/features/api/study_year/get-user-study-year'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { DropdownItem } from '@/pages/common/types'

const saveThemeFx = attach({
  effect: createThemeFx,
})

const savePrerequisiteFx = attach({
  effect: createThemeFx,
  mapParams: (params: Partial<CreateThemeType>) => params,
})

export const getClass = attach({
  effect: getUserStudyYearFx,
})

export const clearFields = createEvent<void>()

export const create = createEvent<void>()

const checkIfThemeCanBeSend = createEvent<void>()
const checkIfPrerequisiteCanBeSend = createEvent<void>()
const saveTheme = createEvent<void>()
const savePrerequisite = createEvent<void>()

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

export const resetThemeTitle = createEvent<void>()
export const themeTitleChanged = createEvent<string>()
export const $themeTitle = restore(themeTitleChanged, '').reset(resetThemeTitle)

export const resetPrerequisiteTitle = createEvent<void>()
export const prerequisiteTitleChanged = createEvent<string>()
export const $prerequisiteTitle = restore(prerequisiteTitleChanged, '').reset(
  resetPrerequisiteTitle
)

forward({
  from: clearFields,
  to: [
    isPrerequisiteChanged.prepend(() => false),
    resetThemeTitle,
    resetPrerequisiteTitle,
    classesDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    positionDropdownModule.methods.resetDropdown,
    prerequisitesDropdownModel.methods.resetDropdown,
    themeDropdownModule.methods.resetDropdown,
    setSelectedSubject.prepend(() => null),
    setSelectedClass.prepend(() => null),
  ],
})

export const $formToSend = combine({
  id: DEFAULT_ID,
  name: $themeTitle,
  is_prerequisite: $isPrerequisite,
  study_year_id: $selectedClass.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  subject_id: $selectedSubject.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  themes_ids: $selectedThemes.map((arr: DropdownItem[]) => arr.map((data) => data && +data.name)),
  prerequisites_ids: $selectedPrerequisites.map((arr) => arr.map((data) => +data.name)),
  parent_theme_id: positionDropdownModule.store.$item.map((data) =>
    data !== null ? +data!.name : null
  ),
})

export const $formToSendPrerequisite = combine({
  id: DEFAULT_ID,
  name: $prerequisiteTitle,
  is_prerequisite: $isPrerequisite,
  subject_id: $selectedSubject.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  themes_ids: $selectedThemes.map((arr: DropdownItem[]) => arr.map((data) => +data.name)),
})

export const $themeTitleErrorModule = createError()

export const $prerequisiteTitleErrorModule = createError()

export const $classErrorModule = createError()

export const $positionErrorModule = createError()

export const $subjectErrorModule = createError()

const resetErrors = createEvent<void>()

forward({
  from: resetErrors,
  to: [
    $themeTitleErrorModule.methods.resetError,
    $prerequisiteTitleErrorModule.methods.resetError,
    $classErrorModule.methods.resetError,
    $positionErrorModule.methods.resetError,
    $subjectErrorModule.methods.resetError,
  ],
})

forward({
  from: isPrerequisiteChanged,
  to: [
    resetThemeTitle,
    resetPrerequisiteTitle,
    classesDropdownModule.methods.resetItem,
    subjectsDropdownModel.methods.resetItem,
    positionDropdownModule.methods.resetItem,
    prerequisitesDropdownModel.methods.resetItem,
    themeDropdownModule.methods.resetItem,
    setSelectedSubject.prepend(() => null),
    setSelectedClass.prepend(() => null),
    resetSelectedPrerequisites,
    resetErrors,
  ],
})

forward({
  from: subjectsDropdownModel.methods.itemChanged,
  to: [resetSelectedPrerequisites, prerequisitesDropdownModel.methods.resetItem],
})

const $saveMethodFired = sample({
  source: $isPrerequisite,
  clock: create,
  fn: (isPrerequisite) => isPrerequisite,
})

condition({
  source: $saveMethodFired,
  if: (isPrerequisite) => isPrerequisite,
  then: checkIfPrerequisiteCanBeSend.prepend(() => ({})),
  else: checkIfThemeCanBeSend.prepend(() => ({})),
})

sample({
  source: $formToSend,
  clock: checkIfThemeCanBeSend,
  fn: (obj) => {
    if (obj.name.trim().length && obj.study_year_id !== DEFAULT_ID && obj.subject_id !== DEFAULT_ID)
      saveTheme()
    else {
      !obj.name.trim().length && $themeTitleErrorModule.methods.setError(true)
      obj.study_year_id === DEFAULT_ID && $classErrorModule.methods.setError(true)
      obj.subject_id === DEFAULT_ID && $subjectErrorModule.methods.setError(true)
      errorToastEvent('Необходимо заполнить все обязательные поля')
    }
  },
})

sample({
  clock: saveTheme,
  source: $formToSend,
  target: saveThemeFx,
})
sample({
  source: $formToSendPrerequisite,
  clock: savePrerequisite,
  target: savePrerequisiteFx,
})

const $ifRedirectTheme = sample({
  clock: saveThemeFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

const $ifRedirectPrerequisite = sample({
  clock: savePrerequisiteFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

forward({
  from: saveThemeFx.doneData,
  to: successToastEvent('Тема успешно создана!'),
})
forward({
  from: savePrerequisiteFx.doneData,
  to: successToastEvent('Пререквизит успешно создан!'),
})

condition({
  source: $ifRedirectTheme,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'themes-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'themes-edit',
    params: { id: `${payload.id}` },
  })),
})
condition({
  source: $ifRedirectPrerequisite,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'themes-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'themes-edit',
    params: { id: `${payload.id}` },
  })),
})

sample({
  source: $formToSendPrerequisite,
  clock: checkIfPrerequisiteCanBeSend,
  fn: (obj) => {
    if (obj.name.length && obj.subject_id !== DEFAULT_ID) savePrerequisite()
    else {
      if (obj.name.length === 0) $prerequisiteTitleErrorModule.methods.setError(true)
      if (obj.subject_id === DEFAULT_ID) $subjectErrorModule.methods.setError(true)
      errorToastEvent('Необходимо заполнить все обязательные поля')
    }
  },
})

forward({
  from: themeTitleChanged,
  to: $themeTitleErrorModule.methods.resetError,
})

forward({
  from: prerequisiteTitleChanged,
  to: $prerequisiteTitleErrorModule.methods.resetError,
})

forward({
  from: classesDropdownModule.methods.itemChanged,
  to: $classErrorModule.methods.resetError,
})

forward({
  from: positionDropdownModule.methods.itemChanged,
  to: $positionErrorModule.methods.resetError,
})

forward({
  from: subjectsDropdownModel.methods.itemChanged,
  to: $subjectErrorModule.methods.resetError,
})
