import { createEvent, forward, restore, sample, split } from 'effector-root'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import { $formWithoutTaskBlock } from '@/pages/learning/learning-lessons/create/model/form-fileds.model'
import { createLessonFx } from '@/features/api/learning/lessons/post-new-lesson'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { updateLessonBulkFx } from '@/features/api/learning/lessons/lesson-by-id-end-points'
import {
  $editedLessonId,
  changeLessonId,
} from '@/pages/learning/learning-lessons/create/model/edit-lesson.model'
import { condition } from 'patronum'

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  createLessonFx.fail,
  updateLessonBulkFx.fail,
])

export const getPageParams = createEvent()

const createLesson = createEvent()

const updateLesson = createEvent<string>()

const lessonId = sample({
  source: $editedLessonId,
  clock: getPageParams,
  fn: (id) => (id.length > 0 ? id : false),
})

split({
  source: lessonId,
  match: {
    post: (payload) => payload === false,
    put: (payload) => payload !== false,
  },
  cases: {
    post: createLesson,
    put: updateLesson,
  },
})

sample({
  source: $formWithoutTaskBlock,
  clock: createLesson,
  fn: (form) => form,
  target: createLessonFx,
})

sample({
  source: $formWithoutTaskBlock,
  clock: updateLesson,
  fn: (form, id) => ({ body: form, id }),
  target: updateLessonBulkFx,
})

const findFirstError = (error: any) => {
  let errorMessage = 'Извините, что то пошло не так :('
  if (error && typeof error === 'object') {
    const data = error && Array.isArray(error) ? error : Object.values(error)
    if (data && Array.isArray(data) && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const subData = data[i] && Array.isArray(data[i]) ? data[i] : Object.values(data[i])
        if (subData.length > 0 && typeof subData[0] === 'string') {
          errorMessage = String(subData[0])
          break
        }
        if (Array.isArray(subData) && subData.length > 0) {
          errorMessage = findFirstError(subData)
        }
      }
    }
  }
  return errorMessage
}

sample({
  clock: [
    createLessonFx.failData.map((res) => res.body),
    updateLessonBulkFx.failData.map((res) => res.body),
  ],
  fn: (data) => {
    return findFirstError(data)
  },
  target: addToast.prepend((message: string) => ({
    type: 'error',
    message,
  })),
})
forward({
  from: createLessonFx.done,
  to: successToastEvent('Урок создан'),
})

forward({
  from: updateLessonBulkFx.done,
  to: successToastEvent('Урок отредактирован'),
})

const $redirectHandler = sample({
  clock: [
    createLessonFx.doneData.map((res) => res.body.id),
    updateLessonBulkFx.doneData.map((res) => res.body.id),
  ],
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'lessons-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => {
    changeLessonId(String(payload.id))
    return {
      name: 'edit-lesson',
      params: { id: `${payload.id}` },
    }
  }),
})
