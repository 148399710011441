























































import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import ServiceFilterDropdown from '@/pages/common/dropdowns/proctors/service-dropdown/ServiceFilterDropdown.vue'
import {
  $proctorsCmsCommentary,
  $proctorsIsActive,
  $proctorsLogin,
  $proctorsNeedAdd,
  $proctorsPassword,
  setProctorsCmsCommentary,
  setProctorsIsActive,
  setProctorsLogin,
  setProctorsNeedAdd,
  setProctorsPassword,
} from '@/pages/exams/proctors/create/model/form-fileds.model'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'

export default Vue.extend({
  name: 'CreateProctorsContent',
  components: {
    FormInput,
    ServiceFilterDropdown,
    BaseSwitch,
  },
  effector: {
    $proctorsLogin,
    $proctorsPassword,
    $proctorsCmsCommentary,
    $proctorsIsActive,
    $proctorsNeedAdd,
  },
  methods: {
    setProctorsLogin,
    setProctorsPassword,
    setProctorsCmsCommentary,
    setProctorsIsActive,
    setProctorsNeedAdd,
  },
})
