import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import {
  $selectedClass,
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  $selectedSubject,
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { condition } from 'patronum'
import {
  $selectedTheme,
  setSelectedTheme,
  themesDropdownModule,
} from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { getLabelFx } from '@/features/api/assignment/labels/get-label'
import { updateLabelFx } from '@/features/api/assignment/update-label'
import { getLabelsTreeLight } from '@/pages/labels/labels-page.model'
import { createError } from '@/lib/effector/error-generator'

export const updateLabel = attach({
  effect: updateLabelFx,
})

const getLabel = attach({
  effect: getLabelFx,
})

export const loadModalToEdit = createEvent<number>()
export const checkIfThemeCanBeSend = createEvent<void>()
export const clearFields = createEvent<void>()

export const modalVisibilityChanged = createEvent<boolean>()
export const $modalVisibility = restore(modalVisibilityChanged, false)

export const labelTitleChanged = createEvent<string>()
const labelTitleReset = createEvent<void>()
export const $labelTitle = restore(labelTitleChanged, '').reset(labelTitleReset)

export const $subjectErrorModule = createError()

export const $classErrorModule = createError()

export const $themeErrorModule = createError()

export const $titleErrorModule = createError()

const $form = combine({
  id: DEFAULT_ID,
  name: $labelTitle,
  study_year_id: $selectedClass.map((data) => (data && data.name ? +data.name : null)),
  subject_id: $selectedSubject.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  theme_id: $selectedTheme.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  is_prerequisite: $selectedTheme.map((data) => data && data.is_prerequisite),
})

sample({
  source: $form,
  clock: checkIfThemeCanBeSend,
  fn: ({ id, name, study_year_id, subject_id, theme_id, is_prerequisite }) => {
    if (
      name.trim().length &&
      subject_id !== DEFAULT_ID &&
      theme_id !== DEFAULT_ID &&
      (is_prerequisite || study_year_id)
    ) {
      updateLabel({
        id,
        name,
        subject_id,
        theme_id,
        study_year_id,
      })
    } else {
      if (!name.trim().length) $titleErrorModule.methods.setError(true)
      if (study_year_id === DEFAULT_ID) $classErrorModule.methods.setError(true)
      if (subject_id === DEFAULT_ID) $subjectErrorModule.methods.setError(true)
      if (theme_id === DEFAULT_ID) $themeErrorModule.methods.setError(true)
      errorToastEvent('Необходимо заполнить все обязательные поля')
    }
  },
})

forward({
  from: labelTitleChanged,
  to: $titleErrorModule.methods.resetError,
})

forward({
  from: subjectsDropdownModel.methods.itemChanged,
  to: $subjectErrorModule.methods.resetError,
})

forward({
  from: classesDropdownModule.methods.itemChanged,
  to: $classErrorModule.methods.resetError,
})

forward({
  from: themesDropdownModule.methods.itemChanged,
  to: $themeErrorModule.methods.resetError,
})

forward({
  from: loadModalToEdit,
  to: [getLabel, modalVisibilityChanged.prepend(() => true)],
})

sample({
  clock: getLabel.doneData.map(({ body }) => body),
  fn: ({ id, name, subject, study_year, theme }) => {
    $form.map((data) => (data.id = id))
    labelTitleChanged(name)
    subject &&
      subjectsDropdownModel.methods.itemChanged({
        name: `${subject.id}`,
        title: subject.name,
      })
    subject &&
      setSelectedSubject({
        id: subject.id,
        name: `${subject.id}`,
        title: subject.name,
      })
    study_year &&
      classesDropdownModule.methods.itemChanged({
        name: `${study_year.id}`,
        title: study_year.name,
      })
    study_year &&
      setSelectedClass({
        id: study_year.id,
        name: `${study_year.id}`,
        title: study_year.name,
      })
    theme && themesDropdownModule.methods.itemChanged({ name: `${theme.id}`, title: theme.name })
    theme &&
      setSelectedTheme({
        name: `${theme.id}`,
        title: theme.name,
        is_prerequisite: theme.is_prerequisite,
      })
  },
})

forward({
  from: updateLabelFx.doneData,
  to: [
    successToastEvent('Метка была успешно обновлена!'),
    getLabelsTreeLight.prepend(() => ({})),
    modalVisibilityChanged.prepend(() => false),
  ],
})

condition({
  source: modalVisibilityChanged,
  if: (payload: boolean) => !payload,
  then: clearFields,
})

forward({
  from: clearFields,
  to: [
    labelTitleReset,
    subjectsDropdownModel.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    themesDropdownModule.methods.resetDropdown,
    setSelectedTheme.prepend(() => null),
    setSelectedClass.prepend(() => null),
    setSelectedSubject.prepend(() => null),
  ],
})
