






































import Vue, { PropType } from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import ModeratorDropdown from '@/pages/common/dropdowns/users/moderator-dropdown/ModeratorDropdown.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  checkIfTaskCanBeSend,
  $moderatorErrorModule,
  setTaskType,
} from '@/pages/bank/common/modals/moderator-select/moderator-select.model'
import { TaskType } from '@/pages/bank/common/modals/moderator-select/constants'

export default Vue.extend({
  components: {
    Modal,
    Icon,
    ModeratorDropdown,
    BaseButton,
  },
  props: {
    type: { type: String as PropType<TaskType> },
  },
  effector: {
    $modalVisibility,
    moderatorError: $moderatorErrorModule.store.$error,
  },
  methods: {
    modalVisibilityChanged,
    checkIfTaskCanBeSend,
  },
  mounted() {
    setTaskType(this.type)
  },
})
