import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams } from '@/features/api/types'

export const getUsersListFx = createApiEffect<GetListQueryParams, { id: number; name: string }[]>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/user-app/users/filter/names/',
    query,
  }),
})

export const getUsersListCert = createApiEffect<GetListQueryParams, { id: number; name: string }[]>(
  {
    requestMapper: (query) => ({
      method: 'GET',
      url: '/api/certificate-app/certificates/list/name/',
      query,
    }),
  }
)
