















import Vue, { PropType } from 'vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import {
  $assignedToMe,
  assignedToMeChanged,
} from '@/pages/applications/outgoing/parts/header/page-header.model'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'

export default Vue.extend({
  components: {
    HeaderPopup,
    DownloadButton,
  },
  effector: {
    $assignedToMe,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
  },
  methods: { assignedToMeChanged },
})
