// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/img/file2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".files[data-v-3f9ac23a]{display:flex;align-items:center;margin-bottom:20px}.files[data-v-3f9ac23a]:last-child{margin-bottom:0}.file-icon[data-v-3f9ac23a]{width:30px;height:30px;margin-right:17px;box-shadow:0 4px 14px rgba(0,0,0,.15);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%;background-repeat:no-repeat}.file-name[data-v-3f9ac23a]{font-weight:600;line-height:1rem;margin-right:20px}.file-download[data-v-3f9ac23a],.file-name[data-v-3f9ac23a]{font-style:normal;font-size:1rem;color:#0f2345}.file-download[data-v-3f9ac23a]{cursor:pointer;font-weight:400;line-height:1.42857rem;-webkit-text-decoration-line:underline;text-decoration-line:underline}.image-link[data-v-3f9ac23a]{width:60px;height:60px;border-radius:5px;overflow:hidden}.images-block[data-v-3f9ac23a]{display:flex}.image-file[data-v-3f9ac23a]{width:100%;height:100%}", ""]);
// Exports
module.exports = exports;
