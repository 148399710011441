































































































































































import Vue, { VueConstructor } from 'vue'
import qs from 'qs'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import PageHeader from '@/pages/results/courses-all/parts/header/PageHeader.vue'
import ResultCourseFilter from '@/pages/results/courses-all/parts/filter/ResultCourseFilter.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import {
  combineRouteQueries,
  computeSortParam,
  isQueryParamsEquelToPage,
  removeHtmlTags,
} from '@/features/lib'
import { config } from '@/config'
import { $permissions } from '@/features/session'
import { DEFAULT_ID } from '@/pages/common/constants'
import {
  searchFieldsData,
  coursesResultDataFields,
  getResultCoursesAllActions,
} from '@/pages/results/courses-all/constants'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  $canRefreshTable,
  incomingResultCoursesPageParams,
  canRefreshTableChanged,
  $isLoading,
  setNewTableFields,
  $tableFields,
  taskLoader,
  setIsLoading,
  addTableFields,
  setDefaultTableFields,
  $editData,
  setEditData,
  saveCommentStudentsCourseAll,
} from '@/pages/results/courses-all/result-courses-all.model'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import {
  RefsType,
  CommonInteractedItemParams,
  DisplayContextMenuPayload,
  ActionsItem,
  HttpOptionsFiltersType,
} from '@/pages/common/types'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import {
  toggleVisibility,
  $visibility,
  resultCoursesFilters,
} from '@/pages/results/courses-all/parts/filter/course-result-filter.model'
import { getActionsDisplayConditions } from '@/pages/common'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import TableColumns from '@/pages/common/parts/table-columns/TableColumns.vue'
import { SortFieldsType } from '@/pages/results/types'
import { axiosClient } from '@/lib/request'
import ContextCell from '@/pages/common/parts/context-cell/ContextCell.vue'
import Icon from '@/ui/icon/Icon.vue'
import { courseAllResultItem } from '@/pages/results/courses-all/types'
import { getDefaultFiltersFx } from '@/features/api/filters/getDefaultFiltersFx'
import getDefaultFilterItemByKind from '@/features/api/filters/utils/getDefaultFilterItemByKind'
import ICoursesDefaultFilter from '@/features/api/filters/interfaces/ICoursesDefaultFilter'
import { coursesNameDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/courses-name-dropdown-filter/courses-filter-dropdown.model'
import IStatusDefaultFilter from '@/features/api/filters/interfaces/IStatusDefaultFilter'
import { statusDropdownModule } from '@/pages/common/dropdowns/courses/status-dropdown/status-dropdown.model'
import TDefaultFilterItem from '@/features/api/filters/types/TDefaultFilterItem'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    PageHeader,
    GeneralFilter,
    ResultCourseFilter,
    TableHeader,
    ActionsButton,
    ContextMenu,
    Vuetable,
    VuetablePagination,
    NoDataContent,
    TooltipCell,
    LoaderBig,
    TableColumns,
    ContextCell,
    Icon,
  },
  effector: {
    $canRefreshTable,
    $filterParams: resultCoursesFilters.store.$filterParams,
    $pageParams: incomingResultCoursesPageParams.store.$pageParams,
    $currentPage: incomingResultCoursesPageParams.store.currentPage,
    $isLoading,
    $tableFields,
    $visibility,
    $permissions,
    $editData,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID as number,
      searchFields: searchFieldsData,
      total: 1,
      fields: coursesResultDataFields,
      selectedRows: [] as number[],
      isFilters: false,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuType: 'item' as ContextMenuType,
      contextMenuItems: [] as ActionsItem[],
      sort: [] as SortFieldsType[],
      currentEditComment: 0,
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/results-app/results/courses/list/`
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getResultCoursesAllActions(displayConditions)
    },
  },
  watch: {
    $canRefreshTable: {
      handler(newVal) {
        if (newVal === true) {
          this.$refs.vuetable.reload()
          canRefreshTableChanged(false)
          if (Object.values(this.$editData).length === 0) {
            this.currentEditComment = 0
          }
        }
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    toggleVisibility,
    changeFilter: resultCoursesFilters.methods.changeFilter,
    resetFilters: resultCoursesFilters.methods.resetFilters,
    applyFilters: resultCoursesFilters.methods.applyFilters,
    changePage: incomingResultCoursesPageParams.methods.changePage,
    queryToParams: incomingResultCoursesPageParams.methods.queryToParams,
    setNewTableFields,
    reset,
    setIsLoading,
    setDefaultTableFields,
    setEditData,
    exportTable() {
      taskLoader.effect.exportFx(null)
      taskLoader.methods.setTaskLoading(true)
    },
    headerApiCall(sort: SortFieldsType) {
      this.sort = [{ ...sort }]
      this.$refs.vuetable.loadData()
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsFiltersType) {
      const sortParams = this.sort[0] ? `${this.sort[0].sortField}|${this.sort[0].direction}` : ''
      const request = await axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(sortParams) },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' })
        },
      })
      if (
        'courses' in httpOptions.params &&
        typeof httpOptions.params.courses === 'string' &&
        request?.data?.data &&
        request.data.data.length > 0
      ) {
        const modFields = [...this.$tableFields].filter((field) => field.name !== 'actions')
        Object.keys(request.data.data[0]).forEach((field) => {
          if (field && field.length > 0 && field[0] === '[') {
            modFields.push({
              name: '',
              title: field,
              width: '220px',
              titleClass: 'wrap',
              dataClass: 'center aligned',
              formatter(data) {
                return data[field] === null ? '-' : data[field]
              },
            })
          }
        })
        modFields.push({
          name: 'actions',
          title: '',
          dataClass: 'actions-cell right aligned',
          width: '50px',
        })
        addTableFields(modFields)
      } else {
        this.setDefaultTableFields()
      }
      return request
    },
    onFilterSet() {
      this.isFilters = true
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.isFilters = false
      this.resetFilters()
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
      event.preventDefault()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-link')) {
        window.open(res.event.target.closest('.actions-link').href, '_blank')
        return
      }
      if (res.event.target.closest('.actions-activator')) return
      if (res.event.target.closest('.edit-comment')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      this.$refs.vuetable.onCheckboxToggledAll(isSelected)
      if (isSelected) {
        this.selectedRows = this.$refs.vuetable.selectedTo
      } else {
        this.selectedRows = []
      }
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    clearWording(str: string) {
      return removeHtmlTags(str)
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getResultCoursesAllActions(displayConditions)
    },
    displayContextMenu({ id, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    getSendItemObject(data: courseAllResultItem) {
      if (data.id in this.$editData) {
        return this.$editData[data.id]
      }
      return {
        course_id: data.course.id,
        student_id: data.student.id,
        commentary: data.commentary || '',
      }
    },
    getComment(data: courseAllResultItem) {
      let str = data.commentary
      if (data.id in this.$editData) {
        str = this.$editData[data.id].commentary
      }
      if (str) return removeHtmlTags(str)
      return '-'
    },
    onEditComment(data: courseAllResultItem, incomingCommentary: string) {
      const commentary = incomingCommentary === '-' ? '' : incomingCommentary
      setEditData({ ...this.$editData, [data.id]: { ...this.getSendItemObject(data), commentary } })
      this.currentEditComment = data.id
    },
    handleUpdateComment(val: string) {
      if (this.currentEditComment) {
        const id = this.currentEditComment
        setEditData({ ...this.$editData, [id]: { ...this.$editData[id], commentary: val } })
      }
    },
    hideCommentInput(id: number) {
      saveCommentStudentsCourseAll(this.$editData[id])
      this.currentEditComment = 0
    },
    isEdit(id: number) {
      return this.currentEditComment === id
    },
    updateCoursesDefaultFilter(body: TDefaultFilterItem[]) {
      const item = getDefaultFilterItemByKind(body, 'courses') as ICoursesDefaultFilter | null

      if (!item) {
        return
      }

      const resultValue = item.value.map((data) => ({
        name: data.id.toString(),
        title: data.name,
      }))

      let stringValue = ''

      if (resultValue.length === 1) {
        stringValue = resultValue[0].name
      }

      if (resultValue.length > 1) {
        resultValue.map((value) => value.name).join(',')
      }

      resultCoursesFilters.methods.changeFilter({
        courses: stringValue,
      })

      coursesNameDropdownModel.methods.setSelectedItems(resultValue)
    },
    updateStatusDefaultFilter(body: TDefaultFilterItem[]) {
      const item = getDefaultFilterItemByKind(body, 'status') as IStatusDefaultFilter | null

      if (!item) {
        return
      }

      // @ts-ignore
      resultCoursesFilters.methods.changeFilter({
        status: item.value.name,
      })

      statusDropdownModule.methods.itemChanged(item.value)
    },
  },
  mounted() {
    getDefaultFiltersFx('results-courses-all').then((res) => {
      this.updateCoursesDefaultFilter(res.body)
      this.updateStatusDefaultFilter(res.body)
      this.onFilterSet()
    })
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
