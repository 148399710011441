











































import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  $subjectTitle,
  $subjectTitleErrorModule,
  resetSubjectTitle,
  subjectTitleChanged,
  $subjectDescription,
  subjectDescriptionChanged,
  $useFulInfo,
  useFulInfoChanged,
  $shortUsefulInfo,
  shortUsefulInfoChanged,
} from '@/pages/dictionary/subjects/common/create-edit.model'

export default Vue.extend({
  components: { FormInput, Wysiwyg },
  effector: {
    $subjectTitle,
    subjectTitleError: $subjectTitleErrorModule.store.$error,
    $subjectDescription,
    $useFulInfo,
    $shortUsefulInfo,
  },
  methods: {
    resetSubjectTitle,
    subjectTitleChanged,
    subjectDescriptionChanged,
    useFulInfoChanged,
    shortUsefulInfoChanged,
  },
})
