import { createApiEffect } from '@/features/api/common/create-api-effect'
import { HobbieType } from '@/features/api/settings/types'

export const createHobbieFx = createApiEffect<HobbieType, void>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/prefs-app/courses/',
    body,
  }),
})
