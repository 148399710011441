

























import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'

export default Vue.extend({
  name: 'ContentFooter',
  components: {
    BaseButton,
  },
  props: {
    disabled: { type: Boolean as PropType<boolean>, default: true },
  },
  methods: {
    onSaveClick() {
      this.$emit('save')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
