import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const getOlympiadActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'editTask',
      title: 'Задание (редактирование)',
      action: 'onEditTask',
      isDisplayed:
        displayOptions.isTableItem || displayOptions.isHeaderItem || displayOptions.isTreeItem,
      hasPermissionToSee: permissions.results_olympiadresult_olympiad_results.can_edit,
    },
    {
      name: 'previewTask',
      title: 'Задание (предпросмотр)',
      action: 'onPreviewTask',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.assignments_olympiadtag_olympiad_tag.can_view,
    },
    {
      name: 'compareAnswers',
      title: 'Сравнить ответы',
      action: 'onCompareAnswers',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
