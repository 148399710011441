








import Vue from 'vue'
import ResizableBlock from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/resizable/ResizableBlock.vue'
import {
  CreatedElementBoundingType,
  ResizableBlockBounding,
} from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/types'
import {
  disableDocumentSelection,
  enableDocumentSelection,
} from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/resizable/document-selection'

const minimumSize = 50

export default Vue.extend({
  name: `ResizableCreator`,
  components: { ResizableBlock },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    scale: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    mouseCoordinates: {
      start: {
        x: 0,
        y: 0,
      },
      end: {
        x: 0,
        y: 0,
      },
    },
    isElementResizing: false,
    wasElementResized: false,
    relativeElementBounding: {} as DOMRect,
    selectionAreaBounding: {} as DOMRect,
  }),
  computed: {
    resizableBlockBounding(): ResizableBlockBounding {
      const computeRelatedElementXDifference =
        this.relativeElementBounding.left - this.selectionAreaBounding.left
      const computeRelatedElementYDifference =
        this.relativeElementBounding.top - this.selectionAreaBounding.top
      return {
        left: `${this.createdElementLeft + computeRelatedElementXDifference}px`,
        top: `${this.createdElementTop + computeRelatedElementYDifference}px`,
        width: `${this.isElementResizing ? this.createdElementWidth : 0}px`,
        height: `${this.isElementResizing ? this.createdElementHeight : 0}px`,
      }
    },
    createdElementBounding(): CreatedElementBoundingType {
      return {
        position: {
          x: this.createdElementLeft,
          y: this.createdElementTop,
        },
        size: {
          width: this.createdElementWidth,
          height: this.createdElementHeight,
        },
      }
    },
    createdElementTop(): number {
      if (this.mouseCoordinates.start.y > this.relativeElementBounding.bottom) {
        return this.relativeElementBounding.height - this.createdElementHeight
      }
      if (this.mouseCoordinates.start.y < this.relativeElementBounding.top) {
        return 0
      }
      const valueToCenterElement =
        !this.wasElementResized && !this.isElementResizing ? this.createdElementHeight / 2 : 0
      return this.mouseCoordinates.start.y - this.relativeElementBounding.top - valueToCenterElement
    },
    createdElementLeft(): number {
      /* Если клик за правым пределом изображения тогда у элемента крайнее правое положение минус ширина,
       которая вычисляется из разницы стартовой и конечной точки mouseCoordinated */
      if (this.mouseCoordinates.start.x > this.relativeElementBounding.right) {
        return this.relativeElementBounding.width - this.createdElementWidth
      }
      // if(this.mouseCoordinates.start.x > this.relativeElementBounding.right)
      if (this.mouseCoordinates.start.x < this.relativeElementBounding.left) {
        return 0
      }
      const valueToCenterElement =
        !this.wasElementResized && !this.isElementResizing ? this.createdElementWidth / 2 : 0
      return (
        this.mouseCoordinates.start.x - this.relativeElementBounding.left - valueToCenterElement
      )
    },
    createdElementWidth(): number {
      return this.getSizeByAxis('x')
    },
    createdElementHeight(): number {
      return this.getSizeByAxis('y')
    },
  },
  methods: {
    getSizeByAxis(axis: 'x' | 'y') {
      const coordinatesDifference =
        this.mouseCoordinates.end[axis] - this.mouseCoordinates.start[axis]
      const maxSize = this.getMaxSize(axis)
      if (
        !this.isElementResizing &&
        ((this.wasElementResized && coordinatesDifference < minimumSize) || !this.wasElementResized)
      ) {
        return minimumSize
      }
      if (coordinatesDifference > maxSize) {
        return maxSize
      }
      if (coordinatesDifference < 0) {
        return 0
      }
      return coordinatesDifference
    },
    getMaxSize(axis: 'x' | 'y') {
      /* Если выделение происходит внутри изображения,
       значит максимальная ширина равна от точки старта до конца изображения  */
      if (axis === 'y') {
        if (
          this.mouseCoordinates.start[axis] > this.relativeElementBounding.bottom ||
          this.mouseCoordinates.start[axis] < this.relativeElementBounding.top
        ) {
          return this.relativeElementBounding.width
        }
        return this.relativeElementBounding.bottom - this.mouseCoordinates.start[axis]
      }

      if (
        this.mouseCoordinates.start[axis] > this.relativeElementBounding.right ||
        this.mouseCoordinates.start[axis] < this.relativeElementBounding.left
      ) {
        return this.relativeElementBounding.width
      }
      return this.relativeElementBounding.right - this.mouseCoordinates.start[axis]
    },
    reset() {
      this.wasElementResized = false
    },
    initResizing() {
      const createResizer = (parentEl: HTMLElement) => {
        const resize = (e: MouseEvent) => {
          if (this.disabled) {
            return
          }
          this.isElementResizing = true
          this.mouseCoordinates.end.x = e.pageX
          this.mouseCoordinates.end.y = e.pageY
        }

        const stop = () => {
          if (this.disabled) {
            return
          }
          enableDocumentSelection()

          window.removeEventListener('mousemove', resize)
          window.removeEventListener('mouseup', stop)
          if (this.isElementResizing) {
            this.isElementResizing = false
            this.wasElementResized = true
          }
          this.$emit('created', this.createdElementBounding)

          this.reset()
        }

        const start = (e: MouseEvent) => {
          if (this.disabled) {
            return
          }
          disableDocumentSelection()

          e.stopPropagation()
          e.preventDefault()
          this.setRelativeElementBounding()
          this.setSelectionAreaBounding()
          this.mouseCoordinates.start.x = e.pageX
          this.mouseCoordinates.start.y = e.pageY

          window.addEventListener('mousemove', resize)
          window.addEventListener('mouseup', stop)
        }

        parentEl.addEventListener('mousedown', start)

        this.$nextTick(() => {
          const backgroundImageWrapper = document.getElementById('backgroundImageWrapper')
          backgroundImageWrapper?.addEventListener('click', this.stopPropagation)
        })
      }

      if (this.$el.parentNode) {
        createResizer(this.$el.parentNode as HTMLElement)
      }
    },
    setRelativeElementBounding() {
      const relativeElement = this.$parent.$refs.backgroundImageWrapper as HTMLElement
      this.relativeElementBounding = relativeElement.getBoundingClientRect()
    },
    setSelectionAreaBounding() {
      const selectionAreaElement = this.$el.parentNode as HTMLElement
      this.selectionAreaBounding = selectionAreaElement.getBoundingClientRect()
    },
    stopPropagation(e: MouseEvent) {
      e.stopPropagation()
      e.preventDefault()
    },
  },
  mounted() {
    this.initResizing()
  },
})
