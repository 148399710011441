import { attach, createEvent, forward, restore } from 'effector-root'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { coursesFilters } from '@/pages/learning/learning-courses/list/parts/filter/course-filter.model'
import { createStore } from 'effector'
import { TableField } from '@/pages/applications/types'
import { coursesLearningDataFields } from '@/pages/learning/learning-courses/constants'
import {
  deleteCourseBulkFx,
  requestDeleteCourseBulkFx,
} from '@/features/api/learning/courses/delete-courses-bulk'
import { getCoursesListFx } from '@/features/api/learning/courses/get-courses-list'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { exportCoursesListFx } from '@/features/api/learning/courses/export-courses-list'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import fileDownload from 'js-file-download'

type SetColumnsColumns = {
  key?: boolean
}

export const deleteCourse = attach({
  effect: deleteCourseBulkFx,
})

export const requestDeleteCourse = attach({
  effect: requestDeleteCourseBulkFx,
})

export const incomingCoursesPageParams = createPageParamsModel()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore<boolean>(setIsLoading, false)

forward({
  from: getCoursesListFx.pending,
  to: setIsLoading,
})

export const canRefreshTableChanged = createEvent<boolean>()
export const $canRefreshTable = restore(canRefreshTableChanged, false)

export const setNewTableFields = createEvent<SetColumnsColumns>()
export const setDefaultTableFields = createEvent<void>()
export const $tableFields = createStore<TableField[]>(coursesLearningDataFields)
  .on(setNewTableFields, (fields, settings) =>
    coursesLearningDataFields.filter((field) => settings[field.name])
  )
  .reset(setDefaultTableFields)

forward({
  from: deleteCourse.doneData,
  to: [
    successToastEvent('Курс(ы) был(и) успешно удалены'),
    canRefreshTableChanged.prepend(() => true),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
  ],
})

forward({
  from: requestDeleteCourse.doneData,
  to: [
    canRefreshTableChanged.prepend(() => true),
    successToastEvent('Отправлена заявка на удаление'),
    requestDeleteModalVisibilityChanged.prepend(() => false),
  ],
})

export const exportCoursesList = attach({
  effect: exportCoursesListFx,
  source: [coursesFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportCoursesList.doneData.watch((res) => fileDownload(res.body, 'coursesList.xlsx'))
