import { createEvent, createStore, guard } from 'effector-root'
import { resetFields } from '@/pages/exams/full-appointments/create/model/form-fileds.model'
import { getFullAppointmentIdFx } from '@/features/api/full-appointments/get-appointment-dy-id.ts'

export const changeFullAppointmentsId = createEvent<string | never>()

export const $editedFullAppointmentId = createStore<string>('')
  .on(changeFullAppointmentsId, (state, payload) => payload || state)
  .reset(resetFields)

guard({
  source: changeFullAppointmentsId,
  filter: (payload) => !!payload,
  target: getFullAppointmentIdFx,
})
