






























































































import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import Icon from '@/ui/icon/Icon.vue'
import Draggable from 'vuedraggable'
import {
  $loadPartText,
  setLoadPartText,
  $loadDurMin,
  $isLoadPart,
  setIsLoadPart,
  setLoadDurationMin,
  $uploadFilesBlock,
  changeUploadBlock,
  addUploadBlock,
  deleteUploadBlock,
  changeUploadBlocksPosition,
} from '@/pages/exams/full-time-exam-test/create/model/form-fields.model'
import { ElementTypeNames } from '@/pages/learning/learning-lessons/create/model/types'

export default Vue.extend({
  name: 'UploadFilesTab',
  components: {
    FormInput,
    BaseSwitch,
    BaseButton,
    Icon,
    Wysiwyg,
    Draggable,
  },
  effector: {
    $loadPartText,
    $loadDurMin,
    $isLoadPart,
    $uploadFilesBlock,
  },
  data: () => ({
    answerBlockInfo: 'Текстовое описание отображается в интерфейсе ученика',
    CMSBlockInfo: 'Комментарий отображается только в CMS',
    blockType: ElementTypeNames.answer_file_field,
  }),
  methods: {
    setLoadDurationMin,
    setIsLoadPart,
    addUploadBlock,
    deleteUploadBlock,
    changeUploadBlock,
    setLoadPartText,
    changeUploadBlocksPosition,
    handleChange(e: { moved: { oldIndex: number; newIndex: number } }) {
      changeUploadBlocksPosition(e.moved)
    },
  },
})
