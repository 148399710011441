import { createApiEffect } from '@/features/api/common/create-api-effect'
import { CreateExamAssignmentFxParams } from '@/features/api/assignment/types/types'
import { ExamAssignment } from '@/features/api/assignment/types/exam-assignments-types'

export const createExamAssignmentFx = createApiEffect<CreateExamAssignmentFxParams, ExamAssignment>(
  {
    requestMapper: (body) => ({
      method: 'POST',
      url: '/api/assignment-app/exam-assignment/',
      body,
    }),
  }
)
