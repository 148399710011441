import { classesDropdownModule } from '@/pages/applications/incoming/parts/filter/parts/classes-dropdown/classes-dropdown.model'
import { statusDropdownModule } from '@/pages/applications/incoming/parts/filter/parts/status-dropdown/status-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { creatorsDropdownModel } from '@/pages/common/dropdowns/application/creators/creators-dropdown.model'
import { moderatorsDropdownModel } from '@/pages/common/dropdowns/application/moderators/moderators-dropdown.model'

export const dropdownModules = {
  classesDropdownModule,
  statusDropdownModule,
  subjectsDropdownModel,
  creatorsDropdownModel,
  moderatorsDropdownModel,
}
