import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import {
  CertificatesGenerator,
  GetCertificatesGeneratorListQueryParams,
} from '@/features/api/results/certificates-generator/types'

export const getCertificatesGeneratorListFX = createApiEffect<
  GetCertificatesGeneratorListQueryParams,
  TableDataResponse<CertificatesGenerator[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/certificate-app/certificates/list/',
    query,
  }),
})
