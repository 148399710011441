import { createEvent, forward, sample } from 'effector-root'
import { getStudentsListFx } from '@/features/api/student/get-student-list'
import { Student } from '@/features/api/student/types'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'
import { GetListQueryParams } from '@/features/api/types'
import { debounce } from 'patronum'

export const studentsDropdownModel = createDropdownMultiselectModel<Student>(getStudentsListFx)

export const loadStudents = createEvent<void>()
export const searchStudents = createEvent<void>()

sample({
  clock: searchStudents,
  source: {
    search: studentsDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search && { search: params.search, search_area: 'search_student' }),
  }),
  target: getStudentsListFx,
})

sample({
  clock: loadStudents,
  source: {
    $nextPage: studentsDropdownModel.store.$nextPage,
    search: studentsDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search, search_area: 'search_student' }),
  }),
  target: getStudentsListFx,
})

const debounced = debounce({
  source: studentsDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchStudents,
})

forward({
  from: studentsDropdownModel.methods.canLoadNextPage,
  to: loadStudents,
})

sample({
  clock: getStudentsListFx.doneData,
  source: {
    items: studentsDropdownModel.store.$items,
    $nextPage: studentsDropdownModel.store.$nextPage,
    search: studentsDropdownModel.store.$searchString,
  },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((user) => ({
      name: `${user.id}`,
      title: `${user.elk_id !== null ? `${user.elk_id} ` : ''}(${user.id}) ${user.full_name}`,
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: studentsDropdownModel.store.$items,
})
