



















import Vue from 'vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import {
  $switchers,
  toggleSwitchers,
} from '@/pages/bank/lesson-tasks/list/parts/modals/tasks-update/parts/switchers/swichers.model'

export default Vue.extend({
  components: {
    BaseSwitch,
  },
  effector: {
    $switchers,
  },
  methods: {
    toggleSwitchers,
  },
})
