

























































































































import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Modal from '@/ui/modal/Modal.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import {
  $attemptsIds,
  $attemptsIdsErrorModule,
  $modalVisibility,
  cancelForm,
  modalVisibilityChanged,
  submitForm,
  $isDisabledAProceed,
  $attemptsAvailableFrom,
  $attemptsAvailableTo,
  setAttemptsAvailableTo,
  setAttemptsAvailableFrom,
  attemptsIdsChanged,
  $proctoringActivated,
  $proctoringDeActivated,
  setProctoringActivated,
  setProctoringDeActivated,
  $isNoValidTime,
  $isNoValidProctor,
  $creditAttempt,
  setCreditAttempt,
  $noCreditAttempt,
  setNoCreditAttempt,
} from '@/pages/exams/full-attempts/list/modals/attempts-update/full-attempts-update-modal.model'
import ProctorsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/ProctorsFilterDropdown.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import Icon from '@/ui/icon/Icon.vue'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ApplicationsCoursesUpdateModal',
  components: {
    Modal,
    BaseTextarea,
    BaseButton,
    DatePicker,
    BaseSwitch,
    ProctorsFilterDropdown,
    Icon,
  },
  effector: {
    $modalVisibility,
    $attemptsIds,
    idsError: $attemptsIdsErrorModule.store.$error,
    $attemptsAvailableFrom,
    $attemptsAvailableTo,
    $isDisabledAProceed,
    $proctoringActivated,
    $proctoringDeActivated,
    $isNoValidTime,
    $isNoValidProctor,
    $creditAttempt,
    $noCreditAttempt,
  },
  data() {
    return {
      showDateError: true,
      showProctorError: true,
    }
  },
  methods: {
    attemptsIdsChanged,
    setAttemptsAvailableTo,
    setAttemptsAvailableFrom,
    modalVisibilityChanged,
    setProctoringActivated,
    setProctoringDeActivated,
    setCreditAttempt,
    setNoCreditAttempt,
    submitForm,
    cancelForm,
    setActivated(value: boolean) {
      if (value && !this.$proctoringDeActivated) {
        this.setProctoringActivated(true)
      } else {
        this.setProctoringActivated(false)
      }
    },
    setDeActivated(value: boolean) {
      if (value && !this.$proctoringActivated) {
        this.setProctoringDeActivated(true)
      } else {
        this.setProctoringDeActivated(false)
      }
    },
    handleCreditAttempt(value: boolean) {
      if (value && !this.$noCreditAttempt) {
        this.setCreditAttempt(true)
      } else {
        this.setCreditAttempt(false)
      }
    },
    handleNoCreditAttempt(value: boolean) {
      if (value && !this.$creditAttempt) {
        this.setNoCreditAttempt(true)
      } else {
        this.setNoCreditAttempt(false)
      }
    },
  },
})
