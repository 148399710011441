import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams } from '@/features/api/types'
import { Groups } from '@/features/api/learning/types'

export const getGroupsListFx = createApiEffect<GetListQueryParams, Groups[]>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/user-app/group/filter/',
    query,
  }),
})
