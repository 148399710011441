import { createEvent, restore, forward } from 'effector-root'
import { every } from 'patronum'
import { $selectedTheme } from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { dropdownModules } from '@/pages/bank/olympiad-tasks/list/parts/tasks-filter/parts/dropdown-modules'
import { reset } from '@/pages/common/general-filter/general-filter.model'

export const olympiadTasksFilters = createFiltersModel({}, dropdownModules)

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

export const $canSetTags = every({
  predicate: (value) => value !== null,
  stores: [$selectedTheme],
})

forward({
  from: [
    dropdownModules.classesDropdownModule.methods.itemChanged,
    dropdownModules.classesDropdownModule.methods.resetItem,
    dropdownModules.subjectsDropdownModel.methods.itemChanged,
    dropdownModules.subjectsDropdownModel.methods.resetItem,
  ],
  to: [
    dropdownModules.tagsDropdownModel.methods.resetItem,
    olympiadTasksFilters.methods.removeParamsFilter.prepend(() => 'tags'),
  ],
})
