
















import Vue from 'vue'
import EditStudentHeader from '@/pages/users/students/edit/parts/EditStudentHeader.vue'
import EditStudentFooter from '@/pages/users/students/edit/parts/EditStudentFooter.vue'
import EditStudentContent from '@/pages/users/students/edit/parts/EditStudentContent.vue'
import {
  changeStudentId,
  saveStudentInfo,
  setRedirectAfterSave,
} from '@/pages/users/students/edit/model/edit-student.model'
import {
  $disabledSaveButtons,
  resetFields,
} from '@/pages/users/students/edit/model/form-fields.model'
import { updateStudentByIdFx } from '@/features/api/student/update-student-by-id'

export default Vue.extend({
  name: 'EditStudentPage',
  components: {
    EditStudentHeader,
    EditStudentFooter,
    EditStudentContent,
  },
  effector: {
    $disabledSaveButtons,
    isLoading: updateStudentByIdFx.pending,
  },
  methods: {
    saveTask(isRedirect: boolean) {
      if (isRedirect) {
        setRedirectAfterSave(true)
        saveStudentInfo()
      } else {
        saveStudentInfo()
      }
    },
  },
  created() {
    changeStudentId(this.$route.params.id)
  },
  beforeDestroy() {
    resetFields()
  },
})
