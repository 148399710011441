import { createEvent, createStore, forward, restore, attach, combine, sample } from 'effector-root'
import { uploadMediaFx } from '@/features/api/media/upload-media'
import { $audioFiles, getAudioFilesFx } from '@/pages/common/parts/audio-files/audio-files.model'
import { BaseAssignment } from '@/features/api/assignment/types/types'
import { parseTemplate } from '@/pages/common/parts/tasks/color-highlight-answer/color-highlight-template-parsers'

export const uploadMedia = attach({
  effect: uploadMediaFx,
})

export const clearFields = createEvent<void>()

export const setWording = createEvent<string>()
export const $wording = restore(setWording, '').reset(clearFields)

export const setContaining = createEvent<string>()
export const $containing = restore(setContaining, '').reset(clearFields)

export const setAnswerExample = createEvent<string>()
export const $answerExample = restore(setAnswerExample, '').reset(clearFields)

export const setTextTemplate = createEvent<string>()
export const $textTemplate = restore(setTextTemplate, '').reset(clearFields)

const $parsedText = createStore<string>('')
const $rightAnswer = createStore<string>('')

export const $parsedTemplate = $textTemplate.map((state) => parseTemplate(state))

sample({
  source: $parsedTemplate,
  fn: (source) => source.$parsedText,
  target: $parsedText,
})

sample({
  source: $parsedTemplate,
  fn: (source) => source.$rightAnswer,
  target: $rightAnswer,
})

export const togglePopover = createEvent<boolean>()
export const $popover = restore(togglePopover, false).reset(clearFields)

export const toggleContextMenu = createEvent<boolean>()
export const $contextMenu = restore(toggleContextMenu, false).reset(clearFields)

export const setColorsPalette = createEvent<string[]>()
export const $colorsPalette = restore(setColorsPalette, []).reset(clearFields)

export const $isFilled = combine(
  $wording,
  $textTemplate,
  $colorsPalette,
  (wording, textTemplate, colorsPalette) =>
    wording && colorsPalette.length && colorsPalette.some((color) => textTemplate.includes(color))
)

export const $form = combine(
  $wording,
  $answerExample,
  $containing,
  $audioFiles,
  $textTemplate,
  $parsedText,
  $rightAnswer,
  $colorsPalette,
  (wording, example_answer, containing, audio, text, parsedText, rightAnswer, colors) => ({
    wording,
    example_answer,
    text: containing,
    question_data: { parsedText, colors },
    correct_answer: { text, rightAnswer },
    audio: audio.map(({ id, isLimited, limit }) => ({
      id,
      isLimited,
      limit,
    })),
  })
)

export const initAssignment = createEvent<BaseAssignment>()

function replaceSpanSpacesWithSpecialCharacter(string: string) {
  /* https://dev.ckeditor.com/ticket/6876 */
  /* needed for imported and old tasks */
  return string.replace(/(<span[^>]*>)([^<]*)(<\/span>)/g, (_, openingTag, content, closingTag) => {
    const replacedContent = content.replace(/ /g, '&nbsp;')
    return `${openingTag}${replacedContent}${closingTag}`
  })
}

forward({
  from: initAssignment,
  to: [
    setWording.prepend((data) => data.wording || ''),
    setContaining.prepend((data) => data.text || ''),
    setAnswerExample.prepend((data) => data.example_answer || ''),
    setTextTemplate.prepend((data) =>
      replaceSpanSpacesWithSpecialCharacter(data.correct_answer.text)
    ),
    setColorsPalette.prepend((data) => data.question_data.colors),
    getAudioFilesFx.prepend(({ audios }) => audios),
  ],
})
