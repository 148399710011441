import { createEvent, restore, forward } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { foldersDropdownModule } from '@/pages/common/dropdowns/exams/exams-tests-folders/folder-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/classes-filter-dropdown.model'
import { tasksChoiceDropdownModel } from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/tasks-choice-dropdown.model'

const dropdownModules = {
  classesDropdownModel,
  foldersDropdownModule,
  subjectsDropdownModel,
  tasksChoiceDropdownModel,
}

export const examTestsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
    is_active: false,
  },
  dropdownModules
)
export const reset = createEvent<void>()

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

forward({
  from: reset,
  to: [
    tasksChoiceDropdownModel.methods.resetDropdown,
    foldersDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    classesDropdownModel.methods.resetDropdown,
  ],
})
