













import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  $courseType,
  courseTypeDropdownModule,
} from '@/pages/common/dropdowns/courses/course-type-filter/coursesType.model'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false,
    },
  },
  effector: {
    $courseType,
    ...courseTypeDropdownModule.store,
  },
  methods: {
    ...courseTypeDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      if (!item || item.name === 'Показать все') {
        this.$emit('setItem', null)
      } else {
        this.$emit('setItem', item.name === 'С заявкой')
      }
    },
  },
})
