import dayjs from 'dayjs'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import { mapTaskStatus } from '@/pages/common/parts/status-controller/constants'
import { EmployeePermissions } from '@/features/api/employee/types'
import { ActionsDisplayConditions } from '@/pages/common'

export const lessonsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID задания',
    width: '130px',
  },
  {
    name: 'course',
    title: 'Курс',
    width: '130px',
    formatter(data: string) {
      return data || '–'
    },
  },
  {
    name: 'folder',
    title: 'Расположение',
    width: '130px',
    formatter(data: { name: string; id: number }) {
      return data.name || '–'
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус',
    width: '130px',
    formatter(status: any) {
      return mapTaskStatus[status] || '-'
    },
  },
  {
    name: 'wording',
    sortField: 'wording',
    title: 'Формулировка задания',
    width: '220px',
    formatter(data: string) {
      return data || '–'
    },
  },
  {
    name: 'type',
    sortField: 'type',
    title: 'Тип задания',
    width: '150px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(icon: string) {
      return icon
    },
  },
  {
    name: 'score',
    sortField: 'score',
    title: 'Баллы',
    width: '100px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(text: string) {
      return text || '-'
    },
  },
  {
    name: 'interface_language',
    sortField: 'interface_language',
    title: 'Язык',
    width: '80px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(text: string) {
      return text || '-'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    width: '200px',
    formatter: (data) => {
      return data && data.first_name && data.last_name
        ? `${data.first_name} ${data.last_name}`
        : '–'
    },
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '130px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Обновлено',
    width: '130px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Посл. изменение',
    width: '180px',
    formatter: (data) => {
      return data && data.first_name && data.last_name
        ? `${data.first_name} ${data.last_name}`
        : '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'id Задания' },
  { name: 'wording', title: 'Формулировка' },
  { name: 'score', title: 'Баллы' },
  { name: 'created_by', title: 'Создатель' },
  { name: 'course', title: 'Курс' },
  { name: 'folder', title: 'Расположение' },
]

export const getLessonsTasksActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'editTask',
      title: 'Редактирование',
      headerTitle: 'Редактировать',
      action: 'onEditTask',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isTreeItem ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.assignments_lessonassignment_assignments.can_edit,
    },
    {
      name: 'deleteTask',
      title: displayOptions.isTableItems ? 'Удалить выделенные задания' : 'Удалить задание',
      headerTitle: 'Удалить',
      action: 'onDeleteTask',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isTreeItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.assignments_lessonassignment_assignments.can_edit,
    },
    {
      name: 'createFolder',
      title: 'Создать папку',
      action: 'onCreateFolder',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: permissions.assignments_lessonassignment_assignments.can_edit,
    },
    {
      name: 'createTask',
      title: 'Создать задание',
      action: 'onCreateTask',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: permissions.assignments_lessonassignment_assignments.can_edit,
    },
    {
      name: 'editFolder',
      title: 'Редактировать',
      action: 'onEditFolder',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: permissions.assignments_lessonassignment_assignments.can_edit,
    },
    {
      name: 'deleteFolder',
      title: 'Удалить',
      action: 'onDeleteFolder',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: true,
    },
    {
      name: 'duplicate',
      title: 'Дублировать задание',
      action: 'onDuplicate',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.assignments_lessonassignment_assignments.can_view,
    },
    {
      name: 'duplicateNTimes',
      title: 'Дублировать задание n раз',
      action: 'onDuplicateNTimes',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.assignments_lessonassignment_assignments.can_edit,
    },
    {
      name: 'preview',
      title: 'Предпросмотр',
      action: 'onPreview',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isTreeItem ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isTreeItem,
      hasPermissionToSee: true,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
