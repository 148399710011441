import { render, staticRenderFns } from "./MovingImagesTextInputAnswer.vue?vue&type=template&id=7ecc486b&scoped=true&"
import script from "./MovingImagesTextInputAnswer.vue?vue&type=script&lang=ts&"
export * from "./MovingImagesTextInputAnswer.vue?vue&type=script&lang=ts&"
import style0 from "./MovingImagesTextInputAnswer.vue?vue&type=style&index=0&id=7ecc486b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ecc486b",
  null
  
)

export default component.exports