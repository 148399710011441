<template>
  <div class='footer'>
    <BaseButton
      class="btn"
      yellow
      :disabled="$props.disabled"
      @click="$emit('saveAndBackToList')"
    >
      Сохранить и вернуться к списку
    </BaseButton>
    <BaseButton
      class="btn"
      yellow
      :disabled="$props.disabled"
      @click="$emit('save')"
    >
      Сохранить
    </BaseButton>
    <span class="label">{{ countLabel }}</span>
  </div>
</template>

<script>
import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { $selectedThemes } from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'
import { $tasksBlock } from '@/pages/common/parts/tests/manual-generation/manual-generation-form.model'
import { typeDropdownModule } from '@/pages/common/dropdowns/testing/type-dropdown/type-dropdown.model'

export default Vue.extend({
  name: 'TestFooter',
  components: {
    BaseButton,
  },
  effector: {
    $selectedThemes,
    $tasksBlock,
    typeTestItem: typeDropdownModule.store.$item,
  },
  props: {
    title: { type: String, default: '' },
    disabled: { type: Boolean, default: true },
  },
  computed: {
    countLabel() {
      return `${
        this.typeTestItem && this.typeTestItem.name === '0'
          ? 'Количество тем в тесте'
          : 'Количество заданий в тесте'
      }: ${
        this.typeTestItem && this.typeTestItem.name === '0'
          ? this.$selectedThemes.length
          : this.$tasksBlock.length
      }`
    },
  },
})
</script>

<style scoped>
.footer {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background-color: #fff;
  border-radius: 3px;
  margin: 20px 0;
}
.title {
  display: flex;
  align-items: center;
  color: var(--base-text-primary);
  font-weight: bold;
  font-size: 20px;
  line-height: 18px;
}
.btn {
  margin-right: 20px;
}
</style>
