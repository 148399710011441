






































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { $selectedIds } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import CoursesDropdown from '@/pages/learning/learning-lessons/list/parts/CoursesDropdown.vue'
import {
  changeVisibility,
  $modalVisibility,
} from '@/pages/learning/learning-lessons/list/model/add-lessons-to-course.model'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'AddLessonsToCourseModal',
  components: {
    Modal,
    CoursesDropdown,
    BaseButton,
    Icon,
  },
  effector: {
    $selectedIds,
    $modalVisibility,
  },
  methods: {
    changeVisibility,
  },
})
