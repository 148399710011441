import StudentsDropdown from '@/pages/results/tests-students/parts/filter/parts/students-tests-result/StudentsDropdown.vue'
import DifficultyDropdown from '@/pages/common/dropdowns/testing/difficulty-dropdown/DifficultyDropdown.vue'
import TestsListDropdown from '@/pages/results/tests-students/parts/filter/parts/tests-result-by-student/TestsListDropdown.vue'
import TestsAttemptDropdown from '@/pages/results/tests-students/parts/filter/parts/tests-attempt/TestsAttemptDropdown.vue'

export const dropdownResultCoursesComponents = {
  StudentsDropdown,
  DifficultyDropdown,
  TestsListDropdown,
  TestsAttemptDropdown,
}
