import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import dayjs from 'dayjs'
import { createError } from '@/lib/effector/error-generator'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import { condition } from 'patronum'
import { AttemptsUpdateForm, UpdateAttemptsBulkFailResponse } from '@/features/api/attempts/types'
import { attemptsStatusDropdownModule } from '@/pages/common/dropdowns/exams/attempts-status-dropdown/attempts-status-dropdown.model'
import { updateAttemptsBulkFx } from '@/features/api/attempts/attempts-bulk'
import { proctorsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/proctors-filter-dropdown.model'
import get_ids_for_string from '@/lib/utils/get_ids_for_string'

const makeMultiChanges = attach({
  effect: updateAttemptsBulkFx,
})
export const loadModalForMultiChanges = createEvent<number[]>()

export const modalVisibilityChanged = createEvent<boolean>()
export const $modalVisibility = restore(modalVisibilityChanged, false)

export const canRefreshAfterMultiChangesChanged = createEvent<boolean>()
export const $canRefreshAfterMultiChanges = restore<boolean>(
  canRefreshAfterMultiChangesChanged,
  false
)

export const submitForm = createEvent<void>()
export const cancelForm = createEvent<void>()

const clearFields = createEvent<void>()

const resetField = createEvent<void>()

export const attemptsIdsChanged = createEvent<string>()
export const $attemptsIds = restore<string>(attemptsIdsChanged, '').reset(resetField)

export const setAttemptsAvailableTo = createEvent<string>()
export const $attemptsAvailableTo = restore(setAttemptsAvailableTo, '').reset(resetField)

export const setAttemptsAvailableFrom = createEvent<string>()
export const $attemptsAvailableFrom = restore(setAttemptsAvailableFrom, '').reset(resetField)

export const setProctoringActivated = createEvent<boolean>()
export const $proctoringActivated = restore(setProctoringActivated, false).reset(resetField)

export const setProctoringDeActivated = createEvent<boolean>()
export const $proctoringDeActivated = restore(setProctoringDeActivated, false).reset(resetField)

export const $isNoValidTime = combine($attemptsAvailableFrom, $attemptsAvailableTo, (from, to) => {
  if (from && to) {
    return dayjs(to).isBefore(dayjs(from))
  }
  return false
})

export const $isNoValidProctor = combine(
  $proctoringActivated,
  proctorsDropdownModel.store.$selectedItems,
  (activated, proctors) => {
    return activated ? proctors.length === 0 : false
  }
)

export const $isDisabledAProceed = combine(
  $isNoValidTime,
  $proctoringActivated,
  $proctoringDeActivated,
  $isNoValidProctor,
  (isNoValidTime, activated, deActivated, isAddProctoring) => {
    const isProctoringActivated = activated && deActivated
    return isNoValidTime || isProctoringActivated || isAddProctoring
  }
)

export const $attemptsIdsErrorModule = createError()

const canSetModeratorChanged = createEvent<boolean>()
export const $canSetModerator = restore(canSetModeratorChanged, false)

forward({
  from: loadModalForMultiChanges,
  to: [
    attemptsIdsChanged.prepend((data) => data.join(',')),
    modalVisibilityChanged.prepend(() => true),
    canRefreshAfterMultiChangesChanged.prepend(() => false),
  ],
})

forward({
  from: clearFields,
  to: [
    resetField,
    attemptsStatusDropdownModule.methods.resetDropdown,
    proctorsDropdownModel.methods.resetDropdown,
  ],
})

forward({
  from: cancelForm,
  to: [modalVisibilityChanged.prepend(() => false), clearFields],
})

sample({
  clock: submitForm,
  source: {
    $attemptsIds,
    status: attemptsStatusDropdownModule.store.$item,
    proctorsArr: proctorsDropdownModel.store.$selectedItems,
    $proctoringActivated,
    $proctoringDeActivated,
    $attemptsAvailableFrom,
    $attemptsAvailableTo,
  },
  fn: (form): AttemptsUpdateForm => {
    const params: AttemptsUpdateForm = {
      ...(form.$proctoringActivated
        ? { proctors: form.proctorsArr.map((proctor) => Number(proctor.name)) }
        : {}),
      attempts: get_ids_for_string(form.$attemptsIds),
      ...(form.status?.name && form.status?.name !== '7'
        ? { status: Number(form.status?.name || 0) }
        : {}),
      ...(form.$proctoringActivated ? { is_proctoring_activated: true } : {}),
      ...(form.$proctoringDeActivated ? { is_proctoring_activated: false } : {}),
      ...(form.$attemptsAvailableFrom ? { available_from: form.$attemptsAvailableFrom } : {}),
      ...(form.$attemptsAvailableTo ? { available_to: form.$attemptsAvailableTo } : {}),
    }
    return params
  },
  target: makeMultiChanges,
})

condition({
  source: modalVisibilityChanged,
  if: (payload: boolean) => !payload,
  then: clearFields,
})

forward({
  from: makeMultiChanges.doneData,
  to: [
    successToastEvent('Данные были успешно обновлены!'),
    modalVisibilityChanged.prepend(() => false),
    clearFields,
    canRefreshAfterMultiChangesChanged.prepend(() => true),
  ],
})

condition({
  source: makeMultiChanges.failData.map((res) => res.body),
  if: (data: UpdateAttemptsBulkFailResponse) => !!data.detail,
  then: addToast.prepend((data: UpdateAttemptsBulkFailResponse) => ({
    type: 'error',
    message: data.detail!,
  })),
  else: addToast.prepend((data: UpdateAttemptsBulkFailResponse) => {
    let message = Array.isArray(data) && data.length > 0 ? data[0] || data : 'Неизвестная ошибка'
    if ('attempts' in data && data.attempts && data.attempts.length > 0) {
      message = data.attempts
    }
    return {
      type: 'error',
      message,
    }
  }),
})
