



































































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import Draggable from 'vuedraggable'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import {
  $selectedThemes,
  setSelectedThemes,
  showErrorAddThemes,
  themesDropdownModule,
} from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'
import { DropdownItem } from '@/pages/common/types'
import ThemeSelectItemRecursive from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/ThemeSelectItemRecursive.vue'
import { $difficulty } from '@/pages/common/parts/tests/automatic-generation/automatic-generation-form.model'
import { config } from '@/config'
import { axiosClient } from '@/lib/request'

export default Vue.extend({
  components: {
    Icon,
    BaseDropdown,
    Draggable,
    SelectItem,
    ThemeSelectItemRecursive,
  },
  props: {
    disabled: { type: Boolean as PropType<boolean> },
    isModal: { type: Boolean as PropType<boolean>, default: false },
  },
  effector: {
    $selectedThemes,
    ...themesDropdownModule.store,
    $difficulty,
  },
  computed: {
    correctValue(): string {
      if (this.isModal) {
        return this.$selectedThemes.length > 0 ? this.$selectedThemes[0].title : ''
      }
      const currentItem = this.$item?.title
      return currentItem || this.$searchString
    },
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/test-app/test/check-autotest-theme/`
    },
  },
  methods: {
    ...themesDropdownModule.methods,
    itemsReorder(items: DropdownItem[]) {
      return items.map((item, index) => ({
        ...item,
        id: index,
      }))
    },
    async onSelectItem(item: DropdownItem) {
      try {
        if (item) {
          if (this.isModal) {
            setSelectedThemes([item])
            this.searchStringChanged('')
            // postAction()
          } else {
            const existedItem = this.$selectedThemes.find(
              (label: DropdownItem) => label.name === item.name
            )
            if (existedItem) {
              const getAssignmentCount = await axiosClient.get(this.apiUrl, {
                params: { theme_id: item.name, difficulty: this.$difficulty },
              })
              if (getAssignmentCount.data.is_available) {
                let themes = this.$selectedThemes.filter(
                  (label: DropdownItem) => label.name !== item.name
                )
                themes = this.itemsReorder(themes)
                setSelectedThemes(themes)
                // postAction()
              }
              if (!getAssignmentCount.data.is_available) {
                showErrorAddThemes()
              }
            } else {
              const themes = this.itemsReorder([...this.$selectedThemes, item])
              setSelectedThemes(themes)
              // postAction()
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    onRemoveItem(item: DropdownItem) {
      const themes = this.$selectedThemes.filter((label: DropdownItem) => label.name !== item.name)
      setSelectedThemes(themes)
    },
    handlerEnd() {
      const themes = this.$selectedThemes.map((theme, id) => ({ ...theme, id }))
      setSelectedThemes(themes)
    },
    clear() {
      this.resetItem()
      this.resetSearchString()
    },
  },
})
