












import Vue from 'vue'
import { DropdownItem } from '@/pages/common/types'
import {
  pointVcDropdownModel,
  setPointsData,
} from '@/pages/common/dropdowns/value-change-dropdowns/point-dropdown/point-vc-dropdown.model'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'

export default Vue.extend({
  name: 'PointDropdown',
  components: {
    FilterDropdown,
  },
  effector: {
    ...pointVcDropdownModel.store,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Выберите',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
    },
    maxPoints: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      searchString: '',
    }
  },
  watch: {
    maxPoints(points) {
      setPointsData(points)
    },
  },
  methods: {
    ...pointVcDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('onChange', item)
    },
    searchStChanged(val: string) {
      this.searchString = val
      this.searchStringChanged(val)
    },
    resetSearchStr() {
      this.searchString = ''
      this.resetSearchString()
    },
  },
  mounted() {
    setPointsData(this.maxPoints)
  },
})
