

























import Vue, { PropType } from 'vue'
import TreeNode from '@/pages/bank/lesson-tasks/list/parts/lessons-tree/TreeNode.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { FiltersParams } from '@/pages/common/types'
import { $examsTree } from '@/pages/bank/exam-tasks/list/exam-tasks-page.model'

export default Vue.extend({
  name: 'ExamTasksTree',
  components: {
    TreeNode,
    NoDataContent,
  },
  props: {
    filters: { type: Object as PropType<FiltersParams> },
  },
  effector: { $examsTree },
})
