import dayjs from 'dayjs'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'
import { $currentUser } from '@/features/session'

export const LessonsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID урока',
    width: '100px',
  },
  {
    name: 'number',
    sortField: 'number',
    title: 'Номер',
    width: '100px',
  },
  {
    name: 'name',
    sortField: 'name',
    title: 'Название',
    width: '150px',
  },
  {
    name: 'subject',
    sortField: 'subject',
    title: 'Предмет',
    width: '150px',
    formatter: (data) => {
      return (data && data.name) || '–'
    },
  },
  {
    name: 'theme',
    sortField: 'theme',
    title: 'Тема',
    width: '150px',
    formatter: (data) => {
      return data && data.name ? data.name : '–'
    },
  },
  {
    name: 'course',
    sortField: 'course',
    title: 'Курс',
    width: '250px',
    formatter: (data) => {
      return (data && data.name) || '–'
    },
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '200px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    width: '130px',
    formatter(data) {
      return data ? `${data.first_name} ${data.last_name}` : '–'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Обновлено',
    width: '130px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'updated_by',
    title: 'Посл. изменение',
    width: '150px',
    formatter: (data) => {
      return data && data.first_name && data.last_name
        ? `${data.first_name} ${data.last_name}`
        : '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'id Урока' },
  { name: 'name', title: 'Название' },
  { name: 'subject', title: 'Предмет' },
  { name: 'theme', title: 'Тема' },
  { name: 'course', title: 'Курс' },
  { name: 'created_by', title: 'Создатель' },
]

export const getLessonsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const user = $currentUser.getState()
  const isDefaultShow = user?.role !== 'METHODIST'

  const actions = [
    {
      name: 'delete',
      title: 'Удалить',
      action: 'onDelete',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: isDefaultShow,
    },
    {
      name: 'preview',
      title: 'Предпросмотр',
      action: 'onPreview',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions?.lessons_lesson_lessons?.can_view,
    },
    {
      name: 'editTask',
      title: 'Редактировать',
      action: 'onEditLesson',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions?.lessons_lesson_lessons?.can_edit,
    },
    {
      name: 'addToCourse',
      title: 'Добавить к курсу',
      action: 'onAddToCourse',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: permissions?.lessons_lesson_lessons?.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
