












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { attemptsCountDropdownModel } from '@/pages/exams/full-appointments/create/parts/AttemptsCount/attempts-count-dropdown.model'
import { attemptsCountMapData } from '@/pages/exams/full-appointments/create/parts/AttemptsCount/constant'

export default Vue.extend({
  name: 'AttemptsCount',
  components: {
    FilterDropdown,
  },
  effector: {
    ...attemptsCountDropdownModel.store,
  },
  data() {
    return {
      searchString: '',
    }
  },
  methods: {
    ...attemptsCountDropdownModel.methods,
  },
  mounted() {
    if (!this.$item) {
      this.itemChanged(attemptsCountMapData[0])
    }
  },
})
