import { createApiEffect } from '@/features/api/common/create-api-effect'
import { FiltersParams } from '@/pages/common/types'
import { ResultsCoursesStudentScores } from '@/features/api/results/types'

export const getResultsCoursesStudentScores = createApiEffect<
  FiltersParams,
  ResultsCoursesStudentScores
>({
  requestMapper: (query) => {
    const { course, lesson, student } = query
    return {
      method: 'GET',
      url: `/api/results-app/results/courses/${course}/${lesson}/${student}/scores/`,
    }
  },
})
