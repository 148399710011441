import { attach, createEvent, forward, combine, createStore } from 'effector-root'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { getStudentsListFx } from '@/features/api/student/get-student-list'
import { requestDeleteStudentsFx } from '@/features/api/student/delete-students'
import { RequestDeleteStudents } from '@/pages/users/students/list/model/types'

const getStudentsList = attach({
  effect: getStudentsListFx,
  mapParams: () => ({}),
})

export const studentPageMounted = createEvent<void>()

export const $isLoading = combine(getStudentsListFx.pending, (list) => list)

export const requestDeleteStudents = attach({
  effect: requestDeleteStudentsFx,
  mapParams: (payload: RequestDeleteStudents) => {
    return {
      student_ids: payload.student_ids,
      ticket_comment: payload.ticket_comment,
    }
  },
})

const needRefresh = createEvent<boolean>()

forward({
  from: requestDeleteStudents.done,
  to: [
    getStudentsList,
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    needRefresh.prepend(() => true),
  ],
})

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(studentPageMounted)

forward({
  from: studentPageMounted,
  to: getStudentsList,
})
