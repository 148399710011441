import { createFilter } from '@/pages/common/filter-dropdown/create-filter'

export const mapAttemptsStatus = [
  {
    name: '7',
    title: 'Не менять статус',
  },
  {
    name: '0',
    title: 'Пройдена успешно',
  },
  {
    name: '1',
    title: 'Зачтена',
  },
  {
    name: '2',
    title: 'Ожидает проверки',
  },
  {
    name: '3',
    title: 'Тестирование пропущено',
  },
  {
    name: '4',
    title: 'Дисквалификация',
  },
  {
    name: '5',
    title: 'Недостаточный результат',
  },
  {
    name: '6',
    title: 'Необходимо повторное тестирование',
  },
]

export const attemptsStatusDropdownModule = createFilter()
