import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AchievementsPage from '@/pages/settings/achievements/AchievementsPage.vue'
import HobbiesPage from '@/pages/settings/hobbies/HobbiesPage.vue'

export default [
  {
    name: 'settings',
    path: '/settings',
    redirect: { name: 'achievement-page' },
    component: DefaultLayout,
    children: [
      {
        name: 'achievements',
        path: 'achievements',
        meta: {
          title: 'Достижения - Школа Летово',
        },
        redirect: { name: 'achievement-page' },
        component: DefaultLayout,
        children: [
          {
            name: 'achievement-page',
            path: 'achievement-page',
            component: AchievementsPage,
            meta: {
              title: 'Достижения - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'hobbies',
        path: 'hobbies',
        meta: {
          title: 'Кружки - Школа Летово',
        },
        redirect: { name: 'hobbies-page' },
        component: DefaultLayout,
        children: [
          {
            name: 'hobbies-page',
            path: 'hobbies-page',
            component: HobbiesPage,
            meta: {
              title: 'Кружки новости - Школа Летово',
            },
          },
        ],
      },
    ],
  },
]
