import { attach, createEffect, sample } from 'effector'
import { uploadTemplateDataFx } from '@/features/api/results/certificates-generator/upload-template-data'
import { File } from '@/pages/common/parts/tasks/types'
import { createEvent, forward, restore } from 'effector-root'
import { addToast } from '@/features/toasts/toasts.model'

export const uploadData = attach({
  effect: uploadTemplateDataFx,
})

const uploadFilesFx = createEffect({
  handler: (files: FileList | null): Promise<any[]> => {
    return Promise.all(
      Array.from(files || []).map(
        (file) =>
          new Promise<any>((resolve) => {
            const formData = new FormData()
            formData.append('file', file)
            const res = uploadData(formData).then((r) => r.body)
            resolve(res)
          })
      )
    )
  },
})

/**
 * FORM
 */
export const resetFields = createEvent()

// NAME
export const setTemplateName = createEvent<string>()
export const $templateName = restore<string>(setTemplateName, '').reset(resetFields)

// FILES
export const uploadFiles = createEvent<FileList>()
export const setFiles = createEvent<File[] | null>()
export const $files = restore(setFiles, []).reset(resetFields)
export const $tempFiles = restore<FileList>(uploadFiles, null).reset(resetFields)
export const setIsLoadFile = createEvent<null | number>()
export const $isLoadFile = restore(setIsLoadFile, null).reset(resetFields)

// ERRORS
type TAddFileDataErrors = {
  error: string
}

export const setAddFileErrors = createEvent<TAddFileDataErrors>()
export const $addFileErrors = restore(setAddFileErrors, {
  error: '',
}).reset(resetFields)

export const $isAddErrors = $addFileErrors.map((data) => {
  return !!data?.error
})

forward({
  from: uploadFiles,
  to: [
    uploadFilesFx,
    setIsLoadFile.prepend(() => null),
    setAddFileErrors.prepend(() => ({
      error: '',
    })),
    addToast.prepend(() => ({ type: 'loading', message: 'Идет загрузка файла(ов)' })),
  ],
})

forward({
  from: uploadFilesFx.doneData,
  to: [
    addToast.prepend(() => ({ type: 'success', message: 'Загрузка завершена!' })),
    setIsLoadFile.prepend((data) => {
      const { attempts } = data[0]
      if (attempts.length === 0) return 3
      return 1
    }),
  ],
})

forward({
  from: uploadFilesFx.fail,
  to: setIsLoadFile.prepend(() => 2),
})

sample({
  source: $tempFiles,
  clock: uploadFilesFx.done,
  fn: (existFiles: FileList | null) => {
    const fileArr: File[] = []
    if (existFiles) {
      Object.values(existFiles).forEach((file) => {
        fileArr.push({
          isLimited: false,
          limit: 1,
          id: 546,
          owner: 121,
          file_type: 'file',
          file: 'file.xlsx',
          file_name: file.name,
          duration_sec: 4545,
        })
      })
    }
    return fileArr
  },
  target: setFiles,
})
