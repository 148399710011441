import { createApiEffect } from '@/features/api/common/create-api-effect'
import { AchievementType } from '@/features/api/settings/types'

export const createAchievementFx = createApiEffect<AchievementType, void>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/prefs-app/achievements/',
    body,
  }),
})
