import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportTagsQueryParams } from '@/features/api/assignment/types/types'

export const exportTagsListFx = createApiEffect<ExportTagsQueryParams, File>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/assignment-app/olympiad-tags/list/export/',
    responseType: 'arraybuffer',
    query,
  }),
})
