import { createEvent, forward, restore } from 'effector-root'
import { OperableTypes } from '@/pages/common/types'

export const confirmDeleteModalVisibilityChanged = createEvent<boolean>()
export const $confirmDeleteModalVisibility = restore(confirmDeleteModalVisibilityChanged, false)

export const setDeleteType = createEvent<OperableTypes>()
export const $deleteType = restore(setDeleteType, 'task')

export const loadConfirmDeleteModal = createEvent<number[] | string[]>()
export const $selectedIds = restore(loadConfirmDeleteModal, [])

forward({
  from: loadConfirmDeleteModal,
  to: confirmDeleteModalVisibilityChanged.prepend(() => true),
})
