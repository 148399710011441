











































import Vue, { PropType } from 'vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseCheckbox from '@/ui/checkbox/BaseCheckbox.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import {
  $exportPopupVisibility,
  changeExportPopupVisibility,
  $allSelected,
  toggleSelectAll,
  changeTestAssignmentsExportColumns,
  uncheckAll,
  $exportColumns,
  $exportColumnsNames,
  initExportPopupStores,
  exportPopupDestroy,
} from '@/pages/common/parts/header/header-popup/header-popup.model'

const ClickOutside = require('vue-click-outside')

export default Vue.extend({
  name: 'HeaderPopup',
  components: {
    BaseSwitch,
    BaseCheckbox,
    BaseButton,
  },
  directives: {
    ClickOutside,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    visibility: { type: Boolean as PropType<boolean> },
    disabled: { type: Boolean as PropType<boolean>, default: false },
  },
  effector: {
    $exportPopupVisibility,
    $allSelected,
    $exportColumns,
    $exportColumnsNames,
  },
  methods: {
    toggleSelectAll,
    changeTestAssignmentsExportColumns,
    uncheckAll,
    clickOutside(evt: any) {
      if (evt.target.closest('#btn-download')) return
      changeExportPopupVisibility(false)
    },
    handleExport() {
      this.$emit('onExport')
      changeExportPopupVisibility(false)
    },
  },
  mounted() {
    initExportPopupStores(this.tableColumns)
  },
  beforeDestroy() {
    exportPopupDestroy()
  },
})
