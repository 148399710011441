














































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { examAssignmentEditFolderModal } from '@/pages/common/modals/tasks-bank/editing-folder/editing-folder-modal.model'
import FolderDropdown from '@/pages/common/dropdowns/bank/exam-tasks/folder-dropdown/FolderDropdown.vue'

export default Vue.extend({
  name: 'EditingExamFolderModal',
  components: {
    Modal,
    Icon,
    FormInput,
    BaseButton,
    FolderDropdown,
  },
  effector: {
    $modalVisibility: examAssignmentEditFolderModal.store.$modalVisibility,
    titleError: examAssignmentEditFolderModal.store.$titleErrorModule.store.$error,
    $folderTitle: examAssignmentEditFolderModal.store.$folderTitle,
  },
  methods: {
    modalVisibilityChanged: examAssignmentEditFolderModal.methods.modalVisibilityChanged,
    checkIfFolderCanBeSend: examAssignmentEditFolderModal.methods.checkIfFolderCanBeSend,
    folderTitleChanged: examAssignmentEditFolderModal.methods.folderTitleChanged,
  },
})
