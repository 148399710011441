







































import Vue from 'vue'
import FormLabel from '@/ui/label/FormLabel.vue'
import {
  $inputs,
  replaceInput,
  removeInput,
} from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'
import AttachingTextItem from './AttachingTextItem.vue'

export default Vue.extend({
  name: `AreasForAttachingText`,
  components: {
    FormLabel,
    AttachingTextItem,
  },
  effector: {
    $inputs,
  },
  methods: {
    replaceInput,
    removeInput,
  },
})
