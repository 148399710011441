










































































import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import CustomFileInputExams from '@/ui/custom-file-input-exams/CustomFileInputExams.vue'
import FormInput from '@/ui/input/FormInput.vue'
import {
  $addFileErrors,
  $files,
  $isAddErrors,
  $isLoadFile,
  $templateName,
  setTemplateName,
  setAddFileErrors,
  setFiles,
  setIsLoadFile,
  uploadFiles,
} from '@/pages/results/certificates-generator/create-template/model/form-fields.model'

export default Vue.extend({
  name: 'CreateCertificatesTemplateForm',
  components: { FormInput, CustomFileInputExams, Icon },
  effector: {
    $files,
    $isLoadFile,
    $addFileErrors,
    $isAddErrors,
    $templateName,
  },
  methods: {
    setFiles,
    uploadFiles,
    setIsLoadFile,
    setTemplateName,
    closeErrors() {
      setAddFileErrors({
        error: '',
      })
    },
  },
})
