
















import Vue, { PropType } from 'vue'
import { NavItemChild } from '@/pages/common/navigation/types'
import {
  $incomingApplicationsCounter,
  $incomingDeletionApplicationsCounter,
  $outcomeModerationApplicationsCounter,
  $outcomeDeletionApplicationsCounter,
} from '@/pages/common/navigation/navigation.model'
import { $routesPermissions } from '@/features/session'

export default Vue.extend({
  name: 'NavChild',
  props: {
    item: { type: Object as PropType<NavItemChild>, required: true },
  },
  effector: {
    $incomingApplicationsCounter,
    $incomingDeletionApplicationsCounter,
    $outcomeModerationApplicationsCounter,
    $outcomeDeletionApplicationsCounter,
    $routesPermissions,
  },
  computed: {
    isChildVisible(): boolean {
      return this.$routesPermissions[this.item.link]
    },
    isSelected(): boolean {
      return this.item.link === this.$route.name && this.item.link !== 'home'
    },
    isIncomingApplication(): boolean {
      return (
        this.item.type === 'incoming-applications' ||
        this.item.type === 'incoming-deletion-applications' ||
        this.item.type === 'outgoing-applications' ||
        this.item.type === 'outgoing-deletion-applications'
      )
    },
    counterValue(): number {
      switch (this.item.type) {
        case 'incoming-applications':
          return this.$incomingApplicationsCounter
        case 'incoming-deletion-applications':
          return this.$incomingDeletionApplicationsCounter
        case 'outgoing-applications':
          return this.$outcomeModerationApplicationsCounter
        case 'outgoing-deletion-applications':
          return this.$outcomeDeletionApplicationsCounter
        default:
          return 0
      }
    },
  },
})
