import { attach } from 'effector-root'
import { createDropdownVcModel } from '@/pages/common/filters/create-dropdown-vc-model'
import { TestList } from '@/features/api/test/types'
import { GetListQueryParams } from '@/features/api/types'
import { getExamsTestsListFx } from '@/features/api/part-time-test/get-part-time-list'

const getTestsText = attach({
  effect: getExamsTestsListFx,
  mapParams: (params: GetListQueryParams) => ({ ...params, search_area: 'search_name' }),
})

export const examTestsVcDropdownModel = createDropdownVcModel<TestList>(getTestsText)
