<template>
  <div class="wrap">
    <div class='custom-file-input' :class="{ 'hide-input': file }">
      <div ref="dropContainer" class="drop-container">
        <span>{{ placeholder }}&nbsp;
          <span ref="triggerText" class='trigger'>файлов</span>
        </span>
      </div>
      <input
        ref="fileInput"
        :value="currentValue"
        class="file-input"
        type="file"
        :accept="accept"
      >
    </div>
    <div v-if="file" class="file-wrap">
      <div>
        {{file.file_name}}
      </div>
      <div class="file-load">
        Загружен
      </div>
      <Icon
        class="close-icon"
        type="close"
        size="10"
        @click="removeFile"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'CustomFileInput',
  components: {
    Icon,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Перетащите файл в поле или выберите из',
    },
    file: Object,
    accept: {
      type: String,
      default: 'file/*',
    },
  },
  data() {
    return {
      currentValue: null,
    }
  },
  methods: {
    preventDefault(event) {
      event.preventDefault()
    },
    dropHandle(event) {
      this.$emit('change', event.dataTransfer.files)
      event.preventDefault()
    },
    removeFile() {
      const { fileInput } = this.$refs
      // нужно что бы после удаления можно было добавить опять тот же файл.
      delete fileInput.files
      this.$emit('remove')
    },
    inputHandle(event) {
      const { fileInput } = this.$refs
      this.$emit('change', fileInput.files)
      event.preventDefault()
    },
    handleClick(event) {
      event.preventDefault()
      const { fileInput } = this.$refs
      fileInput.click()
    },
  },
  mounted() {
    const { dropContainer, fileInput, triggerText } = this.$refs
    dropContainer.addEventListener('dragover', this.preventDefault)
    dropContainer.addEventListener('dragenter', this.preventDefault)
    dropContainer.addEventListener('drop', this.dropHandle)
    fileInput.addEventListener('change', this.inputHandle)
    triggerText.addEventListener('click', this.handleClick)
  },
  beforeDestroy() {
    const { dropContainer, fileInput, triggerText } = this.$refs
    dropContainer.removeEventListener('dragover', this.preventDefault)
    dropContainer.removeEventListener('dragenter', this.preventDefault)
    dropContainer.removeEventListener('drop', this.dropHandle)
    fileInput.removeEventListener('change', this.inputHandle)
    triggerText.removeEventListener('click', this.handleClick)
  },
})
</script>

<style scoped>
.custom-file-input {
  display: flex;
  flex-direction: column;
}
.drop-container {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--c-grey-5);
  border: 1px dashed var(--c-grey-3);
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 17px;
  color: var(--base-text-secondary);
  padding: 15px;
}
.trigger {
  cursor: pointer;
  @mixin underline-text;
  color: var(--base-text-primary);
}
.file-input {
  visibility: hidden;
  height: 10px;
}

.close-icon {
  margin-left: 10px;
  cursor: pointer;
  fill: var(--base-text-secondary);
}

.wrap {
  position: relative;
}
.hide-input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0.01;
}
.file-wrap {
  display: flex;
  align-items: center;
}
.file-load {
  background-color: var(--c-grey-3);
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 25px;
}
</style>
