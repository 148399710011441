































import Vue, { PropType } from 'vue'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { examAssignmentFolderModal } from '@/pages/common/modals/tasks-bank/creating-folder/creating-folder-modal.model'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { examTaskPageParams, taskLoader } from '@/pages/bank/exam-tasks/list/exam-tasks-page.model'
import { $permissions } from '@/features/session'

export default Vue.extend({
  name: 'PageHeader',
  components: {
    BaseButton,
    GridPageHead,
    DownloadButton,
    HeaderPopup,
  },
  effector: {
    $treeView: examTaskPageParams.store.treeView,
    $exportTaskLoading: taskLoader.store.$exportTaskLoading,
    $permissions,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<number[]> },
  },
  methods: {
    modalVisibilityChanged: examAssignmentFolderModal.methods.modalVisibilityChanged,
  },
})
