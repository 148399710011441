












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  $statuses,
  resultStatusesDropdownModel,
  loadStatuses,
} from '@/pages/results/olympiad-results/model/tooltip-filter.model'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    $statuses,
    ...resultStatusesDropdownModel.store,
  },
  methods: {
    ...resultStatusesDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    loadStatuses()
  },
})
