


























































import Vue, { PropType } from 'vue'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  DraggableImage,
  DroppableImage,
  MovingOnTextDroppableImage,
} from '@/pages/common/parts/tasks/types'
import SizeInput from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/SizeInput.vue'

export default Vue.extend({
  name: `ImageMatchItem`,
  components: {
    SizeInput,
    BaseDropdown,
    SelectItem,
    Icon,
  },
  props: {
    droppableImages: {
      type: Array as PropType<(DroppableImage | MovingOnTextDroppableImage)[]>,
      required: true,
    },
    image: {
      type: Object as PropType<DraggableImage>,
      required: true,
    },
    isBackgroundImage: { type: Boolean, default: false },
  },
  methods: {
    canSetImageValue(val: any) {
      return this.droppableImages.find((el) => el.value === val.value)
    },
    pickValue(droppable: DroppableImage | null, image: DraggableImage) {
      this.$emit('change', {
        ...image,
        value: droppable?.value || 0,
        size: droppable?.size,
      })
    },
    getNameFile(patch: string): string {
      if (!patch) return ''
      const patchArr = patch.split('/')
      if (patchArr.length) {
        return patchArr[patchArr.length - 1]
      }
      return patch
    },
  },
})
