import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetProctorsType } from '@/features/api/proctors/types'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'

export const getProctorsFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<GetProctorsType[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/proctors/list/',
    query,
  }),
})
