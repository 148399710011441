















































































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import PageHeader from '@/pages/dictionary/themes/list/parts/PageHeader.vue'
import TooltipCell from '@/pages/dictionary/themes/list/parts/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ThemesFilter from '@/pages/dictionary/themes/list/parts/themes-filter/ThemesFilter.vue'
import ThemesTree from '@/pages/dictionary/themes/list/parts/themes-tree/ThemesTree.vue'
import {
  loadTreeLight,
  loadTree,
  $themesTreeTotal,
  deleteThemes,
  requestDeleteThemes,
  themesPageParams,
  $isLoading,
  $cannotDeleteData,
  $dataToDelete,
  checkBeforeDeletion,
  resetDataToDelete,
  $canManipulateDeleteData,
  resetCannotDeleteData,
  exportThemesList,
} from '@/pages/dictionary/themes/list/themes-page.model'
import {
  toggleVisibility,
  $visibility,
  themesFilters,
} from '@/pages/dictionary/themes/list/parts/themes-filter/themes-filter.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  themesTableFields,
  searchFieldsData,
  getThemesActions,
} from '@/pages/dictionary/themes/list/constants'
import { $currentUser, $permissions } from '@/features/session'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import {
  RefsType,
  HttpOptionsType,
  DisplayContextMenuPayload,
  ActionsItem,
} from '@/pages/common/types'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import CannotDeleteModal from '@/pages/applications/modals/cannot-delete/CannotDeleteModal.vue'
import {
  loadConfirmDeleteModal,
  $confirmDeleteModalVisibility,
} from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import {
  loadRequestDeleteModal,
  $requestDeleteModalVisibility,
} from '@/pages/common/modals/request-delete/request-delete-modal.model'
import {
  ThemesInteractedItemData,
  ThemesInteractedItemParams,
} from '@/pages/dictionary/themes/list/types'
import { DEFAULT_ID } from '@/pages/common/constants'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { clearTreeStates } from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { getActionsDisplayConditions } from '@/pages/common'
import { axiosClient } from '@/lib/request'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'ThemesPage',
  components: {
    Vuetable,
    VuetablePagination,
    PageHeader,
    TableHeader,
    GeneralFilter,
    ThemesFilter,
    TooltipCell,
    ActionsButton,
    ContextMenu,
    ThemesTree,
    NoDataContent,
    ConfirmDeleteModal,
    RequestDeleteModal,
    LoaderBig,
    CannotDeleteModal,
  },
  effector: {
    $visibility,
    $themesTreeTotal,
    $filterParams: themesFilters.store.$filterParams,
    $pageParams: themesPageParams.store.$pageParams,
    $treeView: themesPageParams.store.treeView,
    $currentPage: themesPageParams.store.currentPage,
    $isLoading,
    $cannotDeleteData,
    $dataToDelete,
    $confirmDeleteModalVisibility,
    $requestDeleteModalVisibility,
    $canManipulateDeleteData,
    $permissions,
    $currentUser,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      fields: themesTableFields,
      searchFields: searchFieldsData,
      total: 0,
      selectedRows: [] as number[],
      subject: DEFAULT_ID as number,
      studyYear: DEFAULT_ID as number,
      currentFailed: null as any | null,
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/subject-app/themes/list/`
    },
    showDeleteModal(): boolean {
      return !!this.currentFailed
    },
    selectedIds(): number[] {
      if (this.selectedRows.length && !this.$treeView) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getThemesActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.removeSelection()
      },
    },
    $canManipulateDeleteData: {
      handler(val) {
        if (val) {
          this.deleteCheckedThemes()
          this.showCannotDeleteThemes()
        }
      },
    },
    $confirmDeleteModalVisibility: {
      handler(newVal) {
        if (!newVal) resetDataToDelete()
      },
    },
    $requestDeleteModalVisibility: {
      handler(newVal) {
        if (!newVal) resetDataToDelete()
      },
    },
  },
  methods: {
    changeFilter: themesFilters.methods.changeFilter,
    resetFilters: themesFilters.methods.resetFilters,
    applyFilters: themesFilters.methods.applyFilters,
    toggleTreeView: themesPageParams.methods.toggleTreeView,
    changePage: themesPageParams.methods.changePage,
    queryToParams: themesPageParams.methods.queryToParams,
    loadTree,
    toggleVisibility,
    exportThemesList,
    myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      return axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      clearTreeStates()
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    onCreateTheme() {
      navigatePush({
        name: 'themes-create',
        params: {
          subject: `${this.subject}`,
          studyYear: `${this.studyYear}`,
          theme: `${this.selectedIds[0]}`,
        },
      })
    },
    onEditTheme() {
      navigatePush({ name: 'themes-edit', params: { id: `${this.selectedIds[0]}` } })
    },
    onRemoveThemes() {
      checkBeforeDeletion({
        ids: this.selectedIds,
        rights: this.$permissions!.subjects_theme_themes.can_edit,
      })
    },
    async removeSelectedThemes(ids: number[]) {
      await deleteThemes(ids)
      await Vue.nextTick(() => this.$refs.vuetable.reload())
      this.removeSelection()
    },
    async sendRequestDeleteTheme(comment: string, ids: number[]) {
      await requestDeleteThemes({ themes: ids, ticket_comment: comment })
      this.removeSelection()
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) noInternetToastEvent()
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: ThemesInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type = 'item' }: ThemesInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: ThemesInteractedItemData) {
      this.subject = typeof data.subject === 'number' ? data.subject : DEFAULT_ID
      this.studyYear = typeof data.studyYear === 'number' ? data.studyYear : DEFAULT_ID
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length,
        this.$treeView
      )
      this.contextMenuItems = getThemesActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    toggleModal(val: boolean) {
      if (!val) {
        this.currentFailed = null
        resetCannotDeleteData()
      }
    },
    showCannotDeleteThemes() {
      if (this.$cannotDeleteData.length === 0 || this.showDeleteModal) return
      const val = this.$cannotDeleteData
      const ids = val.map((el) => el.id)
      this.currentFailed = {
        object_type: 'theme',
        ids,
      }
    },
    deleteCheckedThemes() {
      if (this.$dataToDelete.length === 0) return
      const val = this.$dataToDelete
      if (this.$currentUser && this.$currentUser.role.includes('ADMIN'))
        loadConfirmDeleteModal(val!)
      else loadRequestDeleteModal(val!)
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!/create/g.test(from.path) && !/edit/g.test(from.path) && !/preview/g.test(from.path)) {
      clearTreeStates()
    }
    next()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  mounted() {
    loadTreeLight()
  },
  destroyed() {
    this.resetFilters()
  },
})
