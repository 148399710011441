import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportAssignmentQueryParams } from '@/features/api/assignment/types/types'

export const exportLessonsListFx = createApiEffect<ExportAssignmentQueryParams, Blob>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/lesson-app/lessons/list/export/',
    responseType: 'blob',
    query,
  }),
})
