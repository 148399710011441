


































































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $coursesSortModalVisibility,
  changeCoursesSortModalVisibility,
  $coursesSortList,
  setCoursesSortList,
  $isLoadingCoursesList,
  saveCoursesList,
  notUniqueNumber,
  notValidNumber,
} from '@/pages/learning/modals/courses-sort-modal/courses-sort-modalmodel'
import InternalLoader from '@/pages/common/parts/loader/InternalLoader.vue'
import { CourseRelated } from '@/features/api/learning/types'

const ClickOutside = require('vue-click-outside')

export default Vue.extend({
  components: {
    Modal,
    Icon,
    BaseButton,
    InternalLoader,
  },
  directives: {
    ClickOutside,
  },
  effector: {
    $coursesSortModalVisibility,
    $coursesSortList,
    $isLoadingCoursesList,
  },
  methods: {
    changeCoursesSortModalVisibility,
    setCoursesSortList,
    clickOutside(evt: any) {
      if (evt.target.closest('#btn-columns')) return
      changeCoursesSortModalVisibility(false)
    },
    handleSetNumber(value: number, item: CourseRelated) {
      setCoursesSortList(
        this.$coursesSortList.map((cs) => {
          return cs.id === item.id ? { ...item, number: value } : cs
        })
      )
    },
    handleSaveCoursesList() {
      const isAllNumber = this.$coursesSortList.filter((cs) => !Number(cs.number)).length === 0
      const findDuplicates = (arr: number[]) =>
        arr.filter((item, index) => arr.indexOf(item) !== index)
      const isUnique =
        findDuplicates(this.$coursesSortList.map((cs) => Number(cs.number))).length === 0
      if (!isAllNumber) {
        notValidNumber()
      } else if (!isUnique) {
        notUniqueNumber()
      }
      if (isAllNumber && isUnique) {
        saveCoursesList()
      }
    },
  },
})
