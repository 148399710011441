

























import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { $editedQuestionId } from '@/pages/content/faq/edit/model/edit-question.model'

export default Vue.extend({
  name: 'EditQuestionFooter',
  components: {
    BaseButton,
  },
  props: {
    disabled: { type: Boolean as PropType<boolean>, default: true },
    fromPage: { type: String as PropType<string> },
  },
  effector: {
    questionId: $editedQuestionId,
  },
  methods: {
    onSaveClick() {
      this.$emit('save')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
