import { attach, createEffect, createEvent, Effect, restore, split, Store } from 'effector-root'
import { request, RequestParams, Response } from '@/lib/request'
import { addToast } from '@/features/toasts/toasts.model'
import { navigatePush } from '@/features/navigation/navigationMethods'

export const refreshRequest = createEvent<RequestParams>()
export const setTokenForRefresh = createEvent<string>()
export const setTokenForRequest = createEvent<string>()
export const $token = restore(setTokenForRequest, '')

export const requestFx = createEffect<RequestParams, Response, Response>({ handler: request })

export const authorizedRequestFx = attach<
  RequestParams,
  Store<string>,
  Effect<RequestParams, Response, Response>
>({
  effect: requestFx,
  source: $token,
  mapParams: (params) => ({ ...params, headers: { ...params.headers } }),
})

split({
  source: authorizedRequestFx.failData,
  match: {
    unauthorized: (data) => {
      const requestUrl: string = data?.config?.url || ''
      if (!requestUrl) return false
      if (requestUrl.includes('/token/') || requestUrl.includes('employee/me/')) return false
      return data?.status === 401
    },
    forbidden: ({ status }) => status === 403,
    noInternet: ({ status }) => status === undefined,
    notFound: ({ status }) => status === 404,
  },
  cases: {
    unauthorized: navigatePush.prepend<Response>(() => ({ name: 'auth.login' })),
    forbidden: navigatePush.prepend<Response>(() => ({ name: 'home' })),
    noInternet: addToast.prepend(() => ({
      type: 'no-internet',
      message: 'Отсутствует подключение',
    })),
    notFound: addToast.prepend(() => ({ type: 'error', message: 'Страница не найдена' })),
  },
})
