const isCloseCalendar = (event: any) => {
  return (
    !event.target.closest('#filter-icon') &&
    event.target.nodeName !== 'use' &&
    !event.target.closest('.mx-calendar') &&
    !event.target.closest('.mx-time-item')
  )
}

export default isCloseCalendar
