








































import Vue from 'vue'
import ClickOutside from '@/features/directives/click-outside'
import Icon from '@/ui/icon/Icon.vue'
import {
  toggleVisibility,
  reset,
  tagsFilters,
} from '@/pages/tags/parts/tags-filter/tags-filter.model'

import BaseButton from '@/ui/button/BaseButton.vue'
import { FiltersParams } from '@/pages/common/types'
import { dropdownComponents } from '@/pages/tags/parts/tags-filter/parts/dropdown-components'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'TagsFilter',
  components: {
    Icon,
    SubjectDropdown: dropdownComponents.SubjectDropdown,
    ClassDropdown: dropdownComponents.ClassDropdown,
    BaseButton,
  },
  effector: {
    $filterParams: tagsFilters.store.$filterParams,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  methods: {
    toggleVisibility,
    reset,
    closeFilter({ target }: any) {
      // check for close icon (clear filter dropdown)
      if (target.href && target.href.baseVal === '#close-icon') {
        return
      }
      // check for general filter icon
      if (
        target.id !== 'filter-icon' &&
        target.parentElement &&
        target.parentElement.id !== 'filter-icon'
      ) {
        toggleVisibility(false)
      }
    },
    setItem(filter: FiltersParams) {
      this.$emit('changeFilter', filter)
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
      reset() // togglers and visibility
    },
  },
})
