







































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'

export default Vue.extend({
  name: 'EditEmployeeHeader',
  components: {
    Icon,
    BaseButton,
  },
  props: {
    title: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean>, default: true },
    isPreview: { type: Boolean as PropType<boolean> },
    fromPage: { type: String as PropType<string> },
    taskType: { type: String as PropType<string> },
    statusController: { type: Boolean as PropType<boolean>, default: false },
  },
  methods: {
    goToList() {
      switch (this.$route.name) {
        default:
          navigatePush({ name: 'employers-list' })
      }
    },
    onSaveClick() {
      this.$emit('save')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
