<template>
  <div class='footer'>
    <BaseButton
      class="btn"
      yellow
      :disabled="$props.disabled"
      @click="$emit('save')"
    >
      Сохранить
    </BaseButton>
    <BaseButton
      class="btn"
      yellow
      :disabled="$props.disabled"
      @click="$emit('saveAndBackToList')"
    >
      Сохранить и вернуться к списку
    </BaseButton>
  </div>
</template>

<script>
import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'

export default Vue.extend({
  name: 'LearningFooter',
  components: {
    BaseButton,
  },
  props: {
    title: { type: String, default: '' },
    disabled: { type: Boolean, default: true },
  },
})
</script>

<style scoped>
.footer {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background-color: #fff;
  border-radius: 3px;
  margin: 20px 0;
}
.btn + .btn {
  margin-left: 20px;
}
</style>
