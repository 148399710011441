
































































import Vue from 'vue'
import { DropdownItem } from '@/pages/common/types'
import {
  searchAssignment,
  testsAssignmentDropdownModel,
} from '@/pages/common/dropdowns/multiselectDropdown/tests-assignment-dropdown-filter/tests-assignment-dropdown.model'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import TestAssignmentItem from '@/pages/common/dropdowns/multiselectDropdown/tests-assignment-dropdown-filter/TestAssignmentItem.vue'
import { mapTaskTypeTo } from '@/pages/common/constants'
import { formatTitle } from '@/features/lib'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'TestsAssignmentDropdown',
  components: {
    BaseDropdown,
    SelectItem,
    TestAssignmentItem,
    TooltipCell,
    Icon,
  },
  effector: {
    ...testsAssignmentDropdownModel.store,
  },
  computed: {
    correctValue(): string {
      const currentItem = this.$item?.title
      return currentItem || this.$searchString
    },
    selectedItemsIds(): number[] {
      return this.$selectedItems.map((i) => Number(i.name))
    },
  },
  methods: {
    ...testsAssignmentDropdownModel.methods,
    sendItem(value: boolean, item: DropdownItem) {
      const data = [...this.$selectedItems]
      if (value) {
        this.setSelectedItems([...data, item])
      } else {
        this.setSelectedItems(data.filter((i) => i.name !== item.name))
      }
    },
    selectAllItems(cs: () => void) {
      this.setSelectedItems([...this.$items])
      cs()
    },
    onRemoveItem(item: DropdownItem) {
      this.setSelectedItems([...this.$selectedItems].filter((i) => i.name !== item.name))
    },
    clear() {
      this.resetItem()
      this.resetSearchString()
    },
    getCorrectIconType(type: string) {
      return mapTaskTypeTo[type].icon
    },
    getCorrectDescriptionType(type: string) {
      return mapTaskTypeTo[type].description
    },
    getScore(score: number) {
      if (score) {
        return formatTitle(score, ['балл', 'балла', 'баллов'])
      }
      return ''
    },
  },
  mounted() {
    if (!this.$items.length) searchAssignment()
  },
})
