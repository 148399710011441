import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExamTestType } from '@/features/api/full-time-tests/types'

export const createExamTestFx = createApiEffect<Partial<ExamTestType>, { id: number }>({
  requestMapper: (body) => ({
    method: 'POST',
    url: `/api/exams-app/tests/`,
    body,
  }),
})
