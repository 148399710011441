import { createEvent, forward, attach } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { getGroupsFiltersListFx } from '@/features/api/learning/courses/get-groups-filter-list'

export const groupDropdownModule = createFilter()

const getGroups = attach({
  effect: getGroupsFiltersListFx,
})

export const loadGroups = createEvent<void>()

forward({
  from: loadGroups,
  to: getGroups.prepend(() => ({})),
})

forward({
  from: getGroups.doneData.map((res) =>
    res.body.map((group) => {
      return {
        id: group.id,
        title: group.name,
        name: `${group.id}`,
        accept_dt_from: null,
        accept_dt_to: null,
      }
    })
  ),
  to: groupDropdownModule.methods.setItems.prepend((data) => data),
})
