

























import Vue, { PropType } from 'vue'
import TreeNode from '@/pages/bank/test-tasks/list/parts/tasks-tree/parts/TreeNode.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { $tasksTree } from '@/pages/bank/test-tasks/list/tasks-page.model'
import { FiltersParams } from '@/pages/common/types'

export default Vue.extend({
  name: 'TasksTree',
  components: {
    TreeNode,
    NoDataContent,
  },
  props: {
    filters: { type: Object as PropType<FiltersParams> },
  },
  effector: { $tasksTree },
})
