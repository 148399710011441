import { setTokenForRefresh, setTokenForRequest } from '@/features/api/common/request'
import { loginFx } from '@/features/api/user/login'
import { LoginFxParams, LoginFxResponse } from '@/features/api/user/types'
import { getSelfUserFx } from '@/features/api/employee/get-self-user'
import { createEffectorField } from '@/lib/effector/field-generator'
import { Response } from '@/lib/request'
import { isEmailValid } from '@/lib/validators/email'
import { attach, combine, createEvent, forward, guard, restore, sample } from 'effector-root'
import { condition, every } from 'patronum'
import { navigateReplace } from '@/features/navigation/navigationMethods'
import { $routesPermissions } from '@/features/session'
import { router } from '@/features/navigation'
import Cookies from 'js-cookie'
import { config } from '@/config'
import { useVar } from '@/lib/utils/useVar'
import IAttemptsInfo from '@/pages/login/model/interfaces/IAttemptsInfo'

const jwt = require('jsonwebtoken')

/**
 * ATTEMPTS
 */
export const setAttemptsInfo = createEvent<IAttemptsInfo>()

export const $attemptsInfo = restore<IAttemptsInfo>(setAttemptsInfo, { count: 0, timer: 0 })

export const getAttemptsInfo = (): IAttemptsInfo => {
  const info = useVar('letovo_login-attempts', 'json')
  return info ? { count: Number(info.count), timer: Number(info.timer) } : { count: 0, timer: 0 }
}

export const startAttemptsTimer = (): void => {
  const attemptsTimerInterval = setInterval(() => {
    const attemptsInfo = $attemptsInfo.getState()
    const newInfo: IAttemptsInfo = {
      ...attemptsInfo,
      timer: attemptsInfo.timer - 1,
    }

    setAttemptsInfo(newInfo)
    useVar('letovo_login-attempts', 'json', newInfo)

    if (newInfo.timer === 0) {
      clearInterval(attemptsTimerInterval)
    }
  }, 1000)
}

export const resetAttemptsInfo = (): void => {
  useVar('letovo_login-attempts', 'json', {
    count: 0,
    timer: 0,
  })
}

export const updateAttemptsInfo = (): void => {
  const attemptsInfo = $attemptsInfo.getState()

  if (attemptsInfo.count < 7) {
    const newInfo: IAttemptsInfo = {
      ...attemptsInfo,
      count: attemptsInfo.count + 1,
    }
    setAttemptsInfo(newInfo)
    useVar('letovo_login-attempts', 'json', newInfo)
  } else {
    setAttemptsInfo({
      ...attemptsInfo,
      timer: 60,
    })
    startAttemptsTimer()
  }
}

/**
 * FORM
 */
export const loadCurrentSessionFx = attach({
  effect: getSelfUserFx,
  mapParams: (params) => params,
})

export const setIsReady = createEvent<boolean>()
export const $isReadyMounted = restore(setIsReady, false)
const sendLoginFormFx = attach({
  effect: loginFx,
  mapParams: (params: LoginFxParams) => params,
})

const resetField = createEvent()
export const submitForm = createEvent()
export const getUserInfo = createEvent<void>()
export const setNavigate = createEvent<void>()

export const [$email, emailChanged, $emailError, $isEmailCorrect] = createEffectorField({
  defaultValue: '',
  validator: (value: string) => (isEmailValid(value) ? null : 'Неверный формат email'),
  reset: resetField,
})

export const [$password, passwordChanged, $passwordError, $isPasswordCorrect] = createEffectorField(
  {
    defaultValue: '',
    validator: (value: string) => (value.length > 0 ? null : 'Это поле не может быть пустым'),
    reset: resetField,
  }
)

export const $form = combine({
  email: $email,
  password: $password,
})
export const $errors = combine({
  email: $emailError,
  password: $passwordError,
})

export const $isFormValid = every({
  predicate: true,
  stores: [$isEmailCorrect, $isPasswordCorrect],
})

export const $canSubmit = combine(
  $isFormValid,
  sendLoginFormFx.pending,
  loadCurrentSessionFx.pending,
  (formValid, loginPending, sessionPending) => formValid && !loginPending && !sessionPending
)

sample({
  clock: guard({ source: submitForm, filter: $canSubmit }),
  source: $form,
  target: sendLoginFormFx,
})

export const $isLoading = combine(
  sendLoginFormFx.pending,
  loadCurrentSessionFx.pending,
  (loginPending, sessionPending) => loginPending || sessionPending
)

forward({
  from: sendLoginFormFx.doneData,
  to: [
    setTokenForRequest.prepend(({ body }: Response<LoginFxResponse>) => {
      Cookies.set(config.TOKEN_EXP, jwt.decode(body.access).exp)
      Cookies.set(config.TOKEN_KEY, body.access)
      return body.access
    }),
    setTokenForRefresh.prepend(({ body }: Response<LoginFxResponse>) => {
      Cookies.set(config.REFRESH_TOKEN_EXP, jwt.decode(body.refresh).exp)
      Cookies.set(config.REFRESH_TOKEN_KEY, body.refresh)
      return body.refresh
    }),
    loadCurrentSessionFx,
  ],
})

sample({
  source: sendLoginFormFx.doneData,
  fn: resetAttemptsInfo,
})

sample({
  source: sendLoginFormFx.failData,
  fn: () => {
    updateAttemptsInfo()
  },
})

forward({
  from: getUserInfo,
  to: loadCurrentSessionFx,
})

forward({
  from: loadCurrentSessionFx.finally,
  to: setIsReady.prepend(() => true),
})

const $redirect = sample({
  clock: loadCurrentSessionFx.doneData,
  source: { permissions: $routesPermissions },
  fn: (source) => {
    const { permissions } = source
    const { name } = router.currentRoute
    if (name === 'auth.login') return true
    if (name) {
      return !permissions[name]
    }

    return true
  },
})
condition({
  source: $redirect,
  if: (payload: boolean) => payload,
  then: setNavigate,
})

forward({
  from: setNavigate,
  to: [navigateReplace.prepend(() => ({ name: 'home' })), resetField],
})
