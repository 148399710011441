import { createEvent, sample, restore } from 'effector-root'
import { createHobbieFx } from '@/features/api/settings/hobbies/create-hobbie'

export const reset = createEvent<void>()

export const saveHobby = createEvent<void>()

export const changeHobbyText = createEvent<string>()

export const $hobbyText = restore(changeHobbyText, '').reset(reset)

sample({
  source: { text: $hobbyText },
  clock: saveHobby,
  target: createHobbieFx,
})
