import { createApiEffect } from '@/features/api/common/create-api-effect'
import { Banner } from '@/features/api/content/types'

export const updateBannerFx = createApiEffect<Partial<Banner>, Banner>({
  requestMapper: (params) => ({
    method: 'PATCH',
    url: `/api/content-app/banner/${params.id}/`,
    body: params,
  }),
})
