














import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import { ExamsBlockFilterDropdownModel } from '@/pages/exams/full-time-answers/list/parts/exams-block-filter/exams-block-filter.model'

export default Vue.extend({
  name: 'ExamsBlockFilter',
  components: {
    FilterDropdown,
  },
  effector: {
    ...ExamsBlockFilterDropdownModel.store,
  },
  methods: {
    ...ExamsBlockFilterDropdownModel.methods,
    onItemChanged(item: DropdownItem | null) {
      this.$emit('setItem', item)
    },
  },
})
