<template>
  <div class="wrap">
    <div class='audio-file-input' :class="{ 'hide-input': fileName }">
      <div ref="dropContainer" class="drop-container">
        <span>{{ placeholder }}&nbsp;
          <span ref="triggerText" class='trigger'>файлов</span>
        </span>

      </div>
      <input
        ref="fileInput"
        :value="currentValue"
        class="file-input"
        type="file"
        :accept="accept"
      >
    </div>
    <div v-if="fileName" class="image-wrap">
      <div
        class="image-essence"
      >
        <Icon
          class="close-icon"
          type="close"
          size="10"
          @click="removeFile"
        />
      </div>
      <div class="file-name">
        {{fileName}}
      </div>
      <div class="edit" @click="handleClick">
        <Icon
          type="spinner-arrow"
          size="24"
        />
        <div class="edit-text">
          Заменить
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'CustomFileInputExams',
  components: {
    Icon,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Перетащите аудиофайл в поле или выберите из',
    },
    file: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: 'file/*',
    },
  },
  data() {
    return {
      currentValue: null,
      fileName: '',
    }
  },
  watch: {
    file(file) {
      if (file && file.length) {
        this.fileName = file[0].file_name
      } else {
        this.fileName = ''
      }
    },
  },
  methods: {
    removeFile() {
      const { fileInput } = this.$refs
      delete fileInput.files
      this.$emit('remove')
    },
    preventDefault(event) {
      event.preventDefault()
    },
    dropHandle(event) {
      this.$emit('change', event.dataTransfer.files)
      event.preventDefault()
    },
    inputHandle(event) {
      const { fileInput } = this.$refs
      this.$emit('change', fileInput.files)
      event.preventDefault()
    },
    handleClick(event) {
      event.preventDefault()
      const { fileInput } = this.$refs
      fileInput.click()
    },
  },
  mounted() {
    const { dropContainer, fileInput, triggerText } = this.$refs
    dropContainer.addEventListener('dragover', this.preventDefault)
    dropContainer.addEventListener('dragenter', this.preventDefault)
    dropContainer.addEventListener('drop', this.dropHandle)
    fileInput.addEventListener('change', this.inputHandle)
    triggerText.addEventListener('click', this.handleClick)
  },
  beforeDestroy() {
    const { dropContainer, fileInput, triggerText } = this.$refs
    dropContainer.removeEventListener('dragover', this.preventDefault)
    dropContainer.removeEventListener('dragenter', this.preventDefault)
    dropContainer.removeEventListener('drop', this.dropHandle)
    fileInput.removeEventListener('change', this.inputHandle)
    triggerText.removeEventListener('click', this.handleClick)
  },
})
</script>

<style scoped>
.audio-file-input {
  display: flex;
  flex-direction: column;
}
.drop-container {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--c-grey-5);
  border: 1px dashed var(--c-grey-3);
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  line-height: 17px;
  color: var(--base-text-secondary);
  padding: 15px;
}
.trigger {
  cursor: pointer;
  @mixin underline-text;
  color: var(--base-text-primary);
}
.file-input {
  visibility: hidden;
  height: 0;
}

.close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 6;
  fill: #fff;
}

.image-essence {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 5px;
  background-image: url('~assets/img/file.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 5;
  }
}
.wrap {
  position: relative;
}
.hide-input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0.01;
}
.image-wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.file-name {
  max-width: calc(100% - 130px);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0f2345;
}
.edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background: #fff;
  border: 1px dashed #bcc2ca;
  box-sizing: border-box;
  border-radius: 5px;
}
.edit-text {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  color: #0f2345;
}
</style>
