



























import Vue, { PropType } from 'vue'
import { Chrome } from 'vue-color'

export default Vue.extend({
  name: `ColorPicker`,
  components: {
    Picker: Chrome,
  },
  props: {
    color: {
      type: String as PropType<string>,
      default: '#eee',
    },
  },
  data: () => ({
    opened: false,
    colors: {
      hex: '#ffffff',
    },
  }),
  methods: {
    close() {
      this.opened = false
    },
    change(data: any) {
      this.$emit('change', data.hex)
    },
  },
})
