




























import Vue from 'vue'
import CreatePartTimeTestFooter from '@/pages/exams/part-time-test/create/parts/CreatePartTimeTestFooter.vue'
import CreatePartTimeTestHeader from '@/pages/exams/part-time-test/create/parts/CreatePartTimeTestHeader.vue'
import CreatePartTimeTestPage1 from '@/pages/exams/part-time-test/create/parts/CreatePartTimeTestPage1.vue'
import CreatePartTimeTestPage2 from '@/pages/exams/part-time-test/create/parts/CreatePartTimeTestPage2.vue'
import Tabs from '@/pages/common/parts/tabs/Tabs.vue'
import { changePartTimeTestId } from '@/pages/exams/part-time-test/create/model/edit-part-time-test.model'
import {
  $disabledSaveButtons,
  resetFields,
} from '@/pages/exams/part-time-test/create/model/form-fileds.model'
import {
  getPageParams,
  setRedirectAfterSave,
} from '@/pages/exams/part-time-test/create/model/create-part-time-test.model'

export default Vue.extend({
  name: 'PartTimeTestPage',
  components: {
    CreatePartTimeTestHeader,
    CreatePartTimeTestFooter,
    CreatePartTimeTestPage1,
    CreatePartTimeTestPage2,
    Tabs,
  },
  effector: {
    $disabledSaveButtons,
  },
  data() {
    return {
      tab: 1,
      tabs: [
        {
          tab: 1,
          title: 'Шаг 1.  Механика и настройки теста',
        },
        {
          tab: 2,
          title: 'Шаг 2. Сопроводительное описание',
        },
      ],
    }
  },
  methods: {
    savePartTimeTest(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      getPageParams()
    },
    setTab(tab: number) {
      this.tab = tab
    },
  },
  created() {
    changePartTimeTestId(this.$route.params.id)
  },
  beforeDestroy() {
    resetFields()
  },
})
