import { createEvent, createStore, combine, attach, forward } from 'effector-root'
import { getStudentByIdFx } from '@/features/api/student/get-student-by-id'
import { spread } from 'patronum'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getStatusesListFx } from '@/features/api/student/get-statuses'
import { getStepsListFx } from '@/features/api/student/get-steps'
import {
  $selectedClass,
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { ChangeFieldType } from '@/pages/users/students/list/model/types'

export const resetFields = createEvent()

export const changeFieldValue = createEvent<ChangeFieldType>()

const getStatuses = attach({
  effect: getStatusesListFx,
  mapParams: () => ({}),
})

export const loadStatuses = createEvent()

forward({
  from: loadStatuses,
  to: getStatuses,
})

export const $statuses = createStore<DropdownItem[]>([]).on(getStatuses.doneData, (_, res) =>
  res.body.map((payload) => ({ name: `${payload.id}`, title: payload.name }))
)

export const loadSteps = createEvent()

const getSteps = attach({
  effect: getStepsListFx,
  mapParams: () => ({}),
})

forward({
  from: loadSteps,
  to: getSteps,
})

export const $steps = createStore<DropdownItem[]>([]).on(getSteps.doneData, (_, res) =>
  res.body.map((payload) => ({ name: `${payload.code}`, title: payload.name }))
)

export const statusesDropdownModel = createFilter()

export const stepsDropdownModel = createFilter()

export const classesDropdownModel = createFilter()

export const $firstName = createStore('')

export const $lastName = createStore('')

export const $email = createStore('')

export const $phone = createStore('')

const selectedStudyYear = createEvent<{
  id: number
  name: string
} | null>()

export const $selectedStatus = createStore<DropdownItem | null>(null)

export const $selectedStep = createStore<DropdownItem | null>(null)

export const $groups = createStore([])

export const $courses = createStore([])

export const $disabledSaveButtons = combine(
  $firstName,
  $lastName,
  $email,
  $phone,
  $selectedClass,
  (firstName, lastName, email, phone, studyYear) =>
    !firstName || !lastName || !email || !phone || !studyYear
)

export const studentForm = combine(
  $firstName,
  $lastName,
  $email,
  $phone,
  $selectedClass,
  $selectedStatus,
  $selectedStep,
  (firstName, lastName, email, phone, selectedClass) => ({
    email,
    phone,
    first_name: firstName,
    last_name: lastName,
    study_year_pk: Number(selectedClass?.name),
  })
)

spread({
  source: getStudentByIdFx.doneData.map((res) => ({
    ...res.body,
    status: {
      name: res.body.admissionticket?.status.id,
      title: res.body.admissionticket?.status.name,
    },
    step: {
      name: res.body.admissionticket?.id,
      title: res.body.admissionticket?.step_number,
    },
  })),
  targets: {
    first_name: $firstName,
    last_name: $lastName,
    email: $email,
    phone: $phone,
    study_year: selectedStudyYear,
    status: $selectedStatus,
    step: $selectedStep,
    groups: $groups,
    courses_active: $courses,
  },
})

spread({
  source: changeFieldValue.map((data) => {
    const obj = {}
    obj[data.type] = data.value
    return obj
  }),
  targets: {
    first_name: $firstName,
    last_name: $lastName,
    email: $email,
    phone: $phone,
    study_year: [classesDropdownModule.store.$item, setSelectedClass],
    status: $selectedStatus,
    step: $selectedStep,
  },
})

forward({
  from: selectedStudyYear.map((data) => ({
    name: data ? data.id.toString() : '',
    title: data ? data.name : '',
  })),
  to: [classesDropdownModule.store.$item, setSelectedClass],
})
