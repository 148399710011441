export const mapTaskStatus = {
  new: 'Новое',
  reserve: 'Резерв',
  moderation: 'На проверке',
  revision: 'На доработке',
  published: 'Опубликовано',
  archive: 'Архив',
}
export const mapApplicationsStatus = {
  new: 'Новая',
  accepted: 'Принята',
  rejected: 'Отклонена',
  declined: 'Отклонена',
  waiting: 'На проверке',
  revision: 'На доработке',
  finished: 'Проверено',
}
