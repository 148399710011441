import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse as Pagination } from '@/features/api/types'
import { Lesson } from '@/pages/learning/learning-lessons/list/model/types'

export const getLessonsListFx = createApiEffect<GetListQueryParams, Pagination<Lesson[]>>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/lesson-app/lessons/list/',
    query,
  }),
})
