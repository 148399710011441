














































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  $titleErrorModule,
  $folderTitle,
  folderTitleChanged,
  checkIfFolderCanBeSend,
} from '@/pages/common/modals/system-files/create-folder/create-folder.model'
import PositionDropdown from '@/pages/common/dropdowns/system-files/position-dropdown/PositionDropdown.vue'

export default Vue.extend({
  name: 'CreatingFolderModal',
  components: {
    Modal,
    Icon,
    FormInput,
    BaseButton,
    PositionDropdown,
  },
  effector: {
    $modalVisibility,
    titleError: $titleErrorModule.store.$error,
    $folderTitle,
  },
  methods: {
    modalVisibilityChanged,
    checkIfFolderCanBeSend,
    folderTitleChanged,
  },
})
