






































































































































































































































































































































import Vue from 'vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  uploadMedia,
  $lessonsElementBlocks,
  setBlocks,
  changeBlock,
  deleteBlock,
} from '@/pages/learning/learning-lessons/create/model/form-fileds.model'
import {
  DropdownAssignmentBlockNames,
  ElementTypeNames,
  FieldNames,
  LessonElementsType,
} from '@/pages/learning/learning-lessons/create/model/types'
import ElementTypesDropdown from '@/pages/common/dropdowns/add-assignment/ElementTypesDropdown.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FormInput from '@/ui/input/FormInput.vue'
import CustomFileInput from '@/ui/custom-file-input/CustomFileInput.vue'
import Icon from '@/ui/icon/Icon.vue'
import LessonAssigmentContent from '@/pages/learning/learning-lessons/create/parts/LessonAssigmentContent.vue'
import { LessonsAssignBlock } from '@/pages/learning/learning-lessons/create/model/assignment-block.model'
import Draggable from 'vuedraggable'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import { selectBlockElement } from '@/pages/common/dropdowns/add-assignment/assignment-elements.model'

export default Vue.extend({
  name: 'LessonElementsContent',
  components: {
    Wysiwyg,
    ElementTypesDropdown,
    BaseButton,
    FormInput,
    CustomFileInput,
    Icon,
    LessonAssigmentContent,
    Draggable,
    BaseSwitch,
  },
  effector: {
    $lessonsElementBlocks,
    blocks: $lessonsElementBlocks,
  },
  data: () => ({
    fieldType: FieldNames,
    answerBlockInfo: 'Текстовое описание отображается в интерфейсе ученика',
    CMSBlockInfo: 'Комментарий отображается только в CMS',
    blockType: ElementTypeNames,
  }),
  methods: {
    selectBlockElement,
    deleteBlock,
    handlerLessonsEnd() {
      const blocks = JSON.parse(JSON.stringify(this.$lessonsElementBlocks))
      const newBlocks = blocks.map((block: LessonElementsType, id: number) => ({
        ...block,
        blockId: id,
      }))
      setBlocks(newBlocks)
    },
    async uploadFileToBlock(files: any, blockId: number, type: ElementTypeNames) {
      if (files) {
        const formData = new FormData()
        formData.append('file', files[0])
        formData.append('file_type', 'file')
        const res = await uploadMedia(formData)
        this.changeFieldValue(type, blockId, res.body, 'file')
      } else {
        this.changeFieldValue(type, blockId, null, 'file')
      }
    },
    changeFieldValue(type: ElementTypeNames, blockId: number, value: any, field: string) {
      const obj = {}
      obj[field] = value
      changeBlock({ ...obj, blockId, element_type: type })
    },
  },
  mounted() {
    LessonsAssignBlock.methods.loadAssignmentDropdownOptions(DropdownAssignmentBlockNames.subject)
    LessonsAssignBlock.methods.loadAssignmentDropdownOptions(DropdownAssignmentBlockNames.classes)
  },
})
