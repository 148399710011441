import { createEvent, createStore, forward, attach, restore, sample } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { getEmployeesListFx } from '@/features/api/employee/get-employee-list'
import { getEmployeeName } from '@/pages/common'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { EmployeeList } from '@/features/api/employee/types'
import { GetListQueryParams } from '@/features/api/types'

const getModerators = attach({
  effect: getEmployeesListFx,
})

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

export const moderatorsDropdownModel = createDropdownModel<EmployeeList>(getModerators)

export const setSelectedModerator = createEvent<DropdownItem | null>()
export const $selectedModerator = restore(setSelectedModerator, null)

export const loadModerators = createEvent<void>()

sample({
  clock: loadModerators,
  source: { $nextPage: moderatorsDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getModerators,
})

forward({
  from: moderatorsDropdownModel.methods.canLoadNextPage,
  to: loadModerators,
})

sample({
  clock: getModerators.doneData,
  source: { items: moderatorsDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((user) => ({
      name: `${user.id}`,
      title: getEmployeeName(user),
    }))
    return [...items, ...newData]
  },
  target: moderatorsDropdownModel.store.$items,
})

forward({
  from: moderatorsDropdownModel.methods.resetDropdown,
  to: setSelectedModerator.prepend(() => null),
})
