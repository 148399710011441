




































import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  deleteTheme,
  $themes,
  $selectedThemes,
  setSelectedThemes,
  themesDropdownModule,
  resetSelectedThemes,
  componentMounted,
  componentDestroyed,
  $isComponentMounted,
  $isPrerequisite,
} from '@/pages/dictionary/themes/edit/parts/themes/themes.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
    Icon,
  },
  effector: {
    $themes,
    ...themesDropdownModule.store,
    $selectedThemes,
    $isComponentMounted,
    $isPrerequisite,
  },
  data() {
    return {
      themeModuleMethods: themesDropdownModule.methods,
    }
  },
  methods: {
    deleteTheme,
    onItemChanged(item: DropdownItem) {
      if (!item && this.$selectedThemes.length === 1) {
        resetSelectedThemes()
        return
      }
      const existedItem = this.$selectedThemes.find(
        (theme: DropdownItem) => theme.name === item.name
      )
      if (existedItem) {
        const themes = this.$selectedThemes.filter(
          (theme: DropdownItem) => theme.name !== item.name
        )
        setSelectedThemes(themes)
      } else {
        setSelectedThemes([item, ...this.$selectedThemes])
      }
    },
  },
  mounted() {
    componentMounted()
  },
  beforeDestroy() {
    componentDestroyed()
  },
})
