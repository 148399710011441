
















import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { modalVisibilityChanged } from '@/pages/labels/parts/modals/label-creation/label-creation.model'
import { $permissions } from '@/features/session'

export default Vue.extend({
  components: {
    BaseButton,
  },
  effector: {
    $permissions,
  },
  methods: {
    modalVisibilityChanged,
  },
})
