import { createEvent, createStore, forward, restore } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'

export const typeDropdownModule = createFilter()

export const resetSelectedType = createEvent<void>()
export const setSelectedType = createEvent<DropdownItem | null>()
export const $selectedType = restore(setSelectedType, null).reset(resetSelectedType)

export const loadTypes = createEvent<void>()
export const $types = createStore<DropdownItem[]>([
  { name: 'no_timer', title: 'Баннер без обратного отсчёта' },
  { name: 'timer', title: 'Баннер с обратным отсчётом' },
])

forward({
  from: $types,
  to: [
    setSelectedType.prepend((data) => data[0]),
    typeDropdownModule.methods.itemChanged.prepend((data) => data[0]),
  ],
})
