


















import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  studentsDropdownModel,
  loadStudents,
} from '@/pages/common/dropdowns/multiselectDropdown/student-filter-dropdown/studentsFilterDropdown.model'
import { DropdownItem } from '@/pages/common/types'
import SelectedItemsList from '@/pages/common/dropdowns/multiselectDropdown/parts/SelectedItemsList.vue'

export default Vue.extend({
  components: {
    FilterDropdown,
    SelectedItemsList,
  },
  effector: {
    ...studentsDropdownModel.store,
  },
  methods: {
    ...studentsDropdownModel.methods,
    onItemChanged(item: DropdownItem | null) {
      const existedItem = this.$selectedItems.find(
        (prerequisite: DropdownItem) => prerequisite.name === item?.name
      )
      let students: DropdownItem[] = []
      if (existedItem) {
        this.$selectedItems.filter((preStudents: DropdownItem) => preStudents.name !== item?.name)
      } else {
        students = item ? [item, ...this.$selectedItems] : [...this.$selectedItems]
      }
      this.setSelectedItems(students)
      this.$emit(
        'setItem',
        students.map((s) => s.name)
      )
    },
    onDeleteItem(item: string) {
      this.deleteSelectedItem(item)
      this.$emit(
        'setItem',
        this.$selectedItems.filter((s) => s.name !== item).map((i) => i.name)
      )
    },
  },
  mounted() {
    if (this.$items.length === 0) loadStudents()
  },
})
