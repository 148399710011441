





































import Vue, { PropType } from 'vue'
import Card from '@/ui/card/Card.vue'
import Divider from '@/ui/divider/Divider.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import { $isPreview, toggleIsPreview } from '@/pages/preview-tasks/controller.model'
import StatusController from '@/pages/common/parts/status-controller/StatusContoller.vue'

export default Vue.extend({
  name: 'Controller',
  components: {
    Card,
    Divider,
    BaseButton,
    BaseSwitch,
    StatusController,
  },
  props: {
    fromPage: { type: String as PropType<string>, required: true },
    taskType: { type: String as PropType<string> },
  },
  effector: {
    $isPreview,
  },
  methods: {
    toggleIsPreview,
  },
})
