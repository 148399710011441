import dayjs from 'dayjs'
import { TableField } from '@/pages/dictionary/themes/list/types'

export const NewsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '70px',
  },
  {
    name: 'publish_date',
    sortField: 'publish_date',
    title: 'Дата публикации',
    width: '170px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'title',
    sortField: 'title',
    title: 'Заголовок',
    width: '170px',
  },
  {
    name: 'tags',
    sortField: 'tags',
    title: 'Теги',
    width: '170px',
    formatter(tags: { id: string; name: string }[]) {
      return tags.map((tag) => tag.name).join(', ')
    },
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создано',
    width: '200px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Создатель',
    width: '180px',
    formatter: (data) => {
      return data ? `${data.first_name} ${data.last_name}` : '-'
    },
  },
  {
    name: 'updated_at',
    sortField: 'updated_at',
    title: 'Обновлено',
    width: '180px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
]
