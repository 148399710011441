import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteSlidesParams } from '@/features/api/content/types'

export const deleteSlidesFx = createApiEffect<number[], void>({
  requestMapper: (sliders) => ({
    method: 'PATCH',
    url: '/api/content-app/slider/list/bulk-delete/',
    body: { sliders },
  }),
})

export const requestDeleteSlidesFx = createApiEffect<
  RequestDeleteSlidesParams,
  RequestDeleteSlidesParams
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/content-app/slider/list/bulk-delete/',
    body,
  }),
})
