import { createEvent, restore } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'

export const LessonStatusDropdownModule = createFilter()

export const resetLessonStatus = createEvent<void>()
export const setLessonStatus = createEvent<DropdownItem | null>()
export const $selectedLessonStatus = restore(setLessonStatus, null).reset(resetLessonStatus)

export const setStudentsCount = createEvent<DropdownItem[]>()
export const $studentsCount = restore(setStudentsCount, [])
