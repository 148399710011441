

















import Vue from 'vue'
import TaskHeader from '@/pages/testing/tests/common/parts/Header.vue'
import TaskContent from '@/pages/testing/tests/create/parts/Content.vue'
import TestFooter from '@/pages/testing/tests/common/parts/Footer.vue'

import {
  $canSave,
  setRedirectAfterSave,
  save,
  $isLoadingCreateTests,
} from '@/pages/testing/tests/create/test-create-page.model'

import { toggleIsPageLoaded } from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'

export default Vue.extend({
  name: 'TestCreationPage',
  components: {
    TaskHeader,
    TaskContent,
    TestFooter,
  },
  effector: {
    $canSave,
    $isLoadingCreateTests,
  },
  methods: {
    saveTask(isRedirect: boolean) {
      save()
      if (isRedirect) setRedirectAfterSave(true)
    },
  },
  mounted() {
    toggleIsPageLoaded(true)
  },
  beforeDestroy() {
    toggleIsPageLoaded(false)
  },
})
