

















































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $activeProctors,
  $modalVisibility,
  $studentId,
  modalVisibilityChanged,
  resetModalsData,
  setActiveProctors,
} from '@/pages/exams/full-appointments/create/parts/modals/edit-proctor-modal/edit-proctor-modal'
import ProctorsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/ProctorsFilterDropdown.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import {
  loadProctors,
  proctorsDropdownModel,
} from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/proctors-filter-dropdown.model'
import {
  $studentsForAppointmentsIds,
  $studentsProctors,
  setStudentsProctors,
} from '@/pages/exams/full-appointments/create/model/form-fileds.model'

export default Vue.extend({
  name: 'EditProctorModal',
  components: {
    Modal,
    Icon,
    BaseButton,
    ProctorsFilterDropdown,
    BaseSwitch,
  },
  watch: {
    $modalVisibility: {
      handler(value) {
        if (value && this.$studentId && this.$studentId in this.$studentsProctors) {
          setActiveProctors(this.$studentsProctors[this.$studentId].active_proctor)
          const items = this.proctorsAll
          const studentProctorsIds = this.$studentsProctors[this.$studentId].proctors.map(
            (item) => item.name
          )
          const proctors = items.filter((item) => studentProctorsIds.includes(item.name))
          proctorsDropdownModel.methods.setSelectedItems(proctors)
        } else {
          setActiveProctors(false)
          proctorsDropdownModel.methods.setSelectedItems([])
        }
      },
    },
  },
  effector: {
    $modalVisibility,
    $activeProctors,
    $studentId,
    $studentsForAppointmentsIds,
    $studentsProctors,
    proctors: proctorsDropdownModel.store.$selectedItems,
    proctorsAll: proctorsDropdownModel.store.$items,
  },
  methods: {
    setStudentsProctors,
    modalVisibilityChanged,
    setActiveProctors,
    resetModalsData,
    setProctors() {
      if (this.$studentId) {
        setStudentsProctors({
          ...this.$studentsProctors,
          [this.$studentId]: {
            proctors: this.proctors,
            active_proctor: this.$activeProctors,
          },
        })
      } else {
        const setsResult = {}
        this.$studentsForAppointmentsIds.forEach((studentId) => {
          setsResult[studentId] = {
            proctors: this.proctors,
            active_proctor: this.$activeProctors,
          }
        })
        setStudentsProctors({
          ...this.$studentsProctors,
          ...setsResult,
        })
      }
      this.modalVisibilityChanged(false)
      this.resetModalsData()
    },
  },
  mounted() {
    if (this.proctorsAll.length === 0) loadProctors()
  },
})
