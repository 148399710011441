







import Vue from 'vue'
import Block from '@/pages/common/Block.vue'

export default Vue.extend({
  name: 'PageHeader',
  components: { Block },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
})
