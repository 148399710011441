















import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  $themes,
  themesDropdownModule,
  setSelectedTheme,
  componentMounted,
  componentDestroyed,
  $canSetThemePosition,
} from '@/pages/dictionary/themes/edit/parts/position/position.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    $themes,
    $canSetThemePosition,
    ...themesDropdownModule.store,
  },
  props: {
    selectedThemeId: { type: Number },
  },
  methods: {
    ...themesDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedTheme(item)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    componentMounted()
  },
  beforeDestroy() {
    componentDestroyed()
  },
})
