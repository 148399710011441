











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import { rolesDropdownModel } from '@/pages/users/employes/list/parts/roles-dropdown/roles-dropdown.model'
import { EmployeeRole } from '@/features/api/employee/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...rolesDropdownModel.store,
  },
  data: (): { roles: { name: EmployeeRole; title: string }[] } => ({
    roles: [
      {
        name: 'ADMIN',
        title: 'администратор',
      },
      {
        name: 'METHODIST',
        title: 'методист',
      },
      {
        name: 'SUPER_ADMIN',
        title: 'Супер администратор',
      },
    ],
  }),
  methods: {
    ...rolesDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  destroyed() {
    rolesDropdownModel.methods.resetDropdown()
  },
})
