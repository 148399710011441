import { createEvent, forward, restore } from 'effector-root'
import {
  examsAnswersFilters,
  resetExamsAnswersFilters,
} from '@/pages/exams/part-time-answers/list/model/exams-answers-filters.model'

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(resetExamsAnswersFilters)

forward({
  from: [resetExamsAnswersFilters],
  to: [examsAnswersFilters.methods.resetFilters],
})
