











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { formationDropdownModel } from '@/pages/exams/part-time-test/create/parts/FormationDropdown/formation-dropdown.model'

export default Vue.extend({
  name: 'FormationDropdown',
  components: {
    FilterDropdown,
  },
  effector: {
    ...formationDropdownModel.store,
  },
  data() {
    return {
      searchString: '',
    }
  },
  methods: {
    ...formationDropdownModel.methods,
  },
})
