import { FiltersParams } from '@/pages/common/types'
import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import { UploadMediaResponse } from './types'

export const getMediaListFx = createApiEffect<
  FiltersParams,
  TableDataResponse<UploadMediaResponse[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/media-app/media/list/',
    query,
  }),
})
