











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  $fullTests,
  loadTests,
  examTestsDropdownModel,
} from '@/pages/common/dropdowns/exams/exams-full-tests/exams-full-test-list-dropdown.model'

export default Vue.extend({
  name: 'ExamsTestsListDropdown',
  components: {
    FilterDropdown,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Выберите экзаменационный тест',
    },
    label: {
      type: String,
      default: 'Экзаменационный тест',
    },
  },
  effector: {
    $fullTests,
    ...examTestsDropdownModel.store,
  },
  methods: {
    ...examTestsDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (this.$fullTests.length === 0) {
      loadTests()
    }
  },
})
