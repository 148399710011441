import {
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
  split,
} from 'effector-root'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import { createEmployeeFx } from '@/features/api/employee/create-employee'
import {
  $employeeForm,
  $permissions,
  clearFields,
} from '@/pages/users/employes/edit/model/form-fields.model'
import { getEmployeeByIdFx } from '@/features/api/employee/get-employee-by-id'
import { changeEmployeeInfoByIdFx } from '@/features/api/employee/patch-employee-by-id'
import { $isSelectedAllSubjects } from '@/pages/users/employes/edit/model/dropdowns/subjects-dropdown.model'
import { $isSelectedAllClasses } from '@/pages/users/students/list/parts/classes-dropdown/classes-dropdown.model'
import { $isSelectedAllGroups } from '@/pages/users/students/list/parts/groups-dropdown/groups-dropdown.model'
import { CreateEmployeeType, EditEmployeeFields, Employee } from '@/features/api/employee/types'
import { coursesDropdownModel } from '@/pages/users/employes/edit/parts/courses-dropdown/courses-dropdown.model'

export const changeEmployeeId = createEvent<string | never>()

export const $editedEmployeeId = createStore<string>('')
  .on(changeEmployeeId, (state, payload) => payload || state)
  .reset(clearFields)

guard({
  source: changeEmployeeId,
  filter: (payload) => !!payload,
  target: getEmployeeByIdFx,
})

export const saveEmployeeInfo = createEvent<void>()

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  createEmployeeFx.fail,
])

const $employeeData = combine(
  $employeeForm,
  $permissions,
  $isSelectedAllSubjects,
  $isSelectedAllClasses,
  $isSelectedAllGroups,
  coursesDropdownModel.store.$selectedItems,
  (employee, permissions, allSubjects, allClasses, allGroups, allCourses) => ({
    ...employee,
    permissions,
    [EditEmployeeFields.available_subjects_all]:
      employee[EditEmployeeFields.role] === 'METHODIST' ? allSubjects : true,
    [EditEmployeeFields.available_study_years_all]:
      employee[EditEmployeeFields.role] === 'METHODIST' ? allClasses : true,
    [EditEmployeeFields.available_groups_all]:
      employee[EditEmployeeFields.role] === 'METHODIST' ? allGroups : true,
    [EditEmployeeFields.available_courses_all]:
      employee[EditEmployeeFields.role] === 'METHODIST'
        ? allCourses.some(({ name }) => name === 'all')
        : true,
  })
)

const createEmployee = createEvent<void>()

const updateEmployee = createEvent<string>()

const employeeId = sample({
  source: $editedEmployeeId,
  clock: saveEmployeeInfo,
  fn: (id) => (id.length > 0 ? id : false),
})

split({
  source: employeeId,
  match: {
    post: (payload) => payload === false,
    patch: (payload) => payload !== false,
  },
  cases: {
    post: createEmployee,
    patch: updateEmployee,
  },
})

sample({
  source: $employeeData,
  clock: createEmployee,
  target: createEmployeeFx,
})

sample({
  source: $employeeData,
  clock: updateEmployee,
  fn: (form, id) => {
    const spreadedForm = { ...form } as Partial<CreateEmployeeType>
    !form.password && delete spreadedForm.password
    return { body: spreadedForm, id }
  },
  target: changeEmployeeInfoByIdFx,
})

forward({
  from: createEmployeeFx.done,
  to: successToastEvent('Пользователь успешно сохранен'),
})

const $redirectAfterFirstSave = sample({
  source: $redirectAfterSave,
  clock: createEmployeeFx.done,
  fn: (redirect, res: { result: { body: Employee } }) => ({ id: res.result.body.id, redirect }),
})

guard({
  source: $redirectAfterFirstSave,
  filter: (payload) => payload.redirect,
  target: navigatePush.prepend(() => ({ name: 'employers-list' })),
})

guard({
  source: $redirectAfterFirstSave,
  filter: (payload) => !payload.redirect,
  target: [
    navigatePush.prepend((payload: { id: number; redirect: boolean }) => ({
      name: 'edit-employee',
      params: { id: `${payload.id}` },
    })),
    changeEmployeeId.prepend((payload: { id: number; redirect: boolean }) => {
      return `${payload.id}`
    }),
  ],
})

forward({
  from: changeEmployeeInfoByIdFx.done,
  to: successToastEvent('Пользователь отредактирован'),
})

guard({
  source: changeEmployeeInfoByIdFx.done,
  filter: $redirectAfterSave,
  target: navigatePush.prepend(() => ({ name: 'employers-list' })),
})

forward({
  from: createEmployeeFx.failData.map((res) => res.body),
  to: addToast.prepend((data: { email: string[]; password: string[] }) => ({
    type: 'error',
    message: `${data.email ? data.email.join() : ''} ${data.password ? data.password.join() : ''}`,
  })),
})

forward({
  from: changeEmployeeInfoByIdFx.failData.map((res) => res.body),
  to: addToast.prepend((data: { email: string[]; password: string[] }) => ({
    type: 'error',
    message: `${data.email ? data.email.join() : ''} ${data.password ? data.password.join() : ''}`,
  })),
})
