










































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  $labelTitle,
  $labelsList,
} from '@/pages/labels/parts/modals/tasks/tasks.model'

export default Vue.extend({
  name: 'ModalLogout',
  components: {
    Modal,
    Icon,
    BaseButton,
  },
  effector: {
    $modalVisibility,
    $labelTitle,
    $labelsList,
  },
  methods: {
    modalVisibilityChanged,
    handleOnPreview() {
      this.$emit('showPreview', this.$labelsList)
      modalVisibilityChanged(false)
    },
  },
})
