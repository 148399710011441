










































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import DifficultyDropdown from '@/pages/bank/test-tasks/list/parts/modals/tasks-update/parts/difficulty-dropdown/DifficultyDropdown.vue'
import Switchers from '@/pages/bank/test-tasks/list/parts/modals/tasks-update/parts/switchers/Switchers.vue'
import ModeratorDropdown from '@/pages/common/dropdowns/users/moderator-dropdown/ModeratorDropdown.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  $tasksIds,
  tasksIdsChanged,
  submitForm,
  cancelForm,
  $tasksIdsErrorModule,
  $selectedType,
  $canSetModerator,
} from '@/pages/bank/test-tasks/list/parts/modals/tasks-update/tasks-update-modal.model'
import { loadDifficultys } from '@/pages/bank/test-tasks/list/parts/modals/tasks-update/parts/difficulty-dropdown/difficulty.model'

export default Vue.extend({
  name: 'TasksUpdateModal',
  components: {
    Modal,
    BaseTextarea,
    DifficultyDropdown,
    Switchers,
    ModeratorDropdown,
    BaseButton,
  },
  effector: {
    $modalVisibility,
    $tasksIds,
    idsError: $tasksIdsErrorModule.store.$error,
    $selectedType,
    $canSetModerator,
  },
  methods: {
    modalVisibilityChanged,
    tasksIdsChanged,
    loadDifficultys,
    submitForm,
    cancelForm,
  },
  mounted() {
    loadDifficultys()
  },
})
