















































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $duplicateModalVisibility,
  $nDuplicate,
  changedDuplicateModalVisibility,
  changedNDuplicate,
  $selectedTasks,
  $isDataValid,
  validate,
} from '@/pages/bank/common/modals/duplicate/duplicate.model'
import BaseInput from '@/ui/input/BaseInput.vue'

export default Vue.extend({
  name: 'DuplicateModal',
  components: {
    BaseInput,
    Modal,
    Icon,
    BaseButton,
  },
  effector: {
    $duplicateModalVisibility,
    $nDuplicate,
    $selectedTasks,
    $isDataValid,
  },
  methods: {
    changedDuplicateModalVisibility,
    changedNDuplicate,
    async confirm() {
      await validate(null)
      if ($isDataValid) this.$emit('confirmTaskDuplicate', this.$selectedTasks)
    },
  },
})
