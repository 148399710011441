import { attach } from 'effector-root'
import { getSubjectsListFx } from '@/features/api/subject/get-subjects-list'
import { SubjectGrid } from '@/features/api/subject/types'
import { createDropdownVcModel } from '@/pages/common/filters/create-dropdown-vc-model'

const getSubjects = attach({
  effect: getSubjectsListFx,
})

export const subjectsVcDropdownModel = createDropdownVcModel<SubjectGrid>(getSubjects)
