import { render, staticRenderFns } from "./FileUploadBlock.vue?vue&type=template&id=b3a8dcda&scoped=true&"
import script from "./FileUploadBlock.vue?vue&type=script&lang=ts&"
export * from "./FileUploadBlock.vue?vue&type=script&lang=ts&"
import style0 from "./FileUploadBlock.vue?vue&type=style&index=0&id=b3a8dcda&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3a8dcda",
  null
  
)

export default component.exports