

























import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { UploadMediaResponse } from '@/features/api/media/types'
import { getFileForPreview } from '@/features/lib'
import { loadModal } from '@/ui/preview-file/preview-file.model'
import PreviewFile from '@/ui/preview-file/PreviewFile.vue'

export default Vue.extend({
  name: 'BroadFileAnswer',
  components: {
    PreviewFile,
    BaseButton,
  },
  props: {
    files: { type: Array as PropType<UploadMediaResponse[]> },
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    filesData() {
      try {
        const data = this.files.map((file: UploadMediaResponse) => ({
          ...file,
          file_short_name: `${file.file_name.substring(0, 10)}... ${file.file_name.substring(
            file.file_name.length - 3
          )}`,
        }))
        if (data.length > 0) {
          return data
        }
        return []
      } catch (e) {
        return []
      }
    },
    textData() {
      try {
        if (this.answer?.text && Array.isArray(this.answer.text)) {
          return this.answer.text
        }
        if (Array.isArray(this.answer)) {
          return this.answer
        }
        return []
      } catch (e) {
        return []
      }
    },
    correctAnswerData() {
      return 'Нет ответа'
    },
  },
  methods: {
    download(file: UploadMediaResponse) {
      getFileForPreview(file.file, true).then((blob) => {
        if (!blob) {
          return
        }

        const splitFileName = file.file_name.split('.')
        loadModal({
          file: blob,
          format: splitFileName[splitFileName.length - 1],
          filename: splitFileName.slice(0, -1).join(''),
        })
      })
    },
  },
})
