import { getThemeFx } from '@/features/api/subject/get-theme'
import { getThemesListFx } from '@/features/api/subject/get-themes-list'
import { Theme } from '@/features/api/subject/types'
import { GetListQueryParams } from '@/features/api/types'
import { $selectedSubject } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { DropdownItem } from '@/pages/common/types'
import {
  attach,
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector-root'

export const getThemeData = attach({
  effect: getThemeFx,
})

export const getPrerequisites = attach({
  effect: getThemesListFx,
  mapParams: (params: GetListQueryParams) => params,
})

export const prerequisitesDropdownModel = createDropdownModel<Theme>(getPrerequisites)

export const setSelectedPrerequisites = createEvent<DropdownItem[]>()
export const resetSelectedPrerequisites = createEvent<void>()
export const $selectedPrerequisites = restore<DropdownItem[]>(setSelectedPrerequisites, []).reset(
  resetSelectedPrerequisites
)
export const deletePrerequisite = createEvent<string>()

export const loadPrerequisites = createEvent<void>()

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

export const isPrerequisiteChanged = createEvent<boolean>()
export const $isPrerequisite = restore(isPrerequisiteChanged, false)

guard({
  clock: [loadPrerequisites, $selectedSubject],
  source: { $nextPage: prerequisitesDropdownModel.store.$nextPage, $selectedSubject },
  filter: combine($isComponentMounted, $isPrerequisite, (isMounted, isPrerequisite) => {
    return isMounted && !isPrerequisite
  }),
  target: getPrerequisites.prepend(
    (params: { $nextPage: number; $selectedSubject: DropdownItem | null }): GetListQueryParams => ({
      page: params.$nextPage,
      subject: params.$selectedSubject?.name as number | null | undefined,
      is_prerequisite: true,
    })
  ),
})

forward({
  from: prerequisitesDropdownModel.methods.canLoadNextPage,
  to: loadPrerequisites,
})

sample({
  source: prerequisitesDropdownModel.store.$items,
  clock: getPrerequisites.doneData.map(({ body }) => body.data),
  fn: (oldItems, newItems): DropdownItem[] => [
    ...oldItems,
    ...newItems.map((field) => ({
      name: `${field.id}`,
      title: field.name,
    })),
  ],
  target: prerequisitesDropdownModel.store.$items,
})

sample({
  source: $selectedPrerequisites,
  clock: deletePrerequisite,
  fn: (list, id) => list.filter((el) => el.name !== id),
  target: $selectedPrerequisites,
})

sample({
  source: $selectedPrerequisites,
  clock: deletePrerequisite,
  fn: (selected) => (selected.length > 0 ? selected[0] : null),
  target: prerequisitesDropdownModel.methods.itemChanged,
})

forward({
  from: $selectedSubject,
  to: [
    resetSelectedPrerequisites,
    prerequisitesDropdownModel.methods.resetItem,
    prerequisitesDropdownModel.methods.dropdownDestroy,
  ],
})
