import { createEvent, restore } from 'effector-root'
import { TestResultExtraFilterElement } from '@/pages/common/modals/result-tests-filter/type'
import { reset } from '@/pages/common/general-filter/general-filter.model'

export const resultTestsFilterModalVisibilityChanged = createEvent<boolean>()
export const $resultTestsFilterModalModalVisibility = restore(
  resultTestsFilterModalVisibilityChanged,
  false
)
export const saveFiltersChanged = createEvent<boolean>()
export const $saveFilters = restore(saveFiltersChanged, false).reset(reset)

export const resetResultFilterBlock = createEvent<boolean>()

export const resultFilterBlockChanged = createEvent<TestResultExtraFilterElement[]>()
export const $resultFilterBlock = restore(resultFilterBlockChanged, [
  new TestResultExtraFilterElement(),
]).reset(resetResultFilterBlock, reset)
