



















import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'ModalLogout',
  components: { Icon },
  props: {
    index: { type: Number as PropType<number> },
    image: { type: String as PropType<string>, default: '' },
    id: { type: Number as PropType<number> },
  },
  computed: {
    fileIndex() {
      return this.index !== undefined ? this.index + 1 : ''
    },
  },
})
