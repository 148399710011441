









import Vue from 'vue'

export default Vue.extend({
  name: 'MultipleListTextAnswer',
  props: {
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    correctAnswer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    currentAnswer() {
      return this.answerType === 'student' ? this.answer : this.correctAnswer
    },
    answerData() {
      try {
        if (this.answerType === 'student') {
          if (Array.isArray(this.answer) && this.question !== null) {
            const arr: string[] = []
            this.answer.forEach((el: number, index) => {
              if (el !== null) {
                arr.push(this.question.variants[index].options[el])
              } else {
                arr.push(' - ')
              }
            })
            return arr.join(';')
          }
        }

        if (this.answerType === 'correct') {
          if (Array.isArray(this.correctAnswer) && this.question !== null) {
            const arr: string[] = []
            this.correctAnswer.forEach((el: number, index) => {
              if (el !== null) {
                arr.push(this.question.variants[index].options[el])
              } else {
                arr.push(' - ')
              }
            })
            return arr.join(';')
          }
        }

        return this.answerType === 'student' ? this.answer : this.correctAnswer
      } catch (e) {
        console.error(e)
        return []
      }
    },
  },
})
