






















































import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  setInput,
  $finalTextDropdownsPercent,
  defaultFinalText,
  clearFields,
} from '@/pages/common/parts/tests/parts/final-text-form-percent/final-text-form.model'
import { FinalTextRelated } from '@/features/api/test/types'
import PercentDropdown from '@/pages/common/dropdowns/value-change-dropdowns/percent-dropdown/PercentDropdown.vue'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  name: 'FinalTextFormPercent',
  components: {
    PercentDropdown,
    Icon,
    Wysiwyg,
  },
  effector: {
    setInput,
    $finalTextDropdownsPercent,
  },
  methods: {
    addBtnActive(input: FinalTextRelated, id: number) {
      return (
        input.score_min !== null &&
        input.score_max !== null &&
        id === this.$finalTextDropdownsPercent.length - 1
      )
    },
    setScoreInput(id: number, field: string, value: DropdownItem) {
      const dropdowns = this.$finalTextDropdownsPercent.map(
        (input: FinalTextRelated, index: number) => {
          if (index === id) {
            const numberValue = value ? Number(value.name) : null
            return {
              ...input,
              score_min: field === 'score_min_item' ? numberValue : input.score_min,
              score_max: field === 'score_max_item' ? numberValue : input.score_max,
              score_min_item: field === 'score_min_item' ? value : input.score_min_item,
              score_max_item: field === 'score_max_item' ? value : input.score_max_item,
            }
          }
          return input
        }
      )
      setInput(dropdowns)
    },
    setText(id: number, value: string) {
      const dropdowns = this.$finalTextDropdownsPercent.map(
        (input: FinalTextRelated, index: number) => {
          if (index === id) {
            return {
              ...input,
              text: value,
            }
          }
          return input
        }
      )
      setInput(dropdowns)
    },
    addItem() {
      setInput([
        ...this.$finalTextDropdownsPercent,
        { ...defaultFinalText, id: this.$finalTextDropdownsPercent.length, isNew: true },
      ])
    },
    removeItem(id: number) {
      const dropdowns = this.$finalTextDropdownsPercent.filter(
        (input: FinalTextRelated) => input.id !== id
      )
      setInput(dropdowns)
    },
  },
  beforeDestroy() {
    clearFields()
  },
})
