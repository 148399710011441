import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import ExamsFullTestsListDropdown from '@/pages/common/dropdowns/exams/exams-full-tests/ExamsFullTestsListDropdown.vue'
import AttemptsPassStatusFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/attempts-pass-status-dropdown/AttemptsPassStatusFilterDropdown.vue'
import GroupsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/GroupsFilterDropdown.vue'
// import StudentsAttemptsFilter from '@/pages/exams/attempts/list/parts/filter/parts/students-attempts-filter/StudentsAttemptsFilter.vue'
import StudentsExamsFullAnswerFilter from '@/pages/exams/full-time-answers/list/parts/students-exams-full-answer-filter/StudentsExamsFullAnswerFilter.vue'
import ProctorsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/ProctorsFilterDropdown.vue'
import ResultsStatusAttemptsFilter from '@/pages/exams/full-attempts/list/parts/filter/parts/results-status-filter/ResultsStatusAttemptsFilter.vue'

export const dropdownAttemptAllComponents = {
  GroupsFilterDropdown,
  StudentsExamsFullAnswerFilter,
  SubjectsDropdown,
  ClassesDropdown,
  ExamsFullTestsListDropdown,
  AttemptsPassStatusFilterDropdown,
  ProctorsFilterDropdown,
  ResultsStatusAttemptsFilter,
}
