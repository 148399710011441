import { createEvent, guard, restore } from 'effector-root'
// import { proctorsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/proctors-filter-dropdown.model'

export const resetModalsData = createEvent()

export const modalVisibilityChanged = createEvent<boolean>()
export const $modalVisibility = restore(modalVisibilityChanged, false)

export const setActiveProctors = createEvent<boolean>()
export const $activeProctors = restore(setActiveProctors, false).reset(resetModalsData)

export const setEditStudentId = createEvent<number>()
export const $studentId = restore(setEditStudentId, 0).reset(resetModalsData)

guard({
  source: modalVisibilityChanged,
  filter: (payload) => !payload,
  target: resetModalsData,
})
