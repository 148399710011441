import { createApiEffect } from '@/features/api/common/create-api-effect'
import { updateQuestionParams } from '@/features/api/results/types'
import { createEffect } from 'effector'

export const updateResultStudentsCoursesListFx = createApiEffect<updateQuestionParams, Blob>({
  requestMapper: (params) => {
    const { answer_obj_id } = params
    const body = {
      ...('commentary' in params && params.commentary ? { commentary: params.commentary } : {}),
      ...('status' in params && params.status ? { status: params.status } : {}),
      ...('mark' in params && params.mark ? { score: params.mark } : {}),
    }
    return {
      method: 'PATCH',
      url: `/api/lesson-app/employee/answers/${answer_obj_id}/`,
      body,
    }
  },
})

export const sendResultStudentsCoursesListFx = createEffect(
  async (data: updateQuestionParams[]) => {
    return Promise.all(
      data.map(async (params) => {
        await updateResultStudentsCoursesListFx(params)
      })
    )
  }
)
