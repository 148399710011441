import { DropdownItem } from '@/pages/common/types'

export const tasksChoiceMapData: DropdownItem[] = [
  {
    name: '0',
    title: 'Свободный',
  },
  {
    name: '1',
    title: 'Последовательный',
  },
]
