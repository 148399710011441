import { createApiEffect } from '@/features/api/common/create-api-effect'
import { FiltersList, StudentsCount, StudentsFiltersList } from '@/features/api/learning/types'
import { GetGroupsForIdParams, GetStudentsForIdParams } from '@/features/api/results/types'

export const getFilterLessonsByIdFx = createApiEffect<number, { lessons: FiltersList[] }>({
  requestMapper: (course) => ({
    method: 'GET',
    url: `/api/results-app/results/courses/${course}`,
  }),
})

export const getFilterGroupsByIdFx = createApiEffect<GetGroupsForIdParams, FiltersList[]>({
  requestMapper: (params) => ({
    method: 'GET',
    url: `/api/results-app/results/courses/${params.course}/${params.lesson}/groups/`,
  }),
})

export const getFilterStudentsByIdFx = createApiEffect<
  GetStudentsForIdParams,
  StudentsFiltersList[]
>({
  requestMapper: (params) => {
    const { group, status } = params
    const query =
      group || status
        ? {
            ...(group ? { group } : {}),
            ...(status ? { status } : {}),
          }
        : null
    return {
      method: 'GET',
      url: `/api/results-app/results/courses/${params.course}/${params.lesson}/`,
      ...(query ? { query } : {}),
    }
  },
})

export const getStudentsCountFx = createApiEffect<GetStudentsForIdParams, StudentsCount[]>({
  requestMapper: (params) => {
    const { group, status } = params
    const query =
      group || status
        ? {
            ...(group ? { group } : {}),
            ...(status ? { status } : {}),
          }
        : null
    return {
      method: 'GET',
      url: `/api/results-app/results/courses/${params.course}/${params.lesson}/statuses/`,
      ...(query ? { query } : {}),
    }
  },
})
