
























































import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  setInput,
  $finalTextDropdownsPoints,
  defaultFinalText,
  clearFields,
} from '@/pages/common/parts/tests/parts/final-text-form-points/final-text-points-form.model'
import { FinalTextRelated } from '@/features/api/test/types'
import { $maxPoints } from '@/pages/common/parts/tests/manual-generation/manual-generation-form.model'
import PointsDropdown from '@/pages/common/dropdowns/value-change-dropdowns/point-dropdown/PointDropdown.vue'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  name: 'FinalTextFormPoints',
  components: {
    PointsDropdown,
    Icon,
    Wysiwyg,
  },
  effector: {
    setInput,
    $finalTextDropdownsPoints,
    $maxPoints,
  },
  methods: {
    addBtnActive(input: FinalTextRelated, id: number) {
      return (
        input.score_min !== null &&
        input.score_max !== null &&
        id === this.$finalTextDropdownsPoints.length - 1
      )
    },
    setInputValue(id: number, field: string, value: DropdownItem) {
      const dropdowns = this.$finalTextDropdownsPoints.map(
        (input: FinalTextRelated, index: number) => {
          if (index === id) {
            const numberValue = value ? Number(value.name) : null
            return {
              ...input,
              score_min: field === 'score_min_item' ? numberValue : input.score_min,
              score_max: field === 'score_max_item' ? numberValue : input.score_max,
              score_min_item: field === 'score_min_item' ? value : input.score_min_item,
              score_max_item: field === 'score_max_item' ? value : input.score_max_item,
            }
          }
          return input
        }
      )
      setInput(dropdowns)
    },
    setText(id: number, value: string) {
      const dropdowns = this.$finalTextDropdownsPoints.map(
        (input: FinalTextRelated, index: number) => {
          if (index === id) {
            return {
              ...input,
              text: value,
            }
          }
          return input
        }
      )
      setInput(dropdowns)
    },
    addItem() {
      setInput([
        ...this.$finalTextDropdownsPoints,
        { ...defaultFinalText, id: this.$finalTextDropdownsPoints.length, isNew: true },
      ])
    },
    removeItem(id: number) {
      const dropdowns = this.$finalTextDropdownsPoints.filter(
        (input: FinalTextRelated) => input.id !== id
      )
      setInput(dropdowns)
    },
  },
  beforeDestroy() {
    clearFields()
  },
})
