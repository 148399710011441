import { attach, createEvent, forward, restore } from 'effector-root'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { createStore } from 'effector'
import { TableField } from '@/pages/applications/types'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { coursesResultDataFields } from '@/pages/results/courses-all/constants'
import { resultCoursesFilters } from '@/pages/results/courses-all/parts/filter/course-result-filter.model'
import { exportResultCoursesListFx } from '@/features/api/results/export-result-courses-all'
import { createTaskModule } from '@/features/api/tasks/tasks-module'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { updateCourseParams } from '@/pages/results/courses-all/types'
import { updateResultCoursesAllFx } from '@/features/api/results/update-result-courses-all'
import { successToastEvent, errorToastEvent } from '@/features/toasts/toasts.model'

type SetColumnsColumns = {
  [key: string]: boolean
}

export const saveCommentStudentsCourseAll = createEvent<updateCourseParams>()

const updResultStudentsCourseAll = attach({
  effect: updateResultCoursesAllFx,
})

export const incomingResultCoursesPageParams = createPageParamsModel()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore<boolean>(setIsLoading, false)

export const canRefreshTableChanged = createEvent<boolean>()
export const $canRefreshTable = restore(canRefreshTableChanged, false)

export const setEditData = createEvent<{ [key: number]: updateCourseParams }>()
export const $editData = restore(setEditData, {})

export const setNewTableFields = createEvent<SetColumnsColumns>()
export const addTableFields = createEvent<TableField[]>()
export const setDefaultTableFields = createEvent<void>()
export const $tableFields = createStore<TableField[]>(coursesResultDataFields)
  .on(setNewTableFields, (fields, settings) =>
    coursesResultDataFields.filter((field) => settings[field.name])
  )
  .on(addTableFields, (_, fields) => fields)
  .reset(setDefaultTableFields, reset)

const exportResultCoursesList = attach({
  effect: exportResultCoursesListFx,
  source: [resultCoursesFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

forward({
  from: saveCommentStudentsCourseAll,
  to: updResultStudentsCourseAll,
})

export const taskLoader = createTaskModule(exportResultCoursesList, 'resultCoursesList')

forward({
  from: [updResultStudentsCourseAll.doneData],
  to: [
    successToastEvent('Успешно сохранено!'),
    setEditData.prepend(() => ({})),
    canRefreshTableChanged.prepend(() => true),
    setEditData.prepend(() => ({})),
  ],
})
forward({
  from: [updResultStudentsCourseAll.fail],
  to: [errorToastEvent('Сохранить не удалось')],
})
