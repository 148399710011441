











import Vue from 'vue'
import ResourcesHeader from '@/pages/dictionary/resources/create/parts/header/Header.vue'
import ResourceContent from '@/pages/dictionary/resources/create/parts/Content.vue'
import {
  create,
  getTheme,
  $formToSend,
  clearFields,
  redirectAfterSaveChanged,
} from '@/pages/dictionary/resources/create/resource-creation-page.model'

export default Vue.extend({
  name: 'ResourceCreationPage',
  effector: {
    $formToSend,
  },
  components: {
    ResourcesHeader,
    ResourceContent,
  },
  methods: {
    redirectAfterSaveChanged,
    clearFields,
    create,
    createWithRedirect() {
      redirectAfterSaveChanged(true)
      create()
    },
  },
  mounted() {
    const { id } = this.$route.params
    if (id) {
      getTheme(+id)
    }
  },
  beforeDestroy() {
    redirectAfterSaveChanged(false)
    clearFields()
  },
})
