import { createApiEffect } from '@/features/api/common/create-api-effect'
import { updateCourseParams } from '@/pages/results/courses-all/types'

export const updateResultCoursesAllFx = createApiEffect<updateCourseParams, Blob>({
  requestMapper: (params) => {
    const { course_id, student_id } = params
    const body = {
      ...('commentary' in params && params.commentary
        ? { commentary: params.commentary }
        : { commentary: null }),
    }
    return {
      method: 'PATCH',
      url: `/api/results-app/results/courses/${course_id}/${student_id}/commentary/`,
      body,
    }
  },
})
