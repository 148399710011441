
































































import { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import Chip from '@/pages/dictionary/resources/list/parts/tree/parts/Chip.vue'
import { TreeData, TreeElementType } from '@/features/api/types'
import { removeHtmlTags, sortResourcesTreeLeaves, sortTreeLeaves } from '@/features/lib'
import {
  $openedTreeBranches,
  storeOpenedBranch,
} from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { FiltersParams, TreeNodeIdProp } from '@/pages/common/types'
import AutoOpenFolderMixin from '@/features/lib/mixins/AutoOpenFolderMixin'
import { DEFAULT_ID } from '@/pages/common/constants'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { isBranchOpened } from '@/pages/common'

export default AutoOpenFolderMixin({
  filters: {
    theme: (item, search) => !!item.theme?.name.toLowerCase().includes(search.toLowerCase()),
    study_year: (item, search) =>
      !!item.study_year?.name.toLowerCase().includes(search.toLowerCase()),
    subject: (item, search) => !!item.subject?.name.toLowerCase().includes(search.toLowerCase()),
  },
}).extend({
  name: 'TreeNode',
  components: {
    Icon,
    Chip,
    ActionsButton,
  },
  props: {
    node: { type: Object as PropType<TreeData> },
    parent: { type: Boolean, default: false },
    prerequisiteFolder: { type: Boolean, default: false },
    isPrerequisite: { type: Boolean as PropType<boolean>, default: false },
    nodeId: { type: [Number, String] as PropType<TreeNodeIdProp> },
    filters: { type: Object as PropType<FiltersParams> },
    firstChild: { type: Boolean, default: false },
    lastChild: { type: Boolean, default: false },
    parentNodeId: { type: [Number, String] as PropType<TreeNodeIdProp>, default: null },
    parentNodeType: { type: String as PropType<TreeElementType>, default: null },
  },
  data: () => ({
    opened: false,
  }),
  computed: {
    title() {
      const type = this.node.element_type
      let fullName = ''
      if (type !== 'study_resource' && type !== 'assignment' && type !== 'media') {
        const entity = this.node[type]
        fullName = entity ? entity.name : ''
        if (fullName.length > 100) {
          fullName = `${fullName.slice(0, 100)}...`
        }
      } else if (type !== 'assignment' && type !== 'media') {
        const entity = this.node[type]
        if (!entity) fullName = ''
        else if (entity.text && entity.text.length) fullName = removeHtmlTags(entity.text)
        else if (entity.file_name && entity.file_name.length)
          fullName = entity.file_name.slice(entity.file_name.lastIndexOf('/') + 1)
        else if (entity.link && entity.link.length) fullName = entity.link
        fullName = fullName.length > 30 ? `${fullName.slice(0, 30)}...` : fullName
      }
      return fullName
    },
    resources(): { [key in string]: { count: number; description: string } } {
      return {
        videos: {
          count: (this.node.theme && this.node.theme.all_video_amount) || 0,
          description: 'Количество ресурсов типа "Видео"',
        },
        texts: {
          count: (this.node.theme && this.node.theme.all_texts_amount) || 0,
          description: 'Количество ресурсов типа "Текст"',
        },
        links: {
          count: (this.node.theme && this.node.theme.all_links_amount) || 0,
          description: 'Количество ресурсов типа "Ссылка"',
        },
        files: {
          count: (this.node.theme && this.node.theme.all_files_amount) || 0,
          description: 'Количество ресурсов типа "Файл"',
        },
      }
    },
    showActions() {
      const { element_type } = this.$props.node
      return element_type === 'study_resource' || element_type === 'theme'
    },
    nodeLeavesLength(): number {
      return this.node.leaves.length
    },
  },
  watch: {
    opened: {
      handler(newVal) {
        if (newVal) {
          const { leaves } = this.node
          if (leaves.some((el) => el.element_type === 'study_resource')) {
            this.node.leaves = sortResourcesTreeLeaves(leaves)
          } else this.node.leaves = sortTreeLeaves(leaves)
          this.loadFolderData()
        }
        storeOpenedBranch({
          id: this.nodeId,
          opened: newVal as boolean,
          type: this.node.element_type,
          parentNodeId: this.parentNodeId,
          parentNodeType: this.parentNodeType,
          isPrerequisite: this.isPrerequisite,
        })
      },
    },
    nodeLeavesLength: {
      handler(newVal) {
        if (newVal && this.opened) {
          const { leaves } = this.node
          if (leaves.some((el) => el.element_type === 'study_resource')) {
            this.node.leaves = sortResourcesTreeLeaves(leaves)
          } else this.node.leaves = sortTreeLeaves(leaves)
        }
      },
    },
  },
  methods: {
    toggle(evt: any) {
      if (evt.target.closest('.action') || this.node.element_type === 'study_resource') return
      this.opened = !this.opened
    },
    loadFolderData() {
      if (
        (!this.node.leaves.length ||
          this.node.leaves.every((leaf) => leaf.element_type === 'theme')) &&
        this.node.element_type === 'theme'
      ) {
        const { subject_id, study_year_id, id } = this.node[this.node.element_type]!
        this.$emit('loadTree', {
          subject: subject_id,
          study_year: study_year_id,
          theme: id,
          is_prerequisite: this.prerequisiteFolder ? true : undefined,
        })
      }
    },
    getType(): ContextMenuType {
      if (this.node.element_type === 'theme') return 'folder'
      return 'item'
    },
    handleActionsButtonClick(event: MouseEvent) {
      const type = this.getType()
      this.$emit('onActionsClick', {
        data: {
          id: this.nodeId,
          theme: this.node.theme ? this.node.theme.id : DEFAULT_ID,
        },
        event,
        type,
      })
    },
    handleRightClick(event: Event) {
      const type = this.getType()
      this.$emit('onRightClick', {
        data: {
          id: this.nodeId,
          theme: this.node.theme ? this.node.theme.id : DEFAULT_ID,
        },
        event,
        type,
      })
    },
  },
  mounted() {
    const { element_type } = this.$props.node
    if (element_type === 'theme' || element_type === 'study_resource') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}-${element_type}`)
      nodeElement && nodeElement.addEventListener('contextmenu', this.handleRightClick)
    }
    this.opened = isBranchOpened(
      $openedTreeBranches,
      this.nodeId,
      this.node.element_type,
      this.parentNodeId,
      this.parentNodeType,
      this.isPrerequisite
    )
    if (this.filters.search) {
      this.searchString = this.filters.search_area
        ? this.filters.search_area.slice(this.filters.search_area?.indexOf('_') + 1)
        : ''
      this.autoOpenFolders([this.node])
    }
  },
  beforeDestroy() {
    const { element_type } = this.$props.node
    if (element_type === 'theme' || element_type === 'study_resource') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}-${element_type}`)
      nodeElement && nodeElement.removeEventListener('contextmenu', this.handleRightClick)
    }
  },
})
