import { attach, createEvent, forward, combine, createStore } from 'effector-root'
import { getCertificatesGeneratorListFX } from '@/features/api/results/certificates-generator/get-certificates-generator-list'
import { deleteCertificatesFX } from '@/features/api/results/certificates-generator/delete-certificates'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'

const getCertificatesGeneratorList = attach({
  effect: getCertificatesGeneratorListFX,
  mapParams: () => ({}),
})

export const certificatesGeneratorTablePageMounted = createEvent<void>()

export const $isLoading = combine(getCertificatesGeneratorListFX.pending, (list) => list)

const needRefresh = createEvent<boolean>()

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(certificatesGeneratorTablePageMounted)

forward({
  from: certificatesGeneratorTablePageMounted,
  to: getCertificatesGeneratorList,
})

export const deleteCertificates = attach({
  effect: deleteCertificatesFX,
  mapParams: (payload: number[]) => {
    return {
      body: {
        certificate_ids: payload,
      },
    }
  },
})

forward({
  from: deleteCertificates.done,
  to: [
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    needRefresh.prepend(() => true),
  ],
})
