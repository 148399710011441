<template>
  <div class="question-answers-form">
    <div class="left-border" />
    <BaseCheckbox
      option="reorder"
      class="reorder-checkbox"
      :value="$reorderEnabled"
      @change="toggleReorderEnabling"
    >
      Отключить перемешивание
    </BaseCheckbox>
    <div
      v-for="(match, idx) in $matches"
      :key="match.id"
      class="matches"
    >
      <div class="match">
        <div v-if="idx === 0" class="label">А</div>
        <Wysiwyg
          :editor-id="`wysiwyg-a-${idx}`"
          class="wysiwyg"
          :value="match.matchA"
          @input="matchA => filled && handleMatchAChange({ id: match.id, matchA })"
          @imgId="val => handleImgId(val, originArrA)"
          @imgSrc="val => handleImgSrc(val, originArrA)"
          @linkPaste="val => handleLinkPaste(match.id, val, originArrA)"
        />
      </div>
      <div class="match">
        <div v-if="idx === 0" class="label">Б</div>
        <Wysiwyg
          :editor-id="`wysiwyg-b-${idx}`"
          class="wysiwyg"
          :value="match.matchB"
          @input="matchB => filled && handleMatchBChange({ id: match.id, matchB })"
          @imgId="val => handleImgId(val, originArrB)"
          @imgSrc="val => handleImgSrc(val, originArrB)"
          @linkPaste="val => handleLinkPaste(match.id, val, originArrB)"
        />
      </div>
      <div
        v-if="$matches.length > 1"
        :class="{ transparent: true, 'icon-btn': true, 'first-icon': idx === 0 }"
        @click="removeMatch({ id: match.id })"
      >
        <Icon
          class="icon-close"
          type="close"
          size="8"
        />
      </div>
    </div>
    <div class="add-question">
      <BaseButton @click="addMatch">Добавить сопоставление</BaseButton>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseCheckbox from '@/ui/checkbox/BaseCheckbox.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  $matches,
  setMatches,
  $reorderEnabled,
  toggleReorderEnabling,
  taskTypeDataPrepared,
  $taskTypeDataReady,
  toggleTaskTypeDataReady,
} from '@/pages/common/parts/tasks/connect-lines-answer/connect-lines-answer.model'
import { getRandomId } from '@/pages/common/parts/tasks/utils'
import { DEFAULT_ID } from '@/pages/common/constants'

export default Vue.extend({
  name: 'CorrectAnswerForm',
  components: {
    Icon,
    Wysiwyg,
    BaseButton,
    BaseCheckbox,
  },
  effector: {
    $matches,
    $reorderEnabled,
    $taskTypeDataReady,
  },
  data() {
    return {
      maxWidth: 200,
      originArrA: [],
      originArrB: [],
      filled: false,
    }
  },
  watch: {
    $taskTypeDataReady: {
      handler(ready) {
        ready && this.prepareMatches()
      },
    },
  },
  methods: {
    taskTypeDataPrepared,
    toggleReorderEnabling,
    prepareMatches() {
      this.$matches.forEach((match) => {
        this.prefillOrigin(match.id, this.originArrA, 'matchA')
        this.prefillOrigin(match.id, this.originArrB, 'matchB')
      })
      this.$matches.forEach((match) => {
        match.matchA = this.editSrc(match.id, match.matchA, this.originArrA, 'matchA')
        match.matchB = this.editSrc(match.id, match.matchB, this.originArrB, 'matchB')

        const matches = this.$matches.map((m) =>
          m.id === match.id ? { ...m, matchA: match.matchA, matchB: match.matchB } : m
        )
        setMatches(matches)
      })
      taskTypeDataPrepared()
    },
    handleImageSize(img) {
      const imgCheck = img.indexOf('<img')
      if (imgCheck !== DEFAULT_ID) {
        const width = img.match(new RegExp('(?:width: )(\\d+?)(?=px;)'))[1]
        if (+width > this.maxWidth) {
          img = img.replace(`width: ${width}`, `width: ${this.maxWidth}`)
          const height = img.match(new RegExp('(?:height: )(\\d+?)(?=px;)'))[1]
          if (height) {
            const ratio = +height / +width
            const newHeight = Math.floor(this.maxWidth * ratio)
            img = img.replace(`height: ${height}`, `height: ${newHeight}`)
          }
        }
      }
      return img
    },
    getSrc(str) {
      const attrs = str.split(' ')
      const srcAttr = attrs.find((attr) => attr.includes('src="'))
      return (srcAttr && srcAttr.slice(5, srcAttr.length - 1)) || ''
    },
    handleIframePaste(str) {
      const attrs = str.split(' ')
      const paragraphTag = attrs.find((attr) => attr.match(new RegExp('<p>[^<p>]*<iframe')))
      const paragraphTagIndex = attrs.findIndex((attr) =>
        attr.match(new RegExp('<p>[^<p>]*<iframe'))
      )
      const videodetectorClass = attrs.findIndex((attr) => attr.includes('class="videodetector"'))
      if (paragraphTag && videodetectorClass === DEFAULT_ID) {
        const paragraphIndex = paragraphTag.indexOf('<p>')
        attrs[paragraphTagIndex] = `${paragraphTag.slice(
          0,
          paragraphIndex
        )}<div class="videodetector">${paragraphTag.slice(paragraphIndex + 3)}`

        const paragraphTagEnd = attrs.find((attr) => attr.match(new RegExp('</iframe>[^<p>]*</p>')))
        const paragraphTagEndIndex = attrs.findIndex((attr) =>
          attr.match(new RegExp('</iframe>[^<p>]*</p>'))
        )
        const paragraphEndIndex = paragraphTagEnd.indexOf('</p>')
        attrs[paragraphTagEndIndex] = `${paragraphTagEnd.slice(
          0,
          paragraphEndIndex
        )}</div>${paragraphTagEnd.slice(paragraphEndIndex + 4)}`
        return attrs.join(' ')
      }
      return attrs.join(' ')
    },
    findDuplicateArrays(arrA, arrB, key, value) {
      const arrMatchA = arrA.find((el) => el[key] === value)
      const arrMatchB = arrB.find((el) => el[key] === value)
      return arrMatchA || arrMatchB
    },
    findDuplicateMatches(value, id = DEFAULT_ID) {
      const duplicateSrcA = this.$matches.find(
        (el) => this.getSrc(el.matchA) === value && el.id !== id
      )
      const duplicateSrcB = this.$matches.find(
        (el) => this.getSrc(el.matchB) === value && el.id !== id
      )
      return duplicateSrcA || duplicateSrcB
    },
    handleImgId(img, arr) {
      const el = document.activeElement
      const elImg = el && el.querySelector('p > span')
      if (elImg) {
        document
          .getElementsByClassName('cke cke_focus')[0]
          .querySelector('.cke_button__image_icon')
          .click()
      }
      if (!this.originArrA.length && !this.originArrB.length) {
        arr.push({ src: img, id: null, origin: img })
      } else {
        const duplicate = this.findDuplicateArrays(this.originArrA, this.originArrB, 'src', img)
        duplicate
          ? arr.push({ src: duplicate.origin, id: null, origin: duplicate.origin })
          : arr.push({ src: img, id: null, origin: img })
      }
    },
    handleImgSrc(src, arr) {
      const originSrcIndex = src.indexOf('="')
      const originSrc = src.slice(originSrcIndex + 2, src.length - 1)
      const duplicateSrc = this.findDuplicateMatches(originSrc, DEFAULT_ID)
      if (duplicateSrc) {
        const duplicateOrigin = this.findDuplicateArrays(
          this.originArrA,
          this.originArrB,
          'id',
          duplicateSrc.id
        )
        duplicateOrigin &&
          arr.push({ src: duplicateOrigin.origin, id: null, origin: duplicateOrigin.origin })
      }
    },
    handleLinkPaste(id, src, arr) {
      const newSrc = this.getSrc(src)
      const originSrc = this.findDuplicateMatches(newSrc)
      if (originSrc) {
        const existMatch = arr.find((el) => el.id === id)
        const arrMatch = this.findDuplicateArrays(
          this.originArrA,
          this.originArrB,
          'id',
          originSrc.id
        )
        arrMatch && !existMatch && arr.push({ src: arrMatch.origin, id, origin: arrMatch.origin })
      }
    },
    setArrId(id, arr, match) {
      if ((arr.length && arr[arr.length - 1].id) || !arr.length) {
        this.handleLinkPaste(id, match, arr)
      } else if (arr.length) {
        const lastPushed = arr[arr.length - 1]
        if (!lastPushed.id) {
          lastPushed.id = id
        }
      }
    },
    handleDelete(id, arr, arrId) {
      const deleted = this.$matches.find((el) => el.id === id)
      if (deleted === DEFAULT_ID || !deleted[arrId] || !deleted[arrId].includes('<img')) {
        return
      }
      const attrs = deleted[arrId].split(' ')
      const attrId = attrs.findIndex((el) => el.includes('img'))

      if (!attrs[attrId + 2].includes('"for-')) {
        const otherMatch = this.$matches.find(
          (el) => this.getIdFromAttr(el[arrId]) === `for-${this.getIdFromAttr(deleted[arrId])}`
        )
        if (otherMatch) {
          const otherOrigin = arr.find((el) => el.id === otherMatch.id)
          const newMatch = this.deleteFor(otherMatch[arrId].split(' '), otherOrigin)
          const matches = this.$matches.map((m) => {
            if (arrId === 'matchA') {
              return m.id === otherOrigin.id ? { ...m, matchA: newMatch } : m
            }
            return m.id === otherOrigin.id ? { ...m, matchB: newMatch } : m
          })
          setMatches(matches)
        }
      }
    },
    deleteFor(attrs, curr) {
      const idIndex = attrs.findIndex((el) => el.includes('id="for-'))
      if (idIndex !== DEFAULT_ID) {
        // Change in $matches.
        const forIndex = attrs[idIndex].indexOf('for-')
        attrs[idIndex] = attrs[idIndex].slice(0, forIndex) + attrs[idIndex].slice(forIndex + 4)
      }
      // Change in originArr.
      if (curr) {
        const originForIndex = curr.src.indexOf('for-')
        if (originForIndex !== DEFAULT_ID) {
          curr.src = curr.src.slice(originForIndex + 4)
        }
      }
      return attrs.join(' ')
    },
    addFor(attrs, curr) {
      // Change in $matches.
      const idIndex = attrs.findIndex((el) => el.includes('id="'))
      let forIndex = attrs[idIndex].indexOf('="for-')
      if (idIndex !== DEFAULT_ID && forIndex === DEFAULT_ID) {
        forIndex = attrs[idIndex].indexOf('id="')
        attrs[idIndex] = `${attrs[idIndex].slice(0, forIndex + 4)}for-${attrs[idIndex].slice(
          forIndex + 4
        )}`
      }
      // Change in originArr.
      const originForIndex = curr.src.indexOf('for-')
      if (originForIndex === DEFAULT_ID) {
        curr.src = `for-${curr.src}`
      }
    },
    getIdFromAttr(html) {
      const attrs = html.split(' ')
      const id = attrs.find((el) => el.includes('id="'))
      return id ? id.slice(4, id.length - 1) : null
    },
    checkText(newMatch) {
      const textReg = newMatch.match(new RegExp('<img.*?/>'))
      if (!textReg || !textReg[0]) {
        return ''
      }
      const imgTagIndex = newMatch.indexOf(textReg[0])
      const text = newMatch.slice(0, imgTagIndex) + newMatch.slice(imgTagIndex + textReg[0].length)
      const arr = text
        .split(' ')
        .map((a) => {
          a = a.replaceAll(new RegExp('<.*?>', 'g'), '')
          a = a.replaceAll('&nbsp;', '')
          a = a.replaceAll('\n', '')
          return a
        })
        .join('')
      return arr
    },
    prefillOrigin(id, arr, matchId) {
      const match = this.$matches.find((el) => el.id === id)
      if (!match) return

      const origin = arr.find((el) => el.id === id)
      if (!origin) return

      const originId = origin.origin
      const originText = this.checkText(match[matchId])

      if (origin) {
        origin.src = originId + originText
      }
    },
    editSrc(id, currMatch, arr, arrMatch) {
      let newMatch = currMatch
      if (!currMatch) {
        document
          .querySelectorAll('div.videodetector br')
          .forEach((el) => (el.parentElement.parentElement.innerHTML = ''))
      }
      newMatch = this.handleIframePaste(newMatch)
      if (newMatch.includes('videodetector')) {
        if (!newMatch.includes('iframe')) {
          newMatch = newMatch.replace('<div class="videodetector">', '')
          newMatch = newMatch.replace('</div>', '')
        } else {
          const substr = '<p>&nbsp;</p>'
          const strToChange = newMatch.indexOf(substr)
          if (strToChange !== DEFAULT_ID) {
            newMatch = `${
              newMatch.slice(0, strToChange) + newMatch.slice(strToChange + substr.length)
            }`
          }
        }
      }
      if (newMatch.includes('<img')) {
        const currSrc = arr.find((el) => el.id === id)
        if (!currSrc) return newMatch
        const currAttrs = newMatch.split(' ')
        const currText = this.checkText(newMatch)
        const idIndex = currAttrs.findIndex((el) => el.includes('id="'))
        const imgIndex = currAttrs.findIndex((el) => el.includes('<img'))
        const duplicateSrc = arr.find((el) => {
          const duplicateAttrs = this.$matches.find((match) => match.id === el.id)
          const duplicateText = this.checkText(duplicateAttrs[arrMatch])
          return el.src + duplicateText === currSrc.src + currText && el.id !== id
        })
        const originDuplicateSrc = arr.find((el) => {
          const duplicateAttrs = this.$matches.find((match) => match.id === el.id)
          const duplicateText = this.checkText(duplicateAttrs[arrMatch])
          return `for-${el.src + duplicateText}` === currSrc.src + currText && el.id !== id
        })

        if (!duplicateSrc) {
          if (!originDuplicateSrc) {
            currSrc.src = currSrc.origin + currText
          }
        } else if (duplicateSrc) {
          if (currSrc.src.includes('for-')) {
            currSrc.src = `${currSrc.origin + currText}`
          } else {
            currSrc.src = `for-${currSrc.origin + currText}`
          }
        }

        if (idIndex === DEFAULT_ID) {
          currAttrs[imgIndex + 1] += ` id="${currSrc.src}"`
        } else {
          currAttrs[idIndex] = `id="${currSrc.src}"`
        }

        newMatch = currAttrs.join(' ')
      } else {
        const oldImgHandler = arr.findIndex((el) => el.id === id)
        if (oldImgHandler !== DEFAULT_ID) {
          arr.splice(oldImgHandler, 1)
        }
      }
      return newMatch
    },
    replaceEmptyVideodetector(str) {
      const attrs = str.split(' ')
      const dubles = attrs.filter((attr) => attr.includes('videodetector'))

      const clearVideodetector = (val) => {
        val = val.replace('<div class="videodetector">', '')
        val = val.replace('</div>', '')
        return val
      }

      if (dubles.length > 1) {
        str = clearVideodetector(str)
      }

      if (str.includes('videodetector')) {
        if (!str.includes('iframe')) {
          str = clearVideodetector(str)
        }
      }
      return str
    },
    handleMatchAChange({ id, matchA }) {
      matchA = this.replaceEmptyVideodetector(matchA)
      if (matchA.includes('<img')) {
        matchA = this.handleImageSize(matchA)
        this.setArrId(id, this.originArrA, matchA)
      } else {
        const arrMatch = this.originArrA.findIndex((el) => el.id === id)
        arrMatch !== DEFAULT_ID && this.originArrA.splice(arrMatch, 1)
      }

      const matches = this.$matches.map((m) => (m.id === id ? { ...m, matchA } : m))
      setMatches(matches)
    },
    handleMatchBChange({ id, matchB }) {
      matchB = this.replaceEmptyVideodetector(matchB)
      if (matchB.includes('<img')) {
        matchB = this.handleImageSize(matchB)
        this.setArrId(id, this.originArrB, matchB)
      } else {
        const arrMatch = this.originArrB.findIndex((el) => el.id === id)
        arrMatch !== DEFAULT_ID && this.originArrB.splice(arrMatch, 1)
      }

      const matches = this.$matches.map((match) => (match.id === id ? { ...match, matchB } : match))
      setMatches(matches)
    },
    addMatch() {
      setMatches([...this.$matches, { id: getRandomId(), matchA: '', matchB: '' }])
    },
    removeMatch({ id }) {
      this.handleDelete(id, this.originArrA, 'matchA')
      this.handleDelete(id, this.originArrB, 'matchB')

      const deletedMatchA = this.originArrA.findIndex((match) => match.id === id)
      deletedMatchA !== DEFAULT_ID && this.originArrA.splice(deletedMatchA, 1)

      const deletedMatchB = this.originArrB.findIndex((match) => match.id === id)
      deletedMatchB !== DEFAULT_ID && this.originArrB.splice(deletedMatchB, 1)

      const matches = this.$matches.filter((match) => match.id !== id)
      setMatches(matches)
    },
    fillMatches(match, originArr, column) {
      const attrs = match[column].split(' ')
      const attrImg = attrs.findIndex((el) => el.includes('img'))
      const attrId = attrs.findIndex((el) => el.includes('id="'))
      if (attrImg !== DEFAULT_ID && attrId !== DEFAULT_ID && attrs[attrId].includes('id="for-')) {
        const src = attrs[attrId].slice(4, attrs[attrId].length - 1)
        const origin = src.match(new RegExp('for-(.*?(?:png|jpg|jpeg|gif))'))
        originArr.push({
          id: match.id,
          origin: origin[1] || '',
          src,
        })
      } else if (
        attrImg !== DEFAULT_ID &&
        attrId !== DEFAULT_ID &&
        attrs[attrId].includes('id="')
      ) {
        const src = attrs[attrId].slice(4, attrs[attrId].length - 1)
        const origin = src.match(new RegExp('(.*?(?:png|jpg|jpeg|gif))'))
        originArr.push({
          id: match.id,
          origin: origin[1] || '',
          src,
        })
      }
    },
  },
  mounted() {
    if (this.$route.name.includes('-create')) {
      this.$matches.forEach((match) => {
        this.fillMatches(match, this.originArrA, 'matchA')
        this.fillMatches(match, this.originArrB, 'matchB')
      })
      setTimeout(() => (this.filled = true), 200)
      toggleTaskTypeDataReady.prepend(() => false)
    } else {
      this.filled = true
    }
  },
})
</script>

<style scoped>
.wysiwyg {
  max-width: 430px;
  width: 100%;
}

.question-answers-form {
  position: relative;
  display: flex;
  flex-direction: column;
}

.left-border {
  position: absolute;
  left: -30px;
  width: 4px;
  height: 100%;
  background-color: var(--c-yellow-1);
}

.reorder-checkbox {
  margin-bottom: 20px;
}

.matches {
  display: flex;
  margin-bottom: 20px;
}

.match {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 400px;

  & + .match {
    margin-left: 20px;
  }
}

.label {
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 5px;
}

.first-icon {
  margin-top: 20px;
}

.icon-btn {
  max-width: 150px;
  margin-left: 10px;
  cursor: pointer;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: var(--base-text-primary);
}

.transparent {
  background-color: transparent;
}

.icon-close {
  fill: var(--c-grey-3);
}

.add-question {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
