

































































import { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import Chip from '@/pages/tags/parts/tree/Chip.vue'
import { TreeData, TreeElementType } from '@/features/api/types'
import { loadModalToEdit } from '@/pages/tags/parts/modals/tag-edition/tag-edition.modal'
import { loadModal } from '@/pages/tags/parts/modals/tasks/tasks.model'
import { createTagFromTree } from '@/pages/tags/parts/modals/tag-creation/tag-creation.modal'
import { sortTreeLeaves } from '@/features/lib'
import AutoOpenFolderMixin from '@/features/lib/mixins/AutoOpenFolderMixin'
import { FiltersParams, TreeNodeIdProp } from '@/pages/common/types'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { DEFAULT_ID } from '@/pages/common/constants'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import {
  $openedTreeBranches,
  storeOpenedBranch,
} from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { isBranchOpened } from '@/pages/common'

export default AutoOpenFolderMixin({
  filters: {
    name: (item, search) => !!item.olympiad_tag?.name.toLowerCase().includes(search.toLowerCase()),
    study_year: (item, search) =>
      !!item.study_year?.name.toLowerCase().includes(search.toLowerCase()),
    subject: (item, search) => !!item.subject?.name.toLowerCase().includes(search.toLowerCase()),
  },
}).extend({
  name: 'TreeNode',
  components: {
    Icon,
    Chip,
    ActionsButton,
  },
  props: {
    node: { type: Object as PropType<TreeData> },
    parent: { type: Boolean, default: false },
    nodeId: { type: [Number, String] as PropType<TreeNodeIdProp> },
    filters: { type: Object as PropType<FiltersParams> },
    firstChild: { type: Boolean, default: false },
    lastChild: { type: Boolean, default: false },
    parentNodeId: { type: [Number, String] as PropType<TreeNodeIdProp>, default: null },
    parentNodeType: { type: String as PropType<TreeElementType>, default: null },
  },
  data: () => ({
    opened: false,
  }),
  computed: {
    title() {
      const type = this.node.element_type
      let fullName = ''
      if (type !== 'assignment' && type !== 'study_resource' && type !== 'media') {
        const entity = this.node[type]
        fullName = entity ? entity.name : ''
        if (fullName.length > 100) {
          fullName = `${fullName.slice(0, 100)}...`
        }
      }
      return fullName
    },
    resources() {
      return {
        tasks: {
          count: this.node.olympiad_tag ? this.node.olympiad_tag.assignments_count : null,
          description: 'Количество заданий',
        },
        tags: {
          count: this.node.leaves.filter((el) => el.element_type === 'olympiad_tag').length,
          description: 'Количество тегов',
        },
      }
    },
    dataToCreateTag() {
      const { study_year } = this.$props.node
      if (study_year)
        return {
          class_id: study_year.id,
          subject_id: study_year.subject_id,
        }
      return null
    },
    showActions() {
      const { element_type } = this.$props.node
      return element_type === 'olympiad_tag' || element_type === 'study_year'
    },
    nodeLeavesLength(): number {
      return this.node.leaves.length
    },
  },
  watch: {
    opened: {
      handler(newVal) {
        if (newVal) {
          this.node.leaves = sortTreeLeaves(this.node.leaves)
          this.loadFolderData()
        }
        storeOpenedBranch({
          id: this.nodeId,
          opened: newVal as boolean,
          type: this.node.element_type,
          parentNodeId: this.parentNodeId,
          parentNodeType: this.parentNodeType,
        })
      },
    },
    nodeLeavesLength: {
      handler(newVal) {
        if (newVal && this.opened) this.node.leaves = sortTreeLeaves(this.node.leaves)
      },
    },
  },
  methods: {
    loadModalToEdit,
    loadModal,
    createTagFromTree,
    toggle(evt: any) {
      if (evt.target.closest('.action') || this.node.element_type === 'olympiad_tag') return
      this.opened = !this.opened
    },
    loadFolderData() {
      if (!this.node.leaves.length && this.node.element_type === 'study_year') {
        const { subject_id, id } = this.node[this.node.element_type]!
        this.$emit('loadTree', { subject: subject_id, study_year: id })
      }
    },
    getType(): ContextMenuType {
      if (this.node.element_type === 'study_year') return 'folder'
      return 'item'
    },
    getTreeData(): { subject_id: number; class_id: number } {
      if (this.node.element_type === 'olympiad_tag' && this.node.olympiad_tag) {
        return {
          subject_id: this.node.olympiad_tag.subject_id,
          class_id: this.node.olympiad_tag.study_year_id,
        }
      }
      if (this.node.element_type === 'study_year' && this.node.study_year) {
        return {
          subject_id: this.node.study_year.subject_id,
          class_id: this.node.study_year.id,
        }
      }
      return {
        subject_id: DEFAULT_ID,
        class_id: DEFAULT_ID,
      }
    },
    treeActionsButtonClick(event: MouseEvent) {
      const type = this.getType()
      this.$emit('onActionsClick', {
        data: {
          id: this.nodeId,
          ...this.getTreeData(),
        },
        event,
        type,
      })
    },
    handleRightClick(event: Event) {
      const type = this.getType()
      this.$emit('onRightClick', {
        data: {
          id: this.nodeId,
          ...this.getTreeData(),
        },
        event,
        type,
      })
    },
  },
  mounted() {
    const type = this.$props.node.element_type
    if (type === 'study_year' || type === 'olympiad_tag') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}`)
      nodeElement && nodeElement.addEventListener('contextmenu', this.handleRightClick)
    }
    this.opened = isBranchOpened(
      $openedTreeBranches,
      this.nodeId,
      this.node.element_type,
      this.parentNodeId,
      this.parentNodeType
    )
    if (this.filters.search) {
      this.searchString = this.filters.search_area
        ? this.filters.search_area.slice(this.filters.search_area?.indexOf('_') + 1)
        : ''
      this.autoOpenFolders([this.node])
    }
  },
  beforeDestroy() {
    const type = this.$props.node.element_type
    if (type === 'study_year' || type === 'olympiad_tag') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}`)
      nodeElement && nodeElement.removeEventListener('contextmenu', this.handleRightClick)
    }
  },
})
