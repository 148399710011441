import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportCertificatesGeneratorQueryParams } from '@/features/api/results/certificates-generator/types'

export const exportCertificatesGeneratorListFX = createApiEffect<
  ExportCertificatesGeneratorQueryParams,
  Blob
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/certificate-app/certificates/list/export/',
    query,
    responseType: 'arraybuffer',
  }),
})
