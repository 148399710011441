









































import Vue from 'vue'
import { urlEncoder } from '@/pages/results/answer-types/wrappers/helpers'

export default Vue.extend({
  name: 'MovingImagesImageInputAnswer',
  props: {
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    correctAnswer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    answerData() {
      try {
        if (this.answerType === 'student') {
          // Array.isArray(this.correctAnswer.draggable)
          // this.answer.droppable && Array(Object.keys(this.answer.droppable).length)
          const imageIDS: number[] =
            this.answer.droppable && Array(this.correctAnswer.draggable.length)
          const textValues: string[] =
            this.answer.inputs && Array(Object.keys(this.answer.inputs).length)
          this.question?.droppable?.length > 0 &&
            // @ts-ignore
            Object.entries(this.answer.droppable).forEach((arr: [string, number]) => {
              // eslint-disable-next-line prefer-destructuring
              imageIDS[Number(arr[0]) - 1] = Number(arr[1])
            })
          this.answer.inputs &&
            // @ts-ignore
            Object.entries(this.answer.inputs).forEach((arr: [string, string]) => {
              // eslint-disable-next-line prefer-destructuring
              textValues[Number(arr[0]) - 1] = arr[1] ? arr[1] : 'Нет ответа от студента'
            })
          if (
            Object.keys(this.question?.inputs).length > 0 &&
            this.question?.droppable?.length > 0 &&
            this.question?.draggable?.length > 0
          ) {
            const imagesSRC = imageIDS.map((imageID) => {
              const images = this.question.draggable.filter((el: any) => el.id === imageID)
              return images ? urlEncoder(images[0]?.image) : null
            })
            return { textValues, imagesSRC, type: 'both' }
          }
          if (this.question.droppable?.length > 0 && this.question.draggable?.length > 0) {
            const imagesSRC = imageIDS.map((imageID) => {
              const images = this.question.draggable.filter((el: any) => el.id === imageID)
              return images ? urlEncoder(images[0]?.image) : null
            })
            return { imagesSRC, type: 'only-images' }
          }
          if (
            typeof this.answer === 'object' &&
            !!this.answer.inputs &&
            Object.keys(this.answer.inputs).length > 0
          ) {
            return { textValues, type: 'only-text' }
          }
        }

        if (this.answerType === 'correct') {
          const spreadedCorrectAnswer = Array.isArray(this.correctAnswer.draggable) && [
            ...this.correctAnswer.draggable,
          ]
          const imageIDS: boolean | number[] =
            spreadedCorrectAnswer &&
            spreadedCorrectAnswer
              .sort((a: { id: number; value: string }, b: { id: number; value: string }) => {
                if (a.value > b.value) {
                  return 1
                }
                if (a.value < b.value) {
                  return -1
                }
                return 0
              })
              .map((el: { id: number; value: string }) => el.id)
          const textValues: string[] =
            Array.isArray(this.correctAnswer.inputs) &&
            this.correctAnswer.inputs.map(
              (arr: any) =>
                arr.value.map((el: { value: number; id: number }) =>
                  el.value ? el.value : 'Нет правильного ответа'
                )[0]
            )
          if (
            imageIDS &&
            textValues &&
            typeof this.correctAnswer === 'object' &&
            Array.isArray(this.correctAnswer.inputs) &&
            Array.isArray(this.correctAnswer.draggable)
          ) {
            const imagesSRC = imageIDS.map((imageID) => {
              const images = this.question.draggable.filter((el: any) => el.id === imageID)
              return images ? urlEncoder(images[0]?.image) : null
            })
            return { textValues, imagesSRC, type: 'both' }
          }
          if (
            imageIDS &&
            typeof this.correctAnswer === 'object' &&
            Array.isArray(this.correctAnswer.draggable)
          ) {
            const imagesSRC = imageIDS.map((imageID) => {
              const images = this.question.draggable.filter((el: any) => el.id === imageID)
              return images ? urlEncoder(images[0]?.image) : null
            })
            return { imagesSRC, type: 'only-images' }
          }
          if (
            textValues &&
            typeof this.correctAnswer === 'object' &&
            Array.isArray(this.correctAnswer.inputs)
          ) {
            return { textValues, type: 'only-text' }
          }
        }

        return this.answerType === 'student' ? this.answer : this.correctAnswer
      } catch (e) {
        console.error(e)
        return []
      }
    },
  },
})
