import { attach } from 'effector'
import { RequestDeleteTestsParams } from '@/features/api/test/types'
import { deleteTestFx, requestDeleteTestsFx } from '@/features/api/test/delete-test'
import { createEvent, forward, restore } from 'effector-root'
import { testsFilters } from '@/pages/testing/tests/list/parts/tests-filter/tests-filter.model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { exportTestsListFx } from '@/features/api/test/export-tests'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import fileDownload from 'js-file-download'

export const deleteTests = attach({
  effect: deleteTestFx,
})

export const requestDeleteTests = attach({
  effect: requestDeleteTestsFx,
  mapParams: (payload: RequestDeleteTestsParams): RequestDeleteTestsParams => {
    return {
      tests: payload.tests,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore<boolean>(setIsLoading, false)

export const canRefreshTableChanged = createEvent<boolean>()
export const $canRefreshTable = restore(canRefreshTableChanged, false)

export const exportTestsList = attach({
  effect: exportTestsListFx,
  source: [testsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportTestsList.doneData.watch((res) => fileDownload(res.body, 'testsList.xlsx'))

forward({
  from: deleteTests.doneData,
  to: [
    successToastEvent('Тест(ы) был(и) успешно удалены'),
    canRefreshTableChanged.prepend(() => true),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
  ],
})

forward({
  from: requestDeleteTests.doneData,
  to: [
    canRefreshTableChanged.prepend(() => true),
    successToastEvent('Отправлена заявка на удаление'),
    requestDeleteModalVisibilityChanged.prepend(() => false),
  ],
})
