

































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  components: {
    Icon,
  },
  props: {
    disabled: { type: Boolean as PropType<boolean> },
    value: { type: Boolean as PropType<boolean> },
    name: { type: String as PropType<string>, default: 'ckbx' },
  },
})
