import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteAppointments } from '@/pages/exams/appointments/list/model/types'

export const requestDeleteAppointmentsFx = createApiEffect<RequestDeleteAppointments, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/exams-app/part-time/appointments/list/bulk-delete/',
    body,
  }),
})
