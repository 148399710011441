













































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  resetAnswers,
  $studentAnswer,
  $correctAnswer,
  $comparingAnswerModalVisibility,
  comparingAnswerModalVisibilityChanged,
} from '@/pages/common/modals/comparing-answer-modal/comparing-answer-modal.model'
import Answers from '@/pages/results/answer-types/Answers.vue'

export default Vue.extend({
  name: 'ComparingAnswerModal',
  components: {
    Modal,
    BaseButton,
    Answers,
  },
  props: {
    inputFormat: {
      type: String,
      default: 'tests',
    },
  },
  effector: {
    $comparingAnswerModalVisibility,
    $studentAnswer,
    $correctAnswer,
  },
  methods: {
    getCorrectAnswerType(data: any) {
      switch (data.element_type) {
        case 'answer_file_field':
          return 'BROAD_FILE_ANSWER'
        case 'answer_text_field':
          return 'BROAD_OPEN_ANSWER'
        default:
          return this.inputFormat === 'tests' ? data.task_type : data.task.type
      }
    },
    comparingAnswerModalVisibilityChanged,
    studentAnswer() {
      if (!this.$studentAnswer)
        return {
          type: null,
          files: null,
          question: null,
          answer: null,
          correctAnswer: null,
        }
      if (this.inputFormat === 'courses')
        return {
          type: this.getCorrectAnswerType(this.$studentAnswer) || null,
          files: this.$studentAnswer.answer_obj.answer_files,
          question:
            this.$studentAnswer.task_type !== null ? this.$studentAnswer.task.question_data : null,
          answer: this.$studentAnswer.answer_obj.answer,
          correctAnswer: this.$studentAnswer.answer_obj.correct_answer,
        }
      return {
        type: this.$studentAnswer.type,
        files: this.$studentAnswer.answer_files,
        question: this.$studentAnswer.question_data,
        answer: this.$studentAnswer.answer,
        correctAnswer: this.$studentAnswer.correct_answer,
      }
    },
    correctAnswer() {
      if (!$correctAnswer)
        return {
          type: null,
          files: null,
          question: null,
          answer: null,
          correctAnswer: null,
        }
      if (this.inputFormat === 'courses')
        return {
          type: this.getCorrectAnswerType(this.$correctAnswer) || null,
          files: this.$correctAnswer.answer_obj.answer_files,
          question:
            this.$correctAnswer.task_type !== null ? this.$correctAnswer.task.question_data : null,
          answer: this.$correctAnswer.answer_obj.answer,
          correctAnswer: this.$correctAnswer.answer_obj.correct_answer,
        }
      return {
        type: this.$correctAnswer.type,
        files: this.$correctAnswer.answer_files,
        question: this.$correctAnswer.question_data,
        answer: this.$correctAnswer.answer,
        correctAnswer: this.$correctAnswer.correct_answer,
      }
    },
  },
  deactivated() {
    resetAnswers()
  },
})
