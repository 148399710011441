












































import Vue, { PropType } from 'vue'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Loader from '@/pages/common/parts/loader/Loader.vue'
import { loadModalForMultiChanges } from '@/pages/exams/full-attempts/list/modals/attempts-update/full-attempts-update-modal.model'
import { $permissions } from '@/features/session'
import {
  taskLoader,
  archiveTaskLoader,
} from '@/pages/exams/full-attempts/list/model/full-attempts-filters.model'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'FullAttemptsHeader',
  components: {
    DownloadButton,
    GridPageHead,
    HeaderPopup,
    BaseButton,
    Icon,
    Loader,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<number[]> },
  },
  effector: {
    $permissions,
    isLoading: taskLoader.store.$exportTaskLoading,
    isExportArchiveLoading: archiveTaskLoader.store.$exportTaskLoading,
  },
  methods: {
    loadModalForMultiChanges,
  },
})
