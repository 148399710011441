











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  coursesDropdownModel,
  loadFilterOptions,
  setSelectedType,
  $courses,
} from '@/pages/learning/learning-lessons/list/model/tooltip-filter.model'
import { DropdownItem } from '@/pages/common/types'
import { DropdownLessonsEnum } from '@/pages/learning/learning-lessons/list/model/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...coursesDropdownModel.store,
    $courses,
  },
  props: {
    label: String,
  },
  data() {
    return {
      coursesDropdownMethods: coursesDropdownModel.methods,
    }
  },
  methods: {
    ...coursesDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedType({ value: item || null, type: DropdownLessonsEnum.course })
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    loadFilterOptions(DropdownLessonsEnum.course)
  },
})
