




import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'Divider',
  props: {
    vertical: { type: Boolean as PropType<boolean>, required: false },
  },
  methods: {
    getStyles() {
      if (this.$props.vertical) {
        return {
          width: '1px',
        }
      }

      return {
        width: '100%',
        height: '1px',
      }
    },
  },
})
