import { createEvent, attach, createStore, split, guard, restore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import {
  getCoursesFilterOptionsFx,
  getThemesFilterOptionsFx,
} from '@/features/api/learning/lessons/get-lessons-filter-options'
import { themesDropdownModule } from '@/pages/common/dropdowns/themes-assigment-tree/themes-assigment-dropdown.model'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownLessonsEnum } from '@/pages/learning/learning-lessons/list/model/types'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { debounce } from 'patronum'

export const coursesDropdownModel = createFilter()

export const loadFilterOptions = createEvent<DropdownLessonsEnum>()

export const lessonPageMounted = createEvent<boolean>()
export const $lessonPageMounted = restore(lessonPageMounted, false)

const getCourses = attach({
  effect: getCoursesFilterOptionsFx,
})

const getThemes = attach({
  effect: getThemesFilterOptionsFx,
  mapParams: (params) => {
    if (!!params && typeof params !== 'string') {
      return { is_lesson_theme: true, ...params }
    }
    return { is_lesson_theme: true }
  },
})

export const $courses = createStore<DropdownItem[]>([]).on(getCourses.doneData, (_, res) =>
  res.body.map((payload) => ({ name: `${payload.id}`, title: payload.name }))
)

export const $themes = createStore<DropdownItem[]>([]).on(getThemes.doneData, (_, res) =>
  res.body.map((payload) => ({ name: `${payload.id}`, title: payload.name }))
)

export const setSelectedType = createEvent<{
  value: DropdownItem | null
  type: DropdownLessonsEnum
}>()

export const $selectedCourse = createStore<DropdownItem | null>(null)
  .on(setSelectedType, (state, payload) =>
    payload.type === DropdownLessonsEnum.course ? payload.value : state
  )
  .reset(coursesDropdownModel.methods.resetDropdown)

export const $selectedTheme = createStore<DropdownItem | null>(null)
  .on(setSelectedType, (state, payload) =>
    payload.type === DropdownLessonsEnum.theme ? payload.value : state
  )
  .reset([themesDropdownModule.methods.resetDropdown])

split({
  source: loadFilterOptions,
  match: {
    theme: (payload) => payload === DropdownLessonsEnum.theme,
    course: (payload) => payload === DropdownLessonsEnum.course,
  },
  cases: {
    theme: getThemes,
    course: getCourses,
  },
})

const debounced = debounce({
  source: subjectsDropdownModel.store.$item.map((subject) =>
    subject ? { subject: subject.name } : null
  ),
  timeout: 150,
})

guard({
  clock: debounced,
  filter: $lessonPageMounted.map((mounted) => mounted),
  target: getThemes,
})

export const dropdownModules = {
  coursesDropdownModel,
  subjectsDropdownModel,
  themesDropdownModule,
}
