












import Vue, { PropType } from 'vue'
import ScrollContainer from '@/ui/scroll-container/ScrollContainer.vue'
import {
  actionsSelectMenuMaxHeight,
  actionsSelectSmallMenuMaxHeight,
} from '@/pages/common/parts/context-menu/constants'

export default Vue.extend({
  name: 'SelectMenu',
  components: {
    ScrollContainer,
  },
  props: {
    loading: { type: Boolean as PropType<boolean> },
    smallMenu: { type: Boolean, default: false },
  },
  data: () => ({
    actionsSelectMenuMaxHeight,
    actionsSelectSmallMenuMaxHeight,
  }),
})
