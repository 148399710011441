import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { TestList } from '@/features/api/test/types'

export const getExamsTestsListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<TestList[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/part-time/tests/list/',
    query,
  }),
})
