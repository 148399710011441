import { createApiEffect } from '@/features/api/common/create-api-effect'
import {
  ApplicationsCoursesBulkParams,
  UpdateApplicationsCoursesBulkFailResponse,
} from '@/features/api/learning/course-applications/types'

export const updateCourseApplicationsBulkFx = createApiEffect<
  ApplicationsCoursesBulkParams,
  ApplicationsCoursesBulkParams,
  UpdateApplicationsCoursesBulkFailResponse
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/ticket-app/course-ticket/bulk-patch/',
    body,
  }),
})
