import { createEvent, createStore, forward, restore, sample } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { condition } from 'patronum'

// для value - onChange инпута с статистичными данными
export function createStaticFiltersModel(data: DropdownItem[]) {
  const setItems = createEvent<DropdownItem[]>()

  const resetItem = createEvent<void>()

  const itemChanged = createEvent<null | { name: string; title: string }>()
  const $item = restore<null | { name: string; title: string }>(itemChanged, null).reset(resetItem)

  const searchStringChanged = createEvent<string>()
  const resetSearchString = createEvent<void>()
  const $searchString = restore<string>(searchStringChanged, '').reset(resetSearchString)

  const $itemsDropdown = createStore<DropdownItem[]>([])
  const $_itemsDropdown = createStore<DropdownItem[]>([])

  const searchItem = createEvent<string>()
  const restoreItems = createEvent<void>()

  const resetDropdown = createEvent<void>()

  const dropdownDestroy = createEvent<void>()

  const $items = createStore<DropdownItem[]>(data).reset(dropdownDestroy, resetDropdown)

  forward({
    from: setItems,
    to: [$itemsDropdown, $_itemsDropdown],
  })

  forward({
    from: resetDropdown,
    to: [resetItem, resetSearchString],
  })

  condition({
    source: $searchString,
    if: (str: string) => !!str.length,
    then: sample({
      clock: $searchString,
      fn: (str) => {
        if ($item && str.length) resetItem()
        return str
      },
      target: searchItem,
    }),
    else: restoreItems,
  })

  function filterItems(str: string, list: any) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].title.toLowerCase().indexOf(str.toLocaleLowerCase()) === -1) {
        if (list[i].leaves) {
          if (!list[i].leaves.length) {
            list.splice(i, 1)
            i--
          } else {
            list[i].leaves = filterItems(str, list[i].leaves)
            if (!list[i].leaves.length) {
              list.splice(i, 1)
              i--
            }
          }
        } else {
          list.splice(i, 1)
          i--
        }
      }
    }
    return list
  }

  sample({
    source: $_itemsDropdown,
    clock: searchItem,
    fn: (list, str) => {
      const arr = JSON.parse(JSON.stringify(list))
      return filterItems(str, arr)
    },
    target: $itemsDropdown,
  })

  sample({
    source: $_itemsDropdown,
    clock: restoreItems,
    target: $itemsDropdown,
  })

  return {
    store: {
      $item,
      $itemsDropdown,
      $searchString,
      $items,
    },
    methods: {
      setItems,
      resetItem,
      itemChanged,
      searchStringChanged,
      resetSearchString,
      resetDropdown,
      dropdownDestroy,
    },
  }
}
