














import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  studentsExamAnswerDropdownModel,
  loadStudents,
} from '@/pages/exams/part-time-answers/list/parts/students-exams-answer-filter/students-exams-answer-filter.model'

export default Vue.extend({
  name: 'StudentsExamsAnswerFilter',
  components: {
    FilterDropdown,
  },
  effector: {
    ...studentsExamAnswerDropdownModel.store,
  },
  methods: {
    ...studentsExamAnswerDropdownModel.methods,
    onItemChanged(item: DropdownItem | null) {
      this.$emit('setItem', item)
    },
  },
  mounted() {
    if (this.$items.length === 0) loadStudents()
  },
})
