import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { Employee } from '@/features/api/employee/types'
import { Test } from '@/features/api/test/types'

export const attemptsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    dataClass: 'wrap',
    title: 'ID попытки',
    width: '100px',
  },
  {
    name: 'student_answer',
    dataClass: 'wrap',
    title: 'Ответы ученика ',
    width: '150px',
  },
  {
    name: 'test',
    sortField: 'test',
    dataClass: 'wrap',
    title: 'Экзаменационный тест',
    width: '210px',
    formatter: (test: Test) => {
      return test?.internal_name || '–'
    },
  },
  {
    name: 'subject',
    sortField: 'subject',
    title: 'Предмет',
    width: '140px',
    formatter(subject: { id: number; name: string }) {
      return subject ? subject.name : '-'
    },
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    title: 'Класс',
    width: '100px',
    formatter(year: { id: number; name: string }) {
      return year ? year.name : '-'
    },
  },
  {
    name: 'studentId',
    sortField: 'student',
    dataClass: 'center aligned',
    title: 'ID ученика',
    width: '130px',
  },
  {
    name: 'student_elk_id',
    sortField: 'student_elk_id',
    title: 'ID ЕЛК',
    width: '90px',
  },
  {
    name: 'student_name',
    sortField: 'student_name',
    title: 'Ученик',
    width: '160px',
  },
  {
    name: 'groups',
    sortField: 'groups',
    dataClass: 'wrap',
    title: 'Группа',
    width: '170px',
    formatter(groups: { name: string }[] | string) {
      if (!groups || groups.length === 0) return 'Не задано'
      if (Array.isArray(groups)) {
        return groups.map((group) => group.name).join(', ')
      }
      return groups
    },
  },
  {
    name: 'available_from',
    sortField: 'available_from',
    title: 'Дата начала доступности',
    titleClass: 'wrap',
    width: '160px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'available_to',
    sortField: 'available_to',
    title: 'Дата окончания доступности',
    titleClass: 'wrap',
    width: '160px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус попытки',
    dataClass: 'wrap',
    width: '160px',
    formatter(status: number) {
      if (status === 0) return 'Назначен'
      return status === 1 ? 'В процессе прохождения' : 'Завершен'
    },
  },
  {
    name: 'appointment',
    sortField: 'appointment',
    title: 'ID назначения',
    dataClass: 'wrap',
    width: '160px',
  },
  {
    name: 'is_proctoring_activated',
    sortField: 'is_proctoring_activated',
    title: 'Прокторинг',
    width: '140px',
    formatter(proctoring: boolean) {
      return proctoring ? 'Активирован' : 'Не активирован'
    },
  },
  {
    name: 'proctors',
    sortField: 'proctors',
    title: 'Прокторы',
    dataClass: 'wrap',
    width: '140px',
    formatter(proctors: { id: number; service: string; is_active: boolean; login: string }[]) {
      if (proctors.length === 0) return '-'
      return proctors.map((proctor) => proctor.login).join(', ')
    },
  },
  {
    name: 'themes_amount',
    sortField: 'themes_amount',
    title: 'Кол-во тем (факт)',
    width: '170px',
  },
  {
    name: 'tasks_amount',
    sortField: 'tasks_amount',
    title: 'Кол-во вопросов (факт)',
    width: '210px',
  },
  {
    name: 'test_progress',
    sortField: 'test_progress',
    title: 'Прогресс теста (%) ',
    width: '180px',
    formatter(progress: number) {
      return progress || progress === 0 ? `${progress}%` : '-'
    },
  },
  {
    name: 'correct_answers_count',
    sortField: 'correct_answers_count',
    title: 'Текущий шаг процессинга экзамена',
    titleClass: 'wrap',
    width: '200px',
  },
  {
    name: 'tasks_looked_count',
    sortField: 'tasks_looked_count',
    title: 'Кол-во просмотренных вопросов',
    titleClass: 'wrap',
    width: '200px',
  },
  {
    name: 'answers_count',
    sortField: 'answers_count',
    title: 'Кол-во ответов (всего)',
    titleClass: 'wrap',
    width: '200px',
  },
  {
    name: 'correct_answers_count',
    sortField: 'correct_answers_count',
    title: 'Кол-во верных ответов',
    titleClass: 'wrap',
    width: '210px',
  },
  {
    name: 'result_percent',
    sortField: 'result_percent',
    title: 'Результат (%)',
    titleClass: 'wrap',
    width: '200px',
    formatter(result: number) {
      return result || result === 0 ? `${result}%` : '-'
    },
  },
  {
    name: 'total_student_correct_score',
    sortField: 'total_student_correct_score',
    title: 'Кол-во баллов за верные ответы',
    titleClass: 'wrap',
    width: '200px',
  },
  {
    name: 'test_structure',
    title: 'Данные структуры теста (id темы: [id задания])',
    titleClass: 'wrap',
    width: '200px',
    formatter(data: string) {
      return data || 'Отсутствует'
    },
  },
  {
    name: 'started_at',
    sortField: 'started_at',
    title: 'Время начала прохождения теста',
    titleClass: 'wrap',
    width: '180px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : '–'
    },
  },
  {
    name: 'finished_at',
    sortField: 'finished_at',
    title: 'Время окончания прохождения теста',
    titleClass: 'wrap',
    width: '180px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : '–'
    },
  },
  {
    name: 'duration',
    sortField: 'duration',
    title: 'Длительность прохождения теста (чч:мм:сс)',
    titleClass: 'wrap',
    width: '190px',
    formatter(datetime: string) {
      return datetime
    },
  },
  {
    name: 'is_passed',
    sortField: 'is_passed',
    title: 'Попытка зачтена (без прохождения)',
    titleClass: 'wrap',
    width: '180px',
    formatter(is_passed: boolean) {
      return is_passed ? 'да' : 'нет'
    },
  },
  {
    name: 'result_status',
    sortField: 'result_status',
    title: 'Итоговый статус (после проверки)',
    titleClass: 'wrap',
    width: '180px',
    formatter(status: number) {
      const codes = {
        0: 'SUCCESSFUL_RESULT',
        1: 'PASSED_RESULT',
        2: 'WAITING_RESULT',
        3: 'SKIPPED_RESULT',
        4: 'DISQUALIFIED_RESULT',
        5: 'INSUFFICIENT_RESULT',
        6: 'RETEST_RESULT',
      }

      const titles = {
        SUCCESSFUL_RESULT: 'Пройдено успешно',
        PASSED_RESULT: 'Зачтено',
        WAITING_RESULT: 'Ожидает проверки',
        SKIPPED_RESULT: 'Тестирование пропущено',
        DISQUALIFIED_RESULT: 'Дисквалификация',
        INSUFFICIENT_RESULT: 'Недостаточный результат',
        RETEST_RESULT: 'Необходимо повторное тестирование',
      }

      return Number.isInteger(status) ? titles[codes[status]] : '-'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    titleClass: 'wrap',
    width: '220px',
    formatter(user: Employee | null) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '110px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Обновил',
    titleClass: 'wrap',
    width: '220px',
    formatter(user: Employee | null) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Изменено',
    width: '110px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : ''
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'test_id', title: 'ID экзаменационного теста' },
  { name: 'test_name', title: 'Название экзаменационного теста' },
  { name: 'all', title: 'Название экзаменационного теста' },
  { name: 'subject', title: 'Предмет' },
  { name: 'class', title: 'Класс' },
  { name: 'all', title: 'Группа' },
  { name: 'all', title: 'ID абитуриента' },
  { name: 'all', title: 'Имя и фамилия абитуриента' },
  { name: 'all', title: 'Результатам (баллы)' },
  { name: 'all', title: 'результатам (проценты)' },
]
