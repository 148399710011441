
















import Vue from 'vue'
import CreateProctorsFooter from '@/pages/exams/proctors/create/parts/CreateProctorsFooter.vue'
import CreateProctorsHeader from '@/pages/exams/proctors/create/parts/CreateProctorsHeader.vue'
import CreateProctorsContent from '@/pages/exams/proctors/create/parts/CreateProctorsContent.vue'
import { changeProctorsId } from '@/pages/exams/proctors/create/model/edit-proctors.model'
import {
  $disabledSaveButtons,
  resetFields,
} from '@/pages/exams/proctors/create/model/form-fileds.model'
import {
  getPageParams,
  setRedirectAfterSave,
} from '@/pages/exams/proctors/create/model/create-proctors.model'

export default Vue.extend({
  name: 'CreateProctorsPage',
  components: {
    CreateProctorsHeader,
    CreateProctorsFooter,
    CreateProctorsContent,
  },
  effector: {
    $disabledSaveButtons,
  },
  methods: {
    saveGroup(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      getPageParams()
    },
  },
  created() {
    changeProctorsId(this.$route.params.id)
  },
  beforeDestroy() {
    resetFields()
  },
})
