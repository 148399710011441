import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { difficultyDropdownModule } from '@/pages/common/dropdowns/bank/test-tasks/difficulty-dropdown/difficulty-dropdown.model'
import { languagesDropdownModule } from '@/pages/common/dropdowns/bank/test-tasks/language-dropdown/language-dropdown.model'
import { statusDropdownModule } from '@/pages/common/dropdowns/bank/test-tasks/status-dropdown/status-dropdown.model'
import { labelsDropdownModel } from '@/pages/common/dropdowns/labels/labels-dropdown.model'
import { themesDropdownModule } from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import { typeDropdownModule } from '@/pages/common/dropdowns/bank/test-tasks/type-dropdown/type-dropdown.model'

export const dropdownModules = {
  classesDropdownModule,
  subjectsDropdownModel,
  difficultyDropdownModule,
  languagesDropdownModule,
  statusDropdownModule,
  labelsDropdownModel,
  themesDropdownModule,
  typeDropdownModule,
}
