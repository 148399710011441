




















import Vue from 'vue'

export default Vue.extend({
  name: `FilePicker`,
  props: {
    accept: {
      type: String,
      default: '*/*',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(event: HTMLInputElement | FileList) {
      const uploader = this.$refs.uploader as HTMLInputElement
      let files = null
      if (event instanceof FileList) {
        files = event
      } else {
        files = uploader.files as ArrayLike<File>
      }
      const filesArray = Array.from(files)

      this.$emit('change', {
        getFormData: (key: string, otherKeys?: any) => {
          const data = new FormData()
          filesArray.forEach((file) => {
            data.append(key, file)
          })
          if (otherKeys) {
            Object.keys(otherKeys).forEach((anotherKey) =>
              data.append(anotherKey, otherKeys[anotherKey])
            )
          }
          return data
        },
        files,
        filesArray,
        nativeEvent: event,
      })

      uploader.value = ''
    },
    dropHandle(event: DragEvent) {
      this.change(event.dataTransfer!.files)
      event.preventDefault()
    },
    preventDefault(event: MouseEvent) {
      event.preventDefault()
    },
  },
})
