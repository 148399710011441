




















































import Vue from 'vue'
import StartToEndDatetime from '@/pages/learning/learning-courses/parts/inputs/datetime-picker/StartToEndDatetime.vue'
import {
  $groups,
  loadGroups,
} from '@/pages/learning/learning-courses/parts/inputs/groups-lessons-block/groups-block.model'
import GroupsFilter from '@/pages/learning/learning-courses/parts/inputs/groups-lessons-block/GroupsFilter.vue'
import Icon from '@/ui/icon/Icon.vue'
import { GroupCourseType, LessonsGroupCourseType } from '@/pages/learning/learning-courses/types'
import { DatetimeLessonsGroup } from '@/features/api/learning/types'

export default Vue.extend({
  name: 'GroupsDropdown',
  components: {
    StartToEndDatetime,
    GroupsFilter,
    Icon,
  },
  props: {
    group: {
      type: Object,
    },
    groupsList: {
      type: Array,
    },
    idx: {
      type: Number,
    },
    blockId: {
      type: Number,
    },
    labelStart: {
      type: String,
      default: '',
    },
    labelEnd: {
      type: String,
      default: '',
    },
    lessonCount: {
      type: String,
      default: '',
    },
  },
  effector: {
    $groups,
  },
  data: () => ({
    correctGroupsList: [],
  }),
  computed: {
    datetimes(): DatetimeLessonsGroup {
      return {
        start: this.group?.start,
        end: this.group?.end,
      }
    },
  },
  watch: {
    $groups: {
      handler(newVal) {
        this.onSetGroupsList(newVal)
      },
    },
    groupsList: {
      handler() {
        this.onSetGroupsList(this.$groups)
      },
    },
  },
  methods: {
    loadGroups,
    onSetGroupsList(list: LessonsGroupCourseType[]) {
      // ToDo TRUE не удаляет из массива элемент
      // @ts-ignore
      this.correctGroupsList = list.filter((el: LessonsGroupCourseType) => {
        return this.groupsList.reduce((previousValue: boolean, currentValue: any) => {
          if (!previousValue) return false
          return el.id !== currentValue.id
        }, true)
      })
    },
    onSetDatetime(datetimes: DatetimeLessonsGroup) {
      this.$emit(
        'changeGroup',
        {
          ...this.group,
          start: datetimes.start,
          end: datetimes.end,
        },
        this.blockId
      )
    },
    setSelectedGroup(group: GroupCourseType | null) {
      if (group) {
        const { id, title, name } = group
        this.$emit('changeGroup', { ...this.group, id, title, name }, this.blockId)
      } else {
        this.$emit('changeGroup', { ...this.group, id: 0, title: '', name: '' }, this.blockId)
      }
    },
    addGroup() {
      this.$emit('addGroup')
    },
    removeGroup() {
      this.$emit('removeGroup', this.blockId)
    },
  },
  mounted() {
    if (this.$groups.length === 0) {
      loadGroups()
    } else {
      this.onSetGroupsList(this.$groups)
    }
  },
})
