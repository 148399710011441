import { createEvent, restore } from 'effector-root'

export const comparingStudentAnswerModalVisibilityChanged = createEvent<boolean>()
export const $comparingStudentAnswerModalVisibility = restore(
  comparingStudentAnswerModalVisibilityChanged,
  false
)

export const resetAnswers = createEvent<void>()

export const setStudentViewAnswer = createEvent<any>()
export const $studentAnswer = restore<any>(setStudentViewAnswer, null).reset(resetAnswers)

export const setStudent = createEvent<any>()
export const $student = restore<any>(setStudent, null).reset(resetAnswers)
