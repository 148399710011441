import { createApiEffect } from '@/features/api/common/create-api-effect'
import { AttemptsUpdateForm, UpdateAttemptsBulkFailResponse } from '@/features/api/attempts/types'

export const updateFullAttemptsBulkFx = createApiEffect<
  AttemptsUpdateForm,
  AttemptsUpdateForm,
  UpdateAttemptsBulkFailResponse
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/exams-app/attempts/bulk-update/',
    body,
  }),
})
