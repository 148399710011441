import {
  TreeData,
  GetThemeTreeFilterListResponse,
  GetFolderTreeListItem,
} from '@/features/api/types'
import { Dictionary } from 'vue-router/types/router'
import { OperableTypes, ChildNodes, PageParams } from '@/pages/common/types'
import { config } from '@/config'

export const removeHtmlTags = (str: string) => {
  return str.replace(new RegExp('<[^>]*>', 'g'), '').replace(new RegExp('&[a-z]*;', 'g'), ' ')
}

const wordDeclination = (total: number, words: string[]) => {
  /*
   Порядок слов для массива words:
     1. Слово под нулевым индексом для чисел заканчивающихся на один, но не на 11.
     2. Слово под индексом один для чисел заканчивающихся на числа от 2-4, но где последние 2 цифры не
      находятся в диапазоне от 10 до 20 включительно.
     3. Слово под индексом два для всех оставшихся чисел.
  */
  if (total % 10 === 1 && total % 100 !== 11) {
    return words[0]
  }
  if (total % 10 >= 2 && total % 10 <= 4 && (total % 100 < 10 || total % 100 >= 20)) {
    return words[1]
  }
  return words[2]
}

const formatTotalAmount = (total: number) => {
  if (total.toString().length < 4) return total
  let res = total.toString().split('').reverse()
  res = res.map((el, index) => (index % 3 === 0 ? `${el} ` : `${el}`))
  return res.reverse().join('')
}

export const formatTitle = (total: number, words: string[]) => {
  return `${formatTotalAmount(total)} ${wordDeclination(total, words)}`
}

export { formatTotalAmount }
export const formatTitleByType = function (total: number, type: OperableTypes) {
  /* Важен порядок передаваемых слов, подробнее об этом в функции wordDeclination */
  switch (type) {
    case 'theme':
      return formatTitle(total, ['тема', 'темы', 'тем'])
    case 'task':
      return formatTitle(total, ['задание', 'задания', 'заданий'])
    case 'tag':
      return formatTitle(total, ['тег', 'тега', 'тегов'])
    case 'test':
      return formatTitle(total, ['тест', 'теста', 'тестов'])
    case 'label':
      return formatTitle(total, ['метка', 'метки', 'меток'])
    case 'resource':
      return formatTitle(total, ['ресурс', 'ресурса', 'ресурсов'])
    case 'file':
      return formatTitle(total, ['файл', 'файла', 'файлов'])
    case 'ticket':
      return formatTitle(total, ['заявка', 'заявки', 'заявок'])
    case 'lesson':
      return formatTitle(total, ['урок', 'урока', 'уроков'])
    case 'courses':
      return formatTitle(total, ['курс', 'курса', 'курсов'])
    case 'subject':
      return formatTitle(total, ['предмет', 'предмета', 'предметов'])
    case 'results':
      return formatTitle(total, ['результат', 'результата', 'результатов'])
    case 'elements':
      return formatTitle(total, ['элемент', 'элемента', 'элементов'])
    case 'students':
      return formatTitle(total, ['ученик', 'ученика', 'учеников'])
    case 'employers':
      return formatTitle(total, ['сотрудник', 'сотрудника', 'сотрудников'])
    case 'news':
      return formatTitle(total, ['новость', 'новости', 'новостей'])
    case 'slide':
      return formatTitle(total, ['слайд', 'слайда', 'слайдов'])
    case 'banner':
      return formatTitle(total, ['баннер', 'баннера', 'баннеров'])
    case 'question':
      return formatTitle(total, ['вопрос', 'вопроса', 'вопросов'])
    case 'groups':
      return formatTitle(total, ['групп', 'группы', 'групп'])
    case 'instructions':
      return formatTitle(total, ['инструкция', 'инструкции', 'инструкций'])
    case 'proctors':
      return formatTitle(total, ['проктор', 'проктора', 'прокторов'])
    case 'part-time-test':
      return formatTitle(total, ['экзамен', 'экзамена', 'экзаменов'])
    case 'appointment':
      return formatTitle(total, ['назначение', 'назначения', 'назначений'])
    case 'attempts':
      return formatTitle(total, ['попытка', 'попытки', 'попыток'])
    case 'exams-answers':
      return formatTitle(total, ['ответ', 'ответа', 'ответов'])
    case 'commentaries':
      return formatTitle(total, ['комментарий', 'комментария', 'комментариев'])
    case 'subscribers':
      return formatTitle(total, ['подписчик', 'подписчика', 'подписчиков'])
    default:
      return total
  }
}

export const sortTreeLeaves = (leaves: TreeData[]) => {
  return leaves.sort((a: TreeData, b: TreeData) => {
    a.ordering_string = removeHtmlTags(a.ordering_string)
    b.ordering_string = removeHtmlTags(b.ordering_string)
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
    if (
      a.ordering_string.match(/\d{2,3}\S+/) !== null &&
      b.ordering_string.match(/\d{2,3}\S+/) !== null
    ) {
      return collator.compare(a.ordering_string, b.ordering_string)
    }
    if (
      a.ordering_string.match(/(^.\d+)/) !== null &&
      b.ordering_string.match(/(^.\d+)/) !== null
    ) {
      return +a.ordering_string.match(/(^.\d+)/)![0] - +b.ordering_string.match(/(^.\d+)/)![0]
    }
    if (
      a.ordering_string.match(/(^.\d+)/) !== null &&
      b.ordering_string.match(/(^.\d+)/) === null
    ) {
      return 1
    }
    if (
      a.ordering_string.match(/(^.\d+)/) === null &&
      b.ordering_string.match(/(^.\d+)/) !== null
    ) {
      return -1
    }
    return collator.compare(a.ordering_string, b.ordering_string)
  })
}

export const sortResourcesTreeLeaves = (leaves: TreeData[]) => {
  const sorter = {
    file: {
      value: 1,
      text: 'file_name',
    },
    video: {
      value: 2,
      text: 'link',
    },
    text: {
      value: 3,
      text: 'text',
    },
    link: {
      value: 4,
      text: 'link',
    },
  }
  let arr = JSON.parse(JSON.stringify(leaves))
  arr = arr.sort((a: any, b: any) => {
    if (a.element_type !== 'study_resource' || b.element_type !== 'study_resource') return 0
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
    if (
      sorter[a.study_resource.resource_type].value === sorter[b.study_resource.resource_type].value
    ) {
      const aText: string = a.study_resource[sorter[a.study_resource.resource_type].text]
      const bText: string = b.study_resource[sorter[a.study_resource.resource_type].text]
      return collator.compare(removeHtmlTags(aText), removeHtmlTags(bText))
    }
    return (
      sorter[a.study_resource.resource_type].value - sorter[b.study_resource.resource_type].value
    )
  })
  return arr
}

function isNodeMatch(currentTreeNode: TreeData, payloadNode: TreeData) {
  const payloadNodeElementType = payloadNode.element_type
  const currentTreeNodeElementType = currentTreeNode.element_type
  if (
    payloadNodeElementType !== 'virtual_folder' &&
    currentTreeNodeElementType !== 'virtual_folder'
  ) {
    const payloadNodeElementId = payloadNode[payloadNodeElementType]!.id
    const currentTreeNodeElementId = currentTreeNode[currentTreeNodeElementType]!.id
    return (
      payloadNodeElementType === currentTreeNodeElementType &&
      payloadNodeElementId === currentTreeNodeElementId
    )
  }
  return payloadNodeElementType === currentTreeNodeElementType
}

const checkChildren = (currentNode: TreeData, payloadMatchedNode: TreeData) => {
  return currentNode.leaves.some((currentNodeLeave) =>
    payloadMatchedNode.leaves.some(
      (payloadMatchedNodeLeave) =>
        payloadMatchedNodeLeave.element_type === currentNodeLeave.element_type
    )
  )
}

export const mergeTreeData = (currentTree: TreeData[], payloadTree: TreeData[]) => {
  return currentTree.map((currentTreeNode) => {
    const payloadMatchedNode = payloadTree.find((payloadNode) =>
      isNodeMatch(currentTreeNode, payloadNode)
    )
    if (payloadMatchedNode === undefined) return currentTreeNode

    if (!checkChildren(currentTreeNode, payloadMatchedNode)) {
      payloadMatchedNode.leaves.forEach((ndl) => currentTreeNode.leaves.push(ndl))
      if (currentTreeNode.element_type === 'theme' && !!currentTreeNode.theme) {
        currentTreeNode.theme.labels_amount = payloadMatchedNode.theme?.labels_amount!
        currentTreeNode.theme.assignments_amount = payloadMatchedNode.theme?.assignments_amount!
      }
    } else if (checkChildren(currentTreeNode, payloadMatchedNode)) {
      if (currentTreeNode.leaves.length)
        mergeTreeData(currentTreeNode.leaves, payloadMatchedNode.leaves)
      else currentTreeNode.leaves = payloadMatchedNode.leaves
    }
    return currentTreeNode
  })
}

export const combineRouteQueries = (
  currentQueries: Dictionary<string | (string | null)[]>,
  newQueries: Dictionary<string | (string | null)[]>
) => {
  /* https://stegriff.co.uk/upblog/get-vue-router-to-change-part-of-the-query-string-without-a-page-refresh/ */
  const queries = JSON.parse(JSON.stringify(currentQueries))

  return {
    query: {
      ...queries,
      ...newQueries,
    },
  }
}

export const parseToPrimitive = (value: string | (string | null)[]) => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value.toString()
    }
  }
  return null
}

export const isQueryParamsEquelToPage = (
  query: Dictionary<string | (string | null)[]>,
  pageParams: PageParams
) => {
  const queryKeys = Object.keys(query)
  const stateKeys = Object.keys(pageParams)

  if (queryKeys.length !== stateKeys.length) {
    return false
  }

  for (const key of queryKeys) {
    if (parseToPrimitive(query[key]) !== pageParams[key]) {
      return false
    }
  }

  return true
}

export const cropString = (str: string, len: number) => {
  if (str.length > len) {
    return `${removeHtmlTags(str).slice(0, len)}...`
  }
  return str
}

export function formatData(data: GetThemeTreeFilterListResponse[], is_prerequisite?: boolean): any {
  return data.map((elem: any) => ({
    name: `${elem.theme.id}`,
    title: elem.theme.name,
    id: elem.theme.id,
    is_prerequisite,
    leaves: elem.leaves.length ? formatData(elem.leaves) : elem.leaves,
  }))
}

export function formatFoldersData(data: GetFolderTreeListItem[], is_prerequisite?: boolean): any {
  return data
    .filter((elem) => elem.element_type === 'folder')
    .map((elem: any) => ({
      name: `${elem.folder.id}`,
      title: elem.folder.name,
      id: elem.folder.id,
      is_prerequisite,
      leaves: elem.leaves.length ? formatFoldersData(elem.leaves) : null,
    }))
}

export const computeSortParam = (sortParam: string) => {
  if (!sortParam) return ''
  const [field, order] = sortParam.split('|')
  const sign = order === 'desc' ? '-' : ''
  return `${sign}${field}`
}

export const isArraysEquel = (a: any[], b: any[]) => {
  return a.length === b.length && a.every((v: any, i: any) => v === b[i])
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export type ObjectValuesType<T> = {
  [K in keyof T]: string[]
}

/*
  last comment
  https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript
*/
export function entries<T>(obj: T): Entries<T> {
  return Object.entries(obj) as any
}

export function parseError(data: { [key in string]: string }) {
  const type = Object.keys(data)[0]
  const map = {
    theme_id: 'Тема не найдена',
    study_year_id: 'Класс не найден',
    subject_id: 'Предмет не найден',
    folder: 'Расположение не найдено',
    labels: 'Метка не найдена',
  }
  return map[type] ? `${map[type]}` : 'Один из указанных элементов не найден'
}

export function prepareTemplateText(text: string) {
  if (text.match(/input id="/)) return text
  const correct = text.replace(/<input(.*?)placeholder="s\d+"(.*?)>/g, (match) => {
    const id = +match.match(/data-index="\d+"/g)![0].match(/\d/g)![0]
    const correctStr = `<input id="${id + 1}" placeholder="S${id + 1}" type="" />`
    return correctStr
  })
  return correct
}

export function updateOldImportedTasksInputsPlaceholders(text: string) {
  if (
    text.match(/<input(.*?)placeholder="i(\d+)"(.*?)\/>/g) ||
    text.match(/<input(.*?)placeholder="d(\d+)"(.*?)\/>/g)
  ) {
    let res = text.replace(/<input(.*?)placeholder="i(\d+)"(.*?)\/>/g, (match) => {
      const b = match.replace(/placeholder="i/, 'placeholder="B')
      return b
    })
    res = res.replace(/<input(.*?)placeholder="d(\d+)"(.*?)\/>/g, (match) => {
      const a = match.replace(/placeholder="d/, 'placeholder="A')
      return a
    })
    return res
  }
  return text
}
export type FinalNodeLinkCopy = Element | Node | null

export function transformTreeNode(
  node: ChildNodes,
  // eslint-disable-next-line no-shadow
  callback: (root: ChildNodes, finalNodeLinkCopy: FinalNodeLinkCopy) => FinalNodeLinkCopy,
  finalNodeLinkCopy: FinalNodeLinkCopy
) {
  const nextFinalNodeLinkCopy = callback(node, finalNodeLinkCopy)
  if (node.childNodes.length !== 0) {
    node.childNodes.forEach((child: ChildNode | Element) => {
      transformTreeNode(child, callback, nextFinalNodeLinkCopy)
    })
  }
}

export function isObjectEmpty(obj: Record<string, unknown>): boolean {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

export function getMediaType(mediaType: string) {
  if (mediaType.includes('image')) {
    return 'img'
  }
  if (mediaType.includes('audio')) {
    return 'audio'
  }
  if (mediaType.includes('video')) {
    return 'video'
  }
  if (mediaType.match(/pdf|doc|docx|ppt|pptx|pps|ppsx|odt|xls|xlsx|xml/)) {
    return 'doc'
  }
  if (mediaType.match(/7z|ace|bin|gz|gzip|pak|pkg|ppt|rar|tar|tar-gz|tgz|xar|zip|zipx/)) {
    return 'zip'
  }
  return 'file'
}

export function downloadFile(fileLink: string, fileName: string, isFullLink?: boolean): void {
  const url = isFullLink ? fileLink : `${config.BACKEND_URL}${fileLink}`
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      const fileLinkElement = document.createElement('a')

      fileLinkElement.href = fileURL
      fileLinkElement.download = fileName
      document.body.appendChild(fileLinkElement)

      fileLinkElement.click()
      document.body.removeChild(fileLinkElement)
    })
}

export async function getFileForPreview(
  fileLink: string,
  isFullLink?: boolean
): Promise<Blob | null> {
  const url = isFullLink ? fileLink : `${config.BACKEND_URL}${fileLink}`
  try {
    const response = await fetch(url)
    return await response.blob()
  } catch (err) {
    console.error(err)
    return null
  }
}

export function downloadFileNoUrl(fileLink: string, fileName: string): void {
  fetch(fileLink)
    .then((response) => response.blob())
    .then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      const fileLinkElement = document.createElement('a')

      fileLinkElement.href = fileURL
      fileLinkElement.download = fileName
      document.body.appendChild(fileLinkElement)

      fileLinkElement.click()
      document.body.removeChild(fileLinkElement)
    })
}
