import { createEvent, createStore, forward, attach } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { getGroupsFiltersListFx } from '@/features/api/learning/courses/get-groups-filter-list'
import { LessonsGroupCourseType } from '@/pages/learning/learning-courses/types'

export const groupDropdownModule = createFilter()

const getGroups = attach({
  effect: getGroupsFiltersListFx,
})

export const loadGroups = createEvent<void>()
export const $groups = createStore<LessonsGroupCourseType[]>([])

forward({
  from: loadGroups,
  to: getGroups.prepend(() => ({})),
})

forward({
  from: getGroups.doneData.map((res) =>
    res.body.map((group) => {
      return {
        id: group.id,
        name: group.name,
        title: group.name,
        start: null,
        end: null,
      }
    })
  ),
  to: $groups,
})
