<template>
  <div class="datetime-form">
    <div class="datetime-item">
      <span class="label">{{ labelStart }}</span>
      <DatePicker
        v-model="datetime.start"
        type="datetime"
        placeholder="Выберите дату"
        @input="$emit('input', datetime)"
      />
    </div>
    <div class="datetime-item">
      <span class="label">{{ labelEnd }}</span>
      <DatePicker
        v-model="datetime.end"
        class="datepicker"
        type="datetime"
        placeholder="Выберите дату"
        @input="$emit('input', datetime)"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default Vue.extend({
  name: 'StartToEndDatetime',
  components: {
    DatePicker,
  },
  props: {
    datetime: {
      type: Object,
      default: () => ({
        start: null,
        end: null,
      }),
    },
    labelStart: {
      type: String,
      default: 'Дата начала',
    },
    labelEnd: {
      type: String,
      default: 'Дата окончания',
    },
  },
})
</script>

<style scoped>
.datetime-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.datetime-item {
  display: flex;
  flex-direction: column;
  width: calc(50% - 5px);
}
.label {
  color: #000;
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 5px;
}
/deep/ .mx-datepicker svg {
  display: none;
}
/deep/ .mx-input {
  padding: 12px 6px;
}
</style>
