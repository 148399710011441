







































import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Icon from '@/ui/icon/Icon.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $template } from '@/pages/results/certificates-generator/create-certificates/model/form-fields.model'

export default Vue.extend({
  name: 'CreateCertificatesHeader',
  components: { Icon, BaseButton },
  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  effector: {
    $template,
  },
  methods: {
    goToList() {
      navigatePush({
        name: 'certificates-generator-table',
      })
    },
    onSavePreview() {
      this.$emit('savePreview')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
