import { createEvent, restore } from 'effector'
import { attach, combine, createEffect, forward, guard, sample } from 'effector-root'
import { UploadMediaResponse } from '@/features/api/media/types'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import { uploadMediaFx } from '@/features/api/media/upload-media'
import { getMediaFx } from '@/features/api/media/get-media'
import {
  defaultGroup,
  LessonsCourseType,
  QuestionsCourseType,
  GroupCourseType,
} from '@/pages/learning/learning-courses/types'
import { createCoursesFx } from '@/features/api/learning/courses/create-courses'
import { classesDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/classes-filter-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/subject-filter-dropdown.model'
import {
  $selectedCourseTaskCheckType,
  resetCourseCourseTaskCheck,
} from '@/pages/learning/learning-courses/parts/inputs/course-task-check-type/coursesTaskCheckType.model'
import { CreateCourseFxParams, GroupsCourseData } from '@/features/api/learning/types'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { sendLessonsFormat } from '@/pages/learning/learning-courses/CourseUtils'
import url_slug from '@/lib/utils/url_slug'
import { $selectedDifficult } from '@/pages/common/dropdowns/courses/tags-dropdown/DificultDropdown/difficult-dropdown'
import { $selectedForWho } from '@/pages/common/dropdowns/courses/tags-dropdown/ForWhoDropdown/for-who-dropdown'

const createCourses = attach({
  effect: createCoursesFx,
})

export const setRedirect = createEvent()
export const sendIsCompleteMessage = createEvent()
export const setSendCourseId = createEvent<number>()
export const $sendCourseId = restore(setSendCourseId, 0)

export const sendGroupsCourse = createEvent<GroupsCourseData[]>()

export const uploadMedia = attach({
  effect: uploadMediaFx,
})

export const getCoverCourse = attach({
  effect: getMediaFx,
})

export const getIconCourse = attach({
  effect: getMediaFx,
})

export const $isLoadingCreateCourse = combine(createCoursesFx.pending, (Courses) => Courses)

export const save = createEvent<void>()
export const clearFieldsCourseCreate = createEvent<void>()

export const clearCourseTitle = createEvent<void>()
export const courseTitleChanged = createEvent<string>()
export const $courseTitle = restore(courseTitleChanged, '').reset(
  clearFieldsCourseCreate,
  clearCourseTitle
)

// true "с заявокой" false "назначенный"
export const courseWithTicketChanged = createEvent<boolean>()
export const $courseWithTicket = restore(courseWithTicketChanged, false).reset(
  clearFieldsCourseCreate
)

// Курс доступен только по ссылке
export const setIsAvailableByLink = createEvent<boolean>()
export const $courseIsAvailableByLink = restore(setIsAvailableByLink, false).reset(
  clearFieldsCourseCreate
)

export const clearCourseSlug = createEvent<void>()
export const courseSlugChanged = createEvent<string>()
export const $courseSlug = restore(courseSlugChanged, '').reset(
  clearFieldsCourseCreate,
  clearCourseSlug
)

export const toggleCompetition = createEvent<boolean>()
export const $competition = restore(toggleCompetition, true).reset(clearFieldsCourseCreate)

export const toggleCommentModule = createEvent<boolean>()
export const $commentModule = restore(toggleCommentModule, true).reset(clearFieldsCourseCreate)

export const courseDescriptionChanged = createEvent<string>()
export const $courseDescription = restore(courseDescriptionChanged, '').reset(
  clearFieldsCourseCreate
)

export const clearCourseCommentCms = createEvent<void>()
export const courseCommentCmsChanged = createEvent<string>()
export const $courseCommentCms = restore(courseCommentCmsChanged, '').reset(
  clearFieldsCourseCreate,
  clearCourseCommentCms
)

export const courseInputTextChanged = createEvent<string>()
export const $courseInputText = restore(courseInputTextChanged, '').reset(clearFieldsCourseCreate)

export const toggleCheckTypeCourse = createEvent<boolean>()
export const $taskCheckTypeCourse = restore(toggleCheckTypeCourse, false).reset(
  clearFieldsCourseCreate
)

export const toggleWithAgreementCourse = createEvent<boolean>()
export const $withAgreementCourse = restore(toggleWithAgreementCourse, false).reset(
  clearFieldsCourseCreate
)

export const toggleTicketsCheckTypeCourse = createEvent<boolean>()
export const $ticketsCheckTypeCourse = restore(toggleTicketsCheckTypeCourse, false).reset(
  clearFieldsCourseCreate
)

export const courseSeoTitleClear = createEvent<void>()
export const courseSeoTitleChanged = createEvent<string>()
export const $courseSeoTitle = restore(courseSeoTitleChanged, '').reset(
  clearFieldsCourseCreate,
  courseSeoTitleClear
)

export const courseSeoDescriptionClear = createEvent<void>()
export const courseSeoDescriptionChanged = createEvent<string>()
export const $courseSeoDescription = restore(courseSeoDescriptionChanged, '').reset(
  clearFieldsCourseCreate,
  courseSeoDescriptionClear
)

export const setQuestionsCourse = createEvent<QuestionsCourseType[]>()
export const $questionsCourse = restore(setQuestionsCourse, []).reset(clearFieldsCourseCreate)

export const setGroupsCourse = createEvent<GroupCourseType[]>()
export const $groupsCourse = restore(setGroupsCourse, []).reset(clearFieldsCourseCreate)

export const changeIfRedirect = createEvent<boolean>()
export const $ifRedirect = restore(changeIfRedirect, false)

export const setLessonsCourse = createEvent<LessonsCourseType[]>()
export const $lessonsCourse = restore(setLessonsCourse, [
  {
    id: 0,
    name: '',
    blockId: 0,
    title: '',
    groups: [defaultGroup()],
  },
]).reset(clearFieldsCourseCreate)

// Обложка курса
export const uploadCoverCourse = createEvent<FileList>()
export const resetCoverCourseId = createEvent<void>()
export const coverCourseIdChanged = createEvent<number>()
export const $coverCourseId = restore(coverCourseIdChanged, -1).reset(
  resetCoverCourseId,
  clearFieldsCourseCreate
)
export const coverCourseChanged = createEvent<string>()
export const $coverCourse = restore(coverCourseChanged, '').reset(
  resetCoverCourseId,
  clearFieldsCourseCreate
)

// Иконка курса
export const uploadIconCourse = createEvent<FileList>()
export const resetIconCourseId = createEvent<void>()
export const iconCourseIdChanged = createEvent<number>()
export const $iconCourseId = restore(iconCourseIdChanged, -1).reset(
  resetIconCourseId,
  clearFieldsCourseCreate
)
export const iconCourseChanged = createEvent<string>()
export const $iconCourse = restore(iconCourseChanged, '').reset(
  resetIconCourseId,
  clearFieldsCourseCreate
)

export const admissionTextChanged = createEvent<string>()
export const $admissionText = restore(admissionTextChanged, '').reset(clearFieldsCourseCreate)

const $inputsForm = combine({
  name: $courseTitle,
  is_tickets_acceptable: $taskCheckTypeCourse,
  tickets_check_type: $ticketsCheckTypeCourse,
  with_agreement: $withAgreementCourse,
  seo_title: $courseSeoTitle,
  seo_description: $courseSeoDescription,
  description: $courseDescription,
  commentary: $courseCommentCms,
  is_comments_active: $commentModule,
  is_competition: $competition,
  is_available_by_link: $courseIsAvailableByLink,
})

const $withTicketForm = combine(
  $courseInputText,
  $questionsCourse,
  $courseSlug,
  $groupsCourse,
  $courseTitle,
  (courseInputText, questionsCourse, courseSlug, groupsCourse, title) => ({
    intro: courseInputText,
    questions: questionsCourse.map((l) => l.question),
    url_slug: courseSlug ? url_slug(courseSlug) : url_slug(title),
    groups: groupsCourse.map((g) => ({
      id: g.id,
      accept_dt_from: g.accept_dt_from,
      accept_dt_to: g.accept_dt_to,
    })),
  })
)

const $baseFormCourses = combine<any>(
  $courseWithTicket,
  $selectedCourseTaskCheckType,
  $inputsForm,
  $withTicketForm,
  $coverCourseId,
  $iconCourseId,
  classesDropdownModel.store.$selectedItems,
  subjectsDropdownModel.store.$selectedItems,
  $selectedDifficult,
  $selectedForWho,
  $competition,
  $lessonsCourse,
  $admissionText,
  (
    courseWithTicket: any,
    selectedCourseTaskCheckType: any,
    inputsForm: any,
    withTicketForm: any,
    coverCourseId: any,
    iconCourseId: any,
    selectedClasses: any,
    selectedSubjects: any,
    selectedDifficult: any,
    selectedForWho: any,
    competition: boolean,
    lessonsCourse: any,
    admissionText: any
  ): CreateCourseFxParams => ({
    with_ticket: courseWithTicket,
    classes: selectedClasses.map((cl: any) => Number(cl.name)),
    subjects: selectedSubjects.map((sb: any) => Number(sb.name)),
    difficulty: selectedDifficult?.name || null,
    students_type: selectedForWho?.name || null,
    is_competition: competition,
    lessons: sendLessonsFormat(lessonsCourse.filter((l: any) => l.title)),
    task_check_type: selectedCourseTaskCheckType
      ? selectedCourseTaskCheckType.name === 'Автоматизированный'
      : false,
    ...inputsForm,
    ...(courseWithTicket ? withTicketForm : {}),
    image: coverCourseId !== -1 ? coverCourseId : null,
    icon: iconCourseId !== -1 ? iconCourseId : null,
    admission_text: admissionText,
  })
)

export const $canSaveCourse = combine(
  $courseTitle,
  $courseWithTicket,
  $lessonsCourse,
  $questionsCourse,
  subjectsDropdownModel.store.$selectedItems,
  createCoursesFx.pending,
  $groupsCourse,
  (
    courseTitle,
    courseWithTicket,
    lessonsCourse,
    questionsCourse,
    selectedSubjects,
    loading,
    groupsWithTicket
  ) => {
    const hasEmptyLessons = lessonsCourse.filter((l) => l.id === 0).length > 0
    if (!courseWithTicket) {
      return courseTitle && courseTitle.length > 0 && !hasEmptyLessons && !loading
    }
    const hasEmptyGroups =
      groupsWithTicket.filter(
        (l) => l.id === 0 || l.accept_dt_from === null || l.accept_dt_to === null
      ).length > 0
    const isQuestions =
      questionsCourse.length > 0
        ? questionsCourse.filter((i) => i.question.length > 0).length === questionsCourse.length
        : true
    return (
      !hasEmptyLessons &&
      courseTitle &&
      courseTitle.length > 0 &&
      isQuestions &&
      !loading &&
      !hasEmptyGroups
    )
  }
)

// загрузка медиа на бэк
const uploadCoverCourseFx = createEffect({
  handler: (files: FileList | null): Promise<UploadMediaResponse[]> => {
    return Promise.all(
      Array.from(files || []).map(
        (file) =>
          new Promise<UploadMediaResponse>((resolve) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('file_type', 'image')
            const res = uploadMedia(formData).then((r) => r.body)
            resolve(res)
          })
      )
    )
  },
})
const uploadIconCourseFx = createEffect({
  handler: (files: FileList | null): Promise<UploadMediaResponse[]> =>
    Promise.all(
      Array.from(files || []).map(
        (file) =>
          new Promise<UploadMediaResponse>((resolve) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('file_type', 'image')
            const res = uploadMedia(formData).then((r) => r.body)
            resolve(res)
          })
      )
    ),
})

guard({
  clock: uploadCoverCourse,
  filter: (file) => file.length > 0,
  target: [
    uploadCoverCourseFx,
    addToast.prepend(() => ({ type: 'loading', message: 'Идет загрузка файла(ов)' })),
  ],
})
forward({
  from: uploadCoverCourseFx.doneData,
  to: [
    coverCourseIdChanged.prepend((files) => files[0].id),
    successToastEvent('Загрузка завершена'),
  ],
})
guard({
  clock: uploadIconCourse,
  filter: (file) => file.length > 0,
  target: [
    uploadIconCourseFx,
    addToast.prepend(() => ({ type: 'loading', message: 'Идет загрузка файла(ов)' })),
  ],
})
forward({
  from: uploadIconCourseFx.doneData,
  to: [
    iconCourseIdChanged.prepend((files) => files[0].id),
    successToastEvent('Загрузка завершена'),
  ],
})

// агрегация данных медиа
sample({
  clock: coverCourseIdChanged,
  source: $coverCourseId,
  target: getCoverCourse,
})
forward({
  from: getCoverCourse.doneData,
  to: coverCourseChanged.prepend(({ body }) => body.file),
})
sample({
  clock: iconCourseIdChanged,
  source: $iconCourseId,
  target: getIconCourse,
})
forward({
  from: getIconCourse.doneData,
  to: iconCourseChanged.prepend(({ body }) => body.file),
})

forward({
  from: clearFieldsCourseCreate,
  to: [
    classesDropdownModel.methods.resetSelectedItems,
    subjectsDropdownModel.methods.resetSelectedItems,
    resetCourseCourseTaskCheck,
  ],
})

sample({
  source: $baseFormCourses,
  clock: save,
  target: createCourses as any,
})

sample({
  source: $groupsCourse,
  clock: createCoursesFx.doneData.map((data) => data.body.id),
  fn: (groupsCourse: GroupCourseType[], courseId: number) => {
    setSendCourseId(courseId)
    sendGroupsCourse(
      groupsCourse.map((gr) => ({
        course: courseId,
        group: gr.id,
        accept_dt_from: gr.accept_dt_from,
        accept_dt_to: gr.accept_dt_to,
      }))
    )
  },
})

forward({
  from: createCoursesFx.done,
  to: setRedirect,
})

forward({
  from: sendIsCompleteMessage,
  to: successToastEvent('Курс успешно сохранен!'),
})

forward({
  from: createCoursesFx.failData,
  to: addToast.prepend((data: any) => {
    try {
      return { type: 'error', message: data.body[0] }
    } catch (e) {
      return { type: 'error', message: 'Не удалось сохранить курс' }
    }
  }),
})

sample({
  source: [$ifRedirect, $sendCourseId],
  clock: setRedirect,
  fn: (data: [ifRedirect: boolean, id: number]) => {
    const ifRedirect = data[0]
    const id = data[1]
    sendIsCompleteMessage()
    if (ifRedirect) {
      navigatePush({ name: 'learning-courses' })
    } else {
      navigatePush({ name: 'courses-edit-page', params: { id: `${id}` } })
    }
  },
})
