<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="themes-filter"
  >
    <div class="row">
      <CoursesDropdown class="two-third" @setItem="val => setItem({'courses': val})" />
      <SubjectsDropdown class="half-third" @setItem="val => setItem({'subjects': val})" />
    </div>
    <div class="row">
      <StudentsFilterDropdown class="half-third" @setItem="val => setItem({'created_by_id': val})" />
      <ClassesDropdown class="half-third" @setItem="val => setItem({'classes': val})" />
      <GroupsFilterDropdown class="half-third" @setItem="val => setItem({'groups': val})" />
    </div>
    <div class="row">
      <div class="datetime-item half-third">
        <div class="label">Дата подачи от:</div>
        <DatePicker
          :value="$acceptFrom"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setDateFrom"
        />
      </div>
      <div class="datetime-item half-third">
        <div class="label">Дата подачи до:</div>
        <DatePicker
          :value="$acceptTo"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setDateTo"
        />
      </div>
      <StatusFilterDropdown class="half-third"  @setItem="val => setItem({'status': val})" />
    </div>
    <div class="row footer">
      <div class="half-third btn-col">
        <BaseButton
          small
          @click="applyFilters"
        >
          Применить
        </BaseButton>
        <BaseButton
          class="borderless"
          small
          border-without-bg
          @click="resetFilters"
        >
          Сбросить фильтры
        </BaseButton>
      </div>
    </div>
    <Icon
      type="close"
      class="close-icon"
      size="10"
      @click="toggleVisibility(false)"
    />
    <div class="arrow-up" />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  toggleVisibility,
  $acceptFrom,
  $acceptTo,
  setAcceptTo,
  setAcceptFrom,
} from '@/pages/learning/learning-courses-application/list/parts/filter/applications-course-filter.model'
import ClickOutside from '@/features/directives/click-outside.ts'
import { dropdownComponents } from '@/pages/learning/learning-courses-application/list/parts/filter/parts/dropdown-components'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { reset } from '@/pages/common/general-filter/general-filter.model'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ApplicationsCoursesFilter',
  components: {
    Icon,
    ClassesDropdown: dropdownComponents.ClassesDropdown,
    SubjectsDropdown: dropdownComponents.SubjectsDropdown,
    CoursesDropdown: dropdownComponents.CoursesFilterDropdown,
    GroupsFilterDropdown: dropdownComponents.GroupsFilterDropdown,
    StudentsFilterDropdown: dropdownComponents.StudentsFilterDropdown,
    StatusFilterDropdown: dropdownComponents.StatusFilterDropdown,
    BaseButton,
    DatePicker,
  },
  effector: {
    $acceptFrom,
    $acceptTo,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  methods: {
    setAcceptFrom,
    setAcceptTo,
    toggleVisibility,
    closeFilter(event) {
      if (
        !event.target.closest('#filter-icon') &&
        !event.target.closest('.mx-calendar') &&
        !event.target.closest('.mx-time') &&
        event.target.nodeName !== 'use' &&
        !event.target.closest('.icon-wrapper')
      ) {
        toggleVisibility(false)
      }
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    setDateFrom(date) {
      this.setAcceptFrom(date)
      this.setItem({ accept_dt_from: date })
    },
    setDateTo(date) {
      this.setAcceptTo(date)
      this.setItem({ accept_dt_to: date })
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
      reset() // togglers and visibility
    },
  },
})
</script>

<style scoped>
.themes-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}
.row:last-of-type {
  justify-content: flex-end;
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}
.two-third {
  width: calc(100% / 3 * 2 - 20px);
  flex-shrink: 0;
}

.btn-col {
  display: flex;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}

.arrow-up {
  @mixin arrow-up;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
.label {
  margin-bottom: 5px;
  pointer-events: none;
  font-weight: 600;
  color: #000;
}
.date-picker {
  width: 100%;
}
.date-picker /deep/ .mx-input {
  height: 46px;
}
</style>

