import { attach, createEvent, forward } from 'effector-root'
import { getTestAssignmentFx } from '@/features/api/assignment/test-assignment/get-test-assignment'
import { getOlympiadAssignmentFx } from '@/features/api/assignment/olympiad-assignment/get-olympiad-assignment'
import { getLessonAssignmentFx } from '@/features/api/assignment/lesson-assignment/get-lesson-assignment'
import { getExamAssignmentFx } from '@/features/api/assignment/exam-assignment/get-exam-assignment'
import {
  setApplicationStatus,
  setStatus,
} from '@/pages/common/parts/status-controller/status.model'
import { getTicketFx } from '@/features/api/ticket/moderation/get-ticket'

export const loadTestAssignment = attach({
  effect: getTestAssignmentFx,
})
export const loadOlympiadAssignment = attach({
  effect: getOlympiadAssignmentFx,
})
export const loadLessonAssignment = attach({
  effect: getLessonAssignmentFx,
})

export const getIncomingApplication = attach({
  effect: getTicketFx,
})

const loadExamAssignment = attach({
  effect: getExamAssignmentFx,
})

export const loadTestTask = createEvent<number>()
export const loadOlympiadTask = createEvent<number>()
export const loadLessonTask = createEvent<number>()
export const loadExamTask = createEvent<number>()
export const loadApplication = createEvent<number>()

forward({
  from: loadTestTask,
  to: loadTestAssignment,
})
forward({
  from: loadTestAssignment.doneData,
  to: [setStatus.prepend(({ body }) => body.status)],
})

forward({
  from: loadOlympiadTask,
  to: loadOlympiadAssignment,
})
forward({
  from: loadOlympiadAssignment.doneData,
  to: [setStatus.prepend(({ body }) => body.status)],
})
forward({
  from: loadLessonTask,
  to: loadLessonAssignment,
})
forward({
  from: loadLessonAssignment.doneData,
  to: [setStatus.prepend(({ body }) => body.status)],
})

forward({
  from: loadExamAssignment,
  to: loadExamAssignment,
})
forward({
  from: loadExamAssignment.doneData,
  to: setStatus.prepend(({ body }) => body.status),
})

forward({
  from: loadApplication,
  to: getIncomingApplication,
})
forward({
  from: getIncomingApplication.doneData,
  to: [setApplicationStatus.prepend(({ body }) => body.status)],
})
