
























































import Vue from 'vue'
import Card from '@/ui/card/Card.vue'
import FormInput from '@/ui/input/FormInput.vue'
import PositionDropdown from '@/pages/dictionary/themes/edit/parts/position/PositionDropdown.vue'
import Prerequisites from '@/pages/dictionary/themes/edit/parts/prerequisites/Prerequisites.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import TeachingResources from '@/pages/dictionary/themes/edit/parts/teaching-resources/TeachingResources.vue'
import Themes from '@/pages/dictionary/themes/edit/parts/themes/Themes.vue'
import {
  $themeTitle,
  $themeId,
  resetThemeTitle,
  themeTitleChanged,
  $themeTitleErrorModule,
  $prerequisiteTitle,
  $prerequisiteTitleErrorModule,
  resetPrerequisiteTitle,
  prerequisiteTitleChanged,
  $positionErrorModule,
  $subjectErrorModule,
  $classErrorModule,
} from '@/pages/dictionary/themes/edit/theme-edition-page.model'
import { $isPrerequisite } from '@/pages/dictionary/themes/edit/parts/prerequisites/prerequisites.model'

export default Vue.extend({
  components: {
    Card,
    FormInput,
    PositionDropdown,
    Prerequisites,
    SubjectsDropdown,
    ClassesDropdown,
    TeachingResources,
    Themes,
  },
  effector: {
    $themeTitle,
    $themeId,
    $prerequisiteTitle,
    themeTitleError: $themeTitleErrorModule.store.$error,
    prerequisiteTitleError: $prerequisiteTitleErrorModule.store.$error,
    positionError: $positionErrorModule.store.$error,
    subjectError: $subjectErrorModule.store.$error,
    classError: $classErrorModule.store.$error,
    $isPrerequisite,
  },
  methods: {
    resetThemeTitle,
    themeTitleChanged,
    resetPrerequisiteTitle,
    prerequisiteTitleChanged,
  },
})
