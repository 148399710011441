












import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { RefsType } from '@/pages/common/types'
import CreateCertificatesHeader from '@/pages/results/certificates-generator/create-certificates/CreateCertificatesHeader.vue'
import CreateCertificatesForm from '@/pages/results/certificates-generator/create-certificates/CreateCertificatesForm.vue'
import CreateCertificateFooter from '@/pages/results/certificates-generator/create-certificates/CreateCertificatesFooter.vue'
import {
  $template,
  $files,
  createCertificates,
  createCertificatesPreview,
} from '@/pages/results/certificates-generator/create-certificates/model/form-fields.model'

Vue.use(VueEvents)

export default (Vue as VueConstructor<Vue & { $refs: RefsType }>).extend({
  name: 'CertificatesGeneratorCreateCertificates',
  components: {
    CreateCertificateFooter,
    CreateCertificatesForm,
    CreateCertificatesHeader,
  },
  effector: {
    $template,
    $files,
  },
  computed: {
    disableCreate() {
      return !this.$template || !this.$files
    },
  },
  methods: {
    createCertificates,
    createCertificatesPreview,
  },
})
