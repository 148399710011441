import { createEvent, forward, attach, restore, sample } from 'effector-root'
import { getSubjectsListFx } from '@/features/api/subject/get-subjects-list'
import { DropdownItem } from '@/pages/common/types'
import { GetListQueryParams } from '@/features/api/types'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { SubjectGrid } from '@/features/api/subject/types'
import { debounce } from 'patronum'

const getSubjects = attach({
  effect: getSubjectsListFx,
})

export const subjectsDropdownModel = createDropdownModel<SubjectGrid>(getSubjects)

export const setSelectedSubject = createEvent<DropdownItem | null>()
export const $selectedSubject = restore(setSelectedSubject, null)

export const loadSubjects = createEvent<void>()

export const searchSubjects = createEvent<void>()

sample({
  clock: loadSubjects,
  source: {
    $nextPage: subjectsDropdownModel.store.$nextPage,
    search: subjectsDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search }),
  }),
  target: getSubjects,
})

sample({
  clock: searchSubjects,
  source: {
    search: subjectsDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search && { search: params.search }),
  }),
  target: getSubjects,
})

const debounced = debounce({
  source: subjectsDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchSubjects,
})

forward({
  from: subjectsDropdownModel.methods.canLoadNextPage,
  to: loadSubjects,
})

sample({
  clock: getSubjects.doneData,
  source: {
    items: subjectsDropdownModel.store.$items,
    $nextPage: subjectsDropdownModel.store.$nextPage,
    search: subjectsDropdownModel.store.$searchString,
  },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((field) => ({
      name: `${field.id}`,
      title: field.name,
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: subjectsDropdownModel.store.$items,
})

forward({
  from: subjectsDropdownModel.methods.resetDropdown,
  to: setSelectedSubject.prepend(() => null),
})
