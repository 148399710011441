












































import Vue, { PropType } from 'vue'
import BaseInput from '@/ui/input/BaseInput.vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'FormInput',
  components: {
    BaseInput,
    Icon,
  },
  props: {
    value: { type: [String, Number] as PropType<string | number>, default: '' },
    label: { type: String as PropType<string>, default: '' },
    labelVisible: { type: Boolean as PropType<boolean>, default: true },
    placeholder: { type: String as PropType<string>, default: '' },
    type: { type: String as PropType<string>, default: 'text' },
    maxLength: { type: Number },
    minValue: { type: Number },
    errorMessage: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean> },
    clearBtn: { type: Boolean as PropType<boolean> },
    readOnlyDropdown: { type: Boolean as PropType<boolean> },
    min: { type: [String, Number] as PropType<string | number>, default: '' },
    name: { type: String, default: '' },
  },
  model: {
    event: 'input',
    prop: 'value',
  },
  data: () => ({
    showClearBtn: false,
  }),
  computed: {
    hasError() {
      return this.$slots.error || this.errorMessage
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.showClearBtn = newVal !== oldVal && newVal.length && this.clearBtn
      },
    },
  },
  mounted() {
    if (this.value && this.clearBtn) {
      this.showClearBtn = true
    }
  },
})
