import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import { EmployeeList, GetEmployeeListQueryParams } from '@/features/api/employee/types'

export const getEmployeesListFx = createApiEffect<
  GetEmployeeListQueryParams,
  TableDataResponse<EmployeeList[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/user-app/employee/list/',
    query,
  }),
})

export const getEmployeesListCert = createApiEffect<
  GetEmployeeListQueryParams,
  { id: number; name: string }[]
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/user-app/users/filter/cert-created-by/',
    query,
  }),
})
