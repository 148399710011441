import { createEvent, restore, forward } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { rolesDropdownModel } from '@/pages/users/employes/list/parts/roles-dropdown/roles-dropdown.model'

const dropdownModules = {
  rolesDropdownModel,
}

export const employeesFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownModules
)

export const employeesPageParams = createPageParamsModel()

export const reset = createEvent<void>()

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

forward({
  from: reset,
  to: rolesDropdownModel.methods.resetDropdown,
})
