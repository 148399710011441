import { DropdownItem } from '@/pages/common/types'

export type RequestDeleteStudents = {
  student_ids: number[]
  ticket_comment?: string
}

export type RegistrationsType = {
  code: string
  name: string
}

// eslint-disable-next-line no-shadow
export enum TooltipFilterEnum {
  courses = 'courses',
  status = 'status',
  class = 'classes',
  groups = 'groups',
  registration = 'registration',
}

export enum EditStudentFields {
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  phone = 'phone',
  study_year = 'study_year',
  status = 'status',
  step = 'step',
  groups = 'groups',
  courses_active = 'courses',
}

export type ChangeFieldType = {
  type: EditStudentFields
  value: string | DropdownItem
}
