
























import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import { DraggableText } from '@/pages/common/parts/tasks/types'

export default Vue.extend({
  name: `TextMatchItem`,
  components: {
    FormInput,
    Icon,
  },
  props: {
    value: {
      type: Object as PropType<DraggableText>,
      required: true,
    },
  },
})
