import { createApiEffect } from '@/features/api/common/create-api-effect'
import { Slide } from '@/features/api/content/types'

export const updateSlideFx = createApiEffect<Partial<Slide>, Slide>({
  requestMapper: (params) => ({
    method: 'PATCH',
    url: `/api/content-app/slider/${params.id}/`,
    body: params,
  }),
})
