import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExamAssignmentsBulkUpdate } from '@/features/api/assignment/types/exam-assignments-types'
import { UpdateAssignmentsBulkFailResponse } from '@/features/api/assignment/types/types'

export const duplicateExamsAssignmentsFx = createApiEffect<
  ExamAssignmentsBulkUpdate,
  ExamAssignmentsBulkUpdate,
  UpdateAssignmentsBulkFailResponse
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/assignment-app/exam-assignment/bulk-patch/',
    body,
  }),
})
