





















import Vue, { PropType } from 'vue'
import WithTooltip from '@/ui/tooltip/WithTooltip.vue'

export default Vue.extend({
  name: 'PrerequisiteMenu',
  components: {
    WithTooltip,
  },
  props: {
    prerequisiteId: { type: Number as PropType<number> },
  },
  data: () => ({
    opened: false,
  }),
  methods: {
    toggleOpened() {
      this.opened = !this.opened
    },
    openNewTab() {
      window.open(`/dictionary/themes/edit/${this.prerequisiteId}`, '_blank')
    },
  },
})
