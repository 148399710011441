import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const getPartTimeTestsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions?.exams_ptexamtest_ptexams?.can_edit,
    },
    {
      name: 'delete',
      title: 'Удалить',
      action: 'onDelete',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.exams_ptexamtest_ptexams.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
