import { render, staticRenderFns } from "./FullTimeExamTestsListPage.vue?vue&type=template&id=4ab6c30f&scoped=true&"
import script from "./FullTimeExamTestsListPage.vue?vue&type=script&lang=ts&"
export * from "./FullTimeExamTestsListPage.vue?vue&type=script&lang=ts&"
import style0 from "./FullTimeExamTestsListPage.vue?vue&type=style&index=0&id=4ab6c30f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab6c30f",
  null
  
)

export default component.exports