import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import { NewsList } from '@/features/api/content/types'

export const getNewsListFx = createApiEffect<void, TableDataResponse<NewsList[]>>({
  requestMapper: () => ({
    method: 'GET',
    url: '/api/content-app/news/list/',
  }),
})
