import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { SubjectGrid } from '@/features/api/subject/types'

export const getSubjectsListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<SubjectGrid[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/subject-app/subjects/list/',
    query,
  }),
})
