











































































import Vue from 'vue'
import StatusDropdown from '@/pages/users/students/edit/parts/StatusDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import FormInput from '@/ui/input/FormInput.vue'
import { $editedStudentId } from '@/pages/users/students/edit/model/edit-student.model'
import {
  $firstName,
  $lastName,
  $email,
  $phone,
  changeFieldValue,
  $groups,
  $courses,
} from '@/pages/users/students/edit/model/form-fields.model'
import { EditStudentFields } from '@/pages/users/students/list/model/types'

export default Vue.extend({
  name: 'EditStudentContent',
  components: {
    FormInput,
    StatusDropdown,
    ClassesDropdown,
  },
  effector: {
    $firstName,
    $lastName,
    $email,
    $phone,
    $editedStudentId,
    $groups,
    $courses,
  },
  data: () => ({
    fieldType: EditStudentFields,
  }),
  methods: {
    changeField(type: EditStudentFields, value: string) {
      changeFieldValue({ type, value })
    },
  },
})
