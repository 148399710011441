
















































import Vue, { PropType } from 'vue'
import SwiperCore, { Controller, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { $images } from '@/pages/applications/modals/outgoing-comment/outgoing-comment.model'
import Icon from '@/ui/icon/Icon.vue'

SwiperCore.use([Navigation, Controller])

export default Vue.extend({
  components: {
    Icon,
    Swiper,
    SwiperSlide,
  },
  effector: {
    $images,
  },
  props: {
    currentSlide: {
      type: Number as PropType<number>,
      default: 0,
    },
    isVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      swiper: {} as SwiperCore,
    }
  },
  computed: {
    swiperOptions() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const instance = this

      return {
        slidesPerView: 1,
        loop: true,
        isNavigation: true,
        scrollbar: false,
        height: 500,
        navigation: {
          nextEl: '.FSS-button-next',
          prevEl: '.FSS-button-prev',
        },

        on: {
          init(swiper: SwiperCore) {
            instance.swiper = swiper
          },
        },
      }
    },
    isNavigationVisible(): boolean {
      return !!this.$images && this.$images.length > this.swiperOptions.slidesPerView
    },
  },
  watch: {
    currentSlide(value) {
      if (value) {
        this.$nextTick(() => {
          if (this.swiper.activeIndex !== this.currentSlide) {
            this.swiper.slideTo(value, 0)
          }
        })
      }
    },
  },
})
