<template>
  <div
    class="result-filter"
  >
    <div class="row">
      <CourseDropdown
        ref="courses"
        class="half"
        @setItem="setCourse"
      />
      <LessonStatusDropdown
        ref="lessonsStatus"
        class="half"
        @setItem="setFindStatus"
      />
    </div>
    <div class="row">
      <LessonDropdown
        ref="lessons"
        class="half-third"
        :is-by-id="true"
        :find-id="$findCourseId"
        @setItem="setLessons"
      />
      <GroupsDropdown
        ref="groups"
        class="half-third"
        :is-by-id="true"
        :find-id="$CourseLessonId"
        @setItem="setFindGroup"
      />
      <StudentsDropdown
        ref="students"
        class="half-third"
        :is-by-id="true"
        :find-id="$CourseLessonId"
        @setItem="(val) => setItem({'student': val})"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  $findCourseId,
  resultStudentsFilters,
  setFindCourseId,
  setFindLessonId,
  $CourseLessonId,
  setFindStatus,
  setFindGroup,
} from '@/pages/results/courses-students/parts/filter/result-student-filter.model'
import ClickOutside from '@/features/directives/click-outside.ts'
import { dropdownResultCoursesComponents } from '@/pages/results/courses-students/parts/filter/parts/dropdown-components'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ResultCourseFilter',
  components: {
    CourseDropdown: dropdownResultCoursesComponents.CoursesFilterDropdown,
    LessonStatusDropdown: dropdownResultCoursesComponents.LessonStatusDropdown,
    LessonDropdown: dropdownResultCoursesComponents.LessonDropdown,
    GroupsDropdown: dropdownResultCoursesComponents.GroupsDropdown,
    StudentsDropdown: dropdownResultCoursesComponents.StudentsDropdown,
  },
  effector: {
    $filterParams: resultStudentsFilters.store.$filterParams,
    $findCourseId,
    $CourseLessonId,
  },
  methods: {
    setFindStatus,
    setFindGroup,
    setItem(filter) {
      this.$emit('changeFilter', filter)
      setTimeout(() => {
        this.$emit('setFilter')
      })
    },
    applyFilters() {
      this.$emit('setFilter')
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
    },
    setCourse(val) {
      this.$emit('removeParamsFilter', 'lesson')
      this.$emit('removeParamsFilter', 'student')
      setFindCourseId(Number(val))
      this.setItem({ course: val })
    },
    setLessons(val) {
      this.$emit('removeParamsFilter', 'student')
      setFindLessonId(Number(val))
      this.setItem({ lesson: val })
    },
  },
  beforeDestroy() {
    this.$refs.courses.resetDropdown()
    this.$refs.lessonsStatus.resetDropdown()
    this.$refs.lessons.resetDropdown()
    this.$refs.groups.resetDropdown()
    this.$refs.students.resetDropdown()
  },
})
</script>

<style scoped>
.result-filter {
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-bottom: 20px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.half {
  width: calc(50% - 20px);
  flex-shrink: 0;
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}

.two-third {
  width: calc(100% / 3 * 2 - 20px);
  flex-shrink: 0;
}

.btn-col {
  display: flex;
  align-items: flex-end;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}

.arrow-up {
  @mixin arrow-up;
}
</style>

