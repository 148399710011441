<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="themes-filter"
  >
    <div class="row">
      <FolderDropdown class="half-second" @setItem="val => setItem({'folder': val})" />
      <SubjectDropdown class="half-second" @setItem="val => setItem({'subjects': val})" />
      <ClassesDropdown class="half-second" @setItem="val => setItem({'classes': val})" />
    </div>
    <div class="row-aligned">
      <BaseSwitch
        class="half-second"
        :checked="$filterParams.is_active"
        @change="setItem({'is_active': $event})"
      >
        <p>Отобразить доступные для прохождения</p>
      </BaseSwitch>
      <TasksChoiceDropdown class="half-second" @setItem="val => setItem({'tasks_choice': val})" />
      <div class="buttons">
        <div class="btn">
          <BaseButton
            small
            @click="applyFilters"
          >
            Применить
          </BaseButton>
        </div>
        <div class="btn">
          <BaseButton
            class="borderless"
            small
            border-without-bg
            @click="resetFilters"
          >
            Сбросить фильтры
          </BaseButton>
        </div>
      </div>
    </div>
    <Icon
      type="close"
      class="close-icon"
      size="10"
      @click="toggleVisibility(false)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import {
  reset,
  toggleVisibility,
  examTestsFilters,
} from '@/pages/exams/full-time-exam-test/list/parts/exam-tests-filter.model'
import ClickOutside from '@/features/directives/click-outside.ts'
import { dropdownComponents } from '@/pages/exams/full-time-exam-test/list/parts/dropdown-components'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ExamsTestsFilter',
  components: {
    Icon,
    BaseButton,
    BaseSwitch,
    TasksChoiceDropdown: dropdownComponents.TasksChoiceDropdown,
    SubjectDropdown: dropdownComponents.SubjectFilterDropdown,
    FolderDropdown: dropdownComponents.ExamTestsFolders,
    ClassesDropdown: dropdownComponents.ClassesDropdown,
  },
  effector: {
    $filterParams: examTestsFilters.store.$filterParams,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },

  methods: {
    toggleVisibility,
    closeFilter(event) {
      // check for general filter icon
      if (!event.target.closest('#filter-icon')) {
        toggleVisibility(false)
      }
    },
    setItem(filter) {
      try {
        this.$emit('changeFilter', { [Object.keys(filter)[0]]: Object.values(filter)[0].join(',') })
      } catch (e) {
        this.$emit('changeFilter', filter)
      }
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
      reset() // togglers and visibility
    },
  },
})
</script>

<style scoped>
.themes-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row-aligned {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .close-icon {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.half-second {
  width: 100%;
  margin-right: 30px;
}

.buttons {
  display: flex;
  align-self: flex-end;
  width: calc(100% / 3 - 20px);
  margin-left: auto;
  flex-shrink: 0;
}

.btn {
  width: max-content;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}

.arrow-up {
  @mixin arrow-up;
}
.block {
  display: flex;
}
</style>
