



























import Vue from 'vue'
import TaskHeader from '@/pages/bank/common/parts/Header.vue'
import TaskContent from '@/pages/bank/exam-tasks/edit/parts/Content.vue'
import TaskFooter from '@/pages/bank/common/parts/Footer.vue'
import SelectTask from '@/pages/preview-tasks/parts/select-task/SelectTask.vue'
import {
  $currentIndex,
  $currentQuestion,
  setCurrentQuestion,
} from '@/pages/preview-tasks/parts/select-task/select-task.model'
import {
  save,
  $canSave,
  clearFields,
  setRedirectAfterSave,
  componentMounted,
  componentDestroyed,
  loadTask,
  $taskId,
  $isPreview,
  toggleIsPreview,
} from '@/pages/bank/exam-tasks/edit/parts/exam-task-edit.model'
import { createExamAssignmentFx } from '@/features/api/assignment/exam-assignment/create-exam-assignment'
import { getExamAssignmentFx } from '@/features/api/assignment/exam-assignment/get-exam-assignment'
import { navigateReplace } from '@/features/navigation/navigationMethods'
import { $token } from '@/features/api/common/request'
import { FromPage } from '@/pages/common/types'
import { combineRouteQueries } from '@/features/lib'
import { DEFAULT_ID } from '@/pages/common/constants'

export default Vue.extend({
  name: 'ExamTaskEditionPage',
  components: {
    TaskHeader,
    TaskContent,
    TaskFooter,
    SelectTask,
  },
  effector: {
    $isPreview,
    $canSave,
    $token,
    isLoading: createExamAssignmentFx.pending || getExamAssignmentFx.pending,
    taskID: $taskId,
    $currentIndex,
    $currentQuestion,
  },
  data() {
    return {
      questions: [] as string[],
      fromPage: '' as FromPage,
    }
  },
  computed: {
    title() {
      return this.taskID !== 0
        ? 'Редактирование задания для очного экзамена'
        : 'Создание задания для очного экзамена'
    },
  },
  watch: {
    $isPreview: {
      handler(newVal) {
        if (newVal) {
          navigateReplace({
            name: 'preview-task',
            query: {
              questions: this.questions.length ? this.questions.join(',') : this.$route.params.id,
              applications: `${this.taskID}`,
              fromPage: 'tasks',
              taskType: 'exam-assignment',
              currentQuestion: String(this.$currentQuestion),
              token: this.$token,
            },
          })
        }
      },
    },
    $currentIndex: {
      handler(newVal) {
        !!this.questions.length && newVal !== DEFAULT_ID && loadTask(+this.questions[newVal])
      },
    },
  },
  methods: {
    toggleIsPreview,
    goToSelectedTask() {
      if (this.$route.query.currentQuestion !== String(this.$currentQuestion)) {
        this.$router.replace(
          combineRouteQueries(this.$route.query, { currentQuestion: String(this.$currentQuestion) })
        )
      }
    },
    saveTask(isRedirect: boolean) {
      save()
      if (isRedirect) setRedirectAfterSave(true)
    },
  },
  created() {
    const { questions, fromPage, currentQuestion } = this.$route.query

    if (questions && typeof questions === 'string') {
      this.questions = questions.split(',')
    }
    if (fromPage && (fromPage === 'applications' || fromPage === 'tasks')) {
      this.fromPage = fromPage
    }
    if (currentQuestion && typeof +currentQuestion === 'number') {
      setCurrentQuestion(+currentQuestion)
    }
    if (this.questions.length && this.$currentIndex !== DEFAULT_ID) {
      loadTask(+this.questions[this.$currentIndex])
    } else if (+this.$route.params.id) {
      loadTask(+this.$route.params.id)
    }
  },
  beforeMount() {
    if (this.$route.query.togglePreview === 'toggle') {
      toggleIsPreview(true)
    }
  },
  mounted() {
    componentMounted()
  },
  beforeDestroy() {
    componentDestroyed()
    clearFields()
    toggleIsPreview(false)
  },
})
