












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  typeDropdownModule,
  loadTypes,
  $types,
  setSelectedType,
} from '@/pages/dictionary/resources/list/parts/resources-filter/parts/type/type-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    $types,
    ...typeDropdownModule.store,
  },
  methods: {
    ...typeDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
      setSelectedType(item)
    },
  },
  mounted() {
    loadTypes()
  },
})
