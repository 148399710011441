

















import Vue from 'vue'
import EditNewsContent from '@/pages/content/news/edit/parts/EditNewsContent.vue'
import EditNewsFooter from '@/pages/content/news/edit/parts/EditNewsFooter.vue'
import EditNewsHeader from '@/pages/content/news/edit/parts/EditNewsHeader.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import {
  $disabledSaveButtons,
  clearFields,
} from '@/pages/content/news/edit/model/form-fields.model'
import {
  changeNewsId,
  saveNewsInfo,
  setRedirectAfterSave,
} from '@/pages/content/news/edit/model/edit-news.model'
import { updateNewsByIdFx } from '@/features/api/content/news/patch-news-by-id'

export default Vue.extend({
  name: 'EditNewsPage',
  components: {
    EditNewsHeader,
    EditNewsFooter,
    EditNewsContent,
    LoaderBig,
  },
  effector: {
    $disabledSaveButtons,
    $isEditing: updateNewsByIdFx.pending,
  },
  methods: {
    saveTask(isRedirect: boolean) {
      if (isRedirect) {
        setRedirectAfterSave(true)
        saveNewsInfo()
      } else {
        saveNewsInfo()
      }
    },
  },
  created() {
    changeNewsId(this.$route.params.id)
  },
  beforeDestroy() {
    clearFields()
  },
})
