import { render, staticRenderFns } from "./LessonsDropdown.vue?vue&type=template&id=0f349095&"
import script from "./LessonsDropdown.vue?vue&type=script&lang=ts&"
export * from "./LessonsDropdown.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports