

















































import Vue from 'vue'
import {
  uploadIconSubject,
  uploadImageSubject,
  $iconSubject,
  $imageSubject,
  resetIconSubjectId,
  resetImageSubjectId,
  $imageSubjectId,
  $colorIdErrorModule,
  $iconSubjectId,
} from '@/pages/dictionary/subjects/common/create-edit.model'
import FileInput from '@/ui/audio-file-input/AudioFileInput.vue'
import ColorPicker from '@/pages/dictionary/subjects/common/colors/ColorPicker.vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  components: { FileInput, Icon, ColorPicker },
  effector: {
    $iconSubject,
    $imageSubject,
    $imageSubjectId,
    colorIdError: $colorIdErrorModule.store.$error,
    $iconSubjectId,
  },
  methods: {
    uploadIconSubject,
    uploadImageSubject,
    resetIconSubjectId,
    resetImageSubjectId,
  },
})
