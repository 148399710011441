
















import Vue, { PropType } from 'vue'
import { Toast } from '@/features/toasts/toasts.model'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'Toast',
  components: {
    Icon,
  },
  props: {
    toast: { type: Object as PropType<Toast>, required: true },
  },
  computed: {
    icon() {
      switch (this.toast.type) {
        case 'error':
          return 'close'
        case 'success':
          return 'tick'
        case 'loading':
          return 'loader'
        case 'no-internet':
          return 'wifi'
        default: {
          // Toast type unknown, icon break'
          return ''
        }
      }
    },
    isSuccess() {
      return this.toast.type === 'success'
    },
    isLoading() {
      return this.toast.type === 'loading'
    },
    isError() {
      return this.toast.type === 'error'
    },
    isNoInternet() {
      return this.toast.type === 'no-internet'
    },
  },
})
