










































































import Vue, { PropType } from 'vue'
import { Chrome } from 'vue-color'
import Icon from '@/ui/icon/Icon.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $textTemplate,
  setTextTemplate,
  $popover,
  togglePopover,
  $contextMenu,
  toggleContextMenu,
  $colorsPalette,
  setColorsPalette,
} from '@/pages/common/parts/tasks/color-highlight-answer/color-highlight-answer.model'
import { errorToastEvent } from '@/features/toasts/toasts.model'
import { FinalNodeLinkCopy, transformTreeNode } from '@/features/lib'
import { ChildNodes } from '@/pages/common/types'

const ClickOutside = require('vue-click-outside')

type SetupContextMenuArguments = {
  parentElementPosition: DOMRect
  mouseEvent: MouseEvent
  editor: any
}

export default Vue.extend({
  name: 'CorrectAnswerForm',
  directives: {
    ClickOutside,
  },
  components: {
    Icon,
    ColorPicker: Chrome,
    Wysiwyg,
    BaseButton,
  },
  effector: {
    $textTemplate,
    $popover,
    $contextMenu,
    $colorsPalette,
  },
  data: () => ({
    editorWithSelection: {} as PropType<any>,
    selectedColor: '' as string | null,
    contextMenuStyle: {
      height: '100px',
      left: '0',
      top: '0',
    },
    contextMenuSettled: false,
    colors: {
      hex: '#ffffff',
    },
  }),
  methods: {
    setTextTemplate,
    openContextMenu() {
      setTimeout(() => {
        toggleContextMenu(true)
        this.contextMenuSettled = true
      }, 100)
    },
    closeContextMenu() {
      if (this.contextMenuSettled) {
        toggleContextMenu(false)
      }
      this.contextMenuSettled = false
    },
    togglePopover() {
      togglePopover(!this.$popover)
    },
    addColor() {
      if (this.$colorsPalette.find((color) => color === this.colors.hex)) {
        errorToastEvent('Такой цвет уже существует!')
        return
      }
      setColorsPalette([...this.$colorsPalette, this.colors.hex])
      this.togglePopover()
    },
    removeColor(colorToRemove: string) {
      const palette = this.$colorsPalette.filter((color) => color !== colorToRemove)
      setColorsPalette(palette)
    },
    setupContextMenu({ parentElementPosition, mouseEvent, editor }: SetupContextMenuArguments) {
      const selectedHTML = editor.getSelectedHtml()
      let rangeHasEditorHighlight = false

      const elementHasEditorHighlight = (element: Element) => {
        if (
          'hasAttribute' in element &&
          element.hasAttribute('style') &&
          element.getAttribute('style')!.match(/background-color/g)?.length &&
          !element.hasAttribute('it-fill')
        ) {
          rangeHasEditorHighlight = true
        }
      }

      const preOrderTreeTraversal = (node: Element, callback: (root: Element) => void) => {
        callback(node)
        if (node.children.length !== 0) {
          Array.prototype.forEach.call(node.children, (child: Element) => {
            preOrderTreeTraversal(child, callback)
          })
        }
      }
      preOrderTreeTraversal(selectedHTML.$, elementHasEditorHighlight)

      if (rangeHasEditorHighlight) return

      let positionX = mouseEvent.x
      let positionY = parentElementPosition.top - parseInt(this.contextMenuStyle.height, 10)

      const farLeftPosition = parentElementPosition.left - 10
      const farRightPosition = parentElementPosition.right + 10
      if (mouseEvent.x > farRightPosition) {
        positionX = parentElementPosition.right
      }
      if (mouseEvent.x < farLeftPosition) {
        positionX = parentElementPosition.left
      }

      const contextMenuParentPosition = this.$el.getClientRects()[0]
      positionX -= contextMenuParentPosition.left
      positionY -= contextMenuParentPosition.top

      this.contextMenuStyle = {
        ...this.contextMenuStyle,
        top: `${positionY}px`,
        left: `${positionX}px`,
      }
      this.editorWithSelection = editor
      this.openContextMenu()
    },
    replaceSpacesWithSpecialCharacter(string: string) {
      /* https://dev.ckeditor.com/ticket/6876 */
      return string.replace(/ /g, '\xA0')
    },
    cleanRange(node: ChildNodes, finalNodeLinkCopy: FinalNodeLinkCopy): FinalNodeLinkCopy {
      if (
        (node.nodeName === 'SPAN' && 'hasAttribute' in node && node.hasAttribute('it-fill')) ||
        node.nodeType === 11
      ) {
        return finalNodeLinkCopy
      }
      if (node.nodeType === 3) {
        const textNodeClone = node.cloneNode()
        textNodeClone.textContent = this.replaceSpacesWithSpecialCharacter(
          textNodeClone.textContent!
        )
        finalNodeLinkCopy!.appendChild(textNodeClone)
      } else {
        const cleanNodeCopy = node.cloneNode()
        finalNodeLinkCopy!.appendChild(cleanNodeCopy)
        return cleanNodeCopy
      }
      return null
    },
    setTextColor(color: string) {
      this.selectedColor = color
      const editor = this.editorWithSelection

      /* @ts-ignore */ // eslint-disable-next-line
      const textWrapper = new CKEDITOR.dom.element('span')
      textWrapper.$.setAttribute('it-fill', color)
      textWrapper.$.setAttribute('style', `background-color: ${color}`)

      /* @ts-ignore */
      transformTreeNode(editor.getSelectedHtml().$, this.cleanRange, textWrapper.$)
      /* @ts-ignore */
      this.editorWithSelection.insertElement(textWrapper)
      this.closeContextMenu()
    },
    removeTextColor() {
      const fragment = document.createElement('DIV')
      /* @ts-ignore */
      transformTreeNode(this.editorWithSelection.getSelectedHtml().$, this.cleanRange, fragment)
      const finalString = fragment.outerHTML.slice(5, -6)
      /* @ts-ignore */
      this.editorWithSelection.insertHtml(finalString)
      this.closeContextMenu()
    },
  },
})
