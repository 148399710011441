


























import Vue, { PropType } from 'vue'
import TreeNode from '@/pages/dictionary/files/parts/tree/parts/TreeNode.vue'
import { $filesTree, $filesTreeTotal } from '@/pages/dictionary/files/system-files-page.model'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { FiltersParams } from '@/pages/common/types'

export default Vue.extend({
  name: 'FilesTree',
  components: {
    TreeNode,
    NoDataContent,
  },
  props: {
    showPaste: { type: Boolean },
    filters: { type: Object as PropType<FiltersParams> },
    token: { type: String },
  },
  effector: { $filesTree, $filesTreeTotal },
})
