import {
  attach,
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getThemesTreeListFx } from '@/features/api/subject/get-themes-tree-list'
import { combineEvents, debounce } from 'patronum'
import {
  $selectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { $selectedClass } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { formatData } from '@/features/lib'
import { GetListQueryParams } from '@/features/api/types'

export const getThemesTreeList = attach({
  effect: getThemesTreeListFx,
})

export const getPrerequisitesTreeList = attach({
  effect: getThemesTreeListFx,
})

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

export const loadThemes = createEvent<void>()

export const themesDropdownModule = createFilter()

export const $themes = createStore<DropdownItem[]>([]).reset(
  componentDestroyed,
  themesDropdownModule.methods.resetDropdown
)

export const setSelectedTheme = createEvent<DropdownItem | null>()
export const $selectedTheme = restore(setSelectedTheme, null).reset(
  componentDestroyed,
  subjectsDropdownModel.methods.itemChanged
)

export const setThemeToSelect = createEvent<DropdownItem | null>()
export const $themeToSelect = restore(setThemeToSelect, null).reset(componentDestroyed)

export const getThemes = createEvent<GetListQueryParams>()
export const getPrerequisites = createEvent<GetListQueryParams>()

const themesDropdownFilled = createEvent<void>()

$themes.watch((items) => {
  items.length && themesDropdownFilled()
})

const $formToGetThemeList = combine($selectedClass, $selectedSubject, (study_year, subject) => ({
  study_year: study_year && +study_year.name,
  subject: subject && +subject.name,
}))

const debounced = debounce({
  source: $formToGetThemeList,
  timeout: 150,
})

guard({
  clock: debounced,
  filter: $isComponentMounted,
  target: [getThemes, getPrerequisites],
})

guard({
  clock: getThemes,
  filter: ({ subject }) => typeof subject === 'number',
  target: getThemesTreeList.prepend((payload: GetListQueryParams) => ({
    ...payload,
    is_not_prerequisite: true,
  })),
})

guard({
  clock: getPrerequisites,
  filter: ({ subject }) => typeof subject === 'number',
  target: getPrerequisitesTreeList.prepend((payload: GetListQueryParams) => ({
    subject: payload.subject,
    is_prerequisite: true,
  })),
})

const combinedTree = combineEvents({
  events: {
    themes: getThemesTreeList.doneData.map((data) => formatData(data.body, false)),
    prerequisites: getPrerequisitesTreeList.doneData.map((data) => formatData(data.body, true)),
  },
})

export const $canSetThemePosition = combine(
  $selectedSubject,
  $themes,
  (subject, themes): boolean => !!subject && !!themes.length
).on(
  getPrerequisitesTreeList.doneData.map((data) => formatData(data.body, true)),
  (state, data) => state || data.length
)

guard({
  clock: getPrerequisitesTreeList.doneData.map((data) => formatData(data.body, true)),
  filter: $selectedClass.map((study_year) => !!study_year === false),
  target: $themes,
})

guard({
  source: $themeToSelect,
  clock: getPrerequisitesTreeList.doneData,
  filter: (theme) => !!theme,
  target: [
    themesDropdownModule.methods.itemChanged,
    setSelectedTheme,
    setThemeToSelect.prepend(() => null),
  ],
})

sample({
  clock: combinedTree,
  fn: (data): DropdownItem[] => {
    Array.prototype.push.apply(data.themes, data.prerequisites)
    return data.themes
  },
  target: $themes,
})

guard({
  clock: themesDropdownFilled,
  source: $themeToSelect,
  filter: $themeToSelect.map((theme) => theme !== null),
  target: [
    themesDropdownModule.methods.itemChanged,
    setSelectedTheme,
    setThemeToSelect.prepend(() => null),
  ],
})

forward({
  from: loadThemes,
  to: getThemesTreeList.prepend(() => ({ is_prerequisite: false })),
})
