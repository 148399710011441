import { studentsDropdownModule } from '@/pages/common/dropdowns/students/students-dropdown.model'
import { statusDropdownModule } from '@/pages/common/dropdowns/testing/status-dropdown/status-dropdown.model'
import { typeDropdownModule } from '@/pages/common/dropdowns/testing/type-dropdown/type-dropdown.model'
import { difficultyDropdownModule } from '@/pages/common/dropdowns/testing/difficulty-dropdown/difficulty-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { groupsDropdownModule } from '@/pages/common/dropdowns/groups/groups-dropdown.model'
import { testsDropdownModel } from '@/pages/common/dropdowns/tests/test-list-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'

export const dropdownResultsTestsAllModules = {
  groupsDropdownModule,
  studentsDropdownModule,
  statusDropdownModule,
  typeDropdownModule,
  difficultyDropdownModule,
  subjectsDropdownModel,
  testsDropdownModel,
  classesDropdownModule,
}
