





































































import Vue from 'vue'
import Card from '@/ui/card/Card.vue'
import FormInput from '@/ui/input/FormInput.vue'
import ThemesDropdown from '@/pages/common/dropdowns/themes-tree/ThemesDropdown.vue'
import TypeDropdown from '@/pages/dictionary/resources/edit/parts/type/TypeDropdown.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import FileUploadBlock from '@/pages/dictionary/resources/edit/parts/file-upload/FileUploadBlock.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  $themeErrorModule,
  $typeErrorModule,
  $resourceDescription,
  resourceDescriptionChanged,
  $link,
  linkChanged,
  resetLink,
  $linkErrorModule,
  $fileErrorModule,
  $descriptionErrorModule,
  playerLinkChanged,
  resetPlayerLink,
  $playerLink,
} from '@/pages/dictionary/resources/edit/resource-edition-page.model'
import { $selectedType } from '@/pages/dictionary/resources/edit/parts/type/type-dropdown.model'

export default Vue.extend({
  components: {
    Card,
    FormInput,
    TypeDropdown,
    ThemesDropdown,
    SubjectsDropdown,
    ClassesDropdown,
    Wysiwyg,
    FileUploadBlock,
  },
  effector: {
    themeError: $themeErrorModule.store.$error,
    typeError: $typeErrorModule.store.$error,
    $resourceDescription,
    $link,
    linkError: $linkErrorModule.store.$error,
    $playerLink,
    $selectedType,
    fileError: $fileErrorModule.store.$error,
    descriptionError: $descriptionErrorModule.store.$error,
  },
  methods: {
    resourceDescriptionChanged,
    linkChanged,
    resetLink,
    playerLinkChanged,
    resetPlayerLink,
  },
})
