import { addToast, Toast } from '@/features/toasts/toasts.model'
import { createEvent, restore, sample } from 'effector'
import { FinalTextRelated } from '@/features/api/test/types'
import { condition } from 'patronum'
import { $maxPoints } from '@/pages/common/parts/tests/manual-generation/manual-generation-form.model'

export const clearFields = createEvent<void>()

export const defaultFinalText = {
  id: 0,
  score_min: null,
  score_max: null,
  text: '',
  score_min_item: null,
  score_max_item: null,
  isNew: true,
}
export const setInput = createEvent<FinalTextRelated[]>()
export const $finalTextDropdownsPoints = restore(setInput, [defaultFinalText]).reset(clearFields)

export const checkFinalTextFormValidPoints = createEvent<void>()

export const toggleFinalTextFormValid = createEvent<boolean>()
export const $finalTextFormValidPoints = restore(toggleFinalTextFormValid, false)

sample({
  source: { form: $finalTextDropdownsPoints, points: $maxPoints },
  clock: checkFinalTextFormValidPoints,
  fn: (data): Toast => {
    const { form, points } = data
    let valid = true
    let fullRange = 0
    let message = ''
    form.forEach((range) => {
      if (range.score_min === null || range.score_max === null || !range.text) {
        message = 'Добавьте итоговый текст'
        valid = false
        return
      }
      if (range.score_min! >= range.score_max!) {
        message = 'Диапазон для итогового текста задан неверно'
        valid = false
        return
      }
      fullRange += range.score_max! - range.score_min!
    })
    if (fullRange > points && valid) {
      message = 'Диапазон для итогового текста задан неверно'
      valid = false
    }
    if (fullRange < points && valid) {
      message = 'Итоговый текст указан не для всех возможных результатов'
      valid = false
    }

    toggleFinalTextFormValid(valid)

    return {
      type: 'error',
      message,
    }
  },
  target: condition({
    if: (payload) => payload.message !== '',
    then: addToast,
  }),
})
