import { attach, createEvent, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { dropdownModules } from '@/pages/users/groups/list/model/tooltip-filter.model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { exportFullAppointmentsListFx } from '@/features/api/full-appointments/export-appointments-list'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import fileDownload from 'js-file-download'

export const fullAppointmentsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownModules
)

export const fullAppointmentsPageParams = createPageParamsModel()

export const setFullPassedTo = createEvent<string>()
export const $fullPassedTo = restore(setFullPassedTo, '').reset(reset)

export const setFullPassedFrom = createEvent<string>()
export const $fullPassedFrom = restore(setFullPassedFrom, '').reset(reset)

export const setProctoringActivated = createEvent<boolean>()
export const $proctoringActivated = restore(setProctoringActivated, false).reset(reset)

export const resetFullAppointmentsFilters = createEvent<void>()

export const exportFullAppointmentsList = attach({
  effect: exportFullAppointmentsListFx,
  source: [fullAppointmentsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => ({ ...filters, ...exportedColumns }),
})

exportFullAppointmentsList.doneData.watch((res) =>
  fileDownload(res.body, 'FullAppointmentsList.xlsx')
)
