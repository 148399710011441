




















































import Vue, { PropType } from 'vue'
import Divider from '@/ui/divider/Divider.vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import { modalTasksTypesVisibilityChanged } from '@/pages/common/modals/tasks-bank/tasks-types/tasks-types-modal.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { ActionsItem, OperableTypes } from '@/pages/common/types'

export default Vue.extend({
  name: 'TableHeader',
  components: {
    Divider,
    Icon,
    BaseSwitch,
  },
  props: {
    items: { type: Array as PropType<ActionsItem[]>, default: () => [] },
    total: { type: Number, required: false, default: DEFAULT_ID },
    taskTypes: { type: Boolean, default: false },
    hasTreeView: { type: Boolean, default: false },
    treeView: { type: Boolean, default: false },
    hasShowOnboarding: { type: Boolean, default: false },
    showOnboarding: { type: Boolean, default: false },
    operableType: { type: String as PropType<OperableTypes>, required: true },
  },
  computed: {
    totalVisible() {
      return this.total !== DEFAULT_ID
    },
  },
  methods: {
    modalTasksTypesVisibilityChanged,
    handleAction(itemName: string) {
      this.$emit(`on${itemName.slice(0, 1).toUpperCase() + itemName.slice(1)}`)
    },
  },
})
