import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CommentsOnLessonsListPage from '@/pages/callback/comments-on-lessons/CommentsOnLessonsListPage.vue'
import Subscribers from '@/pages/callback/subscribers/Subscribers.vue'

export default [
  {
    name: 'callback',
    path: '/callback',
    meta: {
      title: 'Обратная связь - Школа Летово',
    },
    redirect: { name: 'comments-on-lessons-list' },
    component: DefaultLayout,
    children: [
      {
        name: 'comments-on-lessons-list',
        path: 'comments-on-lessons-list',
        component: CommentsOnLessonsListPage,
        meta: {
          title: 'Комментарии к урокам - Школа Летово',
        },
      },
      {
        name: 'subscribers-on-email',
        path: 'subscribers-on-email',
        component: Subscribers,
        meta: {
          title: 'Подписчики на рассылку',
        },
      },
    ],
  },
]
