import { attach, createEvent, forward, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { exportAttemptsListFx } from '@/features/api/attempts/export-attempts-list.ts'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { coursesNameDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/courses-name-dropdown-filter/courses-filter-dropdown.model'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/exams-tests-filter/exams-test-filter.model'
import { dropdownAttemptAllModules } from '@/pages/exams/attempts/list/parts/filter/parts/dropdown-modules'
import { attemptsStatusDropdownModule } from '@/pages/common/dropdowns/exams/attempts-status-dropdown/attempts-status-dropdown.model'
import { attemptsStatusPassDropdownModule } from '@/pages/common/dropdowns/multiselectDropdown/attempts-pass-status-dropdown/attempts-status-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import {
  $filterBlock,
  $saveFilters,
} from '@/pages/common/modals/attempts-filter/attempts-filter-modal.model'
import { createTaskModule } from '@/features/api/tasks/tasks-module'

export const attemptsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownAttemptAllModules
)

const exportAttemptsList = attach({
  effect: exportAttemptsListFx,
  source: [
    attemptsFilters.store.$filterParams,
    $exportColumnsQueryParam,
    $filterBlock,
    $saveFilters,
  ],
  mapParams: (_, [filters, exportedColumns, filterBlock, save]) => {
    const formatColumns = {
      fields: exportedColumns.fields.map((field: string) => {
        if (field === 'student_answer') return 'answers_stat'
        return field
      }),
    }
    const resultsParams = {
      result_fs: JSON.stringify(
        filterBlock.map((block) => ({
          subject: block.subject ? block.subject.name : '',
          test: block.test ? block.test.name : '',
          sign: block.sign ? block.sign.name : '',
          mode: block.result_number ? 'r' : 'p',
          value: block.result_number ? block.result_number : block.result?.name || '',
        }))
      ),
    }
    return { ...filters, ...formatColumns, ...(save && resultsParams) }
  },
})

export const attemptsPageParams = createPageParamsModel()

export const resetAttemptsFilters = createEvent<void>()

export const toggleVisibilityModal = createEvent<boolean>()
export const $visibilityModal = restore(toggleVisibilityModal, false).reset(reset)

export const toggleVisibilityFilters = createEvent<boolean>()
export const $visibilityFilters = restore(toggleVisibilityFilters, false)

export const setAttemptsFiltersAvailableTo = createEvent<string>()
export const $attemptsFiltersAvailableTo = restore(setAttemptsFiltersAvailableTo, '').reset(reset)

export const setAttemptsFiltersAvailableFrom = createEvent<string>()
export const $attemptsFiltersAvailableFrom = restore(setAttemptsFiltersAvailableFrom, '').reset(
  reset
)

export const setTestPassedTo = createEvent<string>()
export const $testPassedTo = restore(setTestPassedTo, '').reset(reset)

export const setTestPassedFrom = createEvent<string>()
export const $testPassedFrom = restore(setTestPassedFrom, '').reset(reset)

export const setProctoringActivated = createEvent<boolean>()
export const $proctoringActivated = restore(setProctoringActivated, false).reset(reset)

forward({
  from: reset,
  to: [
    subjectsDropdownModel.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    coursesNameDropdownModel.methods.resetDropdown,
    groupsDropdownModel.methods.resetDropdown,
    examTestsDropdownModel.methods.resetDropdown,
    attemptsStatusDropdownModule.methods.resetDropdown,
    attemptsStatusPassDropdownModule.methods.resetDropdown,
  ],
})

export const taskLoader = createTaskModule(exportAttemptsList, 'attemptsList')
