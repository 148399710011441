





















import Vue from 'vue'
import BannerHeader from '@/pages/content/common/header/Header.vue'
import BannerContent from '@/pages/content/banners/create/parts/Content.vue'
import BannerFooter from '@/pages/content/common/footer/Footer.vue'
import {
  create,
  clearFields,
  redirectAfterSaveChanged,
  $isActive,
  toggleIsActive,
  $disabledSaveButtons,
} from '@/pages/content/banners/create/banner-creation-page.model'
import { navigatePush } from '@/features/navigation/navigationMethods'

export default Vue.extend({
  name: 'BannerCreationPage',
  components: {
    BannerHeader,
    BannerContent,
    BannerFooter,
  },
  effector: {
    $isActive,
    $disabledSaveButtons,
  },
  methods: {
    toggleIsActive,
    redirectAfterSaveChanged,
    clearFields,
    create,
    navigatePush,
    createWithRedirect() {
      redirectAfterSaveChanged(true)
      create()
    },
  },
  beforeDestroy() {
    redirectAfterSaveChanged(false)
    clearFields()
  },
})
