

















































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuItem, ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { loadRequestDeleteModal } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { $permissions } from '@/features/session'
import {
  RefsType,
  HttpOptionsType,
  DisplayContextMenuPayload,
  CommonInteractedItemParams,
} from '@/pages/common/types'
import { navigatePush } from '@/features/navigation/navigationMethods'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { TableHeaderItem } from '@/pages/common/parts/table-header/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import NewsHeader from '@/pages/content/news/list/parts/NewsHeader.vue'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import {
  newsFilters,
  newsPageParams,
} from '@/pages/content/news/list/model/content-news-filters.model'
import { NewsTableFields } from '@/pages/content/news/list/model/constants'
import {
  $isLoading,
  $refreshPage,
  newsPageMounted,
  requestDeleteNews,
} from '@/pages/content/news/list/model/content-news-page.model'
import { axiosClient } from '@/lib/request'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'NewsPage',
  components: {
    Vuetable,
    VuetablePagination,
    NewsHeader,
    TableHeader,
    TooltipCell,
    ContextMenu,
    ActionsButton,
    NoDataContent,
    ConfirmDeleteModal,
    RequestDeleteModal,
    LoaderBig,
  },
  effector: {
    $isLoading,
    $refreshPage,
    $filterParams: newsFilters.store.$filterParams,
    $pageParams: newsPageParams.store.$pageParams,
    $currentPage: newsPageParams.store.currentPage,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      fields: NewsTableFields,
      total: 0,
      selectedRows: [] as number[],
      showDeleteModalType: 'news',
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/content-app/news/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): TableHeaderItem[] {
      const items = []
      if (this.selectedRows.length === 1) {
        items.push(
          { name: 'delete', title: 'Удалить', action: 'onRemoveNews' },
          { name: 'editTask', title: 'Редактировать', action: 'onEditNews' },
          { name: 'removeSelection', title: 'Снять выделение', action: 'onRemoveSelection' }
        )
      }
      if (this.selectedRows.length > 1) {
        items.push(
          { name: 'delete', title: 'Удалить', action: 'onRemoveNews' },
          { name: 'removeSelection', title: 'Снять выделение', action: 'onRemoveSelection' }
        )
      }

      return items
    },
    contextMenuItems(): ContextMenuItem[] {
      return [
        { name: 'delete', title: 'Удалить', action: 'onRemoveNews' },
        { name: 'editTask', title: 'Редактировать', action: 'onEditNews' },
      ]
    },
  },
  watch: {
    $refreshPage: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    changeFilter: newsFilters.methods.changeFilter,
    resetFilters: newsFilters.methods.resetFilters,
    applyFilters: newsFilters.methods.applyFilters,
    changePage: newsPageParams.methods.changePage,
    queryToParams: newsPageParams.methods.queryToParams,
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const request = axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
      return request
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onRemoveNews() {
      const payload = this.selectedIds
      this.showDeleteModalType = 'news'
      this.$permissions?.users_employee_admin.can_edit
        ? loadConfirmDeleteModal(payload)
        : loadRequestDeleteModal(payload)
    },
    onEditNews() {
      const payload = this.selectedIds[0]
      navigatePush({
        name: 'edit-news',
        params: { id: `${payload}` },
      })
    },
    async handleConfirmDelete(ids: number[], type: string) {
      if (type === 'news') {
        await requestDeleteNews({ news: ids })
        this.$refs.vuetable.reload()
        this.removeSelection()
      }
    },
    async sendRequestDeleteLesson(comment: string, ids: number[]) {
      await requestDeleteNews({ news: ids, ticket_comment: comment })
      this.$refs.vuetable.reload()
      this.removeSelection()
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    headerActionsButtonClick(event: MouseEvent) {
      this.handleActionsClick({
        data: { id: this.selectedIds[0] },
        event,
        type: 'header-actions',
      })
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: { id: number }) {
      this.interactedItemId = data.id
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
  },
  mounted() {
    newsPageMounted()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
