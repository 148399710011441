
















import Vue from 'vue'
import CreateInstructionsFooter from '@/pages/dictionary/instructions/create/parts/CreateInstructionsFooter.vue'
import CreateInstructionsHeader from '@/pages/dictionary/instructions/create/parts/CreateInstructionsHeader.vue'
import CreateInstructionsContent from '@/pages/dictionary/instructions/create/parts/CreateInstructionsContent.vue'
import { changeInstructionsId } from '@/pages/dictionary/instructions/create/model/edit-instructions.model'
import {
  $disabledSaveButtons,
  resetFields,
} from '@/pages/dictionary/instructions/create/model/form-fileds.model'
import {
  getPageParams,
  setRedirectAfterSave,
} from '@/pages/dictionary/instructions/create/model/create-instructions.model'

export default Vue.extend({
  name: 'CreateInstructionsPage',
  components: {
    CreateInstructionsHeader,
    CreateInstructionsFooter,
    CreateInstructionsContent,
  },
  effector: {
    $disabledSaveButtons,
  },
  methods: {
    saveGroup(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      getPageParams()
    },
  },
  created() {
    changeInstructionsId(this.$route.params.id)
  },
  beforeDestroy() {
    resetFields()
  },
})
