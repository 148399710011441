














































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { examTestsEditFolderModal } from '@/pages/common/modals/tasks-bank/editing-folder/editing-folder-modal.model'
import ExamTestsFolders from '@/pages/common/dropdowns/exams/exams-tests-folders/ExamTestsFolders.vue'

export default Vue.extend({
  name: 'EditingExamTestFolderModal',
  components: {
    Modal,
    Icon,
    FormInput,
    BaseButton,
    ExamTestsFolders,
  },
  effector: {
    $modalVisibility: examTestsEditFolderModal.store.$modalVisibility,
    titleError: examTestsEditFolderModal.store.$titleErrorModule.store.$error,
    $folderTitle: examTestsEditFolderModal.store.$folderTitle,
  },
  methods: {
    modalVisibilityChanged: examTestsEditFolderModal.methods.modalVisibilityChanged,
    checkIfFolderCanBeSend: examTestsEditFolderModal.methods.checkIfFolderCanBeSend,
    folderTitleChanged: examTestsEditFolderModal.methods.folderTitleChanged,
  },
})
