
















































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import PageHeader from '@/pages/dictionary/resources/list/parts/PageHeader.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ResourcesFilter from '@/pages/dictionary/resources/list/parts/resources-filter/ResourcesFilter.vue'
import ResourcesTree from '@/pages/dictionary/resources/list/parts/tree/ResourcesTree.vue'
import {
  loadTree,
  loadTreeLight,
  $resourcesTreeTotal,
  deleteResources,
  $isLoading,
} from '@/pages/dictionary/resources/list/resources-page.model'
import {
  toggleVisibility,
  $visibility,
  resourcesFilters,
} from '@/pages/dictionary/resources/list/parts/resources-filter/resources-filter.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { getResourcesActions, searchFieldsData } from '@/pages/dictionary/resources/list/constants'
import { ActionsItem, DisplayContextMenuPayload, RefsType } from '@/pages/common/types'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import {
  ResourcesInteractedItemData,
  ResourcesInteractedItemParams,
} from '@/pages/dictionary/resources/list/types'
import { DEFAULT_ID } from '@/pages/common/constants'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import { clearTreeStates } from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $permissions } from '@/features/session'
import { getActionsDisplayConditions } from '@/pages/common'

Vue.use(VueEvents)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'ResourcesPage',
  components: {
    TableHeader,
    PageHeader,
    GeneralFilter,
    ResourcesFilter,
    ContextMenu,
    ResourcesTree,
    ConfirmDeleteModal,
    LoaderBig,
  },
  effector: {
    $visibility,
    $resourcesTreeTotal,
    $isLoading,
    filterParams: resourcesFilters.store.$filterParams,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID as number,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      searchFields: searchFieldsData,
      total: 0,
      theme: DEFAULT_ID as number,
    }
  },
  computed: {
    selectedIds(): number[] {
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
  },
  methods: {
    changeFilter: resourcesFilters.methods.changeFilter,
    resetFilters: resourcesFilters.methods.resetFilters,
    applyFilters: resourcesFilters.methods.applyFilters,
    loadTree,
    toggleVisibility,
    onFilterSet() {
      this.applyFilters()
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      clearTreeStates()
    },
    onRemoveResource() {
      loadConfirmDeleteModal(this.selectedIds)
    },
    removeResource() {
      deleteResources(this.selectedIds)
    },
    onCreateResource() {
      navigatePush({
        name: 'resources-create',
        params: { id: `${this.theme}` },
      })
    },
    onEditResource() {
      navigatePush({ name: 'resources-edit', params: { id: `${this.interactedItemId}` } })
    },
    handleActionsClick({ data, event, type }: ResourcesInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type = 'item' }: ResourcesInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: ResourcesInteractedItemData) {
      this.theme = data.theme
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(this.contextMenuType, 0, true)
      this.contextMenuItems = getResourcesActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!/create/g.test(from.path) && !/edit/g.test(from.path) && !/preview/g.test(from.path)) {
      clearTreeStates()
    }
    next()
  },
  mounted() {
    loadTreeLight()
  },
  destroyed() {
    this.resetFilters()
  },
})
