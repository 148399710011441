






import Vue from 'vue'

export default Vue.extend({
  name: 'ShortClosedAnswer',
  props: {
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    correctAnswer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    answerData() {
      if (this.answerType === 'student') {
        return this.answer
      }
      if (this.answerType === 'correct') {
        return this.correctAnswer.join(' ; ')
      }
      return this.answerType === 'student' ? this.answer : this.correctAnswer
    },
  },
})
