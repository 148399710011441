import { createEvent, forward, attach, restore, createStore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { getFilterCoursesFx } from '@/features/api/learning/courses/get-courses-name'

export const coursesDropdownModel = createFilter()

export const setSelectedCourse = createEvent<DropdownItem | null>()
export const $selectedCourse = restore(setSelectedCourse, null)

const getCourses = attach({
  effect: getFilterCoursesFx,
})

export const loadCourses = createEvent<void>()
export const $courses = createStore<DropdownItem[]>([])

forward({
  from: loadCourses,
  to: getCourses.prepend(() => ({})),
})

forward({
  from: getCourses.doneData.map((res) =>
    res.body.map((course) => ({ name: `${course.id}`, title: `(${course.id}) ${course.name}` }))
  ),
  to: $courses,
})

forward({
  from: coursesDropdownModel.methods.resetDropdown,
  to: setSelectedCourse.prepend(() => null),
})
