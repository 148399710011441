import dayjs from 'dayjs'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { EmployeePermissions } from '@/features/api/employee/types'
import { ActionsDisplayConditions } from '@/pages/common'

export const bannersTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'id',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '100px',
  },
  {
    name: 'text',
    sortField: 'text',
    title: 'Текст баннера',
    width: '200px',
  },
  {
    name: 'active',
    sortField: 'active',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Включен',
    formatter: (data) => {
      return data ? 'Да' : 'Нет'
    },
    width: '130px',
  },
  {
    name: 'banner_type',
    sortField: 'banner_type',
    title: 'Тип',
    width: '250px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter: (data) => {
      return data === 'timer' ? 'Баннер с обратным отсчётом' : 'Баннер без обратного отсчёта'
    },
  },
  {
    name: 'timer_target',
    sortField: 'timer_target',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
    title: 'Время-цель обратного отсчета ',
    width: '260px',
  },
  {
    name: 'name',
    sortField: 'name',
    title: 'Название CMS',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '220px',
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создано',
    width: '200px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'updated_at',
    sortField: 'updated_at',
    title: 'Обновлено',
    width: '130px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

// todo: Добавить обработку поведения для пререквизитов
/* https://paper.dropbox.com/doc/2021.02.03-CMS-.-2--BNqnuEpl~7pbk6S62vOQUcpSAg-YxtbuGcqW1Qgem78oxMfL#:h2=3.1.1.4.-%D0%94%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D0%B8%D1%8F-%D1%81-%D1%82%D0%B5%D0%BC%D0%B0%D0%BC%D0%B8 */
export const getBannersActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.content_banner_banner.can_edit,
    },
    {
      name: 'delete',
      title: displayOptions.isTableItems ? 'Удалить выделенные баннеры' : 'Удалить',
      headerTitle: 'Удалить',
      action: 'onDelete',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}

export const searchFieldsData = [
  { name: 'all', title: 'Искать везде' },
  { name: 'name', title: 'Внутреннее название' },
]
