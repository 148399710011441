import { TaskType } from '@/pages/common/modals/tasks-bank/tasks-types/types'

export const tasksTypesData: TaskType[] = [
  {
    icon: 'type-0',
    text: 'Задание с вводом короткого закрытого ответа',
  },
  {
    icon: 'type-1',
    text: 'Задание с несколькими вопросами и вводом коротких закрытых ответов',
  },
  {
    icon: 'type-2',
    text: 'Задание с выбором одного или нескольких правильных ответов',
  },
  {
    icon: 'type-3',
    text: 'Задание с выбором одного правильного ответа',
  },
  {
    icon: 'type-4',
    text: 'Задание с вводом развернутого открытого ответа',
  },
  {
    icon: 'type-5',
    text: 'Задание с одним общим выпадающим списком в строке',
  },
  {
    icon: 'type-6',
    text: 'Задание на сопоставление с перемещением частей',
  },
  {
    icon: 'type-6',
    text: 'Задание на сопоставление частей линией',
  },
  {
    icon: 'type-7',
    text: 'Задание с перемещением картинок и вводом данных в поле на картинке',
  },
  {
    icon: 'type-7',
    text: 'Задание с перемещением картинок и вводом данных в поле в тексте',
  },
  {
    icon: 'type-5',
    text: 'Задание с общим выпадающим списком в тексте',
  },
  {
    icon: 'type-5',
    text: 'Задание с различными выпадающими списками в тексте',
  },
  {
    icon: 'type-8',
    text: 'Задание с вводом развернутого открытого ответа и/или прикреп-м файла',
  },
  {
    icon: 'type-9',
    text: 'Задание с цветовым выделенем в тексте',
  },
]
