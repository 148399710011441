




















import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { saveHobby } from '@/pages/settings/hobbies/model/hobbies-page.model'

export default Vue.extend({
  name: 'HobbiesHeader',
  components: {
    BaseButton,
  },
  props: {
    title: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean>, default: true },
    fromPage: { type: String as PropType<string> },
  },
  methods: {
    saveHobby,
  },
})
