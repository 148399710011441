import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { $isAuthed, $routesPermissions, logout, $currentUser } from '@/features/session'

Vue.use(VueRouter)
Vue.use(VueMeta)

export const router = new VueRouter({
  mode: 'history',
})

type MetaItem = {
  content: string
}

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  const nameDataAtr = 'data-vue-router'
  // set title if it exist
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title || 'CMS Letovo Online'
  // remove prev page meta tags
  const oldMetaTags: HTMLElement[] = Array.from(document.querySelectorAll(`[${nameDataAtr}]`))
  if (oldMetaTags.length) oldMetaTags.map((el: any) => el.parentNode.removeChild(el))

  if (nearestWithMeta) {
    // set arr meta tags
    const metaTags: HTMLMetaElement[] = nearestWithMeta.meta.metaTags.map((tagDef: MetaItem) => {
      const tag = document.createElement('meta')
      Object.keys(tagDef).forEach((key) => tag.setAttribute(key, tagDef[key]))
      tag.setAttribute(nameDataAtr, '')
      return tag
    })
    // set to page our meta tags
    metaTags.forEach((tag: HTMLMetaElement) => document.head.appendChild(tag))
  }
  function checkPermission(): boolean {
    const user = $currentUser.getState()
    let havePermission

    // для возможности методисту без прав редактирования и просмотра
    // редактировать (убрано 25.07.2023 | MaxKomov) и просматривать задания которые он видит во входящих заявках
    if (
      user &&
      user.role === 'METHODIST' &&
      (from?.name === 'incoming-list' || from?.name === 'preview-task') &&
      to?.name === 'preview-task'
    ) {
      return true
    }
    if (to.name === 'preview-task') {
      havePermission = $routesPermissions.getState()[`${to.name}-${to.query.taskType}`]
    } else {
      havePermission =
        to.name && $routesPermissions.getState() && $routesPermissions.getState()[to.name]
    }
    return havePermission
  }

  const permission = checkPermission()
  if (to.name === 'auth.login' && !permission && $isAuthed.getState()) {
    logout()
  } else if (!permission) {
    next(false)
  } else {
    next()
  }
})
