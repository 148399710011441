import {
  createEvent,
  combine,
  guard,
  forward,
  restore,
  sample,
  split,
  createStore,
} from 'effector-root'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { createExamTestFx } from '@/features/api/full-time-tests/create-exam-test'
import { editExamTestFx } from '@/features/api/full-time-tests/edit-exam-test'
import { getExamTestByIdFx } from '@/features/api/full-time-tests/get-exam-test-by-id'
import { condition } from 'patronum'
import {
  $step1Fields,
  $step2FieldIntroTab,
  $step2FieldsLoadTab,
  $step2FieldsMainTab,
  $loadPartText,
  resetFields,
} from '@/pages/exams/full-time-exam-test/create/model/form-fields.model'
import { ExamTestType } from '@/features/api/full-time-tests/types'

export const $fieldErrors = createStore<{ [key: string]: string }>({}).reset(resetFields)

export const changeExamTestID = createEvent<string | never>()
export const $editedExamTestID = createStore<string>('')
  .on(changeExamTestID, (state, payload) => payload || state)
  .reset(resetFields)

guard({
  source: $editedExamTestID,
  filter: (payload) => !!payload,
  target: getExamTestByIdFx,
})

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  createExamTestFx.fail,
  editExamTestFx.fail,
])

const $form = combine(
  $step1Fields,
  $step2FieldIntroTab,
  $step2FieldsLoadTab,
  $step2FieldsMainTab,
  $loadPartText,
  (step1, step2Intro, step2Load, step2Main, load_part_text) => {
    return { ...step1, ...step2Intro, ...step2Load, ...step2Main, load_part_text }
  }
)

export const getPageParams = createEvent()

const createExamTest = createEvent()

const updateExamTest = createEvent<string>()

const examTestId = sample({
  source: $editedExamTestID,
  clock: getPageParams,
  fn: (id) => (id.length > 0 ? id : false),
})

split({
  source: examTestId,
  match: {
    post: (payload) => payload === false,
    put: (payload) => payload !== false,
  },
  cases: {
    post: createExamTest,
    put: updateExamTest,
  },
})

sample({
  source: $form,
  clock: createExamTest,
  fn: (form) => form,
  target: createExamTestFx,
})

sample({
  source: $form,
  clock: updateExamTest,
  fn: (form, id: string) => ({ body: form, id }),
  target: editExamTestFx,
})

const renameField = (field: keyof ExamTestType) => {
  switch (field) {
    case 'name':
      return 'Имя экзаменационного теста'
    case 'tasks_choice':
      return 'Переход между заданиями'
    case 'folder_id':
      return 'Папка'
    case 'study_year_id':
      return 'Класс'
    case 'subject_id':
      return 'Предмет'
    case 'intro_unit':
      return 'Вводная часть'
    case 'intro_dur_min':
      return 'Продолжительность вводной часть'
    case 'main_unit':
      return 'Основная часть'
    case 'load_unit':
      return 'Загрузка файлов'
    default:
      return 'Неизвестное поле'
  }
}

const formattingErrorMessage = (error: any) => {
  try {
    const formattedData = {}
    Object.keys(error).forEach((el) => {
      // @ts-ignore
      formattedData[el] = `${renameField(el)}: ${error[el]}`
    })
    return formattedData
  } catch (e) {
    return error
  }
}

sample({
  clock: [
    createExamTestFx.failData.map((res) => res.body),
    editExamTestFx.failData.map((res) => res.body),
  ],
  fn: (data: any) => {
    return formattingErrorMessage(data)
  },
  target: $fieldErrors,
})

$fieldErrors.watch((fields) => {
  if (Object.keys(fields).length > 0) {
    Object.values(fields).forEach((message) =>
      addToast({
        type: 'error',
        duration: 6000,
        message,
      })
    )
  }
})

forward({
  from: createExamTestFx.done,
  to: successToastEvent('Экзаменационный тест создан'),
})

forward({
  from: editExamTestFx.done,
  to: successToastEvent('Экзаменационный тест отредактирован'),
})

const $redirectHandler = sample({
  clock: [
    createExamTestFx.doneData.map((res) => res.body.id),
    editExamTestFx.doneData.map((res) => res.body.id),
  ],
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'full-exam-tests-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => {
    changeExamTestID(String(payload.id))
    return {
      name: 'full-exam-test-edit',
      params: { id: `${payload.id}` },
    }
  }),
})
