

















import Vue from 'vue'
import TaskHeader from '@/pages/testing/tests/common/parts/Header.vue'
import TestContent from '@/pages/testing/tests/edit/parts/Content.vue'
import TestFooter from '@/pages/testing/tests/common/parts/Footer.vue'

import {
  $canSave,
  setRedirectAfterSave,
  clearFields,
  save,
  $isLoadingEditTests,
  loadTest,
} from '@/pages/testing/tests/edit/test-edit-page.model'
import { clearFields as clearFieldsCreate } from '@/pages/testing/tests/create/test-create-page.model'
import { toggleIsPageLoaded } from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'

export default Vue.extend({
  name: 'TestEditPage',
  components: {
    TaskHeader,
    TestContent,
    TestFooter,
  },
  effector: {
    $canSave,
    $isLoadingEditTests,
  },
  methods: {
    saveTask(isRedirect: boolean) {
      save()
      if (isRedirect) setRedirectAfterSave(true)
    },
  },
  mounted() {
    toggleIsPageLoaded(true)
    loadTest(+this.$route.params.id)
  },
  beforeDestroy() {
    toggleIsPageLoaded(false)
    clearFields()
    clearFieldsCreate()
  },
})
