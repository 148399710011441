import { DropdownItem } from '@/pages/common/types'
import { attach, createEvent, forward, guard, restore, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { debounce } from 'patronum'
import { getTestsAssignmentListFx } from '@/features/api/test/get-tests-assignment-list'
import { TestAssignment } from '@/features/api/assignment/types/test-assignments-types'
import { $selectedThemes } from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'
import { TASK_TYPES_DATA } from '@/pages/bank/common/constants'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'

export const getTestsAssignment = attach({
  effect: getTestsAssignmentListFx,
  mapParams: (params: GetListQueryParams) => {
    return { ...params, status: 'published' }
  },
})

export const testsAssignmentDropdownModel =
  createDropdownMultiselectModel<TestAssignment>(getTestsAssignment)

export const setNewSelectedTestsAssignment = createEvent<void>()

export const scrollAssignment = createEvent<void>()

export const setSelectedTestsAssignmentId = createEvent<number[]>()
export const $selectedTestsAssignmentID = restore<number[]>(setSelectedTestsAssignmentId, []).reset(
  testsAssignmentDropdownModel.methods.resetSelectedItems
)

export const searchAssignment = createEvent<void>()

export const loadTestsAssignment = createEvent<void>()

export const taskDifficultyLevel = (difficulty: number) => {
  switch (difficulty) {
    case 0:
      return '[Базовый уровень]'
    case 1:
      return '[Продвинутый уровень]'
    case 2:
      return '[Экзамен]'
    default:
      return ''
  }
}

sample({
  clock: $selectedThemes,
  fn: (themes): GetListQueryParams => {
    return {
      page: 1,
      ...(themes.length > 0 && { theme: themes[0].id }),
    }
  },
  target: [testsAssignmentDropdownModel.methods.resetDropdown, getTestsAssignment],
})

sample({
  clock: loadTestsAssignment,
  source: {
    $nextPage: testsAssignmentDropdownModel.store.$nextPage,
    search: testsAssignmentDropdownModel.store.$searchString,
    theme: $selectedThemes,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search }),
    ...(params.theme.length > 0 && { theme: params.theme[0].id }),
  }),
  target: getTestsAssignment,
})

sample({
  clock: searchAssignment,
  source: {
    search: testsAssignmentDropdownModel.store.$searchString,
    theme: $selectedThemes,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search && { search: params.search }),
    ...(params.theme.length > 0 && { theme: params.theme[0].id }),
  }),
  target: [testsAssignmentDropdownModel.methods.setItems.prepend(() => []), getTestsAssignment],
})

const debounced = debounce({
  source: testsAssignmentDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchAssignment,
})

guard({
  source: testsAssignmentDropdownModel.store.$loading,
  clock: testsAssignmentDropdownModel.methods.canLoadNextPage,
  filter: (loading: boolean) => !loading,
  target: loadTestsAssignment,
})

forward({
  from: getTestsAssignment.doneData.map((res) =>
    res.body.data.map((assignment) => {
      return {
        name: `${assignment.id}`,
        title: `${taskDifficultyLevel(assignment.difficulty)} (${assignment.id}) ${
          TASK_TYPES_DATA.find((el) => el.name === assignment.type)!.title
        }`,
        type: assignment.type,
        score: assignment.score,
        difficulty: assignment.difficulty,
        id: assignment.id,
        has_internal_scores: assignment.has_internal_scores,
      }
    })
  ),
  to: testsAssignmentDropdownModel.methods.setItems,
})

guard({
  source: $selectedTestsAssignmentID,
  clock: testsAssignmentDropdownModel.store.$items,
  filter: (ids: number[]) => ids && ids.length > 0,
  target: setNewSelectedTestsAssignment,
})

sample({
  source: { ids: $selectedTestsAssignmentID, list: testsAssignmentDropdownModel.store.$items },
  clock: setNewSelectedTestsAssignment,
  fn: (data: { ids: number[]; list: DropdownItem[] }) => {
    const { ids, list } = data
    if (ids && ids.length > 0) {
      return list.filter((el: DropdownItem) => ids.includes(Number(el.name)))
    }
    return []
  },
  target: [
    testsAssignmentDropdownModel.methods.setSelectedItems,
    setSelectedTestsAssignmentId.prepend(() => []),
  ],
})
