<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="proctors-filter"
  >
    <div class="row">
      <SubjectFilterDropdown
        class="half-third"
        @setItem="val => setItem({'subjects': val})"
      />
      <ClassesFilterDropdown
        class="half-third"
        @setItem="val => setItem({'classes': val})"
      />
      <ExamsTestsFilterDropdown
        class="half-third"
        :is-reset-destroy="false"
        @setItem="setTests"
      />
    </div>
    <div class="row">
      <GroupsFilterDropdown
        class="half-third"
        @setItem="setGroups"
      />
      <StudentsExamsAnswerFilter
        class="half-third"
        @setItem="setStudent"
      />
      <AttemptsFilter
        class="half-third"
        @setItem="setAttempts"
      />
    </div>
    <div class="row">
      <div class="half-third">
        <div class="label">Дата выдачи вопроса с</div>
        <DatePicker
          :value="$questionStartedAt"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setStartedAt"
        />
      </div>
      <div class="half-third">
        <div class="label">Дата выдачи вопроса до</div>
        <DatePicker
          :value="$questionFinishedAt"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setFinishedAt"
        />
      </div>
      <div class="half-third" />
    </div>
    <div class="row">
      <div class="half-third switch-wrap">
        <BaseSwitch
          class="switch"
          :checked="$showCorrect"
          @change="(val) => setCorrect(val, true)"
        >
          <p>Отобразить верные ответы</p>
        </BaseSwitch>
      </div>
      <div class="half-third switch-wrap">
        <BaseSwitch
          class="switch"
          :checked="$showYesAnswer"
          @change="(val) => setShowAnswer(val, true)"
        >
          <p>Отобразить результаты, если дан ответ</p>
        </BaseSwitch>
      </div>
      <div class="half-third" />
    </div>

    <div class="row">
      <div class="half-third switch-wrap">
        <BaseSwitch
          class="switch"
          :checked="$showNotCorrect"
          @change="(val) => setCorrect(val, false)"
        >
          <p>Отобразить неверные ответы</p>
        </BaseSwitch>
      </div>
      <div class="half-third switch-wrap">
        <BaseSwitch
          class="switch"
          :checked="$showNotAnswer"
          @change="(val) => setShowAnswer(val, false)"
        >
          <p>Отобразить результаты, если ответа нет</p>
        </BaseSwitch>
      </div>
      <div class="half-third">
        <div class="buttons">
          <div class="btn">
            <BaseButton
              small
              @click="applyFilters"
            >
              Применить
            </BaseButton>
          </div>
          <div class="btn">
            <BaseButton
              class="borderless"
              small
              border-without-bg
              @click="resetFilters"
            >
              Сбросить фильтры
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <Icon
      type="close"
      class="close-icon"
      size="10"
      @click="toggleVisibility(false)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import DatePicker from 'vue2-datepicker'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import { dropdownExamsAnswerComponents } from '@/pages/exams/part-time-answers/list/model/dropdown-components'
import { toggleVisibility } from '@/pages/exams/part-time-answers/list/model/tooltip-filter.model'
import {
  $questionFinishedAt,
  $questionStartedAt,
  $showCorrect,
  $showNotAnswer,
  $showNotCorrect,
  $showYesAnswer,
  setQuestionFinishedAt,
  setQuestionStartedAt,
  setShowCorrect,
  setShowNotAnswer,
  setShowNotCorrect,
  setShowYesAnswer,
} from '@/pages/exams/part-time-answers/list/model/exams-answers-filters.model'
import isCloseCalendar from '@/lib/utils/no_close_calendar'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ExamsAnswerFilter',
  components: {
    Icon,
    BaseButton,
    DatePicker,
    BaseSwitch,
    SubjectFilterDropdown: dropdownExamsAnswerComponents.SubjectsDropdown,
    ClassesFilterDropdown: dropdownExamsAnswerComponents.ClassesDropdown,
    ExamsTestsFilterDropdown: dropdownExamsAnswerComponents.ExamsTestsFilterDropdown,
    GroupsFilterDropdown: dropdownExamsAnswerComponents.GroupsFilterDropdown,
    StudentsExamsAnswerFilter: dropdownExamsAnswerComponents.StudentsExamsAnswerFilter,
    AttemptsFilter: dropdownExamsAnswerComponents.AttemptsFilter,
  },
  effector: {
    $questionFinishedAt,
    $questionStartedAt,
    $showCorrect,
    $showNotCorrect,
    $showYesAnswer,
    $showNotAnswer,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  methods: {
    setQuestionStartedAt,
    setQuestionFinishedAt,
    toggleVisibility,
    setShowCorrect,
    setShowNotCorrect,
    setShowYesAnswer,
    setShowNotAnswer,
    closeFilter(event) {
      // check for general filter icon
      if (isCloseCalendar(event)) {
        toggleVisibility(false)
      }
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    removeItem(filter) {
      this.$emit('removeFilter', filter)
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
    },
    setTests(val) {
      if (val) {
        if (typeof val === 'string') {
          this.setItem({ tests: val })
        } else if (Array.isArray(val)) {
          this.setItem({ tests: val.join(',') })
        }
      } else {
        this.setItem({ tests: undefined })
      }
    },
    setStudent(val) {
      if (val) {
        this.setItem({ students: val.name })
      } else {
        this.setItem({ students: undefined })
      }
    },
    setAttempts(val) {
      if (val) {
        this.setItem({ attempts: val.name })
      } else {
        this.setItem({ attempts: undefined })
      }
    },
    setGroups(val) {
      if (val) {
        if (typeof val === 'string') {
          this.setItem({ groups: val })
        } else if (Array.isArray(val)) {
          this.setItem({ groups: val.join(',') })
        }
      } else {
        this.setItem({ groups: undefined })
      }
    },
    setStartedAt(value) {
      this.setQuestionStartedAt(value)
      this.setItem({ period_from: value })
    },
    setFinishedAt(value) {
      this.setQuestionFinishedAt(value)
      this.setItem({ period_to: value })
    },
    setCorrect(value, isCorrect) {
      if (isCorrect) {
        this.setShowCorrect(value)
        if (value) {
          this.setItem({ is_correct: true })
        }
        if (this.$showNotCorrect && value) {
          this.setShowNotCorrect(false)
        }
      }
      if (!isCorrect) {
        this.setShowNotCorrect(value)
        if (value) {
          this.setItem({ is_correct: false })
        }
        if (this.$showCorrect && value) {
          this.setShowCorrect(false)
        }
      }
      if (!this.$showCorrect && !this.$showNotCorrect) {
        this.removeItem('is_correct')
      }
    },
    setShowAnswer(value, isAnswer) {
      if (isAnswer) {
        this.setShowYesAnswer(value)
        if (value) {
          this.setItem({ show_a: true })
        }
        if (this.$showNotAnswer && value) {
          this.setShowNotAnswer(false)
        }
      }
      if (!isAnswer) {
        this.setShowNotAnswer(value)
        if (value) {
          this.setItem({ show_a: false })
        }
        if (this.$showYesAnswer && value) {
          this.setShowYesAnswer(false)
        }
      }
      if (!this.$showYesAnswer && !this.$showNotAnswer) {
        this.removeItem('show_a')
      }
    },
  },
})
</script>

<style scoped>
.proctors-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.btn {
  width: max-content;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}
.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}
.date-picker {
  width: 100%;
}
.date-picker /deep/ .mx-input {
  height: 46px;
}
.label {
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.switch-wrap {
  display: flex;
  align-items: center;
}
.switch {
  margin-top: 17px;
}
.buttons {
  display: flex;
  align-items: center;

  .btn:first-child {
    margin-right: 38px;
  }
}
</style>
