import { render, staticRenderFns } from "./FullAppointmentsPage3.vue?vue&type=template&id=e0bda0d0&scoped=true&"
import script from "./FullAppointmentsPage3.vue?vue&type=script&lang=ts&"
export * from "./FullAppointmentsPage3.vue?vue&type=script&lang=ts&"
import style0 from "./step3.css?vue&type=style&index=0&id=e0bda0d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0bda0d0",
  null
  
)

export default component.exports