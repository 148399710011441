import { createEvent, restore } from 'effector-root'
import { coursesDropdownModel } from '@/pages/users/students/list/parts/courses-dropdown/courses-dropdown.model'
import { classesDropdownModel } from '@/pages/users/students/list/parts/classes-dropdown/classes-dropdown.model'
import { groupsDropdownModel } from '@/pages/users/students/list/parts/groups-dropdown/groups-dropdown.model'
import { statusesDropdownModel } from '@/pages/users/students/list/parts/statuses-dropdown/statuses-dropdown.model'
import { registrationsDropdownModel } from '@/pages/users/students/list/parts/registration-dropdown/registration-dropdown.model'

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false)

export const dropdownModules = {
  classesDropdownModel,
  statusesDropdownModel,
  registrationsDropdownModel,
  groupsDropdownModel,
  coursesDropdownModel,
}
