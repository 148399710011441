import { attach, createEvent } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { dropdownModules } from '@/pages/users/groups/list/model/tooltip-filter.model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'
import { exportGroupsListFx } from '@/features/api/groups/export-groups-list'

export const userGroupsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownModules
)

export const userGroupsPageParams = createPageParamsModel()

export const resetUserGroupFilters = createEvent<void>()

export const exportUserGroupsList = attach({
  effect: exportGroupsListFx,
  source: [userGroupsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => ({ ...filters, ...exportedColumns }),
})

exportGroupsListFx.doneData.watch((res) => fileDownload(res.body, 'groupsList.xlsx'))
