import { forward, attach, sample, createEvent, restore } from 'effector-root'
import { createSubjectFx } from '@/features/api/subject/create-subject'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import {
  $colorIdErrorModule,
  $formToSend,
  $subjectTitleErrorModule,
} from '@/pages/dictionary/subjects/common/create-edit.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { condition } from 'patronum'

const saveSubjectFx = attach({
  effect: createSubjectFx,
})

export const save = createEvent<void>()
const saveSubject = createEvent<void>()
const checkIfSubjectCanBeSend = createEvent<void>()

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

// дергаем отправку формы
forward({
  from: save,
  to: checkIfSubjectCanBeSend.prepend(() => ({})),
})
sample({
  source: $formToSend,
  clock: checkIfSubjectCanBeSend,
  fn: (obj) => {
    if (obj.name && obj.name.trim().length < 31 && obj.color && obj.color.length) {
      saveSubject()
    } else {
      if (obj.color && !obj.color.length) $colorIdErrorModule.methods.setError(true)
      if (obj.name.trim().length > 30) {
        $subjectTitleErrorModule.methods.setError(true)
        errorToastEvent('Название предмета содержит более 30 символов')
      }
      if (!obj.name.trim().length) $subjectTitleErrorModule.methods.setError(true)
      errorToastEvent('Необходимо заполнить все обязательные поля')
    }
  },
})
sample({
  clock: saveSubject,
  source: $formToSend,
  target: saveSubjectFx,
})

const $ifRedirect = sample({
  clock: saveSubjectFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

forward({
  from: saveSubjectFx.doneData,
  to: successToastEvent('Предмет успешно создан!'),
})
condition({
  source: $ifRedirect,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'subjects-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'subjects-edit',
    params: { id: `${payload.id}` },
  })),
})
