import { combine, createEvent, forward, restore } from 'effector-root'
import { spread } from 'patronum'
import { getFullAppointmentIdFx } from '@/features/api/full-appointments/get-appointment-dy-id'
import { proctorsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/proctors-filter-dropdown.model'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/exams/exams-full-tests/exams-full-test-list-dropdown.model'
import { Test } from '@/features/api/test/types'
import {
  AttemptStudentAppointmentsData,
  StudentAppointmentsDataMinimal,
} from '@/pages/exams/full-appointments/create/model/types'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { Groups } from '@/features/api/learning/types'
import { attach, createEffect, sample } from 'effector'
import { addToast } from '@/features/toasts/toasts.model'
import { File } from '@/pages/common/parts/tasks/types'
import { attemptsCountDropdownModel } from '@/pages/exams/full-appointments/create/parts/AttemptsCount/attempts-count-dropdown.model'
import { DropdownItem } from '@/pages/common/types'
import { uploadProctorsFx } from '@/features/api/full-appointments/upload-proctors'
import { UploadProctorResponse } from '@/features/api/full-appointments/types'

export const uploadData = attach({
  effect: uploadProctorsFx,
})

const uploadFilesFx = createEffect({
  handler: (files: FileList | null): Promise<UploadProctorResponse[]> => {
    return Promise.all(
      Array.from(files || []).map(
        (file) =>
          new Promise<UploadProctorResponse>((resolve) => {
            const formData = new FormData()
            formData.append('file', file)
            const res = uploadData(formData).then((r) => r.body)
            resolve(res)
          })
      )
    )
  },
})

export const resetFields = createEvent()
export const resetFiltersFields = createEvent()

export const setNameCmsCommentary = createEvent<string>()
export const $nameCmsCommentary = restore(setNameCmsCommentary, '').reset(resetFields)

export const setAppointmentsCmsCommentary = createEvent<string>()
export const $appointmentsCmsCommentary = restore(setAppointmentsCmsCommentary, '').reset(
  resetFields
)

export const uploadFiles = createEvent<FileList>()
export const setFiles = createEvent<File[] | null>()
export const $files = restore(setFiles, []).reset(resetFields)
export const $tempFiles = restore<FileList>(uploadFiles, null).reset(resetFields)
export const setIsLoadFile = createEvent<null | number>()
export const $isLoadFile = restore(setIsLoadFile, null).reset(resetFields)
export const setImportFileId = createEvent<null | number>()
export const $importFileId = restore(setImportFileId, null).reset(resetFields)

export const setAppointmentsIsProctoringActivated = createEvent<boolean>()
export const $appointmentsIsProctoringActivated = restore(
  setAppointmentsIsProctoringActivated,
  true
).reset(resetFields)

export const setAppointmentsAvailableFrom = createEvent<string>()
export const $appointmentsAvailableFrom = restore(setAppointmentsAvailableFrom, '').reset(
  resetFields
)

export const setAppointmentsAvailableTo = createEvent<string>()
export const $appointmentsAvailableTo = restore(setAppointmentsAvailableTo, '').reset(resetFields)

// step3
type StudentsForForAppointments = {
  [key: number]: StudentAppointmentsDataMinimal
}

type addStudentsErrorsAppointments = {
  proctors: string
  students: string
}

export const setStudentsErrorsAppointments = createEvent<addStudentsErrorsAppointments>()
export const $studentsErrorsAppointments = restore(setStudentsErrorsAppointments, {
  proctors: '',
  students: '',
}).reset(resetFields)

export const $isAddErrors = $studentsErrorsAppointments.map((data) => {
  return !!data?.proctors || !!data?.students
})

export type StudentsProctors = {
  [key: number]: {
    proctors: DropdownItem[]
    active_proctor: boolean
  }
}

export const setStudentsProctors = createEvent<StudentsProctors>()
export const $studentsProctors = restore(setStudentsProctors, {}).reset(resetFields)

export const changeStudentsForAppointments = createEvent<StudentsForForAppointments>()
export const $studentsForAppointments = restore<StudentsForForAppointments>(
  changeStudentsForAppointments,
  {}
).reset(resetFields)

export const $studentsForAppointmentsCount = $studentsForAppointments.map(
  (data) => Object.keys(data).length
)

export const $studentsForAppointmentsIds = $studentsForAppointments.map((data) =>
  Object.values(data).map((student) => student.id)
)

export const $disabledSaveButtons = combine(
  $nameCmsCommentary,
  $appointmentsIsProctoringActivated,
  $studentsForAppointmentsIds,
  (name, is_proctoring_activated, students_ids) => {
    return !name || students_ids.length === 0
  }
)

export const $fullAppointmentSendForm = combine(
  $nameCmsCommentary,
  $appointmentsCmsCommentary,
  examTestsDropdownModel.store.$item,
  $studentsForAppointments,
  classesDropdownModule.store.$item,
  $appointmentsAvailableFrom,
  $appointmentsAvailableTo,
  groupsDropdownModel.store.$selectedItems,
  attemptsCountDropdownModel.store.$item,
  $studentsProctors,
  $importFileId,
  (
    cms_name,
    cms_commentary,
    test,
    students,
    study_year,
    available_from,
    available_to,
    groups,
    attempts,
    studentsProctors,
    importFileId
  ) => {
    const isStudent = Object.keys(students).length > 0
    const attemptsObj = Object.values(students).map((student) => {
      if (student.id in studentsProctors) {
        return {
          student: student.id,
          proctors: studentsProctors[student.id].proctors.map((proctor) => proctor.name),
        }
      }
      return {
        student: student.id,
        proctors: null,
      }
    })
    return {
      cms_name,
      cms_commentary,
      test_id: Number(test?.name) || 0,
      ...(study_year ? { study_year_id: study_year.name } : {}),
      ...(isStudent ? { attempts: attemptsObj } : {}),
      ...(available_from ? { available_from } : {}),
      ...(available_to ? { available_to } : {}),
      ...(attempts ? { trials: attempts.name } : {}),
      ...(groups ? { groups_ids: groups.map((group) => Number(group.name)) } : {}),
      ...(importFileId ? { import_file_id: importFileId } : {}),
    }
  }
)

spread({
  source: getFullAppointmentIdFx.doneData.map((res) => ({
    ...res.body,
    cms_name: res.body.cms_name,
    cms_commentary: res.body.cms_commentary,
    test: res.body.test,
    study_year: res.body.study_year,
    attempts: res.body.attempts,
    groups: res.body.groups,
    available_from: res.body.available_from ? new Date(res.body.available_from) : '',
    available_to: res.body.available_to ? new Date(res.body.available_to) : '',
  })),
  targets: {
    cms_name: $nameCmsCommentary,
    cms_commentary: $appointmentsCmsCommentary,
    test: examTestsDropdownModel.methods.itemChanged.prepend((payload: Test | null) => {
      if (payload)
        return {
          name: `${payload.id}`,
          title: `(${payload.id}) ${payload.name}`,
        }
      return {
        name: ``,
        title: '',
      }
    }),
    study_year: classesDropdownModule.methods.itemChanged.prepend(
      (payload: { id: number; name: string } | null) => {
        if (payload)
          return {
            name: `${payload.id}`,
            title: payload.name,
          }
        return null
      }
    ),
    groups: groupsDropdownModel.methods.setSelectedItems.prepend((payload: Groups[]) => {
      if (payload)
        return payload.map((group: Groups) => ({
          name: `${group.id}`,
          title: group.name,
        }))
      return []
    }),
    available_from: $appointmentsAvailableFrom,
    available_to: $appointmentsAvailableTo,
    attempts: [
      changeStudentsForAppointments.prepend((data: AttemptStudentAppointmentsData[]) => {
        return data.reduce((students, student) => {
          students[student.id] = {
            email: student.email,
            fullname: `${student.first_name} ${student.last_name}`,
            id: student.id,
            study_year: student?.study_year?.name || '',
          }
          return students
        }, {})
      }),
    ],
  },
})

forward({
  from: resetFields,
  to: [
    proctorsDropdownModel.methods.resetDropdown,
    examTestsDropdownModel.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    groupsDropdownModel.methods.resetDropdown,
  ],
})

forward({
  from: resetFiltersFields,
  to: [classesDropdownModule.methods.resetDropdown, groupsDropdownModel.methods.resetDropdown],
})

forward({
  from: uploadFiles,
  to: [
    uploadFilesFx,
    setIsLoadFile.prepend(() => null),
    setStudentsErrorsAppointments.prepend(() => ({
      proctors: '',
      students: '',
    })),
    addToast.prepend(() => ({ type: 'loading', message: 'Идет загрузка файла(ов)' })),
  ],
})

forward({
  from: uploadFilesFx.doneData,
  to: [
    addToast.prepend(() => ({ type: 'success', message: 'Загрузка завершена!' })),
    setIsLoadFile.prepend((data) => {
      const { attempts } = data[0]
      if (attempts.length === 0) return 3
      return 1
    }),
  ],
})

sample({
  clock: uploadFilesFx.doneData,
  fn: (addImportAttempts: UploadProctorResponse[]) => {
    const setsProctors = {}
    if (addImportAttempts.length) {
      const { attempts } = addImportAttempts[0]
      attempts.forEach((attempt) => {
        const proctors = attempt.proctors
          ? attempt.proctors.map((proctor) => ({
              name: String(proctor.id),
              title: proctor.login,
            }))
          : []
        setsProctors[attempt.student.id] = {
          proctors,
          active_proctor: true,
        }
      })
    }
    return setsProctors
  },
  target: setStudentsProctors,
})

sample({
  clock: uploadFilesFx.doneData,
  fn: (addImportAttempts: UploadProctorResponse[]) => {
    const setsStudents = {}
    if (addImportAttempts.length) {
      const { attempts } = addImportAttempts[0]
      attempts.forEach((attempt) => {
        setsStudents[attempt.student.id] = {
          email: attempt.student.email,
          fullname: attempt.student.name,
          id: attempt.student.id,
          study_year: attempt.student.study_year,
        }
      })
    }
    return setsStudents
  },
  target: changeStudentsForAppointments,
})
sample({
  clock: uploadFilesFx.doneData,
  fn: (addImportAttempts: UploadProctorResponse[]) => {
    return addImportAttempts[0].import_file_id || null
  },
  target: setImportFileId,
})

sample({
  clock: uploadFilesFx.doneData,
  fn: (addImportAttempts: UploadProctorResponse[]) => {
    const data = addImportAttempts[0]
    return {
      proctors: data && data.errors.proctors ? data.errors.proctors.replace(/,/g, ', ') : '',
      students: data && data.errors.students ? data.errors.students.replace(/,/g, ', ') : '',
    }
  },
  target: setStudentsErrorsAppointments,
})

forward({
  from: uploadFilesFx.fail,
  to: setIsLoadFile.prepend(() => 2),
})

sample({
  source: $tempFiles,
  clock: uploadFilesFx.done,
  fn: (existFiles: FileList | null) => {
    const fileArr: File[] = []
    if (existFiles) {
      Object.values(existFiles).forEach((file) => {
        fileArr.push({
          isLimited: false,
          limit: 1,
          id: 546,
          owner: 121,
          file_type: 'file',
          file: 'file.xlsx',
          file_name: file.name,
          duration_sec: 4545,
        })
      })
    }
    return fileArr
  },
  target: setFiles,
})

export const $isFileUploadLoading = uploadFilesFx.pending
