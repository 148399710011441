import { DropdownItem } from '@/pages/common/types'
import {
  attach,
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector-root'
import { getTagsListFx } from '@/features/api/assignment/olympiad-tags/get-tags-list'
import { GetListQueryParams } from '@/features/api/types'
import { Tag } from '@/features/api/assignment/types/types'
import { $selectedClass } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { $selectedSubject } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { debounce } from 'patronum'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'
import { addToast } from '@/features/toasts/toasts.model'
import { parseError } from '@/features/lib'

export const getTags = attach({
  effect: getTagsListFx,
})

export const tagsDropdownModel = createDropdownMultiselectModel<Tag>(getTags)

export const setNewTags = createEvent<void>()

export const setSelectedTagsId = createEvent<number[]>()
export const resetSelectedTagsId = createEvent()
export const $selectedTagsID = restore<number[]>(setSelectedTagsId, []).reset(
  tagsDropdownModel.methods.resetSelectedItems,
  resetSelectedTagsId
)

export const loadTags = createEvent<void>()
export const toggleTagsLoaded = createEvent<boolean>()
export const $tagsLoaded = restore(toggleTagsLoaded, false)

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

const $formToGetTagsList = combine($selectedClass, $selectedSubject, (study_year, subject) => ({
  study_year: study_year && +study_year.name,
  subject: subject && +subject.name,
}))

const debounced = debounce({
  source: $formToGetTagsList,
  timeout: 150,
})

guard({
  clock: debounced,
  filter: $isComponentMounted,
  target: getTags,
})

sample({
  clock: loadTags,
  source: { $nextPage: tagsDropdownModel.store.$nextPage, $formToGetTagsList },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...params.$formToGetTagsList,
  }),
  target: getTags,
})

forward({
  from: tagsDropdownModel.methods.canLoadNextPage,
  to: loadTags,
})

sample({
  clock: getTags.doneData,
  source: { items: tagsDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newTags = res.body.data.map((field) => ({ name: `${field.id}`, title: field.name }))
    if (res.body.current_page === 1) {
      return newTags
    }
    return [...items, ...newTags]
  },
  target: tagsDropdownModel.store.$items,
})

sample({
  clock: getTags.failData,
  target: [
    addToast.prepend((data: any) => ({ type: 'error', message: parseError(data.body) })),
    toggleTagsLoaded.prepend(() => true),
  ],
})

guard({
  clock: getTags.inFlight,
  filter: (count) => count === 0,
  target: toggleTagsLoaded.prepend(() => true),
})

guard({
  clock: tagsDropdownModel.store.$items,
  source: $selectedTagsID,
  filter: (ids) => ids.length > 0,
  target: setNewTags,
})

guard({
  clock: setSelectedTagsId,
  filter: (ids) => ids.length === 0,
  target: tagsDropdownModel.methods.resetSelectedItems,
})

sample({
  source: { ids: $selectedTagsID, items: tagsDropdownModel.store.$items },
  clock: setNewTags,
  fn: (data: { ids: number[]; items: DropdownItem[] }) => {
    const { ids, items } = data
    if (ids && ids.length > 0) {
      return items.filter((el: DropdownItem) => ids.includes(Number(el.name)))
    }
    return []
  },
  target: [
    tagsDropdownModel.methods.setSelectedItems,
    resetSelectedTagsId,
    toggleTagsLoaded.prepend(() => true),
  ],
})
