












































































































































































import Vue from 'vue'
import {
  $searchAppointmentsStudent,
  setSearchAppointmentsStudent,
  studentAppointmentsFilters,
  studentAppointmentsPageParams,
  $studentIds,
  setStudentIds,
  showIdsFormatError,
  loadStudents,
  $studentsForTable,
  $appointmentsStudentsList,
  setAppointmentsStudentsList,
} from '@/pages/exams/appointments/create/model/student-shearch-appointments-filters.model'
import { DropdownItem } from '@/pages/common/types'
import FormInput from '@/ui/input/FormInput.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  mapStudentsType,
  studentsAppointmentsTableFields,
} from '@/pages/exams/appointments/create/model/constants'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import StudentErrorMessage from '@/pages/common/parts/users/StudentErrorMessage.vue'
import GroupsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/GroupsFilterDropdown.vue'
import {
  $studentsForAppointments,
  $studentsForAppointmentsCount,
  $studentsForAppointmentsIds,
  changeStudentsForAppointments,
  resetFiltersFields,
} from '@/pages/exams/appointments/create/model/form-fileds.model'
import { StudentAppointmentsDataMinimal } from '@/pages/exams/appointments/create/model/types'
import AppointmentsStudentsTypeDropdown from '@/pages/common/dropdowns/exams/appointments-students-type/AppointmentsStudentsTypeDropdown.vue'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { studentsTypeDropdownModule } from '@/pages/common/dropdowns/exams/appointments-students-type/appointments-students-type.model'
import SmallLoader from '@/pages/common/parts/internal-loader-blocks/SmallLoader.vue'
import { getStudentsListFx } from '@/features/api/appointments/get-students-list'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import get_ids_for_string from '@/lib/utils/get_ids_for_string'

export default Vue.extend({
  name: 'AppointmentsPage3',
  components: {
    SmallLoader,
    FormInput,
    Icon,
    ClassesDropdown,
    BaseButton,
    GroupsFilterDropdown,
    BaseTextarea,
    StudentErrorMessage,
    AppointmentsStudentsTypeDropdown,
  },
  data() {
    return {
      debounceId: undefined as ReturnType<typeof setTimeout> | undefined,
      showError: false,
      usersError: [],
      isLoadingTable: false,
      userData: [],
      total: 0,
      fields: studentsAppointmentsTableFields,
      checkedIds: [] as number[],
      showEmptyTable: false,
      idsError: false,
      isSearchStudentsId: false,
      noSearchStudentsId: [] as number[],
      searchStudentsId: [] as number[],
      isShowTable: false,
    }
  },
  watch: {
    isLoading(value) {
      if (!value && this.isSearchStudentsId) {
        const showingStudents: number[] = this.searchStudentsId
        const noShowStudents: number[] = []
        const studentData = this.$studentsForTable.list
        if (studentData.length > 0) {
          const studentsIds: number[] = studentData.map(
            (student: StudentAppointmentsDataMinimal) => student.id
          )
          showingStudents.forEach((studentId: number) => {
            if (!studentsIds.includes(studentId)) {
              noShowStudents.push(studentId)
            }
          })
          this.noSearchStudentsId = noShowStudents
        } else {
          this.noSearchStudentsId = [...showingStudents]
        }
        this.isSearchStudentsId = false
      }
    },
  },
  effector: {
    $searchAppointmentsStudent,
    $studentIds,
    $studentsForAppointments,
    $studentsForAppointmentsCount,
    $studentsForAppointmentsIds,
    $studentsForTable,
    $appointmentsStudentsList,
    class: classesDropdownModule.store.$item,
    groups: groupsDropdownModel.store.$selectedItems,
    $filterParams: studentAppointmentsFilters.store.$filterParams,
    $pageParams: studentAppointmentsPageParams.store.$pageParams,
    $treeView: studentAppointmentsPageParams.store.treeView,
    $currentPage: studentAppointmentsPageParams.store.currentPage,
    isLoading: getStudentsListFx.pending,
  },
  methods: {
    setSearchAppointmentsStudent,
    setStudentIds,
    changeStudentsForAppointments,
    showIdsFormatError,
    resetFiltersFields,
    loadStudents,
    changeFilter: studentAppointmentsFilters.methods.changeFilter,
    removeParamsFilter: studentAppointmentsFilters.methods.removeParamsFilter,
    resetFilters: studentAppointmentsFilters.methods.resetFilters,
    applyFilters: studentAppointmentsFilters.methods.applyFilters,
    queryToParams: studentAppointmentsPageParams.methods.queryToParams,
    setStudentsTypeDropdownModule: studentsTypeDropdownModule.methods.itemChanged,
    searchClassesStudents(item: string | null) {
      if (item) {
        this.changeFilter({
          study_year: Number(item),
        })
        this.onFilterSet()
      } else {
        this.removeParamsFilter('study_year')
      }
      this.onFilterSet()
    },
    searchGroups(item: string | string[] | null) {
      if (item) {
        if (typeof item === 'string') {
          this.changeFilter({
            groups_ids: item,
          })
        } else {
          this.changeFilter({
            groups_ids: item.join(','),
          })
        }
      } else {
        this.removeParamsFilter('groups_ids')
      }
      this.onFilterSet()
    },
    searchStudents(value: string) {
      this.setSearchAppointmentsStudent(value)
      if (this.debounceId !== undefined) {
        clearTimeout(this.debounceId)
      }
      this.debounceId = setTimeout(() => {
        if (value) {
          this.changeFilter({
            search: value,
          })
        } else {
          this.removeParamsFilter('search')
        }
        this.onFilterSet()
      }, 600)
    },
    studentIdsChanged(value: string) {
      this.setStudentIds(value)
      if (this.idsError) {
        this.idsError = false
      }
    },
    sendSearchStudentsId() {
      if (this.noSearchStudentsId.length > 0) {
        this.noSearchStudentsId = []
        this.searchStudentsId = []
      }
      const ids: number[] = get_ids_for_string(this.$studentIds)
      this.isSearchStudentsId = true
      this.searchStudentsId = ids
      this.changeFilter({
        ids: ids.join(','),
      })
      this.onFilterSet()
    },
    setStudentIdsFilters(item: DropdownItem) {
      // работа фильта показать всех или только выбранных
      // для показать всех показываем таблицу с учетом фильтов
      // для показать выбранных отправляется запрос на поиск по ид студентов из списка выбранных
      if (item.name === '1') {
        if (this.$studentIds) {
          this.sendSearchStudentsId()
        } else {
          this.removeParamsFilter('ids')
          this.onFilterSet()
        }
      } else if (this.$studentsForAppointmentsIds.length === 0) {
        this.showEmptyTable = true
        this.onFilterSet()
      } else {
        this.changeFilter({
          ids: this.$studentsForAppointmentsIds.join(','),
        })
        this.onFilterSet()
      }
    },
    onFilterSet() {
      this.applyFilters()
      this.onLoad()
    },
    resetUserResults() {
      this.removeParamsFilter('search')
      this.onFilterSet()
      this.setSearchAppointmentsStudent('')
      setAppointmentsStudentsList([])
    },
    resetUserIdResults() {
      this.removeParamsFilter('ids')
      this.onFilterSet()
      this.setStudentIds('')
      this.noSearchStudentsId = []
      setAppointmentsStudentsList([])
    },
    resetResults() {
      this.checkedIds = []
      this.setSearchAppointmentsStudent('')
    },
    onFilterReset() {
      this.setStudentIds('')
      this.noSearchStudentsId = []
      this.setSearchAppointmentsStudent('')
      this.resetFiltersFields()
      this.resetFilters()
      this.setStudentsTypeDropdownModule(mapStudentsType[0])
      this.onLoad()
    },
    onLoad() {
      if (Object.keys(this.$filterParams).length > 2) {
        this.isShowTable = true
        this.loadStudents()
      } else {
        this.isShowTable = false
      }
    },
    clearAll() {
      setAppointmentsStudentsList([])
      // this.removeAllStudent()
      this.onFilterReset()
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    toggleStudent(student: StudentAppointmentsDataMinimal, value: boolean) {
      const students = { ...this.$studentsForAppointments }
      if (value) {
        students[student.id] = student
      } else {
        delete students[student.id]
      }
      this.changeStudentsForAppointments(students)
    },
    addAllStudent() {
      const students = { ...this.$studentsForAppointments }
      this.$appointmentsStudentsList.forEach((student: StudentAppointmentsDataMinimal) => {
        students[student.id] = student
      })
      this.changeStudentsForAppointments(students)
    },
    removeAllStudent() {
      this.changeStudentsForAppointments({})
    },
  },
  beforeMount() {
    if (this.class?.name) {
      this.changeFilter({
        study_year: Number(this.class.name),
      })
    }
    if (this.groups && this.groups.length > 0) {
      this.changeFilter({
        groups_ids: this.groups.join(','),
      })
    }
  },
})
