import { createEvent, createStore, forward, attach, guard } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getSubjectsListFx } from '@/features/api/subject/get-subjects-list'
import { GetListQueryParams } from '@/features/api/types'

export const subjectsDropdownModule = createFilter()

export const unselectAllSubjects = createEvent<void>()

export const selectAllSubjects = createEvent<void>()

export const $isSelectedAllSubjects = createStore<boolean>(false)
  .on(unselectAllSubjects, () => false)
  .on(selectAllSubjects, () => true)

export const addSubject = createEvent<DropdownItem>()

export const deleteSubject = createEvent<DropdownItem>()

guard({
  clock: addSubject,
  filter: (payload) => payload?.name !== 'all',
  target: unselectAllSubjects,
})

const getSubjects = attach({
  effect: getSubjectsListFx,
  mapParams: (params: GetListQueryParams) => ({
    ...params,
    per_page: -1,
  }),
})

export const loadSubjects = createEvent<void>()
export const $subjects = createStore<DropdownItem[]>([])

forward({
  from: loadSubjects,
  to: getSubjects.prepend(() => ({})),
})

forward({
  from: getSubjects.doneData.map((res) => [
    ...res.body.data.map((studyYear) => ({ name: `${studyYear.id}`, title: studyYear.name })),
    { name: 'all', title: 'Все' },
  ]),
  to: $subjects,
})

export const $selectedSubjects = createStore<DropdownItem[]>([])
  .on(addSubject, (state, payload) => {
    return [...state, payload]
  })
  .on(deleteSubject, (state, payload) => {
    return state.filter((element) => element.name !== payload.name)
  })
  .reset(subjectsDropdownModule.methods.resetDropdown)
