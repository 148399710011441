import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import { CommentOnLesson, GetCommentsOnLessonsListQueryParams } from '@/features/api/callback/types'

export const getCommentsOnLessonsListFX = createApiEffect<
  GetCommentsOnLessonsListQueryParams,
  TableDataResponse<CommentOnLesson[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/lesson-app/lessons/commentaries/list/',
    query,
  }),
})
