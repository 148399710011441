<template>
  <div class="content">
    <BaseButton
      small
      @click='openModals'
    >
      Фильтр по результатам
    </BaseButton>
  </div>
</template>

<script>
import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import { attemptsFilterModalVisibilityChanged } from '@/pages/common/modals/attempts-filter/attempts-filter-modal.model'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'AttemptsModalButtonFilter',
  components: {
    BaseButton,
  },
  methods: {
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    openModals() {
      attemptsFilterModalVisibilityChanged(true)
    },
  },
})
</script>

<style scoped>
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>

