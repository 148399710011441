































































import Vue from 'vue'
import PageHeader from '@/pages/dictionary/files/parts/header/PageHeader.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import { getSystemFilesActions, searchFieldsData } from '@/pages/dictionary/files/constants'
import {
  $visibility,
  filesFilters,
  toggleVisibility,
} from '@/pages/dictionary/files/parts/filter/filter.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import FilesFilter from '@/pages/dictionary/files/parts/filter/FilesFilter.vue'
import FilesTree from '@/pages/dictionary/files/parts/tree/FilesTree.vue'
import {
  $folderId,
  $isLoading,
  deleteSelectedFileFx,
  deleteSelectedFolderFx,
  loadTree,
  loadTreeLight,
  setElementToCopy,
  setFolderId,
  uploadFile,
  $elementToCopy,
  pasteFile,
  pasteFolder,
  $filesTreeTotal,
} from '@/pages/dictionary/files/system-files-page.model'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import CreatingFolderModal from '@/pages/common/modals/system-files/create-folder/CreateFolder.vue'
import { loadFolder } from '@/pages/common/modals/system-files/create-folder/create-folder.model'
import UpdateElementModal from '@/pages/common/modals/system-files/update-element/UpdateElementModal.vue'
import {
  loadFolder as loadFolderToUpdate,
  loadFile,
  setElementType,
} from '@/pages/common/modals/system-files/update-element/update-element.model'
import { $token } from '@/features/api/common/request'
import { DEFAULT_ID } from '@/pages/common/constants'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import {
  CommonInteractedItemParams,
  ActionsItem,
  DisplayContextMenuPayload,
} from '@/pages/common/types'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import { clearTreeStates } from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { getActionsDisplayConditions } from '@/pages/common'
import { $permissions } from '@/features/session'

export default Vue.extend({
  name: 'SystemFilesPage',
  components: {
    TableHeader,
    PageHeader,
    GeneralFilter,
    FilesFilter,
    FilesTree,
    LoaderBig,
    ContextMenu,
    ConfirmDeleteModal,
    CreatingFolderModal,
    UpdateElementModal,
  },
  data: () => ({
    searchFields: searchFieldsData,
    interactedItemId: DEFAULT_ID as number,
    interactedItemName: '',
    contextMenuType: 'item' as ContextMenuType,
    contextMenuClickedCoordinates: { x: 0, y: 0 },
    contextMenuItems: [] as ActionsItem[],
    isFolder: false,
  }),
  effector: {
    $visibility,
    $isLoading,
    $folderId,
    $elementToCopy,
    filterParams: filesFilters.store.$filterParams,
    $token,
    $filesTreeTotal,
    $permissions,
  },
  computed: {
    selectedIds(): number[] {
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    interactedItemType(): 'file' | 'folder' {
      return this.contextMenuType === 'folder' ? 'folder' : 'file'
    },
  },
  methods: {
    changeFilter: filesFilters.methods.changeFilter,
    resetFilters: filesFilters.methods.resetFilters,
    applyFilters: filesFilters.methods.applyFilters,
    toggleVisibility,
    reset,
    loadTree,
    loadFolder,
    onFilterSet() {
      this.applyFilters()
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      clearTreeStates()
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({
        id: data.id,
        name: data.name!,
        type,
        coordinates: { x: event.x, y: event.y },
      })
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      event.preventDefault()
      this.displayContextMenu({
        id: data.id,
        name: data.name!,
        type,
        coordinates: { x: event.x, y: event.y },
      })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(this.contextMenuType, 0, true)
      this.contextMenuItems = getSystemFilesActions(
        displayConditions,
        this.$permissions!,
        this.$elementToCopy.id
      )
    },
    displayContextMenu({ id, name, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.interactedItemName = name!
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    handleRemove() {
      loadConfirmDeleteModal([`"${this.interactedItemName}"`])
    },
    handleConfirmDelete(ids: number[], type: string) {
      const id = this.interactedItemId
      type === 'folder' ? deleteSelectedFolderFx(id) : deleteSelectedFileFx(id)
    },
    handleCreateFolder() {
      loadFolder(this.selectedIds[0])
    },
    handleUploadFile() {
      setFolderId(this.selectedIds[0])
      const fakeInput = document.getElementById('fileInput')
      fakeInput!.click()
    },
    handleFileUpload() {
      const input = document.querySelector('#fileInput')
      const { files } = input as HTMLInputElement
      uploadFile({ files: files!, folderID: this.$folderId })
    },
    handleRename() {
      setElementType(this.interactedItemType)
      this.interactedItemType === 'folder'
        ? loadFolderToUpdate(this.selectedIds[0])
        : loadFile(this.selectedIds[0])
    },
    handleCopy() {
      setElementToCopy({ id: this.selectedIds[0], type: this.interactedItemType })
    },
    handlePaste() {
      this.$elementToCopy.type === 'folder'
        ? pasteFolder(this.selectedIds[0])
        : pasteFile(this.selectedIds[0])
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!/create/g.test(from.path) && !/edit/g.test(from.path) && !/preview/g.test(from.path)) {
      clearTreeStates()
    }
    next()
  },
  mounted() {
    loadTreeLight()
  },
  destroyed() {
    this.resetFilters()
  },
})
