














































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FolderDropdown from '@/pages/common/dropdowns/bank/exam-tasks/folder-dropdown/FolderDropdown.vue'
import { examAssignmentFolderModal } from '@/pages/common/modals/tasks-bank/creating-folder/creating-folder-modal.model'

export default Vue.extend({
  name: 'CreatingExamAssignmentFolderModal',
  components: {
    Modal,
    Icon,
    FormInput,
    BaseButton,
    FolderDropdown,
  },
  effector: {
    $modalVisibility: examAssignmentFolderModal.store.$modalVisibility,
    titleError: examAssignmentFolderModal.store.$titleErrorModule.store.$error,
    $folderTitle: examAssignmentFolderModal.store.$folderTitle,
  },
  methods: {
    ...examAssignmentFolderModal.methods,
  },
})
