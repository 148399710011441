














import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'BaseInput',
  props: {
    value: { type: [String, Number] as PropType<string | number>, default: '' },
    maxLength: { type: Number },
    minValue: { type: Number, default: 0 },
  },
  model: {
    event: 'input',
    prop: 'value',
  },
})
