











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  $templates,
  loadTemplates,
  templatesDropdownModel,
} from '@/pages/results/certificates-generator/create-certificates/model/certifiacates-templates-list-dropdown'

export default Vue.extend({
  name: 'CertificatesTemplatesListDropdown',
  components: {
    FilterDropdown,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Выберите шаблон сертификата',
    },
    label: {
      type: String,
      default: 'Шаблон сертификата',
    },
  },
  effector: {
    $templates,
    ...templatesDropdownModel.store,
  },
  methods: {
    ...templatesDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item)
    },
  },
  mounted() {
    if (this.$templates.length === 0) {
      loadTemplates()
    }
  },
})
