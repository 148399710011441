
















import Vue from 'vue'
import Tabs from '@/pages/common/parts/tabs/Tabs.vue'
import IntroTab from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/IntroTab.vue'
import MainTab from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/MainTab.vue'
import UploadFilesTab from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/UploadFilesTab.vue'

export default Vue.extend({
  name: 'CreateExamTestTab2',
  components: {
    Tabs,
    UploadFilesTab,
    IntroTab,
    MainTab,
  },
  data() {
    return {
      tab: 1,
      tabs: [
        {
          tab: 1,
          title: 'Вводная часть',
        },
        {
          tab: 2,
          title: 'Основная часть',
        },
        {
          tab: 3,
          title: 'Загрузка файлов',
        },
      ],
    }
  },
  methods: {
    setTab(tab: number) {
      this.tab = tab
    },
  },
})
