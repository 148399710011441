






import Vue from 'vue'
import { config } from '@/config'
import dayjs from 'dayjs'

export default Vue.extend({
  name: 'HomePage',
  computed: {
    gitCommitText() {
      return config.COMMIT_MESSAGE
    },
    gitCommitInitiator() {
      return config.COMMIT_INITIATOR
    },
    gitCommitDate() {
      return config.COMMIT_DATE
        ? dayjs(config.COMMIT_DATE).add(3, 'hour').format('HH:mm DD.MM.YYYY')
        : '–'
    },
  },
})
