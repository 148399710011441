



















import Vue from 'vue'
import { $isOpened } from '@/pages/common/navigation/navigation.model'

export default Vue.extend({
  name: 'HeaderLogo',
  effector: {
    $isOpened,
  },
})
