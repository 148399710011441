











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import { attemptDropdownModule } from '@/pages/results/tests-students/parts/filter/parts/tests-attempt/attempt-dropdown.model'

export default Vue.extend({
  name: 'TestsAttemptDropdown',
  components: {
    FilterDropdown,
  },
  effector: {
    ...attemptDropdownModule.store,
  },
  methods: {
    ...attemptDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
})
