import { attach, createEvent, forward, restore } from 'effector-root'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { createStore } from 'effector'
import { TableField } from '@/pages/applications/types'
import { studentsResultDataFields } from '@/pages/results/tests-students/constants'
import { sendResultStudentsCoursesListFx } from '@/features/api/results/update-result-courses-student'
import { updateQuestionParams } from '@/features/api/results/types'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { exportResultStudentsTestingsListFx } from '@/features/api/results/export-result-testings-student'
import fileDownload from 'js-file-download'

type SetColumnsColumns = {
  [key: string]: boolean
}

export const saveResultStudentsCourse = createEvent<updateQuestionParams[]>()

const updResultStudentsCourse = attach({
  effect: sendResultStudentsCoursesListFx,
})

export const incomingResultStudentsPageParams = createPageParamsModel()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore<boolean>(setIsLoading, false)

export const resetStudentId = createEvent()
export const setStudentId = createEvent<number>()
export const $studentId = restore<number>(setStudentId, 0).reset(resetStudentId, reset)

export const resetTestsId = createEvent()
export const setTestId = createEvent<number>()
export const $testId = restore<number>(setTestId, 0).reset(resetTestsId, reset)

export const resetAttemptId = createEvent()
export const setAttemptId = createEvent<number>()
export const $attemptId = restore<number>(setAttemptId, 0).reset(resetAttemptId, reset)

export const setEditData = createEvent<{ [key: number]: updateQuestionParams }>()
export const $editData = restore(setEditData, {})

export const canRefreshTableChanged = createEvent<boolean>()
export const $canRefreshTable = restore(canRefreshTableChanged, false)

export const setNewTableFields = createEvent<SetColumnsColumns>()
export const setDefaultTableFields = createEvent<void>()
export const $tableFields = createStore<TableField[]>(studentsResultDataFields)
  .on(setNewTableFields, (fields, settings) =>
    studentsResultDataFields.filter((field) => settings[field.name])
  )
  .reset(setDefaultTableFields)

export const exportResultStudentsTestingsList = attach({
  effect: exportResultStudentsTestingsListFx,
  source: { student: $studentId, test: $testId, sample: $attemptId },
  mapParams: (_, filters) => {
    const sampleId = filters.sample.toString()
    const student = filters.student.toString()
    const test = filters.test.toString()
    return { student, test, sample: sampleId }
  },
})

exportResultStudentsTestingsList.doneData.watch((res) =>
  fileDownload(res.body, 'resultStudentsTestingsList.xlsx')
)

forward({
  from: saveResultStudentsCourse,
  to: updResultStudentsCourse,
})

forward({
  from: updResultStudentsCourse.doneData,
  to: [
    successToastEvent('Успешно сохранено!'),
    setEditData.prepend(() => ({})),
    canRefreshTableChanged.prepend(() => true),
  ],
})
