import { TableField } from '@/pages/applications/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { TicketCommentType } from '@/features/api/ticket/types'
import { mapApplicationsStatus } from '@/pages/common/parts/status-controller/constants'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'
import { StudyYear, Subject } from '@/features/api/subject/types'
import { TestAssignment } from '@/features/api/assignment/types/test-assignments-types'

export const incomingApplicationsDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'test_assignment',
    sortField: 'test_assignment__id',
    title: 'ID задания',
    width: '120px',
    formatter(obj: TestAssignment) {
      return (obj && obj.id) || '-'
    },
  },
  {
    name: 'test_assignment.theme.subject',
    sortField: 'test_assignment__theme__subject__name',
    title: 'Предмет',
    width: '140px',
    formatter(subject: Subject) {
      return (subject && subject.name) || '-'
    },
  },
  {
    name: 'test_assignment.theme.study_year',
    sortField: 'test_assignment__theme__study_year__name',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Класс',
    width: '100px',
    formatter(study_year: StudyYear) {
      return (study_year && study_year.number) || '-'
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус',
    width: '180px',
    formatter(status: any) {
      return mapApplicationsStatus[status] || '-'
    },
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создана',
    width: '160px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'moderated_at',
    sortField: 'moderated_at',
    title: 'Обработана',
    width: '160px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'moderate_by',
    sortField: 'moderate_by',
    title: 'Проверяющий',
    width: '200px',
    formatter: (data) => {
      return data && data.first_name && data.last_name
        ? `${data.first_name} ${data.last_name}`
        : '–'
    },
  },
  {
    name: 'comment',
    sortField: 'comment',
    title: 'Комментарий',
    width: '200px',
    formatter(comment: TicketCommentType) {
      return comment?.text || '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '120px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'id Задания' },
  { name: 'subject', title: 'Предмет' },
  { name: 'study_year', title: 'Класс' },
  { name: 'created_by', title: 'Создатель' },
  { name: 'moderate_by', title: 'Проверяющий' },
  { name: 'updated_by', title: 'Редактор' },
  { name: 'comment', title: 'Комментарий' },
]

export const getIncomingTicketsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const commonIsDisplayed =
    displayOptions.isTableItem ||
    displayOptions.isTableItems ||
    displayOptions.isHeaderItem ||
    displayOptions.isHeaderItems
  const actions = [
    {
      name: 'preview',
      title: 'Предпросмотр',
      action: 'onPreview',
      isDisplayed: commonIsDisplayed,
      hasPermissionToSee:
        permissions.assignments_testassignment_difficulty_base.can_view ||
        permissions.assignments_testassignment_difficulty_pro.can_view ||
        permissions.assignments_testassignment_difficulty_exam.can_view ||
        permissions.tickets_moderationticket_income_tickets.can_edit,
    },
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed: commonIsDisplayed,
      hasPermissionToSee:
        permissions.assignments_testassignment_difficulty_base.can_edit ||
        permissions.assignments_testassignment_difficulty_pro.can_edit ||
        permissions.assignments_testassignment_difficulty_exam.can_edit ||
        permissions.tickets_moderationticket_income_tickets.can_edit,
    },
    {
      name: 'accept',
      title: 'Принять',
      action: 'onAccept',
      isDisplayed: commonIsDisplayed,
      hasPermissionToSee: permissions.tickets_moderationticket_income_tickets.can_edit,
    },
    {
      name: 'sendToRevision',
      title: 'На доработку',
      action: 'onSendToRevision',
      isDisplayed: commonIsDisplayed,
      hasPermissionToSee: permissions.tickets_moderationticket_income_tickets.can_edit,
    },
    {
      name: 'assignToModerator',
      title: 'Назначить',
      action: 'onAssignToModerator',
      isDisplayed: commonIsDisplayed,
      hasPermissionToSee: permissions.tickets_moderationticket_income_tickets.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
