import { createApiEffect } from '@/features/api/common/create-api-effect'
import {
  CreateTestManualItemFxParams,
  ManualItem,
  UpdateTestManualItemFxParams,
} from '@/features/api/test/types'
import { createEffect } from 'effector'

export const createTestManualItemFx = createApiEffect<CreateTestManualItemFxParams, ManualItem>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/test-app/manual-item/',
    body,
  }),
})

export const createTestManualItemsArrFx = createEffect(
  async (data: CreateTestManualItemFxParams[]) => {
    return Promise.all(
      data.map(async (manualItem) => {
        await createTestManualItemFx(manualItem)
      })
    )
  }
)
export const updateTestManualItemsFx = createApiEffect<UpdateTestManualItemFxParams, ManualItem>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: `/api/test-app/manual-item/${body.id}/`,
    body,
  }),
})

export const removeTestManualItemsFx = createApiEffect<number>({
  requestMapper: (id) => ({
    method: 'DELETE',
    url: `/api/test-app/manual-item/${id}/`,
  }),
})
export const resendTestManualItemsArrFx = createEffect(
  async (data: UpdateTestManualItemFxParams[]) => {
    const dataIds = data.map((item) => item.id)
    const oldIds = data.length > 0 ? data[0].oldId : []
    const deleteItems = oldIds.filter((item) => !dataIds.includes(item))
    return Promise.all([
      ...data.map(async (manualItem) => {
        if (manualItem.isNew) {
          await createTestManualItemFx(manualItem)
        } else if (manualItem.id) {
          await updateTestManualItemsFx(manualItem)
        }
      }),
      ...deleteItems.map(async (deleteId) => {
        if (deleteId) {
          await removeTestManualItemsFx(deleteId)
        }
      }),
    ])
  }
)
