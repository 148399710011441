import { attach, createEffect, createEvent, forward, combine } from 'effector-root'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { condition } from 'patronum'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { getThemesListFx } from '@/features/api/subject/get-themes-list'
import { RequestDeleteBannersParams } from '@/features/api/content/types'
import {
  deleteBannersFx,
  requestDeleteBannersFx,
} from '@/features/api/content/banners/delete-banners'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'

const getBannersList = attach({
  effect: getThemesListFx,
})

export const deleteBanners = createEffect({
  handler: (ids: number[]): Promise<number[]> => {
    return new Promise((resolve) => {
      deleteBannersFx(ids).then(() => {
        resolve(ids)
      })
    })
  },
})

export const requestDeleteBanners = attach({
  effect: requestDeleteBannersFx,
  mapParams: (payload: RequestDeleteBannersParams): RequestDeleteBannersParams => {
    return {
      banners: payload.banners,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const bannersFilters = createFiltersModel(
  {
    search_area: 'search_all',
  },
  {}
)

export const bannersPageParams = createPageParamsModel()

const showDeleteBannersToast = createEvent<number[]>()

export const $isLoading = combine(getBannersList.pending, (list) => list)

forward({
  from: deleteBanners.doneData,
  to: [confirmDeleteModalVisibilityChanged.prepend(() => false), showDeleteBannersToast],
})

condition({
  source: showDeleteBannersToast,
  if: (ids: number[]) => ids.length === 1,
  then: successToastEvent('Баннер был успешно удален!'),
  else: successToastEvent('Баннеры были успешно удалены!'),
})

forward({
  from: requestDeleteBanners.doneData,
  to: [
    successToastEvent('Отправлена заявка на удаление'),
    requestDeleteModalVisibilityChanged.prepend(() => false),
  ],
})
