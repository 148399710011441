
























import Vue, { PropType } from 'vue'
import Card from '@/ui/card/Card.vue'
import BaseButton from '@/ui/button/BaseButton.vue'

export default Vue.extend({
  components: {
    Card,
    BaseButton,
  },
  props: {
    title: { type: String as PropType<string> },
  },
})
