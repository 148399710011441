import { createEvent, forward, restore, sample } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { ApiEffect } from '@/features/api/common/create-api-effect'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { formatSelectedItems } from '@/pages/common/dropdowns/multiselectDropdown/types'

export function createDropdownMultiselectModel<T>(
  loader: ApiEffect<GetListQueryParams, TableDataResponse<T[]>, any>
) {
  const baseModel = createDropdownModel(loader)

  const resetSelectedItems = createEvent<void>()
  const setSelectedItems = createEvent<DropdownItem[]>()
  const $selectedItems = restore<DropdownItem[]>(setSelectedItems, []).reset(resetSelectedItems)
  const deleteSelectedItem = createEvent<string>()

  forward({
    from: baseModel.methods.resetDropdown,
    to: resetSelectedItems,
  })

  sample({
    source: $selectedItems,
    clock: deleteSelectedItem,
    fn: (list, id: string) => list.filter((el: any) => el.name !== id),
    target: $selectedItems,
  })

  sample({
    source: {
      selected: $selectedItems,
      all: baseModel.store.$itemsDropdown,
      searchString: baseModel.store.$searchString,
    },
    clock: baseModel.methods.itemChanged,
    fn: (list, element) => {
      return formatSelectedItems(list, element)
    },
    target: [$selectedItems, baseModel.methods.resetItem],
  })

  return {
    store: {
      ...baseModel.store,
      $selectedItems,
    },
    methods: {
      ...baseModel.methods,
      setSelectedItems,
      resetSelectedItems,
      deleteSelectedItem,
    },
  }
}
