import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import DifficultyDropdown from '@/pages/common/dropdowns/testing/difficulty-dropdown/DifficultyDropdown.vue'
import StatusDropdown from '@/pages/common/dropdowns/testing/status-dropdown/StatusDropdown.vue'
import TypeDropdown from '@/pages/common/dropdowns/testing/type-dropdown/TypeDropdown.vue'
import GroupDropdown from '@/pages/common/dropdowns/groups/GroupsDropdown.vue'

export const dropdownComponents = {
  ClassesDropdown,
  SubjectsDropdown,
  DifficultyDropdown,
  StatusDropdown,
  TypeDropdown,
  GroupDropdown,
}
