


































































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import NavChild from '@/pages/common/navigation/parts/NavChild.vue'
import {
  $openedItem,
  $openedSubmenu,
  $totalApplicationsCounter,
  changeOpenedItem,
  changeOpenedSubmenu,
} from '@/pages/common/navigation/navigation.model'
import { NavItem, NavItemChild, NavItemSubmenu } from '@/pages/common/navigation/types'
import Cookies from 'js-cookie'
import { $currentUser, $routesPermissions } from '@/features/session'

export default Vue.extend({
  name: 'NavItem',
  components: { Icon, NavChild },
  effector: {
    $openedItem,
    $totalApplicationsCounter,
    $routesPermissions,
    $openedSubmenu,
    changeOpenedSubmenu,
    $currentUser,
  },
  props: {
    item: { type: Object as PropType<NavItem>, required: true },
    opened: { type: Boolean, required: true },
  },

  data: () => ({
    isHovered: false,
    isHoveredSubmenu: false,
    currentSubmenu: '',
  }),
  computed: {
    isItemVisible(): boolean {
      if (!this.item.children) return this.$currentUser?.role !== 'METHODIST'
      return this.item.children.some((child: NavItemChild | NavItemSubmenu) => {
        if ('isSubmenu' in child) {
          return child.children?.some((childrenItem) => this.isPermissions(childrenItem.link))
        }
        return this.isPermissions(child.link)
      })
    },
    isCollapsed(): boolean {
      return !this.opened || this.$openedItem !== this.item.id
    },
    iconType(): string {
      if (this.isHovered) return `${this.item.icon}-selected`
      return this.isCollapsed ? this.item.icon : `${this.item.icon}-selected`
    },
  },
  methods: {
    isPermissions(link: string) {
      if (!link) return false
      if (link in this.$routesPermissions) {
        return this.$routesPermissions[link]
      }
      if (this.$currentUser?.role !== 'METHODIST') return false
      return true
    },
    changeCollapseState() {
      if (!this.opened) return
      if (this.$openedItem === this.item.id) {
        changeOpenedItem(null)
      } else {
        changeOpenedItem(this.item.id)
        Cookies.set('openedItem', `${this.$openedItem}`)
      }
    },
    changeCollapseStateSubmenu(id: string) {
      if (this.$openedSubmenu === id) {
        changeOpenedSubmenu(null)
        Cookies.remove('openedItemSubmenu')
      } else {
        this.currentSubmenu = id
        changeOpenedSubmenu(id)
        Cookies.set('openedItemSubmenu', `${this.$openedSubmenu}`)
      }
    },
    isShowSubmenu(menu: NavItemChild | NavItemSubmenu | null) {
      if (menu && 'isSubmenu' in menu) {
        if (menu.id in this.$routesPermissions) {
          return this.isPermissions(menu.id)
        }
        return true
      }
      return false
    },
  },
  beforeMount() {
    const openedItem = Cookies.get('openedItem')
    if (openedItem) changeOpenedItem(openedItem)
    const openedItemSubmenu = Cookies.get('openedItemSubmenu')
    if (openedItemSubmenu) {
      this.currentSubmenu = openedItemSubmenu
      changeOpenedSubmenu(openedItemSubmenu)
    }
  },
})
