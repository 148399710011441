import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/exams/exams-full-tests/exams-full-test-list-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { studentsExamAnswerDropdownModel } from '@/pages/exams/full-time-answers/list/parts/students-exams-full-answer-filter/students-exams-full-answer-filter.model'
import { AttemptsDropdownModel } from '@/pages/exams/full-time-answers/list/parts/full-attempts-filter/full-attempts-filter.model'
import { ExamsBlockFilterDropdownModel } from '@/pages/exams/full-time-answers/list/parts/exams-block-filter/exams-block-filter.model'
import { ExamsElemTypeFilterFilterDropdownModel } from '@/pages/exams/full-time-answers/list/parts/exams-elem-type-filter/exams-elem-type-filter.model'

export const dropdownFullExamsAnswerModules = {
  groupsDropdownModel,
  subjectsDropdownModel,
  classesDropdownModule,
  examTestsDropdownModel,
  studentsExamAnswerDropdownModel,
  AttemptsDropdownModel,
  ExamsBlockFilterDropdownModel,
  ExamsElemTypeFilterFilterDropdownModel,
}
