import { combine, forward, attach, sample, createEvent, restore } from 'effector-root'
import { updateSubjectFx } from '@/features/api/subject/update-subject'
import { getSubjectFx } from '@/features/api/subject/get-subject'
import { Subject } from '@/features/api/subject/types'
import {
  $formToSend,
  clearFields,
  $subjectTitleErrorModule,
  toggleIsMondatory,
  subjectTitleChanged,
  subjectDescriptionChanged,
  useFulInfoChanged,
  shortUsefulInfoChanged,
  iconSubjectIdChanged,
  imageSubjectIdChanged,
  $colorIdErrorModule,
} from '@/pages/dictionary/subjects/common/create-edit.model'
import {
  colorChanged,
  resetColor,
} from '@/pages/dictionary/subjects/common/colors/color-picker.model'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { condition } from 'patronum'

const updateSubjectDataFx = attach({
  effect: updateSubjectFx,
})
const getSubjectToUpdate = attach({
  effect: getSubjectFx,
})

export const save = createEvent<void>()
const updateSubject = createEvent<void>()
const checkIfSubjectCanBeSend = createEvent<void>()

export const changeIdSubject = createEvent<number>()
const $idSubject = restore(changeIdSubject, -1)

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

const $modifyForm = combine($formToSend, $idSubject, (formToSend, idSubject) => ({
  ...formToSend,
  id: idSubject,
}))
// дергаем отправку формы
forward({
  from: save,
  to: checkIfSubjectCanBeSend.prepend(() => ({})),
})
sample({
  source: $formToSend,
  clock: checkIfSubjectCanBeSend,
  fn: (obj) => {
    if (obj.name && obj.name.trim().length < 31 && obj.color && obj.color.length) {
      updateSubject()
    } else {
      if (obj.color && !obj.color.length) $colorIdErrorModule.methods.setError(true)
      if (obj.name.trim().length > 30) {
        $subjectTitleErrorModule.methods.setError(true)
        errorToastEvent('Название предмета содержит более 30 символов')
      }
      if (!obj.name.trim().length) $subjectTitleErrorModule.methods.setError(true)
      errorToastEvent('Необходимо заполнить все обязательные поля')
    }
  },
})
sample({
  clock: updateSubject,
  source: $modifyForm,
  target: updateSubjectDataFx,
})

const $ifRedirect = sample({
  clock: updateSubjectDataFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

forward({
  from: updateSubjectDataFx.doneData,
  to: successToastEvent('Предмет успешно обновлен!'),
})
condition({
  source: $ifRedirect,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'subjects-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'subjects-edit',
    params: { id: `${payload.id}` },
  })),
})

// получаем предмет
sample({
  clock: changeIdSubject,
  source: $idSubject,
  target: getSubjectToUpdate,
})
sample({
  clock: getSubjectToUpdate.done,
  source: getSubjectFx.doneData.map((data) => data.body),
  fn: (subject: Subject) => {
    toggleIsMondatory(subject.is_mandatory)
    subjectTitleChanged(subject.name)
    subjectDescriptionChanged(subject.description)
    useFulInfoChanged(subject.useful_info)
    shortUsefulInfoChanged(subject.short_useful_info)
    if (subject.icon) iconSubjectIdChanged(subject.icon)
    if (subject.image) imageSubjectIdChanged(subject.image)
    if (subject.color) colorChanged(subject.color)
  },
})

// очистка полей
forward({
  from: clearFields,
  to: resetColor,
})
