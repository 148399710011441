import { createEvent, forward, attach, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { TestList } from '@/features/api/test/types'
import { getExamsTestsListFx } from '@/features/api/part-time-test/get-part-time-list'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'

const getExamTests = attach({
  effect: getExamsTestsListFx,
  mapParams: (params: GetListQueryParams) => params,
})

export const examTestsDropdownModel = createDropdownMultiselectModel<TestList>(getExamTests)

export const loadExamTests = createEvent<void>()

sample({
  clock: loadExamTests,
  source: { $nextPage: examTestsDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getExamTests,
})

forward({
  from: examTestsDropdownModel.methods.canLoadNextPage,
  to: loadExamTests,
})

sample({
  clock: getExamTests.doneData,
  source: { items: examTestsDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newTags = res.body.data.map((field) => ({
      name: `${field.id}`,
      title: `(${field.id}) ${field.name}`,
    }))
    return [...items, ...newTags]
  },
  target: examTestsDropdownModel.store.$items,
})
