















































































import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import {
  $cmsCommentary,
  $durationMin,
  $internalPartTimeName,
  $partTimeIsActive,
  $themesAmount,
  setCmsCommentary,
  setDurationMin,
  setInternalPartTimeName,
  setPartTimeIsActive,
  setThemesAmount,
} from '@/pages/exams/part-time-test/create/model/form-fileds.model'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import TasksChoiceDropdown from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/TasksChoiceDropdown.vue'
import FormationDropdown from '@/pages/exams/part-time-test/create/parts/FormationDropdown/FormationDropdown.vue'
import TestsDropdown from '@/pages/exams/part-time-test/create/parts/TestsDropdown/TestsDropdown.vue'
import ThemesDropdown from '@/pages/exams/part-time-test/create/parts/ThemesDropdown/ThemesDropdown.vue'
import TasksFormationDropdown from '@/pages/exams/part-time-test/create/parts/TasksFormationDropdown/TasksFormationDropdown.vue'
import { formationDropdownModel } from '@/pages/exams/part-time-test/create/parts/FormationDropdown/formation-dropdown.model'
import { tasksFormationDropdownModel } from '@/pages/exams/part-time-test/create/parts/TasksFormationDropdown/tasks-formation-dropdown.model'

export default Vue.extend({
  name: 'CreatePartTimeTestPage1',
  components: {
    FormInput,
    BaseSwitch,
    SubjectsDropdown,
    ClassesDropdown,
    TasksChoiceDropdown,
    FormationDropdown,
    TestsDropdown,
    ThemesDropdown,
    TasksFormationDropdown,
    BaseTextarea,
  },
  effector: {
    $internalPartTimeName,
    $partTimeIsActive,
    formation: formationDropdownModel.store.$item,
    tasks_formation: tasksFormationDropdownModel.store.$item,
    $themesAmount,
    $durationMin,
    $cmsCommentary,
  },
  methods: {
    setInternalPartTimeName,
    setPartTimeIsActive,
    setThemesAmount,
    setDurationMin,
    setCmsCommentary,
    changeThemesAmount(value: number) {
      if (!value || value < 1) {
        this.setThemesAmount(0)
        this.setThemesAmount(1)
      } else if (value > 32767) {
        this.setThemesAmount(1)
        this.setThemesAmount(32767)
      } else {
        this.setThemesAmount(value)
      }
    },
    changeDurationMin(value: number) {
      if (!value || value < 0) {
        this.setDurationMin(-1)
        this.setDurationMin(1)
      } else if (value > 32767) {
        this.setDurationMin(1)
        this.setDurationMin(32767)
      } else {
        this.setDurationMin(value)
      }
    },
  },
})
