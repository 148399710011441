import { createEvent, forward, attach, restore, sample, createStore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { GetListQueryParams } from '@/features/api/types'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { debounce } from 'patronum'
import { getDifficultFx } from '@/features/api/course/get-difficults'

const getDifficult = attach({
  effect: getDifficultFx,
})

export const difficultDropdownModel = createDropdownModel<{ value: string; display: string }>(
  getDifficult as any
)

export const setSelectedDifficult = createEvent<DropdownItem | null>()
export const $selectedDifficult = restore(setSelectedDifficult, null)

export const loadDifficult = createEvent<void>()

export const searchDifficult = createEvent<void>()

export const $difficulties = createStore<DropdownItem[]>([])

sample({
  clock: loadDifficult,
  source: {
    $nextPage: difficultDropdownModel.store.$nextPage,
    search: difficultDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search }),
  }),
  target: getDifficult,
})

sample({
  clock: searchDifficult,
  source: {
    search: difficultDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search && { search: params.search }),
  }),
  target: getDifficult,
})

const debounced = debounce({
  source: difficultDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchDifficult,
})

forward({
  from: difficultDropdownModel.methods.canLoadNextPage,
  to: loadDifficult,
})

sample({
  clock: getDifficult.doneData,
  source: {
    items: difficultDropdownModel.store.$items,
    $nextPage: difficultDropdownModel.store.$nextPage,
    search: difficultDropdownModel.store.$searchString,
  },
  fn: ({ items }, res) => {
    const newData = res.body.map((field) => ({
      name: `${field.value}`,
      title: field.display,
    }))
    return [...items, ...newData]
  },
  target: difficultDropdownModel.store.$items,
})

forward({
  from: difficultDropdownModel.methods.resetDropdown,
  to: setSelectedDifficult.prepend(() => null),
})
