<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="proctors-filter"
  >
    <div class="row">
      <SubjectFilterDropdown
        class="half-third"
        @setItem="val => setItem({'subjects': val})"
      />
      <ClassesFilterDropdown
        class="half-third"
        @setItem="val => setItem({'classes': val})"
      />
      <ExamsFullTestsListDropdown
        class="half-third"
        @setItem="val => setItem({'ids': val})"
      />
    </div>
    <div class="row">
      <div class="half-third">
        <div class="label">Дата и время начала доступности</div>
        <DatePicker
          :value="$fullPassedFrom"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setFrom"
        />
      </div>
      <div class="half-third">
        <div class="label">Дата и время окончания доступности</div>
        <DatePicker
          :value="$fullPassedTo"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setTo"
        />
      </div>
      <div class="half-third switch-wrap">
        <BaseSwitch
          class="switch"
          :checked="$proctoringActivated"
          @change="setActiveProctoring"
        >
          <p>С прокторингом</p>
        </BaseSwitch>
      </div>
    </div>
    <div class="row">
      <div class="labels-input-relative-align">
        <div class="buttons">
          <div class="btn">
            <BaseButton
              small
              @click="applyFilters"
            >
              Применить
            </BaseButton>
          </div>
          <div class="btn">
            <BaseButton
              class="borderless"
              small
              border-without-bg
              @click="resetFilters"
            >
              Сбросить фильтры
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <Icon
      type="close"
      class="close-icon"
      size="10"
      @click="toggleVisibility(false)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import {
  dropdownComponents,
  toggleVisibility,
} from '@/pages/exams/full-appointments/list/model/tooltip-filter.model'
import {
  $fullPassedFrom,
  $fullPassedTo,
  $proctoringActivated,
  fullAppointmentsFilters,
  setFullPassedFrom,
  setFullPassedTo,
  setProctoringActivated,
} from '@/pages/exams/full-appointments/list/model/full-appointments-filters.model'
import DatePicker from 'vue2-datepicker'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import { toggleVisibilityFilters } from '@/pages/exams/attempts/list/model/attempts-filters.model'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ToolTipFilter',
  components: {
    Icon,
    BaseButton,
    DatePicker,
    BaseSwitch,
    SubjectFilterDropdown: dropdownComponents.SubjectsDropdown,
    ClassesFilterDropdown: dropdownComponents.ClassesDropdown,
    ExamsFullTestsListDropdown: dropdownComponents.ExamsFullTestsListDropdown,
  },
  effector: {
    $filterParams: fullAppointmentsFilters.store.$filterParams,
    $fullPassedFrom,
    $fullPassedTo,
    $proctoringActivated,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  methods: {
    toggleVisibility,
    setFullPassedTo,
    setFullPassedFrom,
    setProctoringActivated,
    closeFilter(event) {
      // check for general filter icon
      const isClose =
        !event.target.closest('#filter-icon') &&
        event.target.nodeName !== 'use' &&
        !event.target.closest('.mx-calendar-content') &&
        !event.target.closest('.mx-time-list')
      if (isClose) {
        toggleVisibilityFilters(false)
      }
    },
    setFrom(value) {
      setFullPassedFrom(value)
      this.setItem({ available_from: value })
    },
    setTo(value) {
      setFullPassedTo(value)
      this.setItem({ available_to: value })
    },
    setActiveProctoring(value) {
      this.setProctoringActivated(value)
      this.setItem({ is_proctoring_activated: value })
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
    },
  },
})
</script>

<style scoped>
.proctors-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.labels-input-relative-align {
  width: 100%;
  display: flex;
  align-self: flex-end;
  height: 46px;

  .buttons {
    display: flex;
    align-items: center;
    margin-left: auto;

    .btn:first-child {
      margin-right: 38px;
    }
  }
}

.btn {
  width: max-content;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}
.date-picker {
  width: 100%;
}
.date-picker /deep/ .mx-input {
  height: 46px;
}
.label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.switch-wrap {
  display: flex;
  align-items: center;
}
.switch {
  margin-top: 17px;
}
</style>
