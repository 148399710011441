

















import Vue from 'vue'
import { cropString } from '@/pages/results/answer-types/wrappers/helpers'

export default Vue.extend({
  name: 'MultipleChoiceOneOrManyAnswers',
  props: {
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    correctAnswer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    currentAnswer() {
      return this.answerType === 'student' ? this.answer : this.correctAnswer
    },
    answerData() {
      try {
        if (this.answerType === 'student') {
          const arr: { title: string; bold: boolean }[] = this.question.map(
            (qs: { title: string; number: number }) => {
              if (this.answer.findIndex((el: number) => el === qs.number) > -1) {
                return {
                  title: qs.title.includes('<img') ? cropString(qs.title) : qs.title,
                  bold: true,
                  isImage: qs.title.includes('<img'),
                }
              }
              return {
                title: qs.title.includes('<img') ? cropString(qs.title) : qs.title,
                bold: false,
                isImage: qs.title.includes('<img'),
              }
            }
          )
          return arr
        }
        if (this.answerType === 'correct') {
          const arr: { title: string; bold: boolean }[] = this.question.map(
            (qs: { title: string; number: number }) => {
              if (
                this.correctAnswer.findIndex(
                  (el: { id: number; score: number }) => el.id === qs.number
                ) > -1
              ) {
                return {
                  title: qs.title.includes('<img') ? cropString(qs.title) : qs.title,
                  bold: true,
                  isImage: qs.title.includes('<img'),
                }
              }
              return {
                title: qs.title.includes('<img') ? cropString(qs.title) : qs.title,
                bold: false,
                isImage: qs.title.includes('<img'),
              }
            }
          )
          return arr
        }
        return this.answerType === 'student' ? this.answer : this.correctAnswer
      } catch (e) {
        console.error(e)
        return []
      }
    },
  },
})
