






















import Vue, { PropType } from 'vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { FiltersParams } from '@/pages/common/types'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { resultStudentsFilters } from '@/pages/results/courses-students/parts/filter/result-student-filter.model'
import { exportResultStudentsTestingsListFx } from '@/features/api/results/export-result-testings-student'
import { $permissions } from '@/features/session'

export default Vue.extend({
  components: {
    HeaderPopup,
    DownloadButton,
    BaseButton,
  },
  effector: {
    $filterParams: resultStudentsFilters.store.$filterParams,
    $permissions,
    isLoadingExport: exportResultStudentsTestingsListFx.pending,
  },
  props: {
    disableExport: { type: Boolean },
    tableColumns: { type: Array as PropType<TableField[]> },
  },
  methods: {
    setItem(filter: FiltersParams) {
      this.$emit('changeFilter', filter)
      this.$emit('setFilter')
    },
  },
})
