import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector-root'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { TreeData } from '@/features/api/types'
import { getLessonAssignmentTreeFx } from '@/features/api/assignment/lesson-assignment/get-lesson-assignment-tree'
import { getLessonAssignmentTreeLightFx } from '@/features/api/assignment/lesson-assignment/get-lesson-assignment-tree-light'
import {
  deleteLessonAssignmentsFx,
  requestDeleteLessonAssignmentsFx,
} from '@/features/api/assignment/lesson-assignment/delete-lesson-assignment'
import {
  deleteFolderFx,
  requestDeleteFolderFx,
} from '@/features/api/assignment/folder/delete-folder'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { condition } from 'patronum'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { FiltersParams } from '@/pages/common/types'
import { isObjectEmpty, mergeTreeData, sortTreeLeaves } from '@/features/lib'
import { getLessonInfoFx } from '@/features/api/assignment/lesson-assignment/get-tree-info'
import { getLessonAssignmentListFx } from '@/features/api/assignment/lesson-assignment/get-lesson-assignment-list'
import {
  GetAssignmentTreeQueryParams,
  RequestDeleteAssignmentsParams,
  RequestDeleteFolderParams,
} from '@/features/api/assignment/types/types'
import {
  $nDuplicate,
  changedDuplicateModalVisibility,
} from '@/pages/bank/common/modals/duplicate/duplicate.model'
import { LessonAssignmentsBulkUpdate } from '@/features/api/assignment/types/lesson-assignments-types'
import { updateLessonAssignmentBulkFx } from '@/features/api/assignment/lesson-assignment/update-lesson-assignment-bulk'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { lessonTasksFilters } from '@/pages/bank/lesson-tasks/list/parts/lesson-tasks-filter/lesson-tasks-filter.model'
import { exportLessonAssignmentListFx } from '@/features/api/assignment/lesson-assignment/export-lesson-assignment'
import fileDownload from 'js-file-download'

const getLessonsTree = attach({
  effect: getLessonAssignmentTreeFx,
})
const getLessonsTreeLight = attach({
  effect: getLessonAssignmentTreeLightFx,
})

export const getFilteredTree = attach({
  effect: getLessonAssignmentTreeFx,
})

const getLessonTreeInfo = attach({
  effect: getLessonInfoFx,
})

const getTasksList = attach({
  effect: getLessonAssignmentListFx,
})

export const deleteAssignments = createEffect({
  handler: (ids: number[]): Promise<number[]> => {
    return new Promise((resolve) => {
      deleteLessonAssignmentsFx(ids).then(() => {
        resolve(ids)
      })
    })
  },
})

export const requestDeleteAssignments = attach({
  effect: requestDeleteLessonAssignmentsFx,
  mapParams: (payload: RequestDeleteAssignmentsParams): RequestDeleteAssignmentsParams => {
    return {
      assignments: payload.assignments,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const duplicateAssignment = attach({
  effect: updateLessonAssignmentBulkFx,
  source: $nDuplicate,
  mapParams: (id: number[], n: number): LessonAssignmentsBulkUpdate => ({
    assignments: id,
    number_of_duplicates: n,
  }),
})

export const canRefreshAfterDuplicateChanged = createEvent<boolean>()
export const $canRefreshAfterDuplicate = restore<boolean>(canRefreshAfterDuplicateChanged, false)

export const loadList = createEvent<GetAssignmentTreeQueryParams>()

forward({
  from: loadList,
  to: getTasksList,
})

forward({
  from: duplicateAssignment,
  to: canRefreshAfterDuplicateChanged.prepend(() => false),
})

forward({
  from: duplicateAssignment.doneData,
  to: [
    changedDuplicateModalVisibility.prepend(() => false),
    successToastEvent('Задание было успешно дублировано!'),
    canRefreshAfterDuplicateChanged.prepend(() => true),
    loadList.prepend(() => ({})),
  ],
})

export const deleteFolder = createEffect({
  handler: (id: number): Promise<number> => {
    return new Promise((resolve) => {
      deleteFolderFx(id).then(() => {
        resolve(id)
      })
    })
  },
})

export const requestDeleteFolder = attach({
  effect: requestDeleteFolderFx,
  mapParams: (payload: RequestDeleteFolderParams): RequestDeleteFolderParams => {
    return {
      assignment_folders: payload.assignment_folders,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const exportLessonAssignmentsTableFile = attach({
  effect: exportLessonAssignmentListFx,
  source: [lessonTasksFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportLessonAssignmentsTableFile.doneData.watch((res) =>
  fileDownload(res.body, 'LessonAssignmentsList.xlsx')
)

export const lessonTaskPageParams = createPageParamsModel()

export const loadTree = createEvent<FiltersParams>()
export const loadTreeLight = createEvent<void>()
export const loadFilteredTree = createEvent<FiltersParams>()
const rewriteTree = createEvent<TreeData[] | null>()
export const setLessonsTree = createEvent<TreeData[] | null>()
export const $lessonsTree = createStore<TreeData[] | null>(null)
  .on(setLessonsTree, (state, data) => mergeTreeData(state!, data!))
  .on(rewriteTree, (state, payload) => sortTreeLeaves(payload!))
export const setLessonsTreeTotal = createEvent<number>()
export const $lessonsTreeTotal = restore<number>(setLessonsTreeTotal, 0)

const showDeleteAssignmentsToast = createEvent<number[]>()
const showDeleteFolderToast = createEvent<number>()

export const $isLoading = combine(
  getFilteredTree.pending,
  getLessonAssignmentTreeLightFx.pending,
  getLessonAssignmentListFx.pending,
  (tree, light, list) => tree || light || list
)

forward({
  from: loadTreeLight,
  to: [getLessonsTreeLight, getLessonTreeInfo],
})

sample({
  source: lessonTasksFilters.store.$filterParams,
  clock: loadTree,
  fn: (filterParams, treeParams) => ({ ...filterParams, ...treeParams }),
  target: getLessonsTree,
})

forward({
  from: loadFilteredTree,
  to: getFilteredTree,
})

forward({
  from: getLessonInfoFx.doneData.map(({ body }) => body.total_amount),
  to: setLessonsTreeTotal,
})

forward({
  from: getLessonsTreeLight.doneData,
  to: rewriteTree.prepend(({ body }) => body.data),
})

forward({
  from: getFilteredTree.doneData,
  to: [
    rewriteTree.prepend(({ body }) => body.data),
    setLessonsTreeTotal.prepend(({ body }) => body.total),
  ],
})

forward({
  from: getLessonsTree.doneData,
  to: setLessonsTree.prepend(({ body }) => body.data),
})

forward({
  from: deleteAssignments.doneData,
  to: [
    loadTreeLight.prepend(() => ({})),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    showDeleteAssignmentsToast,
  ],
})

condition({
  source: showDeleteAssignmentsToast,
  if: (ids: number[]) => ids.length === 1,
  then: successToastEvent('Задание было успешно удалено!'),
  else: successToastEvent('Задания были успешно удалены!'),
})

forward({
  from: deleteFolder.doneData,
  to: [
    loadTreeLight.prepend(() => ({})),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    showDeleteFolderToast,
  ],
})

forward({
  from: showDeleteFolderToast,
  to: successToastEvent('Папка была успешно удалена!'),
})

forward({
  from: [requestDeleteAssignments.doneData, requestDeleteFolder.doneData],
  to: [
    successToastEvent('Отправлена заявка на удаление'),
    requestDeleteModalVisibilityChanged.prepend(() => false),
  ],
})

guard({
  clock: lessonTasksFilters.methods.resetFilters,
  filter: lessonTaskPageParams.store.treeView,
  target: loadTreeLight.prepend(() => ({})),
})

guard({
  clock: lessonTaskPageParams.store.treeView,
  source: lessonTasksFilters.store.$filterParams,
  filter: (filterParams, treeView) => treeView && !isObjectEmpty(filterParams),
  target: loadFilteredTree,
})

guard({
  clock: lessonTasksFilters.methods.applyFilters,
  filter: lessonTaskPageParams.store.treeView,
  source: lessonTasksFilters.store.$filterParams,
  target: loadFilteredTree,
})
