import { attach, createEvent, forward, combine, createStore } from 'effector-root'
import { getSubscribersListFx } from '@/features/api/callback/get-suscribers'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'

const getSubscribersList = attach({
  effect: getSubscribersListFx,
  mapParams: (params) => params,
})

export const subscriberPageMounted = createEvent<void>()

export const subscribersPageParams = createPageParamsModel()

export const $isLoading = combine(getSubscribersListFx.pending, (list) => list)

const needRefresh = createEvent<boolean>()

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(subscriberPageMounted)

forward({
  from: subscriberPageMounted,
  to: getSubscribersList,
})
