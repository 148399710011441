import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExamAssignment } from '@/features/api/assignment/types/exam-assignments-types'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'

export const getExamAssignmentListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<ExamAssignment[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/assignment-app/exam-assignment/',
    query,
  }),
})
