import { createEvent, forward, restore, sample, split } from 'effector-root'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { condition } from 'patronum'
import {
  $editedProctorId,
  changeProctorsId,
} from '@/pages/exams/proctors/create/model/edit-proctors.model'
import { $proctorSendForm } from '@/pages/exams/proctors/create/model/form-fileds.model'
import { createProctorsFx } from '@/features/api/proctors/post-new-proctors'
import { updateProctorsFx } from '@/features/api/proctors/patch-edit-proctor'

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  createProctorsFx.fail,
])

export const getPageParams = createEvent()

const createProctor = createEvent()
const updateProctor = createEvent<string>()

const proctorId = sample({
  source: $editedProctorId,
  clock: getPageParams,
  fn: (id) => (id.length > 0 ? id : false),
})

split({
  source: proctorId,
  match: {
    post: (payload) => payload === false,
    patch: (payload) => payload !== false,
  },
  cases: {
    post: createProctor,
    patch: updateProctor,
  },
})

sample({
  source: $proctorSendForm,
  clock: createProctor,
  fn: (form) => form,
  target: createProctorsFx,
})

sample({
  source: $proctorSendForm,
  clock: updateProctor,
  fn: (form, id) => ({ body: form, id }),
  target: updateProctorsFx,
})

forward({
  from: createProctorsFx.done,
  to: successToastEvent('Проктор создан'),
})

forward({
  from: updateProctorsFx.done,
  to: successToastEvent('Проктор изменен'),
})

forward({
  from: [createProctorsFx.fail, updateProctorsFx.fail],
  to: errorToastEvent('Что-то пошло не так'),
})

const $redirectHandler = sample({
  clock: [
    createProctorsFx.doneData.map((res) => res.body.id),
    updateProctorsFx.doneData.map((res) => res.body.id),
  ],
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'proctors-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => {
    changeProctorsId(String(payload.id))
    return {
      name: 'proctors-edit',
      params: { id: `${payload.id}` },
    }
  }),
})
