
































































































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import { $token } from '@/features/api/common/request'
import PageHeader from '@/pages/exams/full-time-exam-test/list/parts/PageHeader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import {
  loadTree,
  $examsTreeTotal,
  requestDeleteExamTests,
  examTestsPageParams,
  loadTreeLight,
  $isLoading,
  deleteFolder,
  requestDeleteFolder,
  exportFullTimeExamTestsList,
} from '@/pages/exams/full-time-exam-test/list/parts/exam-tests-page.model'
import {
  toggleVisibility,
  $visibility,
  examTestsFilters,
} from '@/pages/exams/full-time-exam-test/list/parts/exam-tests-filter.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  fullTimeExamTestTableFields,
  searchFieldsData,
  getExamTestsActions,
} from '@/pages/exams/full-time-exam-test/list/constants'
import { DEFAULT_ID } from '@/pages/common/constants'
import {
  CommonInteractedItemParams,
  ActionsItem,
  DisplayContextMenuPayload,
  RefsType,
} from '@/pages/common/types'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import {
  loadConfirmDeleteModal,
  $deleteType,
  setDeleteType,
} from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { loadRequestDeleteModal } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { $currentUser, $permissions } from '@/features/session'
import TableColumns from '@/pages/common/parts/table-columns/TableColumns.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { examTestsFolderModal } from '@/pages/common/modals/tasks-bank/creating-folder/creating-folder-modal.model'
import { examTestsEditFolderModal } from '@/pages/common/modals/tasks-bank/editing-folder/editing-folder-modal.model'
import CreatingExamTestFolderModal from '@/pages/common/modals/tasks-bank/creating-folder/CreatingExamTestFolderModal.vue'
import EditingExamTestFolderModal from '@/pages/common/modals/tasks-bank/editing-folder/EditingExamTestFolderModal.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import { clearTreeStates } from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { getActionsDisplayConditions } from '@/pages/common'
import { axiosClient } from '@/lib/request'
import ExamTestTree from '@/pages/exams/full-time-exam-test/list/parts/ExamTestTree.vue'
import ExamsTestsFilter from '@/pages/exams/full-time-exam-test/list/parts/ExamTestsFilter.vue'
import { SortFieldsType } from '@/pages/results/types'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'FullTimeExamTestsListPage',
  components: {
    ExamsTestsFilter,
    ExamTestTree,
    Vuetable,
    VuetablePagination,
    PageHeader,
    TableHeader,
    GeneralFilter,
    TooltipCell,
    ActionsButton,
    ContextMenu,
    NoDataContent,
    ConfirmDeleteModal,
    RequestDeleteModal,
    LoaderBig,
    TableColumns,
    CreatingExamTestFolderModal,
    EditingExamTestFolderModal,
  },
  effector: {
    $token,
    $visibility,
    $examsTreeTotal,
    $filterParams: examTestsFilters.store.$filterParams,
    $pageParams: examTestsPageParams.store.$pageParams,
    $treeView: examTestsPageParams.store.treeView,
    $currentPage: examTestsPageParams.store.currentPage,
    $isLoading,
    $deleteType,
    $currentUser,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      fields: fullTimeExamTestTableFields,
      searchFields: searchFieldsData,
      total: 0,
      selectedRows: [] as number[],
      sort: [] as SortFieldsType[],
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/exams-app/tests/`
    },
    canRemoveTask(): boolean {
      const hasPermissionToRemove = this.$permissions!.exams_examtest_exams.can_edit
      return hasPermissionToRemove && this.$currentUser!.role !== 'METHODIST'
    },
    selectedIds(): number[] {
      if (this.selectedRows.length && !this.$treeView) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getExamTestsActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.removeSelection()
      },
    },
  },
  methods: {
    changeFilter: examTestsFilters.methods.changeFilter,
    resetFilters: examTestsFilters.methods.resetFilters,
    applyFilters: examTestsFilters.methods.applyFilters,
    toggleTreeView: examTestsPageParams.methods.toggleTreeView,
    changePage: examTestsPageParams.methods.changePage,
    queryToParams: examTestsPageParams.methods.queryToParams,
    toggleVisibility,
    loadTree,
    exportFullTimeExamTestsList,
    headerApiCall(sort: SortFieldsType) {
      this.sort = [{ ...sort }]
      this.$refs.vuetable.loadData()
    },
    async myFetch(apiUrl: string, httpOptions: any) {
      const sortParams = this.sort[0] ? `${this.sort[0].sortField}|${this.sort[0].direction}` : ''
      const request = axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(sortParams) },
      })
      return request
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      clearTreeStates()
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onEdit() {
      const routeData = this.$router.resolve({
        name: 'full-exam-test-edit',
        params: { id: `${this.selectedIds[0]}` },
      })
      window.open(routeData.href, '_blank')
    },
    onRemove() {
      setDeleteType('exams')
      this.canRemoveTask
        ? loadConfirmDeleteModal(this.selectedIds)
        : loadRequestDeleteModal(this.selectedIds)
    },
    async removeSelected(ids: number[]) {
      if (this.$deleteType === 'folder') {
        await deleteFolder(ids[0])
      } else {
        await requestDeleteExamTests({ assignments: ids })
      }
      await Vue.nextTick(() => this.$refs.vuetable.reload())
      this.removeSelection()
    },
    async sendRequestDelete(comment: string, ids: number[]) {
      if (this.$deleteType === 'folder') {
        await requestDeleteFolder({ assignment_folders: ids, ticket_comment: comment })
      } else {
        await requestDeleteExamTests({ assignments: ids, ticket_comment: comment })
      }
      this.removeSelection()
    },
    createFolder() {
      examTestsFolderModal.methods.loadFolder(this.selectedIds[0])
      examTestsFolderModal.methods.modalVisibilityChanged(true)
    },
    editFolder() {
      examTestsEditFolderModal.methods.loadFolder(this.selectedIds[0])
      examTestsEditFolderModal.methods.modalVisibilityChanged(true)
    },
    deleteFolder() {
      setDeleteType('folder')
      this.$permissions!.subjects_theme_themes.can_edit
        ? loadConfirmDeleteModal(this.selectedIds)
        : loadRequestDeleteModal(this.selectedIds)
    },
    createTask() {
      examTestsFolderModal.methods.loadFolder(this.selectedIds[0])
      this.$router.push({ name: 'exam-tasks-create' })
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length,
        this.$treeView
      )
      this.contextMenuItems = getExamTestsActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
      event.preventDefault()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      // if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      // else this.selectedRows = []
      this.$refs.vuetable.onCheckboxToggledAll(isSelected)
      if (isSelected) {
        this.selectedRows = this.$refs.vuetable.selectedTo
      } else {
        this.selectedRows = []
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!/create/g.test(from.path) && !/edit/g.test(from.path) && !/preview/g.test(from.path)) {
      clearTreeStates()
    }
    next()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  mounted() {
    loadTreeLight()
  },
  destroyed() {
    this.resetFilters()
  },
})
