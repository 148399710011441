import { render, staticRenderFns } from "./ColorHighlightAnswer.vue?vue&type=template&id=d04c2e58&scoped=true&"
import script from "./ColorHighlightAnswer.vue?vue&type=script&lang=ts&"
export * from "./ColorHighlightAnswer.vue?vue&type=script&lang=ts&"
import style0 from "./ColorHighlightAnswer.vue?vue&type=style&index=0&id=d04c2e58&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d04c2e58",
  null
  
)

export default component.exports