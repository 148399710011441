import TypeDropdown from '@/pages/common/dropdowns/bank/lesson-tasks/type-dropdown/TypeDropdown.vue'
import LanguageDropdown from '@/pages/common/dropdowns/bank/test-tasks/language-dropdown/LanguageDropdown.vue'
import SubjectFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/SubjectFilterDropdown.vue'
// import PositionDropdown from '@/pages/common/dropdowns/bank/lesson-tasks/position-dropdown/PositionDropdown.vue'
import FolderDropdown from '@/pages/common/dropdowns/bank/exam-tasks/folder-dropdown/FolderDropdown.vue'

export const dropdownComponents = {
  TypeDropdown,
  LanguageDropdown,
  SubjectFilterDropdown,
  FolderDropdown,
}
