import { attach } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { dropdownModules } from '@/pages/users/students/list/model/tooltip-filter.model'
import { exportStudentsListFx } from '@/features/api/student/export-students-list'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { createTaskModule } from '@/features/api/tasks/tasks-module'

export const studentsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownModules
)

export const studentsPageParams = createPageParamsModel()

const exportStudentsList = attach({
  effect: exportStudentsListFx,
  source: [studentsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

export const taskLoader = createTaskModule(exportStudentsList, 'studentsList')
