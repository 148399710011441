import { createApiEffect } from '@/features/api/common/create-api-effect'

export const requestDeleteExamsTestFx = createApiEffect<
  {
    tests: number[]
    ticket_comment?: string
  },
  {
    tests: number[]
    ticket_comment?: string
  }
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/exams-app/tests/bulk-delete/',
    body,
  }),
})
