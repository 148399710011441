













import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  loadCertCreators,
  certCreatorsDropdownModel,
  setSelectedCreator,
  $certCreators,
} from '@/pages/common/dropdowns/cert-users/cert-creators-dropdown/cert-creators-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...certCreatorsDropdownModel.store,
    $certCreators,
  },
  methods: {
    ...certCreatorsDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedCreator(item)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    loadCertCreators()
  },
})
