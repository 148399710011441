import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { LessonFilter } from '@/features/api/learning/types'

export const getFilterLessonsFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<LessonFilter[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/lesson-app/lessons/filter/names/',
    query,
  }),
})
