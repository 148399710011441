import { createApiEffect } from '@/features/api/common/create-api-effect'
import { AttemptsList } from '@/features/api/attempts/types'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'

export const FullAttemptsListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<AttemptsList[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/attempts/filter/names/',
    query,
  }),
})
