import { createEvent, forward, restore, attach, combine } from 'effector-root'
import { uploadMediaFx } from '@/features/api/media/upload-media'
import { getRandomId } from '@/pages/common/parts/tasks/utils'
import {
  MultipleShortClosedQuestion,
  MultipleShortClosedType,
} from '@/pages/common/parts/tasks/types'
import { $audioFiles, getAudioFilesFx } from '@/pages/common/parts/audio-files/audio-files.model'
import { BaseAssignment } from '@/features/api/assignment/types/types'

export const uploadMedia = attach({
  effect: uploadMediaFx,
})

export const clearFields = createEvent<void>()

export const setWording = createEvent<string>()
export const $wording = restore(setWording, '').reset(clearFields)

export const setContaining = createEvent<string>()
export const $containing = restore(setContaining, '').reset(clearFields)

export const setAnswerExample = createEvent<string>()
export const $answerExample = restore(setAnswerExample, '').reset(clearFields)

export const setQuestionsAnswers = createEvent<MultipleShortClosedType[]>()
export const $questionsAnswers = restore(setQuestionsAnswers, [
  { id: getRandomId(), question: '', answers: [''], score: 1 },
]).reset(clearFields)

export const toggleMarksEnabling = createEvent<boolean>()
export const $marksEnabled = restore(toggleMarksEnabling, false).reset(clearFields)

export const $isFilled = combine(
  $wording,
  $questionsAnswers,
  (wording, questionsAnswers) =>
    wording &&
    questionsAnswers.length &&
    questionsAnswers.every((qa) => qa.question && qa.answers.every((answer) => answer)) &&
    questionsAnswers.filter((qa) => qa.score).every((qa) => +qa.score! > 0)
)

export const $form = combine(
  $wording,
  $answerExample,
  $containing,
  $questionsAnswers,
  $audioFiles,
  $marksEnabled,
  (wording, example_answer, containing, questionsAnswers, audio, marks) => ({
    wording,
    example_answer,
    text: containing,
    question_data: questionsAnswers.map(({ question, score }) => ({
      question,
      score,
    })),
    correct_answer: questionsAnswers.map(({ answers }, id) => ({
      answers,
      question: questionsAnswers[id].question,
    })),
    common_list_text_answer: null,
    audio: audio.map(({ id, isLimited, limit }) => ({
      id,
      isLimited,
      limit,
    })),
    use_internal_scores: marks,
  })
)

export const initAssignment = createEvent<BaseAssignment>()

forward({
  from: initAssignment,
  to: [
    setWording.prepend((data) => data.wording || ''),
    setContaining.prepend((data) => data.text || ''),
    setAnswerExample.prepend((data) => data.example_answer || ''),
    toggleMarksEnabling.prepend((data) => data.use_internal_scores),
    setQuestionsAnswers.prepend((data): MultipleShortClosedType[] => {
      return data.question_data.map((question: MultipleShortClosedQuestion, id: number) => {
        const { answers } = data.correct_answer[id]
        return {
          id,
          ...question,
          answers,
        }
      })
    }),
    getAudioFilesFx.prepend(({ audios }) => audios),
  ],
})
