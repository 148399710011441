import NewsPage from '@/pages/content/news/list/NewsPage.vue'
import EditNewsPage from '@/pages/content/news/edit/EditNewsPage.vue'
import FAQPage from '@/pages/content/faq/list/FAQPage.vue'
import EditQuestionPage from '@/pages/content/faq/edit/EditQuestionPage.vue'
// slides
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import SlidesPage from '@/pages/content/onboarding/list/SlidesPage.vue'
import SlideCreationPage from '@/pages/content/onboarding/create/SlideCreationPage.vue'
import SlideEditionPage from '@/pages/content/onboarding/edit/SlideEditionPage.vue'
// banners
import BannersPage from '@/pages/content/banners/list/BannersPage.vue'
import BannerCreationPage from '@/pages/content/banners/create/BannerCreationPage.vue'
import BannerEditionPage from '@/pages/content/banners/edit/BannerEditionPage.vue'

export default [
  {
    name: 'content',
    path: '/content',
    redirect: { name: 'slides-list' },
    component: DefaultLayout,
    children: [
      {
        name: 'news',
        path: 'news',
        meta: {
          title: 'Новости - Школа Летово',
        },
        redirect: { name: 'news-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'news-list',
            path: 'news-list',
            component: NewsPage,
            meta: {
              title: 'Новости - Школа Летово',
            },
          },
          {
            name: 'create-news',
            path: 'create-news',
            component: EditNewsPage,
            meta: {
              title: 'Создание новости - Школа Летово',
            },
          },
          {
            name: 'edit-news',
            path: 'create-news/:id',
            component: EditNewsPage,
            meta: {
              title: 'Редактирование новости - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'faq',
        path: 'faq',
        meta: {
          title: 'FAQ - Школа Летово',
        },
        redirect: { name: 'faq-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'faq-list',
            path: 'faq-list',
            component: FAQPage,
            meta: {
              title: 'FAQ - Школа Летово',
            },
          },
          {
            name: 'create-question',
            path: 'create-question',
            component: EditQuestionPage,
            meta: {
              title: 'Создание вопроса - Школа Летово',
            },
          },
          {
            name: 'edit-question',
            path: 'create-question/:id',
            component: EditQuestionPage,
            meta: {
              title: 'Редактирование вопроса - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'onboarding',
        path: 'onboarding',
        meta: {
          title: 'Слайдеры - Школа Летово',
        },
        redirect: { name: 'slides-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'slides-list',
            path: 'list',
            component: SlidesPage,
            meta: {
              title: 'Слайдеры - Школа Летово',
            },
          },
          {
            name: 'slides-create',
            path: 'create',
            component: SlideCreationPage,
            meta: {
              title: 'Создание слайдера - Школа Летово',
            },
          },
          {
            name: 'slides-edit',
            path: 'create/:id',
            component: SlideEditionPage,
            meta: {
              title: 'Редактирование слайдера - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'banners',
        path: 'banners',
        meta: {
          title: 'Баннер - Школа Летово',
        },
        redirect: { name: 'banners-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'banners-list',
            path: 'list',
            component: BannersPage,
            meta: {
              title: 'Баннер - Школа Летово',
            },
          },
          {
            name: 'banners-create',
            path: 'create',
            component: BannerCreationPage,
            meta: {
              title: 'Создание баннера - Школа Летово',
            },
          },
          {
            name: 'banners-edit',
            path: 'create/:id',
            component: BannerEditionPage,
            meta: {
              title: 'Редактирование баннера - Школа Летово',
            },
          },
        ],
      },
    ],
  },
]
