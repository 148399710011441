import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { attach, createEvent, createStore, forward, guard, restore, sample } from 'effector-root'
import {
  $selectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { findItem } from '@/pages/common/filter-dropdown/lib'
import { getThemesTreeListFx } from '@/features/api/subject/get-themes-tree-list'
import { formatData } from '@/features/lib'

const getThemesTreeList = attach({
  effect: getThemesTreeListFx,
})

export const themeDropdownModule = createFilter()

export const $themes = createStore<DropdownItem[]>([])

export const setSelectedThemes = createEvent<DropdownItem[]>()
export const $selectedThemes = restore<DropdownItem[]>(setSelectedThemes, [])
export const resetSelectedThemes = createEvent()
export const deleteTheme = createEvent<string>()

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

guard({
  clock: subjectsDropdownModel.methods.itemChanged,
  source: $selectedSubject,
  filter: $isComponentMounted,
  target: [
    themeDropdownModule.methods.resetDropdown,
    resetSelectedThemes,
    getThemesTreeList.prepend((subject: DropdownItem | null) => ({
      subject: +subject?.name! || undefined,
      is_not_prerequisite: true,
    })),
  ],
})

forward({
  from: getThemesTreeList.doneData.map(({ body }) => formatData(body)),
  to: $themes,
})

sample({
  source: {
    selected: $selectedThemes,
    all: themeDropdownModule.store.$itemsDropdown,
  },
  clock: themeDropdownModule.methods.itemChanged,
  fn: (list, element) => {
    const elementId = element?.name
    if (!list.all.length) return [...list.selected]
    const arr = list.selected.slice()
    if (arr.find((el) => el.id === +elementId!) === undefined) {
      const elem = findItem(elementId!, list.all)
      if (elem) arr.push(elem!)
    }
    return [...arr]
  },
  target: $selectedThemes,
})

sample({
  source: $selectedThemes,
  clock: resetSelectedThemes,
  fn: () => [],
  target: $selectedThemes,
})

forward({
  from: deleteTheme,
  to: themeDropdownModule.methods.resetItem,
})

sample({
  source: $selectedThemes,
  clock: deleteTheme,
  fn: (list, id: string) => list.filter((el: any) => el.name !== id),
  target: $selectedThemes,
})

sample({
  source: $selectedThemes,
  clock: deleteTheme,
  fn: (selected) => (selected.length > 0 ? selected[0] : null),
  target: themeDropdownModule.methods.itemChanged,
})

forward({
  from: subjectsDropdownModel.methods.itemChanged,
  to: [themeDropdownModule.methods.resetDropdown, resetSelectedThemes],
})
