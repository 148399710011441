import { createEvent, createStore, guard } from 'effector-root'
import { getProctorIdFx } from '@/features/api/proctors/get-proctor-dy-id'
import { resetFields } from '@/pages/exams/proctors/create/model/form-fileds.model'

export const changeProctorsId = createEvent<string | never>()

export const $editedProctorId = createStore<string>('')
  .on(changeProctorsId, (state, payload) => payload || state)
  .reset(resetFields)

guard({
  source: changeProctorsId,
  filter: (payload) => !!payload,
  target: getProctorIdFx,
})
