import { createEvent, restore } from 'effector'
import { attach, combine, forward, sample } from 'effector-root'
import { getCourseApplicationsFx } from '@/features/api/learning/course-applications/get-course-applications'
import { statusDropdownModule } from '@/pages/common/dropdowns/courses/status-dropdown/status-dropdown.model'
import {
  CourseRelated,
  Question,
  SendCourseApplications,
  StudentRelated,
} from '@/features/api/learning/types'
import { setStatusField } from '@/pages/learning/learning-courses-application/constants'
import { ApplicationsCourseStatus } from '@/pages/learning/learning-courses-application/types'
import { updateApplicationsCoursesFx } from '@/features/api/learning/course-applications/update-course-applications'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { successToastEvent } from '@/features/toasts/toasts.model'

const getCourseApplications = attach({
  effect: getCourseApplicationsFx,
})

export const save = createEvent<void>()

const sendCourseApplications = attach({
  effect: updateApplicationsCoursesFx,
})

export const $isLoadingApplicationsCourse = combine(getCourseApplications.pending, (load) => load)

export const $isSaveApplicationsCourse = combine(sendCourseApplications.pending, (update) => update)

export const clearFieldsApplicationsCourseEdit = createEvent<void>()

export const loadApplicationsCourse = createEvent<number>()
export const $applicationsCourseId = restore(loadApplicationsCourse, 0).reset(
  clearFieldsApplicationsCourseEdit
)

export const setStudentInfo = createEvent<StudentRelated>()
export const $studentInfo = restore<StudentRelated>(setStudentInfo, {
  email: '',
  first_name: '',
  id: 0,
  last_name: '',
  study_year: null,
  status: null,
  group: null,
}).reset(clearFieldsApplicationsCourseEdit)

export const setCourseInfo = createEvent<CourseRelated>()
export const $courseInfo = restore<CourseRelated>(setCourseInfo, {
  name: '',
  number: 0,
  id: 0,
}).reset(clearFieldsApplicationsCourseEdit)

export const setRedirect = createEvent()

export const changeIfRedirect = createEvent<boolean>()
export const $ifRedirect = restore(changeIfRedirect, false).reset(clearFieldsApplicationsCourseEdit)

export const applicationsCourseCommentChanged = createEvent<string>()
export const $applicationsCourseComment = restore<string>(
  applicationsCourseCommentChanged,
  ''
).reset(clearFieldsApplicationsCourseEdit)

export const lessonAccessDtChanged = createEvent<Date | null>()
export const $lessonAccessDt = restore<Date | null>(lessonAccessDtChanged, null).reset(
  clearFieldsApplicationsCourseEdit
)

export const setQuestions = createEvent<Question>()
export const $questions = restore<Question>(setQuestions, {
  answers: [],
  questions: [],
}).reset(clearFieldsApplicationsCourseEdit)

export const setStatus = createEvent<ApplicationsCourseStatus | null>()
export const $status = restore<ApplicationsCourseStatus | null>(setStatus, null).reset(
  clearFieldsApplicationsCourseEdit
)

export const $isDisabledAccessDtChanged = statusDropdownModule.store.$item.map((statusApp) =>
  statusApp ? statusApp.name !== 'suspended' : true
)

export const $canSaveCourse = combine(
  $applicationsCourseId,
  $lessonAccessDt,
  $status,
  (applicationsCourseId, lessonAccessDt, status) => {
    const statusIsSuspended = status && status === ApplicationsCourseStatus.suspended
    return !applicationsCourseId || !status || (statusIsSuspended && !lessonAccessDt)
  }
)

const $baseFormCourses = combine(
  $applicationsCourseId,
  $applicationsCourseComment,
  $lessonAccessDt,
  $status,
  (id, comment, lesson_access_dt, status): SendCourseApplications => ({
    id,
    comment,
    lesson_access_dt,
    ...(status ? { status } : {}),
  })
)

forward({
  from: loadApplicationsCourse,
  to: getCourseApplications,
})

forward({
  from: getCourseApplications.doneData.map((res) => res.body),
  to: [
    applicationsCourseCommentChanged.prepend((data) => (data.comment ? data.comment : '')),
    setStudentInfo.prepend((data) => {
      return {
        ...data.created_by,
        status: data.status,
        group: data.group ? data.group.name : null,
      }
    }),
    setCourseInfo.prepend((data) => data.course),
    lessonAccessDtChanged.prepend((data) => {
      if (data.lesson_access_dt) {
        return new Date(data.lesson_access_dt)
      }
      return null
    }),
    setQuestions.prepend((data) =>
      data.questions
        ? data.questions
        : {
            answers: [],
            questions: [],
          }
    ),
    statusDropdownModule.methods.itemChanged.prepend((data) => setStatusField(data.status)),
    setStatus.prepend((data) => data.status),
    setCourseInfo.prepend((data) => data.course),
  ],
})

sample({
  source: $baseFormCourses,
  clock: save,
  target: sendCourseApplications,
})

sample({
  source: $ifRedirect,
  clock: sendCourseApplications.doneData,
  fn: (ifRedirect: boolean) => {
    ifRedirect && navigatePush({ name: 'courses-application-list' })
  },
})

forward({
  from: sendCourseApplications.doneData,
  to: successToastEvent('Заявка на курс успешно обновлена!'),
})

forward({
  from: clearFieldsApplicationsCourseEdit,
  to: statusDropdownModule.methods.resetItem,
})
