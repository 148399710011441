import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import { GetStudentsListQueryParams, StudentTestResultList } from '@/features/api/student/types'

export const getStudentsForTestsListFx = createApiEffect<
  GetStudentsListQueryParams,
  TableDataResponse<StudentTestResultList[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/results-app/results/test/students/list/',
    query,
  }),
})
