






























import Vue from 'vue'
import TaskHeader from '@/pages/bank/common/parts/Header.vue'
import TaskContent from '@/pages/bank/olympiad-tasks/edit/parts/Content.vue'
import TaskFooter from '@/pages/bank/common/parts/Footer.vue'
import {
  save,
  $canSave,
  clearFields,
  loadTask,
  setRedirectAfterSave,
  $isPreview,
  toggleIsPreview,
  $taskId,
} from '@/pages/bank/olympiad-tasks/edit/task-edition-page.model'
import { $token } from '@/features/api/common/request'
import SelectTask from '@/pages/preview-tasks/parts/select-task/SelectTask.vue'
import {
  $currentIndex,
  $currentQuestion,
  setCurrentQuestion,
} from '@/pages/preview-tasks/parts/select-task/select-task.model'
import { combineRouteQueries } from '@/features/lib'
import { FromPage } from '@/pages/common/types'
import { navigateReplace } from '@/features/navigation/navigationMethods'
import { updateOlympiadAssignmentFx } from '@/features/api/assignment/olympiad-assignment/update-olympiad-assignment'
import { DEFAULT_ID } from '@/pages/common/constants'
import {
  $tagsLoaded,
  toggleTagsLoaded,
} from '@/pages/common/dropdowns/multiselectDropdown/tags-dropdown-filter/tags-filter-dropdown.model'

export default Vue.extend({
  name: 'TaskEditionPage',
  components: {
    TaskHeader,
    TaskContent,
    TaskFooter,
    SelectTask,
  },
  effector: {
    $canSave,
    $isPreview,
    $taskId,
    $token,
    $currentIndex,
    $currentQuestion,
    $tagsLoaded,
    isLoading: updateOlympiadAssignmentFx.pending,
  },
  data() {
    return {
      questions: [] as string[],
      fromPage: '' as FromPage,
    }
  },
  watch: {
    $isPreview: {
      handler(newVal) {
        if (newVal) {
          navigateReplace({
            name: 'preview-task',
            query: {
              questions: this.questions.length ? this.questions.join(',') : this.$route.params.id,
              fromPage: this.fromPage,
              taskType: 'olympiad-assignment',
              currentQuestion: String(this.$currentQuestion),
              token: this.$token,
            },
          })
        }
      },
    },
    $currentIndex: {
      handler(newVal) {
        !!this.questions.length && newVal !== DEFAULT_ID && loadTask(+this.questions[newVal])
      },
    },
  },
  methods: {
    toggleIsPreview,
    saveTask(isRedirect: boolean) {
      save()
      if (isRedirect) setRedirectAfterSave(true)
    },
    goToSelectedTask() {
      if (this.$route.query.currentQuestion !== String(this.$currentQuestion)) {
        toggleTagsLoaded(false)
        this.$router.replace(
          combineRouteQueries(this.$route.query, { currentQuestion: String(this.$currentQuestion) })
        )
      }
    },
  },
  created() {
    const { questions, fromPage, currentQuestion } = this.$route.query

    if (questions && typeof questions === 'string') {
      this.questions = questions.split(',')
    }
    if (fromPage && (fromPage === 'applications' || fromPage === 'tasks' || fromPage === 'tags')) {
      this.fromPage = fromPage
    }
    if (currentQuestion && typeof +currentQuestion === 'number') {
      setCurrentQuestion(+currentQuestion)
    }
    if (this.questions.length && this.$currentIndex !== DEFAULT_ID) {
      loadTask(+this.questions[this.$currentIndex])
    } else if (+this.$route.params.id) {
      loadTask(+this.$route.params.id)
    }
  },
  beforeMount() {
    if (this.$route.query.togglePreview === 'toggle') {
      toggleIsPreview(true)
    }
  },
  beforeDestroy() {
    clearFields()
    toggleIsPreview(false)
  },
})
