import { combine, createEvent, forward, restore } from 'effector-root'
import { spread } from 'patronum'
import { getAppointmentIdFx } from '@/features/api/appointments/get-appointment-dy-id'
import { proctorsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/proctors-filter-dropdown.model'
import { GetProctorsType } from '@/features/api/proctors/types'
import { testsDropdownModel } from '@/pages/common/dropdowns/exams/exams-tests/exams-test-list-dropdown.model'
import { Test } from '@/features/api/test/types'
import {
  AttemptStudentAppointmentsData,
  StudentAppointmentsDataMinimal,
} from '@/pages/exams/appointments/create/model/types'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { Groups } from '@/features/api/learning/types'

export const resetFields = createEvent()
export const resetFiltersFields = createEvent()

export const setNameCmsCommentary = createEvent<string>()
export const $nameCmsCommentary = restore(setNameCmsCommentary, '').reset(resetFields)

export const setAppointmentsCmsCommentary = createEvent<string>()
export const $appointmentsCmsCommentary = restore(setAppointmentsCmsCommentary, '').reset(
  resetFields
)

export const setAppointmentsIsProctoringActivated = createEvent<boolean>()
export const $appointmentsIsProctoringActivated = restore(
  setAppointmentsIsProctoringActivated,
  true
).reset(resetFields)

export const setAppointmentsAvailableFrom = createEvent<string>()
export const $appointmentsAvailableFrom = restore(setAppointmentsAvailableFrom, '').reset(
  resetFields
)

export const setAppointmentsAvailableTo = createEvent<string>()
export const $appointmentsAvailableTo = restore(setAppointmentsAvailableTo, '').reset(resetFields)

// step3
type StudentsForForAppointments = {
  [key: number]: StudentAppointmentsDataMinimal
}
export const changeStudentsForAppointments = createEvent<StudentsForForAppointments>()
export const $studentsForAppointments = restore<StudentsForForAppointments>(
  changeStudentsForAppointments,
  {}
).reset(resetFields)

export const $studentsForAppointmentsCount = $studentsForAppointments.map(
  (data) => Object.keys(data).length
)

export const $studentsForAppointmentsIds = $studentsForAppointments.map((data) =>
  Object.values(data).map((student) => student.id)
)

export const $disabledSaveButtons = combine(
  $nameCmsCommentary,
  $appointmentsIsProctoringActivated,
  proctorsDropdownModel.store.$selectedItems,
  $studentsForAppointmentsIds,
  (name, is_proctoring_activated, proctors, students_ids) => {
    const proctor = is_proctoring_activated ? proctors.length === 0 : false
    return !name || proctor || students_ids.length === 0
  }
)

export const $appointmentSendForm = combine(
  $nameCmsCommentary,
  $appointmentsCmsCommentary,
  $appointmentsIsProctoringActivated,
  proctorsDropdownModel.store.$selectedItems,
  testsDropdownModel.store.$item,
  $studentsForAppointmentsIds,
  classesDropdownModule.store.$item,
  $appointmentsAvailableFrom,
  $appointmentsAvailableTo,
  groupsDropdownModel.store.$selectedItems,
  (
    cms_name,
    cms_commentary,
    is_proctoring_activated,
    proctors,
    test,
    students_ids,
    study_year,
    available_from,
    available_to,
    groups
  ) => {
    return {
      cms_name,
      cms_commentary,
      is_proctoring_activated,
      proctors_ids: is_proctoring_activated ? proctors.map((proctor) => Number(proctor.name)) : [],
      test_id: Number(test?.name) || 0,
      students_ids,
      ...(study_year ? { study_year_id: study_year.name } : {}),
      ...(available_from ? { available_from } : {}),
      ...(available_to ? { available_to } : {}),
      ...(groups ? { groups_ids: groups.map((group) => Number(group.name)) } : {}),
    }
  }
)

spread({
  source: getAppointmentIdFx.doneData.map((res) => ({
    ...res.body,
    cms_name: res.body.cms_name,
    cms_commentary: res.body.cms_commentary,
    is_proctoring_activated: res.body.is_proctoring_activated,
    proctors: res.body.proctors,
    test: res.body.test,
    study_year: res.body.study_year,
    attempts: res.body.attempts,
    groups: res.body.groups,
    available_from: res.body.available_from ? new Date(res.body.available_from) : '',
    available_to: res.body.available_to ? new Date(res.body.available_to) : '',
  })),
  targets: {
    cms_name: $nameCmsCommentary,
    cms_commentary: $appointmentsCmsCommentary,
    is_proctoring_activated: $appointmentsIsProctoringActivated,
    proctors: proctorsDropdownModel.methods.setSelectedItems.prepend(
      (payload: GetProctorsType[] | null) => {
        if (payload)
          return payload.map((proctor) => ({
            name: `${proctor.id}`,
            title: proctor.service,
          }))
        return []
      }
    ),
    test: testsDropdownModel.methods.itemChanged.prepend((payload: Test | null) => {
      if (payload)
        return {
          name: `${payload.id}`,
          title: `(${payload.id}) ${payload.name}`,
        }
      return {
        name: ``,
        title: '',
      }
    }),
    study_year: classesDropdownModule.methods.itemChanged.prepend(
      (payload: { id: number; name: string } | null) => {
        if (payload)
          return {
            name: `${payload.id}`,
            title: payload.name,
          }
        return null
      }
    ),
    groups: groupsDropdownModel.methods.setSelectedItems.prepend((payload: Groups[]) => {
      if (payload)
        return payload.map((group: Groups) => ({
          name: `${group.id}`,
          title: group.name,
        }))
      return []
    }),
    available_from: $appointmentsAvailableFrom,
    available_to: $appointmentsAvailableTo,
    attempts: [
      changeStudentsForAppointments.prepend((data: AttemptStudentAppointmentsData[]) => {
        return data.reduce((students, student) => {
          students[student.id] = {
            email: student.email,
            fullname: `${student.first_name} ${student.last_name}`,
            id: student.id,
            study_year: student?.study_year?.name || '',
          }
          return students
        }, {})
      }),
    ],
  },
})

forward({
  from: resetFields,
  to: [
    proctorsDropdownModel.methods.resetDropdown,
    testsDropdownModel.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    groupsDropdownModel.methods.resetDropdown,
  ],
})

forward({
  from: resetFiltersFields,
  to: [classesDropdownModule.methods.resetDropdown, groupsDropdownModel.methods.resetDropdown],
})
