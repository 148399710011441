import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { attach, createEvent, createStore, forward, guard } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { getUserStudyYearsListFx } from '@/features/api/study_year/get-user-study-years-list'

export const classesDropdownModel = createFilter()

export const loadClasses = createEvent<void>()

export const setSelectedClasses = createEvent<DropdownItem>()

export const deleteSelectedClass = createEvent<DropdownItem>()

const getClasses = attach({
  effect: getUserStudyYearsListFx,
  mapParams: () => ({}),
})

forward({
  from: loadClasses,
  to: getClasses,
})

export const $classes = createStore<DropdownItem[]>([]).on(getClasses.doneData, (_, res) => [
  ...res.body.data.map((payload) => ({ name: `${payload.id}`, title: payload.name })),
  { name: 'all', title: 'Все' },
])

export const $selectedClasses = createStore<DropdownItem[]>([])
  .on(setSelectedClasses, (state, payload) => [...state, payload])
  .on(deleteSelectedClass, (state, payload) =>
    state.filter((element) => element.name !== payload.name)
  )
  .reset([classesDropdownModel.methods.resetDropdown])

export const unselectAllClasses = createEvent<void>()

export const selectAllClasses = createEvent<void>()

export const $isSelectedAllClasses = createStore<boolean>(false)
  .on(unselectAllClasses, () => false)
  .on(selectAllClasses, () => true)

guard({
  clock: setSelectedClasses,
  filter: (payload) => payload?.name !== 'all',
  target: unselectAllClasses,
})
