






























































































import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FormInput from '@/ui/input/FormInput.vue'
import {
  $mainBlocks,
  addMainBlock,
  deleteMainBlock,
  changeMainBlock,
} from '@/pages/exams/full-time-exam-test/create/model/form-fields.model'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import Icon from '@/ui/icon/Icon.vue'
import ExamTestPage from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/exam-pages/ExamTestPages.vue'
// import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
// import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import TasksChoiceDropdown from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/TasksChoiceDropdown.vue'
import ExamDurationDropdown from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/exam-duration-dropdown/ExamDurationDropdown.vue'
import { UnitType } from '@/features/api/full-time-tests/types'
import { ElementTypeNames } from '@/pages/learning/learning-lessons/create/model/types'
import { examDurationDropdownModel } from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/exam-duration-dropdown/exam-duration-dropdown.model'

export default Vue.extend({
  name: 'MainTab',
  components: {
    BaseButton,
    ExamTestPage,
    BaseSwitch,
    FormInput,
    TasksChoiceDropdown,
    ExamDurationDropdown,
    Icon,
  },
  effector: {
    $mainBlocks,
    maxDuration: examDurationDropdownModel.store.$item,
  },
  computed: {
    showDurationField() {
      return this.maxDuration !== null && this.maxDuration.name === 'per_block'
    },
  },
  methods: {
    addMainBlock,
    changeFieldValue(type: UnitType, depth: string, value: number | string, field: string) {
      const obj = {}
      obj[field] = value
      changeMainBlock({ type, depth, obj })
    },
    onDelete(type: UnitType, depth: string) {
      deleteMainBlock({ type, depth })
    },
    onAddUnit(type: UnitType, depth: string, element_type: ElementTypeNames | null) {
      addMainBlock({ type, depth, element_type })
    },
  },
})
