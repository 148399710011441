import dayjs from 'dayjs'
import { TableField } from '@/pages/dictionary/themes/list/types'

export const FAQTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '70px',
  },
  {
    name: 'question',
    sortField: 'question',
    title: 'Вопрос',
    width: '150px',
  },
  {
    name: 'answer',
    sortField: 'answer',
    title: 'Ответ',
    width: '380px',
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создано',
    width: '150px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'updated_at',
    sortField: 'updated_at',
    title: 'Обновлено',
    width: '150px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
]
