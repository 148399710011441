import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { ExamsBlockList } from '@/features/api/full-time-answer/type'

export const ExamsBlockListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<ExamsBlockList[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/sssspart-time/attempts/filter/names/',
    query,
  }),
})
