











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  usersDropdownModel,
  loadUsers,
  setSelectedUser,
  $users,
} from '@/pages/common/dropdowns/users/users-dropdown/users-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...usersDropdownModel.store,
    $users,
  },
  methods: {
    ...usersDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedUser(item || null)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    loadUsers()
  },
})
