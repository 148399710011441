






















import Vue from 'vue'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  setSelectedCourseType,
  $selectedCourseType,
  mapCoursesTypeFilter,
  mapCoursesType,
} from '@/pages/learning/learning-courses/parts/inputs/course-type/coursesType.model'

export default Vue.extend({
  components: {
    BaseDropdown,
    SelectItem,
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    courseType() {
      return this.isFilter ? mapCoursesTypeFilter : mapCoursesType
    },
  },
  effector: {
    $selectedCourseType,
  },
  methods: {
    setSelectedCourseType,
    handleClick(item: DropdownItem, cb: () => void) {
      this.$emit('item-changed', item)
      this.setSelectedCourseType(item)
      cb()
    },
  },
  mounted() {
    if (!this.isEdit) {
      setSelectedCourseType(mapCoursesTypeFilter[2])
    }
  },
})
