import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteLessonsParams } from '@/pages/learning/learning-lessons/list/model/types'

export const deleteOneLessonFx = createApiEffect<number, void>({
  requestMapper: (id) => ({
    method: 'DELETE',
    url: `/api/lesson-app/lessons/${id}/`,
  }),
})

export const requestDeleteLessonsFx = createApiEffect<
  RequestDeleteLessonsParams,
  RequestDeleteLessonsParams
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/lesson-app/lessons/bulk-delete/',
    body,
  }),
})
