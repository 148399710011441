import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
} from 'effector-root'
import { getTagsTreeFx } from '@/features/api/assignment/olympiad-tags/get-tags-tree'
import { deleteTagsFx } from '@/features/api/assignment/olympiad-tags/delete-tag'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { TreeData } from '@/features/api/types'
import { getTagsTreeLightFx } from '@/features/api/assignment/olympiad-tags/get-tags-tree-light'
import { cropString, isObjectEmpty, mergeTreeData, sortTreeLeaves } from '@/features/lib'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { condition } from 'patronum'
import { FiltersParams } from '@/pages/common/types'
import { getTagsInfoFx } from '@/features/api/assignment/olympiad-tags/get-tags-tree-info'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { getTagsListFx } from '@/features/api/assignment/olympiad-tags/get-tags-list'
import { getOlympiadAssignmentFx } from '@/features/api/assignment/olympiad-assignment/get-olympiad-assignment'
import { tagsFilters } from '@/pages/tags/parts/tags-filter/tags-filter.model'
import { exportTagsListFx } from '@/features/api/assignment/olympiad-tags/export-tags-list'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'

export const getTagsTree = attach({
  effect: getTagsTreeFx,
})

export const getTagsTreeLight = attach({
  effect: getTagsTreeLightFx,
})

export const getFilteredTree = attach({
  effect: getTagsTreeFx,
})

export const exportTagsList = attach({
  effect: exportTagsListFx,
  source: [tagsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportTagsList.doneData.watch((res) => fileDownload(res.body, 'tagsList.xlsx'))

const getTagsTreeInfo = attach({
  effect: getTagsInfoFx,
})

const getTagsList = attach({
  effect: getTagsListFx,
})

export const deleteTags = createEffect({
  handler: (ids: number[]): Promise<number[]> => {
    return new Promise((resolve) => {
      deleteTagsFx(ids).then(() => {
        resolve(ids)
      })
    })
  },
})

export const collectTaskData = createEffect({
  handler: (ids: number[]): Promise<any[]> =>
    Promise.all(
      ids.map(
        async (id) =>
          new Promise<any>((resolve) => {
            const res = getOlympiadAssignmentFx(id).then((r) => {
              return {
                id: r.body.id,
                name: `${r.body.id}`,
                title: `[id${r.body.id}] - ${cropString(r.body.wording, 34)}`,
              }
            })
            resolve(res)
          })
      )
    ),
})
export const tagsPageParams = createPageParamsModel()

export const canrefreshTableAfterDeletionChanged = createEvent<boolean>()
export const $canRefreshTableAfterDeletion = restore<boolean>(
  canrefreshTableAfterDeletionChanged,
  false
)

export const loadTreeLight = createEvent<void>()
export const loadTree = createEvent<FiltersParams>()
export const loadFilteredTree = createEvent<FiltersParams>()
const rewriteTagsTree = createEvent<TreeData[] | null>()
export const setTagsTree = createEvent<TreeData[] | null>()
export const $tagsTree = createStore<TreeData[] | null>(null)
  .on(setTagsTree, (state, data) => mergeTreeData(state!, data!))
  .on(rewriteTagsTree, (state, payload) => sortTreeLeaves(payload!))
export const setTagsTreeTotal = createEvent<number>()
export const $tagsTreeTotal = restore<number>(setTagsTreeTotal, 0)

const showDeleteAssignmentsToast = createEvent<number[]>()

export const $isLoading = combine(
  getFilteredTree.pending,
  getTagsTreeLight.pending,
  getTagsList.pending,
  (tree, light, list) => tree || light || list
)

forward({
  from: loadTree,
  to: [getTagsTree, getTagsTreeInfo.prepend(() => ({}))],
})

forward({
  from: loadTreeLight,
  to: [getTagsTreeLight, getTagsTreeInfo],
})

forward({
  from: loadFilteredTree,
  to: getFilteredTree,
})

forward({
  from: getTagsInfoFx.doneData.map(({ body }) => body.total_amount),
  to: setTagsTreeTotal,
})
forward({
  from: getFilteredTree.doneData,
  to: [
    rewriteTagsTree.prepend(({ body }) => body.data),
    setTagsTreeTotal.prepend(({ body }) => body.total),
  ],
})

forward({
  from: getTagsTreeLight.doneData,
  to: [
    rewriteTagsTree.prepend(({ body }) => body.data),
    canrefreshTableAfterDeletionChanged.prepend(() => false),
  ],
})

forward({
  from: getTagsTree.doneData,
  to: [
    setTagsTree.prepend(({ body }) => body.data),
    canrefreshTableAfterDeletionChanged.prepend(() => false),
  ],
})

forward({
  from: deleteTags.doneData,
  to: [
    loadTreeLight.prepend(() => ({})),
    canrefreshTableAfterDeletionChanged.prepend(() => true),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    showDeleteAssignmentsToast,
  ],
})

condition({
  source: showDeleteAssignmentsToast,
  if: (ids: number[]) => ids.length === 1,
  then: successToastEvent('Тег был успешно удален!'),
  else: successToastEvent('Теги были успешно удалены!'),
})

guard({
  clock: tagsFilters.methods.resetFilters,
  filter: tagsPageParams.store.treeView,
  target: loadTreeLight.prepend(() => ({})),
})

guard({
  clock: tagsPageParams.store.treeView,
  source: tagsFilters.store.$filterParams,
  filter: (filterParams, treeView) => treeView && !isObjectEmpty(filterParams),
  target: loadFilteredTree,
})

guard({
  clock: tagsFilters.methods.applyFilters,
  filter: tagsPageParams.store.treeView,
  source: tagsFilters.store.$filterParams,
  target: loadFilteredTree,
})
