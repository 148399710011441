import { createApiEffect } from '@/features/api/common/create-api-effect'
import { InstructionCreateFxParams, InstructionCreateType } from '@/features/api/instructions/types'

export const createInstructionsFx = createApiEffect<
  InstructionCreateType,
  InstructionCreateFxParams
>({
  requestMapper: (body) => ({
    method: 'POST',
    url: `/api/assignment-app/olympiad-instructions/`,
    body,
  }),
})
