import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { CommentOnLesson, CommentOnLessonUser } from '@/features/api/callback/types'

export const CommentsOnLessonsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID Комментария',
    width: '160px',
  },
  {
    name: 'marks',
    sortField: 'marks',
    title: 'Оценки (Все)',
    width: '140px',
  },
  {
    name: 'likes',
    sortField: 'likes',
    title: 'Оценки (+)',
    width: '130px',
  },
  {
    name: 'dislikes',
    sortField: 'dislikes',
    title: 'Оценки (-)',
    width: '130px',
  },
  {
    name: 'text',
    sortField: 'text',
    dataClass: 'wrap',
    title: 'Текст',
    width: '260px',
  },
  {
    name: 'media',
    sortField: 'media',
    title: 'Файлы',
    width: '260px',
    formatter(media: string): string {
      return media || '-'
    },
  },
  {
    name: 'group',
    sortField: 'group',
    title: 'Группа',
    width: '130px',
    formatter(group: { name: string }): string {
      return group ? group.name : '-'
    },
  },
  {
    name: 'course',
    sortField: 'course',
    title: 'Курс',
    width: '260px',
    formatter(course: { name: string }): string {
      return course ? course.name : '-'
    },
  },
  {
    name: 'lesson',
    sortField: 'lesson',
    dataClass: 'wrap',
    title: 'Урок',
    width: '260px',
  },
  {
    name: 'is_shown',
    sortField: 'is_shown',
    title: 'Отображается',
    width: '180px',
    formatter(is_shown: boolean): string {
      return is_shown ? 'Да' : 'Нет'
    },
  },
  {
    name: 'is_attached',
    sortField: 'is_attached',
    title: 'Закреплен',
    width: '160px',
    formatter(is_attached: boolean): string {
      return is_attached ? 'Да' : 'Нет'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Пользователь',
    width: '260px',
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Посл. изм.',
    width: '180px',
    formatter(updated_by: CommentOnLessonUser): string {
      return updated_by
        ? `(${updated_by.id}) ${updated_by.first_name} ${updated_by.last_name}`
        : '-'
    },
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создано',
    width: '140px',
    formatter(created_at: string): string {
      return created_at ? dayjs(created_at).format('DD.MM.YYYY') : '-'
    },
  },
  {
    name: 'updated_at',
    sortField: 'updated_at',
    title: 'Изменено',
    width: '140px',
    formatter(updated_at: string): string {
      return updated_at ? dayjs(updated_at).format('DD.MM.YYYY') : '-'
    },
  },
  {
    name: 'parent',
    sortField: 'parent',
    title: 'Ответ на комментарий',
    width: '310px',
    formatter(parent: number): string {
      return parent?.toString() || '-'
    },
  },
  {
    name: 'children',
    sortField: 'children',
    title: 'Другие ответы',
    width: '310px',
    formatter(children: CommentOnLesson[]): string {
      return children.length ? children.map((item) => item?.id).join(', ') : '-'
    },
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID' },
  { name: 'text', title: 'Текст' },
  { name: 'course', title: 'Курс' },
  { name: 'lesson', title: 'Урок' },
  { name: 'created_by', title: 'Пользователь' },
  { name: 'parent', title: 'Ответ на комментарий' },
]
