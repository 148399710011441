import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams } from '@/features/api/types'
import { CoursesFilter } from '@/features/api/learning/types'

export const getFilterCoursesFx = createApiEffect<GetListQueryParams, CoursesFilter[]>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/course-app/courses/filter/names/',
    query,
  }),
})
