




























import Vue from 'vue'
import AchievementHeader from '@/pages/settings/achievements/parts/AchievementHeader.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $achievementText,
  changeAchievementText,
  reset,
  saveAchievement,
} from '@/pages/settings/achievements/model/achievements-page.model'
import { createAchievementFx } from '@/features/api/settings/achievements/create-achievement'

export default Vue.extend({
  name: 'AchievementsPage',
  components: {
    AchievementHeader,
    LoaderBig,
    Wysiwyg,
    BaseButton,
  },
  effector: {
    $isLoading: createAchievementFx.pending,
    $achievementText,
  },
  methods: {
    changeAchievementText,
    saveAchievement,
  },
  beforeDestroy() {
    reset()
  },
})
