import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportAssignmentQueryParams } from '@/features/api/assignment/types/types'

export const getExamFullTimeTestsFx = createApiEffect<ExportAssignmentQueryParams, Blob>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/tests/export/',
    responseType: 'blob',
    query,
  }),
})
