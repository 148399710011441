import { createApiEffect } from '@/features/api/common/create-api-effect'
import { FAQType } from '@/features/api/content/types'

export const updateQuestionByIdFx = createApiEffect<
  { body: Partial<FAQType>; id: string },
  FAQType
>({
  requestMapper: ({ body, id }) => ({
    method: 'PATCH',
    url: `/api/content-app/faq/${id}/`,
    body,
  }),
})
