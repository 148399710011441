import { LessonsCourseType } from '@/pages/learning/learning-courses/types'
import { SendLessonsCourseType } from '@/features/api/learning/types'

export const sendLessonsFormat = (lessons: LessonsCourseType[]): SendLessonsCourseType[] => {
  return lessons.map((l) => {
    const lesson = { ...l }
    delete lesson.blockId
    const result = {
      ...lesson,
      groups:
        lesson.groups.filter((g) => !!g.name).length > 0
          ? lesson.groups.map((g) => ({ id: g.id, start: g.start || null, end: g.end || null }))
          : [],
    }
    return JSON.parse(JSON.stringify(result))
  })
}
