import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportAssignmentQueryParams } from '@/features/api/assignment/types/types'

export const exportResultCoursesCheckListFx = createApiEffect<ExportAssignmentQueryParams, Blob>({
  requestMapper: () => ({
    method: 'GET',
    url: '/api/results-app/results/lessons/list/export/',
    responseType: 'blob',
  }),
})
