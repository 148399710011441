import { createEvent, forward, attach, restore, sample } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { getEmployeesListFx } from '@/features/api/employee/get-employee-list'
import { getEmployeeName } from '@/pages/common'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { EmployeeList } from '@/features/api/employee/types'
import { GetListQueryParams } from '@/features/api/types'

const getCreators = attach({
  effect: getEmployeesListFx,
})

export const creatorsDropdownModel = createDropdownModel<EmployeeList>(getCreators)

export const setSelectedCreator = createEvent<DropdownItem | null>()
export const $selectedCreator = restore(setSelectedCreator, null)

export const loadCreators = createEvent<void>()

sample({
  clock: loadCreators,
  source: { $nextPage: creatorsDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getCreators,
})

forward({
  from: creatorsDropdownModel.methods.canLoadNextPage,
  to: loadCreators,
})

sample({
  clock: getCreators.doneData,
  source: { items: creatorsDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((user) => ({
      name: `${user.id}`,
      title: getEmployeeName(user),
    }))
    return [...items, ...newData]
  },
  target: creatorsDropdownModel.store.$items,
})

forward({
  from: creatorsDropdownModel.methods.resetDropdown,
  to: setSelectedCreator.prepend(() => null),
})
