


















import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  tagsDropdownModel,
  componentMounted,
  componentDestroyed,
} from '@/pages/common/dropdowns/multiselectDropdown/tags-dropdown-filter/tags-filter-dropdown.model'
import { DropdownItem } from '@/pages/common/types'
import SelectedItemsList from '@/pages/common/dropdowns/multiselectDropdown/parts/SelectedItemsList.vue'

export default Vue.extend({
  components: {
    FilterDropdown,
    SelectedItemsList,
  },
  effector: {
    ...tagsDropdownModel.store,
  },
  methods: {
    ...tagsDropdownModel.methods,
    onItemChanged(item: DropdownItem | null) {
      if (item && !this.$selectedItems.find((i) => i.name === item.name)) {
        this.sendItem([...this.$selectedItems.map((i) => i.name), item.name])
      }
    },
    onDeleteItem(item: string) {
      this.deleteSelectedItem(item)
      this.sendItem(this.$selectedItems.filter((i) => i.name !== item).map((i) => i.name))
    },
    sendItem(itemArr: Array<string>) {
      if (itemArr.length === 0) {
        this.$emit('setItem', undefined)
      } else if (itemArr.length === 1) {
        this.$emit('setItem', itemArr[0])
      } else {
        this.$emit('setItem', itemArr)
      }
    },
  },
  mounted() {
    componentMounted()
  },
  beforeDestroy() {
    componentDestroyed()
  },
})
