import { DropdownItem } from '@/pages/common/types'

export const formationMapData: DropdownItem[] = [
  {
    name: '0',
    title: 'Из освоенных учеником тем в тесте',
  },
  {
    name: '1',
    title: 'Самостоятельный выбор тем',
  },
]
