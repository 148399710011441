































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { StudentData } from '@/pages/users/groups/create/model/types'
import BaseCheckbox from '@/ui/checkbox/BaseCheckbox.vue'

export default Vue.extend({
  name: 'StudentCard',
  components: {
    Icon,
    BaseCheckbox,
  },
  props: {
    student: { type: Object as PropType<StudentData>, required: true },
    checkedIds: { type: Array as PropType<number[]>, default: () => [] },
  },
  computed: {
    isChecked() {
      return this.checkedIds.includes(this.student.id)
    },
  },
  methods: {
    selected() {
      this.$emit('selected', this.student.id)
    },
    remove() {
      this.$emit('remove', this.student.id)
    },
  },
})
