import { createEvent, forward, restore, attach, combine } from 'effector-root'
import { uploadMediaFx } from '@/features/api/media/upload-media'
import {
  $questionData,
  setupMovingOnTextAnswerDataFx,
} from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'
import { $audioFiles, getAudioFilesFx } from '@/pages/common/parts/audio-files/audio-files.model'
import { BaseAssignment } from '@/features/api/assignment/types/types'
import { MovingOnTextQuestionData } from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/types'
import { DraggableImage, MovingOnTextDroppableInput } from '@/pages/common/parts/tasks/types'

export const uploadMedia = attach({
  effect: uploadMediaFx,
})

export const clearFields = createEvent<void>()

export const setWording = createEvent<string>()
export const $wording = restore(setWording, '').reset(clearFields)

export const setContaining = createEvent<string>()
export const $containing = restore(setContaining, '').reset(clearFields)

export const setAnswerExample = createEvent<string>()
export const $answerExample = restore(setAnswerExample, '').reset(clearFields)

export const $isFilled = combine($wording, $questionData, (wording, questionData) => {
  if (!wording) return false
  if (!questionData.droppable.length && !questionData.inputs.length) return false
  if (!questionData['draggable-text'].every((input) => !!input.text)) return false
  if (
    questionData.draggable &&
    !!questionData.droppable.length &&
    (!questionData.draggable.length ||
      questionData.draggable.filter((input) => !!input.value).length !==
        questionData.droppable.length)
  )
    return false
  if (
    questionData.inputs &&
    !!questionData.inputs.length &&
    !questionData.inputs.every((input) => input.value.some((value) => value.value !== ''))
  )
    return false
  return true
})

export const $form = combine(
  $wording,
  $answerExample,
  $containing,
  $audioFiles,
  $questionData,
  (wording, example_answer, containing, audio, questionData) => {
    return {
      wording,
      example_answer,
      text: containing,
      question_data: {
        draggable: questionData.draggable.map(({ id, image, size }) => ({
          id,
          image,
          size,
        })),
        'draggable-text': questionData['draggable-text'],
        inputs: questionData.inputs.map(({ id, color, size }) => ({
          id,
          color,
          size,
        })),
        droppable: questionData.droppable,
        mainText: questionData.mainText,
      },
      correct_answer: {
        draggable: questionData.draggable.map(({ id, value }) => ({ id, value })),
        inputs: questionData.inputs.map(({ id, value }) => ({ id, value })),
      },
      common_list_text_answer: null,
      audio: audio.map(({ id, isLimited, limit }) => ({
        id,
        isLimited,
        limit,
      })),
    }
  }
)

export const initAssignment = createEvent<BaseAssignment>()

forward({
  from: initAssignment,
  to: [
    setWording.prepend((data) => data.wording || ''),
    setContaining.prepend((data) => data.text || ''),
    setAnswerExample.prepend((data) => data.example_answer || ''),
    setupMovingOnTextAnswerDataFx.prepend(
      ({ question_data, correct_answer }): MovingOnTextQuestionData => {
        return {
          draggable:
            (question_data.draggable &&
              question_data.draggable.map((data: DraggableImage, id: number) => ({
                ...data,
                value: correct_answer.draggable[id].value,
              }))) ||
            [],
          droppable: question_data.droppable,
          'draggable-text': question_data['draggable-text'],
          inputs:
            (question_data.inputs &&
              question_data.inputs.map((data: MovingOnTextDroppableInput, id: number) => ({
                ...data,
                value: correct_answer.inputs[id].value,
              }))) ||
            [],
          mainText: question_data.mainText,
        }
      }
    ),
    getAudioFilesFx.prepend(({ audios }) => audios),
  ],
})
