import { attach, createEffect, createEvent, forward, restore, sample } from 'effector-root'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { createStore } from 'effector'
import { TableField } from '@/pages/applications/types'
import { studentsResultDataFields } from '@/pages/results/courses-students/constants'
import { exportResultStudentsCoursesListFx } from '@/features/api/results/export-result-courses-student'
import { resultStudentsFilters } from '@/pages/results/courses-students/parts/filter/result-student-filter.model'
import { sendResultStudentsCoursesListFx } from '@/features/api/results/update-result-courses-student'
import { ResultsCoursesStudentScores, updateQuestionParams } from '@/features/api/results/types'
import { successToastEvent } from '@/features/toasts/toasts.model'
import fileDownload from 'js-file-download'
import { getResultsCoursesStudentScores } from '@/features/api/results/get-results-courses-student-scores'
import { condition } from 'patronum'
import { saveResultLessonFx } from '@/features/api/results/save-result-lesson'
import { FiltersParams } from '@/pages/common/types'
import IDeleteResultParams from '@/features/api/results/results-courses-students/types/IDeleteResultParams'
import { deleteResultFx } from '@/features/api/results/results-courses-students/delete-result'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'

type SetColumnsColumns = {
  [key: string]: boolean
}

export const saveResultStudentsCourse = createEvent<updateQuestionParams[]>()

const updResultStudentsCourse = attach({
  effect: sendResultStudentsCoursesListFx,
})

export const endEdit = createEvent()

export const incomingResultStudentsPageParams = createPageParamsModel()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore<boolean>(setIsLoading, false)

export const setEditData = createEvent<{ [key: number]: updateQuestionParams }>()
export const $editData = restore(setEditData, {})

export const changeResultText = createEvent<string>()
export const $resultText = restore(changeResultText, '')

export const changeResultStatus = createEvent<string>()
export const $resultStatus = restore(changeResultStatus, '')

export const canRefreshTableChanged = createEvent<boolean>()
export const $canRefreshTable = restore(canRefreshTableChanged, false)

export const resetStudentData = createEvent()
export const setStudentData = createEvent<ResultsCoursesStudentScores>()
export const $studentData = restore<ResultsCoursesStudentScores>(setStudentData, {
  total_score: 0,
  total_student_score: 0,
  commentary: null,
  moderated_at: '',
  moderated_by: null,
  status: '',
}).reset(resetStudentData)

export const setNewTableFields = createEvent<SetColumnsColumns>()
export const setDefaultTableFields = createEvent<void>()
export const $tableFields = createStore<TableField[]>(studentsResultDataFields)
  .on(setNewTableFields, (fields, settings) =>
    studentsResultDataFields.filter((field) => settings[field.name])
  )
  .reset(setDefaultTableFields)

export const exportResultStudentsCoursesList = attach({
  effect: exportResultStudentsCoursesListFx,
  source: resultStudentsFilters.store.$filterParams,
  mapParams: (_, filters) => {
    const { course = '0', lesson = '0', group = '0', student = '0' } = filters
    return { course, lesson, group, student }
  },
})

export const loadStudentData = attach({
  effect: getResultsCoursesStudentScores,
  source: resultStudentsFilters.store.$filterParams,
})

exportResultStudentsCoursesList.doneData.watch((res) =>
  fileDownload(res.body, 'resultStudentCoursesList.xlsx')
)

forward({
  from: saveResultStudentsCourse,
  to: updResultStudentsCourse,
})

forward({
  from: [endEdit, saveResultLessonFx.doneData],
  to: [
    successToastEvent('Успешно сохранено!'),
    setEditData.prepend(() => ({})),
    canRefreshTableChanged.prepend(() => true),
    changeResultText.prepend(() => ''),
    changeResultStatus.prepend(() => ''),
  ],
})

sample({
  source: {
    text: $resultText,
    status: $resultStatus,
    params: resultStudentsFilters.store.$filterParams,
  },
  clock: updResultStudentsCourse.doneData,
  target: condition({
    if: (payload: { text: string; status: string; params: FiltersParams }) =>
      !!payload?.text || !!payload?.status,
    then: saveResultLessonFx.prepend(
      (payload: { text: string; status: string; params: FiltersParams }) => {
        return {
          ...(payload?.text ? { commentary: payload.text } : {}),
          ...(payload?.status ? { status: payload.status } : {}),
          course: payload.params?.course || '0',
          lesson: payload.params?.lesson || '0',
          student: payload.params?.student || '0',
        }
      }
    ),
    else: endEdit,
  }),
})

forward({
  from: loadStudentData.doneData,
  to: [
    setStudentData.prepend((data) => data.body),
    changeResultText.prepend((data) => data.body.commentary || ''),
    changeResultStatus.prepend((data) => data.body.status),
  ],
})

forward({
  from: [deleteResultFx.doneData],
  to: [
    successToastEvent('Результат был успешно удален'),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
  ],
})

export const deleteResult = createEffect({
  handler: (params: IDeleteResultParams): Promise<IDeleteResultParams> => {
    return new Promise((resolve) => {
      deleteResultFx(params).then(() => {
        resolve(params)
      })
    })
  },
})
