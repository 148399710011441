







































import Vue, { PropType } from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import Icon from '@/ui/icon/Icon.vue'
import SizeInput from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/SizeInput.vue'
import { MovingOnTextDroppableImage } from '@/pages/common/parts/tasks/types'
import ColorPicker from './ColorPicker.vue'

export default Vue.extend({
  name: `AttachingImagesItem`,
  components: {
    Icon,
    FormInput,
    SizeInput,
    ColorPicker,
  },
  props: {
    answer: {
      type: Object as PropType<MovingOnTextDroppableImage>,
      required: true,
    },
  },
})
