import { TableField } from '@/pages/applications/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'
import { $currentUser } from '@/features/session'

export const studentsResultDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'assignment',
    sortField: 'assignment',
    title: 'ID задания',
    width: '140px',
  },
  {
    name: 'theme',
    sortField: 'theme',
    title: 'Тема',
    width: '140px',
    formatter(theme: { name: string; is_prerequisite: boolean; id: number }) {
      return theme ? theme.name : '-'
    },
  },
  {
    name: 'is_prerequisite',
    sortField: 'is_prerequisite',
    title: 'Пререквизит',
    width: '140px',
    formatter(theme: { name: string; is_prerequisite: boolean; id: number }) {
      return theme && theme.is_prerequisite ? 'да' : 'нет'
    },
  },
  {
    name: 'type',
    sortField: 'type',
    dataClass: 'center aligned',
    title: 'Тип зада...',
    width: '140px',
  },
  {
    name: 'wording',
    sortField: 'wording',
    title: 'Вопрос',
    width: '250px',
    formatter(data: string) {
      return data || '-'
    },
  },
  {
    name: 'answer',
    sortField: 'answer',
    title: 'Ответ абитуриента',
    width: '400px',
    formatter(data) {
      return data || 'Ошибка загрузки данных'
    },
  },
  {
    name: 'correct_answer',
    sortField: 'correct_answer',
    title: 'Правильный ответ',
    width: '400px',
    formatter(data) {
      return data || 'Ошибка загрузки данных'
    },
  },
  {
    name: 'duration',
    sortField: 'duration',
    title: 'Затраченное время',
    width: '180px',
  },

  {
    name: 'is_correct',
    sortField: 'is_correct',
    title: '+ / -',
    width: '80px',
    formatter(data: boolean) {
      return data ? '+' : '-'
    },
  },
  {
    name: 'score',
    sortField: 'score',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Баллы',
    width: '120px',
    formatter(data: string) {
      return data || '-'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '50px',
  },
]

export const getResultTestsStudentActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const user = $currentUser.getState()
  const isDefaultShow = user?.role !== 'METHODIST'

  const actions = [
    {
      name: 'edit_test',
      title: 'Тест (редактирование)',
      action: 'edit_test',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: isDefaultShow,
    },
    {
      name: 'edit_assignment',
      title: 'Задание (редактирование)',
      action: 'edit_assignment',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.tests_sampleitem_sample_items.can_edit,
    },
    {
      name: 'view_assignment',
      title: 'Задание (предпросмотр)',
      action: 'view_assignment',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
    {
      name: 'compare_answers',
      title: 'Сравнить ответы',
      action: 'compare_answers',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
