<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="lessons-filter"
  >
    <div class="row">
      <ClassesDropdown
        class="half-third"
        @setItem="val => setItem({'study_year': val})"
      />
      <SubjectsDropdown
        class="half-third"
        @setItem="val => setItem({'subject': val})"
      />
      <StudentsDropdown
        class="half-third"
        @setItem="val => setItem({'student': val})"
      />
    </div>
    <div class="row">
      <TaskTypesDropdown class="half" @setItem="val => setItem({'assignment_type': val})" />
      <ScoreDropdown
        class="field"
        @setItem="val => setItem({'assignment_score': val})"
      />
    </div>
    <div class="row">
      <OlympiadTasksDropdown
        class="half"
        label="Задание"
        @setItem="val => setItem({'assignment': val})"
      />
      <ResultStatusDropdown class="field" @setItem="val => setItem({'status': val})" />
    </div>
    <div class="row">
      <TagsDropdown
        class="half"
      />
      <div class="labels-input-relative-align">
        <div class="buttons">
          <div class="btn">
            <BaseButton
              small
              @click="applyFilters"
            >
              Применить
            </BaseButton>
          </div>
          <div class="btn">
            <BaseButton
              class="borderless"
              small
              border-without-bg
              @click="resetFilters"
            >
              Сбросить фильтры
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <Icon
      type="close"
      class="close-icon"
      size="10"
      @click="toggleVisibility(false)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import TaskTypesDropdown from '@/pages/common/dropdowns/bank/task-types-dropdown/TaskTypesDropdown.vue'
import TagsDropdown from '@/pages/common/dropdowns/multiselectDropdown/tags-dropdown-filter/TagsFilterDropdown.vue'
import ScoreDropdown from '@/pages/common/dropdowns/bank/olympiad-tasks/score-dropdown/ScoreDropdown.vue'
import { toggleVisibility } from '@/pages/results/olympiad-results/model/olympiad-results-filter.model'
import ClickOutside from '@/features/directives/click-outside.ts'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import StudentsDropdown from '@/pages/common/dropdowns/students/StudentsDropdown.vue'
import ResultStatusDropdown from '@/pages/results/olympiad-results/parts/ResultStatusDropdown.vue'
import OlympiadTasksDropdown from '@/pages/results/olympiad-results/parts/OlympiadTasksDropdown.vue'
import { tagsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/tags-dropdown-filter/tags-filter-dropdown.model'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ToolTipFilter',
  components: {
    Icon,
    ClassesDropdown,
    BaseButton,
    SubjectsDropdown,
    TagsDropdown,
    TaskTypesDropdown,
    StudentsDropdown,
    ScoreDropdown,
    ResultStatusDropdown,
    OlympiadTasksDropdown,
  },
  effector: {
    $selectedTags: tagsDropdownModel.store.$selectedItems,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  watch: {
    $selectedTags: {
      handler(val) {
        if (val.length > 0) {
          this.setItem({ tags: val.map((element) => element.name).join() })
        }
      },
    },
  },
  methods: {
    toggleVisibility,
    closeFilter(event) {
      // check for general filter icon
      if (!event.target.closest('#filter-icon')) {
        toggleVisibility(false)
      }
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
    },
  },
})
</script>

<style scoped>
.lessons-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.half-second {
  width: 100%;
  margin-right: 30px;
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}

.half {
  width: calc(100% / 2 - 20px);
  flex-shrink: 0;
}

.labels-input-relative-align {
  width: 100%;
  display: flex;
  align-self: flex-end;
  height: 46px;

  .buttons {
    display: flex;
    align-items: center;
    margin-left: auto;

    .btn:first-child {
      margin-right: 38px;
    }
  }
}

.btn {
  width: max-content;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}
</style>
