import { createEvent, restore } from 'effector-root'

export const comparingAnswerModalVisibilityChanged = createEvent<boolean>()
export const $comparingAnswerModalVisibility = restore(comparingAnswerModalVisibilityChanged, false)

export const resetAnswers = createEvent<void>()

export const setStudentAnswer = createEvent<any>()
export const $studentAnswer = restore<any>(setStudentAnswer, null).reset(resetAnswers)

export const setCorrectAnswer = createEvent<any>()
export const $correctAnswer = restore<any>(setCorrectAnswer, null).reset(resetAnswers)
