import { createApiEffect } from '@/features/api/common/create-api-effect'
import { CreateLabelType, Label } from '@/features/api/assignment/types/types'

export const updateLabelFx = createApiEffect<CreateLabelType, Label>({
  requestMapper: (params) => ({
    method: 'PUT',
    url: `/api/assignment-app/labels/${params.id}/`,
    body: params,
  }),
})
