



























import Vue from 'vue'
import CreateFullAppointmentsFooter from '@/pages/exams/full-appointments/create/parts/CreateFullAppointmentsFooter.vue'
import CreateFullAppointmentsHeader from '@/pages/exams/full-appointments/create/parts/CreateFullAppointmentsHeader.vue'
import CreateFullAppointmentsContent from '@/pages/exams/full-appointments/create/parts/CreateFullAppointmentsContent.vue'
import { changeFullAppointmentsId } from '@/pages/exams/full-appointments/create/model/edit-full-appointments.model'
import {
  $disabledSaveButtons,
  resetFields,
} from '@/pages/exams/full-appointments/create/model/form-fileds.model'
import {
  getPageParams,
  setRedirectAfterSave,
} from '@/pages/exams/full-appointments/create/model/create-full-appointments.model.ts'
import Tabs from '@/pages/common/parts/tabs/Tabs.vue'

export default Vue.extend({
  name: 'CreateFullAppointmentsPage',
  components: {
    CreateFullAppointmentsHeader,
    CreateFullAppointmentsFooter,
    CreateFullAppointmentsContent,
    Tabs,
  },
  effector: {
    $disabledSaveButtons,
  },
  data() {
    return {
      tab: 1,
      tabs: [
        {
          tab: 1,
          title: 'Шаг 1. Организационные настройки',
        },
        {
          tab: 2,
          title: 'Шаг 2. Выбор теста и периода',
        },
        {
          tab: 3,
          title: 'Шаг 3. Назначение ученикам',
        },
      ],
    }
  },
  methods: {
    save(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      getPageParams()
    },
    setTab(tab: number) {
      this.tab = tab
    },
  },
  created() {
    changeFullAppointmentsId(this.$route.params.id)
  },
  beforeDestroy() {
    resetFields()
  },
})
