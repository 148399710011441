













import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { debounce } from 'throttle-debounce'
import { DropdownItem } from '@/pages/common/types'
import { getFilterLessonsFx } from '@/features/api/learning/courses/get-filter-lessons-list'
import { GetListQueryParams } from '@/features/api/types'
import { LessonFilter } from '@/features/api/learning/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    label: String,
    lesson: Object,
    blockId: Number,
  },
  data(): {
    page: number
    lastPage: null | number
    isLoading: boolean
    searchString: string
    itemsDropdown: LessonFilter[]
    lessonsData: DropdownItem[]
  } {
    return {
      itemsDropdown: [],
      page: 1,
      lastPage: null,
      isLoading: false,
      searchString: '',
      lessonsData: [],
    }
  },
  watch: {
    searchString: {
      handler() {
        this.searchLesson()
      },
    },
  },
  methods: {
    calculateSearchParams(str: string | null | undefined) {
      if (isNaN(Number(str))) return { search_area: 'search_wording' }
      return { search_area: 'search_id' }
    },
    async getLessons(
      search: string | undefined | null = null,
      inputPage: number | undefined | null = null
    ) {
      if (!this.isLoading) {
        const page = inputPage || this.page + 1
        if (!inputPage && this.lastPage && page > this.lastPage) return
        this.isLoading = true
        const query: GetListQueryParams = {
          page,
          per_page: 100,
          ...(search ? { search } : {}),
          ...this.calculateSearchParams(search),
        }
        try {
          const data = await getFilterLessonsFx(query)
          this.page = data.body.current_page
          this.lastPage = data.body.last_page
          if (inputPage) {
            this.lessonsData = data.body.data.map((el: { id: number; name: string }) => ({
              name: `${el.id}`,
              title: el.name,
            }))
          } else {
            this.lessonsData = [
              ...this.lessonsData,
              ...data.body.data.map((el: { id: number; name: string }) => ({
                name: `${el.id}`,
                title: el.name,
              })),
            ]
          }
          this.setItems(data.body.data)
        } finally {
          this.isLoading = false
        }
      }
    },
    setItems(items: LessonFilter[]) {
      this.itemsDropdown = items
    },
    resetItem() {
      this.$emit('item-changed', null)
    },
    resetSearchString() {
      this.searchString = ''
    },
    searchStringChanged(value: string) {
      this.searchString = value
    },
    onSelectItem(lesson: LessonFilter | null) {
      this.$emit('item-changed', lesson, this.blockId)
    },
    searchLesson: debounce(300, false, function getDebounceTask(this: any) {
      this.lastPage = null
      this.getLessons(this.searchString, 1)
    }),
    getNextPage() {
      this.getLessons(this.searchString)
    },
  },
  mounted() {
    this.getLessons(null, 1)
  },
})
