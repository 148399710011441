





























import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import {
  $instructionsName,
  $instructionsText,
  setInstructionsName,
  setInstructionsText,
} from '@/pages/dictionary/instructions/create/model/form-fileds.model'

export default Vue.extend({
  name: 'CreateInstructionsContent',
  components: {
    FormInput,
    BaseTextarea,
    ClassesDropdown,
    SubjectsDropdown,
  },
  effector: {
    $instructionsName,
    $instructionsText,
  },
  methods: {
    setInstructionsText,
    setInstructionsName,
  },
})
