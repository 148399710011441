// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/img/file.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".audio-file-input[data-v-1e0d6f1a]{display:flex;flex-direction:column}.drop-container[data-v-1e0d6f1a]{width:100%;height:90px;display:flex;justify-content:center;align-items:center;background:#fdfdfd;border:1px dashed #929bae;box-sizing:border-box;border-radius:5px;text-align:center;line-height:1.21429rem;color:#6b768b;padding:15px}.trigger[data-v-1e0d6f1a]{cursor:pointer;text-decoration:underline;-webkit-text-decoration-skip-ink:none;text-decoration-skip-ink:none;text-decoration-skip:none;-webkit-text-decoration-skip:none;color:#0f2345}.file-input[data-v-1e0d6f1a]{visibility:hidden;height:0}.close-icon[data-v-1e0d6f1a]{position:absolute;top:8px;right:8px;cursor:pointer;z-index:6;fill:#fff}.image-essence[data-v-1e0d6f1a]{width:60px;height:60px;margin-right:10px;border-radius:5px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%;background-repeat:no-repeat;position:relative;overflow:hidden}.image-essence[data-v-1e0d6f1a]:after{content:\"\";position:absolute;left:0;top:0;width:100%;height:100%;background-color:rgba(0,0,0,.2);z-index:5}.wrap[data-v-1e0d6f1a]{position:relative}.hide-input[data-v-1e0d6f1a]{position:absolute;width:1px;height:1px;opacity:.01}.image-wrap[data-v-1e0d6f1a]{display:flex;align-items:center;overflow:hidden}.file-name[data-v-1e0d6f1a]{max-width:calc(100% - 130px);font-weight:400;font-size:1rem;line-height:1.14286rem;-webkit-text-decoration-line:underline;text-decoration-line:underline;color:#0f2345}.edit[data-v-1e0d6f1a]{display:flex;flex-direction:column;justify-content:center;align-items:center;margin-left:auto;width:60px;height:60px;cursor:pointer;background:#fff;border:1px dashed #bcc2ca;box-sizing:border-box;border-radius:5px}.edit-text[data-v-1e0d6f1a]{font-style:normal;font-weight:600;font-size:.71429rem;line-height:.71429rem;color:#0f2345}", ""]);
// Exports
module.exports = exports;
