















































import Vue, { PropType } from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  $searchString,
  searchStringChanged,
  $searchField,
  searchFieldChanged,
  reset,
} from '@/pages/common/general-filter/general-filter.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  name: 'GeneralFilter',
  components: {
    FormInput,
    BaseDropdown,
    SelectItem,
    Icon,
  },
  effector: {
    $searchString,
    $searchField,
  },
  props: {
    searchFields: { type: Array as PropType<DropdownItem[]>, required: true },
    isShowFilter: { type: Boolean, default: true },
  },
  data: () => ({
    debounceId: undefined as ReturnType<typeof setTimeout> | undefined,
    title: 'Искать везде',
  }),
  computed: {
    correctTittle(): string {
      return this.title.length > 15 ? `${this.title.substring(0, 12)}...` : this.title
    },
  },
  watch: {
    $searchField: {
      handler(newVal) {
        this.title = newVal.title
      },
    },
  },
  methods: {
    searchStringChanged,
    searchFieldChanged,
    handleSearchString(value: string) {
      if (this.debounceId !== undefined) {
        clearTimeout(this.debounceId)
      }
      this.debounceId = setTimeout(() => {
        searchStringChanged(value)
        this.$emit('changeFilter', {
          search: value,
        })
        this.$emit('setFilter')
      }, 600)
    },
    handleSearchFieldClick(item: DropdownItem, cb: any) {
      searchFieldChanged(item)
      this.$emit('changeFilter', {
        search_area: `search_${item.name}`,
      })
      this.$emit('setFilter')
      cb()
    },
    clear() {
      this.$emit('resetFilter')
    },
  },
  mounted() {
    reset()
  },
})
