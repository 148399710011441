import { attach, createEvent, forward, restore, createStore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { getUsersListFx } from '@/features/api/user/get-users-list'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'

export const usersDropdownModel = createFilter()

export const setSelectedUser = createEvent<DropdownItem | null>()
export const $selectedUser = restore(setSelectedUser, null)

const getUsers = attach({
  effect: getUsersListFx,
})

export const loadUsers = createEvent<void>()
export const $users = createStore<DropdownItem[]>([])

forward({
  from: loadUsers,
  to: getUsers.prepend(() => ({})),
})

forward({
  from: getUsers.doneData.map((res) =>
    res.body.map((user) => ({ name: `${user.id}`, title: user.name }))
  ),
  to: $users,
})

forward({
  from: usersDropdownModel.methods.resetDropdown,
  to: setSelectedUser.prepend(() => null),
})
