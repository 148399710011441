














import Vue from 'vue'
import SubjectHeader from '@/pages/dictionary/subjects/common/Header.vue'
import {
  redirectAfterSaveChanged,
  save,
} from '@/pages/dictionary/subjects/create/subjects-creation-page.model'
import { clearFields } from '@/pages/dictionary/subjects/common/create-edit.model'
import LeftBlock from '@/pages/dictionary/subjects/common/LeftBlock.vue'
import RightBlock from '@/pages/dictionary/subjects/common/RightBlock.vue'

export default Vue.extend({
  name: 'SubjectEditPage',
  components: { SubjectHeader, LeftBlock, RightBlock },
  methods: {
    clearFields,
    save,
    createWithRedirect() {
      redirectAfterSaveChanged(true)
      save()
    },
  },
  beforeDestroy() {
    redirectAfterSaveChanged(false)
    clearFields()
  },
})
