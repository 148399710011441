













import Vue from 'vue'
import { DropdownItem } from '@/pages/common/types'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  $courseApplicationsType,
  applicationsCourseTypeDropdownModule,
} from '@/pages/learning/learning-courses/list/parts/filter/parts/course-submit-applications-filter/course-submit-applications-filter.model'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    $courseApplicationsType,
    ...applicationsCourseTypeDropdownModule.store,
  },
  methods: {
    ...applicationsCourseTypeDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      if (!item || item.name === 'Показать все') {
        this.$emit('setItem', null)
      } else {
        this.$emit('setItem', item.name === 'Подача заявок открыта')
      }
    },
  },
  mounted() {
    if (!this.$item) {
      applicationsCourseTypeDropdownModule.methods.itemChanged({
        name: 'Показать все',
        title: 'Показать все',
      })
    }
  },
})
