import { TableField } from '@/pages/applications/types'
import { DropdownItem } from '@/pages/common/types'

export const maxLengthText = (text: string | null): string => {
  if (!text) return '-'
  return text.length > 250 ? `${text.slice(0, 250)}...` : text
}

export const coursesCheckResultDataFields: TableField[] = [
  {
    name: 'course',
    sortField: 'course',
    titleClass: 'pl-20',
    dataClass: 'pl-20 wrap',
    title: 'Название курса',
    width: '200px',
  },
  {
    name: 'group',
    sortField: 'group',
    dataClass: 'wrap',
    title: 'Группа',
    width: '200px',
  },
  {
    name: 'lesson',
    sortField: 'lesson',
    dataClass: 'wrap',
    title: 'Урок',
    width: '260px',
  },
  {
    name: 'total_works',
    sortField: 'total_works',
    title: 'Всего работ',
    dataClass: 'center aligned',
    width: '140px',
    formatter(value: number | null) {
      return value || '-'
    },
  },
  {
    name: 'total_verified_works',
    sortField: 'total_verified_works',
    title: 'Количество проверенных работ',
    dataClass: 'center aligned',
    width: '260px',
    formatter(value: number | null) {
      return value || '-'
    },
  },
  {
    name: 'total_deferred_works',
    sortField: 'total_deferred_works',
    title: 'Количество отложенных работ',
    dataClass: 'center aligned',
    width: '240px',
    formatter(value: number | null) {
      return value || '-'
    },
  },
  {
    name: 'total_not_verified_works',
    sortField: 'total_not_verified_works',
    title: 'Количество новых работ',
    dataClass: 'center aligned',
    width: '210px',
    formatter(value: number | null) {
      return value || '-'
    },
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'courses', title: 'Название курса' },
  { name: 'lessons', title: 'Название урока' },
]
