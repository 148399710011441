import StatusDropdown from '@/pages/applications/incoming-deletion/parts/filter/parts/status-dropdown/StatusDropdown.vue'
import CreatorsDropdown from '@/pages/common/dropdowns/application/creators/CreatorsDropdown.vue'
import ModeratorsDropdown from '@/pages/common/dropdowns/application/moderators/ModeratorsDropdown.vue'
import TypesDropdown from '@/pages/applications/incoming-deletion/parts/filter/parts/type-dropdown/TypesDropdown.vue'

export const dropdownComponents = {
  StatusDropdown,
  ModeratorsDropdown,
  CreatorsDropdown,
  TypesDropdown,
}
