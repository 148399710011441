import { createEvent, restore } from 'effector-root'
import {
  TableField,
  SetColumnsColumns,
  SetColumnsColumnsNames,
} from '@/pages/common/modals/table-settings/table-colunm-edit/types'
import { createStore, forward } from 'effector'

export const setColumnsModalDestroy = createEvent<void>()
export const changeColumnsModalVisibility = createEvent<boolean>()
export const $columnsModalVisibility = restore(changeColumnsModalVisibility, false).reset(
  setColumnsModalDestroy
)

export const initSetColumnsModalStores = createEvent<TableField[]>()
function onInitSetColumnsModalStores(
  _: SetColumnsColumns | SetColumnsColumnsNames,
  payload: TableField[],
  storeName: string
) {
  const columns = {}
  payload.forEach((field) => {
    if (storeName === '$exportColumns') columns[field.name] = true
    if (storeName === '$exportColumnsNames') columns[field.name] = field.title
  })
  return columns
}

function onInitSetColumnsColumns(state: SetColumnsColumns, payload: TableField[]) {
  return onInitSetColumnsModalStores(state, payload, '$exportColumns')
}

function onInitSetColumnsColumnsNames(state: SetColumnsColumnsNames, payload: TableField[]) {
  return onInitSetColumnsModalStores(state, payload, '$exportColumnsNames')
}

export const changeSetColumnsColumns = createEvent<{ [key: string]: boolean }>()
function onchangeSetColumnsColumns(state: SetColumnsColumns, params: SetColumnsColumns) {
  return { ...state, ...params }
}

export const uncheckAll = createEvent<void>()
export const toggleSelectAll = createEvent<boolean>()
export const $allSelected = restore(toggleSelectAll, true).reset(setColumnsModalDestroy)

function onToggleSelectAll(state: SetColumnsColumns, payload: boolean) {
  const columns = { ...state }
  for (const column in columns) {
    if (columns.hasOwnProperty(column)) {
      if (column === '__checkbox' || column === 'actions') {
        columns[column] = true
      } else {
        columns[column] = payload
      }
    }
  }
  return columns
}

export const $setColumns = createStore<SetColumnsColumns>({})
  .on(initSetColumnsModalStores, onInitSetColumnsColumns)
  .on(changeSetColumnsColumns, onchangeSetColumnsColumns)
  .on($allSelected, onToggleSelectAll)
  .reset(setColumnsModalDestroy)

export const $setColumnsNames = createStore<SetColumnsColumnsNames>({})
  .on(initSetColumnsModalStores, onInitSetColumnsColumnsNames)
  .reset(setColumnsModalDestroy)

forward({
  from: uncheckAll,
  to: toggleSelectAll.prepend(() => false),
})
