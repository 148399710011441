






















































import Vue from 'vue'
import PageHeader from '@/pages/labels/parts/header/PageHeader.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import LabelsFilter from '@/pages/labels/parts/labels-filter/LabelsFilter.vue'
import LabelsTree from '@/pages/labels/parts/tree/LabelsTree.vue'
import LabelCreationModal from '@/pages/labels/parts/modals/label-creation/LabelCreationModal.vue'
import LabelEditionModal from '@/pages/labels/parts/modals/label-edition/LabelEditionModal.vue'
import TasksModal from '@/pages/labels/parts/modals/tasks/TasksModal.vue'
import {
  $visibility,
  labelsFilters,
  toggleVisibility,
} from '@/pages/labels/parts/labels-filter/labels-filter.model'
import {
  deleteLabels,
  loadTree,
  loadTreeLight,
  $isLoading,
  collectTaskData,
  $labelsTreeTotal,
} from '@/pages/labels/labels-page.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { getLabelsActions, searchFieldsData } from '@/pages/labels/constants'
import { loadModalToEdit } from '@/pages/labels/parts/modals/label-edition/label-edition.modal'
import { loadModal } from '@/pages/labels/parts/modals/tasks/tasks.model'
import { createLabelFromTree } from '@/pages/labels/parts/modals/label-creation/label-creation.model'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { $token } from '@/features/api/common/request'
import { changeTasks } from '@/pages/preview-tasks/parts/tasks-dropdown/tasks-dropdown.model'
import { LabelsInteractedItemData, LabelsInteractedItemParams } from '@/pages/labels/types'
import { DEFAULT_ID } from '@/pages/common/constants'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { ActionsItem, DisplayContextMenuPayload } from '@/pages/common/types'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import { clearTreeStates } from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { $permissions } from '@/features/session'
import { getActionsDisplayConditions } from '@/pages/common'
import { navigatePush } from '@/features/navigation/navigationMethods'

export default Vue.extend({
  components: {
    TableHeader,
    PageHeader,
    GeneralFilter,
    LabelsFilter,
    LabelsTree,
    ContextMenu,
    LabelCreationModal,
    LabelEditionModal,
    TasksModal,
    ConfirmDeleteModal,
    LoaderBig,
  },
  effector: {
    $visibility,
    $isLoading,
    filterParams: labelsFilters.store.$filterParams,
    $token,
    $labelsTreeTotal,
    $permissions,
  },
  data() {
    return {
      searchFields: searchFieldsData,
      interactedItemId: DEFAULT_ID as number,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      subject_id: DEFAULT_ID as number,
      class_id: null as number | null,
      is_prerequisite: false,
      contextMenuItems: [] as ActionsItem[],
    }
  },
  computed: {
    selectedIds(): number[] {
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
  },
  methods: {
    changeFilter: labelsFilters.methods.changeFilter,
    resetFilters: labelsFilters.methods.resetFilters,
    applyFilters: labelsFilters.methods.applyFilters,
    loadTree,
    toggleVisibility,
    onFilterSet() {
      this.applyFilters()
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      clearTreeStates()
    },
    handleActionsClick({ data, event, type }: LabelsInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type = 'item' }: LabelsInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: LabelsInteractedItemData) {
      this.subject_id = data.subject_id
      this.class_id = data.class_id
      this.is_prerequisite = data.is_prerequisite
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(this.contextMenuType, 0, true)
      this.contextMenuItems = getLabelsActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    onCreateLabel() {
      createLabelFromTree({
        class_id: this.class_id,
        subject_id: this.subject_id,
        theme_id: this.interactedItemId,
        is_prerequisite: this.is_prerequisite,
      })
    },
    onRemoveLabel() {
      loadConfirmDeleteModal(this.selectedIds)
    },
    removeLabel(ids: number[]) {
      deleteLabels(ids)
    },
    showTasks() {
      loadModal(this.selectedIds[0])
    },
    editLabel() {
      loadModalToEdit(this.selectedIds[0])
    },
    showPreview(value: number[]) {
      this.transferSelectedApps(value)
      navigatePush({
        name: 'preview-task',
        query: {
          questions: value.join(','),
          fromPage: 'labels',
          taskType: 'test-assignment',
          token: this.$token,
        },
      })
    },
    transferSelectedApps(ids: number[]): void {
      collectTaskData(ids).then((value) => changeTasks(value))
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!/create/g.test(from.path) && !/edit/g.test(from.path) && !/preview/g.test(from.path)) {
      clearTreeStates()
    }
    next()
  },
  mounted() {
    loadTreeLight()
  },
  destroyed() {
    this.resetFilters()
  },
})
