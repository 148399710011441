import { createEvent, createStore, forward, attach } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getStudentsListFx } from '@/features/api/student/get-student-list'
import {
  getFilterStudentsByIdFx,
  getStudentsCountFx,
} from '@/features/api/results/get-filters-result-list'
import { GetStudentsForIdParams } from '@/features/api/results/types'
import { setStudentsCount } from '@/pages/results/courses-students/parts/filter/parts/lesson-status-dropdown/lesson-status-dropdown.model'

export const studentsDropdownModule = createFilter()

const getStudents = attach({
  effect: getStudentsListFx,
})

const getStudentsById = attach({
  effect: getFilterStudentsByIdFx,
})

const getStudentsCount = attach({
  effect: getStudentsCountFx,
})

export const loadStudents = createEvent<void>()
export const loadStudentsById = createEvent<GetStudentsForIdParams>()
export const $students = createStore<DropdownItem[]>([])

forward({
  from: loadStudents,
  to: getStudents.prepend(() => ({})),
})

forward({
  from: loadStudentsById,
  to: [getStudentsById, getStudentsCount],
})

forward({
  from: getStudents.doneData.map((res) =>
    res.body.data.map((student) => ({
      title: student.full_name
        ? `${student.full_name}`
        : `${student.first_name} ${student.last_name}`,
      name: `${student.id}`,
    }))
  ),
  to: $students,
})

forward({
  from: getStudentsById.doneData.map((res) =>
    res.body.map((student) => ({
      name: `${student.id}`,
      title: `${student?.elk_id !== null ? `${student.elk_id} ` : ''}(${student.id}) ${
        student.first_name
      } ${student.last_name}`,
    }))
  ),
  to: $students,
})

forward({
  from: getStudentsCount.doneData.map((res) =>
    res.body.map((item) => ({
      title: item.name,
      name: item.code,
    }))
  ),
  to: setStudentsCount,
})

forward({
  from: studentsDropdownModule.methods.resetDropdown,
  to: setStudentsCount.prepend(() => []),
})
