







































import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import Icon from '@/ui/icon/Icon.vue'
import {
  groupsDropdownModel,
  $groups,
  $selectedGroups,
  unselectAllGroups,
  deleteSelectedGroup,
  setSelectedGroups,
  loadGroups,
  $isSelectedAllGroups,
  selectAllGroups,
} from '@/pages/users/students/list/parts/groups-dropdown/groups-dropdown.model'

export default Vue.extend({
  components: {
    FilterDropdown,
    Icon,
  },
  effector: {
    ...groupsDropdownModel.store,
    $groups,
    $selectedGroups,
    $isSelectedAllGroups,
  },
  props: {
    label: String,
  },
  data() {
    return {
      groupsDropdownMethods: groupsDropdownModel.methods,
    }
  },
  methods: {
    ...groupsDropdownModel.methods,
    unselectAllGroups,
    selectAllGroups,
    onSelectItem(item: DropdownItem | null, selectedItems: DropdownItem[]) {
      if (item !== null) {
        const queryParams = selectedItems.map((element) => element.name).join()
        item.name === 'all' ? selectAllGroups() : setSelectedGroups(item)
        this.$emit('setItem', queryParams.length > 0 ? `${item.name},${queryParams}` : item.name)
      }
    },
    deleteCourse(event: MouseEvent, item: DropdownItem) {
      event.stopPropagation()
      event.preventDefault()
      deleteSelectedGroup(item)
    },
  },
  mounted() {
    loadGroups()
  },
  destroyed() {
    unselectAllGroups()
  },
})
