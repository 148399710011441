











import Vue, { PropType } from 'vue'
import { DropdownItem } from '@/pages/common/types'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  $groups,
  groupsDropdownModule,
  loadGroups,
  loadGroupsById,
  setSelectedGroups,
} from '@/pages/common/dropdowns/groups/groups-dropdown.model'
import { GetGroupsForIdParams } from '@/features/api/results/types'

export default Vue.extend({
  name: 'GroupsDropdown',
  components: {
    FilterDropdown,
  },
  props: {
    /**
     * isById true для фильтрации групп в результаты - курсы по студентам
     * findId два id нужные для запроса: course и lesson
     * */
    isById: { type: Boolean, default: false },
    findId: {
      type: Object as PropType<GetGroupsForIdParams>,
      default: () => ({
        course: 0,
        lesson: 0,
      }),
    },
  },
  watch: {
    findId(value) {
      if (this.isById && value && value?.course && value?.lesson) {
        loadGroupsById(value)
      }
    },
  },
  effector: {
    $groups,
    ...groupsDropdownModule.store,
  },
  methods: {
    ...groupsDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedGroups(item)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (!this.isById && this.$groups.length === 0) loadGroups()
  },
})
