












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  testsDropdownModel,
  loadTests,
} from '@/pages/exams/part-time-test/create/parts/TestsDropdown/test-list-dropdown.model'

export default Vue.extend({
  name: 'TestsDropdown',
  components: {
    FilterDropdown,
  },
  effector: {
    ...testsDropdownModel.store,
  },
  methods: {
    ...testsDropdownModel.methods,
  },
  mounted() {
    if (this.$items.length === 0) loadTests()
  },
})
