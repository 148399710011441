



































































import Vue, { PropType } from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import SizeInput from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/SizeInput.vue'
import { MovingOnTextDroppableInput } from '@/pages/common/parts/tasks/types'
import ColorPicker from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/parts/areas-for-attaching-images/ColorPicker.vue'

export default Vue.extend({
  name: `AttachingTextItem`,
  components: {
    SizeInput,
    BaseButton,
    Icon,
    FormInput,
    ColorPicker,
  },
  props: {
    answer: {
      type: Object as PropType<MovingOnTextDroppableInput>,
      required: true,
    },
  },
  methods: {
    addAnswer() {
      this.$emit('change', {
        ...this.answer,
        value: [
          ...this.answer.value,
          {
            value: '',
            id: this.answer.value.length + 1,
          },
        ],
      })
    },
    removeAnswer(i: number) {
      if (this.answer.value.length === 1) {
        this.$emit('remove', this.answer)
        return
      }

      const value = [...this.answer.value]
      value.splice(i, 1)

      value
        .filter(({ id }) => id > i)
        .map((answer) => ({
          value: answer.value,
          id: answer.id--,
        }))

      this.$emit('change', {
        ...this.answer,
        value,
      })
    },
    editAnswer(i: number, newValue: string) {
      const value = [...this.answer.value]
      const { id } = value[i]
      value.splice(i, 1, {
        value: newValue,
        id,
      })

      this.$emit('change', {
        ...this.answer,
        value,
      })
    },
  },
})
