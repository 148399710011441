import { render, staticRenderFns } from "./VideoStorage.vue?vue&type=template&id=7d10b8d9&scoped=true&"
import script from "./VideoStorage.vue?vue&type=script&lang=ts&itemscope=true&"
export * from "./VideoStorage.vue?vue&type=script&lang=ts&itemscope=true&"
import style0 from "./VideoStorage.vue?vue&type=style&index=0&id=7d10b8d9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d10b8d9",
  null
  
)

export default component.exports