import { createApiEffect } from '@/features/api/common/create-api-effect'
import { PartTimeCreateFxParams, PartTimeCreateType } from '@/features/api/part-time-test/types'

export const createPartTimeFx = createApiEffect<PartTimeCreateType, PartTimeCreateFxParams>({
  requestMapper: (body) => ({
    method: 'POST',
    url: `/api/exams-app/part-time/tests/`,
    body,
  }),
})
