import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GroupCreateFxParams, GroupCreateType } from '@/features/api/groups/types'

export const createGroupFx = createApiEffect<GroupCreateType, GroupCreateFxParams>({
  requestMapper: (body) => ({
    method: 'POST',
    url: `/api/user-app/groups/`,
    body,
  }),
})
