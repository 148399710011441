

















































































































































import Vue, { VueConstructor } from 'vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import TooltipFilter from '@/pages/results/certificates-generator/table/parts/TooltipFilter.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import CertificatesGeneratorTableHeader from '@/pages/results/certificates-generator/table/parts/CertificatesGeneratorTableHeader.vue'
import {
  ActionsItem,
  CommonInteractedItemParams,
  DisplayContextMenuPayload,
  HttpOptionsType,
  RefsType,
} from '@/pages/common/types'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import {
  $visibility,
  toggleVisibility,
} from '@/pages/results/certificates-generator/table/model/tooltip-filter.model'
import {
  $isLoading,
  $refreshPage,
  certificatesGeneratorTablePageMounted,
  deleteCertificates,
} from '@/pages/results/certificates-generator/table/model/callback-certificates-generator-table-page.model'
import {
  callbackCertificatesGeneratorFilters,
  certificatesGeneratorTablePageParams,
  exportCertificatesGeneratorList,
} from '@/pages/results/certificates-generator/table/model/callback-certificates-generator-table-filters.model'
import { $permissions } from '@/features/session'
import { DEFAULT_ID } from '@/pages/common/constants'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import {
  searchFieldsData,
  CertificatesGeneratorTableFields,
} from '@/pages/results/certificates-generator/table/model/constants'
import { config } from '@/config'
import { getActionsDisplayConditions } from '@/pages/common'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import { axiosClient } from '@/lib/request'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import dayjs from 'dayjs'
import { patchCertificateFX } from '@/features/api/results/certificates-generator/patch-certificate'
import PreviewFile from '@/ui/preview-file/PreviewFile.vue'
import { UpdateCertificatesGeneratorQueryBody } from '@/features/api/results/certificates-generator/types'
import { getCertificatesGeneratorTableActions } from '@/pages/results/certificates-generator/table/constants'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (Vue as VueConstructor<Vue & { $refs: RefsType }>).extend({
  name: 'CertificatesGeneratorTable',
  components: {
    ConfirmDeleteModal,
    PreviewFile,
    Vuetable,
    VuetablePagination,
    CertificatesGeneratorTableHeader,
    ContextMenu,
    TableHeader,
    TooltipCell,
    LoaderBig,
    GeneralFilter,
    TooltipFilter,
    ActionsButton,
    NoDataContent,
  },
  effector: {
    $visibility,
    $isLoading,
    $refreshPage,
    $filterParams: callbackCertificatesGeneratorFilters.store.$filterParams,
    $pageParams: certificatesGeneratorTablePageParams.store.$pageParams,
    $treeView: certificatesGeneratorTablePageParams.store.treeView,
    $currentPage: certificatesGeneratorTablePageParams.store.currentPage,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      searchFields: searchFieldsData,
      fields: CertificatesGeneratorTableFields,
      total: 0,
      selectedRows: [] as number[],
      showDeleteModalType: 'students',
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/certificate-app/certificates/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length && !this.$treeView) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getCertificatesGeneratorTableActions(displayConditions)
    },
  },
  watch: {
    $refreshPage: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.removeSelection()
      },
    },
  },
  methods: {
    dayjs,
    changeFilter: callbackCertificatesGeneratorFilters.methods.changeFilter,
    resetFilters: callbackCertificatesGeneratorFilters.methods.resetFilters,
    applyFilters: callbackCertificatesGeneratorFilters.methods.applyFilters,
    toggleTreeView: certificatesGeneratorTablePageParams.methods.toggleTreeView,
    changePage: certificatesGeneratorTablePageParams.methods.changePage,
    queryToParams: certificatesGeneratorTablePageParams.methods.queryToParams,
    toggleVisibility,
    exportCertificatesGeneratorList,
    onRemove() {
      loadConfirmDeleteModal(this.selectedIds)
    },
    removeCertificates() {
      const payload = this.selectedIds
      deleteCertificates(payload)
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      return axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      toggleVisibility(false)
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    editCertificate() {
      const payload = this.selectedIds[0]
      navigatePush({
        name: 'edit-certificate',
        params: { id: `${payload}` },
      })
    },
    setShownOrAttached(type: 'shown' | 'attached', value: boolean) {
      const body: UpdateCertificatesGeneratorQueryBody = {
        certificate_ids: this.selectedIds,
        [`is_${type}`]: value,
      }
      patchCertificateFX({ body }).then(() => {
        Vue.nextTick(() => this.$refs.vuetable.reload())
      })
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    headerActionsButtonClick(event: MouseEvent) {
      this.handleActionsClick({
        data: { id: this.selectedIds[0] },
        event,
        type: 'header-actions',
      })
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: { id: number }) {
      this.interactedItemId = data.id
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    getShortFileName(fileName: string) {
      return `${fileName.substring(0, 10)}... ${fileName.substring(fileName.length - 3)}`
    },
  },
  mounted() {
    certificatesGeneratorTablePageMounted()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
