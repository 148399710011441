







































































import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  $questionsCourse,
  setQuestionsCourse,
} from '@/pages/learning/learning-courses/create/courses-create-page.model'
import { QuestionsCourseType } from '@/pages/learning/learning-courses/types'
import { getRandomId } from '@/pages/common/parts/tasks/utils'
import BaseButton from '@/ui/button/BaseButton.vue'
import FormInput from '@/ui/input/FormInput.vue'
import Draggable from 'vuedraggable'

export default Vue.extend({
  name: 'QuestionCourses',
  components: {
    Icon,
    BaseButton,
    FormInput,
    Draggable,
  },
  effector: {
    $questionsCourse,
  },
  computed: {},
  methods: {
    setQuestionsCourse,
    handleQuestionChange({ id, question }: QuestionsCourseType) {
      const questionsCourse = this.$questionsCourse.map((qc) =>
        qc.id === id ? { ...qc, question } : qc
      )
      setQuestionsCourse(questionsCourse)
    },
    removeQuestion({ questionId }: { questionId: number }) {
      const questionsCourse = this.$questionsCourse.filter((qc) => qc.id !== questionId)
      setQuestionsCourse(questionsCourse)
    },
    addQuestion() {
      setQuestionsCourse([...this.$questionsCourse, { id: getRandomId(), question: '' }])
    },
    handlerEnd() {
      const questions = JSON.parse(JSON.stringify(this.$questionsCourse))
      questions.map((question: QuestionsCourseType, id: number) => (question.id = id))
      setQuestionsCourse(questions)
    },
  },
})
