import { render, staticRenderFns } from "./DropdownActivator.vue?vue&type=template&id=51faad60&scoped=true&"
import script from "./DropdownActivator.vue?vue&type=script&lang=ts&"
export * from "./DropdownActivator.vue?vue&type=script&lang=ts&"
import style0 from "./DropdownActivator.vue?vue&type=style&index=0&id=51faad60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51faad60",
  null
  
)

export default component.exports