















import Vue from 'vue'
import { LessonsAssignBlock } from '@/pages/learning/learning-lessons/create/model/assignment-block.model'
import {
  DropdownAssignmentBlockNames,
  formToGetTaskList,
  TaskDataType,
  TaskTypeNames,
} from '@/pages/learning/learning-lessons/create/model/types'
import {
  getTestAssignmentListFx,
  getOlympiadAssignmentListFx,
  getLessonTasksAssignmentListFx,
} from '@/features/api/learning/lessons/get-lesson-task-list'
import TasksFilterDropdown from '@/pages/learning/learning-lessons/create/parts/assignment-block/TasksFilterDropdown.vue'
import { GetListQueryParams } from '@/features/api/types'
import { debounce } from 'throttle-debounce'
import { $token } from '@/features/api/common/request'

export default Vue.extend({
  components: {
    TasksFilterDropdown,
  },
  props: {
    label: String,
    blockId: Number,
  },
  data(): {
    page: number
    lastPage: null | number
    isLoading: boolean
    searchString: string
  } {
    return {
      page: 1,
      lastPage: null,
      isLoading: false,
      searchString: '',
    }
  },
  effector: {
    ...LessonsAssignBlock.modules.tasksDropdownModule.store,
    $tasks: LessonsAssignBlock.store.$tasks,
    $selectedTask: LessonsAssignBlock.store.$selectedTask,
    $formToGetTaskList: LessonsAssignBlock.store.$formToGetTaskList,
    $token,
  },
  watch: {
    $formToGetTaskList: {
      handler(newVal, oldVal) {
        const taskTypeChanged = newVal.taskType[this.blockId] !== oldVal.taskType[this.blockId]
        if (taskTypeChanged) {
          this.resetSearchString()
        }
        const thisBlockNewValues = Object.values(newVal).map((item: any) => item[this.blockId])
        const thisBlockOldValues = Object.values(oldVal).map((item: any) => item[this.blockId])
        if (JSON.stringify(thisBlockNewValues) !== JSON.stringify(thisBlockOldValues)) {
          this.lastPage = null
          this.getTask(newVal, this.searchString, 1, taskTypeChanged)
        }
      },
    },
    searchString: {
      handler() {
        this.searchTask()
      },
    },
  },
  methods: {
    ...LessonsAssignBlock.modules.tasksDropdownModule.methods,
    calculateSearchParams(str?: string | null) {
      if (isNaN(Number(str))) return { search_area: 'search_wording' }
      return { search_area: 'search_id' }
    },
    resetSearchString() {
      this.searchString = ''
    },
    searchStringChanged(value: string) {
      this.searchString = value
    },
    onSelectItem(item: TaskDataType | null) {
      LessonsAssignBlock.methods.setSelectedType({
        value: item || null,
        type: DropdownAssignmentBlockNames.tasks,
        id: this.blockId,
      })
    },
    searchTask: debounce(300, false, function getDebounceTask(this: any) {
      this.lastPage = null
      this.getTask(this.$formToGetTaskList, this.searchString, 1)
    }),
    setNewList(testTasksData: TaskDataType[]) {
      const setData =
        this.page > 1 ? [...this.$tasks[this.blockId], ...testTasksData] : testTasksData
      LessonsAssignBlock.methods.setTasksList({ data: setData, id: this.blockId })
    },
    async getTask(
      newVal: formToGetTaskList,
      search?: string | null,
      inputPage: number | undefined | null = null,
      taskTypeChanged?: boolean
    ) {
      if (!this.isLoading) {
        const page = inputPage || this.page + 1
        if (
          !inputPage &&
          this.lastPage &&
          page > this.lastPage &&
          this.$tasks[this.blockId].length > 0
        )
          return
        this.isLoading = true
        taskTypeChanged && this.$emit('loading', true)
        if (newVal) {
          const study_year = newVal.study_year[this.blockId]?.name
            ? Number(newVal.study_year[this.blockId].name)
            : null
          const subject = newVal.subject[this.blockId]?.name
            ? Number(newVal.subject[this.blockId].name)
            : null
          const theme = newVal.theme[this.blockId]?.name
            ? Number(newVal.theme[this.blockId].name)
            : null
          const themeName =
            newVal.taskType[this.blockId] === TaskTypeNames.lesson ? 'folder' : 'theme'
          const query: GetListQueryParams = {
            page,
            per_page: 100,
            ...(study_year ? { study_year } : {}),
            ...(subject ? { subject } : {}),
            ...(theme ? { [themeName]: theme } : {}),
            ...(search ? { search } : {}),
            ...this.calculateSearchParams(search),
            ...(newVal.taskType[this.blockId] !== TaskTypeNames.lesson ? { difficulty_ex: 2 } : {}),
          }
          if (newVal.taskType[this.blockId] === TaskTypeNames.test) {
            try {
              const data = await getTestAssignmentListFx(query)
              this.page = data.body.current_page
              this.lastPage = data.body.last_page
              const testTasksData = data.body.data.map((el) => ({
                wording: el.wording,
                difficulty: el.difficulty,
                id: el.id,
                type: el.type,
                score: el.score,
                useInternalScores: false,
              }))
              this.setNewList(testTasksData)
            } finally {
              this.isLoading = false
              taskTypeChanged && this.$emit('loading', false)
            }
          }
          if (newVal.taskType[this.blockId] === TaskTypeNames.olympiad) {
            try {
              const data = await getOlympiadAssignmentListFx(query)
              this.page = data.body.current_page
              this.lastPage = data.body.last_page
              const olympiadTasksData = data.body.data.map((el) => ({
                wording: el.wording,
                difficulty: el.difficulty,
                id: el.id,
                type: el.type,
                score: el.score,
                useInternalScores: el.has_internal_scores ? el.use_internal_scores : false,
              }))
              this.setNewList(olympiadTasksData)
            } finally {
              this.isLoading = false
              taskTypeChanged && this.$emit('loading', false)
            }
          }
          if (newVal.taskType[this.blockId] === TaskTypeNames.lesson) {
            try {
              const data = await getLessonTasksAssignmentListFx(query)
              this.page = data.body.current_page
              this.lastPage = data.body.last_page
              const lessonTasksData = data.body.data.map((el) => ({
                wording: el.wording,
                difficulty: el.difficulty,
                id: el.id,
                type: el.type,
                score: el.score,
                useInternalScores: el.has_internal_scores ? el.use_internal_scores : false,
              }))
              this.setNewList(lessonTasksData)
            } finally {
              this.isLoading = false
              taskTypeChanged && this.$emit('loading', false)
            }
          }
        }
      }
    },
    showTask(id: number) {
      const taskType = this.$formToGetTaskList.taskType[this.blockId]
      const routeData = this.$router.resolve({
        name: `${taskType}-tasks-edit`,
        query: {
          questions: `${id}`,
          fromPage: 'tasks',
          togglePreview: 'toggle',
        },
        params: { id: `${id}` },
      })
      window.open(routeData.href, '_blank')
    },
    getNextPage() {
      this.getTask(this.$formToGetTaskList, this.searchString)
    },
  },
  beforeDestroy() {
    this.dropdownDestroy()
  },
  mounted() {
    this.getTask(this.$formToGetTaskList, this.searchString, 1)
  },
})
