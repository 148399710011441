import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetExportStudentsForIdParams } from '@/features/api/results/types'

export const exportResultStudentsCoursesListFx = createApiEffect<
  GetExportStudentsForIdParams,
  Blob
>({
  requestMapper: (params) => {
    const { course, lesson, student } = params
    return {
      method: 'GET',
      url: `/api/results-app/results/courses/${course}/${lesson}/${student}/export/`,
      responseType: 'blob',
    }
  },
})
