import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetFolderTreeListResponse, GetListQueryParams } from '@/features/api/types'

export const getFolderTreeFx = createApiEffect<GetListQueryParams, GetFolderTreeListResponse>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/assignment-app/folder/tree/',
    query,
  }),
})
