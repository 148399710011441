import { attach } from 'effector-root'
import { createDropdownVcModel } from '@/pages/common/filters/create-dropdown-vc-model'
import { getTestsListFx } from '@/features/api/test/get-tests-list'
import { TestList } from '@/features/api/test/types'
import { GetListQueryParams } from '@/features/api/types'

const getTestsText = attach({
  effect: getTestsListFx,
  mapParams: (params: GetListQueryParams) => ({ ...params, search_area: 'search_name' }),
})

export const testsTextVcDropdownModel = createDropdownVcModel<TestList>(getTestsText)
