







































import Vue, { PropType } from 'vue'

import MenuWrap, { Position } from '@/ui/menu/MenuWrap.vue'
import SelectMenu from '@/ui/select/parts/SelectMenu.vue'
import DropdownActivator from '@/ui/dropdown/parts/DropdownActivator.vue'

export default Vue.extend({
  name: 'BaseDropdown',
  components: {
    MenuWrap,
    DropdownActivator,
    SelectMenu,
  },
  props: {
    errorMessage: { type: String as PropType<string>, default: '' },
    value: { type: String as PropType<string>, required: true },
    placeholder: { type: String as PropType<string>, default: '' },
    label: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean> },
    readOnlyDropdown: { type: Boolean as PropType<boolean> },
    loading: { type: Boolean as PropType<boolean> },
    smallMenu: { type: Boolean, default: false },
    clearBtn: { type: Boolean, default: true },
    position: { type: String as PropType<Position>, default: 'bottom' },
  },
  data: () => ({
    isOpen: false,
  }),
  watch: {
    disabled: {
      handler(newVal) {
        if (newVal) {
          this.isOpen = false
        }
      },
    },
  },
  methods: {
    onActivatorClick() {
      this.isOpen = !this.isOpen
    },
    closeMenu() {
      this.isOpen = false
    },
    // onClear(e) {
    //   e.preventDefault()
    //   this.$emit('clear', e)
    // },
  },
})
