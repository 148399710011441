


























import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import {
  $previewInfo,
  $modalVisibility,
  modalVisibilityChanged,
} from '@/ui/preview-file/preview-file.model'
import BaseButton from '@/ui/button/BaseButton.vue'
import TPreviewFormat from '@/ui/preview-file/types/TPreviewFormat'
import { previewFormats } from '@/ui/preview-file/constants/previewFormats'
import { renderAsync } from 'docx-preview'
// @ts-ignore
import xlsxPreview from 'xlsx-preview'

export default Vue.extend({
  components: { BaseButton, Modal },
  data: () => ({
    format: null as TPreviewFormat | null,
    text: '',
  }),
  watch: {
    $previewInfo: {
      handler(info) {
        if (!info) {
          return
        }

        this.format =
          (Object.keys(previewFormats).find((format) =>
            previewFormats[format].includes(info.format)
          ) as TPreviewFormat) || null

        Vue.nextTick(() => {
          switch (this.format) {
            case 'doc': {
              this.renderDoc()
              break
            }
            case 'excel': {
              this.renderXls()
              break
            }
            case 'text': {
              this.renderText()
              break
            }
            default: {
              break
            }
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    modalVisibilityChanged,
    getLinkByBlob(blob: Blob): string | null {
      if (!blob) {
        return null
      }

      return window.URL.createObjectURL(blob)
    },
    renderDoc(): void {
      if (!this.$previewInfo || !this.$refs.doc) {
        return
      }

      renderAsync(this.$previewInfo.file, this.$refs.doc as HTMLDivElement)
    },
    async renderXls(): Promise<void> {
      if (!this.$previewInfo || !this.$refs.xls) {
        return
      }

      const result = await xlsxPreview.xlsx2Html(this.$previewInfo.file)
      const url = URL.createObjectURL(
        new Blob([result], {
          type: 'text/html',
        })
      )
      const element = this.$refs.xls as HTMLDivElement
      element.innerHTML = `<object class="xls-wrapper" type="text/html" data="${url}"></object>`
    },
    renderText(): void {
      if (!this.$previewInfo) {
        return
      }

      this.$previewInfo?.file.text().then((text) => {
        this.text = text
      })
    },
    download(): void {
      if (!this.$previewInfo) {
        return
      }

      const fileURL = this.getLinkByBlob(this.$previewInfo.file) as string
      const fileLinkElement = document.createElement('a')

      fileLinkElement.href = fileURL
      fileLinkElement.download = `${this.$previewInfo.filename}.${this.$previewInfo.format}`
      document.body.appendChild(fileLinkElement)

      fileLinkElement.click()
      document.body.removeChild(fileLinkElement)
    },
  },
  effector: {
    $modalVisibility,
    $previewInfo,
  },
})
