import { attach, combine, createEvent, forward, restore, sample, Event, Store } from 'effector-root'
import { condition } from 'patronum'
import { createError } from '@/lib/effector/error-generator'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
// lesson assignment
import { updateFolderFx } from '@/features/api/assignment/folder/update-folder'
import { getFolderFx } from '@/features/api/assignment/folder/get-folder'
import {
  $folders,
  $selectedFolder,
  foldersDropdownModule,
  setSelectedFolder,
} from '@/pages/common/dropdowns/bank/lesson-tasks/position-dropdown/position-dropdown.model'
import { loadTreeLight } from '@/pages/bank/lesson-tasks/list/lesson-page.model'

// exam assignment
import { updateExamFolderFx } from '@/features/api/assignment/exam-assignment/folder/create-folder'
import { getExamFolderFx } from '@/features/api/assignment/exam-assignment/folder/get-folder'
import { loadTreeLight as loadExamTreeLight } from '@/pages/bank/exam-tasks/list/exam-tasks-page.model'
import {
  $folders as $examFolders,
  foldersDropdownModule as examFoldersDropdownModule,
  setSelectedFolder as examSetSelectedFolder,
  $selectedFolder as $examSelectedFolder,
} from '@/pages/common/dropdowns/bank/exam-tasks/folder-dropdown/folder-dropdown.model'

// exam tests
import { updateExamTestFolderFx } from '@/features/api/full-time-tests/create-folder'
import { getExamTestFolderFx } from '@/features/api/full-time-tests/get-folder'
import { loadTreeLight as loadExamTestTreeLight } from '@/pages/exams/full-time-exam-test/list/parts/exam-tests-page.model'
import {
  $folders as $examTestFolders,
  foldersDropdownModule as examTestFoldersDropdownModule,
  setSelectedFolder as examTestSetSelectedFolder,
  $selectedFolder as $examTestSelectedFolder,
} from '@/pages/common/dropdowns/exams/exams-tests-folders/folder-dropdown.model'

import { DEFAULT_ID } from '@/pages/common/constants'
import { findItem } from '@/pages/common/filter-dropdown/lib'
import { ApiEffect } from '@/features/api/common/create-api-effect'
import { CreateFolderType, FolderType } from '@/features/api/assignment/types/types'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'

function editFolderModalModule(
  updateFolderMethod: ApiEffect<{ id: number; body: CreateFolderType }, FolderType, any>,
  getFolderMethod: ApiEffect<number, FolderType, any>,
  treeLightEvent: Event<void>,
  folderEffectorUnits: {
    dropdownModule: ReturnType<typeof createFilter>
    folders: Store<DropdownItem[]>
    selectedFolder: Store<DropdownItem | null>
    setSelectedFolder: Event<DropdownItem | null>
  }
) {
  const updateFolder = attach({
    effect: updateFolderMethod,
  })

  const getFolder = attach({
    effect: getFolderMethod,
  })

  const loadFolder = createEvent<number>()

  const setFolderId = createEvent<number>()
  const $folderId = restore(setFolderId, DEFAULT_ID)

  const checkIfFolderCanBeSend = createEvent<void>()
  const clearFields = createEvent<void>()

  const modalVisibilityChanged = createEvent<boolean>()
  const $modalVisibility = restore(modalVisibilityChanged, false)

  const folderTitleChanged = createEvent<string>()
  const $folderTitle = restore(folderTitleChanged, '').reset(clearFields)

  const $titleErrorModule = createError()

  const $form = combine({
    name: $folderTitle,
    id: $folderId,
    parent_id: folderEffectorUnits.selectedFolder.map((data) =>
      data && data.id ? +data.id : null
    ),
  })

  sample({
    source: $form,
    clock: checkIfFolderCanBeSend,
    fn: (obj) => {
      if (obj.name.trim().length) {
        updateFolder({ id: obj.id, body: obj })
      } else {
        if (!obj.name.trim().length) $titleErrorModule.methods.setError(true)
        errorToastEvent('Необходимо заполнить все обязательные поля')
      }
    },
  })

  forward({
    from: folderTitleChanged,
    to: $titleErrorModule.methods.resetError,
  })

  condition({
    source: modalVisibilityChanged,
    if: (payload: boolean) => !payload,
    then: clearFields,
  })

  forward({
    from: clearFields,
    to: [
      folderEffectorUnits.dropdownModule.methods.resetDropdown,
      $titleErrorModule.methods.resetError,
      folderEffectorUnits.setSelectedFolder.prepend(() => null),
    ],
  })

  const canUpdateApplicationPageChanged = createEvent<boolean>()
  const $canUpdateApplicationPage = restore(canUpdateApplicationPageChanged, false)

  forward({
    from: loadFolder,
    to: [getFolder, canUpdateApplicationPageChanged.prepend(() => false)],
  })

  forward({
    from: getFolder.doneData.map(({ body }) => body),
    to: [folderTitleChanged.prepend(({ name }) => name!), setFolderId.prepend(({ id }) => id!)],
  })

  sample({
    source: folderEffectorUnits.folders,
    clock: getFolder.doneData.map(({ body }) => body),
    fn: (folders, data) => {
      const elem = findItem(`${data.parent_id}`, folders)
      return elem ? { ...elem, parent_name: data.parent_name } : null
    },
    target: folderEffectorUnits.setSelectedFolder,
  })

  sample({
    source: folderEffectorUnits.selectedFolder,
    clock: folderEffectorUnits.setSelectedFolder,
    fn: (data): { name: string; title: string } | null => {
      return data?.id
        ? {
            name: `${data?.id}`,
            title: data.parent_name === undefined ? data.title : data.parent_name!,
          }
        : null
    },
    target: condition({
      if: (data: { name: string; title: string } | null) => !!data,
      then: folderEffectorUnits.dropdownModule.methods.itemChanged.prepend(
        (data: { name: string; title: string } | null) => ({
          name: `${data?.name}`,
          title: data?.title!,
        })
      ),
    }),
  })

  forward({
    from: updateFolder.doneData,
    to: [
      treeLightEvent.prepend(() => ({})),
      modalVisibilityChanged.prepend(() => false),
      successToastEvent('Папка была успешно обновлена!'),
      canUpdateApplicationPageChanged.prepend(() => true),
    ],
  })

  return {
    store: {
      $modalVisibility,
      $folderTitle,
      $titleErrorModule,
      $canUpdateApplicationPage,
    },
    methods: {
      updateFolder,
      getFolder,
      loadFolder,
      checkIfFolderCanBeSend,
      clearFields,
      modalVisibilityChanged,
      folderTitleChanged,
    },
  }
}

export const lessonAssignmentEditFolderModal = editFolderModalModule(
  updateFolderFx,
  getFolderFx,
  loadTreeLight,
  {
    dropdownModule: foldersDropdownModule,
    folders: $folders,
    selectedFolder: $selectedFolder,
    setSelectedFolder,
  }
)

export const examAssignmentEditFolderModal = editFolderModalModule(
  updateExamFolderFx,
  getExamFolderFx,
  loadExamTreeLight,
  {
    dropdownModule: examFoldersDropdownModule,
    folders: $examFolders,
    selectedFolder: $examSelectedFolder,
    setSelectedFolder: examSetSelectedFolder,
  }
)

export const examTestsEditFolderModal = editFolderModalModule(
  updateExamTestFolderFx,
  getExamTestFolderFx,
  loadExamTestTreeLight,
  {
    dropdownModule: examTestFoldersDropdownModule,
    folders: $examTestFolders,
    selectedFolder: $examTestSelectedFolder,
    setSelectedFolder: examTestSetSelectedFolder,
  }
)
