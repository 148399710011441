import { attach, createEvent, createStore, forward, guard, restore, sample } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { formatFoldersData, sortTreeLeaves } from '@/features/lib'
import { GetListQueryParams } from '@/features/api/types'
import { getFolderTreeFx } from '@/features/api/assignment/folder/get-folder-tree'

export const getThemesTreeList = attach({
  effect: getFolderTreeFx,
})

export const getPrerequisitesTreeList = attach({
  effect: getFolderTreeFx,
})

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

export const loadFolderThemes = createEvent<void>()

export const themesDropdownModule = createFilter()

export const $themes = createStore<DropdownItem[]>([]).reset(
  componentDestroyed,
  themesDropdownModule.methods.resetDropdown
)

export const setSelectedTheme = createEvent<DropdownItem | null>()
export const $selectedTheme = restore(setSelectedTheme, null).reset(componentDestroyed)

export const setThemeToSelect = createEvent<DropdownItem | null>()
export const $themeToSelect = restore(setThemeToSelect, null).reset(componentDestroyed)

export const getThemes = createEvent<GetListQueryParams>()

const themesDropdownFilled = createEvent<void>()

$themes.watch((items) => {
  items.length && themesDropdownFilled()
})

sample({
  clock: getThemesTreeList.doneData.map((data) =>
    // @ts-ignore
    formatFoldersData(sortTreeLeaves(data.body.data), false)
  ),
  target: $themes,
})

guard({
  clock: themesDropdownFilled,
  source: $themeToSelect,
  filter: $themeToSelect.map((theme) => theme !== null),
  target: [
    themesDropdownModule.methods.itemChanged,
    setSelectedTheme,
    setThemeToSelect.prepend(() => null),
  ],
})

forward({
  from: loadFolderThemes,
  to: getThemesTreeList.prepend(() => ({ is_prerequisite: false })),
})
