import { attach, createEvent, forward, createStore, restore } from 'effector-root'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { requestDeleteProctorsFx } from '@/features/api/proctors/delete-proctors'
import { RequestDeleteProctors } from '@/pages/exams/proctors/list/model/types'

export const proctorsPageMounted = createEvent<void>()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore(setIsLoading, false)

export const requestDeleteProctors = attach({
  effect: requestDeleteProctorsFx,
  mapParams: (payload: RequestDeleteProctors) => {
    return {
      proctors: payload.proctors,
      ticket_comment: payload.ticket_comment,
    }
  },
})

const needRefresh = createEvent<boolean>()

forward({
  from: requestDeleteProctors.done,
  to: [
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    needRefresh.prepend(() => true),
  ],
})

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(proctorsPageMounted)
