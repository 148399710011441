import { createApiEffect } from '@/features/api/common/create-api-effect'
import IAddFileError from '@/pages/results/certificates-generator/create-certificates/model/interfaces/IAddFileError'

export const createCertificatesFx = createApiEffect<
  FormData,
  Blob,
  { row_errors: IAddFileError[] }
>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/certificate-app/create-preview/',
    body,
  }),
})
