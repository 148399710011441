import { createEvent, createStore, forward, attach } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getEmployeesListFx } from '@/features/api/employee/get-employee-list'

export const authorsDropdownModule = createFilter()

const getAuthors = attach({
  effect: getEmployeesListFx,
})

export const loadAuthors = createEvent<void>()
export const $authors = createStore<DropdownItem[]>([])

forward({
  from: loadAuthors,
  to: getAuthors.prepend(() => ({})),
})

forward({
  from: getAuthors.doneData.map((res) =>
    res.body.data.map((user) => ({
      name: `${user.id}`,
      title: user.full_name!,
    }))
  ),
  to: $authors,
})
