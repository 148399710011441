import { guard, createEvent, createStore, forward, restore, sample, split } from 'effector-root'
import {
  $selectedCourse,
  coursesDropdownModel,
} from '@/pages/learning/learning-lessons/list/model/tooltip-filter.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { addToast } from '@/features/toasts/toasts.model'
import { DropdownItem } from '@/pages/common/types'
import { clearFieldsCourseEdit } from '@/pages/learning/learning-courses/edit/courses-edit-page.model'
import { Lesson } from '@/pages/learning/learning-lessons/list/model/types'

export const changeVisibility = createEvent<boolean>()
export const $modalVisibility = restore(changeVisibility, false)

export const setArrLessonToCourse = createEvent<Lesson[]>()
export const $addLessonToCourseArr = restore(setArrLessonToCourse, [])

export const updateCourses = createEvent<'edit' | 'create'>()
export const editCourse = createEvent()
export const createCourse = createEvent()

export const setLessonsInfo = createEvent<any>()
export const lessonsInfo = createStore([]).reset(clearFieldsCourseEdit)

guard({
  source: changeVisibility,
  filter: (payload) => payload,
  target: coursesDropdownModel.methods.resetItem,
})

forward({
  from: changeVisibility,
  to: coursesDropdownModel.methods.resetItem,
})

const courseInfo = sample({
  source: $selectedCourse,
  clock: editCourse,
  fn: (course) => course,
})

split({
  source: courseInfo,
  match: {
    IsId: (payload) => !!payload,
    NoId: (payload) => !payload,
  },
  cases: {
    IsId: navigatePush.prepend((course: DropdownItem) => ({
      name: 'courses-edit-page',
      params: { id: `${course.name}` },
    })),
    NoId: addToast.prepend(() => ({
      type: 'error',
      message: 'Выберите курс',
    })),
  },
})

forward({
  from: createCourse,
  to: navigatePush.prepend(() => ({
    name: 'courses-creation-page',
  })),
})
