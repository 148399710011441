import { attach, combine, createEvent, createStore, forward } from 'effector-root'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { RequestDeleteQuestions } from '@/features/api/content/types'
import { getFAQListFx } from '@/features/api/content/faq/get-faq-list'
import { requestDeleteQuestionsFx } from '@/features/api/content/faq/delete-questions'

const getFAQList = attach({
  effect: getFAQListFx,
})

export const FAQPageMounted = createEvent<void>()

export const $isLoading = combine(getFAQListFx.pending, (list) => list)

export const requestDeleteQuestions = attach({
  effect: requestDeleteQuestionsFx,
  mapParams: (payload: RequestDeleteQuestions) => {
    return {
      faqs: payload.faqs,
      ticket_comment: payload.ticket_comment,
    }
  },
})

const needRefresh = createEvent<boolean>()

forward({
  from: requestDeleteQuestions.done,
  to: [
    getFAQList,
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    needRefresh.prepend(() => true),
  ],
})

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(FAQPageMounted)
