
















import Vue, { PropType } from 'vue'
import { taskLoader } from '@/pages/results/tests-all/result-tests-all.model'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { FiltersParams } from '@/pages/common/types'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { $permissions } from '@/features/session'

export default Vue.extend({
  components: {
    HeaderPopup,
    DownloadButton,
  },
  effector: {
    $permissions,
    isLoadingExport: taskLoader.store.$exportTaskLoading,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
  },
  methods: {
    setItem(filter: FiltersParams) {
      this.$emit('changeFilter', filter)
      this.$emit('setFilter')
    },
  },
})
