

















import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import SelectedItemsList from '@/pages/common/dropdowns/multiselectDropdown/parts/SelectedItemsList.vue'
import {
  examTestsDropdownModel,
  loadExamTests,
} from '@/pages/common/dropdowns/multiselectDropdown/exams-tests-filter/exams-test-filter.model'

export default Vue.extend({
  name: 'ExamsTestsFilterDropdown',
  components: {
    FilterDropdown,
    SelectedItemsList,
  },
  effector: {
    ...examTestsDropdownModel.store,
  },
  props: {
    isResetDestroy: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...examTestsDropdownModel.methods,
    onItemChanged(item: DropdownItem | null) {
      if (item && !this.$selectedItems.find((i) => i.name === item.name)) {
        this.sendItem([...this.$selectedItems.map((i) => i.name), item.name])
      }
    },
    onDeleteItem(item: string) {
      this.deleteSelectedItem(item)
      this.sendItem(this.$selectedItems.filter((i) => i.name !== item).map((i) => i.name))
    },
    sendItem(itemArr: Array<string>) {
      if (itemArr.length === 0) {
        this.$emit('setItem', undefined)
      } else if (itemArr.length === 1) {
        this.$emit('setItem', itemArr[0])
      } else {
        this.$emit('setItem', itemArr)
      }
    },
  },
  beforeDestroy() {
    if (this.isResetDestroy) {
      this.dropdownDestroy()
    }
  },
  mounted() {
    if (this.$items.length === 0) loadExamTests()
  },
})
