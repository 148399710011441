












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  languagesDropdownModule,
  $languages,
  loadLanguages,
} from '@/pages/common/dropdowns/bank/olympiad-tasks/language-dropdown/language-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    $languages,
    ...languagesDropdownModule.store,
  },
  methods: {
    loadLanguages,
    ...languagesDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (this.$languages.length === 0) loadLanguages()
  },
})
