import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { difficultyDropdownModule } from '@/pages/common/dropdowns/bank/olympiad-tasks/difficulty-dropdown/difficulty-dropdown.model'
import { statusDropdownModule } from '@/pages/common/dropdowns/bank/olympiad-tasks/status-dropdown/status-dropdown.model'
import { typeDropdownModule } from '@/pages/common/dropdowns/bank/olympiad-tasks/type-dropdown/type-dropdown.model'
import { languagesDropdownModule } from '@/pages/common/dropdowns/bank/olympiad-tasks/language-dropdown/language-dropdown.model'
import { tagsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/tags-dropdown-filter/tags-filter-dropdown.model'

export const dropdownModules = {
  classesDropdownModule,
  subjectsDropdownModel,
  difficultyDropdownModule,
  statusDropdownModule,
  typeDropdownModule,
  languagesDropdownModule,
  tagsDropdownModel,
}
