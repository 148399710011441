import { attach, createEvent, forward } from 'effector-root'
import { getGroupsFiltersListFx } from '@/features/api/learning/courses/get-groups-filter-list'
import { createFiltersModel } from '@/pages/common/filters/create-filters-multiselect-model'

const getGroups = attach({
  effect: getGroupsFiltersListFx,
})

export const loadGroups = createEvent<void>()
export const groupsDropdownModel = createFiltersModel()

forward({
  from: loadGroups,
  to: getGroups.prepend(() => ({})),
})

forward({
  from: getGroups.doneData.map((res) =>
    res.body.map((field) => ({ name: `${field.id}`, title: `(${field.id})${field.name}` }))
  ),
  to: groupsDropdownModel.methods.setItems,
})
