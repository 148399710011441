







































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FullSizeSlider from '@/pages/applications/modals/outgoing-comment/parts/FullsizeSlider.vue'
import {
  $modalVisibility,
  $selectedId,
  modalVisibilityChanged,
  $comment,
} from '@/pages/applications/modals/outgoing-comment/outgoing-comment.model'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'
import MainSlider from '@/pages/applications/modals/outgoing-comment/parts/MainSlider.vue'

SwiperCore.use([Navigation])

export default Vue.extend({
  name: 'CommentModal',
  components: {
    Modal,
    Icon,
    BaseButton,
    FullSizeSlider,
    MainSlider,
  },
  effector: {
    $modalVisibility,
    $selectedId,
    $comment,
  },
  props: {
    taskId: { type: Number, required: true },
  },
  data: () => ({
    currentSlide: 0,
    fullSizeSliderIsVisible: false,
  }),
  methods: {
    modalVisibilityChanged,
    openFullSizeSlider(clickedSlideIndex: number) {
      this.currentSlide = clickedSlideIndex
      this.fullSizeSliderIsVisible = true
    },
    closeFullSizeSlider(slideIndex: number) {
      this.currentSlide = slideIndex
      this.fullSizeSliderIsVisible = false
    },
  },
})
