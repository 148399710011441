import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportTicketsListQueryParams } from '@/features/api/ticket/types'

export const exportTicketsListFx = createApiEffect<ExportTicketsListQueryParams, Blob>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/ticket-app/moderation-ticket/list/export/',
    responseType: 'arraybuffer',
    query,
  }),
})
