import { TreeElementType } from '@/features/api/types'
import { createEvent, createStore } from 'effector-root'
import { OpenedTreeBranchesKey, TreeNodeIdProp } from '@/pages/common/types'
import { getBranchKey } from '@/pages/common'

type StoreOpenedBranchPayload = {
  id: TreeNodeIdProp
  opened: boolean
  type: TreeElementType
  isPrerequisite?: boolean
  parentNodeId?: TreeNodeIdProp | null
  parentNodeType?: TreeElementType | null
}

export type OpenedTreeBranches = {
  [K in OpenedTreeBranchesKey]?: {
    opened: boolean
    isPrerequisite: boolean
  }
}

export const clearTreeStates = createEvent<void>()
export const storeOpenedBranch = createEvent<StoreOpenedBranchPayload>()
export const $openedTreeBranches = createStore<OpenedTreeBranches>({})
  .on(storeOpenedBranch, (state, payload) => {
    const branchKey = getBranchKey(
      payload.id,
      payload.type,
      payload.parentNodeId!,
      payload.parentNodeType!
    )
    const branch = {
      [branchKey]: {
        opened: payload.opened,
        isPrerequisite: payload.isPrerequisite !== undefined ? !!payload.isPrerequisite : false,
      },
    }

    return { ...state, ...branch }
  })
  .reset(clearTreeStates)
