






























import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'BaseTextarea',
  components: {
    Icon,
  },
  props: {
    value: { type: [String, Number] as PropType<string | number>, default: '' },
    maxLength: { type: Number },
    placeholder: { type: String as PropType<string> },
    label: { type: String as PropType<string> },
    clearBtn: { type: Boolean as PropType<boolean>, default: false },
  },
  model: {
    event: 'input',
    prop: 'value',
  },
  data: () => ({
    showClearBtn: false,
  }),
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.showClearBtn = newVal !== oldVal && newVal.length && this.clearBtn
      },
    },
  },
  mounted() {
    if (this.value && this.clearBtn) {
      this.showClearBtn = true
    }
  },
})
