import { createEvent, createStore, restore } from 'effector-root'

export const examsAnswersPageMounted = createEvent<void>()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore(setIsLoading, false)

const needRefresh = createEvent<boolean>()

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(examsAnswersPageMounted)
