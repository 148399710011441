































import Vue, { PropType } from 'vue'
import Card from '@/ui/card/Card.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $isPrerequisite,
  toggleIsPrerequisite,
} from '@/pages/dictionary/themes/create/parts/header/header.model'

export default Vue.extend({
  components: {
    Card,
    BaseSwitch,
    BaseButton,
  },
  effector: {
    $isPrerequisite,
  },
  props: {
    title: { type: String as PropType<string> },
  },
  methods: {
    toggleIsPrerequisite,
  },
})
