import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import { getTicketsListFx } from '@/features/api/ticket/moderation/get-tickets-list'
import { GetTicketsListQueryParams, UpdateTicketBulkType } from '@/features/api/ticket/types'
import { updateTicketBulkFx } from '@/features/api/ticket/moderation/update-ticket-bulk'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { outgoingApplicationsFilters } from '@/pages/applications/outgoing/parts/filter/filter.model'
import { modalVisibilityChanged } from '@/pages/applications/modals/cancel/cancel.model'
import { updateApplicationsCounters } from '@/pages/common/navigation/navigation.model'
import { exportTicketsListFx } from '@/features/api/ticket/moderation/export-tickets-list'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'

export const cancelApplicationsFx = attach({
  effect: updateTicketBulkFx,
  mapParams: (params: UpdateTicketBulkType) => ({
    ...params,
    accept: null,
    send_to_revision: null,
    comment_id: null,
    set_moderator: null,
    moderator_id: null,
    cancel_outcome: true,
  }),
})

export const exportTicketsList = attach({
  effect: exportTicketsListFx,
  source: [outgoingApplicationsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportTicketsList.doneData.watch((res) => fileDownload(res.body, 'outgoingTicketsList.xlsx'))

export const outgoingApplicationsPageParams = createPageParamsModel()

export const $isLoading = combine(getTicketsListFx.pending, (list) => list)

export const canUpdateTableChanged = createEvent<boolean>()
export const $canUpdateTable = restore(canUpdateTableChanged, false)

const getOutgoingApplicationsList = attach({
  effect: getTicketsListFx,
  mapParams: (params: GetTicketsListQueryParams) => ({ ...params, created_by_me: true }),
})

export const loadList = createEvent<GetTicketsListQueryParams>()

forward({
  from: loadList,
  to: [getOutgoingApplicationsList, canUpdateTableChanged.prepend(() => false)],
})

forward({
  from: cancelApplicationsFx.doneData,
  to: [
    successToastEvent('Заявки(а) были успешно отменены'),
    modalVisibilityChanged.prepend(() => false),
    canUpdateTableChanged.prepend(() => true),
    updateApplicationsCounters,
  ],
})

forward({
  from: outgoingApplicationsFilters.methods.resetFilters,
  to: loadList.prepend(() => ({})),
})

sample({
  clock: outgoingApplicationsFilters.methods.applyFilters,
  source: outgoingApplicationsFilters.store.$filterParams,
  target: loadList,
})
