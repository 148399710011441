




















































import Vue from 'vue'
import ClickOutside from '@/features/directives/click-outside'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseCheckbox from '@/ui/checkbox/BaseCheckbox.vue'
import { FiltersParams } from '@/pages/common/types'
import {
  filesFilters,
  toggleVisibility,
  reset,
  $checkboxes,
  updateCheckboxes,
} from '@/pages/dictionary/files/parts/filter/filter.model'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'FilesFilter',
  components: {
    BaseButton,
    BaseSwitch,
    BaseCheckbox,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  effector: {
    $filterParams: filesFilters.store.$filterParams,
    $checkboxes,
  },
  data: () => ({
    fileTypes: [
      { title: 'Документ', name: 'doc' },
      { title: 'Архив', name: 'zip' },
      { title: 'Картинка', name: 'img' },
      { title: 'Аудио', name: 'audio' },
      { title: 'Видео', name: 'video' },
      { title: 'Другое', name: 'file' },
    ],
  }),
  methods: {
    toggleVisibility,
    reset,
    updateCheckboxes,
    closeFilter({ target }: any) {
      // check for close icon (clear filter dropdown)
      if (target.href && target.href.baseVal === '#close-icon') {
        return
      }
      // check for general filter icon
      if (
        target.id !== 'filter-icon' &&
        target.parentElement &&
        target.parentElement.id !== 'filter-icon'
      ) {
        toggleVisibility(false)
      }
    },
    setItem(filter: FiltersParams) {
      this.$emit('changeFilter', filter)
    },
    applyFilters() {
      const ckbx = Object.entries(this.$checkboxes)
        .filter((el) => el[1])
        .map((el) => el[0])
      const res = ckbx.join(',')
      this.setItem({ file_type: res.length > 0 ? res : undefined })
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
    },
  },
})
