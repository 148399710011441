import { createEvent, forward, sample } from 'effector-root'
import { debounce } from 'patronum'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { ApiEffect } from '@/features/api/common/create-api-effect'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'

// для value - onChange инпута с загрузкой инфы через АПИ
export function createDropdownVcModel<T extends { id: number; name: string }>(
  loader: ApiEffect<GetListQueryParams, TableDataResponse<T[]>, any>
) {
  const baseModel = createDropdownModel(loader)

  const load = createEvent<void>()

  const search = createEvent<void>()

  sample({
    clock: load,
    source: {
      $nextPage: baseModel.store.$nextPage,
      search: baseModel.store.$searchString,
    },
    fn: (params): GetListQueryParams => ({
      page: params.$nextPage,
      ...(params.search && { search: params.search }),
    }),
    target: loader,
  })

  sample({
    clock: search,
    source: {
      search: baseModel.store.$searchString,
    },
    fn: (params): GetListQueryParams => ({
      page: 1,
      ...(params.search && { search: params.search }),
    }),
    target: loader,
  })

  const debounced = debounce({
    source: baseModel.store.$searchString,
    timeout: 300,
  })

  forward({
    from: debounced,
    to: search,
  })

  forward({
    from: baseModel.methods.canLoadNextPage,
    to: load,
  })

  sample({
    clock: loader.doneData,
    source: {
      items: baseModel.store.$items,
      $nextPage: baseModel.store.$nextPage,
      search: baseModel.store.$searchString,
    },
    fn: ({ items }, res) => {
      const newData = res.body.data.map((field) => ({
        name: `${field.id}`,
        title: field.name,
      }))
      if (res.body.current_page === 1) {
        return [...newData]
      }
      return [...items, ...newData]
    },
    target: baseModel.store.$items,
  })

  return {
    store: {
      ...baseModel.store,
    },
    methods: {
      ...baseModel.methods,
      load,
      search,
    },
  }
}
