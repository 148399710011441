




























import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'TooltipCell',
  components: {
    Icon,
  },
  props: {
    title: { type: String },
    rowId: { type: Number, required: true },
    dataId: { type: String, default: '' },
    iconType: { type: String },
    description: { type: String },
    showLength: { type: Number, default: 15 },
  },
  computed: {
    iconTooltip() {
      return {
        content: this.description,
      }
    },
    options() {
      return {
        content: this.title.length > 250 ? `${this.title.slice(0, 250)}...` : this.title,
      }
    },
  },
  methods: {
    handleRightClick(event: any) {
      event.preventDefault()
      this.$emit('onRightClick', { data: { id: this.$props.rowId }, event })
    },
  },
  mounted() {
    const container = document.querySelector(`#cell-${this.$props.rowId}-${this.$props.dataId}`)
    container && container.addEventListener('contextmenu', this.handleRightClick)
  },
  beforeDestroy() {
    const container = document.querySelector(`#cell-${this.$props.rowId}-${this.$props.dataId}`)
    container && container.removeEventListener('contextmenu', this.handleRightClick)
  },
})
