




















































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import ThemesDropdown from '@/pages/common/dropdowns/themes-tree/ThemesDropdown.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  $subjectErrorModule,
  $classErrorModule,
  $titleErrorModule,
  $themeErrorModule,
  checkIfThemeCanBeSend,
  $labelTitle,
  labelTitleChanged,
} from '@/pages/labels/parts/modals/label-edition/label-edition.modal'

export default Vue.extend({
  components: {
    Modal,
    Icon,
    SubjectsDropdown,
    ClassesDropdown,
    ThemesDropdown,
    BaseTextarea,
    BaseButton,
  },
  effector: {
    $modalVisibility,
    subjectError: $subjectErrorModule.store.$error,
    classError: $classErrorModule.store.$error,
    titleError: $titleErrorModule.store.$error,
    themeError: $themeErrorModule.store.$error,
    $labelTitle,
  },
  methods: {
    modalVisibilityChanged,
    checkIfThemeCanBeSend,
    labelTitleChanged,
  },
})
