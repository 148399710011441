import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { attach, createStore, forward, createEvent } from 'effector-root'
import { getStatusesListFx } from '@/features/api/student/get-statuses'
import { DropdownItem } from '@/pages/common/types'

export const statusesDropdownModel = createFilter()

export const loadStatuses = createEvent<void>()

export const selectStatus = createEvent<DropdownItem>()

const getStatuses = attach({
  effect: getStatusesListFx,
  mapParams: () => ({}),
})

export const $statuses = createStore<DropdownItem[]>([]).on(getStatuses.doneData, (_, res) =>
  res.body.map((payload: any) => ({ name: `${payload.id}`, title: payload.name }))
)

forward({
  from: loadStatuses,
  to: getStatuses,
})

forward({
  from: selectStatus,
  to: statusesDropdownModel.methods.itemChanged,
})
