import { createEvent, forward, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { debounce } from 'patronum'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { getStudentsListFx } from '@/features/api/appointments/get-students-list'
import { StudentAppointmentsData } from '@/pages/exams/appointments/create/model/types'

export const studentsDropdownModel = createDropdownModel<StudentAppointmentsData>(getStudentsListFx)

export const loadStudents = createEvent<void>()
export const searchStudents = createEvent<void>()

sample({
  clock: searchStudents,
  source: {
    search: studentsDropdownModel.store.$searchString,
    groups: groupsDropdownModel.store.$selectedItems,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search ? { search: params.search, search_area: 'search_student' } : {}),
    ...(params.groups && params.groups.length > 0
      ? { groups: params.search, search_area: 'search_student' }
      : {}),
  }),
  target: getStudentsListFx,
})

sample({
  clock: loadStudents,
  source: {
    $nextPage: studentsDropdownModel.store.$nextPage,
    search: studentsDropdownModel.store.$searchString,
    groups: groupsDropdownModel.store.$selectedItems,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search, search_area: 'search_student' }),
    ...(params.groups && params.groups.length > 0
      ? { groups_ids: params.groups.map((group) => group.name), search_area: 'search_student' }
      : {}),
  }),
  target: getStudentsListFx,
})

const debounced = debounce({
  source: studentsDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchStudents,
})

forward({
  from: [studentsDropdownModel.methods.canLoadNextPage, groupsDropdownModel.store.$selectedItems],
  to: loadStudents,
})

sample({
  clock: getStudentsListFx.doneData,
  source: {
    items: studentsDropdownModel.store.$items,
  },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((user) => ({
      name: `${user.id}`,
      title: `${user?.elk_id !== null ? `${user.elk_id} ` : ''}(${user.id}) ${user.fullname}`,
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: studentsDropdownModel.store.$items,
})
