const url_slug = (word: string): string => {
  if (!word) return ''
  const liteWord = word.trim().toLowerCase()
  let answer = ''
  const a: {
    [key: string]: string
  } = {}

  a['ё'] = 'yo'
  a['й'] = 'i'
  a['ц'] = 'ts'
  a['у'] = 'u'
  a['к'] = 'k'
  a['е'] = 'e'
  a['н'] = 'n'
  a['г'] = 'g'
  a['ш'] = 'sh'
  a['щ'] = 'sch'
  a['з'] = 'z'
  a['х'] = 'h'
  a['ъ'] = ''
  a['Ф'] = 'F'
  a['Ы'] = 'I'
  a['В'] = 'V'
  a['А'] = 'a'
  a['П'] = 'P'
  a['Р'] = 'R'
  a['О'] = 'O'
  a['Л'] = 'L'
  a['Д'] = 'D'
  a['Э'] = 'E'
  a['ф'] = 'f'
  a['ы'] = 'i'
  a['в'] = 'v'
  a['а'] = 'a'
  a['п'] = 'p'
  a['р'] = 'r'
  a['о'] = 'o'
  a['л'] = 'l'
  a['д'] = 'd'
  a['ж'] = 'zh'
  a['э'] = 'e'
  a['я'] = 'ya'
  a['ч'] = 'ch'
  a['с'] = 's'
  a['м'] = 'm'
  a['и'] = 'i'
  a['т'] = 't'
  a['ь'] = ''
  a['б'] = 'b'
  a['ю'] = 'yu'

  for (let i = 0; i < liteWord.length; i++) {
    if (liteWord.hasOwnProperty(i)) {
      if (a[liteWord[i]] === undefined) {
        answer += liteWord[i]
      } else {
        answer += a[liteWord[i]]
      }
    }
  }
  return answer
    .replace(/[^\w^-]+/g, '')
    .replace(/ +/g, '-')
    .replace(/-+/g, '-')
}

export default url_slug
