











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  attemptsStatusDropdownModule,
  mapAttemptsStatus,
} from '@/pages/common/dropdowns/exams/attempts-status-dropdown/attempts-status-dropdown.model'

export default Vue.extend({
  name: 'AttemptsStatusFilterDropdown',
  components: {
    FilterDropdown,
  },
  props: {
    label: {
      type: String,
      default: 'Изменить итоговый статус попытки',
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
  },
  effector: {
    ...attemptsStatusDropdownModule.store,
  },
  methods: {
    ...attemptsStatusDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (!this.isFilter) {
      this.setItems(mapAttemptsStatus)
      this.itemChanged(mapAttemptsStatus[0])
    } else {
      this.setItems(mapAttemptsStatus.slice(1))
    }
  },
})
