import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { Employee, EmployeePermissions } from '@/features/api/employee/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { Test } from '@/features/api/test/types'

export const fullAttemptsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    dataClass: 'wrap',
    title: 'ID попытки',
    width: '100px',
  },
  {
    name: 'student_answer',
    dataClass: 'wrap',
    title: 'Ответы ученика ',
    width: '150px',
  },
  {
    name: 'test',
    sortField: 'test',
    dataClass: 'wrap',
    title: 'Экзаменационный тест',
    width: '210px',
    formatter: (test: Test) => {
      return test?.internal_name || '–'
    },
  },
  {
    name: 'subject',
    sortField: 'subject',
    title: 'Предмет',
    width: '140px',
    formatter(subject: { id: number; name: string }) {
      return subject ? subject.name : '-'
    },
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    title: 'Класс',
    width: '100px',
    formatter(year: { id: number; name: string }) {
      return year ? year.name : '-'
    },
  },
  {
    name: 'studentId',
    sortField: 'student',
    dataClass: 'center aligned',
    title: 'ID ученика',
    width: '130px',
  },
  {
    name: 'student_elk_id',
    sortField: 'student_elk_id',
    title: 'ID ЕЛК',
    width: '90px',
  },
  {
    name: 'student_name',
    sortField: 'student_name',
    title: 'Ученик',
    width: '160px',
  },
  {
    name: 'groups',
    sortField: 'groups',
    dataClass: 'wrap',
    title: 'Группа',
    width: '170px',
    formatter(groups: { name: string }[] | string) {
      if (!groups || groups.length === 0) return 'Не задано'
      if (Array.isArray(groups)) {
        return groups.map((group) => group.name).join(', ')
      }
      return groups
    },
  },
  {
    name: 'available_from',
    sortField: 'available_from',
    title: 'Экзамен можно начать с',
    titleClass: 'wrap',
    width: '160px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'available_to',
    sortField: 'available_to',
    title: 'Экзамен можно начать до',
    titleClass: 'wrap',
    width: '160px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'appointment',
    sortField: 'appointment',
    title: 'ID назначения',
    dataClass: 'wrap',
    width: '160px',
  },
  {
    name: 'is_proctoring_activated',
    sortField: 'is_proctoring_activated',
    title: 'Прокторинг',
    width: '140px',
    formatter(proctoring: boolean) {
      return proctoring ? 'Активирован' : 'Не активирован'
    },
  },
  {
    name: 'proctors',
    sortField: 'proctors',
    title: 'Прокторы',
    dataClass: 'wrap',
    width: '140px',
    formatter(proctors: { id: number; service: string; is_active: boolean; login: string }[]) {
      if (proctors.length === 0) return '-'
      return proctors.map((proctor) => proctor.login).join(', ')
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус попытки',
    dataClass: 'wrap',
    width: '160px',
    formatter(status: number) {
      if (status === 0) return 'Назначен'
      if (status === 3) return 'Пропущен'
      return status === 1 ? 'В процессе прохождения' : 'Завершен'
    },
  },
  {
    name: 'skipped_parts',
    sortField: 'skipped_parts',
    title: 'Пропущенные части',
    titleClass: 'wrap',
    dataClass: 'wrap',
    width: '160px',
    formatter(missed: string) {
      return missed || '-'
    },
  },
  {
    name: 'started_at',
    sortField: 'started_at',
    title: 'Время начала прохождения теста',
    titleClass: 'wrap',
    width: '180px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : '–'
    },
  },
  {
    name: 'finished_at',
    sortField: 'finished_at',
    title: 'Время окончания прохождения теста',
    titleClass: 'wrap',
    width: '180px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : '–'
    },
  },
  {
    name: 'duration',
    sortField: 'duration',
    title: 'Длительность прохождения теста (чч:мм:сс)',
    titleClass: 'wrap',
    width: '190px',
    formatter(datetime: string) {
      return datetime
    },
  },
  {
    name: 'intro_part_duration',
    sortField: 'intro_part_duration',
    title: 'Время прохождения вводной части (чч:мм:сс)',
    titleClass: 'wrap',
    width: '190px',
    formatter(datetime: string) {
      return datetime
    },
  },
  {
    name: 'main_part_duration',
    sortField: 'main_part_duration',
    title: 'Время прохождения основной части (чч:мм:сс)',
    titleClass: 'wrap',
    width: '190px',
    formatter(datetime: string) {
      return datetime
    },
  },
  {
    name: 'load_part_duration',
    sortField: 'load_part_duration',
    title: 'Время загрузки файлов (чч:мм:сс)',
    titleClass: 'wrap',
    width: '190px',
    formatter(datetime: string) {
      return datetime
    },
  },
  {
    name: 'total_score',
    sortField: 'total_score',
    titleClass: 'wrap',
    title: 'Макс. возможный результат за автоматизированные задания (баллы)',
    width: '210px',
  },
  {
    name: 'total_student_score',
    sortField: 'total_student_score',
    titleClass: 'wrap',
    title: 'Результат ученика по автоматизированным заданиям (баллы)',
    width: '210px',
  },
  {
    name: 'result_percent',
    sortField: 'result_percent',
    titleClass: 'wrap',
    title: 'Результат ученика по автоматизированным заданиям (проценты)',
    width: '210px',
  },
  {
    name: 'is_passed',
    sortField: 'is_passed',
    titleClass: 'wrap',
    title: 'Попытка зачтена (без прохождения)',
    width: '210px',
    formatter(is_passed: boolean) {
      return is_passed ? 'да' : 'нет'
    },
  },
  {
    name: 'test_structure',
    title: 'Данные структуры теста (id темы: [id задания])',
    titleClass: 'wrap',
    width: '200px',
    formatter(data: string) {
      return data || 'Отсутствует'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    titleClass: 'wrap',
    width: '220px',
    formatter(user: Employee | null) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '110px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Обновил',
    titleClass: 'wrap',
    width: '220px',
    formatter(user: Employee | null) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Изменено',
    width: '110px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : ''
    },
  },
  {
    name: 'results_sent',
    sortField: 'results_sent',
    titleClass: 'wrap',
    title: 'Результаты отправлены в систему проверки',
    width: '180px',
    formatter(result: string) {
      return result === 'yes' ? 'Да' : 'Нет '
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'test_id', title: 'ID экзаменационного теста' },
  { name: 'test_name', title: 'Название экзаменационного теста' },
  { name: 'all', title: 'Название экзаменационного теста' },
  { name: 'subject', title: 'Предмет' },
  { name: 'class', title: 'Класс' },
  { name: 'all', title: 'Группа' },
  { name: 'all', title: 'ID абитуриента' },
  { name: 'all', title: 'Имя и фамилия абитуриента' },
  { name: 'all', title: 'Результатам (баллы)' },
  { name: 'all', title: 'результатам (проценты)' },
]

export const getExamAttemptsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'delete',
      title: 'Удалить',
      action: 'onRemoveAttempts',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.exams_attempt_attempts.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
