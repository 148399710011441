












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  statusesDropdownModel,
  $statuses,
  $selectedStatus,
  loadStatuses,
  changeFieldValue,
} from '@/pages/users/students/edit/model/form-fields.model'
import { EditStudentFields } from '@/pages/users/students/list/model/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...statusesDropdownModel.store,
    $statuses,
    $selectedStatus,
  },
  props: {
    label: String,
  },
  data() {
    return {
      statusesDropdownMethods: statusesDropdownModel.methods,
    }
  },
  methods: {
    ...statusesDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      if (item !== null) {
        changeFieldValue({ type: EditStudentFields.status, value: item })
        this.$emit('setItem', item.name)
      }
    },
  },
  mounted() {
    loadStatuses()
  },
})
