import { ActionsDisplayConditions } from '@/pages/common'
import { ActionsItem } from '@/pages/common/types'

export const getCommentsOnLessonsActions = (
  displayOptions: ActionsDisplayConditions,
  selectedRows: number[]
): ActionsItem[] => {
  const actions = [
    // TODO Добавить функцию редактирования текста комментария
    // {
    //   name: 'editComment',
    //   title: 'Редактировать',
    //   action: 'onEditComment',
    //   isDisplayed:
    //     (displayOptions.isTableItem || displayOptions.isHeaderItem || displayOptions.isTreeItem) &&
    //     selectedRows.length < 2,
    //   hasPermissionToSee: true,
    // },
    {
      name: 'setIsAttached',
      title: 'Закрепить',
      action: 'onSetIsAttached',
      isDisplayed:
        (displayOptions.isTableItem || displayOptions.isHeaderItem || displayOptions.isTreeItem) &&
        selectedRows.length < 2,
      hasPermissionToSee: true,
    },
    {
      name: 'setIsNotAttached',
      title: 'Открепить',
      action: 'onSetIsNotAttached',
      isDisplayed:
        (displayOptions.isTableItem || displayOptions.isHeaderItem || displayOptions.isTreeItem) &&
        selectedRows.length < 2,
      hasPermissionToSee: true,
    },
    {
      name: 'setIsShown',
      title: 'Отобразить',
      action: 'onSetIsShown',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
    {
      name: 'setIsNotShown',
      title: 'Скрыть',
      action: 'onSetIsNotShown',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
