import { attach, createEvent, forward, createStore, restore } from 'effector-root'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { requestDeleteAttemptsFx } from '@/features/api/full-attempts/delete-attempts.ts'
import { RequestDeleteAttempts } from '@/pages/exams/attempts/list/model/types'
import { TableField } from '@/pages/applications/types'
import { fullAttemptsTableFields } from '@/pages/exams/full-attempts/list/model/constants'

type SetColumnsColumns = {
  key?: boolean
}
export const attemptsPageMounted = createEvent<void>()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore(setIsLoading, false)

export const setNewTableFields = createEvent<SetColumnsColumns>()
export const setDefaultTableFields = createEvent<void>()
export const $tableFields = createStore<TableField[]>(fullAttemptsTableFields)
  .on(setNewTableFields, (fields, settings) =>
    fullAttemptsTableFields.filter((field) => settings[field.name])
  )
  .reset(setDefaultTableFields)

export const requestDeleteAttempts = attach({
  effect: requestDeleteAttemptsFx,
  mapParams: (payload: RequestDeleteAttempts) => {
    return {
      attempts: payload.attempts,
      ticket_comment: payload.ticket_comment,
    }
  },
})

const needRefresh = createEvent<boolean>()

forward({
  from: requestDeleteAttempts.done,
  to: [
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    needRefresh.prepend(() => true),
  ],
})

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(attemptsPageMounted)
