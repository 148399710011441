import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { OpenedTreeBranches } from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { Store } from 'effector-root'
import { TreeElementType } from '@/features/api/types'
import { TreeNodeIdProp } from '@/pages/common/types'
import { EmployeeList } from '@/features/api/employee/types'

export type ActionsDisplayConditions = {
  isHeaderActions: boolean
  isTreeThemeFolder: boolean
  isTreeStudyYearFolder: boolean
  isTreeFolder: boolean
  isTreeItem: boolean
  isTableItem: boolean
  isTableItems: boolean
  isHeaderItem: boolean
  isHeaderItems: boolean
}

export function getActionsDisplayConditions(
  conditionType: ContextMenuType | 'tableHeader',
  selectedRowsLength: number,
  treeView = false,
  isPrerequisite = false
): ActionsDisplayConditions {
  return {
    isHeaderActions: conditionType === 'header-actions' && selectedRowsLength === 1,
    isTreeThemeFolder: treeView && conditionType === 'folder' && isPrerequisite,
    isTreeStudyYearFolder: treeView && conditionType === 'folder',
    isTreeFolder: treeView && conditionType === 'folder',
    isTreeItem: treeView && conditionType === 'item',
    isTableItem: !treeView && conditionType === 'item' && selectedRowsLength <= 1,
    isTableItems: !treeView && conditionType === 'item' && selectedRowsLength > 1,
    isHeaderItem: conditionType === 'tableHeader' && selectedRowsLength === 1,
    isHeaderItems: conditionType === 'tableHeader' && !!selectedRowsLength,
  }
}

export function getBranchKey(
  branchId: TreeNodeIdProp,
  branchType: TreeElementType,
  parentBranchId: TreeNodeIdProp | null,
  parentBranchType: TreeElementType | null
) {
  const currentBranchKey = `${branchType}-${branchId}`
  const parentBranchKey =
    parentBranchId && parentBranchType && `${parentBranchType}-${parentBranchId}`
  if (parentBranchKey) {
    return `${parentBranchKey}-${currentBranchKey}`
  }
  return currentBranchKey
}

export function isBranchOpened(
  openedTreeBranches: Store<OpenedTreeBranches>,
  branchId: TreeNodeIdProp,
  branchType: TreeElementType,
  parentBranchId: TreeNodeIdProp | null,
  parentBranchType: TreeElementType | null,
  isPrerequisite = false
): boolean {
  const openedTreeBranchesState = openedTreeBranches.getState()
  const treeBranchKey = getBranchKey(branchId, branchType, parentBranchId, parentBranchType)
  const treeBranchInfo = openedTreeBranchesState[treeBranchKey]
  if (
    treeBranchInfo &&
    treeBranchInfo.isPrerequisite === isPrerequisite &&
    treeBranchInfo?.opened
  ) {
    return true
  }
  return false
}

export function getEmployeeName(data: EmployeeList) {
  return data.full_name?.trim() ? data.full_name : data.email || ''
}

export const getUserName = (data: {
  id: number
  first_name: string
  last_name: string
}): string => {
  return data?.first_name && data?.last_name
    ? `(${data.id}) ${data.first_name} ${data.last_name}`
    : '-'
}
