import { createApiEffect } from '@/features/api/common/create-api-effect'
import { FAQType } from '@/features/api/content/types'

export const createQuestionFx = createApiEffect<Partial<FAQType>, FAQType>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/content-app/faq/',
    body,
  }),
})
