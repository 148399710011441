






















import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  $answer,
  $question,
  changeFieldValue,
} from '@/pages/content/faq/edit/model/form-fields.model'

export default Vue.extend({
  name: 'EditQuestionContent',
  components: {
    FormInput,
    Wysiwyg,
  },
  effector: {
    $answer,
    $question,
  },
  methods: {
    changeField(type: 'question' | 'answer', value: string) {
      changeFieldValue({ type, value })
    },
  },
})
