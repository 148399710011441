















import Vue from 'vue'
import Divider from '@/ui/divider/Divider.vue'

export default Vue.extend({
  name: 'BaseHeader',
  components: { Divider },
})
