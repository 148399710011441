import ClassesDropdown from '@/pages/applications/outgoing/parts/filter/parts/classes-dropdown/ClassesDropdown.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import StatusDropdown from '@/pages/applications/outgoing/parts/filter/parts/status-dropdown/StatusDropdown.vue'
import ModeratorsDropdown from '@/pages/common/dropdowns/application/moderators/ModeratorsDropdown.vue'

export const dropdownComponents = {
  ClassesDropdown,
  SubjectsDropdown,
  StatusDropdown,
  ModeratorsDropdown,
}
