















































































import { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import Chip from '@/pages/dictionary/themes/list/parts/themes-tree/parts/Chip.vue'
import { TreeData, TreeElementType } from '@/features/api/types'
import { DEFAULT_ID, mapTaskTypeTo } from '@/pages/common/constants'
import { removeHtmlTags, sortTreeLeaves } from '@/features/lib'
import {
  $openedTreeBranches,
  storeOpenedBranch,
} from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { mapTaskStatus } from '@/pages/common/parts/status-controller/constants'
import AutoOpenFolderMixin from '@/features/lib/mixins/AutoOpenFolderMixin'
import { FiltersParams, TreeNodeIdProp } from '@/pages/common/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { isBranchOpened } from '@/pages/common'

export default AutoOpenFolderMixin({
  filters: {
    id: (item, search) => !!item.assignment?.id.toString().includes(search),
    theme: (item, search) => !!item.theme?.name.toLowerCase().includes(search.toLowerCase()),
    wording: (item, search) =>
      !!item.assignment?.wording.toLowerCase().includes(search.toLowerCase()),
    created_by: (item, search) =>
      !!item.assignment?.created_by.first_name?.toLowerCase().includes(search) ||
      !!item.assignment?.created_by.last_name?.toLowerCase().includes(search) ||
      !!item.assignment?.created_by.id?.toString().includes(search),
  },
}).extend({
  name: 'TreeNode',
  components: {
    Icon,
    Chip,
    ActionsButton,
  },
  props: {
    node: { type: Object as PropType<TreeData> },
    parent: { type: Boolean, default: false },
    prerequisiteFolder: { type: Boolean, default: false },
    nodeId: { type: [Number, String] as PropType<TreeNodeIdProp> },
    filters: { type: Object as PropType<FiltersParams> },
    isPrerequisite: { type: Boolean as PropType<boolean>, default: false },
    firstChild: { type: Boolean, default: false },
    lastChild: { type: Boolean, default: false },
    parentNodeId: { type: [Number, String] as PropType<TreeNodeIdProp>, default: null },
    parentNodeType: { type: String as PropType<TreeElementType>, default: null },
  },
  data: () => ({
    opened: false,
    searchString: '',
  }),
  computed: {
    iconType(): string {
      if (this.prerequisiteFolder) {
        return 'folder-prerequisite'
      }
      if (this.opened) {
        return 'tree-folder-opened'
      }
      return 'tree-folder'
    },
    title() {
      const entity = this.node[this.node.element_type]
      let fullName = ''
      if (this.node.element_type !== 'assignment') {
        // @ts-ignore
        fullName = entity ? entity.name : ''
        if (fullName.length > 100) {
          fullName = `${fullName.slice(0, 100)}...`
        }
      } else {
        // @ts-ignore
        fullName = entity ? entity.wording : ''
        // @ts-ignore
        if (!entity.wording) return ''
        fullName = removeHtmlTags(fullName)
        fullName = `${fullName.slice(0, 30)}...`
      }
      return fullName
    },
    resources() {
      return {
        tasks: {
          count: this.node.theme && this.node.theme.all_assignments_amount,
          description: 'Количество заданий в теме',
        },
        resources: {
          count: this.node.theme && this.node.theme.study_resource_amount,
          description: 'Количество обучающих ресурсов в теме',
        },
      }
    },
    taskIcon() {
      return mapTaskTypeTo[this.node.assignment!.type].icon
    },
    correctStatus() {
      return mapTaskStatus[this.node.assignment!.status]
    },
    taskDifficultyLevel() {
      switch (this.node.assignment!.difficulty) {
        case 0:
          return { title: 'Базовый уровень', class: '--green' }
        case 1:
          return { title: 'Продвинутый уровень', class: '--yellow' }
        case 2:
          return { title: 'Экзамен', class: '--red' }
        default:
          return { title: '', class: 'invisible' }
      }
    },
    showActions() {
      const { element_type } = this.$props.node
      return element_type === 'assignment' || element_type === 'theme'
    },
    nodeLeavesLength(): number {
      return this.node.leaves.length
    },
  },
  watch: {
    opened: {
      handler(newVal) {
        if (newVal) {
          this.node.leaves = sortTreeLeaves(this.node.leaves)
          this.loadFolderData()
        }
        storeOpenedBranch({
          id: this.nodeId,
          opened: newVal as boolean,
          type: this.node.element_type,
          parentNodeId: this.parentNodeId,
          parentNodeType: this.parentNodeType,
          isPrerequisite: this.isPrerequisite,
        })
      },
    },
    nodeLeavesLength: {
      handler(newVal) {
        if (newVal && this.opened) this.node.leaves = sortTreeLeaves(this.node.leaves)
      },
    },
  },
  methods: {
    toggle(evt: any) {
      if (evt.target.closest('.action') || this.node.element_type === 'assignment') return
      this.opened = !this.opened
    },
    loadFolderData() {
      if (
        (!this.node.leaves.length && this.node.element_type === 'theme') ||
        (this.node.leaves.every((leaf) => leaf.element_type === 'theme') &&
          this.node.element_type === 'theme')
      ) {
        const { subject_id, study_year_id, id } = this.node[this.node.element_type]!
        this.$emit('loadTree', {
          subject: subject_id,
          study_year: study_year_id,
          theme: id,
          is_prerequisite: this.prerequisiteFolder ? true : undefined,
        })
      }
    },
    getType(): ContextMenuType {
      if (this.node.element_type !== 'theme') return 'item'
      return 'folder'
    },
    handleRightClick(event: Event) {
      const type = this.getType()
      this.$emit('onRightClick', {
        data: {
          id: this.nodeId,
          subject: this.node.theme ? this.node.theme.subject_id : DEFAULT_ID,
          studyYear: this.node.theme ? this.node.theme.study_year_id : DEFAULT_ID,
          isPrerequisite: this.prerequisiteFolder || this.isPrerequisite,
        },
        event,
        type,
      })
    },
    treeActionsButtonClick(event: MouseEvent) {
      const type = this.getType()
      this.$emit('onActionsClick', {
        data: {
          id: (this.node.assignment && this.node.assignment.id) || this.node.theme!.id,
          subject: this.node.theme ? this.node.theme.subject_id : DEFAULT_ID,
          studyYear: this.node.theme ? this.node.theme.study_year_id : DEFAULT_ID,
          isPrerequisite: this.prerequisiteFolder || this.isPrerequisite,
        },
        event,
        type,
      })
    },
  },
  mounted() {
    const { element_type } = this.$props.node
    if (element_type === 'theme' || element_type === 'assignment') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}`)
      nodeElement && nodeElement.addEventListener('contextmenu', this.handleRightClick)
    }
    this.opened = isBranchOpened(
      $openedTreeBranches,
      this.nodeId,
      this.node.element_type,
      this.parentNodeId,
      this.parentNodeType,
      this.isPrerequisite
    )
    if (this.filters.search) {
      const searchString = this.filters.search_area
        ? this.filters.search_area.slice(this.filters.search_area?.indexOf('_') + 1)
        : ''
      this.searchString = searchString === 'search_wording' ? 'assignment' : searchString
      this.autoOpenFolders([this.node])
    }
  },
  beforeDestroy() {
    const { element_type } = this.$props.node
    if (element_type === 'theme' || element_type === 'assignment') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}`)
      nodeElement && nodeElement.removeEventListener('contextmenu', this.handleRightClick)
    }
  },
})
