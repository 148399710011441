import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteFolderParams } from '@/features/api/assignment/types/types'

export const deleteExamTestsFolderFx = createApiEffect<number, void>({
  requestMapper: (id) => ({
    method: 'DELETE',
    url: `/api/exams-app/folders/${id}/`,
  }),
})

export const requestDeleteExamTestsFx = createApiEffect<
  RequestDeleteFolderParams,
  RequestDeleteFolderParams
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/exams-folder/folders/bulk-delete/',
    body,
  }),
})
