import { createEvent, restore } from 'effector-root'
import { coursesDropdownModel } from '@/pages/common/dropdowns/courses/courses-name-dropdown/courses-dropdown.model'
import { lessonsDropdownModule } from '@/pages/common/dropdowns/lessons/lesson-dropdown.model'
import { shownDropdownModel } from '@/pages/callback/comments-on-lessons/parts/dropdowns/shown-dropdown/shown-dropdown.model'
import { creatorsDropdownModel } from '@/pages/common/dropdowns/application/creators/creators-dropdown.model'
import { groupsDropdownModel } from '@/pages/users/students/list/parts/groups-dropdown/groups-dropdown.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { certUsersDropdownModel } from '@/pages/common/dropdowns/cert-users/cert-users-dropdown/cert-users-dropdown.model'

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false)

export const dropdownCertificatesGeneratorModules = {
  coursesDropdownModel,
  lessonsDropdownModule,
  certUsersDropdownModel,
  shownDropdownModel,
  creatorsDropdownModel,
  groupsDropdownModel,
}

export const setAcceptFrom = createEvent<string>()
export const $acceptFrom = restore(setAcceptFrom, '').reset(reset)

export const setAcceptTo = createEvent<string>()
export const $acceptTo = restore(setAcceptTo, '').reset(reset)
