











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { tasksFormationDropdownModel } from '@/pages/exams/part-time-test/create/parts/TasksFormationDropdown/tasks-formation-dropdown.model'

export default Vue.extend({
  name: 'TasksFormationDropdown',
  components: {
    FilterDropdown,
  },
  effector: {
    ...tasksFormationDropdownModel.store,
  },
  data() {
    return {
      searchString: '',
    }
  },
  methods: {
    ...tasksFormationDropdownModel.methods,
  },
})
