import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'

export const StudentsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '70px',
  },
  {
    name: 'elk_id',
    sortField: 'elk_id',
    title: 'ID ЕЛК',
    width: '90px',
  },
  {
    name: 'full_name',
    sortField: 'full_name',
    title: 'Ученик',
    width: '130px',
  },
  {
    name: 'study_year',
    sortField: 'study_year__number',
    title: 'Класс',
    width: '100px',
    formatter: (data) => {
      return data && data.name ? data.name : '–'
    },
  },
  {
    name: 'email',
    sortField: 'email',
    title: 'Логин(email)',
    width: '150px',
  },
  {
    name: 'phone',
    sortField: 'phone',
    title: 'Телефон',
    width: '130px',
    formatter: (data) => {
      return data || '–'
    },
  },
  {
    name: 'group_string',
    sortField: 'group__name',
    title: 'Группы',
    width: '150px',
    formatter: (data) => {
      return data || '–'
    },
  },
  {
    name: 'course_string',
    sortField: 'courses_amount',
    title: 'Курсы (открытые)',
    width: '180px',
    formatter: (data) => {
      return data || '–'
    },
  },
  {
    name: 'admissionticket_status',
    sortField: 'admissionticket__status',
    title: 'Статус заявки на поступление',
    width: '270px',
    formatter: (data) => {
      return data || '–'
    },
  },
  {
    name: 'visits_amount',
    sortField: 'visits_amount',
    title: 'Количество визитов',
    width: '200px',
    formatter(datetime: string) {
      return datetime || '–'
    },
  },
  {
    name: 'google_adwords_client_id',
    sortField: 'google_adwords_client_id',
    title: 'GoogleAdwords Client id',
    width: '220px',
    formatter(data: string) {
      return data || '–'
    },
  },
  {
    name: 'register_by_service',
    sortField: 'register_by_service',
    title: 'Место регистрации',
    width: '200px',
    formatter(data: string) {
      return data || '–'
    },
  },
  {
    name: 'date_joined',
    sortField: 'date_joined',
    title: 'Время регистрации',
    width: '200px',
    formatter(datetime: string) {
      return datetime || '–'
    },
  },
  {
    name: 'first_login_at',
    sortField: 'first_login_at',
    title: 'Время первого входа в ЛО',
    width: '240px',
    formatter(datetime: string) {
      return datetime || '–'
    },
  },
  {
    name: 'last_login',
    sortField: 'last_login',
    title: 'Время последнего входа в ЛО',
    width: '260px',
    formatter(datetime: string) {
      return datetime || '–'
    },
  },
  {
    name: 'global_register_at',
    sortField: 'global_register_at',
    title: 'Время создания записи в ЛО',
    width: '260px',
    formatter(datetime: string) {
      return datetime || '–'
    },
  },
  {
    name: 'updated_at',
    sortField: 'updated_at',
    title: 'Время посл. обновления записи в ЛО',
    width: '310px',
    formatter(datetime: string) {
      return datetime || '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID' },
  { name: 'study_year', title: 'Класс' },
  { name: 'full_name', title: 'Ученик' },
  { name: 'email', title: 'Логин(email)' },
  { name: 'phone', title: 'Телефон' },
  { name: 'group', title: 'Группы' },
  { name: 'course', title: 'Курсы(открытые)' },
  { name: 'admissionticket__status', title: 'Статус заявки на поступление' },
  { name: 'visits_amount', title: 'Количество визитов' },
  { name: 'google_adwords_client_id', title: 'GoogleAdwords Client id' },
  { name: 'register_by_service', title: 'Место регистрации' },
  { name: 'register_at', title: 'Время регистрации' },
]
