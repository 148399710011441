import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { TestList } from '@/features/api/test/types'

export const getTestsFiltersFx = createApiEffect<GetListQueryParams, TableDataResponse<TestList[]>>(
  {
    requestMapper: (query) => ({
      method: 'GET',
      url: '/api/test-app/test/filter/names/',
      query,
    }),
  }
)
