import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteAttempts } from '@/pages/exams/attempts/list/model/types'

export const requestDeleteAttemptsFx = createApiEffect<RequestDeleteAttempts, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/exams-app/attempts/bulk-delete/',
    body,
  }),
})
