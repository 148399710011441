































import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Icon from '@/ui/icon/Icon.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $templateName } from '@/pages/results/certificates-generator/create-template/model/form-fields.model'

export default Vue.extend({
  name: 'CreateCertificatesTemplateHeader',
  components: { Icon, BaseButton },
  props: {
    disabled: { type: Boolean as PropType<boolean>, default: true },
  },
  effector: {
    $templateName,
  },
  methods: {
    goToList() {
      navigatePush({ name: 'certificates-generator-table' })
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
