











































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import PageHeader from '@/pages/content/onboarding/list/parts/PageHeader.vue'
import TooltipCell from '@/pages/content/onboarding/list/parts/TooltipCell.vue'
import {
  deleteSlides,
  requestDeleteSlides,
  slidesPageParams,
  $isLoading,
  $showOnboarding,
  toggleShowOnboarding,
  getShowOnboarding,
} from '@/pages/content/onboarding/list/slides-page.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import { slidesTableFields, getSlidesActions } from '@/pages/content/onboarding/list/constants'
import { $permissions } from '@/features/session'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import {
  RefsType,
  HttpOptionsType,
  DisplayContextMenuPayload,
  ActionsItem,
  CommonInteractedItemParams,
} from '@/pages/common/types'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import {
  $confirmDeleteModalVisibility,
  loadConfirmDeleteModal,
  setDeleteType,
} from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import {
  $requestDeleteModalVisibility,
  loadRequestDeleteModal,
} from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { getActionsDisplayConditions } from '@/pages/common'
import { axiosClient } from '@/lib/request'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'SlidesPage',
  components: {
    Vuetable,
    VuetablePagination,
    PageHeader,
    TableHeader,
    TooltipCell,
    ActionsButton,
    ContextMenu,
    NoDataContent,
    ConfirmDeleteModal,
    RequestDeleteModal,
    LoaderBig,
  },
  effector: {
    $pageParams: slidesPageParams.store.$pageParams,
    $currentPage: slidesPageParams.store.currentPage,
    $isLoading,
    $confirmDeleteModalVisibility,
    $requestDeleteModalVisibility,
    $permissions,
    $showOnboarding,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      fields: slidesTableFields,
      total: 0,
      selectedRows: [] as number[],
      currentFailed: null as any | null,
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/content-app/slider/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getSlidesActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    changePage: slidesPageParams.methods.changePage,
    queryToParams: slidesPageParams.methods.queryToParams,
    toggleShowOnboarding,
    myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      return axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    onEditSlide() {
      navigatePush({ name: 'slides-edit', params: { id: `${this.selectedIds[0]}` } })
    },
    onRemoveSlides() {
      setDeleteType('slide')
      this.$permissions?.content_slider_slider.can_edit
        ? loadConfirmDeleteModal(this.selectedIds)
        : loadRequestDeleteModal(this.selectedIds)
    },
    async removeSelectedSlide(ids: number[]) {
      await deleteSlides(ids)
      await Vue.nextTick(() => this.$refs.vuetable.reload())
      this.removeSelection()
    },
    async sendRequestDeleteSlide(comment: string, ids: number[]) {
      await requestDeleteSlides({ sliders: ids, ticket_comment: comment })
      this.removeSelection()
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) noInternetToastEvent()
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      event.preventDefault()
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getSlidesActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
  },
  created() {
    this.queryToParams(this.$route.query)
    getShowOnboarding()
  },
})
