

















































import Vue, { PropType } from 'vue'
import { AttemptResultExtraFilterElement } from '@/pages/common/modals/attempts-filter/type'
import SubjectsVcDropdown from '@/pages/common/dropdowns/value-change-dropdowns/subject-vc/SubjectsVcDropdown.vue'
import SignVcDropdown from '@/pages/common/dropdowns/value-change-dropdowns/sign-vc/SignVcDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import Icon from '@/ui/icon/Icon.vue'
import ExamTestVcDropdown from '@/pages/common/dropdowns/value-change-dropdowns/exams-test-vc/ExamTestVcDropdown.vue'
import PercentDropdown from '@/pages/common/dropdowns/value-change-dropdowns/percent-dropdown/PercentDropdown.vue'
import FormInput from '@/ui/input/FormInput.vue'

export default Vue.extend({
  name: 'AttemptsFilterBlock',
  components: {
    SubjectsVcDropdown,
    SignVcDropdown,
    Icon,
    ExamTestVcDropdown,
    PercentDropdown,
    FormInput,
  },
  props: {
    block: { type: Object as PropType<AttemptResultExtraFilterElement> },
    index: { type: Number },
  },
  methods: {
    handleChangeBlock(data: DropdownItem) {
      this.$emit('changeBlock', { ...this.block, ...data })
    },
    removeBlock() {
      this.$emit('removeBlock', this.index)
    },
  },
})
