import { classesDropdownModule } from '@/pages/applications/outgoing/parts/filter/parts/classes-dropdown/classes-dropdown.model'
import { statusDropdownModule } from '@/pages/applications/outgoing/parts/filter/parts/status-dropdown/status-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { moderatorsDropdownModel } from '@/pages/common/dropdowns/application/moderators/moderators-dropdown.model'

export const dropdownModules = {
  classesDropdownModule,
  statusDropdownModule,
  subjectsDropdownModel,
  moderatorsDropdownModel,
}
