import { attach, combine, createEvent, createStore, split } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { getSubjectsFilterOptionsFx } from '@/features/api/learning/lessons/get-lessons-filter-options'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import {
  DropdownAssignmentBlockNames,
  TaskDataType,
  TaskTypeNames,
} from '@/pages/learning/learning-lessons/create/model/types'
import { getUserStudyYearsListFx } from '@/features/api/study_year/get-user-study-years-list'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { TestAssignment } from '@/features/api/assignment/types/test-assignments-types'
import { getTestAssignmentListFx } from '@/features/api/learning/lessons/get-lesson-task-list'
import { GetListQueryParams, TableDataResponse, TreeData } from '@/features/api/types'
import { ApiEffect } from '@/features/api/common/create-api-effect'
import { StudyYear } from '@/features/api/subject/types'
import { GetNameOptionsResponseType } from '@/pages/learning/learning-lessons/list/model/types'
import { getExamAssignmentListFx } from '@/features/api/assignment/exam-assignment/get-exam-assignment-list'
import { ExamAssignment } from '@/features/api/assignment/types/exam-assignments-types'

function createAssignmentBlock<T>(
  getSubjectMethod: ApiEffect<void, GetNameOptionsResponseType[], any>,
  getStudyYearMethod: ApiEffect<GetListQueryParams, TableDataResponse<StudyYear[]>, any>,
  getAssignmentMethod: ApiEffect<GetListQueryParams, TableDataResponse<T[]>, any>
) {
  const getSubjects = attach({
    effect: getSubjectMethod,
  })

  const getClasses = attach({
    effect: getStudyYearMethod,
  })

  // для связи с модулем создания уроков
  const addAssignmentBlock = createEvent<{
    id: number
    task?: TaskDataType
    score?: string
    useTaskScore?: boolean | undefined
    taskType?: TaskTypeNames
  }>()
  const deleteAssignmentBlock = createEvent<number>()
  const resetFields = createEvent()

  const classesDropdownModel = createFilter()

  const subjectsDropdownModel = createFilter()

  const themesDropdownModel = createFilter()

  const tasksDropdownModule = createDropdownModel<T>(getAssignmentMethod)

  const loadAssignmentDropdownOptions = createEvent<DropdownAssignmentBlockNames>()

  const setTaskScore = createEvent<{ id: number; value: string }>()

  const $taskScore = createStore<{ [key: number]: string }>({})
    .on(addAssignmentBlock, (state, { id, score }) => {
      const newState = { ...state }
      newState[id] = score || ''
      return newState
    })
    .on(deleteAssignmentBlock, (state, id) => {
      const newState = { ...state }
      delete newState[id]
      return newState
    })
    .on(setTaskScore, (state, payload) => {
      const newState = { ...state }
      newState[payload.id] = payload.value
      return newState
    })
    .reset(resetFields)

  const toggleTaskScore = createEvent<{ id: number }>()

  const setUseTaskScore = createEvent<{ id: number; value: boolean }>()

  const $useTaskScore = createStore<{ [key: number]: boolean }>({})
    .on(addAssignmentBlock, (state, { id, useTaskScore }) => {
      const newState = { ...state }
      newState[id] = typeof useTaskScore === 'boolean' ? useTaskScore : true
      return newState
    })
    .on(deleteAssignmentBlock, (state, id) => {
      const newState = { ...state }
      delete newState[id]
      return newState
    })
    .on(toggleTaskScore, (state, payload) => {
      const newState = { ...state }
      newState[payload.id] = !newState[payload.id]
      return newState
    })
    .on(setUseTaskScore, (state, payload) => {
      const newState = { ...state }
      newState[payload.id] = payload.value
      return newState
    })
    .reset(resetFields)

  const toggleTaskType = createEvent<{ id: number; task: TaskTypeNames }>()

  const $taskType = createStore<{ [key: number]: TaskTypeNames }>({})
    .on(addAssignmentBlock, (state, { id, taskType }) => {
      const newState = { ...state }
      newState[id] = taskType || TaskTypeNames.test
      return newState
    })
    .on(deleteAssignmentBlock, (state, id) => {
      const newState = { ...state }
      delete newState[id]
      return newState
    })
    .on(toggleTaskType, (state, payload) => {
      const newState = { ...state }
      newState[payload.id] = payload.task
      return newState
    })
    .reset(resetFields)

  const $classes = createStore<DropdownItem[]>([])
    .on(getClasses.doneData, (_, res) =>
      res.body.data.map((studyYear) => ({ name: `${studyYear.id}`, title: studyYear.name }))
    )
    .reset(resetFields)

  const setThemesList = createEvent<{ data: TreeData[]; id: number }>()

  const $themes = createStore<{ [key: number]: TreeData[] }>({})
    .on(addAssignmentBlock, (state, { id }) => {
      const newState = { ...state }
      newState[id] = []
      return newState
    })
    .on(deleteAssignmentBlock, (state, id) => {
      const newState = { ...state }
      delete newState[id]
      return newState
    })
    .on(setThemesList, (state, res) => {
      const newState = { ...state }
      newState[res.id] = res.data
      return newState
    })
    .reset(resetFields)

  const $subjects = createStore<DropdownItem[]>([])
    .on(getSubjects.doneData, (_, res) =>
      res.body.map((payload) => ({ name: `${payload.id}`, title: payload.name }))
    )
    .reset(resetFields)

  const setTasksList = createEvent<{
    data: TaskDataType[]
    id: number
  }>()

  const $tasks = createStore<{ [key: number]: TaskDataType[] }>({})
    .on(addAssignmentBlock, (state, { id }) => {
      const newState = { ...state }
      newState[id] = []
      return newState
    })
    .on(deleteAssignmentBlock, (state, id) => {
      const newState = { ...state }
      delete newState[id]
      return newState
    })
    .on(setTasksList, (state, res) => {
      const newState = { ...state }
      newState[res.id] = res.data
      return newState
    })
    .reset(resetFields)

  const setSelectedType = createEvent<{
    value: DropdownItem | TaskDataType | null
    type: DropdownAssignmentBlockNames
    id: number
  }>()

  const $selectedSubject = createStore<{ [key: number]: DropdownItem }>([])
    .on(addAssignmentBlock, (state, { id }) => {
      const newState = { ...state }
      newState[id] = { id, name: '', title: '' }
      return newState
    })
    .on(deleteAssignmentBlock, (state, id) => {
      const newState = { ...state }
      delete newState[id]
      return newState
    })
    .on(setSelectedType, (state, payload) => {
      const newState = { ...state }
      if (payload.type === DropdownAssignmentBlockNames.subject) {
        newState[payload.id] = payload.value as DropdownItem
        return newState
      }
      return state
    })
    .on(toggleTaskType, (state, payload) => {
      const newState = { ...state }
      newState[payload.id] = {
        id: payload.id,
        name: '',
        title: '',
      }
      return newState
    })
    .reset(resetFields)

  const $selectedTheme = createStore<{ [key: number]: DropdownItem }>({})
    .on(addAssignmentBlock, (state, { id }) => {
      const newState = { ...state }
      newState[id] = { id, name: '', title: '' }
      return newState
    })
    .on(deleteAssignmentBlock, (state, id) => {
      const newState = { ...state }
      delete newState[id]
      return newState
    })
    .on(setSelectedType, (state, payload) => {
      const newState = { ...state }
      if (payload.type === DropdownAssignmentBlockNames.theme) {
        newState[payload.id] = payload.value as DropdownItem
        return newState
      }
      return state
    })
    .on(toggleTaskType, (state, payload) => {
      const newState = { ...state }
      newState[payload.id] = {
        id: payload.id,
        name: '',
        title: '',
      }
      return newState
    })
    .reset(resetFields)

  const $selectedClass = createStore<{ [key: number]: DropdownItem }>({})
    .on(addAssignmentBlock, (state, { id }) => {
      const newState = { ...state }
      newState[id] = { id, name: '', title: '' }
      return newState
    })
    .on(deleteAssignmentBlock, (state, id) => {
      const newState = { ...state }
      delete newState[id]
      return newState
    })
    .on(setSelectedType, (state, payload) => {
      const newState = { ...state }
      if (payload.type === DropdownAssignmentBlockNames.classes) {
        newState[payload.id] = payload.value
          ? (payload.value as DropdownItem)
          : { id: payload.id, name: '', title: '' }
        return newState
      }
      return state
    })
    .on(toggleTaskType, (state, payload) => {
      const newState = { ...state }
      newState[payload.id] = {
        id: payload.id,
        name: '',
        title: '',
      }
      return newState
    })
    .reset(resetFields)

  const $selectedTask = createStore<{ [key: number]: TaskDataType }>({})
    .on(addAssignmentBlock, (state, { id, task }) => {
      const newState = { ...state }
      newState[id] = task || {
        blockId: id,
        id: 0,
        wording: '',
        type: 'SHORT_CLOSED_ANSWER',
        score: null,
        difficulty: null,
      }
      return newState
    })
    .on(deleteAssignmentBlock, (state, id) => {
      const newState = { ...state }
      delete newState[id]
      return newState
    })
    .on(setSelectedType, (state, payload) => {
      const newState = { ...state }
      if (payload.type === DropdownAssignmentBlockNames.tasks) {
        newState[payload.id] = payload.value as TaskDataType
        return newState
      }
      return state
    })
    .on(toggleTaskType, (state, payload) => {
      const newState = { ...state }
      newState[payload.id] = {
        ...newState[payload.id],
        id: 0,
        wording: '',
        type: 'SHORT_CLOSED_ANSWER',
        score: null,
        difficulty: null,
      }
      return newState
    })
    .reset(resetFields)

  split({
    source: loadAssignmentDropdownOptions,
    match: {
      subject: (payload) => payload === DropdownAssignmentBlockNames.subject,
      classes: (payload) => payload === DropdownAssignmentBlockNames.classes,
    },
    cases: {
      subject: getSubjects,
      classes: getClasses,
    },
  })

  const $formToGetThemeList = combine(
    $selectedClass,
    $selectedSubject,
    $taskType,
    (classes, subject, taskType) => {
      return {
        study_year: classes,
        subject,
        taskType,
      }
    }
  )

  const $formToGetTaskList = combine(
    $selectedClass,
    $selectedSubject,
    $selectedTheme,
    $taskType,
    (classes, subject, theme, taskType) => {
      return {
        study_year: classes,
        subject,
        theme,
        taskType,
      }
    }
  )

  const $sendFormAssignmentBlock = combine(
    $selectedTask,
    $taskType,
    $useTaskScore,
    $taskScore,
    (task, task_type, use_task_score, score) => {
      return {
        task,
        task_type,
        use_task_score,
        score,
      }
    }
  )

  return {
    store: {
      $classes,
      $themes,
      $subjects,
      $tasks,
      $taskType,
      $selectedTheme,
      $selectedSubject,
      $selectedClass,
      $selectedTask,
      $useTaskScore,
      $formToGetThemeList,
      $formToGetTaskList,
      $taskScore,
      $sendFormAssignmentBlock,
    },
    methods: {
      loadAssignmentDropdownOptions,
      toggleTaskType,
      setSelectedType,
      toggleTaskScore,
      setThemesList,
      setTasksList,
      setTaskScore,
      addAssignmentBlock,
      deleteAssignmentBlock,
      resetFields,
      setUseTaskScore,
    },
    modules: {
      classesDropdownModel,
      subjectsDropdownModel,
      themesDropdownModel,
      tasksDropdownModule,
    },
  }
}

export const LessonsAssignBlock = createAssignmentBlock<TestAssignment>(
  getSubjectsFilterOptionsFx,
  getUserStudyYearsListFx,
  getTestAssignmentListFx
)

export const ExamTestsAssignBlock = createAssignmentBlock<ExamAssignment>(
  getSubjectsFilterOptionsFx,
  getUserStudyYearsListFx,
  getExamAssignmentListFx
)
