
































import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import { $editedLessonId } from '@/pages/learning/learning-lessons/create/model/edit-lesson.model'
import { navigatePush } from '@/features/navigation/navigationMethods'

export default Vue.extend({
  name: 'CreateLessonFooter',
  components: {
    BaseButton,
    BaseSwitch,
  },
  props: {
    title: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean>, default: true },
    isPreview: { type: Boolean as PropType<boolean> },
  },
  effector: {
    lessonId: $editedLessonId,
  },
  methods: {
    goToList() {
      switch (this.$route.name) {
        default:
          navigatePush({ name: 'lessons-list' })
      }
    },
    onSaveClick() {
      this.$emit('save')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
