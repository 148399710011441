import { createEvent, restore, forward } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import {
  coursesDropdownModel,
  dropdownModules,
} from '@/pages/learning/learning-lessons/list/model/tooltip-filter.model'
import { resetFields } from '@/pages/learning/learning-lessons/create/model/form-fileds.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { themesDropdownModule } from '@/pages/common/dropdowns/themes-assigment-tree/themes-assigment-dropdown.model'

export const lessonsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownModules
)

export const lessonsPageParams = createPageParamsModel()

export const reset = createEvent<void>()

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

forward({
  from: [reset, resetFields],
  to: [
    coursesDropdownModel.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    themesDropdownModule.methods.resetDropdown,
  ],
})
