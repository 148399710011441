import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteGroups } from '@/pages/users/groups/list/model/types'

export const requestDeleteGroupsFx = createApiEffect<RequestDeleteGroups, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/user-app/groups/bulk-delete/',
    body,
  }),
})
