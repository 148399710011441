import { createApiEffect } from '@/features/api/common/create-api-effect'
import {
  FinalText,
  CreateTestFinalTextFxParams,
  UpdateTestFinalTextFxParams,
} from '@/features/api/test/types'
import { createEffect } from 'effector'

export const createTestFinalTextFx = createApiEffect<CreateTestFinalTextFxParams, FinalText>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/test-app/final-text/',
    body,
  }),
})

export const updateTestFinalTextFx = createApiEffect<UpdateTestFinalTextFxParams, FinalText>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: `/api/test-app/final-text/${body.id}/`,
    body,
  }),
})

export const removeTestFinalTextFx = createApiEffect<number>({
  requestMapper: (id) => ({
    method: 'DELETE',
    url: `/api/test-app/final-text/${id}`,
  }),
})

export const createTestFinalTextArrFx = createEffect(
  async (data: CreateTestFinalTextFxParams[]) => {
    return Promise.all(
      data.map(async (textData) => {
        await createTestFinalTextFx(textData)
      })
    )
  }
)

export const resendTestFinalTextArrFx = createEffect(
  async (data: UpdateTestFinalTextFxParams[]) => {
    const dataIds = data.map((item) => item.id)
    const oldIds = data.length > 0 ? data[0].oldId : []
    const deleteItems = oldIds.filter((item) => !dataIds.includes(item))
    return Promise.all([
      ...data.map(async (finalText) => {
        if (finalText.isNew) {
          await createTestFinalTextFx(finalText)
        } else if (finalText.id) {
          await updateTestFinalTextFx(finalText)
        }
      }),
      ...deleteItems.map(async (deleteId) => {
        if (deleteId) {
          await removeTestFinalTextFx(deleteId)
        }
      }),
    ])
  }
)
