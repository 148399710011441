import { createEvent, forward, restore, guard, sample } from 'effector-root'
import { getThemesTreeListFx } from '@/features/api/subject/get-themes-tree-list'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { formatData } from '@/features/lib'
import { errorToastEvent } from '@/features/toasts/toasts.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { GetListQueryParams } from '@/features/api/types'

export const themesDropdownModule = createFilter()
export const loadThemes = createEvent<void>()

export const resetThemes = createEvent<void>()
export const showErrorAddThemes = createEvent<void>()

export const toggleIsPageLoaded = createEvent<boolean>()
export const $isPageLoaded = restore(toggleIsPageLoaded, false)

forward({
  from: resetThemes,
  to: themesDropdownModule.methods.resetDropdown,
})

export const setLoadingForEditThemes = createEvent<boolean>()
export const $loadingForEditThemes = restore(setLoadingForEditThemes, false)

export const resetSelectedThemes = createEvent<void>()
export const setSelectedThemes = createEvent<DropdownItem[]>()
export const $selectedThemes = restore(setSelectedThemes, []).reset(
  resetThemes,
  resetSelectedThemes
)

sample({
  clock: loadThemes,
  source: {
    subject: subjectsDropdownModel.store.$item,
    study_year: classesDropdownModule.store.$item,
  },
  fn: (params): GetListQueryParams => {
    return {
      is_prerequisite: false,
      ...(params.subject?.name && { subject: Number(params.subject?.name) }),
      ...(params.study_year?.name && { study_year: Number(params.study_year?.name) }),
    }
  },
  target: getThemesTreeListFx,
})

forward({
  from: getThemesTreeListFx.doneData.map((data) => formatData(data.body)),
  to: themesDropdownModule.methods.setItems,
})

guard({
  clock: getThemesTreeListFx.doneData,
  source: $loadingForEditThemes,
  filter: (loadingForEdit) => loadingForEdit,
  target: setLoadingForEditThemes.prepend(() => false),
})

forward({
  from: showErrorAddThemes,
  to: errorToastEvent('В теме недостаточно заданий'),
})

forward({
  from: [subjectsDropdownModel.store.$item, classesDropdownModule.store.$item],
  to: loadThemes,
})
