import { TableField } from '@/pages/dictionary/themes/list/types'

export const CreateEmployeeTableFields: TableField[] = [
  {
    name: 'name',
    title: 'Раздел',
    width: '120px',
  },
  {
    name: 'can_edit',
    title: 'Редактирование',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'can_view',
    title: 'Просмотр',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
]
