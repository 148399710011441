import { createEvent, forward, attach, restore, sample, guard, createStore } from 'effector-root'
import { debounce } from 'patronum'
import { $selectedSubject } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { $selectedClass } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  $selectedTheme,
  setSelectedTheme,
} from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import { getLabelsListFx } from '@/features/api/assignment/labels/get-labels-list'
import { Label } from '@/features/api/assignment/types/types'
import { combine } from 'effector/compat'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'
import { DropdownItem } from '@/pages/common/types'
import { addToast } from '@/features/toasts/toasts.model'
import { parseError } from '@/features/lib'

export const getLabels = attach({
  effect: getLabelsListFx,
})

export const labelsDropdownModel = createDropdownMultiselectModel<Label>(getLabels)

export const setNewLabels = createEvent<void>()

export const setSelectedLabelsId = createEvent<number[]>()
export const resetSelectedLabelsId = createEvent<void>()
export const $selectedLabelsId = restore(setSelectedLabelsId, []).reset(resetSelectedLabelsId)

export const loadLabels = createEvent<void>()
export const toggleLabelsLoaded = createEvent<boolean>()
export const $labelsLoaded = restore(toggleLabelsLoaded, false)

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

const $formToGetLabelsList = combine(
  $selectedSubject,
  $selectedClass,
  $selectedTheme,
  (selectedSubject, selectedClass, selectedTheme) => ({
    subject: selectedSubject && +selectedSubject.name,
    study_year: selectedClass && +selectedClass.name,
    theme: selectedTheme && +selectedTheme.name,
  })
)

const $isRelatedFieldsSelected = combine(
  $selectedSubject,
  $selectedClass,
  $selectedTheme,
  (selectedSubject, selectedClass, selectedTheme): boolean => {
    return !!selectedSubject || !!selectedClass || !!selectedTheme
  }
)

export const $canLoadLabels = combine(
  $isRelatedFieldsSelected,
  $isComponentMounted,
  (isRelatedFieldsSelected, isComponentMounted) => isRelatedFieldsSelected && isComponentMounted
)

const debounced = debounce({
  source: $formToGetLabelsList,
  timeout: 150,
})

guard({
  clock: debounced,
  filter: $canLoadLabels,
  target: getLabels,
})

sample({
  clock: loadLabels,
  source: { $formToGetLabelsList, $nextPage: labelsDropdownModel.store.$nextPage },
  fn: (params) => ({
    ...params.$formToGetLabelsList,
    page: params.$nextPage,
    is_prerequisite: false,
  }),
  target: getLabels,
})

forward({
  from: labelsDropdownModel.methods.canLoadNextPage,
  to: loadLabels,
})

sample({
  clock: getLabels.doneData,
  source: { items: labelsDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newLabels = res.body.data.map((field) => ({ name: `${field.id}`, title: field.name }))
    if (res.body.current_page === 1) {
      return newLabels
    }
    return [...items, ...newLabels]
  },
  target: labelsDropdownModel.store.$items,
})

sample({
  clock: getLabels.failData,
  target: [
    addToast.prepend((data: any) => ({ type: 'error', message: parseError(data.body) })),
    toggleLabelsLoaded.prepend(() => true),
  ],
})

guard({
  clock: getLabels.inFlight,
  filter: (count) => count === 0,
  target: toggleLabelsLoaded.prepend(() => true),
})

guard({
  clock: [labelsDropdownModel.store.$items, setSelectedTheme],
  source: {
    ids: $selectedLabelsId,
    theme: $selectedTheme,
    items: labelsDropdownModel.store.$items,
  },
  filter: ({ ids, theme, items }) => {
    return (
      ids.length > 0 &&
      !!theme &&
      !!items.length &&
      !!items.filter((el) => ids.includes(Number(el.name))).length
    )
  },
  target: setNewLabels,
})

guard({
  clock: setSelectedLabelsId,
  filter: (ids) => ids.length === 0,
  target: labelsDropdownModel.methods.resetSelectedItems,
})

sample({
  source: { ids: $selectedLabelsId, items: labelsDropdownModel.store.$items },
  clock: setNewLabels,
  fn: (data: { ids: number[]; items: DropdownItem[] }) => {
    const { ids, items } = data
    if (ids && ids.length > 0) {
      return items.filter((el: DropdownItem) => ids.includes(Number(el.name)))
    }
    return []
  },
  target: [labelsDropdownModel.methods.setSelectedItems, resetSelectedLabelsId],
})
