import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'

export const getTags = createApiEffect<
  GetListQueryParams,
  TableDataResponse<{ id: number; name: string }[]>,
  any
>({
  requestMapper: () => ({
    method: 'GET',
    url: '/api/content-app/tags/list/',
  }),
})
