import { createApiEffect } from '@/features/api/common/create-api-effect'
import { AppointmentCreateFxParams, AppointmentCreateType } from '@/features/api/appointments/types'

export const createAppointmentsFx = createApiEffect<
  AppointmentCreateType,
  AppointmentCreateFxParams
>({
  requestMapper: (body) => ({
    method: 'POST',
    url: `/api/exams-app/part-time/appointments/`,
    body,
  }),
})
