import { attach } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'
import { exportCertificatesGeneratorListFX } from '@/features/api/results/certificates-generator/export-certificates-generator-list'
import { dropdownCertificatesGeneratorModules } from '@/pages/results/certificates-generator/table/model/tooltip-filter.model'

/**
 * GENERATION
 */
export const callbackCertificatesGeneratorFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownCertificatesGeneratorModules
)

export const certificatesGeneratorTablePageParams = createPageParamsModel()

export const exportCertificatesGeneratorList = attach({
  effect: exportCertificatesGeneratorListFX,
  source: [callbackCertificatesGeneratorFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportCertificatesGeneratorList.doneData.watch((res) =>
  fileDownload(res.body, 'certificatesList.xlsx')
)
