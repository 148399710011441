import { attach, createEvent, forward, sample } from 'effector-root'
import { coursesNameListFx } from '@/features/api/results/courses-name-list'
import { GetListQueryParams } from '@/features/api/types'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'
import { FilterName } from '@/features/api/results/types'

const getCourses = attach({
  effect: coursesNameListFx,
  mapParams: (params: GetListQueryParams) => params,
})

export const coursesDropdownModel = createDropdownMultiselectModel<FilterName>(getCourses)

export const loadCourses = createEvent<void>()

sample({
  clock: loadCourses,
  source: { $nextPage: coursesDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getCourses,
})

forward({
  from: coursesDropdownModel.methods.canLoadNextPage,
  to: loadCourses,
})

sample({
  clock: getCourses.doneData,
  source: { items: coursesDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newTags = res.body.data.map((field) => ({ name: `${field.id}`, title: field.name }))
    const returnCourses = [...items, ...newTags].filter((course) => course.name !== 'all')
    return [{ name: 'all', title: 'Все' }, ...returnCourses]
  },
  target: coursesDropdownModel.store.$items,
})
