




















































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $filterBlock,
  $attemptsFilterModalModalVisibility,
  filterBlockChanged,
  attemptsFilterModalVisibilityChanged,
  resetFilterBlock,
  saveFiltersChanged,
} from '@/pages/common/modals/attempts-filter/attempts-filter-modal.model'
import AttemptsFilterBlock from '@/pages/common/modals/attempts-filter/AttemptsFilterBlock.vue'
import { AttemptResultExtraFilterElement } from '@/pages/common/modals/attempts-filter/type'

export default Vue.extend({
  name: 'AttemptsFilterModal',
  components: {
    Modal,
    BaseButton,
    AttemptsFilterBlock,
  },
  effector: {
    $attemptsFilterModalModalVisibility,
    $filterBlock,
  },
  methods: {
    attemptsFilterModalVisibilityChanged,
    resetFilterBlock,
    setBlock(newBlock: { block: AttemptResultExtraFilterElement; id: number }) {
      saveFiltersChanged(false)
      const setNewBlocks: AttemptResultExtraFilterElement[] = [...this.$filterBlock].map(
        (block, index) => (index === newBlock.id ? newBlock.block : block)
      )
      filterBlockChanged(setNewBlocks)
    },
    removeBlock(blockIndex: number) {
      saveFiltersChanged(false)
      const setNewBlocks: AttemptResultExtraFilterElement[] = [...this.$filterBlock].filter(
        (block, index) => index !== blockIndex
      )
      filterBlockChanged(setNewBlocks)
    },
    addBlock() {
      saveFiltersChanged(false)
      filterBlockChanged([...this.$filterBlock, new AttemptResultExtraFilterElement()])
    },
    submitFilters() {
      saveFiltersChanged(true)
      attemptsFilterModalVisibilityChanged(false)
      this.$emit('onFilterSet')
    },
  },
})
