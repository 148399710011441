import { createEvent, forward, attach, createStore, sample } from 'effector-root'
import { getFullExamsListFx } from '@/features/api/full-appointments/get-full-exams-list'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { GetListQueryParams } from '@/features/api/types'

const getTests = attach({
  effect: getFullExamsListFx,
})

export const examTestsDropdownModel = createFilter()

export const loadTests = createEvent()

export const $fullTests = createStore<DropdownItem[]>([])

sample({
  clock: loadTests,
  source: {
    search: examTestsDropdownModel.store.$searchString,
    subject: subjectsDropdownModel.store.$item,
    study_year: classesDropdownModule.store.$item,
  },
  fn: (params): GetListQueryParams => {
    return {
      ...(params.search && { search: params.search }),
      ...(params.subject?.name && { subjects: Number(params.subject?.name) }),
      ...(params.study_year?.name && { classes: Number(params.study_year?.name) }),
    }
  },
  target: getTests,
})

forward({
  from: [subjectsDropdownModel.store.$item, classesDropdownModule.store.$item],
  to: loadTests,
})

/* forward({
  from: loadTests,
  to: getTests.prepend(() => ({})),
}) */

forward({
  from: getTests.doneData.map((res) =>
    res.body.map((test) => ({ name: `${test.id}`, title: test.name }))
  ),
  to: $fullTests,
})
