











import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import { $color, colorChanged } from '@/pages/dictionary/subjects/common/colors/color-picker.model'

export default Vue.extend({
  components: { FormInput },
  effector: { $color },
  methods: { colorChanged },
})
