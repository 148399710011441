












































































































import Vue from 'vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  $lessonCover,
  deleteLessonCover,
  uploadCoverLesson,
  changeField,
  $lessonName,
  $lessonNumber,
  $selectedTheme,
  $instruction,
  $lessonResult,
  $lessonNotShowAnswers,
  $lessonIsDuration,
  $lessonDuration,
} from '@/pages/learning/learning-lessons/create/model/form-fileds.model'
import { lessonAssignmentFolderModal } from '@/pages/common/modals/tasks-bank/creating-folder/creating-folder-modal.model'
import { FieldNames } from '@/pages/learning/learning-lessons/create/model/types'
import ThemesAssigmentDropdown from '@/pages/common/dropdowns/themes-assigment-tree/ThemesAssigmentDropdown.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FormInput from '@/ui/input/FormInput.vue'
import LessonElementsContent from '@/pages/learning/learning-lessons/create/parts/LessonElementsContent.vue'
import CreatingLessonAssignmentFolderModal from '@/pages/common/modals/tasks-bank/creating-folder/CreatingLessonAssignmentFolderModal.vue'
import ImageFileInput from '@/ui/image-file-input/ImageFileInput.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'

export default Vue.extend({
  name: 'CreateLessonContent',
  components: {
    Wysiwyg,
    ThemesAssigmentDropdown,
    SubjectsDropdown,
    FormInput,
    LessonElementsContent,
    CreatingLessonAssignmentFolderModal,
    BaseButton,
    ImageFileInput,
    BaseSwitch,
  },
  effector: {
    $lessonCover,
    $lessonName,
    $lessonNumber,
    $selectedTheme,
    $instruction,
    $lessonResult,
    $lessonNotShowAnswers,
    $lessonIsDuration,
    $lessonDuration,
  },
  data: () => ({
    fieldType: FieldNames,
  }),
  methods: {
    uploadCoverLesson,
    deleteLessonCover,
    createFolder: lessonAssignmentFolderModal.methods.modalVisibilityChanged,
    changeFieldValue(type: FieldNames, value: any) {
      changeField({ type, value })
    },
    changeLessonNumber(value: number) {
      if (!value || value < 1) {
        this.changeFieldValue(FieldNames.lessonNumber, 0)
        this.changeFieldValue(FieldNames.lessonNumber, 1)
      } else if (value > 32767) {
        this.changeFieldValue(FieldNames.lessonNumber, 1)
        this.changeFieldValue(FieldNames.lessonNumber, 32767)
      } else {
        this.changeFieldValue(FieldNames.lessonNumber, value)
      }
    },
    changeLessonDuration(value: number) {
      if (!value || value < 1) {
        this.changeFieldValue(FieldNames.lessonDuration, 0)
        this.changeFieldValue(FieldNames.lessonDuration, 1)
      } else if (value > 32767) {
        this.changeFieldValue(FieldNames.lessonDuration, 1)
        this.changeFieldValue(FieldNames.lessonDuration, 32767)
      } else {
        this.changeFieldValue(FieldNames.lessonDuration, value)
      }
    },
  },
})
