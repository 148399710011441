import { createStore } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'

export const mapApplicationsServices = [
  {
    name: 'proctoredu',
    title: 'ProctorEdu',
  },
]

export const serviceDropdownModule = createFilter()

export const $service = createStore<DropdownItem[]>(mapApplicationsServices)
