import { createEvent, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { dropdownModules } from '@/pages/learning/learning-courses-application/list/parts/filter/parts/dropdown-modules'
import { reset } from '@/pages/common/general-filter/general-filter.model'

export const applicationsCoursesFilters = createFiltersModel({}, dropdownModules)

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

export const setAcceptFrom = createEvent<string>()
export const $acceptFrom = restore(setAcceptFrom, '').reset(reset)

export const setAcceptTo = createEvent<string>()
export const $acceptTo = restore(setAcceptTo, '').reset(reset)
