














import Vue from 'vue'
import Divider from '@/ui/divider/Divider.vue'
import NavItem from '@/pages/common/navigation/parts/NavItem.vue'
import {
  $isOpened,
  changeNavState,
  updateApplicationsCounters,
} from '@/pages/common/navigation/navigation.model'
import { data as navItems } from '@/pages/common/navigation/data'

export default Vue.extend({
  name: 'Navigation',
  components: { Divider, NavItem },
  effector: {
    $isOpened,
  },
  data: () => ({
    navItems,
  }),
  methods: {
    openNavigation() {
      if (!this.$isOpened) {
        changeNavState(true)
      }
    },
  },
  beforeCreate() {
    updateApplicationsCounters()
  },
})
