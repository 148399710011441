















































































































































































































import Vue, { VueConstructor } from 'vue'
import qs from 'qs'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import PageHeader from '@/pages/results/courses-students/parts/header/PageHeader.vue'
import ResultStudentFilter from '@/pages/results/courses-students/parts/filter/ResultStudentFilter.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import Answers from '@/pages/results/answer-types/Answers.vue'
import {
  combineRouteQueries,
  computeSortParam,
  isQueryParamsEquelToPage,
  removeHtmlTags,
} from '@/features/lib'
import { config } from '@/config'
import { $token } from '@/features/api/common/request'
import { $permissions } from '@/features/session'
import { DEFAULT_ID, mapTaskTypeTo } from '@/pages/common/constants'
import {
  searchFieldsData,
  studentsResultDataFields,
  getResultCoursesStudentActions,
} from '@/pages/results/courses-students/constants'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  $canRefreshTable,
  incomingResultStudentsPageParams,
  canRefreshTableChanged,
  $isLoading,
  setNewTableFields,
  $tableFields,
  exportResultStudentsCoursesList,
  setIsLoading,
  saveResultStudentsCourse,
  $editData,
  setEditData,
  loadStudentData,
  $studentData,
  resetStudentData,
  changeResultText,
  $resultText,
  $resultStatus,
  changeResultStatus,
  deleteResult,
} from '@/pages/results/courses-students/result-courses-student.model'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import {
  RefsType,
  CommonInteractedItemParams,
  DisplayContextMenuPayload,
  HttpOptionsFiltersType,
  ActionsItem,
} from '@/pages/common/types'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import {
  resultStudentsFilters,
  setFindLessonId,
} from '@/pages/results/courses-students/parts/filter/result-student-filter.model'
import { getActionsDisplayConditions } from '@/pages/common'
import FooterBlock from '@/pages/results/courses-students/parts/FooterBlock/FooterBlock.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import ContextCell from '@/pages/common/parts/context-cell/ContextCell.vue'
import Icon from '@/ui/icon/Icon.vue'
import { lessonsDropdownModule } from '@/pages/common/dropdowns/lessons/lesson-dropdown.model'
import ComparingAnswerModal from '@/pages/common/modals/comparing-answer-modal/ComparingAnswerModal.vue'
import {
  comparingAnswerModalVisibilityChanged,
  $studentAnswer,
  $correctAnswer,
  setStudentAnswer,
  setCorrectAnswer,
} from '@/pages/common/modals/comparing-answer-modal/comparing-answer-modal.model'
import TableColumns from '@/pages/common/parts/table-columns/TableColumns.vue'
import { courseStudentResultItem } from '@/pages/results/courses-students/types'
import { SortFieldsType } from '@/pages/results/types'
import { axiosClient } from '@/lib/request'
import {
  loadConfirmDeleteModal,
  setDeleteType,
} from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    ConfirmDeleteModal,
    PageHeader,
    GeneralFilter,
    ResultStudentFilter,
    TableHeader,
    ActionsButton,
    ContextMenu,
    Vuetable,
    VuetablePagination,
    NoDataContent,
    FooterBlock,
    LoaderBig,
    TooltipCell,
    ContextCell,
    Icon,
    Answers,
    ComparingAnswerModal,
    TableColumns,
  },
  effector: {
    $token,
    $canRefreshTable,
    $filterParams: resultStudentsFilters.store.$filterParams,
    $pageParams: incomingResultStudentsPageParams.store.$pageParams,
    $currentPage: incomingResultStudentsPageParams.store.currentPage,
    $isLoading,
    $tableFields,
    $permissions,
    $editData,
    lesson: lessonsDropdownModule.store.$item,
    $studentData,
    $resultText,
    $resultStatus,
    $studentAnswer,
    $correctAnswer,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID as number,
      searchFields: searchFieldsData,
      total: 1,
      fields: studentsResultDataFields,
      selectedRows: [] as number[],
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuType: 'item' as ContextMenuType,
      contextMenuItems: [] as ActionsItem[],
      currentEditMark: 0,
      currentEditComment: 0,
      studentId: 0,
      sort: [] as SortFieldsType[],
    }
  },
  computed: {
    isFilters(): boolean {
      return (
        'lesson' in this.$filterParams &&
        'course' in this.$filterParams &&
        'student' in this.$filterParams
      )
    },
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/results-app/results/courses/`
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getResultCoursesStudentActions(displayConditions, false)
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
  },
  watch: {
    $canRefreshTable: {
      handler(newVal) {
        if (newVal === true) {
          this.$refs.vuetable.reload()
          canRefreshTableChanged(false)
          if (Object.values(this.$editData).length === 0) {
            this.currentEditMark = 0
            this.currentEditComment = 0
          }
        }
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    loadStudentData,
    changeFilter: resultStudentsFilters.methods.changeFilter,
    resetFilters: resultStudentsFilters.methods.resetFilters,
    applyFilters: resultStudentsFilters.methods.applyFilters,
    removeParamsFilter: resultStudentsFilters.methods.removeParamsFilter,
    changePage: incomingResultStudentsPageParams.methods.changePage,
    queryToParams: incomingResultStudentsPageParams.methods.queryToParams,
    setNewTableFields,
    reset,
    exportResultStudentsCoursesList,
    setIsLoading,
    saveResultStudentsCourse,
    changeResultText,
    changeResultStatus,
    headerApiCall(sort: SortFieldsType) {
      this.sort = [{ ...sort }]
      this.$refs.vuetable.loadData()
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsFiltersType) {
      const { course, lesson, student, ...otherParams } = httpOptions.params
      const sortParams = this.sort[0] ? `${this.sort[0].sortField}|${this.sort[0].direction}` : ''
      const url = `${apiUrl}${course}/${lesson}/${student}/`
      if (!course || !lesson || !student) {
        resetStudentData()
        return new Promise((resolve) => {
          resolve({ data: [] })
        })
      }
      this.loadStudentData()
      this.studentId = student ? Number(student) : 0
      const request = axiosClient.get(url, {
        params: { ...otherParams, sort: computeSortParam(sortParams) },
        paramsSerializer(params) {
          const modParams = { ...params }
          return qs.stringify(modParams, { arrayFormat: 'comma' })
        },
      })
      return request
    },
    getCorrectTypeName(element_type: string) {
      switch (element_type) {
        case 'answer_file_field':
          return 'Поле для загрузки файла'
        case 'answer_text_field':
          return 'Поле ввода решения задания'
        default:
          return 'Неизвестный тип'
      }
    },
    getCorrectAnswerType(data: any) {
      switch (data.element_type) {
        case 'answer_file_field':
          return 'BROAD_FILE_ANSWER'
        case 'answer_text_field':
          return 'BROAD_OPEN_ANSWER'
        default:
          return data.task?.type
      }
    },
    getCorrectIconType(type: string) {
      return mapTaskTypeTo[type].icon
    },
    getCorrectDescriptionType(type: string) {
      return mapTaskTypeTo[type].description
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (res && !res.response) {
        noInternetToastEvent()
      }
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      const isAssignment = data.element_type ? data.element_type === 'assignment' : false
      this.displayContextMenu(
        { id: data.id, type, coordinates: { x: event.x, y: event.y } },
        isAssignment
      )
      event.preventDefault()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      if (res.event.target.closest('.button-edit')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      this.$refs.vuetable.onCheckboxToggledAll(isSelected)
      if (isSelected) {
        this.selectedRows = this.$refs.vuetable.selectedTo
      } else {
        this.selectedRows = []
      }
    },
    onDeleteResult() {
      const { student } = this.$filterParams as {
        student: string
      }

      setDeleteType('lesson-results')
      loadConfirmDeleteModal([student])
    },
    async deleteResult() {
      const { course, lesson, student } = this.$filterParams as {
        course: string
        lesson: string
        student: string
      }
      const tempLessonId = Number(lesson)
      await deleteResult({ course, lesson, student })

      setFindLessonId(0)
      setFindLessonId(tempLessonId)
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    clearWording(str: string) {
      if (str) return removeHtmlTags(str)
      return ''
    },
    getComment(data: courseStudentResultItem) {
      let str = data.answer_obj?.commentary
      if (data.id in this.$editData) {
        str = this.$editData[data.id].commentary
      }
      if (str) return removeHtmlTags(str)
      return '-'
    },
    getMark(data: courseStudentResultItem) {
      if (data.id in this.$editData) {
        return this.$editData[data.id].mark === null ? '-' : this.$editData[data.id].mark
      }
      return data.answer_obj?.score === null ? '-' : data.answer_obj?.score
    },
    tableActionsButtonClick(event: MouseEvent, id: number, element_type: string) {
      this.handleActionsClick({
        data: { id, element_type },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      const isAssignment = data.element_type ? data.element_type === 'assignment' : false
      this.displayContextMenu(
        { id: data.id, type, coordinates: { x: event.x, y: event.y } },
        isAssignment
      )
    },
    setContextMenuItems(isAssignment: boolean) {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getResultCoursesStudentActions(displayConditions, isAssignment)
    },
    displayContextMenu(
      { id, coordinates: { x, y } }: DisplayContextMenuPayload,
      isAssignment: boolean
    ) {
      this.interactedItemId = id
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems(isAssignment)
      showContextMenu()
    },
    getSendItemObject(data: courseStudentResultItem) {
      if (data.id in this.$editData) {
        return this.$editData[data.id]
      }
      return {
        answer_obj_id: data.answer_obj?.id || 0,
        question: data.id,
        student: this.studentId,
        score: data.score,
        mark: data.mark,
        commentary: data.commentary || '',
        status: data.status || '',
      }
    },
    onEditMark(data: courseStudentResultItem) {
      setEditData({ ...this.$editData, [data.id]: this.getSendItemObject(data) })
      this.currentEditMark = data.id
    },
    handleUpdateMark(val: number, data: courseStudentResultItem) {
      const { id } = data
      setEditData({ ...this.$editData, [id]: { ...this.$editData[id], mark: val } })
    },
    hideMarkInput() {
      this.currentEditMark = 0
    },
    onEditComment(data: courseStudentResultItem, incomingCommentary: string) {
      const commentary = incomingCommentary === '-' ? '' : incomingCommentary
      setEditData({ ...this.$editData, [data.id]: { ...this.getSendItemObject(data), commentary } })
      this.currentEditComment = data.id
    },
    handleUpdateComment(val: string) {
      if (this.currentEditComment) {
        const id = this.currentEditComment
        setEditData({ ...this.$editData, [id]: { ...this.$editData[id], commentary: val } })
      }
    },
    hideCommentInput() {
      this.currentEditComment = 0
    },
    saveEditInfo() {
      saveResultStudentsCourse(Object.values(this.$editData))
    },
    onEditAssignment() {
      const currentItem = this.$refs.vuetable.tableData.find(
        (item: any) => item.id === this.selectedIds[0]
      )
      if (currentItem.element_type !== 'assignment') return
      const name = currentItem.task_type
      const routeData = this.$router.resolve({
        name: `${name}-tasks-edit`,
        query: {
          fromPage: 'tasks',
        },
        params: { id: currentItem.task.id },
      })
      window.open(routeData.href, '_blank')
    },
    onViewAssignment() {
      const currentItem = this.$refs.vuetable.tableData.find(
        (item: any) => item.id === this.selectedIds[0]
      )
      if (currentItem.element_type !== 'assignment') return
      const routeData = this.$router.resolve({
        name: 'preview-task',
        query: {
          questions: currentItem.task.id,
          fromPage: 'tasks',
          taskType: 'lesson-assignment',
          token: this.$token,
        },
      })
      window.open(routeData.href, '_blank')
    },
    onLessonsResult() {
      // TODO: на момент написание текущей страницы нет, заглушка для кнопки
      const id = this.lesson && this.lesson.name ? this.lesson.name : '0'
      const routeData = this.$router.resolve({ name: 'edit-lesson', params: { id } })
      window.open(routeData.href, '_blank')
    },
    onStudentResult() {
      // TODO: на момент написание текущей страницы нет, заглушка для кнопки
      const id = this.lesson && this.lesson.name ? this.lesson.name : '0'
      const routeData = this.$router.resolve({ name: 'edit-lesson', params: { id } })
      window.open(routeData.href, '_blank')
    },
    onOpenLesson() {
      const id = this.lesson && this.lesson.name ? this.lesson.name : '0'
      const routeData = this.$router.resolve({ name: 'edit-lesson', params: { id } })
      window.open(routeData.href, '_blank')
    },
    isEditComments(row: courseStudentResultItem) {
      if (!row) return false
      const validTypeArr = ['BROAD_FILE_ANSWER', 'BROAD_OPEN_ANSWER']
      if (
        (row.element_type === 'assignment' && row.task && validTypeArr.includes(row.task.type)) ||
        row.element_type === 'answer_file_field' ||
        row.element_type === 'answer_text_field'
      ) {
        return true
      }
      return false
    },
    compareAnswers() {
      const currentItem = this.$refs.vuetable.tableData.filter((item: { id: number }) =>
        this.selectedIds.includes(item.id)
      )
      if (currentItem && currentItem.length > 0) {
        setStudentAnswer(currentItem[0])
        setCorrectAnswer(currentItem[0])
        comparingAnswerModalVisibilityChanged(true)
      }
    },
    showPreview() {
      this.$router.push({
        name: 'preview-lesson',
        query: {
          questions: this.selectedIds.join(','),
          token: this.$token,
        },
      })
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
