


























































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import Divider from '@/ui/divider/Divider.vue'
import StatusController from '@/pages/common/parts/status-controller/StatusContoller.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'

export default Vue.extend({
  name: 'TaskHeader',
  components: {
    Icon,
    BaseButton,
    BaseSwitch,
    Divider,
    StatusController,
  },
  props: {
    title: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean>, default: true },
    isPreview: { type: Boolean as PropType<boolean> },
    fromPage: { type: String as PropType<string> },
    taskType: { type: String as PropType<string> },
    statusController: { type: Boolean as PropType<boolean>, default: false },
  },
  methods: {
    goToList() {
      navigatePush({ name: 'tests-list' })
    },
  },
})
