
















import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { ResourceChip } from '@/pages/dictionary/themes/list/types'

export default Vue.extend({
  name: 'Chip',
  components: {
    Icon,
  },
  props: {
    icon: { type: String },
    item: { type: Object as PropType<ResourceChip>, required: true },
    primary: { type: Boolean, required: false, default: false },
  },
  computed: {
    options() {
      return {
        content: this.item.description,
      }
    },
  },
})
