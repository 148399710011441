











import Vue from 'vue'
import InternalLoader from '@/pages/common/parts/loader/InternalLoader.vue'
import SmallLoader from '@/pages/common/parts/internal-loader-blocks/SmallLoader.vue'

export default Vue.extend({
  components: { InternalLoader, SmallLoader },
})
