import { createApiEffect } from '@/features/api/common/create-api-effect'
import { Slide } from '@/features/api/content/types'

export const createSlideFx = createApiEffect<Partial<Slide>, Slide>({
  requestMapper: (params) => ({
    method: 'POST',
    url: '/api/content-app/slider/',
    body: params,
  }),
})
