import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/exams-tests-filter/exams-test-filter.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { studentsExamAnswerDropdownModel } from '@/pages/exams/part-time-answers/list/parts/students-exams-answer-filter/students-exams-answer-filter.model'
import { AttemptsDropdownModel } from '@/pages/exams/part-time-answers/list/parts/attempts-filter/attempts-filter.model'

export const dropdownExamsAnswerModules = {
  groupsDropdownModel,
  subjectsDropdownModel,
  classesDropdownModule,
  examTestsDropdownModel,
  studentsExamAnswerDropdownModel,
  AttemptsDropdownModel,
}
