

















import Vue from 'vue'
import EditQuestionContent from '@/pages/content/faq/edit/parts/EditQuestionContent.vue'
import EditQuestionFooter from '@/pages/content/faq/edit/parts/EditQuestionFooter.vue'
import EditQuestionHeader from '@/pages/content/faq/edit/parts/EditQuestionHeader.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { $disabledSaveButtons, clearFields } from '@/pages/content/faq/edit/model/form-fields.model'
import { updateQuestionByIdFx } from '@/features/api/content/faq/patch-question-by-id'
import {
  changeQuestionId,
  saveQuestionInfo,
  setRedirectAfterSave,
} from '@/pages/content/faq/edit/model/edit-question.model'
import { getQuestionByIdFx } from '@/features/api/content/faq/get-question-by-id'

export default Vue.extend({
  name: 'EditQuestionPage',
  components: {
    EditQuestionHeader,
    EditQuestionFooter,
    EditQuestionContent,
    LoaderBig,
  },
  effector: {
    $disabledSaveButtons,
    $isEditing: updateQuestionByIdFx.pending,
    $isLoading: getQuestionByIdFx.pending,
  },
  methods: {
    saveTask(isRedirect: boolean) {
      if (isRedirect) {
        setRedirectAfterSave(true)
        saveQuestionInfo()
      } else {
        saveQuestionInfo()
      }
    },
  },
  created() {
    changeQuestionId(this.$route.params.id)
  },
  beforeDestroy() {
    clearFields()
  },
})
