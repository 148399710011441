import { createStaticFiltersModel } from '@/pages/common/filters/create-static-filters-model'
import { createEvent } from 'effector'
import { forward } from 'effector-root'

export const setResultValueData = createEvent<number>()

export const resultValueDropdownModel = createStaticFiltersModel([])

forward({
  from: setResultValueData,
  to: resultValueDropdownModel.methods.setItems.prepend((maxPoints) =>
    [...Array(maxPoints + 1)].map((e, i) => ({
      name: `${i}`,
      title: `${i}`,
    }))
  ),
})
