import { render, staticRenderFns } from "./FullsizeSlider.vue?vue&type=template&id=bed7279e&scoped=true&"
import script from "./FullsizeSlider.vue?vue&type=script&lang=ts&"
export * from "./FullsizeSlider.vue?vue&type=script&lang=ts&"
import style0 from "./FullsizeSlider.vue?vue&type=style&index=0&id=bed7279e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed7279e",
  null
  
)

export default component.exports