import {
  attach,
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getThemesTreeListFx } from '@/features/api/subject/get-themes-tree-list'
import { debounce } from 'patronum'
import {
  $selectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import {
  $selectedClass,
  classesDropdownModule,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { formatData } from '@/features/lib'
import { getThemeData } from '@/pages/dictionary/themes/create/parts/prerequisites/prerequisites.model'

const getThemesTreeList = attach({
  effect: getThemesTreeListFx,
})

export const themesDropdownModule = createFilter()

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

export const $themes = createStore<DropdownItem[]>([]).reset(componentDestroyed)

export const setSelectedTheme = createEvent<DropdownItem | null>()
export const $selectedTheme = restore(setSelectedTheme, null).reset(componentDestroyed)

const $formToGetThemeList = combine($selectedClass, $selectedSubject, (cl, obj) => ({
  study_year: cl && +cl.name,
  subject: obj && +obj.name,
  is_not_prerequisite: true,
}))

const debounced = debounce({
  source: $formToGetThemeList,
  timeout: 150,
})

export const $canSetThemePosition = combine(
  $selectedSubject,
  $themes,
  (subject, themes): boolean => !!subject && !!themes.length
)

guard({
  clock: debounced,
  filter: combine(
    $isComponentMounted,
    $selectedSubject,
    $selectedClass,
    (isMounted, study_year, subject) => {
      return isMounted && !!study_year?.name && !!subject?.name
    }
  ),
  target: getThemesTreeList,
})

sample({
  clock: getThemesTreeList.doneData.map(({ body }) => formatData(body)),
  target: $themes,
})

forward({
  from: themesDropdownModule.methods.resetDropdown,
  to: setSelectedTheme.prepend(() => null),
})

sample({
  clock: themesDropdownModule.methods.itemChanged,
  source: themesDropdownModule.store.$item,
  fn: (item: { name: string; title: string } | null) => {
    if (item) getThemeData(+item.name)
  },
})

forward({
  from: [classesDropdownModule.methods.itemChanged, subjectsDropdownModel.methods.itemChanged],
  to: themesDropdownModule.methods.resetItem.prepend(() => ({})),
})
