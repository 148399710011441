















import Vue, { PropType } from 'vue'
import 'simplebar/dist/simplebar.min.css'
import SimpleBar from 'simplebar'

export default Vue.extend({
  name: 'ScrollContainer',
  props: {
    maxHeight: { type: [Number, null] as PropType<number | null>, default: null },
    hideHorizontal: { type: Boolean as PropType<boolean> },
    autoHide: { type: Boolean as PropType<boolean>, default: true },
    hideVertical: { type: Boolean as PropType<boolean> },
    loading: { type: Boolean as PropType<boolean> },
  },
  data: () => ({
    bar: {} as SimpleBar,
    scrollElement: {} as HTMLElement,
    contentElement: {} as HTMLElement,
    scrollOffset: 100,
  }),
  computed: {
    classes() {
      return {
        'hide-horizontal': this.hideHorizontal,
        'hide-vertical': this.hideVertical,
      }
    },
  },

  watch: {
    loading(newVal) {
      if (!newVal) {
        Vue.nextTick(() => this.bar.recalculate())
      }
    },
  },

  mounted() {
    this.bar = new SimpleBar(this.$refs.simpleBar as HTMLElement)
    this.scrollElement = this.bar.getScrollElement()
    this.contentElement = this.bar.getContentElement()
    this.bar.getScrollElement().addEventListener('scroll', () => {
      const scrollElementBottomPosition = this.scrollElement.getClientRects()[0].bottom
      const contentElementBottomPosition = this.contentElement.getClientRects()[0].bottom
      const loadStartPosition = contentElementBottomPosition - this.scrollOffset

      if (loadStartPosition <= scrollElementBottomPosition && !this.loading) {
        this.$emit('infiniteHandler')
      }
    })
  },
})
