import { attach, createEvent, forward, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { FilterName } from '@/features/api/results/types'
import { coursesNameListFx } from '@/features/api/results/courses-name-list'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'

const getCourses = attach({
  effect: coursesNameListFx,
  mapParams: (params: GetListQueryParams) => params,
})

export const coursesNameDropdownModel = createDropdownMultiselectModel<FilterName>(getCourses)

export const loadCourses = createEvent<void>()

sample({
  clock: loadCourses,
  source: { $nextPage: coursesNameDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getCourses,
})

forward({
  from: coursesNameDropdownModel.methods.canLoadNextPage,
  to: loadCourses,
})

sample({
  clock: getCourses.doneData,
  source: { items: coursesNameDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newTags = res.body.data.map((field) => ({ name: `${field.id}`, title: field.name }))
    return [...items, ...newTags]
  },
  target: coursesNameDropdownModel.store.$items,
})
