import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { attach, createEvent, createStore, forward, guard } from 'effector-root'
import { getGroupsListFx } from '@/features/api/learning/courses/get-groups-list'
import { DropdownItem } from '@/pages/common/types'

export const groupsDropdownModel = createFilter()

export const loadGroups = createEvent<void>()

export const setSelectedGroups = createEvent<DropdownItem>()

export const deleteSelectedGroup = createEvent<DropdownItem>()

const getGroups = attach({
  effect: getGroupsListFx,
  mapParams: () => ({}),
})

forward({
  from: loadGroups,
  to: getGroups,
})

export const $groups = createStore<DropdownItem[]>([]).on(getGroups.doneData, (_, res) => [
  ...res.body.map((payload) => ({ name: `${payload.id}`, title: payload.name })),
  { name: 'all', title: 'Все' },
])

export const $selectedGroups = createStore<DropdownItem[]>([])
  .on(setSelectedGroups, (state, payload) => [...state, payload])
  .on(deleteSelectedGroup, (state, payload) =>
    state.filter((element) => element.name !== payload.name)
  )
  .reset([groupsDropdownModel.methods.resetDropdown])

export const unselectAllGroups = createEvent<void>()

export const selectAllGroups = createEvent<void>()

export const $isSelectedAllGroups = createStore<boolean>(false)
  .on(unselectAllGroups, () => false)
  .on(selectAllGroups, () => true)

guard({
  clock: setSelectedGroups,
  filter: (payload) => payload?.name !== 'all',
  target: unselectAllGroups,
})
