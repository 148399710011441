<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="lessons-filter"
  >
    <div class="row">
      <CertUsersDropdown class="half-third" @setItem="(val) => setItem({ students: val })" />
      <CoursesDropdown class="half-third" @setItem="(val) => setItem({ courses: val })" />
      <CertCreatorsDropdown
        class="half-third"
        @setItem="(val) => setItem({ created_by_id: val })"
      />
    </div>
    <div class="row">
      <div class="half-third">
        <GroupsFilterDropdown @setItem="(val) => setItem({ groups: val })" />
      </div>
      <div class="half-third">
        <div class="label">Создано после даты</div>
        <DatePicker
          :value="$acceptFrom"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setDateFrom"
        />
      </div>
      <div class="half-third">
        <div class="label">Создано до даты</div>
        <DatePicker
          :value="$acceptTo"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setDateTo"
        />
      </div>
    </div>
    <div class="row">
      <div class="labels-input-relative-align">
        <div class="buttons">
          <div class="btn">
            <BaseButton small @click="applyFilters"> Применить </BaseButton>
          </div>
          <div class="btn">
            <BaseButton
              border-without-bg
              class="borderless"
              small
              @click="resetFilters"
            >
              Сбросить фильтры
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <Icon
      type="close"
      class="close-icon"
      size="10"
      @click="toggleVisibility(false)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import {
  $acceptFrom,
  $acceptTo,
  setAcceptFrom,
  setAcceptTo,
  toggleVisibility,
} from '@/pages/results/certificates-generator/table/model/tooltip-filter.model'
import CoursesDropdown from '@/pages/common/dropdowns/courses/courses-name-dropdown/CoursesDropdown.vue'
import DatePicker from 'vue2-datepicker'
import GroupsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/GroupsFilterDropdown.vue'
import isCloseCalendar from '@/lib/utils/no_close_calendar'
import CertUsersDropdown from '@/pages/common/dropdowns/cert-users/cert-users-dropdown/CertUsersDropdown.vue'
import CertCreatorsDropdown from '@/pages/common/dropdowns/cert-users/cert-creators-dropdown/CertCreatorsDropdown.vue'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ToolTipFilter',
  components: {
    CertCreatorsDropdown,
    CertUsersDropdown,
    GroupsFilterDropdown,
    Icon,
    DatePicker,
    BaseButton,
    CoursesDropdown,
  },
  effector: {
    $acceptFrom,
    $acceptTo,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  methods: {
    setAcceptFrom,
    setAcceptTo,
    toggleVisibility,
    closeFilter(event) {
      if (isCloseCalendar(event)) {
        toggleVisibility(false)
      }
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
    },
    setDateFrom(date) {
      this.setAcceptFrom(date)
      this.setItem({ created_at_from: date })
    },
    setDateTo(date) {
      this.setAcceptTo(date)
      this.setItem({ created_at_to: date })
    },
  },
})
</script>

<style scoped>
.lessons-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.half-second {
  width: 100%;
  margin-right: 30px;
}

.label {
  font-weight: bold;
}

.date-picker {
  margin-top: 5px;
  width: 100%;
}
.date-picker /deep/ .mx-input {
  height: 46px;
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}

.labels-input-relative-align {
  width: 100%;
  display: flex;
  align-self: flex-end;
  height: 46px;

  .buttons {
    display: flex;
    align-items: center;
    margin-left: auto;

    .btn:first-child {
      margin-right: 38px;
    }
  }
}

.btn {
  width: max-content;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}
</style>
