




















































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $resultFilterBlock,
  $resultTestsFilterModalModalVisibility,
  resultFilterBlockChanged,
  resultTestsFilterModalVisibilityChanged,
  resetResultFilterBlock,
  saveFiltersChanged,
} from '@/pages/common/modals/result-tests-filter/result-tests-filter-modal.model'
import ResultTestsFilterBlock from '@/pages/common/modals/result-tests-filter/ResultTestsFilterBlock.vue'
import { TestResultExtraFilterElement } from '@/pages/common/modals/result-tests-filter/type'

export default Vue.extend({
  name: 'ResultTestsFilterModal',
  components: {
    Modal,
    BaseButton,
    ResultTestsFilterBlock,
  },
  effector: {
    $resultTestsFilterModalModalVisibility,
    $resultFilterBlock,
  },
  methods: {
    resultTestsFilterModalVisibilityChanged,
    resetResultFilterBlock,
    setBlock(newBlock: { block: TestResultExtraFilterElement; id: number }) {
      saveFiltersChanged(false)
      const setNewBlocks: TestResultExtraFilterElement[] = [...this.$resultFilterBlock].map(
        (block, index) => (index === newBlock.id ? newBlock.block : block)
      )
      resultFilterBlockChanged(setNewBlocks)
    },
    removeBlock(blockIndex: number) {
      saveFiltersChanged(false)
      const setNewBlocks: TestResultExtraFilterElement[] = [...this.$resultFilterBlock].filter(
        (block, index) => index !== blockIndex
      )
      resultFilterBlockChanged(setNewBlocks)
    },
    addBlock() {
      saveFiltersChanged(false)
      resultFilterBlockChanged([...this.$resultFilterBlock, new TestResultExtraFilterElement()])
    },
    submitFilters() {
      saveFiltersChanged(true)
      resultTestsFilterModalVisibilityChanged(false)
      this.$emit('onFilterSet')
    },
  },
})
