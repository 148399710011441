import { createEvent, forward, restore } from 'effector-root'
import {
  appointmentsFilters,
  resetAppointmentsFilters,
} from '@/pages/exams/appointments/list/model/appointments-filters.model'

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(resetAppointmentsFilters)

export const dropdownModules = {
  // classesDropdownModel,
}

forward({
  from: [resetAppointmentsFilters, toggleVisibility],
  to: [
    // classesDropdownModel.methods.resetDropdown,
  ],
})

forward({
  from: toggleVisibility,
  to: appointmentsFilters.methods.resetFilters,
})
