import { createEvent, createStore, guard } from 'effector-root'
import { resetFields } from '@/pages/exams/appointments/create/model/form-fileds.model'
import { getAppointmentIdFx } from '@/features/api/appointments/get-appointment-dy-id.ts'

export const changeAppointmentsId = createEvent<string | never>()

export const $editedAppointmentId = createStore<string>('')
  .on(changeAppointmentsId, (state, payload) => payload || state)
  .reset(resetFields)

guard({
  source: changeAppointmentsId,
  filter: (payload) => !!payload,
  target: getAppointmentIdFx,
})
