export const useVar = (lsKey: string, type: 'json' | 'number' | 'string', data?: any): any => {
  const formatFunctions: Record<'json' | 'number' | 'string', (ls: string) => any> = {
    json: (ls) => JSON.parse(ls),
    number: (ls) => Number(ls),
    string: (ls) => ls,
  }

  const LSValue = localStorage.getItem(lsKey)
  let value = null

  if (LSValue) {
    value = formatFunctions[type](LSValue)
  }

  // reset
  if (data === null) {
    localStorage.removeItem(lsKey)
  }

  // return ls data
  if (!data) {
    return value
  }

  // update
  if (value) {
    localStorage.setItem(
      lsKey,
      type === 'json'
        ? JSON.stringify({
            ...value,
            ...data,
          })
        : data
    )

    return data
  }

  // set
  localStorage.setItem(lsKey, JSON.stringify(data))

  return data
}
