
























































































































































































































































import Vue, { VueConstructor } from 'vue'
import qs from 'qs'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import PageHeader from '@/pages/learning/learning-courses-application/list/parts/header/PageHeader.vue'
import ApplicationsCoursesFilter from '@/pages/learning/learning-courses-application/list/parts/filter/ApplicationsCoursesFilter.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import Icon from '@/ui/icon/Icon.vue'
import TableColumnEditModal from '@/pages/common/modals/table-settings/table-colunm-edit/TableColumnEditModal.vue'
import CoursesSortModal from '@/pages/learning/modals/courses-sort-modal/CoursesSortModal.vue'
import {
  combineRouteQueries,
  computeSortParam,
  isQueryParamsEquelToPage,
  removeHtmlTags,
} from '@/features/lib'
import { config } from '@/config'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { DEFAULT_ID } from '@/pages/common/constants'
import {
  searchFieldsData,
  applicationsCoursesDataFields,
  getActions,
} from '@/pages/learning/learning-courses-application/constants'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  $canRefreshTable,
  incomingPageParams,
  canRefreshTableChanged,
  $isLoading,
  setNewTableFields,
  $tableFields,
  taskLoader,
  setIsLoading,
} from '@/pages/learning/learning-courses-application/list/applications-courses-page.model'
import {
  toggleVisibility,
  $visibility,
  applicationsCoursesFilters,
} from '@/pages/learning/learning-courses-application/list/parts/filter/applications-course-filter.model'
import {
  $setColumns,
  changeColumnsModalVisibility,
} from '@/pages/common/modals/table-settings/table-colunm-edit/table-colunm-edit-modal.model'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import {
  RefsType,
  HttpOptionsType,
  CommonInteractedItemParams,
  DisplayContextMenuPayload,
  ActionsItem,
  DropdownItem,
} from '@/pages/common/types'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { getActionsDisplayConditions } from '@/pages/common'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import ApplicationsCoursesUpdateModal from '@/pages/learning/learning-courses-application/list/parts/modals/application-course-update/ApplicationsCoursesUpdateModal.vue'
import {
  $canRefreshAfterMultiChanges,
  loadModalForMultiChanges,
  setIsOneSelect,
  setCourseInfo,
} from '@/pages/learning/learning-courses-application/list/parts/modals/application-course-update/application-course-update-modal.model'
import { LoadingCourseApplications, Question } from '@/features/api/learning/types'
import ContextCell from '@/pages/common/parts/context-cell/ContextCell.vue'
import { $permissions } from '@/features/session'
import { SubjectGrid } from '@/features/api/subject/types'
import {
  mapApplicationsStatusCourse,
  statusDropdownModule,
} from '@/pages/common/dropdowns/courses/status-dropdown/status-dropdown.model'
import { axiosClient } from '@/lib/request'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    PageHeader,
    GeneralFilter,
    ApplicationsCoursesFilter,
    TableHeader,
    ActionsButton,
    ContextMenu,
    Vuetable,
    VuetablePagination,
    LoaderBig,
    NoDataContent,
    Icon,
    TableColumnEditModal,
    CoursesSortModal,
    TooltipCell,
    ApplicationsCoursesUpdateModal,
    ContextCell,
  },
  effector: {
    $visibility,
    $canRefreshTable,
    $filterParams: applicationsCoursesFilters.store.$filterParams,
    $pageParams: incomingPageParams.store.$pageParams,
    $currentPage: incomingPageParams.store.currentPage,
    $isLoading,
    $setColumns,
    $tableFields,
    $canRefreshAfterMultiChanges,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID as number,
      searchFields: searchFieldsData,
      total: 1,
      fields: applicationsCoursesDataFields,
      selectedRows: [] as number[],
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuType: 'item' as ContextMenuType,
      contextMenuItems: [] as ActionsItem[],
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/ticket-app/course-ticket/list/`
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $canRefreshTable: {
      handler(newVal) {
        if (newVal === true) {
          this.$refs.vuetable.reload()
          canRefreshTableChanged(false)
        }
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $canRefreshAfterMultiChanges: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
  },
  methods: {
    changeFilter: applicationsCoursesFilters.methods.changeFilter,
    resetFilters: applicationsCoursesFilters.methods.resetFilters,
    applyFilters: applicationsCoursesFilters.methods.applyFilters,
    removeParamsFilter: applicationsCoursesFilters.methods.removeParamsFilter,
    changePage: incomingPageParams.methods.changePage,
    queryToParams: incomingPageParams.methods.queryToParams,
    changeColumnsModalVisibility,
    setNewTableFields,
    toggleVisibility,
    reset,
    setIsLoading,
    exportTable() {
      taskLoader.effect.exportFx(null)
      taskLoader.methods.setTaskLoading(true)
    },
    async editApplicationsCourse() {
      navigatePush({
        name: 'courses-application-edit-page',
        params: { id: `${this.selectedIds()}` },
      })
    },
    setStatus() {
      const ids = this.selectedIds()
      if (ids.length === 1) {
        const row = this.$refs.vuetable.tableData.find(
          (i: LoadingCourseApplications) => i.id === ids[0]
        )
        const subjects = row.subjects.map((s: SubjectGrid) => s.name).join(', ')
        const student = `${row.created_by.first_name} ${row.created_by.last_name}`
        const status = mapApplicationsStatusCourse.find(
          (item: DropdownItem) => item.name === row.status
        )
        statusDropdownModule.methods.itemChanged(status || null)
        setCourseInfo(`заявки №${row.id} (${student} - ${subjects})`)
        setIsOneSelect(true)
      }
      loadModalForMultiChanges(ids)
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const request = await axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' })
        },
      })
      return request
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
      event.preventDefault()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    setColumns() {
      this.setNewTableFields(this.$setColumns)
      this.changeColumnsModalVisibility(false)
    },
    clearWording(str: string) {
      if (str) return removeHtmlTags(str)
      return ''
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    getQuestions(data: Question | null): string {
      if (!data) return ''
      return data.questions.join(', ')
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
