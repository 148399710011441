import { DropdownItem } from '@/pages/common/types'

export class AttemptResultExtraFilterElement {
  subject: DropdownItem | null = null

  test: DropdownItem | null = null

  result: DropdownItem | null = null

  sign: DropdownItem | null = null

  result_number: DropdownItem | null = null
}
