























































import Vue, { PropType } from 'vue'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import Icon from '@/ui/icon/Icon.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import { FilterDropdownMethods } from '@/pages/common/filter-dropdown/types'
import SmallLoader from '@/pages/common/parts/internal-loader-blocks/SmallLoader.vue'
import {
  TaskDataType,
  TasksFilterDropdownStore,
} from '@/pages/learning/learning-lessons/create/model/types'
import { mapTaskTypeTo } from '@/pages/common/constants'
import { formatTitle, removeHtmlTags } from '@/features/lib'

export default Vue.extend({
  components: {
    BaseDropdown,
    Icon,
    SelectItem,
    SmallLoader,
  },
  props: {
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    data: { type: Array as PropType<TaskDataType[]>, default: [] },
    methods: { type: Object as PropType<FilterDropdownMethods>, required: true },
    store: { type: Object as PropType<TasksFilterDropdownStore>, required: true },
    disabled: { type: Boolean as PropType<boolean> },
    selectedData: { type: Array as PropType<TaskDataType[]> },
    isRecursive: { type: Boolean as PropType<boolean> },
    isShowPrerequisites: { type: Boolean as PropType<boolean> },
    loading: { type: Boolean as PropType<boolean>, default: false },
    secondClick: { type: Boolean as PropType<boolean>, default: false },
    disabledElementId: { type: Number },
    smallMenu: { type: Boolean, default: false },
    showDifficulty: { type: Boolean, default: true },
  },
  data() {
    return {
      toolTipText:
        'В этом задании баллы начисляются за каждый верный ответ. Если вы хотите учитывать только полностью верный ответ, переведите тумблер “Использовать баллы за задание” в неактивное состояние',
    }
  },
  computed: {
    correctValue(): string {
      return this.$props.store.$item && this.$props.store.$item.id !== 0
        ? `(${this.$props.store.$item.id}) ID задания`
        : this.$props.store.$searchString
    },
    items(): TaskDataType[] {
      return this.$props.store.$itemsDropdown
    },
  },
  watch: {
    data: {
      handler(val, oldVal) {
        if ((!oldVal.length && !!val.length) || oldVal !== val) {
          this.$props.methods.setItems(this.$props.data)
        }
      },
    },
  },
  methods: {
    onSelectItem(item: TaskDataType, cb: any, event: any | null = null) {
      // не выбирать и не закрывать дропдаун если кликнули по 'Предпросмотр'
      if (event && event?.srcElement?.textContent === 'Предпросмотр') return

      this.$emit('item-changed', item)
      const isSecondClick =
        this.selectedData && !!this.selectedData.find((el: TaskDataType) => el.id === item.id)
      if (this.secondClick && isSecondClick) {
        const resucedSelectedData = this.selectedData.filter((data) => data.id !== item.id)
        this.$props.methods.itemChanged(
          this.selectedData.length === 1 ? null : resucedSelectedData[0]
        )
      } else this.$props.methods.itemChanged(item)
      this.$props.methods.resetSearchString()
      cb()
    },
    clear(event: MouseEvent) {
      event.stopPropagation()
      this.$emit('item-changed', null)
      this.$props.methods.resetItem()
      this.$props.methods.resetSearchString()
    },
    handleClick(val: TaskDataType, cb: any) {
      if (this.disabledElementId && val.id === this.disabledElementId) return
      this.onSelectItem(val, cb)
    },
    iconName(type: string): string {
      return mapTaskTypeTo[type].icon
    },
    correctWording(text: string) {
      if (text.length > 20) {
        return `${removeHtmlTags(text).substring(0, 17)}...`
      }
      return text
    },
    getScore(score: number) {
      if (score) {
        return formatTitle(score, ['балл', 'балла', 'баллов'])
      }
      return ''
    },
  },
  mounted() {
    this.$props.methods.setItems(this.$props.data)
  },
})
