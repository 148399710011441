import { createEvent, forward, attach, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { getTags } from '@/features/api/content/news/get-tags'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/subject-filter-dropdown.model'

const getTagsEffect = attach({
  effect: getTags,
  mapParams: (params: GetListQueryParams) => params,
})

export const tagsDropdownModel = createDropdownMultiselectModel<{ id: string; name: string }>(
  getTagsEffect as any
)

export const loadTags = createEvent<void>()

sample({
  clock: loadTags,
  source: { $nextPage: tagsDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getTagsEffect,
})

sample({
  clock: loadTags,
  source: { $nextPage: tagsDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getTagsEffect,
})

forward({
  from: tagsDropdownModel.methods.canLoadNextPage,
  to: loadTags,
})

sample({
  clock: getTagsEffect.doneData,
  source: { items: subjectsDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newTags = res.body.data.map((field) => ({ name: `${field.id}`, title: field.name }))
    return [...items, ...newTags]
  },
  target: tagsDropdownModel.store.$items,
})
