import { createEvent, forward, attach, restore, createStore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { getEmployeesListCert } from '@/features/api/employee/get-employee-list'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'

const getCreatorsCert = attach({
  effect: getEmployeesListCert,
})

export const certCreatorsDropdownModel = createFilter()

export const $certCreators = createStore<DropdownItem[]>([])
export const setSelectedCreator = createEvent<DropdownItem | null>()
export const $selectedCreator = restore(setSelectedCreator, null)

export const loadCertCreators = createEvent<void>()

forward({
  from: loadCertCreators,
  to: getCreatorsCert.prepend(() => ({})),
})

forward({
  from: getCreatorsCert.doneData.map((res) =>
    res.body.map((user) => ({ name: `${user.id}`, title: user.name }))
  ),
  to: $certCreators,
})

forward({
  from: certCreatorsDropdownModel.methods.resetDropdown,
  to: setSelectedCreator.prepend(() => null),
})
