















import Vue, { PropType } from 'vue'

let Sprite = ''

if (process.env.NODE_ENV === 'production') {
  Sprite = require('@/assets/icons/sprite.svg')
}

export default Vue.extend({
  props: {
    size: { type: String as PropType<string>, default: '24px' },
    viewBox: { type: String as PropType<string>, default: '0 0 64 64' },
    type: { type: String as PropType<string>, required: true },
  },
  data: () => ({
    sprite: Sprite,
  }),

  computed: {
    spritepath() {
      return `${Sprite}#${this.type}-icon`
    },
  },
})
