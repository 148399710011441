


















import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { CreateEmployeeTableCell } from '@/features/api/employee/types'

export default Vue.extend({
  name: 'CheckboxCell',
  components: {
    Icon,
  },
  props: {
    title: { type: String },
    rowId: { type: Number, required: true },
    dataId: { type: String, default: '' },
    dataCell: { type: Object as PropType<CreateEmployeeTableCell> },
    iconType: { type: String },
    isSelected: { type: Boolean },
  },
  methods: {
    handleClick(event: any) {
      event.preventDefault()
      const changedData = { ...this.$props.dataCell }
      changedData[this.$props.dataId] = !changedData[this.$props.dataId]
      this.$emit('handleCellClick', {
        data: changedData,
        type: this.$props.dataId,
        event,
      })
    },
  },
  mounted() {
    const container = document.querySelector(`#cell-${this.$props.rowId}-${this.$props.dataId}`)
    container && container.addEventListener('click', this.handleClick)
  },
  beforeDestroy() {
    const container = document.querySelector(`#cell-${this.$props.rowId}-${this.$props.dataId}`)
    container && container.removeEventListener('click', this.handleClick)
  },
})
