import CoursesFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/courses-name-dropdown-filter/CoursesFilterDropdown.vue'
import CourseTypeDropdown from '@/pages/common/dropdowns/courses/course-type-filter/CourseTypeDropdown.vue'
import SubjectFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/SubjectFilterDropdown.vue'
import ClassesFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/ClassesFilterDropdown.vue'
import GroupsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/GroupsFilterDropdown.vue'
import StatusFilterDropdown from '@/pages/common/dropdowns/courses/status-dropdown/StatusFilterDropdown.vue'

export const dropdownResultCoursesComponents = {
  CoursesFilterDropdown,
  CourseTypeDropdown,
  SubjectFilterDropdown,
  ClassesFilterDropdown,
  GroupsFilterDropdown,
  StatusFilterDropdown,
}
