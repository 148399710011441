import IAddFileError from '@/pages/results/certificates-generator/create-certificates/model/interfaces/IAddFileError'

const handleCreateBlobFileErrors = (data: ArrayBuffer): IAddFileError[] =>
  (
    JSON.parse(new TextDecoder().decode(data)) as {
      row_errors: IAddFileError[]
    }
  ).row_errors

export default handleCreateBlobFileErrors
