

















import Vue from 'vue'
import TaskHeader from '@/pages/bank/common/parts/Header.vue'
import TaskContent from '@/pages/bank/olympiad-tasks/create/parts/Content.vue'
import TaskFooter from '@/pages/bank/common/parts/Footer.vue'
import {
  save,
  $canSave,
  clearFields,
  setRedirectAfterSave,
  createOlympiadAssignment,
  componentMounted,
  componentDestroyed,
} from '@/pages/bank/olympiad-tasks/create/task-creation-page.model'

export default Vue.extend({
  name: 'TaskCreationPage',
  components: {
    TaskHeader,
    TaskContent,
    TaskFooter,
  },
  effector: {
    $canSave,
    isLoading: createOlympiadAssignment.pending,
  },
  methods: {
    saveTask(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      save()
    },
  },
  mounted() {
    componentMounted()
  },
  beforeDestroy() {
    componentDestroyed()
    clearFields()
  },
})
