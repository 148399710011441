













import Vue, { PropType } from 'vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { resultCoursesFilters } from '@/pages/results/courses-all/parts/filter/course-result-filter.model'
import { FiltersParams } from '@/pages/common/types'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { exportResultCoursesCheckListFx } from '@/features/api/results/export-result-courses-check'

export default Vue.extend({
  components: {
    HeaderPopup,
    DownloadButton,
  },
  effector: {
    $filterParams: resultCoursesFilters.store.$filterParams,
    isLoadingExport: exportResultCoursesCheckListFx.pending,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
  },
  methods: {
    setItem(filter: FiltersParams) {
      this.$emit('changeFilter', filter)
      this.$emit('setFilter')
    },
  },
})
