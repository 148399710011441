
























































































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import { $token } from '@/features/api/common/request'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import CompareAnswerModal from '@/pages/results/olympiad-results/parts/CompareAnswerModal.vue'
import { ContextMenuItem, ContextMenuType } from '@/pages/common/parts/context-menu/types'
import {
  studentPageMounted,
  $isLoading,
  $refreshPage,
} from '@/pages/users/students/list/model/users-students-page.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { $permissions } from '@/features/session'
import {
  RefsType,
  HttpOptionsType,
  DisplayContextMenuPayload,
  CommonInteractedItemParams,
  ActionsItem,
} from '@/pages/common/types'
import { navigatePush } from '@/features/navigation/navigationMethods'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import {
  OlympiadResultsTableFields,
  searchFieldsData,
} from '@/pages/results/olympiad-results/model/constants'
import OlympiadHeader from '@/pages/results/olympiad-results/parts/OlympiadHeader.vue'
import ToolTipFilter from '@/pages/results/olympiad-results/parts/TooltipFilter.vue'
import {
  olympiadResultsFilters,
  olympiadResultsPageParams,
  toggleVisibility,
  $visibility,
  exportOlympiadResultsList,
} from '@/pages/results/olympiad-results/model/olympiad-results-filter.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import {
  toggleModalVisibility,
  setCorrectAnswer,
  setStudentAnswer,
} from '@/pages/results/olympiad-results/model/compare-answers.model'
import Answers from '@/pages/results/answer-types/Answers.vue'
import { axiosClient } from '@/lib/request'
import { getActionsDisplayConditions } from '@/pages/common'
import { getOlympiadActions } from '@/pages/results/olympiad-results/constants'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'OlympiadResultsPage',
  components: {
    Vuetable,
    VuetablePagination,
    OlympiadHeader,
    TableHeader,
    GeneralFilter,
    ToolTipFilter,
    TooltipCell,
    ContextMenu,
    ActionsButton,
    NoDataContent,
    CompareAnswerModal,
    Answers,
  },
  effector: {
    $token,
    $visibility,
    $isLoading,
    $refreshPage,
    $filterParams: olympiadResultsFilters.store.$filterParams,
    $pageParams: olympiadResultsPageParams.store.$pageParams,
    $treeView: olympiadResultsPageParams.store.treeView,
    $currentPage: olympiadResultsPageParams.store.currentPage,
    $permissions,
  },
  data() {
    return {
      interactedAssignmentId: DEFAULT_ID,
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      searchFields: searchFieldsData,
      fields: OlympiadResultsTableFields,
      total: 0,
      selectedRows: [] as number[],
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/results-app/results/olympiad/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getOlympiadActions(displayConditions, this.$permissions!)
    },
    contextMenuItems(): ContextMenuItem[] {
      return [
        { name: 'editTask', title: 'Задание (редактирование)', action: 'onEditTask' },
        { name: 'previewTask', title: 'Задание (предпросмотр)', action: 'onPreviewTask' },
        { name: 'compareAnswers', title: 'Сравнить ответы', action: 'onCompareAnswers' },
      ]
    },
  },
  watch: {
    $refreshPage: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.onRemoveSelection()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.onRemoveSelection()
      },
    },
  },
  methods: {
    changeFilter: olympiadResultsFilters.methods.changeFilter,
    resetFilters: olympiadResultsFilters.methods.resetFilters,
    applyFilters: olympiadResultsFilters.methods.applyFilters,
    toggleTreeView: olympiadResultsPageParams.methods.toggleTreeView,
    changePage: olympiadResultsPageParams.methods.changePage,
    queryToParams: olympiadResultsPageParams.methods.queryToParams,
    toggleVisibility,
    exportOlympiadResultsList,
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const request = axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
      return request
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.onRemoveSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      toggleVisibility(false)
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onCompareAnswers() {
      const currentItem = this.$refs.vuetable.tableData.filter((item: { id: number }) =>
        this.selectedIds.includes(item.id)
      )
      if (currentItem && currentItem.length > 0) {
        setCorrectAnswer(currentItem[0])
        setStudentAnswer(currentItem[0])
        toggleModalVisibility(true)
      }
    },
    onEditTask() {
      navigatePush({
        name: 'olympiad-tasks-edit',
        query: {
          questions: `${this.interactedAssignmentId}`,
          fromPage: 'results-olympiad-tasks',
        },
        params: { id: `${this.interactedAssignmentId}` },
      })
    },
    onPreviewTask() {
      this.$router.push({
        name: 'preview-task',
        query: {
          questions: `${this.interactedAssignmentId}`,
          fromPage: 'tasks',
          taskType: 'olympiad-assignment',
          token: this.$token,
        },
      })
    },
    onRemoveSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    headerActionsButtonClick(event: MouseEvent) {
      this.handleActionsClick({
        data: { id: this.selectedIds[0] },
        event,
        type: 'header-actions',
      })
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      // @ts-ignore
      this.interactedAssignmentId = data.assignment_id
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: { id: number }) {
      this.interactedItemId = data.id
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      showContextMenu()
    },
    handleRowClick(res: any) {
      this.interactedAssignmentId = res.data.assignment_id
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    tooltipTitle(data: { number: string; scores: number }[]): string {
      if (data.length > 0) {
        const newData = data.map((clue: { number: string; scores: number }) => {
          return `Подсказка ${clue.number} (${clue.scores} балла)`
        })
        return newData.join(',')
      }
      return 'Нет'
    },
  },
  mounted() {
    studentPageMounted()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
