import CoursesFilterDropdown from '@/pages/common/dropdowns/courses/courses-name-dropdown/CoursesDropdown.vue'
import LessonDropdown from '@/pages/common/dropdowns/lessons/LessonDropdown.vue'
import GroupsDropdown from '@/pages/common/dropdowns/groups/GroupsDropdown.vue'
import StudentsDropdown from '@/pages/common/dropdowns/students/StudentsDropdown.vue'
import LessonStatusDropdown from '@/pages/results/courses-students/parts/filter/parts/lesson-status-dropdown/LessonStatusDropdown.vue'

export const dropdownResultCoursesComponents = {
  CoursesFilterDropdown,
  LessonStatusDropdown,
  LessonDropdown,
  GroupsDropdown,
  StudentsDropdown,
}
