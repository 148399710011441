import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { StudentAppointmentsData } from '@/pages/exams/appointments/create/model/types'

export const getStudentsGroupsListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<StudentAppointmentsData[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/user-app/student/groups/filter/',
    query,
  }),
})
