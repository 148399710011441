import { TableField } from '@/pages/applications/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { ActionsDisplayConditions } from '@/pages/common'
import { ElementType } from '@/pages/results/courses-students/types'

export const getElementType = (status: ElementType): string =>
  ({
    [ElementType.file]: 'Файл',
    [ElementType.assignment]: 'Назначение',
    [ElementType.text]: 'Текст',
    [ElementType.link]: 'Ссылка',
    [ElementType.answer_text_field]: 'Текстовое поле ответа',
    [ElementType.answer_file_field]: 'Файловое поле ответа',
  }[status] || '-')

export const studentsResultDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'answer_obj.answer_dt',
    sortField: 'answer_dt',
    title: 'Дата и время отправки урока',
    titleClass: 'wrap',
    // width: '250px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : '–'
    },
  },
  {
    name: 'number',
    sortField: 'number',
    title: 'Номер блока',
    dataClass: 'center aligned',
    formatter(number) {
      return number || '-'
    },
  },
  {
    name: 'type',
    sortField: 'type',
    title: 'Тип блока',
    // width: '210px',
  },
  {
    name: 'task.wording',
    sortField: 'wording',
    dataClass: 'question',
    // width: '250px',
    title: 'Вопрос/название',
    formatter(data: string) {
      return data || '-'
    },
  },
  {
    name: 'answer',
    sortField: 'answer',
    title: 'Ответ ученика',
    // width: '700px',
    formatter(data) {
      return data.answer_obj.answer || 'Ошибка загрузки данных'
    },
  },
  {
    name: 'correct_answer',
    sortField: 'correct_answer',
    title: 'Правильный ответ',
    // width: '700px',
    formatter(data) {
      return data.answer_obj.correct_answer || 'Ошибка загрузки данных'
    },
  },
  {
    name: 'score',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Оценка',
    width: '120px',
  },
  {
    name: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Баллы',
    width: '120px',
    formatter(data: any) {
      return data.element_type !== 'assignment' && data.task ? data.task?.score : data.score
    },
  },
  {
    name: 'commentary',
    sortField: 'commentary',
    title: 'Комментарий преподавателя',
    // width: '260px',
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '50px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'question_title', title: 'Вопрос/название' },
  { name: 'student_answer', title: 'Ответ ученика' },
  { name: 'correct_answer', title: 'Правильный ответ' },
  { name: 'teacher_comment', title: 'Комментарий преподавателя' },
]

export const mapLessonStatus = [
  {
    name: 'not_verified',
    title: 'не проверен',
  },
  {
    name: 'verified',
    title: 'проверен',
  },
  {
    name: 'deferred',
    title: 'отложен',
  },
]

export const getResultCoursesStudentActions = (
  displayOptions: ActionsDisplayConditions,
  isAssignment: boolean
) => {
  const actions = [
    {
      name: 'delete',
      title: 'Удалить результаты',
      action: 'delete',
      isDisplayed: true,
      hasPermissionToSee: true,
    },
    {
      name: 'studentResult',
      title: 'Сводные результаты по ученику',
      action: 'onStudentResult',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
    {
      name: 'compare_answers',
      title: 'Сравнить ответы',
      action: 'compare_answers',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
    {
      name: 'editAssignment',
      title: 'Открыть задание (редактирование)',
      action: 'onEditAssignment',
      isDisplayed: displayOptions.isTableItem && isAssignment,
      hasPermissionToSee: true,
    },
    {
      name: 'viewAssignment',
      title: 'Открыть задание (предпросмотр)',
      action: 'onViewAssignment',
      isDisplayed: displayOptions.isTableItem && isAssignment,
      hasPermissionToSee: true,
    },
    {
      name: 'openLesson',
      title: 'Открыть урок',
      action: 'onOpenLesson',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
    {
      name: 'onRemoveSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
