import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportAssignmentQueryParams } from '@/features/api/assignment/types/types'

export const exportTestAssignmentsListFx = createApiEffect<
  ExportAssignmentQueryParams,
  { task_id: string }
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/assignment-app/test-assignment/list/export/',
    query,
  }),
})
