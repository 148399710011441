import { TableField } from '@/pages/applications/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { ActionsDisplayConditions } from '@/pages/common'
import { Employee, EmployeePermissions } from '@/features/api/employee/types'
import { ApplicationsCourseStatus } from '@/pages/learning/learning-courses-application/types'

export const getStatusName = (status: ApplicationsCourseStatus): string =>
  ({
    [ApplicationsCourseStatus.accepted]: 'Одобрена',
    [ApplicationsCourseStatus.declined]: 'Отклонена',
    [ApplicationsCourseStatus.excluded]: 'Исключена',
    [ApplicationsCourseStatus.suspended]: 'Приостановлена',
    [ApplicationsCourseStatus.waiting]: 'Ожидает',
  }[status] || '-')

export const setStatusField = (
  status: ApplicationsCourseStatus
): { name: string; title: string } => ({
  name: status,
  title: getStatusName(status),
})

export const applicationsCoursesDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID заявки',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '120px',
    formatter(id: number) {
      return id || '-'
    },
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Дата подачи',
    width: '170px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'accepted_at',
    sortField: 'accepted_at',
    title: 'Дата одобрения заявки',
    titleClass: 'wrap',
    width: '170px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'studentId',
    sortField: 'created_by_id',
    title: 'ID ученика',
    dataClass: 'center aligned',
    width: '120px',
    formatter(student: Employee | null) {
      return student ? student.id : '-'
    },
  },
  {
    name: 'studentElkId',
    sortField: 'created_by_elk_id',
    title: 'ID ЕЛК',
    width: '90px',
  },
  {
    name: 'studentName',
    sortField: 'created_by__first_name',
    title: 'Фамилия Имя',
    width: '140px',
    formatter(student: Employee | null) {
      return student ? `${student.first_name} ${student.last_name}` : '-'
    },
  },
  {
    name: 'study_year',
    sortField: 'classes',
    title: 'Класс',
    dataClass: 'center aligned',
    width: '90px',
    formatter(student: { class: { name: string } }) {
      return (student && student.class.name) || '-'
    },
  },
  {
    name: 'group',
    sortField: 'group',
    title: 'Группа',
    width: '180px',
    formatter(group: { id: number; name: string } | null) {
      return group ? group.name : '-'
    },
  },
  {
    name: 'course',
    sortField: 'course',
    title: 'Название курса',
    width: '220px',
    formatter(course: { id: number; name: string } | null) {
      return course ? course.name : '-'
    },
  },
  {
    name: 'subjects',
    sortField: 'subjects',
    dataClass: 'center aligned',
    title: 'Предмет',
    width: '140px',
    formatter(subjects: { id: number; name: string }[]) {
      return subjects ? subjects.map((s) => s.name).join(', ') : '-'
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус заявки',
    width: '170px',
    formatter(status: ApplicationsCourseStatus) {
      return getStatusName(status)
    },
  },
  {
    name: 'questions',
    sortField: 'questions',
    title: 'Состав заявки',
    width: '500px',
  },
  {
    name: 'comment',
    sortField: 'comment',
    title: 'Заметка',
    width: '140px',
    formatter(status: string) {
      return status || '-'
    },
  },
  {
    name: 'lesson_access_dt',
    sortField: 'lesson_access_dt',
    title: 'Уроки будут отключены с',
    width: '250px',
    formatter(datetime: string | null) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },

  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '50px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'created_by', title: 'Ученику' },
  { name: 'id', title: 'Номер заявки' },
  { name: 'status', title: 'Статус заявки' },
  { name: 'course', title: 'Курс' },
  { name: 'subject', title: 'Предмет' },
  { name: 'description', title: 'Описанию курса' },
]

export const getActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions?.courses_course_courses.can_edit,
    },
    {
      name: 'setStatus',
      title: 'Изменить статус',
      action: 'onSetStatus',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
    {
      name: 'onRemoveSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
