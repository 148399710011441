
















import Vue from 'vue'
import {
  setRedirectAfterSave,
  getPageParams,
} from '@/pages/learning/learning-lessons/create/model/create-lesson.model'
import {
  $disabledSaveButtons,
  resetFields,
} from '@/pages/learning/learning-lessons/create/model/form-fileds.model'
import CreateLessonContent from '@/pages/learning/learning-lessons/create/parts/CreateLessonContent.vue'
import CreateLessonHeader from '@/pages/learning/learning-lessons/create/parts/CreateLessonHeader.vue'
import CreateLessonFooter from '@/pages/learning/learning-lessons/create/parts/CreateLessonFooter.vue'
import { changeLessonId } from '@/pages/learning/learning-lessons/create/model/edit-lesson.model'
import { loadFolderThemes } from '@/pages/common/dropdowns/themes-assigment-tree/themes-assigment-dropdown.model'

export default Vue.extend({
  name: 'CreateLessonPage',
  components: {
    CreateLessonHeader,
    CreateLessonContent,
    CreateLessonFooter,
  },
  effector: {
    $disabledSaveButtons,
  },
  methods: {
    saveLesson(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      getPageParams()
    },
  },
  created() {
    loadFolderThemes()
    changeLessonId(this.$route.params.id)
  },
  beforeDestroy() {
    resetFields()
  },
})
