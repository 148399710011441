












































































import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FormLabel from '@/ui/label/FormLabel.vue'
import {
  $mainImage,
  uploadMainImage,
  setContainerWidth,
  $mainImageUploading,
  $hideDragAndDropControls,
  $mainImageSize,
  $scale,
  addInput,
  addDroppableImage,
  createResizableBlock,
  $canCreateResizableBlock,
  setNextResizerToText,
  setNextResizerToImage,
  removeMainImage,
  setMainImageSize,
} from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/moving-images-on-image-answer-form.model'
import FilePicker from '@/ui/file-picker/FilePicker.vue'
import Spinner from '@/ui/spinner/Spinner.vue'
import DraggableInputs from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/DraggableInputs.vue'
import DraggableImages from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/DraggableImages.vue'
import ResizableCreator from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/resizable/ResizableCreator.vue'
import ImageMatchItem from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/correct-images-match-picker/ImageMatchItem.vue'
import { DraggableImage } from '@/pages/common/parts/tasks/types'
import { BACKGROUND_IMAGE_SIZE } from '@/pages/common/constants'

export default Vue.extend({
  name: `DragAndDropImageContainer`,
  effector: {
    $mainImage,
    $mainImageUploading,
    $hideDragAndDropControls,
    $mainImageSize,
    $scale,
    $canCreateResizableBlock,
  },
  components: {
    ResizableCreator,
    DraggableImages,
    DraggableInputs,
    Spinner,
    FilePicker,
    ImageMatchItem,
    FormLabel,
    BaseButton,
  },
  computed: {
    scaledSizes() {
      if (!this.$mainImageSize) {
        return 0
      }
      return {
        width: `${this.$mainImageSize.width * this.$scale}px`,
        height: `${this.$mainImageSize.height * this.$scale}px`,
      }
    },
    tooltipOptions() {
      return {
        content: 'Добавьте область для прикрепления изображения',
      }
    },
    tooltipOptionsText() {
      return {
        content: 'Добавьте текстовое поле',
      }
    },
  },
  methods: {
    setNextResizerToImage,
    setNextResizerToText,
    setMainImageSize,
    createResizableBlock,
    removeMainImage,
    uploadMainImage,
    addInput,
    addDroppableImage,
    saveContainerWidth() {
      const el = this.$el
      if (el) {
        setContainerWidth(el.clientWidth)
      }
    },
    triggerInputFile() {
      const filePicker = document.getElementById('file-picker')
      filePicker!.click()
    },
    updateMainImageSizes(event: DraggableImage) {
      if (event.size.width <= BACKGROUND_IMAGE_SIZE) setMainImageSize(event.size)
    },
  },
  mounted() {
    window.addEventListener('resize', this.saveContainerWidth)
    this.saveContainerWidth()
  },
  destroyed() {
    window.removeEventListener('resize', this.saveContainerWidth)
    setContainerWidth(0)
  },
})
