import { createApiEffect } from '@/features/api/common/create-api-effect'
import {
  ExportAssignmentQueryParams,
  GetAssignmentListQueryParams,
} from '@/features/api/assignment/types/types'

export const exportAttemptsListFx = createApiEffect<
  ExportAssignmentQueryParams,
  { task_id: string }
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/part-time/attempts/list/export/',
    query,
  }),
})

export const exportFullAttemptsListFx = createApiEffect<
  ExportAssignmentQueryParams,
  { task_id: string }
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/attempts/export/',
    query,
  }),
})

export const exportArchiveFx = createApiEffect<ExportAssignmentQueryParams, { task_id: string }>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/attempts/export/',
    query,
  }),
})

export const exportAttemptsArchiveFx = createApiEffect<
  GetAssignmentListQueryParams,
  { task_id: string }
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/attempts/export-archive/',
    query,
  }),
})
