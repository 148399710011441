import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TreeDataInfoResponse, TreeDataResponse } from '@/features/api/types'
import { FiltersParams } from '@/pages/common/types'

export const getExamTestsTreeFx = createApiEffect<FiltersParams, TreeDataResponse>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/tests/tree/',
    query,
  }),
})

export const getExamTestsTreeLightFx = createApiEffect<void, TreeDataResponse>({
  requestMapper: () => ({
    method: 'GET',
    url: '/api/exams-app/tests/tree/light/',
  }),
})

export const getExamTestsInfoFx = createApiEffect<void, TreeDataInfoResponse>({
  requestMapper: () => ({
    method: 'GET',
    url: '/api/exams-app/tests/tree/info/',
  }),
})
