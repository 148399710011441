































import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FormLabel from '@/ui/label/FormLabel.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  $mainTemplate,
  changeMainTemplate,
  inputsCounter,
  $inputs,
  droppableImagesCounter,
} from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'

let editorInstance: any = null

export default Vue.extend({
  name: `TextAnswerWysiwyg`,
  components: {
    FormLabel,
    BaseButton,
    Wysiwyg,
  },
  effector: {
    $mainTemplate,
    $inputs,
  },
  computed: {
    tooltipOptionsImage() {
      return {
        content: 'Добавьте область для прикрепления изображения',
      }
    },
    tooltipOptionsText() {
      return {
        content: 'Добавьте текстовое поле',
      }
    },
  },
  methods: {
    changeMainTemplate,
    addImage() {
      editorInstance.execCommand('addImageDNDBlock')
    },
    addText() {
      editorInstance.execCommand('addTextDNDBlock')
    },
    instanceReady(editor: any) {
      editor.addCommand('addTextDNDBlock', {
        exec: () =>
          editor.insertHtml(
            `<input
              class="redactor-input field droppable"
              style="width: 100px; height: 20px;"
              type="text"
              placeholder="B${inputsCounter.next()}"
            >&nbsp;`
          ),
      })
      editor.addCommand('addImageDNDBlock', {
        exec: () => {
          const placeholder = `A${droppableImagesCounter.next()}`
          const correctWidth = placeholder.length
          return editor.insertHtml(
            `<input
              class="redactor-drop droppable"
              style="width: ${correctWidth}em; height: 30px;"
              type="text"
              placeholder="${placeholder}"
            >&nbsp;`
          )
        },
      })
      editorInstance = editor
    },
  },
})
