






















import Vue from 'vue'
import FormLabel from '@/ui/label/FormLabel.vue'
import {
  $inputs,
  replaceInput,
  removeInput,
} from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/moving-images-on-image-answer-form.model'
import CorrectTextAnswerItem from './CorrectTextAnswerItem.vue'

export default Vue.extend({
  name: `CorrectTextAnswers`,
  effector: {
    $inputs,
  },
  components: {
    CorrectTextAnswerItem,
    FormLabel,
  },
  methods: {
    replaceInput,
    removeInput,
  },
})
