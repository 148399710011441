import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import ExamsFullTestsListDropdown from '@/pages/common/dropdowns/exams/exams-full-tests/ExamsFullTestsListDropdown.vue'
import GroupsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/GroupsFilterDropdown.vue'
import StudentsExamsFullAnswerFilter from '@/pages/exams/full-time-answers/list/parts/students-exams-full-answer-filter/StudentsExamsFullAnswerFilter.vue'
import AttemptsFilter from '@/pages/exams/full-time-answers/list/parts/full-attempts-filter/FullAttemptsFilter.vue'
import ExamsBlockFilter from '@/pages/exams/full-time-answers/list/parts/exams-block-filter/ExamsBlockFilter.vue'
import ExamsElemTypeFilter from '@/pages/exams/full-time-answers/list/parts/exams-elem-type-filter/ExamsElemTypeFilter.vue'

export const dropdownFullExamsAnswerComponents = {
  GroupsFilterDropdown,
  SubjectsDropdown,
  ClassesDropdown,
  ExamsFullTestsListDropdown,
  StudentsExamsFullAnswerFilter,
  AttemptsFilter,
  ExamsBlockFilter,
  ExamsElemTypeFilter,
}
