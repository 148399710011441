import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import ExamsTestsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/exams-tests-filter/ExamsTestsFilterDropdown.vue'
import GroupsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/GroupsFilterDropdown.vue'
import StudentsExamsAnswerFilter from '@/pages/exams/part-time-answers/list/parts/students-exams-answer-filter/StudentsExamsAnswerFilter.vue'
import AttemptsFilter from '@/pages/exams/part-time-answers/list/parts/attempts-filter/AttemptsFilter.vue'

export const dropdownExamsAnswerComponents = {
  GroupsFilterDropdown,
  SubjectsDropdown,
  ClassesDropdown,
  ExamsTestsFilterDropdown,
  StudentsExamsAnswerFilter,
  AttemptsFilter,
}
