import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { StudentExamAnswerData } from '@/features/api/part-time-answer/types'

export const getStudentsListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<StudentExamAnswerData[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/exams-app/part-time/attempts/filter/students/',
    query,
  }),
})
