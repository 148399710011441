














import Vue from 'vue'
import SubjectHeader from '@/pages/dictionary/subjects/common/Header.vue'
import {
  redirectAfterSaveChanged,
  save,
  changeIdSubject,
} from '@/pages/dictionary/subjects/edit/subjects-edition-page.model'
import { clearFields } from '@/pages/dictionary/subjects/common/create-edit.model'
import LeftBlock from '@/pages/dictionary/subjects/common/LeftBlock.vue'
import RightBlock from '@/pages/dictionary/subjects/common/RightBlock.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'

export default Vue.extend({
  name: 'SubjectEditPage',
  components: { SubjectHeader, LeftBlock, RightBlock },
  methods: {
    save,
    createWithRedirect() {
      redirectAfterSaveChanged(true)
      save()
    },
  },
  mounted() {
    !this.$route.params.id
      ? navigatePush({ name: 'subjects-list' })
      : changeIdSubject(+this.$route.params.id)
  },
  beforeDestroy() {
    redirectAfterSaveChanged(false)
    clearFields()
  },
})
