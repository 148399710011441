




























































































































































































































import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FormInput from '@/ui/input/FormInput.vue'
import {
  $groupComment,
  $groupName,
  $searchFieldGroup,
  $searchStudent,
  $studentsForGroup,
  changeStudentsForGroup,
  $studentsForGroupCount,
  setGroupName,
  setGroupComment,
  setSearchStudent,
  setSearchSelectedStudent,
  $searchSelectedStudent,
  $studentsForGroupFilterList,
  searchFieldGroupChanged,
} from '@/pages/users/groups/create/model/form-fileds.model'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import { searchFieldsDataCreateGroup } from '@/pages/users/groups/create/model/types'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import {
  $studentsForTableGroups,
  loadStudents,
  studentGroupsFilters,
  studentGroupsPageParams,
  $studentIds,
  setStudentIds,
} from '@/pages/users/groups/create/model/student-shearch-groups-filters.model'
import VueEvents from 'vue-events'
import { studentsCreateGroupsTableFields } from '@/pages/users/groups/create/model/constants'
import StudentCard from '@/pages/users/groups/create/parts/StudentCard.vue'
import SmallLoader from '@/pages/common/parts/internal-loader-blocks/SmallLoader.vue'
import { StudentAppointmentsDataMinimal } from '@/pages/exams/appointments/create/model/types'
import { getStudentsGroupsListFx } from '@/features/api/groups/get-students-list'
import { DropdownItem } from '@/pages/common/types'
import StudentErrorMessage from '@/pages/common/parts/users/StudentErrorMessage.vue'
import get_ids_for_string from '@/lib/utils/get_ids_for_string'

Vue.use(VueEvents)

export default Vue.extend({
  name: 'CreateGroupContent',
  components: {
    FormInput,
    BaseButton,
    BaseTextarea,
    BaseDropdown,
    SelectItem,
    ClassesDropdown,
    StudentCard,
    SmallLoader,
    StudentErrorMessage,
  },
  effector: {
    $groupName,
    $groupComment,
    $searchFieldGroup,
    $searchStudent,
    $filterParams: studentGroupsFilters.store.$filterParams,
    $pageParams: studentGroupsPageParams.store.$pageParams,
    $treeView: studentGroupsPageParams.store.treeView,
    $currentPage: studentGroupsPageParams.store.currentPage,
    $studentsForGroup,
    $studentsForGroupCount,
    $searchSelectedStudent,
    $studentsForGroupFilterList,
    $studentsForTableGroups,
    isLoading: getStudentsGroupsListFx.pending,
    $studentIds,
  },
  data() {
    return {
      debounceId: undefined as ReturnType<typeof setTimeout> | undefined,
      title: 'Искать везде',
      searchFields: searchFieldsDataCreateGroup,
      fields: studentsCreateGroupsTableFields,
      checkedIds: [] as number[],
      isShowTable: false,
      isSearchStudentsId: false,
      noSearchStudentsId: [] as number[],
      searchStudentsId: [] as number[],
      idsError: false,
    }
  },
  computed: {
    correctTittle(): string {
      return this.title.length > 15 ? `${this.title.substring(0, 12)}...` : this.title
    },
  },
  watch: {
    isLoading(value) {
      if (!value && this.isSearchStudentsId) {
        const showingStudents: number[] = this.searchStudentsId
        const noShowStudents: number[] = []
        const studentData = this.$studentsForTableGroups.list
        if (studentData.length > 0) {
          const studentsIds: number[] = studentData.map(
            (student: StudentAppointmentsDataMinimal) => student.id
          )
          showingStudents.forEach((studentId: number) => {
            if (!studentsIds.includes(studentId)) {
              noShowStudents.push(studentId)
            }
          })
          this.noSearchStudentsId = noShowStudents
        } else {
          this.noSearchStudentsId = [...showingStudents]
        }
        this.isSearchStudentsId = false
      }
    },
  },
  methods: {
    setGroupName,
    setGroupComment,
    setSearchStudent,
    setSearchSelectedStudent,
    changeFilter: studentGroupsFilters.methods.changeFilter,
    resetFilters: studentGroupsFilters.methods.resetFilters,
    applyFilters: studentGroupsFilters.methods.applyFilters,
    removeParamsFilter: studentGroupsFilters.methods.removeParamsFilter,
    changePage: studentGroupsPageParams.methods.changePage,
    queryToParams: studentGroupsPageParams.methods.queryToParams,
    loadStudents,
    changeStudentsForGroup,
    setStudentIds,
    toggleStudent(student: StudentAppointmentsDataMinimal, value: boolean) {
      const students = { ...this.$studentsForGroup }
      if (value) {
        students[student.id] = student
      } else {
        delete students[student.id]
      }
      this.changeStudentsForGroup(students)
    },
    addAllStudent() {
      const students = { ...this.$studentsForGroup }
      this.$studentsForTableGroups.list.forEach((student: StudentAppointmentsDataMinimal) => {
        students[student.id] = student
      })
      changeStudentsForGroup(JSON.parse(JSON.stringify(students)))
    },
    selectedStudent(id: number) {
      if (!this.checkedIds.includes(id)) {
        this.checkedIds = [...this.checkedIds, id]
      } else {
        this.checkedIds = this.checkedIds.filter((studentId) => studentId !== id)
      }
    },
    selectedAll() {
      this.checkedIds = Object.keys(this.$studentsForGroup).map((id) => Number(id))
    },
    removeStudent(id: number) {
      const data = JSON.parse(JSON.stringify(this.$studentsForGroup))
      delete data[id]
      this.checkedIds = this.checkedIds.filter((studentId) => studentId !== id)
      changeStudentsForGroup(data)
    },
    removeStudentArr() {
      if (this.checkedIds.length > 0) {
        const data = JSON.parse(JSON.stringify(this.$studentsForGroup))
        this.checkedIds.forEach((id) => {
          if (data[id]) {
            delete data[id]
          }
        })
        this.checkedIds = []
        changeStudentsForGroup(data)
      }
    },
    searchStudents(value: string) {
      this.setSearchStudent(value)
      if (this.debounceId !== undefined) {
        clearTimeout(this.debounceId)
      }
      this.debounceId = setTimeout(() => {
        this.changeFilter({
          search: value,
        })
        this.onFilterSet()
      }, 600)
    },
    searchClassesStudents(item: string | null) {
      if (item) {
        this.changeFilter({
          study_year: Number(item),
        })
      } else {
        this.removeParamsFilter('study_year')
      }
      this.onFilterSet()
    },
    resetResults() {
      this.checkedIds = []
      this.setSearchSelectedStudent('')
    },
    onFilterSet() {
      this.applyFilters()
      this.onLoad()
    },
    onLoad() {
      if (Object.keys(this.$filterParams).length > 2) {
        this.isShowTable = true
        this.loadStudents()
      } else {
        this.isShowTable = false
      }
    },
    handleSearchFieldClick(item: DropdownItem, cb: any) {
      if (item) {
        this.title = item.title
      }
      searchFieldGroupChanged(item)
      cb()
    },
    resetUserResults() {
      this.removeParamsFilter('search')
      this.onFilterSet()
      this.setSearchStudent('')
    },
    studentIdsChanged(value: string) {
      this.setStudentIds(value)
      if (this.idsError) {
        this.idsError = false
      }
    },
    resetUserIdResults() {
      this.resetFilters()
      this.onFilterSet()
      this.setStudentIds('')
      this.noSearchStudentsId = []
    },
    sendSearchStudentsId() {
      if (this.noSearchStudentsId.length > 0) {
        this.noSearchStudentsId = []
        this.searchStudentsId = []
      }
      const ids: number[] = get_ids_for_string(this.$studentIds)
      this.isSearchStudentsId = true
      this.searchStudentsId = ids
      this.changeFilter({
        ids: ids.join(','),
      })
      this.onFilterSet()
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
