import { studentsDropdownModule } from '@/pages/results/tests-students/parts/filter/parts/students-tests-result/students-dropdown.model'
import { attemptDropdownModule } from '@/pages/results/tests-students/parts/filter/parts/tests-attempt/attempt-dropdown.model'
import { testsDropdownModule } from '@/pages/results/tests-students/parts/filter/parts/tests-result-by-student/tests-dropdown.model'
import { difficultyDropdownModule } from '@/pages/common/dropdowns/testing/difficulty-dropdown/difficulty-dropdown.model'

export const dropdownModules = {
  studentsDropdownModule,
  difficultyDropdownModule,
  testsDropdownModule,
  attemptDropdownModule,
}
