












import Vue from 'vue'
import ModalOverlay from '@/ui/modal/parts/ModalOverlay.vue'
import ModalBody from '@/ui/modal/parts/ModalBody.vue'

export default Vue.extend({
  name: 'Modal',
  components: {
    ModalOverlay,
    ModalBody,
  },
  props: {
    value: { type: Boolean },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
})
