import { TableField } from '@/pages/dictionary/themes/list/types'
import dayjs from 'dayjs'
import { DropdownItem } from '@/pages/common/types'

export const userGroupsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '70px',
  },
  {
    name: 'name',
    sortField: 'name',
    title: 'Название',
    width: '130px',
  },
  {
    name: 'students_amount',
    sortField: 'students_amount',
    title: 'Количество пользователей',
    width: '200px',
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создано',
    width: '100px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    width: '180px',
  },
  {
    name: 'updated_at',
    sortField: 'updated_at',
    title: 'Обновлено',
    width: '100px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : ''
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Посл. изменение',
    width: '180px',
  },
  {
    name: 'comment',
    sortField: 'comment',
    title: 'Комментарий',
    width: '120px',
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID' },
  { name: 'name', title: 'Название' },
]
