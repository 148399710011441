import { createEvent, createStore, forward, attach } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getFilterLessonsFx } from '@/features/api/learning/courses/get-filter-lessons-list'
import { defaultGroup } from '@/pages/learning/learning-courses/types'

export const lessonsDropdownModule = createFilter()

const getLessons = attach({
  effect: getFilterLessonsFx,
})

export const loadLessons = createEvent<void>()
export const $lessons = createStore<DropdownItem[]>([])

forward({
  from: loadLessons,
  to: getLessons.prepend(() => ({})),
})

forward({
  from: getLessons.doneData.map((res) => {
    return res.body.data.map((lesson) => ({
      id: lesson.id,
      name: `${lesson.id}`,
      title: lesson.name,
      groups: [defaultGroup()],
    }))
  }),
  to: $lessons,
})
