



















































import { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import Chip from '@/pages/dictionary/themes/list/parts/themes-tree/parts/Chip.vue'
import { TreeData, TreeElementType } from '@/features/api/types'
import { sortTreeLeaves } from '@/features/lib'
import {
  $openedTreeBranches,
  storeOpenedBranch,
} from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { FiltersParams, TreeNodeIdProp } from '@/pages/common/types'
import AutoOpenFolderMixin from '@/features/lib/mixins/AutoOpenFolderMixin'
import { DEFAULT_ID } from '@/pages/common/constants'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { isBranchOpened } from '@/pages/common'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'

export default AutoOpenFolderMixin({
  filters: {
    id: (item, search) => !!item.theme?.id.toString().includes(search),
    name: (item, search) => !!item.theme?.name.toLowerCase().includes(search.toLowerCase()),
    study_year: (item, search) =>
      !!item.study_year?.name.toLowerCase().includes(search.toLowerCase()),
    subject: (item, search) => !!item.subject?.name.toLowerCase().includes(search.toLowerCase()),
  },
}).extend({
  name: 'TreeNode',
  components: {
    ActionsButton,
    Icon,
    Chip,
  },
  props: {
    node: { type: Object as PropType<TreeData> },
    parent: { type: Boolean, default: false },
    prerequisiteFolder: { type: Boolean, default: false },
    isPrerequisite: { type: Boolean as PropType<boolean>, default: false },
    nodeId: { type: [Number, String] as PropType<TreeNodeIdProp> },
    filters: { type: Object as PropType<FiltersParams> },
    firstChild: { type: Boolean, default: false },
    lastChild: { type: Boolean, default: false },
    parentNodeId: { type: [Number, String] as PropType<TreeNodeIdProp>, default: null },
    parentNodeType: { type: String as PropType<TreeElementType>, default: null },
  },
  data: () => ({
    opened: false,
  }),
  computed: {
    iconType(): string {
      if (this.prerequisiteFolder) {
        return 'folder-prerequisite'
      }
      if (this.opened) {
        return 'tree-folder-opened'
      }
      return 'tree-folder'
    },
    title() {
      const type = this.node.element_type
      let fullName = ''
      if (type !== 'assignment' && type !== 'study_resource' && type !== 'media') {
        const entity = this.node[type]
        fullName = entity ? entity.name : ''
        if (fullName.length > 100) {
          fullName = `${fullName.slice(0, 100)}...`
        }
      }
      return fullName
    },
    resources() {
      return {
        tasks: {
          count: this.node.theme && this.node.theme.assignments_count,
          description: 'Количество заданий',
        },
        videos: {
          count: this.node.theme && this.node.theme.media_resource_count,
          description: 'Количество ресурсов типа "Видео"',
        },
        texts: {
          count: this.node.theme && this.node.theme.text_resource_count,
          description: 'Количество ресурсов типа "Текст"',
        },
        links: {
          count: this.node.theme && this.node.theme.link_resource_count,
          description: 'Количество ресурсов типа "Ссылка"',
        },
      }
    },
    nodeLeavesLength(): number {
      return this.node.leaves.length
    },
    showActions() {
      return this.node.element_type === 'theme' || this.node.element_type === 'study_year'
    },
  },
  watch: {
    opened: {
      handler(newVal) {
        if (newVal) {
          this.node.leaves = sortTreeLeaves(this.node.leaves)
          this.loadFolderData()
        }
        storeOpenedBranch({
          id: this.nodeId,
          opened: newVal as boolean,
          type: this.node.element_type,
          parentNodeId: this.parentNodeId,
          parentNodeType: this.parentNodeType,
          isPrerequisite: this.isPrerequisite,
        })
      },
    },
    nodeLeavesLength: {
      handler(newVal) {
        if (newVal && this.opened) this.node.leaves = sortTreeLeaves(this.node.leaves)
      },
    },
  },
  methods: {
    toggle(evt: any) {
      if (evt.target.closest('.action') || this.node.element_type === 'label') return
      this.opened = !this.opened
    },
    loadFolderData() {
      if (!this.node.leaves.length && this.node.element_type === 'study_year') {
        const { subject_id, id } = this.node[this.node.element_type]!
        this.$emit('loadTree', { subject: subject_id, study_year: id })
      } else if (!this.node.leaves.length && !this.parent && this.node.element_type === 'subject') {
        const { id } = this.node[this.node.element_type]!
        this.$emit('loadTree', { subject: id, is_prerequisite: true })
      }
    },
    getType(): ContextMenuType {
      if (this.node.element_type === 'theme') return 'item'
      return 'folder'
    },
    getSubject() {
      if (this.node.element_type === 'study_year') return this.node.study_year?.subject_id
      return this.node.subject ? this.node.subject.id : DEFAULT_ID
    },
    treeActionsButtonClick(event: MouseEvent) {
      const type = this.getType()
      this.$emit('onActionsClick', {
        data: {
          id: this.node.element_type === 'theme' ? this.nodeId : undefined,
          subject: this.getSubject(),
          studyYear: this.node.study_year ? this.node.study_year.id : DEFAULT_ID,
        },
        event,
        type,
      })
    },
    handleRightClick(event: any) {
      this.$emit('onRightClick', {
        data: {
          id: this.node.element_type === 'theme' ? this.nodeId : undefined,
          subject: this.getSubject(),
          studyYear: this.node.study_year ? this.node.study_year.id : DEFAULT_ID,
        },
        event,
        type: this.getType(),
      })
    },
  },
  mounted() {
    if (this.node.element_type === 'theme' || this.node.element_type === 'study_year') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}`)
      nodeElement && nodeElement.addEventListener('contextmenu', this.handleRightClick)
    }
    this.opened = isBranchOpened(
      $openedTreeBranches,
      this.nodeId,
      this.node.element_type,
      this.parentNodeId,
      this.parentNodeType,
      this.isPrerequisite
    )
    if (this.filters.search) {
      this.searchString = this.filters.search_area
        ? this.filters.search_area.slice(this.filters.search_area?.indexOf('_') + 1)
        : ''
      this.autoOpenFolders([this.node])
    }
  },
  beforeDestroy() {
    if (this.node.element_type === 'theme' || this.node.element_type === 'study_year') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}`)
      nodeElement && nodeElement.removeEventListener('contextmenu', this.handleRightClick)
    }
  },
})
