























import Vue from 'vue'
import {
  setLessonStatus,
  $selectedLessonStatus,
  $studentsCount,
} from '@/pages/results/courses-students/parts/filter/parts/lesson-status-dropdown/lesson-status-dropdown.model'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import { DropdownItem } from '@/pages/common/types'
import { mapLessonStatus } from '@/pages/results/courses-students/constants'

export default Vue.extend({
  components: {
    BaseDropdown,
    SelectItem,
  },
  computed: {
    mapLessonStatus() {
      if (this.$studentsCount.length > 0) return this.$studentsCount
      return mapLessonStatus
    },
  },
  effector: {
    $selectedLessonStatus,
    $studentsCount,
  },
  methods: {
    setLessonStatus,
    handleClick(item: DropdownItem, cb: () => void) {
      this.setLessonStatus(item)
      this.$emit('setItem', item.name)
      cb()
    },
    resetDropdown() {
      this.$emit('setItem', null)
    },
  },
})
