import { attach, createEvent, forward, restore, sample } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { getEmployeesListFx } from '@/features/api/employee/get-employee-list'
import { getEmployeeName } from '@/pages/common'
import { EmployeeList } from '@/features/api/employee/types'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { GetListQueryParams } from '@/features/api/types'
import { debounce } from 'patronum'

const getModerators = attach({
  effect: getEmployeesListFx,
})

export const moderatorsDropdownModel = createDropdownModel<EmployeeList>(getModerators)

export const setSelectedModerator = createEvent<DropdownItem | null>()
export const $selectedModerator = restore(setSelectedModerator, null)

export const loadModerators = createEvent<void>()

export const searchModerators = createEvent<void>()

sample({
  clock: loadModerators,
  source: {
    $nextPage: moderatorsDropdownModel.store.$nextPage,
    search: moderatorsDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search }),
  }),
  target: getModerators,
})

sample({
  clock: searchModerators,
  source: {
    search: moderatorsDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search && { search: params.search }),
  }),
  target: getModerators,
})

const debounced = debounce({
  source: moderatorsDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchModerators,
})

forward({
  from: moderatorsDropdownModel.methods.canLoadNextPage,
  to: loadModerators,
})

sample({
  clock: getModerators.doneData,
  source: {
    items: moderatorsDropdownModel.store.$items,
    $nextPage: moderatorsDropdownModel.store.$nextPage,
    search: moderatorsDropdownModel.store.$searchString,
  },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((user) => ({
      name: `${user.id}`,
      title: getEmployeeName(user),
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: moderatorsDropdownModel.store.$items,
})

forward({
  from: moderatorsDropdownModel.methods.resetDropdown,
  to: setSelectedModerator.prepend(() => null),
})
