<template>
  <div
    class="footer-block"
  >
    <div class="col">
      <div class="row">
        <span class="text">Количество набранных учеником баллов:</span>
        <span class="text value">{{student.total_student_score}}</span>
      </div>
      <div class="row">
        <span class="text">Количество возможных баллов:</span>
        <span class="text value">{{student.total_score}}</span>
      </div>
      <div class="row">
        <LessonStatusDropdown
          class="lesson"
          :disabled="student.total_score === 0"
          :value="status"
          @input="val => $emit('onEditStatus', val)"
        />
      </div>
    </div>
    <div class="col">
      <div class="row">
        <span class="status" :class="getStatus" />
        <span class="text verifier">Проверяющий:</span>
        <span class="text name">{{moderated}}</span>
        <span v-if="dateTime && moderated" class="text date">({{ dateTime }})</span>
      </div>
      <div class="row editor-wrap">
        <Wysiwyg
          editor-id="wysiwyg-text"
          :value="result"
          class="commentsEditor"
          :disabled="student.total_score === 0"
          placeholder="Введите текст"
          @input="val => $emit('onEditResult', val)"
        />
      </div>
      <div class="row buttons">
        <BaseButton
          class="btn"
          yellow
          @click="$emit('saveEditInfo')"
        >
          Сохранить оценки и комментарии
        </BaseButton>
        <div class="statuses">
          <span class="status-item">
            <span class="status green" />
            <span>- проверен</span>
          </span>
          <span class="status-item">
            <span class="status red" />
            - не проверен
          </span>
          <span class="status-item">
            <span class="status silver" />
            - отложен
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import LessonStatusDropdown from '@/pages/results/courses-students/parts/FooterBlock/lesson-status-dropdown-footer/LessonStatusDropdown.vue'
import dayjs from 'dayjs'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'FooterBlock',
  components: {
    LessonStatusDropdown,
    Wysiwyg,
    BaseButton,
  },
  props: {
    result: {
      type: String,
    },
    status: {
      type: String,
    },
    student: {
      type: Object,
    },
  },
  computed: {
    getStatus() {
      switch (this.student?.status) {
        case 'verified':
          return 'green'
        case 'not_verified':
          return 'red'
        default:
          return 'silver'
      }
    },
    dateTime() {
      return this.student?.moderated_at
        ? dayjs(this.student.moderated_at).format('DD.MM.YYYY HH:mm')
        : ''
    },
    moderated() {
      if (!this.student.moderated_by) return ''
      return `${this.student.moderated_by.first_name} ${
        this.student.moderated_by?.last_name ? `${this.student.moderated_by.last_name[0]}.` : ''
      }`
    },
  },
})
</script>

<style scoped>
.footer-block {
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-top: 20px;
}
.col {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(40% - 20px);
  max-width: 422px;
  &:last-child {
    margin-left: 20px;
    width: 60%;
    max-width: 60%;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.value {
  font-weight: 600;
  margin-left: 5px;
}
.name {
  margin-left: 5px;
}
.date {
  margin-left: 25px;
  opacity: 0.6;
}
.verifier {
  font-weight: 600;
}
.lesson {
  width: 100%;
}
.status {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.green {
  background-color: var(--c-green-0);
}
.red {
  background-color: var(--c-red-2);
}
.silver {
  background-color: #6b768b;
}
.text {
  line-height: 16px;
}
.editor-wrap {
  padding-left: 20px;
}
.commentsEditor {
  width: 100%;
}
::v-deep .commentsEditor {
  .cke_contents {
    height: 76px !important;
  }
  .cke_wysiwyg_div {
    padding-top: 5px;
  }
  .cke_toolbar {
    display: flex;
    flex-wrap: wrap;
  }
}
.buttons {
  padding-left: 20px;
}
.btn {
  width: 268px;
  flex-shrink: 0;
}
.statuses {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  .status {
    display: inline-block;
  }
}
.status-item:not(:last-child) {
  margin-right: 10px;
}
</style>

