












import Vue from 'vue'
import { DropdownItem } from '@/pages/common/types'
import { percentVcDropdownModel } from '@/pages/common/dropdowns/value-change-dropdowns/percent-dropdown/percent-vc-dropdown.model'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'

export default Vue.extend({
  name: 'PercentDropdown',
  components: {
    FilterDropdown,
  },
  effector: {
    ...percentVcDropdownModel.store,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Выберите',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      searchString: '',
    }
  },
  methods: {
    ...percentVcDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('onChange', item)
    },
    searchStChanged(val: string) {
      this.searchString = val
      this.searchStringChanged(val)
    },
    resetSearchStr() {
      this.searchString = ''
      this.resetSearchString()
    },
  },
})
