





























































import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import Modal from '@/ui/modal/Modal.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BasePagination from '@/ui/pagination/BasePagination.vue'
import SmallLoader from '@/pages/common/parts/internal-loader-blocks/SmallLoader.vue'
import {
  $storageVisibility,
  storageVisibilityChanged,
  $images,
  searchStringChanged,
  $selectedPage,
  $mediaSearchString,
  $count,
  selectedPageChanged,
  clearSearchString,
  $loading,
} from './images-storage.model'
import { UploadMediaResponse } from '@/features/api/media/types'

export default Vue.extend({
  name: 'ImagesStorage',
  components: {
    Icon,
    Modal,
    FormInput,
    BasePagination,
    SmallLoader,
  },
  effector: {
    $storageVisibility,
    $images,
    $selectedPage,
    $count,
    $mediaSearchString,
    $loading,
  },
  methods: {
    clearSearchString,
    storageVisibilityChanged,
    searchStringChanged,
    selectedPageChanged,
    onImageSelected(img: UploadMediaResponse) {
      storageVisibilityChanged(false)
      this.$emit('onImageSelected', img)
    },
  },
})
