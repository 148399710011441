import { TableField } from '@/pages/dictionary/themes/list/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'
import { $currentUser } from '@/features/session'

export const subjectsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '70px',
  },
  {
    name: 'name',
    sortField: 'name',
    title: 'Название предмета',
    width: '200px',
    formatter(data: string) {
      return data || '–'
    },
  },
  {
    name: 'is_mandatory',
    sortField: 'is_mandatory',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Обязательный предмет',
    width: '200px',
    formatter(value: boolean) {
      return value ? 'Да' : 'Нет'
    },
  },
  {
    name: 'color',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Цвет предмета',
    formatter(value: string) {
      return value ? `<div class="round-color" style="background-color: ${value}"/>` : ''
    },
    width: '200px',
  },
  {
    name: 'icon',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Иконка предмета',
    formatter(data: any) {
      return data ? `<div style="background-image: url(${data.file})" class="image-subject"/>` : ''
    },
    width: '200px',
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'right aligned',
    width: '125px',
  },
]

export const searchFieldsData = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID Предмета' },
  { name: 'name', title: 'Название предмета' },
  { name: 'required_subject', title: 'Обязательный предмет' },
]

export const getSubjectsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const user = $currentUser.getState()
  const isDefaultShow = user?.role !== 'METHODIST'

  const commonDisplayed =
    displayOptions.isTableItem ||
    displayOptions.isTableItems ||
    displayOptions.isHeaderItem ||
    displayOptions.isHeaderItems
  const actions = [
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.subjects_subject_subjects.can_edit,
    },
    {
      name: 'doMandatory',
      title: 'Сделать обязательным',
      action: 'onDoMandatory',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.subjects_subject_subjects.can_edit,
    },
    {
      name: 'doOptional',
      title: 'Сделать необязательным',
      action: 'onDoOptional',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.subjects_subject_subjects.can_edit,
    },
    {
      name: 'delete',
      title: 'Удалить',
      action: 'onDelete',
      isDisplayed: commonDisplayed,
      hasPermissionToSee: isDefaultShow,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
