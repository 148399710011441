import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteBannersParams } from '@/features/api/content/types'

export const deleteBannersFx = createApiEffect<number[], void>({
  requestMapper: (banners) => ({
    method: 'PATCH',
    url: '/api/content-app/banner/list/bulk-delete/',
    body: { banners },
  }),
})

export const requestDeleteBannersFx = createApiEffect<
  RequestDeleteBannersParams,
  RequestDeleteBannersParams
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/content-app/banner/list/bulk-delete/',
    body,
  }),
})
