import { render, staticRenderFns } from "./Chip.vue?vue&type=template&id=c5a8e84c&scoped=true&"
import script from "./Chip.vue?vue&type=script&lang=ts&"
export * from "./Chip.vue?vue&type=script&lang=ts&"
import style0 from "./Chip.vue?vue&type=style&index=0&id=c5a8e84c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a8e84c",
  null
  
)

export default component.exports