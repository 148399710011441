import { attach, createEvent, forward } from 'effector-root'
import { lessonsNameListFx } from '@/features/api/results/lessons-name-list'
import { createFiltersModel } from '@/pages/common/filters/create-filters-multiselect-model'

const getLessons = attach({
  effect: lessonsNameListFx,
})

export const loadLessons = createEvent<void>()
export const lessonsDropdownModel = createFiltersModel()

forward({
  from: loadLessons,
  to: getLessons.prepend(() => ({})),
})

forward({
  from: getLessons.doneData.map((res) =>
    res.body.map((field) => ({ name: `${field.id}`, title: `(${field.id})${field.name}` }))
  ),
  to: lessonsDropdownModel.methods.setItems,
})
