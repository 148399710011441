






















import Vue from 'vue'
import {
  setSelectedCourseTaskCheckType,
  $selectedCourseTaskCheckType,
  mapCourseTaskCheckType,
  mapCourseTaskCheckTypeFilter,
} from '@/pages/learning/learning-courses/parts/inputs/course-task-check-type/coursesTaskCheckType.model'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    BaseDropdown,
    SelectItem,
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    courseTaskCheckType() {
      return this.isFilter ? mapCourseTaskCheckTypeFilter : mapCourseTaskCheckType
    },
  },
  effector: {
    $selectedCourseTaskCheckType,
  },
  methods: {
    setSelectedCourseTaskCheckType,
    onSelectItem(item: DropdownItem, cb: any) {
      this.$emit('item-changed', item)
      cb()
    },
    handleClick(item: any, cb: () => void) {
      this.setSelectedCourseTaskCheckType(item)
      cb()
    },
  },
})
