


























import Vue from 'vue'
import LearningFooter from '@/pages/learning/learning-courses/list/common/Footer.vue'
import LearningHeader from '@/pages/learning/learning-courses/list/common/Header.vue'
import LeftBlock from '@/pages/learning/learning-courses/edit/common/LeftBlock.vue'
import RightBlock from '@/pages/learning/learning-courses/edit/common/RightBlock.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'

import {
  $canSaveCourse,
  save,
  changeIfRedirect,
  clearFieldsCourseEdit,
  loadCourse,
  $isLoadingGetCourse,
} from '@/pages/learning/learning-courses/edit/courses-edit-page.model'

export default Vue.extend({
  name: 'CoursesEditPage',
  components: {
    LearningHeader,
    LearningFooter,
    LeftBlock,
    RightBlock,
    LoaderBig,
  },
  effector: {
    $canSaveCourse,
    $isLoadingGetCourse,
  },
  methods: {
    updateCourse(isRedirect: boolean) {
      changeIfRedirect(isRedirect)
      save()
    },
  },
  beforeDestroy() {
    clearFieldsCourseEdit()
  },
  created() {
    loadCourse(+this.$route.params.id)
  },
})
