













import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { modalVisibilityChanged } from '@/pages/common/modals/system-files/create-folder/create-folder.model'

export default Vue.extend({
  components: {
    BaseButton,
  },
  methods: {
    modalVisibilityChanged,
  },
})
