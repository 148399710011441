






















import Vue from 'vue'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import { DropdownItem } from '@/pages/common/types'
import { mapLessonStatus } from '@/pages/results/courses-students/constants'

export default Vue.extend({
  components: {
    BaseDropdown,
    SelectItem,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    mapLessonStatus() {
      return mapLessonStatus
    },
    correctValue() {
      if (!this.value) return ''
      return mapLessonStatus.find((value) => value.name === this.value)?.title || ''
    },
  },
  methods: {
    handleClick(item: DropdownItem, cb: () => void) {
      this.$emit('input', item.name)
      cb()
    },
  },
})
