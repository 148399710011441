




























































import Vue from 'vue'
import StartToEndDatetime from '@/pages/learning/learning-courses/parts/inputs/datetime-picker/StartToEndDatetime.vue'
import {
  $groups,
  loadGroups,
  groupDropdownModule,
} from '@/pages/learning/learning-courses/parts/inputs/groups-couses-block/courses-groups-block.model'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import Icon from '@/ui/icon/Icon.vue'
import { GroupCourseType } from '@/pages/learning/learning-courses/types'
import { DatetimeLessonsGroup } from '@/features/api/learning/types'

export default Vue.extend({
  name: 'CoursesGroupsBlock',
  components: {
    StartToEndDatetime,
    FilterDropdown,
    Icon,
  },
  props: {
    group: {
      type: Object,
    },
    idLabel: {
      type: Number,
    },
  },
  effector: {
    $groups,
    ...groupDropdownModule.store,
  },
  computed: {
    correctValue(): string {
      const currentItem = this.$item?.title
      return currentItem || this.$searchString
    },
  },
  methods: {
    loadGroups,
    ...groupDropdownModule.methods,
    onSetDatetime(datetimes: DatetimeLessonsGroup) {
      this.$emit(
        'changeGroup',
        {
          ...this.group,
          accept_dt_from: datetimes.start,
          accept_dt_to: datetimes.end,
        },
        this.group.blockId
      )
    },
    setSelectedGroup(group: GroupCourseType | null) {
      if (group) {
        const { id, title, name } = group
        this.$emit('changeGroup', { ...this.group, id, title, name }, this.group.blockId)
      } else {
        this.$emit('changeGroup', { ...this.group, id: 0, title: '', name: '' }, this.group.blockId)
      }
    },
    clear() {
      this.resetItem()
      this.resetSearchString()
    },
    addGroup() {
      this.$emit('addGroup')
    },
    removeGroup() {
      this.$emit('removeGroup', { blockId: this.group.blockId })
    },
  },
  mounted() {
    if (this.$groups.length === 0) {
      loadGroups()
    }
  },
})
