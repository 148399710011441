














import Vue from 'vue'
import {
  $courseCourseTaskCheckType,
  courseCourseTaskCheckTypeDropdownModule,
} from '@/pages/learning/learning-courses/list/parts/filter/parts/course-task-check-type-filter/coursesTaskCheckType.model'
import { DropdownItem } from '@/pages/common/types'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false,
    },
  },
  effector: {
    $courseCourseTaskCheckType,
    ...courseCourseTaskCheckTypeDropdownModule.store,
  },
  methods: {
    ...courseCourseTaskCheckTypeDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      if (!item || item.name === 'Показать все') {
        this.$emit('setItem', null)
      } else {
        this.$emit('setItem', item.name === 'Автоматизированный')
      }
    },
  },
  mounted() {
    if (!this.$item) {
      courseCourseTaskCheckTypeDropdownModule.methods.itemChanged({
        name: 'Показать все',
        title: 'Показать все',
      })
    }
  },
})
