



































































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BasePagination from '@/ui/pagination/BasePagination.vue'
import SmallLoader from '@/pages/common/parts/internal-loader-blocks/SmallLoader.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  searchStringChanged,
  $selectedPage,
  $moderatorSearchString,
  $count,
  selectedPageChanged,
  $moderators,
  submit,
  clearSearchString,
  moderatorsDropdownModel,
} from '@/pages/applications/modals/set-to-moderator/set-to-moderator.model'

export default Vue.extend({
  name: 'ModeratorModal',
  components: {
    Modal,
    Icon,
    FormInput,
    BasePagination,
    SmallLoader,
  },
  effector: {
    $modalVisibility,
    $selectedPage,
    $count,
    $moderators,
    $moderatorSearchString,
    ...moderatorsDropdownModel.store,
  },
  methods: {
    submit,
    clearSearchString,
    modalVisibilityChanged,
    searchStringChanged,
    selectedPageChanged,
  },
})
