import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { StudyYear, Subject } from '@/features/api/subject/types'
import { Employee, EmployeePermissions } from '@/features/api/employee/types'
import { tasksChoiceMapData } from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/constant'
import { ActionsDisplayConditions } from '@/pages/common'

export const fullTimeExamTestTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID теста',
    // width: '85px',
  },
  {
    name: 'folder',
    sortField: 'folder',
    title: 'Папка',
    titleClass: 'wrap',
    dataClass: 'wrap',
    // width: '180px',
  },
  {
    name: 'name',
    sortField: 'name',
    title: 'Название экзамена для ученика',
    dataClass: 'wrap',
    titleClass: 'wrap',
    // width: '170px',
  },
  {
    name: 'internal_name',
    sortField: 'internal_name',
    title: 'Внутреннее название теста в CMS',
    titleClass: 'wrap',
    dataClass: 'wrap',
    // width: '180px',
  },
  {
    name: 'subject',
    sortField: 'subject',
    title: 'Предмет',
    // width: '140px',
    formatter(subject: Subject) {
      return subject && subject.name ? subject.name : '-'
    },
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    title: 'Класс',
    // width: '100px',
    formatter(year: StudyYear) {
      return year ? year.name : '-'
    },
  },
  {
    name: 'is_active',
    sortField: 'is_active',
    title: 'Доступен для прохождения',
    titleClass: 'wrap',
    // width: '140px',
    formatter(isActive: boolean) {
      return isActive ? 'Да' : 'Нет'
    },
  },
  {
    name: 'intro_dur_min',
    sortField: 'intro_dur_min',
    title: 'Продолжительность вводной части экзамена (мин)',
    titleClass: 'wrap',
    // width: '180px',
    formatter(duration_min: number) {
      return duration_min ? `${duration_min} мин` : ''
    },
  },
  {
    name: 'main_dur_min',
    sortField: 'main_dur_min',
    title: 'Продолжительность основной части экзамена (мин)',
    titleClass: 'wrap',
    // width: '180px',
    formatter(duration_min: number) {
      return duration_min ? `${duration_min} мин` : ''
    },
  },
  {
    name: 'load_dur_min',
    sortField: 'load_dur_min',
    title: 'Продолжительность загрузки файлов (мин)',
    titleClass: 'wrap',
    // width: '180px',
    formatter(duration_min: number) {
      return duration_min ? `${duration_min} мин` : ''
    },
  },
  {
    name: 'tasks_choice',
    sortField: 'tasks_choice',
    title: 'Переход между блоками в тесте',
    titleClass: 'wrap',
    // width: '160px',
    formatter(tasks_choice: number) {
      return tasksChoiceMapData[String(tasks_choice)]?.title || ''
    },
  },
  {
    name: 'main_units_amount',
    sortField: 'main_units_amount',
    title: 'Количество блоков в основной части экзамена',
    titleClass: 'wrap',
    // width: '150px',
  },
  {
    name: 'tasks_amount',
    sortField: 'tasks_amount',
    title: 'Количество заданий в экзамене',
    titleClass: 'wrap',
    // width: '150px',
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    // width: '110px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    titleClass: 'wrap',
    // width: '220px',
    formatter(user: Employee | null) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Обновлено',
    // width: '110px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : ''
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Посл. изменение',
    titleClass: 'wrap',
    // width: '140px',
    formatter(user: Employee | null) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'cms_commentary',
    sortField: 'cms_commentary',
    title: 'Заметка CMS',
    titleClass: 'wrap',
    // width: '140px',
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID теста' },
  { name: 'name', title: 'Название экзамена для ученика' },
  { name: 'subject', title: 'Предмет' },
  { name: 'study_year', title: 'Класс' },
  { name: 'folder', title: 'Папка' },
]

export const getExamTestsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'onEdit',
      title: 'Редактирование',
      headerTitle: 'Редактировать',
      action: 'onEdit',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isTreeItem ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.exams_examtest_exams.can_edit,
    },
    {
      name: 'onRemove',
      title: displayOptions.isTableItems ? 'Удалить выделенные' : 'Удалить экзамен',
      headerTitle: 'Удалить',
      action: 'onRemove',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isTreeItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.exams_examtest_exams.can_edit,
    },
    {
      name: 'createFolder',
      title: 'Создать папку',
      action: 'onCreateFolder',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: permissions.assignments_examassignment_assignments.can_edit,
    },
    {
      name: 'editFolder',
      title: 'Редактировать',
      action: 'onEditFolder',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: permissions.assignments_examassignment_assignments.can_edit,
    },
    {
      name: 'deleteFolder',
      title: 'Удалить',
      action: 'onDeleteFolder',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: permissions.assignments_examassignment_assignments.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
