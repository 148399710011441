import { UserRelated } from '@/features/api/user/types'
import { UploadMediaResponse } from '@/features/api/media/types'

export type NewsList = {
  id: number
  publish_date: Date | null
  title: string
  tags_ids: string[]
  image: UploadMediaResponse
  slug_field: string
  seo_title: string
  seo_description: string
  created_at: string
  updated_at: string
  updated_by: UserRelated
  image_id: number
}

export type GetNewsList = {
  id: number
  publish_date: Date | null
  title: string
  tags: { id: string; name: string }[]
  image: UploadMediaResponse
  slug_field: string
  seo_title: string
  seo_description: string
  created_at: string
  updated_at: string
  updated_by: UserRelated
  image_id: number
}

export type GetNewsByIdType = Omit<GetNewsList, 'publish_date'> & {
  publish_date: string
}

export type RequestDeleteNews = {
  news: number[]
  ticket_comment?: string
}

export type slideMediaType = {
  id: number
  owner: number
  file: string
  file_type: string
  duration_sec: number
  size: number
  file_name: string
}
export type Slide = {
  id: number
  media: slideMediaType
  active: boolean
  show_buttons: boolean
  show_title: boolean
  show_text: boolean
  show_media: boolean
  name: string
  title: string
  text: string
  created_at: string
  updated_at: string
  media_id: number | null
}

export type Banner = {
  id: number
  banner_type: string
  name: string
  text: string
  button_title: string
  button_url: string
  active: boolean
  timer_target: Date | null
  created_at: string
  updated_at: string
}

export type OnboardingCustomizationType = {
  frontend_code: string
  data: {
    show_onboarding: boolean
  }
}

export type RequestDeleteSlidesParams = {
  sliders: number[]
  ticket_comment?: string
}

export enum EditNewsPageFields {
  publish_date = 'publish_date',
  title = 'title',
  tags_ids = 'tags_ids',
  image = 'image',
  image_id = 'image_id',
  text = 'text',
  slug_field = 'slug_field',
  seo_title = 'seo_title',
  seo_description = 'seo_description',
  is_showed = 'is_showed',
}

export type ChangeNewsFormFieldType = {
  type: EditNewsPageFields
  value: string
}

export type RequestDeleteBannersParams = {
  banners: number[]
  ticket_comment?: string
}

export type FAQType = {
  id: number
  question: string
  answer: string
  created_at: string
  updated_at: string
}

export type RequestDeleteQuestions = {
  faqs: number[]
  ticket_comment?: string
}
