import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExamAssignment } from '@/features/api/assignment/types/exam-assignments-types'
import { CreateExamAssignmentFxParams } from '@/features/api/assignment/types/types'

export const editExamAssignmentFx = createApiEffect<
  { body: CreateExamAssignmentFxParams; id: number },
  ExamAssignment
>({
  requestMapper: ({ body, id }) => ({
    method: 'PUT',
    url: `/api/assignment-app/exam-assignment/${id}/`,
    body,
  }),
})
