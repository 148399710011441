























import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import FormInput from '@/ui/input/FormInput.vue'
import {
  examDurationDropdownModel,
  $durationMin,
  setDuration,
} from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/exam-duration-dropdown/exam-duration-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  name: 'ExamDurationDropdown',
  components: {
    FilterDropdown,
    FormInput,
  },
  effector: {
    ...examDurationDropdownModel.store,
    $durationMin,
  },
  computed: {
    showDurationField() {
      return this.$item !== null && this.$item.name === 'overall'
    },
  },
  methods: {
    ...examDurationDropdownModel.methods,
    setDuration,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
})
