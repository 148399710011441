import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteInstructions } from '@/pages/dictionary/instructions/list/model/types'

export const requestDeleteInstructionsFx = createApiEffect<RequestDeleteInstructions, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/assignment-app/olympiad-instructions/bulk-delete/',
    body,
  }),
})
