













import Vue, { PropType } from 'vue'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { $permissions } from '@/features/session'
import { taskLoader } from '@/pages/exams/full-time-answers/list/model/exams-full-answers-filters.model'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'

export default Vue.extend({
  name: 'ExamsAnswersHeader',
  components: {
    DownloadButton,
    GridPageHead,
    HeaderPopup,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
  },
  effector: {
    permissions: $permissions,
    isLoadingExport: taskLoader.store.$exportTaskLoading,
  },
})
