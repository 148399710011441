import { attach, combine, createEvent, forward, guard, restore, sample } from 'effector-root'
import { CourseRelated } from '@/features/api/learning/types'
import { getCoursesSortFx, setCoursesSortFx } from '@/features/api/learning/courses/courses-sort'
import { errorToastEvent } from '@/features/toasts/toasts.model'

const getCoursesList = attach({
  effect: getCoursesSortFx,
})

export const $isLoadingCoursesList = combine({
  isLoadingList: getCoursesSortFx.pending,
  isDisabledButtons: getCoursesSortFx.pending || setCoursesSortFx.pending,
})

export const loadCoursesList = createEvent<void>()
export const saveCoursesList = createEvent<void>()
export const notUniqueNumber = createEvent<void>()
export const notValidNumber = createEvent<void>()
export const changeCoursesSortModalVisibility = createEvent<boolean>()
export const closeCoursesSortModalVisibility = createEvent<boolean>()
export const $coursesSortModalVisibility = restore(changeCoursesSortModalVisibility, false).reset(
  closeCoursesSortModalVisibility
)

export const setCoursesSortList = createEvent<CourseRelated[]>()
export const resetCoursesSortList = createEvent<void>()
export const $coursesSortList = restore(setCoursesSortList, []).reset(resetCoursesSortList)

forward({
  from: loadCoursesList,
  to: getCoursesList.prepend(() => ({})),
})

forward({
  from: setCoursesSortFx.done,
  to: closeCoursesSortModalVisibility.prepend(() => false),
})

const sortList = (a: CourseRelated, b: CourseRelated) => {
  const formatA = a.number ? Number(a.number) : 0
  const formatB = b.number ? Number(b.number) : 0
  return formatA - formatB
}

sample({
  source: $coursesSortList,
  clock: saveCoursesList,
  fn: (list) => ({ courses: list.sort(sortList).map((cs) => cs.id) }),
  target: setCoursesSortFx,
})

forward({
  from: getCoursesList.doneData.map((res) => {
    return res.body.map((cs, index) => ({ ...cs, number: cs.number ? cs.number : index + 1 }))
  }),
  to: $coursesSortList,
})

guard({
  clock: changeCoursesSortModalVisibility,
  source: $coursesSortModalVisibility,
  filter: (show) => show,
  target: loadCoursesList,
})

guard({
  clock: changeCoursesSortModalVisibility,
  source: $coursesSortModalVisibility,
  filter: (show) => !show,
  target: resetCoursesSortList,
})

forward({
  from: notUniqueNumber,
  to: errorToastEvent('Введены не уникальные номера курсов!'),
})

forward({
  from: notValidNumber,
  to: errorToastEvent('Введите номер всем курсам!'),
})
