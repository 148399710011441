

















import Vue from 'vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import DifficultyDropdown from '@/pages/common/dropdowns/testing/difficulty-dropdown/DifficultyDropdown.vue'
import ThemesDropdown from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/ThemesDropdown.vue'

import {
  togglePrerequisites,
  $prerequisites,
  changeDifficulty,
  $difficulty,
} from '@/pages/common/parts/tests/automatic-generation/automatic-generation-form.model'

import {
  $subject,
  $studyYear,
  $filterByStudyYear,
} from '@/pages/testing/tests/create/test-create-page.model'

import {
  $subject as subjectEdit,
  $studyYear as studyYearEdit,
  $filterByStudyYear as filterByStudyYearEdit,
} from '@/pages/testing/tests/edit/test-edit-page.model'

export default Vue.extend({
  name: 'AutomaticGenerationForm',
  components: {
    BaseSwitch,
    DifficultyDropdown,
    ThemesDropdown,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  effector: {
    $prerequisites,
    $difficulty,
    subjectEdit,
    $subject,
    studyYearEdit,
    $studyYear,
    filterByStudyYearEdit,
    $filterByStudyYear,
  },
  methods: {
    changeDifficulty,
    togglePrerequisites,
    themesDropdownDisabled() {
      if (this.isEdit) {
        return (
          (this.subjectEdit && this.studyYearEdit) ||
          (this.subjectEdit && !this.filterByStudyYearEdit)
        )
      }
      return (this.$subject && this.$studyYear) || (this.$subject && !this.$filterByStudyYear)
    },
  },
})
