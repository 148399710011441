




































import Vue, { PropType } from 'vue'
import { TestResultExtraFilterElement } from '@/pages/common/modals/result-tests-filter/type'
import SubjectsVcDropdown from '@/pages/common/dropdowns/value-change-dropdowns/subject-vc/SubjectsVcDropdown.vue'
import TestTextVcDropdown from '@/pages/common/dropdowns/value-change-dropdowns/tests-text-vc/TestTextVcDropdown.vue'
import ResultValueDropdown from '@/pages/common/dropdowns/value-change-dropdowns/result-value-dropdown/ResultValueDropdown.vue'
import SignVcDropdown from '@/pages/common/dropdowns/value-change-dropdowns/sign-vc/SignVcDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'ResultTestsFilterBlock',
  components: {
    SubjectsVcDropdown,
    SignVcDropdown,
    TestTextVcDropdown,
    ResultValueDropdown,
    Icon,
  },
  props: {
    block: { type: Object as PropType<TestResultExtraFilterElement> },
    index: { type: Number },
  },
  methods: {
    handleChangeBlock(data: DropdownItem) {
      this.$emit('changeBlock', { ...this.block, ...data })
    },
    removeBlock() {
      this.$emit('removeBlock', this.index)
    },
  },
})
