import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import { condition } from 'patronum'
import { createError } from '@/lib/effector/error-generator'
import { DEFAULT_ID } from '@/pages/common/constants'
import { createTagFx } from '@/features/api/assignment/olympiad-tags/create-tag'
import { getTagsListFx } from '@/features/api/assignment/olympiad-tags/get-tags-list'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import {
  $selectedClass,
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  $selectedSubject,
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { canRefreshTableChanged } from '@/pages/tags/parts/modals/tag-edition/tag-edition.modal'
import { getTagsTreeLight } from '@/pages/tags/tags-page.model'
import { getUserStudyYearFx } from '@/features/api/study_year/get-user-study-year'
import { getSubjectFx } from '@/features/api/subject/get-subject'

export const createTag = attach({
  effect: createTagFx,
})

const getTagsList = attach({
  effect: getTagsListFx,
})

export const canRefreshAfterCreationChange = createEvent<boolean>()
export const $canRefreshTableAfterCreation = restore<boolean>(canRefreshAfterCreationChange, false)

export const createTagFromTree = createEvent<{ class_id: number; subject_id: number }>()

export const checkIfThemeCanBeSend = createEvent<void>()
export const clearFields = createEvent<void>()

export const modalVisibilityChanged = createEvent<boolean>()
export const $modalVisibility = restore(modalVisibilityChanged, false)

export const tagTitleChanged = createEvent<string>()
const tagTitleReset = createEvent<void>()
export const $tagTitle = restore(tagTitleChanged, '').reset(tagTitleReset)

export const $subjectErrorModule = createError()

export const $classErrorModule = createError()

export const $titleErrorModule = createError()

const $form = combine({
  name: $tagTitle,
  study_year_id: $selectedClass.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  subject_id: $selectedSubject.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
})

sample({
  source: $form,
  clock: checkIfThemeCanBeSend,
  fn: (obj) => {
    if (
      obj.name.trim().length &&
      obj.study_year_id !== DEFAULT_ID &&
      obj.subject_id !== DEFAULT_ID
    ) {
      createTag(obj)
    } else {
      if (!obj.name.trim().length) $titleErrorModule.methods.setError(true)
      if (obj.study_year_id === DEFAULT_ID) $classErrorModule.methods.setError(true)
      if (obj.subject_id === DEFAULT_ID) $subjectErrorModule.methods.setError(true)
      errorToastEvent('Необходимо заполнить все обязательные поля')
    }
  },
})

sample({
  clock: createTagFromTree,
  fn: (tag: { class_id: number; subject_id: number }) => {
    getUserStudyYearFx(tag.class_id).then(({ body }) =>
      classesDropdownModule.methods.itemChanged({ name: `${body.id}`, title: body.name })
    )
    setSelectedClass({
      id: tag.class_id,
      name: `${tag.class_id}`,
      title: `${tag.class_id}`,
    })
    getSubjectFx(tag.subject_id).then(({ body }) =>
      subjectsDropdownModel.methods.itemChanged({ name: `${body.id}`, title: body.name })
    )
    setSelectedSubject({
      id: tag.subject_id,
      name: `${tag.subject_id}`,
      title: `${tag.subject_id}`,
    })
    modalVisibilityChanged(true)
  },
})

forward({
  from: tagTitleChanged,
  to: $titleErrorModule.methods.resetError,
})

forward({
  from: subjectsDropdownModel.methods.itemChanged,
  to: $subjectErrorModule.methods.resetError,
})

forward({
  from: classesDropdownModule.methods.itemChanged,
  to: $classErrorModule.methods.resetError,
})

condition({
  source: modalVisibilityChanged,
  if: (payload: boolean) => !payload,
  then: clearFields,
})

forward({
  from: clearFields,
  to: [
    tagTitleReset,
    subjectsDropdownModel.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    $titleErrorModule.methods.resetError,
    $subjectErrorModule.methods.resetError,
    $classErrorModule.methods.resetError,
    canRefreshTableChanged.prepend(() => false),
    setSelectedClass.prepend(() => null),
    setSelectedSubject.prepend(() => null),
  ],
})

forward({
  from: createTagFx.doneData,
  to: [
    getTagsList.prepend(() => ({})),
    getTagsTreeLight.prepend(() => ({})),
    modalVisibilityChanged.prepend(() => false),
    successToastEvent('Тег был успешно создан!'),
    canRefreshAfterCreationChange.prepend(() => true),
  ],
})
