


























import Vue, { PropType } from 'vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Icon from '@/ui/icon/Icon.vue'
import { loadModalForMultiChanges } from '@/pages/learning/learning-courses-application/list/parts/modals/application-course-update/application-course-update-modal.model'
import { taskLoader } from '@/pages/learning/learning-courses-application/list/applications-courses-page.model'

export default Vue.extend({
  components: {
    BaseButton,
    HeaderPopup,
    DownloadButton,
    Icon,
  },
  effector: {
    isLoadingExport: taskLoader.store.$exportTaskLoading,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<number[]> },
  },
  methods: {
    loadModalForMultiChanges,
  },
})
