














import Vue from 'vue'

export default Vue.extend({
  name: 'BroadOpenAnswer',
  props: {
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    correctAnswer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    currentAnswer() {
      return this.answerType === 'student' ? this.answer : this.correctAnswer
    },
  },
})
