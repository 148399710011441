import { createEvent, forward, restore } from 'effector-root'
import {
  resetUserGroupFilters,
  userGroupsFilters,
} from '@/pages/users/groups/list/model/users-groups-filters.model'

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(resetUserGroupFilters)

export const dropdownModules = {
  // classesDropdownModel,
  // statusesDropdownModel,
  // registrationsDropdownModel,
  // groupsDropdownModel,
  // coursesDropdownModel,
}

forward({
  from: [resetUserGroupFilters, toggleVisibility],
  to: [
    // classesDropdownModel.methods.resetDropdown,
    // statusesDropdownModel.methods.resetDropdown,
    // registrationsDropdownModel.methods.resetDropdown,
    // groupsDropdownModel.methods.resetDropdown,
    // coursesDropdownModel.methods.resetDropdown,
  ],
})

forward({
  from: toggleVisibility,
  to: userGroupsFilters.methods.resetFilters,
})
