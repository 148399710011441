import Vue from 'vue'
import { VueEffector } from 'effector-vue/effector-vue.umd'
import { router } from '@/features/navigation'
import { config } from '@/config'
import { setTokenForRequest } from '@/features/api/common/request'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Cookies from 'js-cookie'
import VTooltip from 'v-tooltip'
import { formatTitleByType } from '@/features/lib/index'
import App from './App.vue'
import { routes } from './routes'
import 'dayjs/locale/ru'
import '@/assets/css/common.css'
import { getUserInfo, setIsReady } from '@/pages/login/login.model'
import '@/lib/request'
import axios from 'axios'
import { logout } from '@/features/session'
import resetAccessAndRefreshTokens from '@/lib/request/utils/resetAccessAndRefreshTokens'

dayjs.extend(relativeTime)
dayjs.locale('ru')

Vue.use(VTooltip, {
  defaultBoundariesElement: document.body,
})

Vue.filter('formatTitleByType', formatTitleByType)

Vue.use(VueEffector)

Vue.config.productionTip = false

router.addRoutes(routes)

export const isReadyToken = (exp: string | undefined): boolean => {
  if (!exp) return false
  const expDate = new Date(+exp * 1000)
  return dayjs(new Date()).isBefore(dayjs(expDate))
}

const token = Cookies.get(config.TOKEN_KEY)
const tokenExp = Cookies.get(config.TOKEN_EXP)
const refreshToken = Cookies.get(config.REFRESH_TOKEN_KEY)
const refreshTokenExp = Cookies.get(config.REFRESH_TOKEN_EXP)

if (token && isReadyToken(tokenExp)) {
  setTokenForRequest(token)
  getUserInfo()
  setIsReady(true)
} else if (refreshToken && isReadyToken(refreshTokenExp)) {
  axios
    .post(`${config.BACKEND_URL}/api/user-app/token/refresh/`, {
      refresh: refreshToken,
    })
    .then((response) => {
      const { access } = response.data

      resetAccessAndRefreshTokens(access)
      setTokenForRequest(access)
      getUserInfo()
    })
    .catch(() => {
      logout()
    })
    .finally(() => {
      setIsReady(true)
    })
} else {
  if (refreshToken || token) logout()
  setIsReady(true)
}

export const app = new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
