import DefaultLayout from '@/layouts/DefaultLayout.vue'
import LearningLessonsPage from '@/pages/learning/learning-lessons/list/LearningLessonsPage.vue'
import LearningCoursesPage from '@/pages/learning/learning-courses/list/LearningCoursesPage.vue'
import CoursesCreationPage from '@/pages/learning/learning-courses/create/CoursesCreationPage.vue'
import CoursesEditPage from '@/pages/learning/learning-courses/edit/CoursesEditPage.vue'
import CreateLessonPage from '@/pages/learning/learning-lessons/create/CreateLessonPage.vue'
import ApplicationsCoursesPage from '@/pages/learning/learning-courses-application/list/ApplicationsCoursesPage.vue'
import ApplicationsCoursesEditPage from '@/pages/learning/learning-courses-application/edit/ApplicationsCoursesEditPage.vue'

export default [
  {
    name: 'learning',
    path: '/learning',
    meta: {
      title: 'Обучение - Школа Летово',
    },
    redirect: { name: 'lessons-list' },
    component: DefaultLayout,
    children: [
      {
        name: 'learning-lessons',
        path: 'learning-lessons',
        component: DefaultLayout,
        redirect: { name: 'lessons-list' },
        children: [
          {
            name: 'lessons-list',
            path: 'lessons-list',
            component: LearningLessonsPage,
            meta: {
              title: 'Уроки - Школа Летово',
            },
          },
          {
            name: 'create-lesson',
            path: 'create',
            component: CreateLessonPage,
            meta: {
              title: 'Создание урока - Школа Летово',
            },
          },
          {
            name: 'edit-lesson',
            path: 'create/:id',
            component: CreateLessonPage,
            meta: {
              title: 'Редактирование урока - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'learning-courses',
        path: 'learning-courses',
        component: DefaultLayout,
        redirect: { name: 'courses-list' },
        children: [
          {
            name: 'courses-list',
            path: 'courses-list',
            component: LearningCoursesPage,
            meta: {
              title: 'Курсы - Школа Летово',
            },
          },
          {
            name: 'courses-creation-page',
            path: 'create',
            component: CoursesCreationPage,
            meta: {
              title: 'Создание курса - Школа Летово',
            },
          },
          {
            name: 'courses-edit-page',
            path: 'create/:id',
            component: CoursesEditPage,
            meta: {
              title: 'Редактирование курса - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'courses-application',
        path: 'courses-application',
        component: DefaultLayout,
        redirect: { name: 'courses-application-list' },
        children: [
          {
            name: 'courses-application-list',
            path: 'list',
            component: ApplicationsCoursesPage,
            meta: {
              title: 'Заявки на курсы - Школа Летово',
            },
          },
          {
            name: 'courses-application-edit-page',
            path: 'create/:id',
            component: ApplicationsCoursesEditPage,
            meta: {
              title: 'Редактирование заявки на курс - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'learning-students',
        path: 'learning-students',
        component: LearningCoursesPage,
        meta: {
          title: 'Ученики - Школа Летово',
        },
      },
    ],
  },
]
