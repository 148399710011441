import { attach, createEvent, forward, combine, createStore } from 'effector-root'
import { getCommentsOnLessonsListFX } from '@/features/api/callback/get-comments-on-lessons-list'

const getCommentsOnLessonsList = attach({
  effect: getCommentsOnLessonsListFX,
  mapParams: () => ({}),
})

export const commentsOnLessonsPageMounted = createEvent<void>()

export const $isLoading = combine(getCommentsOnLessonsListFX.pending, (list) => list)

const needRefresh = createEvent<boolean>()

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(commentsOnLessonsPageMounted)

forward({
  from: commentsOnLessonsPageMounted,
  to: getCommentsOnLessonsList,
})
