import { combine, createEvent, forward, restore } from 'effector-root'
import { spread } from 'patronum'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { getInstructionIdFx } from '@/features/api/instructions/get-instructon-dy-id'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'

export const resetFields = createEvent()

export const setInstructionsName = createEvent<string>()
export const $instructionsName = restore(setInstructionsName, '').reset(resetFields)

export const setInstructionsText = createEvent<string>()
export const $instructionsText = restore(setInstructionsText, '').reset(resetFields)

export const $disabledSaveButtons = combine($instructionsName, (name) => {
  return !name
})

export const $instructionSendForm = combine(
  $instructionsName,
  $instructionsText,
  classesDropdownModule.store.$item,
  subjectsDropdownModel.store.$item,
  (name, text, study_year, subject) => {
    return {
      name,
      text,
      ...(study_year?.name ? { study_year_id: study_year.name } : {}),
      ...(subject?.name ? { subject_id: subject.name } : {}),
    }
  }
)

spread({
  source: getInstructionIdFx.doneData.map((res) => ({
    ...res.body,
    name: res.body.name,
    text: res.body.text,
    study_year: res.body?.study_year,
    subject: res.body?.subject,
  })),
  targets: {
    name: $instructionsName,
    text: $instructionsText,
    study_year: classesDropdownModule.methods.itemChanged.prepend(
      (payload: { id: number; name: string } | null) => {
        if (payload)
          return {
            name: `${payload.id}`,
            title: payload.name,
          }
        return null
      }
    ),
    subject: subjectsDropdownModel.methods.itemChanged.prepend(
      (payload: { id: number; name: string } | null) => {
        if (payload)
          return {
            name: `${payload.id}`,
            title: payload.name,
          }
        return null
      }
    ),
  },
})
forward({
  from: resetFields,
  to: [classesDropdownModule.methods.resetDropdown, subjectsDropdownModel.methods.resetDropdown],
})
