import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteEmployees } from '@/features/api/employee/types'

export const requestDeleteEmployeesFx = createApiEffect<RequestDeleteEmployees, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/user-app/employee/bulk-delete/',
    body,
  }),
})
