













































































import { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import Chip from '@/pages/dictionary/themes/list/parts/themes-tree/parts/Chip.vue'
import { TreeData, TreeElementType } from '@/features/api/types'
import { mapTaskTypeTo } from '@/pages/common/constants'
import { removeHtmlTags, sortTreeLeaves } from '@/features/lib'
import { mapTaskStatus } from '@/pages/common/parts/status-controller/constants'
import {
  $openedTreeBranches,
  storeOpenedBranch,
} from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import AutoOpenFolderMixin from '@/features/lib/mixins/AutoOpenFolderMixin'
import { FiltersParams, TreeNodeIdProp } from '@/pages/common/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { isBranchOpened } from '@/pages/common'

export default AutoOpenFolderMixin({
  filters: {
    id: (item, search) => !!item.assignment?.id.toString().includes(search),
    folder: (item, search) => {
      return !!item.folder?.name.toLowerCase().includes(search.toLowerCase())
    },
    wording: (item, search) =>
      !!item.assignment?.wording.toLowerCase().includes(search.toLowerCase()),
    score: (item, search) => !!item.assignment?.score.toString().includes(search),
    course: (item, search) => !!item.assignment?.course.toString().includes(search),
    created_by: (item, search) =>
      !!item.assignment?.created_by.first_name?.toLowerCase().includes(search) ||
      !!item.assignment?.created_by.last_name?.toLowerCase().includes(search) ||
      !!item.assignment?.created_by.id?.toString().includes(search),
  },
}).extend({
  name: 'TreeNode',
  components: {
    Icon,
    Chip,
    ActionsButton,
  },
  props: {
    node: { type: Object as PropType<TreeData> },
    parent: { type: Boolean, default: false },
    prerequisiteFolder: { type: Boolean, default: false },
    nodeId: { type: Number },
    filters: { type: Object as PropType<FiltersParams> },
    firstChild: { type: Boolean, default: false },
    lastChild: { type: Boolean, default: false },
    parentNodeId: { type: [Number, String] as PropType<TreeNodeIdProp>, default: null },
    parentNodeType: { type: String as PropType<TreeElementType>, default: null },
  },
  data: () => ({
    opened: false,
    showContextMenu: false,
    contextMenuStyles: { top: '0', left: '0' },
    searchString: '',
  }),
  computed: {
    title(): string {
      const type = this.node.element_type
      let fullName = ''
      if (type !== 'assignment' && type !== 'study_resource' && type !== 'media') {
        const entity = this.node[type]
        fullName = entity && entity.name ? entity.name : ''
        if (fullName.length > 100) {
          fullName = `${fullName.slice(0, 100)}...`
        }
      } else if (type !== 'study_resource' && type !== 'media') {
        const entity = this.node[type]
        fullName = entity ? entity.wording : ''
        if (!entity?.wording) return ''
        fullName = removeHtmlTags(fullName)
        fullName = `${fullName.slice(0, 30)}...`
      }
      return fullName
    },
    resources(): Record<string, { count: number; description: string }> {
      return {
        tasks: {
          count: (this.node.folder && this.node.folder.all_assignments_amount) || 0,
          description: 'Количество заданий в папке',
        },
      }
    },
    taskIcon(): string {
      return mapTaskTypeTo[this.node.assignment!.type].icon
    },
    correctStatus(): string {
      return mapTaskStatus[this.node.assignment!.status]
    },
    showActions(): boolean {
      const { element_type } = this.$props.node
      return element_type === 'folder'
    },
    nodeLeavesLength(): number {
      return this.node.leaves.length
    },
  },
  watch: {
    opened: {
      handler(newVal) {
        if (newVal) {
          this.node.leaves = sortTreeLeaves(this.node.leaves)
          this.loadFolderData()
        }
        storeOpenedBranch({
          id: this.nodeId,
          opened: newVal as boolean,
          type: this.node.element_type,
          parentNodeId: this.parentNodeId,
          parentNodeType: this.parentNodeType,
        })
      },
    },
    nodeLeavesLength: {
      handler(newVal) {
        if (newVal && this.opened) this.node.leaves = sortTreeLeaves(this.node.leaves)
      },
    },
  },
  methods: {
    toggle(evt: any) {
      if (evt.target.closest('.action') || this.node.element_type === 'assignment') return
      this.opened = !this.opened
    },
    loadFolderData() {
      if (this.node.element_type === 'folder') {
        const { id } = this.node[this.node.element_type]!
        this.$emit('loadTree', {
          folder: id,
          is_prerequisite: this.prerequisiteFolder ? true : undefined,
        })
      }
    },
    getType(): ContextMenuType {
      if (this.node.element_type !== 'folder') return 'item'
      return this.node.element_type
    },
    treeActionsButtonClick(event: MouseEvent) {
      this.$emit('onActionsClick', {
        data: { id: (this.node.assignment && this.node.assignment.id) || this.nodeId },
        event,
        type: this.getType(),
      })
    },
    handleRightClick(event: Event) {
      this.$emit('onRightClick', {
        data: {
          id: this.nodeId,
        },
        event,
        type: this.getType(),
      })
    },
  },
  mounted() {
    const { element_type } = this.$props.node
    if (element_type === 'assignment' || element_type === 'folder') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}`)
      nodeElement && nodeElement.addEventListener('contextmenu', this.handleRightClick)
    }
    this.opened = isBranchOpened(
      $openedTreeBranches,
      this.nodeId,
      this.node.element_type,
      this.parentNodeId,
      this.parentNodeType
    )
    if (this.filters.search) {
      const searchString = this.filters.search_area
        ? this.filters.search_area.slice(this.filters.search_area?.indexOf('_') + 1)
        : ''
      this.searchString = searchString === 'search_wording' ? 'assignment' : searchString
      this.autoOpenFolders([this.node])
    }
  },
  beforeDestroy() {
    const { element_type } = this.$props.node
    if (element_type === 'assignment' || element_type === 'folder') {
      const nodeElement = document.querySelector(`#node-${this.$props.nodeId}`)
      nodeElement && nodeElement.removeEventListener('contextmenu', this.handleRightClick)
    }
  },
})
