









import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'Actions',
  components: {
    Icon,
  },
})
