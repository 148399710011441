import ClassesDropdown from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/ClassesFilterDropdown.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/SubjectFilterDropdown.vue'
import GroupsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/GroupsFilterDropdown.vue'
import CourseSubmitApplicationsFilterDropdown from '@/pages/learning/learning-courses/list/parts/filter/parts/course-submit-applications-filter/CourseSubmitApplicationsFilterDropdown.vue'
import CourseTypeDropdown from '@/pages/learning/learning-courses/list/parts/filter/parts/course-type-filter/CourseTypeDropdown.vue'
import CourseTaskCheckTypeDropdown from '@/pages/learning/learning-courses/list/parts/filter/parts/course-task-check-type-filter/CourseTaskCheckTypeDropdown.vue'
import StudentsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/student-filter-dropdown/StudentsFilterDropdown.vue'
import StatusFilterDropdown from '@/pages/common/dropdowns/courses/status-dropdown/StatusFilterDropdown.vue'
import CoursesFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/courses-name-dropdown-filter/CoursesFilterDropdown.vue'

export const dropdownComponents = {
  ClassesDropdown,
  SubjectsDropdown,
  GroupsFilterDropdown,
  CourseSubmitApplicationsFilterDropdown,
  CourseTypeDropdown,
  CourseTaskCheckTypeDropdown,
  CoursesFilterDropdown,
  StudentsFilterDropdown,
  StatusFilterDropdown,
}
