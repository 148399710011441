

















































































































































































































































































import Vue, { VueConstructor } from 'vue'
import qs from 'qs'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import PageHeader from '@/pages/results/tests-all/parts/header/PageHeader.vue'
import TestsFilter from '@/pages/results/tests-all/parts/filter/TestsFilter.vue'
import ResultTestsFilter from '@/pages/results/tests-all/parts/result-filter/ResultTestsFilter.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import {
  combineRouteQueries,
  computeSortParam,
  isQueryParamsEquelToPage,
  removeHtmlTags,
} from '@/features/lib'
import { config } from '@/config'
import { $permissions } from '@/features/session'
import { DEFAULT_ID } from '@/pages/common/constants'
import { searchFieldsData, testsResultDataFields } from '@/pages/results/tests-all/constants'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  $canRefreshTable,
  incomingResultTestsPageParams,
  canRefreshTableChanged,
  $isLoading,
  setNewTableFields,
  $tableFields,
  taskLoader,
  setIsLoading,
} from '@/pages/results/tests-all/result-tests-all.model'
import { RefsType, HttpOptionsType } from '@/pages/common/types'
import {
  toggleVisibility,
  $visibility,
  resultTestsAllFilters,
} from '@/pages/results/tests-all/parts/filter/tests-result-filter.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import TableColumnEditModal from '@/pages/common/modals/table-settings/table-colunm-edit/TableColumnEditModal.vue'
import {
  $setColumns,
  changeColumnsModalVisibility,
} from '@/pages/common/modals/table-settings/table-colunm-edit/table-colunm-edit-modal.model'
import Icon from '@/ui/icon/Icon.vue'
import ResultTestsFilterModal from '@/pages/common/modals/result-tests-filter/ResultTestsFilterModal.vue'
import {
  $resultFilterBlock,
  $saveFilters,
} from '@/pages/common/modals/result-tests-filter/result-tests-filter-modal.model'
import { axiosClient } from '@/lib/request'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    PageHeader,
    GeneralFilter,
    TestsFilter,
    TableHeader,
    Vuetable,
    VuetablePagination,
    NoDataContent,
    TooltipCell,
    LoaderBig,
    ResultTestsFilter,
    TableColumnEditModal,
    Icon,
    ResultTestsFilterModal,
  },
  effector: {
    $canRefreshTable,
    $filterParams: resultTestsAllFilters.store.$filterParams,
    $pageParams: incomingResultTestsPageParams.store.$pageParams,
    $currentPage: incomingResultTestsPageParams.store.currentPage,
    $isLoading,
    $tableFields,
    $visibility,
    $permissions,
    $setColumns,
    $saveFilters,
    $resultFilterBlock,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID as number,
      searchFields: searchFieldsData,
      total: 1,
      fields: testsResultDataFields,
      selectedRows: [] as number[],
      isFilters: false,
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/results-app/results/test/list/`
    },
  },
  watch: {
    $canRefreshTable: {
      handler(newVal) {
        if (newVal === true) {
          this.$refs.vuetable.reload()
          canRefreshTableChanged(false)
        }
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    toggleVisibility,
    changeFilter: resultTestsAllFilters.methods.changeFilter,
    resetFilters: resultTestsAllFilters.methods.resetFilters,
    applyFilters: resultTestsAllFilters.methods.applyFilters,
    removeParamsFilter: resultTestsAllFilters.methods.removeParamsFilter,
    changePage: incomingResultTestsPageParams.methods.changePage,
    queryToParams: incomingResultTestsPageParams.methods.queryToParams,
    setNewTableFields,
    setIsLoading,
    changeColumnsModalVisibility,
    exportTable() {
      taskLoader.effect.exportFx(null)
      taskLoader.methods.setTaskLoading(true)
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const resultsParams = {
        get_query_filters: JSON.stringify(
          this.$resultFilterBlock.map((block) => ({
            subject: block.subject ? block.subject.name : '',
            test: block.test ? block.test.name : '',
            sign: block.sign ? block.sign.name : '',
            value: block.value ? block.value.name : '',
          }))
        ),
      }
      const request = axiosClient.get(apiUrl, {
        params: {
          ...httpOptions.params,
          sort: computeSortParam(httpOptions.params.sort),
          ...(this.$saveFilters && resultsParams),
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' })
        },
      })
      return request
    },
    onFilterSet() {
      this.isFilters = true
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.isFilters = false
      reset() // search string and field
      this.resetFilters()
      Vue.nextTick(() => this.$refs.vuetable?.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-link')) {
        window.open(res.event.target.closest('.actions-link').href, '_blank')
        return
      }
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    clearWording(str: string) {
      return removeHtmlTags(str)
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    setColumns() {
      this.setNewTableFields(this.$setColumns)
      this.changeColumnsModalVisibility(false)
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
