import { createApiEffect } from '@/features/api/common/create-api-effect'
import { CourseRelated } from '@/features/api/learning/types'

export const getCoursesSortFx = createApiEffect<void, CourseRelated[]>({
  requestMapper: () => ({
    method: 'GET',
    url: `/api/course-app/courses/list/bulk-change-order/`,
  }),
})

export const setCoursesSortFx = createApiEffect<{ courses: number[] }, CourseRelated[]>({
  requestMapper: (body) => ({
    method: 'PUT',
    url: `/api/course-app/courses/list/bulk-change-order/`,
    body,
  }),
})
