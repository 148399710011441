import { combine, createEvent, forward, restore } from 'effector'
import {
  $selectedThemes,
  resetThemes,
} from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'
import { difficultyDropdownModule } from '@/pages/common/dropdowns/testing/difficulty-dropdown/difficulty-dropdown.model'
import { DEFAULT_ID } from '@/pages/common/constants'

export const clearAutomaticGenerationFields = createEvent<void>()

export const changeDifficulty = createEvent<number>()
export const $difficulty = restore(changeDifficulty, DEFAULT_ID).reset(
  clearAutomaticGenerationFields
)

export const togglePrerequisites = createEvent<boolean>()
export const $prerequisites = restore(togglePrerequisites, false).reset(
  clearAutomaticGenerationFields
)

export const $isFilled = combine($difficulty, $selectedThemes, (difficulty, themes) => {
  const isDifficulty = difficulty >= 0
  return isDifficulty && themes.length > 1
})

forward({
  from: clearAutomaticGenerationFields,
  to: [difficultyDropdownModule.methods.resetDropdown, resetThemes],
})
