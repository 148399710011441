














import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import { AttemptsDropdownModel } from '@/pages/exams/part-time-answers/list/parts/attempts-filter/attempts-filter.model'

export default Vue.extend({
  name: 'AttemptsFilter',
  components: {
    FilterDropdown,
  },
  effector: {
    ...AttemptsDropdownModel.store,
  },
  methods: {
    ...AttemptsDropdownModel.methods,
    onItemChanged(item: DropdownItem | null) {
      this.$emit('setItem', item)
    },
  },
})
