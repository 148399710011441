import { createApiEffect } from '@/features/api/common/create-api-effect'
import { AttemptsUpdateForm, UpdateAttemptsBulkFailResponse } from '@/features/api/attempts/types'

export const updateAttemptsBulkFx = createApiEffect<
  AttemptsUpdateForm,
  AttemptsUpdateForm,
  UpdateAttemptsBulkFailResponse
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/exams-app/part-time/attempts/list/update/',
    body,
  }),
})
