








































































import { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import Chip from '@/pages/labels/parts/tree/Chip.vue'
import { TreeData, TreeElementType } from '@/features/api/types'
import { loadModal } from '@/pages/labels/parts/modals/tasks/tasks.model'
import { loadModalToEdit } from '@/pages/labels/parts/modals/label-edition/label-edition.modal'
import { createLabelFromTree } from '@/pages/labels/parts/modals/label-creation/label-creation.model'
import { sortTreeLeaves } from '@/features/lib'
import {
  $openedTreeBranches,
  storeOpenedBranch,
} from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { FiltersParams, TreeNodeIdProp } from '@/pages/common/types'
import AutoOpenFolderMixin from '@/features/lib/mixins/AutoOpenFolderMixin'
import { DEFAULT_ID } from '@/pages/common/constants'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { isBranchOpened } from '@/pages/common'

export default AutoOpenFolderMixin({
  filters: {
    name: (item, search) => !!item.label?.name.toLowerCase().includes(search.toLowerCase()),
    study_year: (item, search) =>
      !!item.study_year?.name.toLowerCase().includes(search.toLowerCase()),
    subject: (item, search) => !!item.subject?.name.toLowerCase().includes(search.toLowerCase()),
    theme: (item, search) => !!item.theme?.name.toLowerCase().includes(search.toLowerCase()),
  },
}).extend({
  name: 'TreeNode',
  components: {
    Icon,
    Chip,
    ActionsButton,
  },
  props: {
    node: { type: Object as PropType<TreeData> },
    parent: { type: Boolean, default: false },
    prerequisiteFolder: { type: Boolean, default: false },
    nodeId: { type: [Number, String] as PropType<TreeNodeIdProp> },
    filters: { type: Object as PropType<FiltersParams> },
    firstChild: { type: Boolean, default: false },
    lastChild: { type: Boolean, default: false },
    parentNodeId: { type: [Number, String] as PropType<TreeNodeIdProp>, default: null },
    parentNodeType: { type: String as PropType<TreeElementType>, default: null },
  },
  data: () => ({
    opened: false,
    searchString: '',
  }),
  computed: {
    title() {
      const type = this.node.element_type
      let fullName = ''
      if (type !== 'assignment' && type !== 'study_resource' && type !== 'media') {
        const entity = this.node[type]
        fullName = entity ? entity.name : ''
        if (fullName.length > 100) {
          fullName = `${fullName.slice(0, 100)}...`
        }
      }
      return fullName
    },
    resources() {
      return {
        themes_tasks: {
          count: this.node.theme ? this.node.theme.assignments_amount : null,
          description: 'Количество заданий',
        },
        labels_tasks: {
          count: this.node.label ? this.node.label.assignments_count : null,
          description: 'Количество заданий',
        },
        labels: {
          count: this.node.theme ? this.node.theme.labels_amount : null,
          description: 'Количество меток',
        },
      }
    },
    showActions() {
      const { element_type } = this.node
      return ['label', 'theme'].includes(element_type)
    },
    dataToCreateLabel() {
      const { theme } = this.node
      if (theme)
        return {
          class_id: theme.study_year_id,
          subject_id: theme.subject_id,
          theme_id: theme.id,
        }
      return null
    },
    nodeLeavesLength(): number {
      return this.node.leaves.length
    },
  },
  watch: {
    opened: {
      handler(newVal) {
        if (newVal) {
          this.node.leaves = sortTreeLeaves(this.node.leaves)
          this.loadFolderData()
        }
        storeOpenedBranch({
          id: this.nodeId,
          opened: newVal as boolean,
          type: this.node.element_type,
          parentNodeId: this.parentNodeId,
          parentNodeType: this.parentNodeType,
        })
      },
    },
    nodeLeavesLength: {
      handler(newVal) {
        if (newVal && this.opened) this.node.leaves = sortTreeLeaves(this.node.leaves)
      },
    },
  },
  methods: {
    loadModal,
    loadModalToEdit,
    createLabelFromTree,
    toggle(evt: any) {
      if (evt.target.closest('.action') || this.node.element_type === 'label') return
      this.opened = !this.opened
    },
    loadFolderData() {
      if (
        (!this.node.leaves.length && this.node.element_type === 'theme') ||
        (this.node.leaves.every((leaf) => leaf.element_type === 'theme') &&
          this.node.element_type === 'theme')
      ) {
        const { subject_id, study_year_id, id } = this.node[this.node.element_type]!
        this.$emit('loadTree', {
          subject: subject_id,
          study_year: study_year_id,
          theme: id,
          is_prerequisite: this.prerequisiteFolder,
        })
      }
    },
    getType(): ContextMenuType {
      if (this.node.element_type !== 'theme') return 'item'
      return 'folder'
    },
    treeActionsButtonClick(event: MouseEvent) {
      const type = this.getType()
      this.$emit('onActionsClick', {
        data: {
          id: this.nodeId,
          class_id: this.node.theme ? this.node.theme.study_year_id : DEFAULT_ID,
          subject_id: this.node.theme ? this.node.theme.subject_id : DEFAULT_ID,
          is_prerequisite: this.prerequisiteFolder,
        },
        event,
        type,
      })
    },
    handleRightClick(event: any) {
      const type = this.getType()
      this.$emit('onRightClick', {
        data: {
          id: this.nodeId,
          class_id: this.node.theme ? this.node.theme.study_year_id : DEFAULT_ID,
          subject_id: this.node.theme ? this.node.theme.subject_id : DEFAULT_ID,
          is_prerequisite: this.prerequisiteFolder,
        },
        event,
        type,
      })
    },
  },
  mounted() {
    if (['theme', 'label'].includes(this.node.element_type)) {
      const nodeElement = document.querySelector(`#node-${this.node.element_type}-${this.nodeId}`)
      nodeElement && nodeElement.addEventListener('contextmenu', this.handleRightClick)
    }
    this.opened = isBranchOpened(
      $openedTreeBranches,
      this.nodeId,
      this.node.element_type,
      this.parentNodeId,
      this.parentNodeType
    )
    if (this.filters.search) {
      this.searchString = this.filters.search_area
        ? this.filters.search_area.slice(this.filters.search_area?.indexOf('_') + 1)
        : ''
      this.autoOpenFolders([this.node])
    }
  },
  beforeDestroy() {
    if (['theme', 'label'].includes(this.node.element_type)) {
      const nodeElement = document.querySelector(`#node-${this.node.element_type}-${this.nodeId}`)
      nodeElement && nodeElement.removeEventListener('contextmenu', this.handleRightClick)
    }
  },
})
