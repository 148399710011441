


















import Vue from 'vue'
import EditEmployesContent from '@/pages/users/employes/edit/parts/EditEmployesContent.vue'
import EditEmployeeFooter from '@/pages/users/employes/edit/parts/EditEmployeeFooter.vue'
import EditEmployeeHeader from '@/pages/users/employes/edit/parts/EditEmployeeHeader.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import {
  $disabledSaveButtons,
  clearFields,
} from '@/pages/users/employes/edit/model/form-fields.model'
import {
  $editedEmployeeId,
  changeEmployeeId,
  saveEmployeeInfo,
  setRedirectAfterSave,
} from '@/pages/users/employes/edit/model/edit-employee.model'
import { createEmployeeFx } from '@/features/api/employee/create-employee'
import { getEmployeeByIdFx } from '@/features/api/employee/get-employee-by-id'

export default Vue.extend({
  name: 'EditEmployesPage',
  components: {
    EditEmployeeHeader,
    EditEmployeeFooter,
    EditEmployesContent,
    LoaderBig,
  },
  effector: {
    $disabledSaveButtons,
    $editedEmployeeId,
    isCreating: createEmployeeFx.pending,
    isEditing: getEmployeeByIdFx.pending,
  },
  methods: {
    saveTask(isRedirect: boolean) {
      if (isRedirect) {
        setRedirectAfterSave(true)
        saveEmployeeInfo()
      } else {
        saveEmployeeInfo()
      }
    },
  },
  created() {
    changeEmployeeId(this.$route.params.id)
  },
  beforeDestroy() {
    clearFields()
  },
})
