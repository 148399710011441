import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/exams/exams-full-tests/exams-full-test-list-dropdown.model'
import { attemptsStatusPassDropdownModule } from '@/pages/common/dropdowns/multiselectDropdown/attempts-pass-status-dropdown/attempts-status-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { studentsDropdownModel } from '@/pages/exams/attempts/list/parts/filter/parts/students-attempts-filter/students-attempts-filter.model'
import { proctorsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/proctors-filter-dropdown.model'
import { attemptsResultStatusDropdownModule } from '@/pages/exams/full-attempts/list/parts/filter/parts/results-status-filter/results-status-filter.model'

export const dropdownFullExamAttemptAllModules = {
  groupsDropdownModel,
  studentsDropdownModel,
  subjectsDropdownModel,
  classesDropdownModule,
  examTestsDropdownModel,
  attemptsStatusPassDropdownModule,
  proctorsDropdownModel,
  attemptsResultStatusDropdownModule,
}
