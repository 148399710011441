














import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  AttemptsDropdownModel,
  loadAttempts,
} from '@/pages/exams/full-time-answers/list/parts/full-attempts-filter/full-attempts-filter.model'

export default Vue.extend({
  name: 'AttemptsFilter',
  components: {
    FilterDropdown,
  },
  effector: {
    ...AttemptsDropdownModel.store,
  },
  methods: {
    loadAttempts,
    ...AttemptsDropdownModel.methods,
    onItemChanged(item: DropdownItem | null) {
      this.$emit('setItem', item)
    },
  },
})
