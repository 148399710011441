







































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $editedQuestionId } from '@/pages/content/faq/edit/model/edit-question.model'

export default Vue.extend({
  name: 'EditQuestionHeader',
  components: {
    Icon,
    BaseButton,
  },
  props: {
    title: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean>, default: true },
    fromPage: { type: String as PropType<string> },
  },
  effector: {
    questionId: $editedQuestionId,
  },
  methods: {
    goToList() {
      switch (this.$route.name) {
        default:
          navigatePush({ name: 'faq-list' })
      }
    },
    onSaveClick() {
      this.$emit('save')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
