import { attach, combine, createEvent, forward, restore, sample, Event, Store } from 'effector-root'
import { condition } from 'patronum'
import { createError } from '@/lib/effector/error-generator'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
// lessong assignment
import { createFolderFx } from '@/features/api/assignment/folder/create-folder'
import {
  $folders,
  foldersDropdownModule,
} from '@/pages/common/dropdowns/bank/lesson-tasks/position-dropdown/position-dropdown.model'
import { setFolder } from '@/pages/bank/lesson-tasks/create/task-creation-page.model'
import { loadTreeLight } from '@/pages/bank/lesson-tasks/list/lesson-page.model'
import { getFolderFx } from '@/features/api/assignment/folder/get-folder'
// exam-assignment
import { createExamFolderFx } from '@/features/api/assignment/exam-assignment/folder/create-folder'
import { getExamFolderFx } from '@/features/api/assignment/exam-assignment/folder/get-folder'
import { loadTreeLight as loadExamTreeLight } from '@/pages/bank/exam-tasks/list/exam-tasks-page.model'
import {
  $folders as $examFolders,
  foldersDropdownModule as examFoldersDropdownModule,
} from '@/pages/common/dropdowns/bank/exam-tasks/folder-dropdown/folder-dropdown.model'

// exam tests
import { createExamTestFolderFx } from '@/features/api/full-time-tests/create-folder'
import { getExamTestFolderFx } from '@/features/api/full-time-tests/get-folder'
import { loadTreeLight as loadExamTestTreeLight } from '@/pages/exams/full-time-exam-test/list/parts/exam-tests-page.model'
import {
  $folders as $examTestFolders,
  foldersDropdownModule as examTestFoldersDropdownModule,
} from '@/pages/common/dropdowns/exams/exams-tests-folders/folder-dropdown.model'

import { DropdownItem } from '@/pages/common/types'
import { CreateFolderType, FolderType } from '@/features/api/assignment/types/types'
import { ApiEffect } from '@/features/api/common/create-api-effect'

const searchParentFolder = function (id: number, folders: any) {
  let res = null
  folders.forEach((folder: any) => {
    if (folder.id === id) {
      res = folder
      return res
    }
    if (folder.leaves) {
      const fold = searchParentFolder(id, folder.leaves)
      if (fold) {
        res = fold
        return res
      }
    }
    return null
  })
  return res
}

function createFolderModalModule(
  getFolderMethod: ApiEffect<number, FolderType, any>,
  createFolderMethod: ApiEffect<CreateFolderType, FolderType, any>,
  treeLightEvent: Event<void>,
  setFolderEvent: Event<number | null>,
  folderEffectorUnits: {
    dropdownModule: ReturnType<typeof createFilter>
    folders: Store<DropdownItem[]>
  }
) {
  const createFolder = attach({
    effect: createFolderMethod,
  })

  const getFolder = attach({
    effect: getFolderMethod,
  })

  const loadFolder = createEvent<number>()

  const checkIfFolderCanBeSend = createEvent<void>()
  const clearFields = createEvent<void>()

  const modalVisibilityChanged = createEvent<boolean>()
  const $modalVisibility = restore(modalVisibilityChanged, false)

  const folderTitleChanged = createEvent<string>()
  const $folderTitle = restore(folderTitleChanged, '').reset(clearFields)

  const $titleErrorModule = createError()

  const $form = combine({
    name: $folderTitle,
    parent_id: folderEffectorUnits.dropdownModule.store.$item.map((data: any) =>
      data && data.id ? +data.id : null
    ),
  })

  sample({
    source: $form,
    clock: checkIfFolderCanBeSend,
    fn: (obj) => {
      if (obj.name.trim().length) {
        createFolder(obj)
      } else {
        if (!obj.name.trim().length) $titleErrorModule.methods.setError(true)
        errorToastEvent('Необходимо заполнить все обязательные поля')
      }
    },
  })

  forward({
    from: loadFolder,
    to: [getFolder, setFolderEvent],
  })

  sample({
    source: folderEffectorUnits.folders,
    clock: getFolder.doneData.map(({ body }) => body),
    fn: (folders, data): DropdownItem | null => {
      let res = null
      folders.forEach((folder) => {
        if (folder.id === data.id) {
          res = folder
          return res
        }
        if (folder.leaves?.length) {
          const fold = searchParentFolder(data.id!, folder.leaves)
          if (fold) {
            res = fold
            return res
          }
        }
        return null
      })
      return res
    },
    target: folderEffectorUnits.dropdownModule.store.$item,
  })

  forward({
    from: folderTitleChanged,
    to: $titleErrorModule.methods.resetError,
  })

  condition({
    source: modalVisibilityChanged,
    if: (payload: boolean) => !payload,
    then: clearFields,
  })

  forward({
    from: clearFields,
    to: [
      folderEffectorUnits.dropdownModule.methods.resetDropdown,
      $titleErrorModule.methods.resetError,
      folderEffectorUnits.dropdownModule.methods.itemChanged.prepend(() => null),
    ],
  })

  forward({
    from: createFolder.doneData,
    to: [
      treeLightEvent.prepend(() => ({})),
      modalVisibilityChanged.prepend(() => false),
      successToastEvent('Папка была успешно создана!'),
    ],
  })

  return {
    store: {
      $modalVisibility,
      $titleErrorModule,
      $folderTitle,
    },
    methods: {
      loadFolder,
      checkIfFolderCanBeSend,
      modalVisibilityChanged,
      folderTitleChanged,
    },
  }
}

export const lessonAssignmentFolderModal = createFolderModalModule(
  getFolderFx,
  createFolderFx,
  loadTreeLight,
  setFolder,
  {
    dropdownModule: foldersDropdownModule,
    folders: $folders,
  }
)

export const examAssignmentFolderModal = createFolderModalModule(
  getExamFolderFx,
  createExamFolderFx,
  loadExamTreeLight,
  // @ts-ignore
  examFoldersDropdownModule.methods.itemChanged,
  {
    dropdownModule: examFoldersDropdownModule,
    folders: $examFolders,
  }
)

export const examTestsFolderModal = createFolderModalModule(
  getExamTestFolderFx,
  createExamTestFolderFx,
  loadExamTestTreeLight,
  // @ts-ignore
  examTestFoldersDropdownModule.methods.itemChanged,
  {
    dropdownModule: examTestFoldersDropdownModule,
    folders: $examTestFolders,
  }
)
