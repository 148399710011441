import { createApiEffect } from '@/features/api/common/create-api-effect'
import { AppointmentCreateFxParams, AppointmentCreateType } from '@/features/api/appointments/types'

export const updateFullAppointmentsFx = createApiEffect<
  { body: AppointmentCreateType; id: string },
  AppointmentCreateFxParams
>({
  requestMapper: ({ body, id }) => ({
    method: 'PATCH',
    url: `/api/exams-app/appointments/${id}/`,
    body,
  }),
})
