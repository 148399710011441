











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  shownDropdownModel,
  setSelectedShown,
  $shown,
} from '@/pages/callback/comments-on-lessons/parts/dropdowns/shown-dropdown/shown-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...shownDropdownModel.store,
    $shown,
  },
  methods: {
    ...shownDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedShown(item || null)
      this.$emit('setItem', item ? item.name : null)
    },
  },
})
