











import Vue, { PropType } from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { ElementTypeNames } from '@/pages/learning/learning-lessons/create/model/types'
import {
  elementsDropdownModel,
  $elements,
  PageType,
} from '@/pages/common/dropdowns/add-assignment/assignment-elements.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...elementsDropdownModel.store,
    elements: $elements,
  },
  props: {
    label: String,
    pageType: { type: String as PropType<PageType>, default: 'lessons' },
    value: { type: Object as PropType<DropdownItem>, default: null },
    filterBy: { type: Array as PropType<ElementTypeNames[]>, default: () => [] },
  },
  data() {
    return {
      filteredElements: [],
    }
  },
  methods: {
    ...elementsDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      if (this.pageType === 'lessons') {
        this.$emit('setItem', item ? item.name : null)
      }
      if (this.pageType === 'exam-tests') {
        this.$emit('setItem', item || null)
      }
    },
  },
  beforeMount() {
    // @ts-ignore
    this.filteredElements = this.elements.filter((el) => !this.$props.filterBy.includes(el.name))
  },
})
