










import Vue from 'vue'
import AppointmentsPage1 from '@/pages/exams/appointments/create/parts/AppointmentsPage1.vue'
import AppointmentsPage2 from '@/pages/exams/appointments/create/parts/AppointmentsPage2.vue'
import AppointmentsPage3 from '@/pages/exams/appointments/create/parts/AppointmentsPage3.vue'

export default Vue.extend({
  name: 'CreateAppointmentsContent',
  components: {
    AppointmentsPage1,
    AppointmentsPage2,
    AppointmentsPage3,
  },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    tabsArr: {
      type: Array,
      default: () => [],
    },
  },
})
