



















import Vue, { PropType } from 'vue'
import RadioButton from './RadioButton.vue'

type Items = {
  name?: string
  option: string | boolean
  label: string
}[]

export default Vue.extend({
  name: 'RadioContainer',
  components: {
    RadioButton,
  },
  props: {
    value: { type: String as PropType<string>, required: true },
    items: { type: Array as PropType<Items>, required: true },
    direction: { type: String as PropType<'horizontal' | 'vertical'>, default: 'vertical' },
    disabled: { type: Boolean as PropType<boolean> },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
})
