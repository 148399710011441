



























import Vue from 'vue'
import CreateAppointmentsFooter from '@/pages/exams/appointments/create/parts/CreateAppointmentsFooter.vue'
import CreateAppointmentsHeader from '@/pages/exams/appointments/create/parts/CreateAppointmentsHeader.vue'
import CreateAppointmentsContent from '@/pages/exams/appointments/create/parts/CreateAppointmentsContent.vue'
import { changeAppointmentsId } from '@/pages/exams/appointments/create/model/edit-appointments.model'
import {
  $disabledSaveButtons,
  resetFields,
} from '@/pages/exams/appointments/create/model/form-fileds.model'
import {
  getPageParams,
  setRedirectAfterSave,
} from '@/pages/exams/appointments/create/model/create-appointments.model.ts'
import Tabs from '@/pages/common/parts/tabs/Tabs.vue'

export default Vue.extend({
  name: 'CreateAppointmentsPage',
  components: {
    CreateAppointmentsHeader,
    CreateAppointmentsFooter,
    CreateAppointmentsContent,
    Tabs,
  },
  effector: {
    $disabledSaveButtons,
  },
  data() {
    return {
      tab: 1,
      tabs: [
        {
          tab: 1,
          title: 'Шаг 1. Организационные настройки',
        },
        {
          tab: 2,
          title: 'Шаг 2. Выбор теста и периода',
        },
        {
          tab: 3,
          title: 'Шаг 3. Назначение ученикам',
        },
      ],
    }
  },
  methods: {
    save(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      getPageParams()
    },
    setTab(tab: number) {
      this.tab = tab
    },
  },
  created() {
    changeAppointmentsId(this.$route.params.id)
  },
  beforeDestroy() {
    resetFields()
  },
})
