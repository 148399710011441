import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteFolderParams } from '@/features/api/assignment/types/types'

export const deleteExamAssignmentFolderFx = createApiEffect<number, void>({
  requestMapper: (id) => ({
    method: 'DELETE',
    url: `/api/assignment-app/exam-folder/${id}/`,
  }),
})

export const requestDeleteExamFolderFx = createApiEffect<
  RequestDeleteFolderParams,
  RequestDeleteFolderParams
>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/assignment-app/exam-folder/bulk-delete/',
    body,
  }),
})
