import { createStore, combine, createEvent, forward, guard, restore } from 'effector-root'
import { setTokenForRefresh, setTokenForRequest } from '@/features/api/common/request'
import { getSelfUserFx } from '@/features/api/employee/get-self-user'
import { modalLogoutVisibilityChanged } from '@/pages/common/modal-logout/modal-logout.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $routesPermissionsKeys } from '@/features/navigation/types'
import { Employee } from '@/features/api/employee/types'
import { ItemRoutesPermissions } from '@/features/session/types'
import Cookies from 'js-cookie'
import { config } from '@/config'

export const logout = createEvent()

export const $currentUser = createStore<null | Employee>(null)
  .on(getSelfUserFx.doneData, (_, { body }) => body)
  .reset(logout)

export const $isAuthed = combine(
  $currentUser,
  getSelfUserFx.pending,
  (session, pending) => pending || (!!session && !pending)
)

export const setLayoutPadding = createEvent<string>()
export const $layoutPadding = restore(setLayoutPadding, '30px')

export const $permissions = $currentUser.map((user) => user?.permissions)

type RoutesPermissions = { [K in $routesPermissionsKeys]: boolean }

const isShowMenu = (menu: ItemRoutesPermissions | undefined) => {
  if (!menu) return false
  return menu.can_edit || menu.can_view
}

const defaultRoutesState = {
  'no-page': true,
  home: true,
  'auth.login': true,
  'incoming-list': true,
  'outgoing-list': true,
  'incoming-deletion-list': true,
  'outgoing-deletion-list': true,
  'test-tasks-list': true,
  'test-tasks-create': true,
  'test-tasks-edit': true,
  'preview-task-test-assignment': true,
  'olympiad-tasks-list': true,
  'olympiad-tasks-create': true,
  'olympiad-tasks-edit': true,
  'preview-task-olympiad-assignment': true,
  'lesson-tasks-list': true,
  'lesson-tasks-edit': true,
  'lesson-tasks-create': true,
  'preview-task-lesson-assignment': true,
  'tests-list': true,
  'test-creation-page': true,
  'test-edit-page': true,
  'learning-lessons': true,
  'lessons-list': true,
  'employers-list': true,
  'create-employee': true,
  'edit-employee': true,
  'users-list': true,
  'news-list': true,
  'create-news': true,
  'edit-news': true,
  'faq-list': true,
  'create-question': true,
  'edit-question': true,
  'edit-student': true,
  'create-lesson': true,
  'edit-lesson': true,
  'courses-list': true,
  'courses-creation-page': true,
  'courses-edit-page': true,
  'subjects-list': true,
  'subjects-create': true,
  'subjects-edit': true,
  'themes-list': true,
  'themes-create': true,
  'themes-edit': true,
  'resources-list': true,
  'resources-create': true,
  'resources-edit': true,
  'files-list': true,
  'labels-list': true,
  'tags-list': true,
  'results-courses': true,
  'results-courses-list': true,
  'results-courses-student-list': true,
  'results-courses-check-list': true,
  'courses-application': true,
  'courses-application-list': true,
  'courses-application-edit-page': true,
  'results-olympiad-tasks': true,
  'results-tests-all': true,
  'results-tests-students': true,
  'slides-list': true,
  'slides-create': true,
  'slides-edit': true,
  'banners-list': true,
  'banners-create': true,
  'banners-edit': true,
  'achievement-page': true,
  'hobbies-page': true,
  'groups-list': true,
  'create-group': true,
  'edit-group': true,
  'instructions-list': true,
  'instruction-create': true,
  'instruction-edit': true,
  'proctors-list': true,
  'proctors-create': true,
  'proctors-edit': true,
  'part-time-exams-tests-list': true,
  'part-time-exams-tests-edit': true,
  'part-time-exams-tests-create': true,
  'exams-appointment-list': true,
  'exams-appointment-edit': true,
  'exams-appointment-create': true,
  'exams-attempts-list': true,
  'exams-answers-list': true,
  'exam-tasks-list': true,
  'exam-tasks-create': true,
  'exam-tasks-edit': true,
  'preview-task-exam-assignment': true,
  'full-appointment-list': true,
  'full-appointment-create': true,
  'full-appointment-edit': true,
  'full-exam-tests-list': true,
  'full-exam-test-create': true,
  'full-exam-test-edit': true,
  'exams-full-attempts-list': true,
  'exams-full-answers-list': true,
  'comments-on-lessons-list': true,
  'certificates-generator': true,
  'certificates-generator-table': true,
  'certificates-generator-create-template': true,
  'certificates-generator-create-certificates': true,
  'subscribers-on-email': true,
}

export const $routesPermissions = createStore<RoutesPermissions>(defaultRoutesState).on(
  $currentUser,
  (_, user): RoutesPermissions => {
    if (!user || !user?.permissions) return defaultRoutesState
    const { permissions } = user
    const isDefaultShow = user.role !== 'METHODIST'
    return {
      'no-page': isDefaultShow,
      home: true,
      'auth.login': true,
      'incoming-list': isShowMenu(permissions?.tickets_moderationticket_income_tickets),
      'outgoing-list': isShowMenu(permissions?.tickets_moderationticket_outcome_tickets),
      'incoming-deletion-list': isShowMenu(permissions?.tickets_deletionticket_income_tickets),
      'outgoing-deletion-list': isShowMenu(permissions?.tickets_deletionticket_outcome_tickets),
      'test-tasks-list':
        isShowMenu(permissions?.assignments_testassignment_difficulty_base) ||
        isShowMenu(permissions?.assignments_testassignment_difficulty_pro) ||
        isShowMenu(permissions?.assignments_testassignment_difficulty_exam),
      'test-tasks-create':
        !!permissions?.assignments_testassignment_difficulty_base.can_edit ||
        !!permissions?.assignments_testassignment_difficulty_pro.can_edit ||
        !!permissions?.assignments_testassignment_difficulty_exam.can_edit,
      'test-tasks-edit':
        !!permissions?.assignments_testassignment_difficulty_base.can_edit ||
        !!permissions?.assignments_testassignment_difficulty_pro.can_edit ||
        !!permissions?.assignments_testassignment_difficulty_exam.can_edit,
      'preview-task-test-assignment':
        isShowMenu(permissions?.assignments_testassignment_difficulty_base) ||
        isShowMenu(permissions?.assignments_testassignment_difficulty_pro) ||
        isShowMenu(permissions?.assignments_testassignment_difficulty_exam),
      'olympiad-tasks-list': isShowMenu(permissions?.assignments_olympiadassignment_assignments),
      'olympiad-tasks-create': !!permissions?.assignments_olympiadassignment_assignments.can_edit,
      'olympiad-tasks-edit': !!permissions?.assignments_olympiadassignment_assignments.can_edit,
      'preview-task-olympiad-assignment': isShowMenu(
        permissions?.assignments_olympiadassignment_assignments
      ),
      'lesson-tasks-list': isShowMenu(permissions?.assignments_lessonassignment_assignments),
      'lesson-tasks-edit': !!permissions?.assignments_lessonassignment_assignments.can_edit,
      'lesson-tasks-create': !!permissions?.assignments_lessonassignment_assignments.can_edit,
      'preview-task-lesson-assignment': isShowMenu(
        permissions?.assignments_lessonassignment_assignments
      ),
      'tests-list': isShowMenu(permissions?.tests_test_tests),
      'test-creation-page': !!permissions?.tests_test_tests.can_edit,
      'test-edit-page': !!permissions?.tests_test_tests.can_edit,
      'learning-lessons': isShowMenu(permissions?.lessons_lesson_lessons),
      'lessons-list': isShowMenu(permissions?.lessons_lesson_lessons),
      'users-list': isShowMenu(permissions?.users_student_students),
      'edit-student': !!permissions?.users_student_students.can_edit,
      'create-lesson': !!permissions?.lessons_lesson_lessons.can_edit,
      'edit-lesson': !!permissions?.lessons_lesson_lessons.can_edit,
      'courses-list': isShowMenu(permissions?.courses_course_courses),
      'courses-creation-page': !!permissions?.courses_course_courses.can_edit,
      'courses-edit-page': !!permissions?.courses_course_courses.can_edit,
      'subjects-list': isShowMenu(permissions?.subjects_subject_subjects),
      'subjects-create': !!permissions?.subjects_subject_subjects.can_edit,
      'subjects-edit': !!permissions?.subjects_subject_subjects.can_edit,
      'themes-list': isShowMenu(permissions?.subjects_theme_themes),
      'themes-create': !!permissions?.subjects_theme_themes.can_edit,
      'themes-edit': !!permissions?.subjects_theme_themes.can_edit,
      'resources-list': isShowMenu(permissions?.media_studyresource_study_resources),
      'resources-create': !!permissions?.media_studyresource_study_resources.can_edit,
      'resources-edit': !!permissions?.media_studyresource_study_resources.can_edit,
      'files-list': isShowMenu(permissions?.media_media_media),
      'labels-list': isShowMenu(permissions?.assignments_label_labels),
      'tags-list': isShowMenu(permissions?.assignments_olympiadtag_olympiad_tag),
      'employers-list': isShowMenu(permissions?.users_employee_admin),
      'create-employee': !!permissions?.users_employee_admin.can_edit,
      'edit-employee': !!permissions?.users_employee_admin.can_edit,
      'news-list': isShowMenu(permissions?.content_news_news),
      'create-news': !!permissions?.content_news_news.can_edit,
      'edit-news': !!permissions?.content_news_news.can_edit,
      'faq-list': isShowMenu(permissions?.content_faq_faq),
      'create-question': !!permissions?.content_faq_faq.can_edit,
      'edit-question': !!permissions?.content_faq_faq.can_edit,
      'results-courses': isShowMenu(permissions?.results_courseresult_courses_results),
      'results-courses-list': isShowMenu(permissions?.results_courseresult_courses_results),
      'results-courses-student-list': !!permissions?.results_courseresult_courses_results.can_view,
      'results-courses-check-list': !!permissions?.results_courseresult_courses_results.can_edit,
      'courses-application': isDefaultShow,
      'courses-application-list': isDefaultShow,
      'courses-application-edit-page': isDefaultShow,
      'results-olympiad-tasks': !!permissions?.results_olympiadresult_olympiad_results?.can_view,
      'results-tests-all': !!permissions?.results_testresult_tests_results?.can_view,
      'results-tests-students': !!permissions?.tests_sampleitem_sample_items?.can_view,
      'slides-list': isShowMenu(permissions?.content_slider_slider),
      'slides-create': !!permissions?.content_slider_slider.can_edit,
      'slides-edit': !!permissions?.content_slider_slider.can_edit,
      'banners-list': isShowMenu(permissions?.content_banner_banner),
      'banners-create': !!permissions?.content_banner_banner.can_edit,
      'banners-edit': !!permissions?.content_banner_banner.can_edit,
      'achievement-page': isDefaultShow,
      'hobbies-page': isDefaultShow,
      'groups-list': isShowMenu(permissions?.users_group_groups),
      'create-group': !!permissions?.users_group_groups.can_edit,
      'edit-group': !!permissions?.users_group_groups.can_edit,
      'instructions-list': isDefaultShow,
      'instruction-create': isDefaultShow,
      'instruction-edit': isDefaultShow,
      'proctors-list': isDefaultShow,
      'proctors-create': isDefaultShow,
      'proctors-edit': isDefaultShow,
      'part-time-exams-tests-list': !!permissions?.exams_ptexamtest_ptexams.can_view,
      'part-time-exams-tests-edit': !!permissions?.exams_ptexamtest_ptexams.can_edit,
      'part-time-exams-tests-create': !!permissions?.exams_ptexamtest_ptexams.can_edit,
      'exams-appointment-list': !!permissions?.exams_ptappointment_ptappointments.can_view,
      'exams-appointment-edit': !!permissions?.exams_ptappointment_ptappointments.can_edit,
      'exams-appointment-create': !!permissions?.exams_ptappointment_ptappointments.can_edit,
      'exams-attempts-list': !!permissions?.exams_ptattempt_ptattempts.can_view,
      'exams-answers-list': !!permissions?.exams_ptattempt_ptattempts.can_view,
      'exam-tasks-list': !!permissions?.assignments_examassignment_assignments.can_view,
      'exam-tasks-create': !!permissions?.assignments_examassignment_assignments.can_edit,
      'exam-tasks-edit': !!permissions?.assignments_examassignment_assignments.can_edit,
      'preview-task-exam-assignment': isShowMenu(
        permissions?.assignments_lessonassignment_assignments
      ),
      'full-appointment-list': !!permissions?.exams_appointment_appointments.can_view,
      'full-appointment-create': !!permissions?.exams_appointment_appointments.can_edit,
      'full-appointment-edit': !!permissions?.exams_appointment_appointments.can_edit,
      'full-exam-tests-list': !!permissions?.exams_examtest_exams.can_view,
      'full-exam-test-create': !!permissions?.exams_examtest_exams.can_edit,
      'full-exam-test-edit': !!permissions?.exams_examtest_exams.can_edit,
      'exams-full-attempts-list': !!permissions?.exams_attempt_attempts.can_view,
      'exams-full-answers-list': !!permissions?.exams_attempt_attempts.can_view,
      'comments-on-lessons-list': isDefaultShow,
      'subscribers-on-email': isDefaultShow,
      'certificates-generator': isDefaultShow,
      'certificates-generator-table': isDefaultShow,
      'certificates-generator-create-template': isDefaultShow,
      'certificates-generator-create-certificates': isDefaultShow,
    }
  }
)

guard({
  source: getSelfUserFx.failData,
  filter: (data) => {
    const requestUrl: string = data?.config?.url || ''
    if (!requestUrl) return false
    return (
      (data?.status === 500 && requestUrl.includes('/token/')) ||
      requestUrl.includes('employee/me/')
    )
  },
  target: logout,
})
forward({
  from: logout,
  to: [
    modalLogoutVisibilityChanged.prepend(() => false),
    setTokenForRequest.prepend(() => {
      Cookies.remove(config.TOKEN_EXP)
      Cookies.remove(config.TOKEN_KEY)
      return ''
    }),
    setTokenForRefresh.prepend(() => {
      Cookies.remove(config.REFRESH_TOKEN_EXP)
      Cookies.remove(config.REFRESH_TOKEN_KEY)
      return ''
    }),
    navigatePush.prepend(() => ({ name: 'auth.login' })),
  ],
})
