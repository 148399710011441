import { GetListQueryParams } from '@/features/api/types'
import { DropdownItem } from '@/pages/common/types'

export type EmployeeRole = 'SUPER_ADMIN' | 'ADMIN' | 'METHODIST'

export type EmployeePermissionsType =
  | 'tickets_moderationticket_income_tickets'
  | 'tickets_moderationticket_outcome_tickets'
  | 'tickets_deletionticket_income_tickets'
  | 'tickets_deletionticket_outcome_tickets'
  | 'assignments_testassignment_difficulty_base'
  | 'assignments_testassignment_difficulty_pro'
  | 'assignments_testassignment_difficulty_exam'
  | 'assignments_label_labels'
  | 'assignments_olympiadassignment_assignments'
  | 'assignments_olympiadtag_olympiad_tag'
  | 'assignments_olympiadclue_olympiad_clue'
  | 'assignments_lessonassignment_assignments'
  | 'tests_test_tests'
  | 'lessons_lesson_lessons'
  | 'courses_course_courses'
  | 'results_courseresult_courses_results'
  | 'results_olympiadresult_olympiad_results'
  | 'results_testresult_tests_results'
  | 'tests_sampleitem_sample_items'
  | 'users_employee_super_admin'
  | 'users_employee_admin'
  | 'users_student_students'
  | 'users_student_export'
  | 'users_group_groups'
  | 'users_studyyear_study_years'
  | 'subjects_subject_subjects'
  | 'subjects_theme_themes'
  | 'media_media_media'
  | 'media_studyresource_study_resources'
  | 'content_customization_customization'
  | 'content_banner_banner'
  | 'content_slider_slider'
  | 'content_faq_faq'
  | 'content_news_news'
  | 'exams_appointment_appointments'
  | 'exams_attempt_attempts'
  | 'exams_attempt_export_archive'
  | 'exams_examtest_exams'
  | 'assignments_examassignment_assignments'
  | 'exams_ptexamtest_ptexams'
  | 'exams_ptappointment_ptappointments'
  | 'exams_ptattempt_ptattempts'

export type EmployeePermissions = {
  [K in EmployeePermissionsType]: {
    name: string
    available_to_methodist: boolean
    available_to_admin: boolean
    available_to_superadmin: boolean
    can_edit: boolean
    can_view: boolean
  }
}

export type Employee = {
  id: number
  password: string
  first_name: string
  last_name: string
  email: string
  role: EmployeeRole
  is_active: boolean
  date_joined: string
  internal_cms_comment: string
  available_subjects: number[]
  available_study_years: number[]
  available_groups: number[]
  available_courses: number[]
  available_subjects_all: boolean
  available_study_years_all: boolean
  available_groups_all: boolean
  available_courses_all: boolean
  permissions: EmployeePermissions
}

export type CreateEmployeeType = Omit<Employee, 'role' | 'permissions'> & {
  role: string
  permissions: EmployeePermissions | Record<string, never>
}

export type EmployeeList = {
  id?: number
  password?: string
  first_name?: string
  last_name?: string
  email?: string
  role?: EmployeeRole
  is_active?: boolean
  date_joined?: string
  employee_comment?: string
  full_name?: string
}

export type RequestDeleteEmployees = {
  employees: number[]
  ticket_comment?: string
}

export interface GetEmployeeListQueryParams extends EmployeeList, GetListQueryParams {}

export enum EditEmployeeFields {
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  password = 'password',
  active_user = 'is_active',
  role = 'role',
  internal_cms_comment = 'internal_cms_comment',
  available_subjects = 'available_subjects',
  available_study_years = 'available_study_years',
  available_groups = 'available_groups',
  available_courses = 'available_courses',
  available_subjects_all = 'available_subjects_all',
  available_study_years_all = 'available_study_years_all',
  available_groups_all = 'available_groups_all',
  available_courses_all = 'available_courses_all',
  ro_available_courses = 'ro_available_courses',
  ro_available_groups = 'ro_available_groups',
  ro_available_study_years = 'ro_available_study_years',
  ro_available_subjects = 'ro_available_subjects',
}

export type ChangeEmployeeField = {
  value: string | DropdownItem
  type: EditEmployeeFields
}

export type CreateEmployeeTableCell = {
  id: number
  type: string
  name: string
  available_to_methodist: boolean
  available_to_admin: boolean
  available_to_superadmin: boolean
  can_edit: boolean
  can_view: boolean
}

type TableColumn = 'can_edit' | 'can_view'

export type CreateEmployeeTable = {
  data: CreateEmployeeTableCell[]
}

export type ChangeTableColumn = {
  data: CreateEmployeeTableCell
  event: MouseEvent
  type: TableColumn
}
