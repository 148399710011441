import { createApiEffect } from '@/features/api/common/create-api-effect'
import { NewsList } from '@/features/api/content/types'

export const updateNewsByIdFx = createApiEffect<{ body: Partial<NewsList>; id: string }, void>({
  requestMapper: ({ body, id }) => ({
    method: 'PATCH',
    url: `/api/content-app/news/${id}/`,
    body,
  }),
})
