import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { themesDropdownModule } from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import { typeDropdownModule } from '@/pages/dictionary/resources/list/parts/resources-filter/parts/type/type-dropdown.model'

export const dropdownModules = {
  classesDropdownModule,
  subjectsDropdownModel,
  themesDropdownModule,
  typeDropdownModule,
}
