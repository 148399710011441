





























import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
} from '@/pages/testing/tests/common/parts/modals/test-edit-task/test-edit-task-modal.model'
import {
  $currentEditTasks,
  $tasksBlock,
  setTasksBlock,
} from '@/pages/common/parts/tests/manual-generation/manual-generation-form.model'
import ThemesDropdown from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/ThemesDropdown.vue'
import TestsAssignmentDropdown from '@/pages/common/dropdowns/multiselectDropdown/tests-assignment-dropdown-filter/TestsAssignmentDropdown.vue'
import { testsAssignmentDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/tests-assignment-dropdown-filter/tests-assignment-dropdown.model'
import {
  resetSelectedThemes,
  $selectedThemes,
} from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'
import { TaskBlock } from '@/pages/common/parts/tests/types'

export default Vue.extend({
  name: 'TestEditTaskModal',
  components: {
    Modal,
    BaseButton,
    ThemesDropdown,
    TestsAssignmentDropdown,
  },
  effector: {
    $modalVisibility,
    $currentEditTasks,
    $tasksBlock,
    $selectedTestsAssignment: testsAssignmentDropdownModel.store.$selectedItems,
    $selectedThemes,
  },
  methods: {
    ...testsAssignmentDropdownModel.methods,
    modalVisibilityChanged,
    setTasksBlock,
    handleCancel() {
      this.modalVisibilityChanged(false)
      resetSelectedThemes()
      this.resetSelectedItems()
    },
    saveTests() {
      const blocks: TaskBlock[] = this.$tasksBlock.map((block) => {
        if (this.$currentEditTasks && block.id !== this.$currentEditTasks.id) return block
        return { ...block, tasks: this.$selectedTestsAssignment, theme: this.$selectedThemes[0] }
      })
      setTasksBlock(blocks)
      this.handleCancel()
    },
  },
})
