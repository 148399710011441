













import Vue from 'vue'
import PageHeader from '@/pages/common/PageHeader.vue'
// import PageFooter from '@/pages/common/PageFooter.vue'
import ModalLogout from '@/pages/common/modal-logout/ModalLogout.vue'
import Navigation from '@/pages/common/navigation/Navigation.vue'
import { $isOpened, updateApplicationsCounters } from '@/pages/common/navigation/navigation.model'
import Cookies from 'js-cookie'
import { config } from '@/config'
import { logout, $layoutPadding } from '@/features/session'
import axios from 'axios'
import { setTokenForRequest } from '@/features/api/common/request'
import dayjs from 'dayjs'
import { $isCheckTimeout, setIsCheckTimeout } from '@/features/session/chechRefresh'
import resetAccessAndRefreshTokens from '@/lib/request/utils/resetAccessAndRefreshTokens'

export default Vue.extend({
  name: 'DashboardLayout',
  components: { PageHeader, Navigation, ModalLogout },
  effector: {
    $isOpened,
    $isCheckTimeout,
    $layoutPadding,
  },
  data() {
    return {
      contentWidth: 'calc(100vw - 240px)',
      interval: null,
    }
  },
  watch: {
    $isOpened: {
      handler() {
        this.setContentWidth()
      },
    },
  },
  methods: {
    setContentWidth() {
      const scrollContainer = this.$parent.$el as HTMLElement
      const scrollbarWidth = scrollContainer.offsetWidth - scrollContainer.clientWidth
      if (this.$isOpened) {
        this.contentWidth = `calc(100vw - ${scrollbarWidth}px - 240px)`
      } else {
        this.contentWidth = `calc(100vw - ${scrollbarWidth}px - 70px)`
      }
    },
    checkToken() {
      const token = Cookies.get(config.TOKEN_KEY)
      const tokenExp = Cookies.get(config.TOKEN_EXP)
      const refreshToken = Cookies.get(config.REFRESH_TOKEN_KEY)
      const refreshTokenExp = Cookies.get(config.REFRESH_TOKEN_EXP)
      if (token && !this.isReadyToken(tokenExp, 600)) {
        if (refreshToken && this.isReadyToken(refreshTokenExp)) {
          this.getToken()
        } else {
          logout()
        }
      }
    },
    async getToken() {
      const refreshToken = Cookies.get(config.REFRESH_TOKEN_KEY)

      axios
        .post(`${config.BACKEND_URL}/api/user-app/token/refresh/`, {
          refresh: refreshToken,
        })
        .then((response) => {
          const { access } = response.data

          resetAccessAndRefreshTokens(access)
          setTokenForRequest(access)
        })
        .catch(() => {
          logout()
        })
    },
    isReadyToken(exp: string | undefined, correct = 0): boolean {
      if (!exp) return false
      const expDate = new Date((+exp - correct) * 1000)
      return dayjs(new Date()).isBefore(dayjs(expDate))
    },
  },
  mounted() {
    if (!this.$isCheckTimeout) {
      setIsCheckTimeout(true)
      this.checkToken()
      setInterval(() => {
        this.checkToken()
      }, 60000)
    }
    window.addEventListener('resize', this.setContentWidth)
    this.setContentWidth()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setContentWidth)
  },
  beforeUpdate() {
    updateApplicationsCounters()
  },
})
