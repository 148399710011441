import Cookies from 'js-cookie'
import { config } from '@/config'

const jwt = require('jsonwebtoken')

const resetAccessAndRefreshTokens = (accessToken: string): void => {
  Cookies.set(config.TOKEN_EXP, jwt.decode(accessToken).exp)
  Cookies.set(config.TOKEN_KEY, accessToken)
}

export default resetAccessAndRefreshTokens
