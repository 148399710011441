import { attach, combine, createEvent, createStore, forward } from 'effector-root'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { getNewsListFx } from '@/features/api/content/news/get-news-list'
import { requestDeleteNewsFx } from '@/features/api/content/news/delete-news'
import { RequestDeleteNews } from '@/features/api/content/types'

const getNewsList = attach({
  effect: getNewsListFx,
})

export const newsPageMounted = createEvent<void>()

export const $isLoading = combine(getNewsListFx.pending, (list) => list)

export const requestDeleteNews = attach({
  effect: requestDeleteNewsFx,
  mapParams: (payload: RequestDeleteNews) => {
    return {
      news: payload.news,
      ticket_comment: payload.ticket_comment,
    }
  },
})

const needRefresh = createEvent<boolean>()

forward({
  from: requestDeleteNews.done,
  to: [
    getNewsList,
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    needRefresh.prepend(() => true),
  ],
})

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(newsPageMounted)

forward({
  from: newsPageMounted,
  to: getNewsList,
})
