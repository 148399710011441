








































import Vue from 'vue'
import TaskHeader from '@/pages/bank/common/parts/Header.vue'
import TaskContent from '@/pages/bank/test-tasks/edit/parts/Content.vue'
import TaskFooter from '@/pages/bank/common/parts/Footer.vue'
import ModeratorSelectModal from '@/pages/bank/common/modals/moderator-select/ModeratorSelectModal.vue'
import {
  save,
  $canSave,
  loadTask,
  setRedirectAfterSave,
  clearFields,
  $isPreview,
  toggleIsPreview,
  $taskId,
  loadApplication,
} from '@/pages/bank/test-tasks/edit/task-edition-page.model'
import { $token } from '@/features/api/common/request'
import {
  loadModalToSendForCheck,
  $canRefreshAfterSendingToReview,
} from '@/pages/bank/common/modals/moderator-select/moderator-select.model'
import { $status } from '@/pages/common/parts/status-controller/status.model'
import { acceptApplicationsFx } from '@/pages/applications/incoming/incoming-applications-page.model'
import {
  $canRefreshAfterSendingToRevision,
  loadModal,
} from '@/pages/applications/modals/send-for-moderation/send-for-moderation.model'
import SendForModerationModal from '@/pages/applications/modals/send-for-moderation/SendForModerationModal.vue'
import { loadCommentModal } from '@/pages/applications/modals/outgoing-comment/outgoing-comment.model'
import OutgoingModal from '@/pages/applications/modals/outgoing-comment/OutgoingComment.vue'
import SelectTask from '@/pages/preview-tasks/parts/select-task/SelectTask.vue'
import {
  $currentIndex,
  $currentQuestion,
  setCurrentQuestion,
} from '@/pages/preview-tasks/parts/select-task/select-task.model'
import { combineRouteQueries } from '@/features/lib'
import { FromPage } from '@/pages/common/types'
import { navigateReplace } from '@/features/navigation/navigationMethods'
import { updateTestAssignmentFx } from '@/features/api/assignment/test-assignment/update-test-assignment'
import { updateTestAssignmentBulkFx } from '@/features/api/assignment/test-assignment/update-test-assignment-bulk'
import { DEFAULT_ID } from '@/pages/common/constants'
import {
  $labelsLoaded,
  toggleLabelsLoaded,
} from '@/pages/common/dropdowns/labels/labels-dropdown.model'

export default Vue.extend({
  name: 'TaskEditionPage',
  components: {
    TaskHeader,
    TaskContent,
    TaskFooter,
    SelectTask,
    ModeratorSelectModal,
    SendForModerationModal,
    OutgoingModal,
  },
  effector: {
    $canSave,
    $isPreview,
    $taskId,
    $token,
    $status,
    $canRefreshAfterSendingToReview,
    $canRefreshAfterSendingToRevision,
    $currentIndex,
    $currentQuestion,
    $labelsLoaded,
    isLoading: updateTestAssignmentFx.pending || updateTestAssignmentBulkFx.pending,
  },
  data: () => ({
    questions: [] as string[],
    fromPage: '' as FromPage,
    applications: [] as number[],
  }),
  computed: {
    taskId(): number {
      if (this.questions.length) {
        return +this.questions[this.$currentIndex]
      }
      return +this.$route.params.id
    },
  },
  watch: {
    $isPreview: {
      handler(newVal) {
        if (newVal) {
          navigateReplace({
            name: 'preview-task',
            query: {
              questions: this.questions.length ? this.questions.join(',') : this.$route.params.id,
              applications: this.applications.join(','),
              fromPage: this.fromPage,
              taskType: 'test-assignment',
              currentQuestion: String(this.$currentQuestion),
              token: this.$token,
            },
          })
        }
      },
    },
    $currentIndex(value) {
      if (value !== $currentIndex) {
        this.refreshPage()
      }
    },
    $canRefreshAfterSendingToReview(value) {
      if (value) {
        this.refreshPage()
      }
    },
    $canRefreshAfterSendingToRevision(value) {
      if (value) {
        this.refreshPage()
      }
    },
  },
  methods: {
    loadTask,
    toggleIsPreview,
    setCurrentQuestion,
    sendToReview() {
      loadModalToSendForCheck([this.$taskId])
    },
    saveTask(isRedirect: boolean) {
      save()
      if (isRedirect) {
        setRedirectAfterSave(true)
      }
    },
    async acceptApplications() {
      await acceptApplicationsFx({ tickets: [this.applications[this.$currentIndex]] })
      this.refreshPage()
    },
    sendToRevision() {
      loadModal([this.applications[this.$currentIndex]])
    },
    refreshPage() {
      if (
        this.$currentIndex !== DEFAULT_ID &&
        this.$taskId !== +this.questions[this.$currentIndex] &&
        !!this.questions.length
      ) {
        loadTask(+this.questions[this.$currentIndex])
        if (this.applications.length) loadApplication(this.applications[this.$currentIndex])
      }
    },
    showComments() {
      loadCommentModal(this.applications[this.$currentIndex])
    },
    goToSelectedTask() {
      if (this.$route.query.currentQuestion !== String(this.$currentQuestion)) {
        toggleLabelsLoaded(false)
        this.$router.replace(
          combineRouteQueries(this.$route.query, { currentQuestion: String(this.$currentQuestion) })
        )
      }
    },
  },
  created() {
    const { questions, applications, fromPage, currentQuestion } = this.$route.query

    if (questions && typeof questions === 'string') {
      this.questions = questions.split(',')
    }
    if (applications && typeof applications === 'string') {
      this.applications = applications.split(',').map((appId) => Number(appId))
      if (this.$currentIndex !== DEFAULT_ID) loadApplication(this.applications[this.$currentIndex])
    }
    if (
      fromPage &&
      (fromPage === 'applications' || fromPage === 'tasks' || fromPage === 'labels')
    ) {
      this.fromPage = fromPage
    }
    if (currentQuestion && typeof +currentQuestion === 'number') {
      setCurrentQuestion(+currentQuestion)
    }
    if (this.$taskId !== this.taskId && this.taskId) {
      loadTask(this.taskId)
    }
  },
  beforeMount() {
    if (this.$route.query.togglePreview === 'toggle') {
      toggleIsPreview(true)
    }
  },
  beforeDestroy() {
    clearFields()
    toggleIsPreview(false)
  },
})
