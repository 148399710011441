







































import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import {
  $appointmentsCmsCommentary,
  $appointmentsIsProctoringActivated,
  $nameCmsCommentary,
  setAppointmentsCmsCommentary,
  setAppointmentsIsProctoringActivated,
  setNameCmsCommentary,
} from '@/pages/exams/appointments/create/model/form-fileds.model'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import ProctorsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/ProctorsFilterDropdown.vue'

export default Vue.extend({
  name: 'AppointmentsPage1',
  components: {
    FormInput,
    BaseSwitch,
    ProctorsFilterDropdown,
  },
  effector: {
    $nameCmsCommentary,
    $appointmentsCmsCommentary,
    $appointmentsIsProctoringActivated,
  },
  methods: {
    setNameCmsCommentary,
    setAppointmentsCmsCommentary,
    setAppointmentsIsProctoringActivated,
  },
})
