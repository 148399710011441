import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportCommentsOnLessonsQueryParams } from '@/features/api/callback/types'

export const exportCommentsOnLessonsListFX = createApiEffect<
  ExportCommentsOnLessonsQueryParams,
  Blob
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/lesson-app/lessons/commentaries/list/export/',
    query,
    responseType: 'arraybuffer',
  }),
})
