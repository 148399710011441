



























import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalLogoutVisibility,
  modalLogoutVisibilityChanged,
} from '@/pages/common/modal-logout/modal-logout.model'
import { logout } from '@/features/session/index'

export default Vue.extend({
  name: 'ModalLogout',
  components: {
    Modal,
    BaseButton,
  },
  effector: {
    $modalLogoutVisibility,
  },
  methods: {
    modalLogoutVisibilityChanged,
    logout,
  },
})
