import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'

export const proctorsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'ID проктора',
    title: 'ID',
    width: '85px',
  },
  {
    name: 'is_active',
    sortField: 'is_active',
    titleClass: 'wrap',
    title: 'Действующий аккаунт',
    width: '110px',
    formatter(isActive: boolean) {
      return isActive ? 'Да' : 'Нет'
    },
  },
  {
    name: 'need_add',
    sortField: 'need_add',
    titleClass: 'wrap',
    title: 'Добавлять в список прокторов при каждом назначении экзамена',
    width: '230px',
    formatter(needAdd: boolean) {
      return needAdd ? 'Да' : 'Нет'
    },
  },
  {
    name: 'login',
    sortField: 'login',
    titleClass: 'wrap',
    title: 'Логин в прокторинге',
    width: '100px',
  },
  {
    name: 'password',
    sortField: 'password',
    titleClass: 'wrap',
    title: 'Пароль в прокторинге',
    width: '100px',
  },
  {
    name: 'cms_commentary',
    sortField: 'cms_commentary',
    title: 'Заметка CMS',
    width: '100px',
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '100px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Обновлено',
    width: '100px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : ''
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [{ name: 'all', title: 'Искать везде' }]
