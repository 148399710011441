






import Vue from 'vue'

export default Vue.extend({
  name: 'ContextCell',
  props: {
    rowId: { type: Number, required: true },
    dataId: { type: String, default: '' },
  },
  methods: {
    handleRightClick(event: any) {
      event.preventDefault()
      this.$emit('onRightClick', { data: { id: this.$props.rowId }, event })
    },
  },
  mounted() {
    const container = document.querySelector(`#cell-${this.$props.rowId}-${this.$props.dataId}`)
    container && container.addEventListener('contextmenu', this.handleRightClick)
  },
  beforeDestroy() {
    const container = document.querySelector(`#cell-${this.$props.rowId}-${this.$props.dataId}`)
    container && container.removeEventListener('contextmenu', this.handleRightClick)
  },
})
