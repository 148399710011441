import { attach, createEvent, forward, combine, createStore } from 'effector-root'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { getEmployeesListFx } from '@/features/api/employee/get-employee-list'
import { requestDeleteEmployeesFx } from '@/features/api/employee/delete-employees'
import { RequestDeleteEmployees } from '@/features/api/employee/types'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { exportEmployeesListFx } from '@/features/api/employee/export-employee-results'
import { employeesFilters } from '@/pages/users/employes/list/model/user-employees-filters.model'
import fileDownload from 'js-file-download'

const getEmployeesList = attach({
  effect: getEmployeesListFx,
  mapParams: () => ({}),
})

export const employeesPageMounted = createEvent<void>()

export const $isLoading = combine(getEmployeesListFx.pending, (list) => list)

export const requestDeleteEmployees = attach({
  effect: requestDeleteEmployeesFx,
  mapParams: (payload: RequestDeleteEmployees) => {
    return {
      employees: payload.employees,
      ticket_comment: payload.ticket_comment,
    }
  },
})

const needRefresh = createEvent<boolean>()

forward({
  from: requestDeleteEmployees.done,
  to: [
    getEmployeesList,
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    needRefresh.prepend(() => true),
  ],
})

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(employeesPageMounted)

forward({
  from: employeesPageMounted,
  to: getEmployeesList,
})

export const exportEmployeesList = attach({
  effect: exportEmployeesListFx,
  source: [employeesFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportEmployeesList.doneData.watch((res) => fileDownload(res.body, 'employeesList.xlsx'))
