




























import Vue from 'vue'
import ExamTestsHeader from '@/pages/exams/full-time-exam-test/create/parts/ExamTestsHeader.vue'
import ExamTestsFooter from '@/pages/exams/full-time-exam-test/create/parts/ExamTestsFooter.vue'
import CreateExamTestTab1 from '@/pages/exams/full-time-exam-test/create/parts/CreateExamTestTab1.vue'
import CreateExamTestTab2 from '@/pages/exams/full-time-exam-test/create/parts/CreateExamTestTab2.vue'
import Tabs from '@/pages/common/parts/tabs/Tabs.vue'
import {
  changeExamTestID,
  getPageParams,
  setRedirectAfterSave,
} from '@/pages/exams/full-time-exam-test/create/model/create-exam-tests.model'
import { createExamTestFx } from '@/features/api/full-time-tests/create-exam-test'
import { editExamTestFx } from '@/features/api/full-time-tests/edit-exam-test'
import { resetFields } from '@/pages/exams/full-time-exam-test/create/model/form-fields.model'
import { setLayoutPadding } from '@/features/session'

export default Vue.extend({
  name: 'CreateExamTestPage',
  components: {
    ExamTestsFooter,
    ExamTestsHeader,
    CreateExamTestTab1,
    CreateExamTestTab2,
    Tabs,
  },
  effector: {
    $disabledSaveButtons: createExamTestFx.pending || editExamTestFx.pending,
  },
  data() {
    return {
      tab: 1,
      tabs: [
        {
          tab: 1,
          title: 'Шаг 1.  Сопроводительное описание и настройки экзамена',
        },
        {
          tab: 2,
          title: 'Шаг 2. Состав экзамена',
        },
      ],
    }
  },
  methods: {
    savePartTimeTest(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      getPageParams()
    },
    setTab(tab: number) {
      this.tab = tab
    },
  },
  created() {
    changeExamTestID(this.$route.params.id)
  },
  beforeMount() {
    setLayoutPadding('30px 30px 10px 90px')
  },
  beforeDestroy() {
    setLayoutPadding('30px')
    resetFields()
  },
})
