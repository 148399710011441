import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteProctors } from '@/pages/exams/proctors/list/model/types'

export const requestDeleteProctorsFx = createApiEffect<RequestDeleteProctors, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/exams-app/proctors/bulk-delete/',
    body,
  }),
})
