












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  statusDropdownModule,
  loadStatus,
  $status,
} from '@/pages/common/dropdowns/bank/olympiad-tasks/status-dropdown/status-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    $status,
    ...statusDropdownModule.store,
  },
  methods: {
    ...statusDropdownModule.methods,
    loadStatus,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (this.$status.length === 0) loadStatus()
  },
})
