import { createEvent, restore } from 'effector-root'
import { reset } from '@/pages/results/olympiad-results/model/olympiad-results-filter.model'

export const toggleModalVisibility = createEvent<boolean>()
export const $modalVisibility = restore(toggleModalVisibility, false).reset(reset)

export const setCorrectAnswer = createEvent<any>()
export const $correctAnswer = restore(setCorrectAnswer, null).reset(reset)

export const setStudentAnswer = createEvent<any>()
export const $studentAnswer = restore(setStudentAnswer, null).reset(reset)
