


























































import { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { TreeData, TreeElementType } from '@/features/api/types'
import { loadModal } from '@/pages/labels/parts/modals/tasks/tasks.model'
import { loadModalToEdit } from '@/pages/labels/parts/modals/label-edition/label-edition.modal'
import { createLabelFromTree } from '@/pages/labels/parts/modals/label-creation/label-creation.model'
import { getFileForPreview, sortTreeLeaves } from '@/features/lib'
import {
  $openedTreeBranches,
  storeOpenedBranch,
} from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { UploadMediaResponse } from '@/features/api/media/types'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { FiltersParams, TreeNodeIdProp } from '@/pages/common/types'
import AutoOpenFolderMixin from '@/features/lib/mixins/AutoOpenFolderMixin'
import { FolderType } from '@/features/api/assignment/types/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { isBranchOpened } from '@/pages/common'
import { loadModal as loadPreviewFileModal } from '@/ui/preview-file/preview-file.model'
import PreviewFile from '@/ui/preview-file/PreviewFile.vue'

export default AutoOpenFolderMixin({
  filters: {},
}).extend({
  name: 'TreeNode',
  components: {
    PreviewFile,
    Icon,
    ActionsButton,
  },
  props: {
    node: { type: Object as PropType<TreeData> },
    parent: { type: Boolean, default: false },
    prerequisiteFolder: { type: Boolean, default: false },
    nodeId: { type: [Number, String] as PropType<TreeNodeIdProp> },
    showPaste: { type: Boolean },
    filters: { type: Object as PropType<FiltersParams> },
    token: { type: String },
    firstChild: { type: Boolean, default: false },
    lastChild: { type: Boolean, default: false },
    parentNodeId: { type: [Number, String] as PropType<TreeNodeIdProp>, default: null },
    parentNodeType: { type: String as PropType<TreeElementType>, default: null },
  },
  data: () => ({
    opened: false,
  }),
  computed: {
    title() {
      const entity = this.node[this.node.element_type]
      let fullName = ''
      if (this.node.element_type === 'folder' || this.node.element_type === 'media')
        fullName =
          (entity && ((entity as FolderType).name || (entity as UploadMediaResponse).file_name)) ||
          ''
      if (fullName.length > 100) {
        fullName = `${fullName.slice(0, 100)}...`
      }
      return fullName
    },
    showActions() {
      const { element_type } = this.$props.node
      return ['media', 'folder'].includes(element_type)
    },
    nodeLeavesLength(): number {
      return this.node.leaves.length
    },
  },
  watch: {
    opened: {
      handler(newVal) {
        if (newVal) {
          this.node.leaves = sortTreeLeaves(this.node.leaves)
          this.loadFolderData()
        }
        storeOpenedBranch({
          id: this.nodeId,
          opened: newVal as boolean,
          type: this.node.element_type,
          parentNodeId: this.parentNodeId,
          parentNodeType: this.parentNodeType,
        })
      },
    },
    nodeLeavesLength: {
      handler(newVal) {
        if (newVal) this.node.leaves = sortTreeLeaves(this.node.leaves)
      },
    },
  },
  methods: {
    loadModal,
    loadModalToEdit,
    createLabelFromTree,
    loadConfirmDeleteModal,
    toggle(evt: any) {
      if (evt.target.closest('.action')) return
      this.downloadFile()
      this.opened = !this.opened
    },
    downloadFile() {
      if (this.node.element_type === 'media') {
        this.handleOnDownload()
      }
    },
    loadFolderData() {
      if (this.node.element_type === 'folder') {
        const { id } = this.node[this.node.element_type]!
        this.$emit('loadTree', { folder: id, file_type: this.filters.file_type })
      }
    },
    getType(): ContextMenuType {
      if (this.node.element_type !== 'folder') return 'item'
      return this.node.element_type
    },
    treeActionsButtonClick(event: MouseEvent) {
      this.$emit('onActionsClick', {
        data: {
          id: (this.node.media && this.node.media.id) || this.node.folder!.id,
          name: this.title,
        },
        event,
        type: this.getType(),
      })
    },
    handleRightClick(event: Event) {
      this.$emit('onRightClick', {
        data: {
          id: this.$props.nodeId,
          name: this.title,
          isFolder: this.$props.node.element_type === 'folder',
        },
        event,
        type: this.getType(),
      })
    },
    handleOnDownload() {
      const { file } = this.node[this.node.element_type] as UploadMediaResponse
      const { file_name } = this.node[this.node.element_type] as UploadMediaResponse
      getFileForPreview(file).then((blob) => {
        if (!blob) {
          return
        }

        const splitFileName = file_name.split('.')
        loadPreviewFileModal({
          file: blob,
          format: splitFileName[splitFileName.length - 1],
          filename: splitFileName.slice(0, -1).join(''),
        })
      })
    },
  },
  mounted() {
    if (['folder', 'media'].includes(this.node.element_type)) {
      const nodeElement = document.querySelector(`#node-${this.node.element_type}-${this.nodeId}`)
      nodeElement && nodeElement.addEventListener('contextmenu', this.handleRightClick)
    }
    this.opened = isBranchOpened(
      $openedTreeBranches,
      this.nodeId,
      this.node.element_type,
      this.parentNodeId,
      this.parentNodeType
    )
    if (this.filters.search) {
      this.searchString = this.filters.search_area
        ? this.filters.search_area.slice(this.filters.search_area?.indexOf('_') + 1)
        : ''
      this.autoOpenFolders([this.node])
    }
  },
  beforeDestroy() {
    if (['folder', 'media'].includes(this.node.element_type)) {
      const nodeElement = document.querySelector(`#node-${this.node.element_type}-${this.nodeId}`)
      nodeElement && nodeElement.removeEventListener('contextmenu', this.handleRightClick)
    }
  },
})
