import { createApiEffect } from '@/features/api/common/create-api-effect'
import { UploadProctorResponse } from '@/features/api/full-appointments/types'

export const uploadProctorsFx = createApiEffect<FormData, UploadProctorResponse>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/exams-app/appointments/import_attempts/',
    body,
  }),
})
