import { attach, combine, createEvent, createStore, forward, restore, sample } from 'effector-root'
import { areAssignmentsIdsValid, formatTasksIDS } from '@/lib/validators/assignments-list'
import { createError } from '@/lib/effector/error-generator'
import { addToast, errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { statusDropdownModule } from '@/pages/common/dropdowns/courses/status-dropdown/status-dropdown.model'
import { condition } from 'patronum'
import {
  ApplicationsCoursesBulkParams,
  UpdateApplicationsCoursesBulkFailResponse,
} from '@/features/api/learning/course-applications/types'
import { updateCourseApplicationsBulkFx } from '@/features/api/learning/course-applications/update-course-applications-bulk'
import { CoursesUpdateForm } from '@/pages/learning/learning-courses-application/list/parts/modals/application-course-update/types'

const makeMultiChanges = attach({
  effect: updateCourseApplicationsBulkFx,
})
export const loadModalForMultiChanges = createEvent<number[]>()

export const modalVisibilityChanged = createEvent<boolean>()
export const $modalVisibility = restore(modalVisibilityChanged, false)

export const canRefreshAfterMultiChangesChanged = createEvent<boolean>()
export const $canRefreshAfterMultiChanges = restore<boolean>(
  canRefreshAfterMultiChangesChanged,
  false
)

export const setIsSubmitting = createEvent<boolean>()

export const $isSubmitting = createStore(false).on(
  setIsSubmitting,
  (_, isSubmitting) => isSubmitting
)

export const submitForm = createEvent<void>()
export const cancelForm = createEvent<void>()

const clearFields = createEvent<void>()

const resetField = createEvent<void>()

export const setIsOneSelect = createEvent<boolean>()
export const $isOneSelect = restore(setIsOneSelect, false).reset(resetField)

export const setCourseInfo = createEvent<string>()
export const $courseInfo = restore(setCourseInfo, '').reset(resetField)

export const coursesIdsChanged = createEvent<string>()
export const $coursesIds = restore<string>(coursesIdsChanged, '').reset(resetField)

export const lessonAccessDtChanged = createEvent<string>()
export const $lessonAccessDt = restore<string>(lessonAccessDtChanged, '').reset(resetField)

export const $isDisabledAccessDtChanged = statusDropdownModule.store.$item.map((statusApp) =>
  statusApp ? statusApp.name !== 'suspended' : true
)

export const $isDisabledAProceed = combine(
  statusDropdownModule.store.$item,
  $lessonAccessDt,
  $coursesIds,
  (statusApp, accessDt, coursesIds) => {
    if (!statusApp) {
      return true
    }
    return coursesIds.length === 0 || statusApp.name === 'suspended' ? accessDt.length === 0 : false
  }
)

forward({
  from: statusDropdownModule.store.$item,
  to: lessonAccessDtChanged.prepend(() => ''),
})

const validateData = createEvent<CoursesUpdateForm>()
const validationIsPassed = createEvent<boolean>()

const sentForm = createEvent<void>()
const setValidationError = createEvent<void>()

export const $coursesIdsErrorModule = createError()

const canSetModeratorChanged = createEvent<boolean>()
export const $canSetModerator = restore(canSetModeratorChanged, false)

forward({
  from: loadModalForMultiChanges,
  to: [
    coursesIdsChanged.prepend((data) => data.join(',')),
    modalVisibilityChanged.prepend(() => true),
    canRefreshAfterMultiChangesChanged.prepend(() => false),
  ],
})

forward({
  from: clearFields,
  to: [resetField, statusDropdownModule.methods.resetDropdown],
})

forward({
  from: cancelForm,
  to: [modalVisibilityChanged.prepend(() => false), clearFields],
})

sample({
  clock: submitForm,
  source: { $coursesIds },
  target: validateData,
})

sample({
  clock: validateData,
  fn: (form: CoursesUpdateForm) => {
    return areAssignmentsIdsValid(formatTasksIDS(form.$coursesIds))
  },
  target: validationIsPassed,
})

condition({
  source: validationIsPassed,
  if: (passed: boolean) => passed,
  then: sentForm,
  else: setValidationError,
})

sample({
  clock: sentForm,
  source: { $coursesIds, status: statusDropdownModule.store.$item, $lessonAccessDt },
  fn: (form): ApplicationsCoursesBulkParams => {
    setIsSubmitting(true)
    const params: ApplicationsCoursesBulkParams = {
      tickets: formatTasksIDS(form.$coursesIds)
        .split(',')
        .map((el: string) => +el),
      status: form.status ? form.status.name : '',
      ...(form.$lessonAccessDt && { lesson_access_dt: form.$lessonAccessDt }),
    }
    return params
  },
  target: makeMultiChanges,
})

forward({
  from: setValidationError,
  to: [
    $coursesIdsErrorModule.methods.setError.prepend(() => true),
    errorToastEvent('ID заявок указаны в неправильном формате'),
  ],
})

forward({
  from: coursesIdsChanged,
  to: $coursesIdsErrorModule.methods.resetError,
})

condition({
  source: modalVisibilityChanged,
  if: (payload: boolean) => !payload,
  then: clearFields,
})

forward({
  from: makeMultiChanges.doneData,
  to: [
    successToastEvent('Данные были успешно обновлены!'),
    modalVisibilityChanged.prepend(() => false),
    clearFields,
    canRefreshAfterMultiChangesChanged.prepend(() => true),
    setIsSubmitting.prepend(() => false),
  ],
})

condition({
  source: makeMultiChanges.failData.map((res) => res.body),
  if: (data: UpdateApplicationsCoursesBulkFailResponse) => !!data.detail,
  then: addToast.prepend((data: UpdateApplicationsCoursesBulkFailResponse) => ({
    type: 'error',
    message: data.detail!,
  })),
  else: addToast.prepend((data: UpdateApplicationsCoursesBulkFailResponse) => {
    let message = Array.isArray(data) && data.length > 0 ? data[0] || data : 'Неизвестная ошибка'
    if ('tickets' in data && data.tickets && data.tickets.length > 0) {
      message = data.tickets
    }
    setIsSubmitting(false)
    return {
      type: 'error',
      message,
    }
  }),
})
