<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="themes-filter"
  >
    <div class="row">
      <DifficultyDropdown class="half-third" @setItem="val => setItem({'difficulty': val})" />
      <SubjectFilterDropdown
        class="half-third"
        @setItem="val => setItem({'subject': val})"
      />
      <ClassesFilterDropdown
        class="half-third"
        @setItem="val => setItem({'study_year': val})"
      />
    </div>
    <div class="row">
      <StudentsDropdown
        class="two-third"
        @setItem="(val) => setItem({'student': val})"
      />
      <GroupsFilterDropdown
        class="half-third"
        @setItem="val => setItem({'groups': val})"
      />
    </div>
    <div class="row">
      <TestsListDropdown class="two-third" @setItem="val => setItem({'test': val})" />
      <StatusDropdown
        type="results"
        class="half-third"
        @setItem="val => setItem({'status': val})"
      />
    </div>
    <div class="row">
      <TypeDropdown
        label="Тип генерации"
        class="two-third"
        @setItem="val => setItem({'generator': val})"
      />
      <div class="half-third btn-col">
        <BaseButton
          small
          @click="applyFilters"
        >
          Применить
        </BaseButton>
        <BaseButton
          class="borderless"
          small
          border-without-bg
          @click="resetFilters"
        >
          Сбросить фильтры
        </BaseButton>
      </div>
    </div>
    <Icon
      type="close"
      class="close-icon"
      size="10"
      @click="toggleVisibility(false)"
    />
    <div class="arrow-up" />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { dropdownResultsTestsAllComponents } from '@/pages/results/tests-all/parts/filter/parts/dropdown-components'
import {
  toggleVisibility,
  resultTestsAllFilters,
} from '@/pages/results/tests-all/parts/filter/tests-result-filter.model'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ResultCourseFilter',
  components: {
    Icon,
    BaseButton,
    SubjectFilterDropdown: dropdownResultsTestsAllComponents.SubjectsDropdown,
    ClassesFilterDropdown: dropdownResultsTestsAllComponents.ClassesDropdown,
    GroupsFilterDropdown: dropdownResultsTestsAllComponents.GroupsDropdown,
    StudentsDropdown: dropdownResultsTestsAllComponents.StudentsDropdown,
    StatusDropdown: dropdownResultsTestsAllComponents.StatusDropdown,
    TypeDropdown: dropdownResultsTestsAllComponents.TypeDropdown,
    DifficultyDropdown: dropdownResultsTestsAllComponents.DifficultyDropdown,
    TestsListDropdown: dropdownResultsTestsAllComponents.TestsListDropdown,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  effector: {
    $filterParams: resultTestsAllFilters.store.$filterParams,
  },
  methods: {
    toggleVisibility,
    closeFilter(event) {
      // check for general filter icon
      if (!event.target.closest('#filter-icon') && event.target.nodeName !== 'use') {
        toggleVisibility(false)
      }
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
      reset() // togglers and visibility
    },
  },
  beforeDestroy() {
    this.resetFilters()
  },
})
</script>

<style scoped>
.themes-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}
.row:last-of-type {
  justify-content: flex-end;
}

.half {
  width: calc(50% - 20px);
  flex-shrink: 0;
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}

.two-third {
  width: calc(100% / 3 * 2 - 10px);
  flex-shrink: 0;
}

.btn-col {
  display: flex;
  align-items: flex-end;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}

.arrow-up {
  @mixin arrow-up;
}

.table /deep/ .wrap {
  white-space: normal !important;
}
</style>

