






import Vue from 'vue'
import { ESCAPE_BTN_KEY_CODE } from '@/ui/modal/parts/constants'

export default Vue.extend({
  name: 'ModalBody',
  methods: {
    keydownHandler(e: KeyboardEvent) {
      if (e.keyCode === ESCAPE_BTN_KEY_CODE || e.code === 'Escape') {
        this.close()
      }
    },
    clickHandler(e: any) {
      if (
        e.target.classList.contains('modal-overlay') ||
        e.target.classList.contains('content-wrap')
      )
        this.close()
    },
    close() {
      this.$emit('close', false)
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keydownHandler)
    document.addEventListener('click', this.clickHandler)
  },
  destroyed() {
    document.removeEventListener('keydown', this.keydownHandler)
    document.removeEventListener('click', this.clickHandler)
  },
})
