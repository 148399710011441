import { createStaticFiltersModel } from '@/pages/common/filters/create-static-filters-model'
import { createEvent, restore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'

export const examDurationMapData: DropdownItem[] = [
  {
    name: 'per_block',
    title: 'Отдельно для каждого блока',
  },
  {
    name: 'overall',
    title: 'Общее время (на все блоки)',
  },
]
export const setDuration = createEvent<string>()
export const $durationMin = restore(setDuration, '')

export const examDurationDropdownModel = createStaticFiltersModel(examDurationMapData)
