






































import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  $partTimeInstruction,
  $partTimeName,
  $partTimeResume,
  setPartTimeInstruction,
  setPartTimeName,
  setPartTimeResume,
} from '@/pages/exams/part-time-test/create/model/form-fileds.model'

export default Vue.extend({
  name: 'CreatePartTimeTestPage2',
  components: {
    FormInput,
    Wysiwyg,
  },
  effector: {
    $partTimeName,
    $partTimeInstruction,
    $partTimeResume,
  },
  methods: {
    setPartTimeName,
    setPartTimeInstruction,
    setPartTimeResume,
  },
})
