
























































































































import Vue from 'vue'
import SubjectFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/SubjectFilterDropdown.vue'
import ClassesFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/ClassesFilterDropdown.vue'
import CoursesGroupsBlock from '@/pages/learning/learning-courses/parts/inputs/groups-couses-block/CoursesGroupsBlock.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $commentModule,
  $courseDescription,
  $courseInputText,
  $courseSlug,
  $courseTitle,
  $courseCommentCms,
  courseDescriptionChanged,
  courseInputTextChanged,
  courseSlugChanged,
  courseTitleChanged,
  toggleCommentModule,
  courseCommentCmsChanged,
  setGroupsCourse,
  $groupsCourse,
  $courseWithTicket,
  clearCourseTitle,
  clearCourseSlug,
  clearCourseCommentCms,
  $competition,
  toggleCompetition,
} from '@/pages/learning/learning-courses/create/courses-create-page.model'
import LessonsCourses from '@/pages/learning/learning-courses/create/parts/lessons-courses/LessonCourses.vue'
import QuestionCourses from '@/pages/learning/learning-courses/parts/inputs/question-courses/QuestionCourses.vue'
import { GroupCourseType } from '@/pages/learning/learning-courses/types'
import DifficultDropdown from '@/pages/common/dropdowns/courses/tags-dropdown/DificultDropdown/DifficultDropdown.vue'
import ForWhoDropdown from '@/pages/common/dropdowns/courses/tags-dropdown/ForWhoDropdown/ForWhoDropdown.vue'

export default Vue.extend({
  components: {
    ForWhoDropdown,
    DifficultDropdown,
    SubjectFilterDropdown,
    ClassesFilterDropdown,
    Wysiwyg,
    BaseButton,
    FormInput,
    BaseSwitch,
    Icon,
    LessonsCourses,
    QuestionCourses,
    CoursesGroupsBlock,
  },
  data() {
    return {
      slugInfo:
        'Если оставить пустым, то SLUG сгенерируется на основе введенного названия. ' +
        'Разрешенные символы: латиницы в нижнем регистре [a-z], цифры 0-1 и дефис. ' +
        'Максимальная длина 150 символов.',
    }
  },
  effector: {
    $courseTitle,
    $courseSlug,
    $commentModule,
    $competition,
    $courseDescription,
    $courseInputText,
    $courseCommentCms,
    $groupsCourse,
    $courseWithTicket,
  },
  methods: {
    toggleCompetition,
    courseTitleChanged,
    courseSlugChanged,
    toggleCommentModule,
    courseDescriptionChanged,
    courseInputTextChanged,
    courseCommentCmsChanged,
    setGroupsCourse,
    clearCourseTitle,
    clearCourseSlug,
    clearCourseCommentCms,
    addGroups() {
      let highestID = 0
      if (this.$groupsCourse.length > 0) {
        highestID = Math.max.apply(
          null,
          this.$groupsCourse.map((el) => el.blockId || 0)
        )
      }
      setGroupsCourse([
        ...this.$groupsCourse,
        {
          id: 0,
          blockId: highestID + 1,
          name: '',
          title: '',
          accept_dt_from: null,
          accept_dt_to: null,
        },
      ])
    },
    removeGroup({ blockId }: { blockId: number }) {
      const groupsCourse: GroupCourseType[] = this.$groupsCourse.filter(
        (gc) => gc.blockId !== blockId
      )
      setGroupsCourse(groupsCourse)
    },
    changeGroup(group: GroupCourseType) {
      const groupsCourse: GroupCourseType[] = this.$groupsCourse.map((qc) =>
        group.blockId === qc.blockId ? group : qc
      )
      setGroupsCourse(groupsCourse)
    },
  },
})
