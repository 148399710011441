
















import Vue from 'vue'
import {
  setRedirectAfterSave,
  getPageParams,
} from '@/pages/users/groups/create/model/create-group.model'
import {
  $disabledSaveButtons,
  resetFields,
} from '@/pages/users/groups/create/model/form-fileds.model'
import CreateGroupHeader from '@/pages/users/groups/create/parts/CreateGroupHeader.vue'
import CreateGroupFooter from '@/pages/users/groups/create/parts/CreateGroupFooter.vue'
import { changeGroupId } from '@/pages/users/groups/create/model/edit-group.model'
import CreateGroupContent from '@/pages/users/groups/create/parts/CreateGroupContent.vue'

export default Vue.extend({
  name: 'CreateGroupPage',
  components: {
    CreateGroupFooter,
    CreateGroupHeader,
    CreateGroupContent,
  },
  effector: {
    $disabledSaveButtons,
  },
  methods: {
    saveGroup(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      getPageParams()
    },
  },
  created() {
    changeGroupId(this.$route.params.id)
  },
  beforeDestroy() {
    resetFields()
  },
})
