import {
  attach,
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
  split,
} from 'effector-root'
import {
  $isFilled as $isFilledBroadFile,
  $form as $formBroadFile,
  initAssignment as initBroadFileTask,
} from '@/pages/common/parts/tasks/broad-file-answer/broad-file-answer.model'
import {
  $isFilled as $isFilledBroadOpen,
  $form as $formBroadOpen,
  initAssignment as initBroadOpenTask,
} from '@/pages/common/parts/tasks/broad-open-answer/broad-open-answer.model'
import {
  $isFilled as $isFilledColorHighlight,
  $form as $formColorHighlight,
  initAssignment as initColorHighlightTask,
} from '@/pages/common/parts/tasks/color-highlight-answer/color-highlight-answer.model'
import {
  $isFilled as $isFilledCommonListString,
  $form as $formCommonListString,
  initAssignment as initCommonListStringTask,
} from '@/pages/common/parts/tasks/common-list-string-answer/common-list-string-answer.model'
import {
  $isFilled as $isFilledCommonListText,
  $form as $formCommonListText,
  initAssignment as initCommonListTextTask,
} from '@/pages/common/parts/tasks/common-list-text-answer/common-list-text-answer.model'
import {
  $isFilled as $isFilledConnectLines,
  $form as $formConnectLines,
  toggleTaskTypeDataReady,
  taskTypeDataPrepared,
  initAssignment as initConnectLinesTask,
} from '@/pages/common/parts/tasks/connect-lines-answer/connect-lines-answer.model'
import {
  $isFilled as $isFilledCorrectSequence,
  $form as $formCorrectSequence,
  initAssignment as initCorrectSequenceTask,
} from '@/pages/common/parts/tasks/correct-sequence-answer/correct-sequence-answer.model'
import {
  $isFilled as $isFilledMultipleChoiceOne,
  $form as $formMultipleChoiceOne,
  initAssignment as initMultipleChoiceOneTask,
} from '@/pages/common/parts/tasks/multiple-choice-one-answer/multiple-choice-one-answer.model'
import {
  $isFilled as $isFilledMultipleChoiceOneOrMany,
  $form as $formMultipleChoiceOneOrMany,
  initAssignment as initMultipleChoiceOneOrManyTask,
} from '@/pages/common/parts/tasks/multiple-choice-one-or-many-answers/multiple-choice-one-or-many-answers.model'
import {
  $isFilled as $isFilledMultipleListText,
  $form as $formMultipleListText,
  initAssignment as initMultipleListTask,
} from '@/pages/common/parts/tasks/multiple-list-text-answer/multiple-list-text-answer.model'
import {
  $isFilled as $isFilledMultipleShortClosed,
  $form as $formMultipleShortClosed,
  initAssignment as initMultipleShortClosedTask,
} from '@/pages/common/parts/tasks/multiple-short-closed-answer/multiple-short-closed-answer.model'
import {
  $isFilled as $isFilledShortClosed,
  $form as $formShortClosed,
  initAssignment as initShortClosedTask,
} from '@/pages/common/parts/tasks/short-closed-answer/short-closed-answer.model'
import {
  $isFilled as $isFilledMovingOnImage,
  $form as $formMovingOnImage,
  initAssignment as initMovingOnImageTask,
} from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/moving-images-on-image-answer.model'
import {
  $isFilled as $isFilledMovingOnText,
  $form as $formMovingOnText,
  initAssignment as initMovingOnTextTask,
} from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/moving-images-on-text-input-answer.model'

import { addToast, successToastEvent } from '@/features/toasts/toasts.model'

import { DropdownItem } from '@/pages/common/types'
import { LANGUAGE_DATA, TASK_TYPES_DATA } from '@/pages/bank/common/constants'
import { mapTaskTypeTo } from '@/pages/common/constants'
import { createExamAssignmentFx } from '@/features/api/assignment/exam-assignment/create-exam-assignment'
import { getExamAssignmentFx } from '@/features/api/assignment/exam-assignment/get-exam-assignment'
import { editExamAssignmentFx } from '@/features/api/assignment/exam-assignment/edit-exam-assignment'
import { taskTypesDropdownModule } from '@/pages/common/dropdowns/bank/task-types-dropdown/task-types-dropdown.model'
import { foldersDropdownModule } from '@/pages/common/dropdowns/bank/exam-tasks/folder-dropdown/folder-dropdown.model'
import { condition } from 'patronum'
import { uploadAudioFiles } from '@/pages/common/parts/audio-files/audio-files-save.model'
import {
  AssignmentAudioFile,
  CreateExamAssignmentFxParams,
} from '@/features/api/assignment/types/types'
import { parseError } from '@/features/lib'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { setStatus } from '@/pages/common/parts/status-controller/status.model'
import {
  $selectedSubject,
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'

const createExamAssignment = attach({
  effect: createExamAssignmentFx,
})

const uploadAudioFilesFx = attach({
  effect: uploadAudioFiles,
})

const getExamAssignment = attach({
  effect: getExamAssignmentFx,
})

export const save = createEvent<void>()
export const clearFields = createEvent<void>()

export const loadTask = createEvent<number>()
export const $taskId = restore(loadTask, 0).reset(clearFields)

const putExamAssignment = attach({
  effect: editExamAssignmentFx,
  source: $taskId,
  mapParams: (payload: CreateExamAssignmentFxParams, taskID) => {
    return { body: payload, id: taskID }
  },
})

forward({
  from: loadTask,
  to: getExamAssignment,
})

export const setScore = createEvent<number | null>()
export const $score = restore(setScore, 1)

export const setTaskType = createEvent<string | null>()
export const $taskType = restore(setTaskType, null)

export const setLanguage = createEvent<DropdownItem>()
export const $language = restore(setLanguage, LANGUAGE_DATA[0])

export const setAudioIds = createEvent<AssignmentAudioFile[]>()
export const $audioIds = restore(setAudioIds, [])

export const toggleNeedDuplicate = createEvent<boolean>()
export const $needDuplicate = restore(toggleNeedDuplicate, false).reset(clearFields)

export const setCount = createEvent<number>()
export const $count = restore(setCount, 0).reset(clearFields)

export const setRedirectAfterSave = createEvent<boolean>()
const $redirectAfterSave = restore(setRedirectAfterSave, false).reset(clearFields)

export const componentMounted = createEvent<void>()
export const componentDestroyed = createEvent<void>()
export const $isComponentMounted = createStore(false)
  .on(componentMounted, () => true)
  .on(componentDestroyed, () => false)

forward({
  from: clearFields,
  to: [
    taskTypesDropdownModule.methods.resetDropdown,
    setTaskType.prepend(() => null),
    foldersDropdownModule.methods.resetDropdown,
  ],
})

const mapTaskTypeToInitFn = {
  BROAD_FILE_ANSWER: initBroadFileTask,
  BROAD_OPEN_ANSWER: initBroadOpenTask,
  COLOR_HIGHLIGHT_ANSWER: initColorHighlightTask,
  COMMON_LIST_STRING_ANSWER: initCommonListStringTask,
  COMMON_LIST_TEXT_ANSWER: initCommonListTextTask,
  CONNECT_LINES_ANSWER: initConnectLinesTask,
  CORRECT_SEQUENCE_ANSWER: initCorrectSequenceTask,
  MULTIPLE_CHOICE_ONE_ANSWER: initMultipleChoiceOneTask,
  MULTIPLE_CHOICE_ONE_OR_MANY_ANSWERS: initMultipleChoiceOneOrManyTask,
  MULTIPLE_LIST_TEXT_ANSWER: initMultipleListTask,
  MULTIPLE_SHORT_CLOSED_ANSWER: initMultipleShortClosedTask,
  SHORT_CLOSED_ANSWER: initShortClosedTask,
  MOVING_IMAGES_IMAGE_INPUT_ANSWER: initMovingOnImageTask,
  MOVING_IMAGES_TEXT_INPUT_ANSWER: initMovingOnTextTask,
}

sample({
  source: getExamAssignment.doneData.map((res) => res.body),
  fn: (assignment) => {
    mapTaskTypeToInitFn[assignment.type](assignment)
    return assignment
  },
})

forward({
  from: getExamAssignment.doneData.map((res) => res.body),
  to: [
    setScore.prepend((data) => data.score || 0),
    taskTypesDropdownModule.methods.itemChanged.prepend((data) => ({
      name: data.type,
      title: TASK_TYPES_DATA.find((el) => el.name === data.type)!.title,
    })),
    setTaskType.prepend((data) => data.type),
    setLanguage.prepend((data) => ({
      name: data.interface_language,
      title: data.interface_language,
    })),
    foldersDropdownModule.methods.itemChanged.prepend((data) => ({
      name: `${data.folder.id}`,
      title: data.folder.name,
    })),
    setSelectedSubject.prepend((data) =>
      data.subject
        ? {
            name: `${data.subject.id}`,
            title: data.subject.name,
          }
        : null
    ),
    subjectsDropdownModel.methods.itemChanged.prepend((data) => ({
      name: `${data.subject?.id || ''}`,
      title: data.subject?.name || '',
    })),
    setStatus.prepend((data) => data.status),
    setAudioIds.prepend((data) => data.audios),
  ],
})

const $isFilled = combine({
  BroadFileAnswer: $isFilledBroadFile,
  BroadOpenAnswer: $isFilledBroadOpen,
  ColorHighlightAnswer: $isFilledColorHighlight,
  CommonListStringAnswer: $isFilledCommonListString,
  CommonListTextAnswer: $isFilledCommonListText,
  ConnectLinesAnswer: $isFilledConnectLines,
  CorrectSequenceAnswer: $isFilledCorrectSequence,
  MultipleChoiceOneAnswer: $isFilledMultipleChoiceOne,
  MultipleChoiceOneOrManyAnswers: $isFilledMultipleChoiceOneOrMany,
  MultipleListTextAnswer: $isFilledMultipleListText,
  MultipleShortClosedAnswer: $isFilledMultipleShortClosed,
  ShortClosedAnswer: $isFilledShortClosed,
  MovingImagesOnImageInputAnswer: $isFilledMovingOnImage,
  MovingImagesOnTextInputAnswer: $isFilledMovingOnText,
})

export const toggleIsPreview = createEvent<boolean>()
export const $isPreview = restore(toggleIsPreview, false)

export const $canSave = combine(
  $selectedSubject,
  foldersDropdownModule.store.$item,
  $score,
  $taskType,
  $isFilled,
  (subject, folder, score, taskType, isFilled) => {
    const isFilledTask = taskType && isFilled[mapTaskTypeTo[taskType].componentName]
    return isFilledTask && score && folder && subject
  }
)

const $taskform = combine({
  BroadFileAnswer: $formBroadFile,
  BroadOpenAnswer: $formBroadOpen,
  ColorHighlightAnswer: $formColorHighlight,
  CommonListStringAnswer: $formCommonListString,
  CommonListTextAnswer: $formCommonListText,
  ConnectLinesAnswer: $formConnectLines,
  CorrectSequenceAnswer: $formCorrectSequence,
  MultipleChoiceOneAnswer: $formMultipleChoiceOne,
  MultipleChoiceOneOrManyAnswers: $formMultipleChoiceOneOrMany,
  MultipleListTextAnswer: $formMultipleListText,
  MultipleShortClosedAnswer: $formMultipleShortClosed,
  ShortClosedAnswer: $formShortClosed,
  MovingImagesOnImageInputAnswer: $formMovingOnImage,
  MovingImagesOnTextInputAnswer: $formMovingOnText,
})

const $baseForm = combine(
  foldersDropdownModule.store.$item,
  $selectedSubject,
  $score,
  $taskType,
  $language,
  $needDuplicate,
  $count,
  (folder, subject, score, taskType, language, needDuplicate, count) => ({
    type: taskType,
    subject_id: subject?.name,
    folder_id: folder?.name,
    score,
    interface_language: language.name.slice(0, 2),
    ...(needDuplicate ? { duplicate_count: count } : {}),
  })
)

const $generalForm = combine($baseForm, $taskType, $taskform, (baseForm, taskType, taskform) => {
  const form = taskType ? taskform[mapTaskTypeTo[taskType].componentName] : {}
  return {
    ...baseForm,
    ...form,
  }
})

sample({
  source: $generalForm,
  clock: save,
  fn: ({ audio }) => audio,
  target: uploadAudioFilesFx,
})

function prepareAudioFiles(form: any, audioFiles: AssignmentAudioFile[]) {
  const { ...pureForm } = form
  return {
    ...pureForm,
    audios_ids: audioFiles.map(({ id }) => id),
  }
}

sample({
  clock: uploadAudioFilesFx.doneData,
  source: $generalForm,
  fn: (form, audioFiles) => prepareAudioFiles(form, audioFiles),
  target: $generalForm,
})

const immediateCreate = createEvent<AssignmentAudioFile[]>()

sample({
  source: $taskType,
  clock: uploadAudioFilesFx.doneData,
  fn: (type, audioFiles) => ({ type, audioFiles }),
  target: condition({
    if: (payload: { type: string | null; audioFiles: AssignmentAudioFile[] }) =>
      payload.type === 'CONNECT_LINES_ANSWER',
    then: toggleTaskTypeDataReady.prepend(() => true),
    else: immediateCreate.prepend(
      (payload: { type: string | null; audioFiles: AssignmentAudioFile[] }) => payload.audioFiles
    ),
  }),
})

const selectMethod = createEvent<CreateExamAssignmentFxParams>()

sample({
  source: $generalForm,
  clock: immediateCreate,
  target: selectMethod,
})

guard({
  source: $generalForm,
  filter: $isComponentMounted,
  clock: taskTypeDataPrepared,
  target: selectMethod,
})

split({
  source: selectMethod,
  match: {
    get: $taskId.map((id) => id === 0),
    put: $taskId.map((id) => id !== 0),
  },
  cases: {
    get: createExamAssignment,
    put: putExamAssignment,
  },
})

const { createElementNotFound } = split(createExamAssignment.failData, {
  createElementNotFound: ({ status }) => status === 400,
})

const { editElementNotFound } = split(putExamAssignment.failData, {
  editElementNotFound: ({ status }) => status === 400,
})

forward({
  from: [createElementNotFound, editElementNotFound],
  to: addToast.prepend((data: any) => ({ type: 'error', message: parseError(data.body) })),
})

forward({
  from: createExamAssignment.doneData.map((res) => res.body.id),
  to: [successToastEvent('Задание успешно создано!')],
})

forward({
  from: putExamAssignment.doneData.map((res) => res.body.id),
  to: [successToastEvent('Задание успешно изменено!')],
})

const $redirectHandler = sample({
  clock: createExamAssignment.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'exam-tasks-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => {
    loadTask(payload.id)
    return {
      name: 'exam-tasks-edit',
      query: {
        fromPage: 'tasks',
      },
      params: { id: `${payload.id}` },
    }
  }),
})
