

































import Vue, { PropType } from 'vue'
import MenuWrap from '@/ui/menu/MenuWrap.vue'
import SelectMenu from '@/ui/select/parts/SelectMenu.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import ClickOutside from '@/features/directives/click-outside'

import {
  $contextMenuVisible,
  contextMenuDestroy,
  hideContextMenu,
} from '@/pages/common/parts/context-menu/context-menu.model'
import {
  actionsSelectMenuMaxHeight,
  selectItemHeight,
} from '@/pages/common/parts/context-menu/constants'
import { ActionsItem } from '@/pages/common/types'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ContextMenu',
  components: {
    MenuWrap,
    SelectMenu,
    SelectItem,
  },
  props: {
    items: { type: Array as PropType<ActionsItem[]>, required: true },
    clickedCoordinates: { type: Object as PropType<{ x: number; y: number }>, required: true },
  },
  effector: {
    $contextMenuVisible,
  },
  data() {
    return {
      position: { top: '0' as string, left: '0' as string },
    }
  },
  watch: {
    $contextMenuVisible: {
      handler(newVal) {
        if (newVal) this.setPosition()
      },
    },
  },
  methods: {
    hideContextMenu,
    setPosition() {
      const pageBottomPosition = this.$parent.$el.getBoundingClientRect().bottom
      const selectItemsSumHeight = this.items.length * selectItemHeight
      const selectMenuHeight =
        selectItemsSumHeight > actionsSelectMenuMaxHeight
          ? actionsSelectMenuMaxHeight
          : selectItemsSumHeight
      const { scrollTop } = document.querySelector('#app') || { scrollTop: 0 }

      let topPosition = this.clickedCoordinates.y + scrollTop
      const leftPosition = this.clickedCoordinates.x - 120

      if (pageBottomPosition - topPosition < selectMenuHeight) {
        topPosition -= selectMenuHeight
      }

      this.position = {
        top: `${topPosition}px`,
        left: `${leftPosition}px`,
      }
    },
  },
  beforeDestroy() {
    contextMenuDestroy()
  },
})
