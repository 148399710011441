import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import { mapTaskStatus } from '@/pages/common/parts/status-controller/constants'
import { ActionsDisplayConditions } from '@/pages/common'
import { Employee, EmployeePermissions } from '@/features/api/employee/types'
import { difficultyMap } from '@/pages/bank/test-tasks/list/types'
import { StudyYear } from '@/features/api/subject/types'

export const TestTasksTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID задания',
    width: '130px',
  },
  {
    name: 'subject',
    sortField: 'theme__subject__name',
    title: 'Предмет',
    width: '130px',
    formatter: (data) => {
      return (data && data.name) || '–'
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус',
    width: '130px',
    formatter(status: any) {
      return mapTaskStatus[status] || '-'
    },
  },
  {
    name: 'study_year',
    sortField: 'theme__study_year__number',
    title: 'Класс',
    width: '130px',
    formatter: (study_year: StudyYear) => {
      return (study_year && study_year.number) || '–'
    },
  },
  {
    name: 'theme',
    sortField: 'theme__name',
    title: 'Тема',
    width: '150px',
    formatter: (data) => {
      return data && data.name ? data.name : '–'
    },
  },
  {
    name: 'difficulty',
    sortField: 'difficulty',
    title: 'Сложность',
    width: '150px',
    formatter: (data) => {
      return difficultyMap[data]
    },
  },
  {
    name: 'wording',
    sortField: 'wording',
    title: 'Формулировка задания',
    width: '250px',
    formatter(data: string) {
      return data || '–'
    },
  },
  {
    name: 'type',
    sortField: 'type',
    title: 'Тип',
    width: '150px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(icon: string) {
      return icon
    },
  },
  {
    name: 'labels_string',
    sortField: 'labels_amount',
    title: 'Метки',
    width: '200px',
    formatter(data: string) {
      return data || '–'
    },
  },
  {
    name: 'interface_language',
    sortField: 'interface_language',
    title: 'Язык',
    width: '130px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(text: string) {
      return text || '-'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by_name',
    title: 'Создатель',
    width: '200px',
    formatter(user: Employee) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '130px',
    formatter(datetime: string) {
      return datetime || '–'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Обновлено',
    width: '130px',
    formatter(datetime: string) {
      return datetime || '–'
    },
  },
  {
    name: 'updated_by',
    title: 'Посл. изменение',
    width: '150px',
    formatter: (data) => {
      return data && data.first_name && data.last_name
        ? `${data.first_name} ${data.last_name}`
        : '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'id Задания' },
  { name: 'created_by', title: 'Создатель' },
  { name: 'theme', title: 'Тема' },
  { name: 'wording', title: 'Формулировка' },
]

export const getTestTasksActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'editTask',
      title: 'Редактирование',
      headerTitle: 'Редактировать',
      action: 'onEditTask',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isTreeItem ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee:
        permissions.assignments_testassignment_difficulty_base.can_edit ||
        permissions.assignments_testassignment_difficulty_pro.can_edit ||
        permissions.assignments_testassignment_difficulty_exam.can_edit,
    },
    {
      name: 'deleteTask',
      title: displayOptions.isTableItems ? 'Удалить выделенные задания' : 'Удалить задание',
      headerTitle: 'Удалить',
      action: 'onDeleteTask',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isTreeItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
    {
      name: 'createTheme',
      title: 'Создать тему',
      action: 'onCreateTheme',
      isDisplayed: displayOptions.isTreeThemeFolder,
      hasPermissionToSee: permissions.subjects_theme_themes.can_edit,
    },
    {
      name: 'createTask',
      title: 'Создать задание',
      action: 'onCreateTask',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee:
        permissions.assignments_testassignment_difficulty_base.can_edit ||
        permissions.assignments_testassignment_difficulty_pro.can_edit ||
        permissions.assignments_testassignment_difficulty_exam.can_edit,
    },
    {
      name: 'editTheme',
      title: 'Редактировать',
      action: 'onEditTheme',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: permissions.subjects_theme_themes.can_edit,
    },
    {
      name: 'deleteTheme',
      title: 'Удалить',
      action: 'onDeleteTheme',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: true,
    },
    {
      name: 'sendToReview',
      title: displayOptions.isTableItems
        ? 'Отправить задания на проверку'
        : 'Отправить задание на проверку',
      headerTitle: 'На проверку',
      action: 'onSendToReview',
      isDisplayed:
        displayOptions.isTreeItem ||
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.subjects_theme_themes.can_edit,
    },
    {
      name: 'publish',
      title: 'Опубликовать',
      action: 'onPublish',
      isDisplayed:
        displayOptions.isTableItem || displayOptions.isTableItems || displayOptions.isHeaderItems,
      hasPermissionToSee:
        permissions.assignments_testassignment_difficulty_base.can_edit ||
        permissions.assignments_testassignment_difficulty_pro.can_edit ||
        permissions.assignments_testassignment_difficulty_exam.can_edit,
    },
    {
      name: 'preview',
      title: 'Предпросмотр',
      action: 'onPreview',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isTreeItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee:
        permissions.assignments_testassignment_difficulty_base.can_view ||
        permissions.assignments_testassignment_difficulty_pro.can_view ||
        permissions.assignments_testassignment_difficulty_exam.can_view,
    },
    {
      name: 'duplicate',
      title: 'Дублировать задание',
      action: 'onDuplicate',
      isDisplayed: displayOptions.isHeaderActions || displayOptions.isTableItem,
      hasPermissionToSee:
        permissions.assignments_testassignment_difficulty_base.can_edit ||
        permissions.assignments_testassignment_difficulty_pro.can_edit ||
        permissions.assignments_testassignment_difficulty_exam.can_edit,
    },
    {
      name: 'duplicateNTimes',
      title: 'Дублировать задание n раз',
      action: 'onDuplicateNTimes',
      isDisplayed: displayOptions.isHeaderActions || displayOptions.isTableItem,
      hasPermissionToSee:
        permissions.assignments_testassignment_difficulty_base.can_edit ||
        permissions.assignments_testassignment_difficulty_pro.can_edit ||
        permissions.assignments_testassignment_difficulty_exam.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
