



























































































import Vue from 'vue'
import Card from '@/ui/card/Card.vue'
import FormInput from '@/ui/input/FormInput.vue'
import TypeDropdown from '@/pages/common/dropdowns/content/onboarding/type/TypeDropdown.vue'
import {
  $name,
  nameChanged,
  resetName,
  $title,
  titleChanged,
  resetTitle,
  $text,
  textChanged,
  resetText,
  uploadResource,
  $showTitle,
  toggleShowTitle,
  $showText,
  toggleShowText,
  $showMedia,
  toggleShowMedia,
  $mediaFile,
  resetMediaFile,
  $formToSend,
} from '@/pages/content/onboarding/edit/slide-edition-page.model'
import { $selectedType } from '@/pages/common/dropdowns/content/onboarding/type/type-dropdown.model'
import FileInput from '@/ui/audio-file-input/AudioFileInput.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  components: {
    Card,
    FormInput,
    TypeDropdown,
    FileInput,
    BaseSwitch,
    Icon,
  },
  effector: {
    $name,
    $title,
    $text,
    $selectedType,
    $showTitle,
    $showText,
    $showMedia,
    $mediaFile,
    $formToSend,
  },
  computed: {
    showResourceWrapper() {
      return (
        this.$selectedType &&
        (this.$selectedType.name === 'img' || this.$selectedType.name === 'video')
      )
    },
  },
  watch: {
    $selectedType: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal) {
          resetMediaFile()
        }
      },
    },
  },
  methods: {
    nameChanged,
    resetName,
    titleChanged,
    resetTitle,
    textChanged,
    resetText,
    uploadResource,
    toggleShowTitle,
    toggleShowText,
    toggleShowMedia,
    resetMediaFile,
  },
})
