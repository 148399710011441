























import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  $modalVisibility,
  $selectedId,
  modalVisibilityChanged,
  $comment,
} from '@/pages/applications/modals/comment/comment.model'

export default Vue.extend({
  name: 'CommentModal',
  components: {
    Modal,
    Icon,
  },
  effector: {
    $modalVisibility,
    $selectedId,
    $comment,
  },
  methods: {
    modalVisibilityChanged,
  },
})
