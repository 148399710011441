



























































import Vue, { PropType } from 'vue'
import BaseDropdown from '@/ui/dropdown/BaseDropdown.vue'
import Icon from '@/ui/icon/Icon.vue'
import SelectItem from '@/ui/select/parts/SelectItem.vue'
import SelectItemRecursive from '@/ui/select/parts/SelectItemRecursive.vue'
import { DropdownItem } from '@/pages/common/types'
import { FilterDropdownMethods, FilterDropdownStore } from '@/pages/common/filter-dropdown/types'
import SmallLoader from '@/pages/common/parts/internal-loader-blocks/SmallLoader.vue'

export default Vue.extend({
  components: {
    BaseDropdown,
    Icon,
    SelectItem,
    SelectItemRecursive,
    SmallLoader,
  },
  props: {
    noDataMessage: { type: String, default: 'Не найдено совпадений' },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    data: { type: Array as PropType<DropdownItem[]>, default: [] },
    methods: { type: Object as PropType<FilterDropdownMethods>, required: true },
    store: { type: Object as PropType<FilterDropdownStore>, required: true },
    disabled: { type: Boolean as PropType<boolean> },
    selectedData: { type: Array as PropType<DropdownItem[]> },
    isRecursive: { type: Boolean as PropType<boolean> },
    isShowPrerequisites: { type: Boolean as PropType<boolean> },
    loading: { type: Boolean as PropType<boolean>, default: false },
    secondClick: { type: Boolean as PropType<boolean>, default: false },
    disabledElementId: { type: Number },
    smallMenu: { type: Boolean, default: false },
    clearBtn: { type: Boolean, default: true },
    readOnlyDropdown: { type: Boolean, default: false },
  },
  computed: {
    correctValue(): string {
      return this.store.$item && this.store.$item.title && !this.store.$searchString
        ? this.store.$item.title
        : this.store.$searchString
    },
    items(): DropdownItem[] {
      if (!this.isShowPrerequisites) {
        return this.$props.store.$itemsDropdown.filter(
          (item: DropdownItem) => !item.is_prerequisite
        )
      }
      return this.$props.store.$itemsDropdown
    },
  },
  watch: {
    data: {
      handler(val, oldVal) {
        if ((!oldVal.length && !!val.length) || oldVal !== val) {
          this.$props.methods.setItems(this.$props.data)
        }
      },
    },
  },
  methods: {
    onSelectItem(item: DropdownItem | null, cb: any) {
      this.$emit('item-changed', item)
      const isSecondClick =
        this.selectedData && !!this.selectedData.find((el: DropdownItem) => el.name === item?.name)
      if (this.secondClick && isSecondClick) {
        const resucedSelectedData = this.selectedData.filter((data) => data.name !== item?.name)
        this.$props.methods.itemChanged(
          this.selectedData.length === 1 ? null : resucedSelectedData[0]
        )
      } else this.$props.methods.itemChanged(item)
      this.$props.methods.resetSearchString()
      cb()
    },
    clear(event: MouseEvent) {
      event.stopPropagation()
      this.$emit('item-changed', null)
      this.$props.methods.resetItem()
      this.$props.methods.resetSearchString()
    },
    showTick(id: string) {
      return this.selectedData && !!this.selectedData.find((el: any) => +el.name === +id)
    },
    handleClick(val: DropdownItem, cb: any) {
      if (this.disabledElementId && val.id === this.disabledElementId) return
      this.onSelectItem(val, cb)
    },
  },
  mounted() {
    this.$props.methods.setItems(this.$props.data)
  },
})
