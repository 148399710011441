

















































































































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import { $token } from '@/features/api/common/request'
import PageHeader from '@/pages/bank/lesson-tasks/list/parts/PageHeader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import LessonsFilter from '@/pages/bank/lesson-tasks/list/parts/lesson-tasks-filter/LessonsFilter.vue'
import LessonsTree from '@/pages/bank/lesson-tasks/list/parts/lessons-tree/LessonsTree.vue'
import {
  loadTree,
  $lessonsTreeTotal,
  deleteAssignments,
  requestDeleteAssignments,
  deleteFolder,
  lessonTaskPageParams,
  loadTreeLight,
  $isLoading,
  requestDeleteFolder,
  duplicateAssignment,
  $canRefreshAfterDuplicate,
  exportLessonAssignmentsTableFile,
} from '@/pages/bank/lesson-tasks/list/lesson-page.model'
import {
  toggleVisibility,
  $visibility,
  lessonTasksFilters,
} from '@/pages/bank/lesson-tasks/list/parts/lesson-tasks-filter/lesson-tasks-filter.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  getLessonsTasksActions,
  lessonsTableFields,
  searchFieldsData,
} from '@/pages/bank/lesson-tasks/list/constants'
import { DEFAULT_ID, mapTaskTypeTo } from '@/pages/common/constants'
import {
  CommonInteractedItemParams,
  ActionsItem,
  DisplayContextMenuPayload,
  RefsType,
} from '@/pages/common/types'
import { $canRefreshAfterMultiChanges } from '@/pages/bank/lesson-tasks/list/parts/modals/tasks-update/tasks-update-modal.model'
import TasksTypesModal from '@/pages/common/modals/tasks-bank/tasks-types/TasksTypesModal.vue'
import TasksUpdateModal from '@/pages/bank/lesson-tasks/list/parts/modals/tasks-update/TasksUpdateModal.vue'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import {
  loadConfirmDeleteModal,
  $deleteType,
  setDeleteType,
} from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { loadRequestDeleteModal } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { $currentUser, $permissions } from '@/features/session'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import CreatingLessonAssignmentFolderModal from '@/pages/common/modals/tasks-bank/creating-folder/CreatingLessonAssignmentFolderModal.vue'
import EditingLessonFolderModal from '@/pages/common/modals/tasks-bank/editing-folder/EditingLessonFolderModal.vue'
import {
  combineRouteQueries,
  computeSortParam,
  isQueryParamsEquelToPage,
  removeHtmlTags,
} from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import DuplicateModal from '@/pages/bank/common/modals/duplicate/DuplicateModal.vue'
import { loadDuplicateModal } from '@/pages/bank/common/modals/duplicate/duplicate.model'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { lessonAssignmentFolderModal } from '@/pages/common/modals/tasks-bank/creating-folder/creating-folder-modal.model'
import { lessonAssignmentEditFolderModal } from '@/pages/common/modals/tasks-bank/editing-folder/editing-folder-modal.model'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import { clearTreeStates } from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { getActionsDisplayConditions } from '@/pages/common'
import { axiosClient } from '@/lib/request'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'LessonsTasksList',
  components: {
    Vuetable,
    VuetablePagination,
    PageHeader,
    TableHeader,
    GeneralFilter,
    LessonsFilter,
    TooltipCell,
    ActionsButton,
    ContextMenu,
    LessonsTree,
    TasksTypesModal,
    TasksUpdateModal,
    NoDataContent,
    ConfirmDeleteModal,
    RequestDeleteModal,
    CreatingLessonAssignmentFolderModal,
    EditingLessonFolderModal,
    LoaderBig,
    DuplicateModal,
  },
  effector: {
    $token,
    $visibility,
    $lessonsTreeTotal,
    $canRefreshAfterMultiChanges,
    $filterParams: lessonTasksFilters.store.$filterParams,
    $pageParams: lessonTaskPageParams.store.$pageParams,
    $treeView: lessonTaskPageParams.store.treeView,
    $currentPage: lessonTaskPageParams.store.currentPage,
    $isLoading,
    $deleteType,
    $canRefreshAfterDuplicate,
    $currentUser,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      fields: lessonsTableFields,
      searchFields: searchFieldsData,
      total: 0,
      selectedRows: [] as number[],
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/assignment-app/lesson-assignment/list/`
    },
    canRemoveTask(): boolean {
      const hasPermissionToRemove =
        this.$permissions!.assignments_lessonassignment_assignments.can_edit
      return hasPermissionToRemove && this.$currentUser!.role !== 'METHODIST'
    },
    selectedIds(): number[] {
      if (this.selectedRows.length && !this.$treeView) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getLessonsTasksActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $canRefreshAfterMultiChanges: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.removeSelection()
      },
    },
    $canRefreshAfterDuplicate: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
  },
  methods: {
    changeFilter: lessonTasksFilters.methods.changeFilter,
    resetFilters: lessonTasksFilters.methods.resetFilters,
    applyFilters: lessonTasksFilters.methods.applyFilters,
    toggleTreeView: lessonTaskPageParams.methods.toggleTreeView,
    changePage: lessonTaskPageParams.methods.changePage,
    queryToParams: lessonTaskPageParams.methods.queryToParams,
    toggleVisibility,
    loadTree,
    duplicateAssignment,
    loadDuplicateModal,
    exportLessonAssignmentsTableFile,
    showPreview() {
      this.$router.push({
        name: 'preview-task',
        query: {
          questions: this.selectedIds.join(','),
          fromPage: 'tasks',
          taskType: 'lesson-assignment',
          token: this.$token,
        },
      })
    },
    clearWording(str: string) {
      return removeHtmlTags(str)
    },
    getCorrectIconType(type: string) {
      return mapTaskTypeTo[type].icon
    },
    getCorrectDescriptionType(type: string) {
      return mapTaskTypeTo[type].description
    },
    async myFetch(apiUrl: string, httpOptions: any) {
      const request = axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
      return request
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      clearTreeStates()
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    editTask() {
      const routeData = this.$router.resolve({
        name: 'lesson-tasks-edit',
        query: {
          questions: this.selectedIds.join(','),
          fromPage: 'tasks',
        },
        params: { id: `${this.selectedIds[0]}` },
      })
      window.open(routeData.href, '_blank')
    },
    onRemoveTask() {
      setDeleteType('task')
      this.canRemoveTask
        ? loadConfirmDeleteModal(this.selectedIds)
        : loadRequestDeleteModal(this.selectedIds)
    },
    async removeSelected(ids: number[]) {
      if (this.$deleteType === 'folder') {
        await deleteFolder(ids[0])
      } else {
        await deleteAssignments(ids)
      }
      await Vue.nextTick(() => this.$refs.vuetable.reload())
      this.removeSelection()
    },
    async sendRequestDelete(comment: string, ids: number[]) {
      if (this.$deleteType === 'folder') {
        await requestDeleteFolder({ assignment_folders: ids, ticket_comment: comment })
      } else {
        await requestDeleteAssignments({ assignments: ids, ticket_comment: comment })
      }
      this.removeSelection()
    },
    createFolder() {
      lessonAssignmentFolderModal.methods.loadFolder(this.selectedIds[0])
      lessonAssignmentFolderModal.methods.modalVisibilityChanged(true)
    },
    editFolder() {
      lessonAssignmentEditFolderModal.methods.loadFolder(this.selectedIds[0])
      lessonAssignmentEditFolderModal.methods.modalVisibilityChanged(true)
    },
    deleteFolder() {
      setDeleteType('folder')
      this.$permissions!.subjects_theme_themes.can_edit
        ? loadConfirmDeleteModal(this.selectedIds)
        : loadRequestDeleteModal(this.selectedIds)
    },
    createTask() {
      lessonAssignmentFolderModal.methods.loadFolder(this.selectedIds[0])
      this.$router.push({ name: 'lesson-tasks-create' })
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length,
        this.$treeView
      )
      this.contextMenuItems = getLessonsTasksActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
      event.preventDefault()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!/create/g.test(from.path) && !/edit/g.test(from.path) && !/preview/g.test(from.path)) {
      clearTreeStates()
    }
    next()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  mounted() {
    loadTreeLight()
  },
  destroyed() {
    this.resetFilters()
  },
})
