






















import Vue, { PropType } from 'vue'
import { DropdownItem } from '@/pages/common/types'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'SelectedItemsList',
  components: {
    Icon,
  },
  props: {
    items: { type: Array as PropType<DropdownItem[]>, required: true },
  },
  methods: {
    async onDeleteItem(item: DropdownItem) {
      // waiting for click outside function to check
      await new Promise((resolve) => {
        setTimeout(resolve, 1)
      })
      this.$emit('onDeleteItem', item.name)
    },
  },
})
