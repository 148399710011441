














































import Vue, { PropType } from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  $titleErrorModule,
  $title,
  titleChanged,
  checkIfFolderCanBeSend,
} from '@/pages/common/modals/system-files/update-element/update-element.model'
import PositionDropdown from '@/pages/common/dropdowns/system-files/position-dropdown/PositionDropdown.vue'

export default Vue.extend({
  name: 'EditingFolderModal',
  components: {
    Modal,
    Icon,
    FormInput,
    BaseButton,
    PositionDropdown,
  },
  effector: {
    $modalVisibility,
    titleError: $titleErrorModule.store.$error,
    $title,
  },
  props: {
    elementType: { type: String as PropType<string>, required: true },
  },
  computed: {
    correctElementName() {
      return this.elementType === 'folder' ? 'папки' : 'файла'
    },
  },
  methods: {
    modalVisibilityChanged,
    checkIfFolderCanBeSend,
    titleChanged,
  },
})
