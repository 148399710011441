import { createEvent, forward, attach, createStore } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getCertificatesTemplatesListFx } from '@/features/api/results/certificates-generator/get-certificates-templates-list'
import { setTemplate } from '@/pages/results/certificates-generator/create-certificates/model/form-fields.model'

export const templatesDropdownModel = createFilter()

const getTemplates = attach({
  effect: getCertificatesTemplatesListFx,
})

export const loadTemplates = createEvent<void>()
export const $templates = createStore<DropdownItem[]>([])

forward({
  from: loadTemplates,
  to: getTemplates.prepend(() => ({})),
})

forward({
  from: getTemplates.doneData.map((res) =>
    res.body.map((item) => ({
      name: `${item.id}`,
      title: item.name,
      certificate_description: item.certificate_description,
      preview_description: item.preview_description,
    }))
  ),
  to: $templates,
})

forward({
  from: templatesDropdownModel.methods.resetDropdown,
  to: setTemplate.prepend(() => null),
})
