<template>
  <div class="content">
    <BaseButton
      small
      @click='openModals'
    >
      Фильтр по результатам
    </BaseButton>
    <div class="datetime-form">
      <div>
        Период:
      </div>
      <DatePicker
        :value="$dateStarted"
        class="datepicker"
        type="date"
        format="DD.MM.YYYY"
        :disabled-date="disabledStarted"
        @input="setFilterDateStarted"
      />
      <div>
        -
      </div>
      <DatePicker
        :value="$dateFinished"
        class="datepicker"
        type="date"
        format="DD.MM.YYYY"
        :disabled-date="disabledFinished"
        @input="setFilterDateFinished"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import {
  $dateStarted,
  $dateFinished,
  setDateStarted,
  setDateFinished,
} from '@/pages/results/tests-all/parts/result-filter/result-tests-filter.model'
import dayjs from 'dayjs'
import { resultTestsFilterModalVisibilityChanged } from '@/pages/common/modals/result-tests-filter/result-tests-filter-modal.model'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ResultTestsFilter',
  components: {
    BaseButton,
    DatePicker,
  },
  effector: {
    $dateStarted,
    $dateFinished,
  },
  methods: {
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    disabledStarted(date) {
      const currentDay = dayjs(new Date())
      if (this.$dateFinished) {
        return dayjs(date).isAfter(currentDay) || dayjs(date).isAfter(this.$dateFinished)
      }
      return dayjs(date).isAfter(currentDay)
    },
    disabledFinished(date) {
      const currentDay = dayjs(new Date())
      const startedDay = this.$dateStarted ? dayjs(this.$dateStarted) : null
      if (startedDay) {
        return dayjs(date).isBefore(startedDay) || dayjs(date).isAfter(currentDay)
      }
      return dayjs(date).isAfter(currentDay)
    },
    setFilterDateStarted(date) {
      setDateStarted(date)
      if (date) {
        this.setItem({ started_at_gt: date })
      } else {
        this.$emit('removeParamsFilter', 'started_at_gt')
      }
      this.$emit('setFilter')
    },
    setFilterDateFinished(date) {
      setDateFinished(date)
      if (date) {
        this.setItem({ finished_at_lt: date })
      } else {
        this.$emit('removeParamsFilter', 'finished_at_lt')
      }
      this.$emit('setFilter')
    },
    openModals() {
      resultTestsFilterModalVisibilityChanged(true)
    },
  },
})
</script>

<style scoped>
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.datetime-form {
  display: flex;
  align-items: center;
  div:not(:last-child) {
    margin-right: 10px;
  }
}
.datepicker {
  width: 120px;
}
</style>

