















































































































import Vue, { VueConstructor } from 'vue'
import { config } from '@/config'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import {
  searchFieldsData,
  incomingDeletionApplicationsDataFields,
  getIncomingDeletionTicketsActions,
} from '@/pages/applications/incoming-deletion/constants'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import PageHeader from '@/pages/applications/incoming-deletion/parts/header/PageHeader.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ApplicationsFilter from '@/pages/applications/incoming-deletion/parts/filter/Filter.vue'
import TooltipCell from '@/pages/applications/incoming-deletion/parts/table/TooltipCell.vue'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  loadList,
  rejectApplications,
  $canRefreshTable,
  incomingDeletionPageParams,
  $isLoading,
  getTicket,
  exportTicketsDeletionList,
} from '@/pages/applications/incoming-deletion/incoming-deletion-applications-page.model'
import {
  toggleVisibility,
  $visibility,
  incomingDeletionFilters,
} from '@/pages/applications/incoming-deletion/parts/filter/filter.model'
import { $permissions } from '@/features/session'
import RejectModal from '@/pages/applications/modals/reject/RejectModal.vue'
import RejectedModal from '@/pages/applications/modals/rejected/RejectedModal.vue'
import { loadModal } from '@/pages/applications/modals/reject/reject.model'
import CommentModal from '@/pages/applications/modals/comment/CommentModal.vue'
import { loadCommentModal } from '@/pages/applications/modals/comment/comment.model'
import {
  CommonInteractedItemParams,
  ActionsItem,
  DisplayContextMenuPayload,
  RefsType,
} from '@/pages/common/types'
import {
  setDataToDelete,
  $cannotDeleteData,
} from '@/pages/applications/modals/cannot-delete/cannot-delete.model'
import CannotDeleteModal from '@/pages/applications/modals/cannot-delete/CannotDeleteModal.vue'
import { mapApplicationTypeToRoute } from '@/pages/applications/constants'
import DeleteModal from '@/pages/applications/modals/delete/DeleteModal.vue'
import { loadModal as loadDeleteModal } from '@/pages/applications/modals/delete/delete.model'
import { CheckBeforeDeletionResponseType } from '@/features/api/ticket/types'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { DEFAULT_ID } from '@/pages/common/constants'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { getActionsDisplayConditions } from '@/pages/common'
import {
  loadFolder as loadMediaFolderToUpdate,
  setElementType,
  $canUpdateApplicationPage as canUpdateApplicationPageMediaFolder,
} from '@/pages/common/modals/system-files/update-element/update-element.model'
import UpdateElementModal from '@/pages/common/modals/system-files/update-element/UpdateElementModal.vue'
import { lessonAssignmentEditFolderModal } from '@/pages/common/modals/tasks-bank/editing-folder/editing-folder-modal.model'
/* import {
  loadFolder as loadParentFolder,
  modalVisibilityChanged as editFolderModal,
  $canUpdateApplicationPage as canUpdateApplicationPageAssignmentFolder,
} from '@/pages/common/modals/tasks-bank/editing-folder/editing-folder-modal.model' */
import EditingLessonFolderModal from '@/pages/common/modals/tasks-bank/editing-folder/EditingLessonFolderModal.vue'
import { axiosClient } from '@/lib/request'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    NoDataContent,
    PageHeader,
    GeneralFilter,
    ApplicationsFilter,
    TableHeader,
    TooltipCell,
    ActionsButton,
    ContextMenu,
    Vuetable,
    VuetablePagination,
    RejectModal,
    RejectedModal,
    CommentModal,
    DeleteModal,
    CannotDeleteModal,
    LoaderBig,
    UpdateElementModal,
    EditingLessonFolderModal,
  },
  effector: {
    $visibility,
    canRefreshTableAfterReject: $canRefreshTable,
    $cannotDeleteData,
    $filterParams: incomingDeletionFilters.store.$filterParams,
    $pageParams: incomingDeletionPageParams.store.$pageParams,
    $currentPage: incomingDeletionPageParams.store.currentPage,
    $isLoading,
    canUpdateApplicationPageMediaFolder,
    canUpdateApplicationPageAssignmentFolder:
      lessonAssignmentEditFolderModal.store.$canUpdateApplicationPage,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      searchFields: searchFieldsData,
      total: 1,
      fields: incomingDeletionApplicationsDataFields,
      selectedRows: [] as number[],
      currentFailed: null as CheckBeforeDeletionResponseType | null,
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/ticket-app/deletion-ticket/list/?exclude_accepted=true`
    },
    showDeleteModal(): boolean {
      return !!this.currentFailed
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getIncomingDeletionTicketsActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    canRefreshTableAfterReject: {
      handler(newVal) {
        if (newVal) {
          this.$refs.vuetable.reload()
          this.removeSelection()
        }
      },
    },
    canUpdateApplicationPageMediaFolder: {
      handler(newVal) {
        if (newVal) {
          this.$refs.vuetable.reload()
          this.removeSelection
        }
      },
    },
    canUpdateApplicationPageAssignmentFolder: {
      handler(newVal) {
        if (newVal) {
          this.$refs.vuetable.reload()
          this.removeSelection
        }
      },
    },
    $cannotDeleteData: {
      handler() {
        this.showNextFailed()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    changeFilter: incomingDeletionFilters.methods.changeFilter,
    resetFilters: incomingDeletionFilters.methods.resetFilters,
    applyFilters: incomingDeletionFilters.methods.applyFilters,
    changePage: incomingDeletionPageParams.methods.changePage,
    queryToParams: incomingDeletionPageParams.methods.queryToParams,
    toggleVisibility,
    loadList,
    reset,
    rejectApplications,
    exportTicketsDeletionList,
    acceptApplications() {
      loadDeleteModal(this.selectedIds)
    },
    loadRejectApplications() {
      loadModal(this.selectedIds)
    },
    checkIfElementCanBeDeleted(ids: number[]) {
      setDataToDelete(ids)
    },
    async getCurrentTicketData(id: number) {
      const ticketData = await getTicket(id).then((res) => {
        return res.body
      })
      return ticketData
    },
    async openApplication() {
      const currentTicketData = await this.getCurrentTicketData(this.selectedIds[0])
      const taskType = currentTicketData.object_type
      const taskId = currentTicketData[taskType].id
      if (taskType === 'media_folder') {
        setElementType('folder')
        loadMediaFolderToUpdate(taskId!)
        return
      }
      if (taskType === 'assignment_folder') {
        lessonAssignmentEditFolderModal.methods.loadFolder(taskId!)
        lessonAssignmentEditFolderModal.methods.modalVisibilityChanged(true)
        return
      }

      navigatePush({
        name: `${mapApplicationTypeToRoute[taskType]}-edit`,
        params: { id: `${taskId}` },
      })
    },
    showComment() {
      loadCommentModal(this.selectedIds[0])
    },
    toggleModal(val: boolean) {
      if (!val) {
        this.currentFailed = null
        this.showNextFailed()
      }
    },
    showNextFailed() {
      if (this.$cannotDeleteData.length === 0 || this.showDeleteModal) return
      this.currentFailed = this.$cannotDeleteData.shift()!
    },
    myFetch(apiUrl: string, httpOptions: any) {
      return axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      reset() // search string and field
      this.resetFilters()
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
      event.preventDefault()
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getIncomingDeletionTicketsActions(
        displayConditions,
        this.$permissions!
      )
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  mounted() {
    loadList({})
  },
  destroyed() {
    this.resetFilters()
  },
})
