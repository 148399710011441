












import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: 'StudentErrorMessage',
  components: {
    Icon,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
})
