<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="themes-filter"
  >
    <div class="row">
      <CourseSubmitApplicationsFilterDropdown class="half-third" @setItem="val => setItem({'is_tickets_acceptable': val})" />
      <CourseTypeDropdown
        class="half-third"
        @setItem="val => setItem({'with_ticket': val})"
      />
      <CourseTaskCheckTypeDropdown
        class="half-third"
        @setItem="val => setItem({'task_check_type': val})"
      />
    </div>
    <div class="row">
      <SubjectsDropdown
        class="half-third"
        @setItem="val => setItem({'subjects': val})"
      />
      <ClassesDropdown
        class="half-third"
        @setItem="val => setItem({'classes': val})"
      />
      <GroupsFilterDropdown
        class="half-third"
        @setItem="val => setItem({'groups': val})"
      />
    </div>
    <div class="row">
      <div class="half-third">
        <BaseSwitch
          class="switch field"
          :checked="$withClasses"
          @change="handleWithLessons"
        >
          <p>Скрыть курсы с классами</p>
        </BaseSwitch>
      </div>
      <div class="half-third">
        <BaseSwitch
          class="switch field"
          :checked="$withNoClasses"
          @change="handleWithNoLessons"
        >
          <p>Скрыть курсы без классов</p>
        </BaseSwitch>
      </div>
      <div class="half-third btn-col">
        <BaseButton
          small
          @click="applyFilters"
        >
          Применить
        </BaseButton>
        <BaseButton
          class="borderless"
          small
          border-without-bg
          @click="resetFilters"
        >
          Сбросить фильтры
        </BaseButton>
      </div>
    </div>
    <Icon
      type="close"
      class="close-icon"
      size="10"
      @click="toggleVisibility(false)"
    />
    <div class="arrow-up" />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import {
  reset,
  toggleVisibility,
  coursesFilters,
  $withClasses,
  $withNoClasses,
  toggleWithClasses,
  toggleWithNoClasses,
} from '@/pages/learning/learning-courses/list/parts/filter/course-filter.model'
import ClickOutside from '@/features/directives/click-outside.ts'
import { dropdownComponents } from '@/pages/learning/learning-courses/list/parts/filter/parts/dropdown-components'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'CourseFilter',
  components: {
    Icon,
    BaseButton,
    BaseSwitch,
    ClassesDropdown: dropdownComponents.ClassesDropdown,
    SubjectsDropdown: dropdownComponents.SubjectsDropdown,
    GroupsFilterDropdown: dropdownComponents.GroupsFilterDropdown,
    CourseSubmitApplicationsFilterDropdown:
      dropdownComponents.CourseSubmitApplicationsFilterDropdown,
    CourseTypeDropdown: dropdownComponents.CourseTypeDropdown,
    CourseTaskCheckTypeDropdown: dropdownComponents.CourseTaskCheckTypeDropdown,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  effector: {
    $filterParams: coursesFilters.store.$filterParams,
    $withClasses,
    $withNoClasses,
  },
  methods: {
    toggleVisibility,
    toggleWithClasses,
    toggleWithNoClasses,
    closeFilter(event) {
      // check for general filter icon
      if (
        !event.target.closest('#filter-icon') &&
        event.target.nodeName !== 'use' &&
        !event.target.closest('.icon-wrapper')
      ) {
        toggleVisibility(false)
      }
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
      reset() // togglers and visibility
    },
    handleWithLessons(value) {
      if (value) {
        this.setItem({ with_classes: false })
        toggleWithNoClasses(false)
      } else {
        this.$emit('removeParamsFilter', 'with_classes')
      }
      toggleWithClasses(value)
    },
    handleWithNoLessons(value) {
      if (value) {
        this.setItem({ with_classes: true })
        toggleWithClasses(false)
      } else {
        this.$emit('removeParamsFilter', 'with_classes')
      }
      toggleWithNoClasses(value)
    },
  },
  beforeDestroy() {
    this.resetFilters()
  },
})
</script>

<style scoped>
.themes-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}
.row:last-of-type {
  justify-content: flex-end;
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}
.two-third {
  width: calc(100% / 3 * 2 - 20px);
  flex-shrink: 0;
}

.btn-col {
  display: flex;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}

.arrow-up {
  @mixin arrow-up;
}
</style>

