import { createEvent, restore } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'

export const mapCourseTaskCheckType = [
  {
    name: 'Автоматизированный',
    title: 'Автоматизированный',
  },
  {
    name: 'С проверкой',
    title: 'С проверкой',
  },
]
export const mapCourseTaskCheckTypeFilter = [
  {
    name: 'Показать все',
    title: 'Показать все',
  },
  ...mapCourseTaskCheckType,
]

export const courseCourseTaskCheckTypeDropdownModule = createFilter()

export const resetCourseCourseTaskCheck = createEvent<void>()
export const setSelectedCourseTaskCheckType = createEvent<DropdownItem | null>()
export const $selectedCourseTaskCheckType = restore(
  setSelectedCourseTaskCheckType,
  mapCourseTaskCheckType[0]
).reset(resetCourseCourseTaskCheck)
