import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetNameOptionsResponseType } from '@/pages/learning/learning-lessons/list/model/types'

export const getLessonsFilterOptionsFx = createApiEffect<void, GetNameOptionsResponseType[]>({
  requestMapper: () => ({
    method: 'GET',
    url: `/api/lesson-app/lessons/filter/names/`,
  }),
})

export const getCoursesFilterOptionsFx = createApiEffect<void, GetNameOptionsResponseType[]>({
  requestMapper: () => ({
    method: 'GET',
    url: `/api/course-app/courses/filter/names/`,
  }),
})

export const getSubjectsFilterOptionsFx = createApiEffect<void, GetNameOptionsResponseType[]>({
  requestMapper: () => ({
    method: 'GET',
    url: `/api/subject-app/subjects/filter/names/`,
  }),
})

export const getThemesFilterOptionsFx = createApiEffect<any, GetNameOptionsResponseType[]>({
  requestMapper: (query) => ({
    method: 'GET',
    url: `/api/subject-app/themes/filter/lesson-themes-names/`,
    query,
  }),
})
