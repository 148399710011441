import { CommonInteractedItemParams } from '@/pages/common/types'

export type TableField = {
  name: string
  sortField?: string
  titleClass?: string
  dataClass?: string
  title: string
  width?: string
  formatter?: (value: any) => any
}

export enum difficultyMap {
  'Базовый',
  'Продвинутый',
  'Экзамен',
}

export type ResourceChip = {
  count: string
  description: string
}

export type ContextMenuType = 'table_tasks' | 'task' | 'table_lessons' | 'assignment'

export type TestTasksInteractedItemData = {
  subject?: number
  studyYear?: number
  isPrerequisite?: boolean
}

export type TestTasksInteractedItemParams = CommonInteractedItemParams & {
  data: TestTasksInteractedItemData
}
