import GroupsDropdown from '@/pages/common/dropdowns/groups/GroupsDropdown.vue'
import StudentsDropdown from '@/pages/common/dropdowns/students/StudentsDropdown.vue'
import StatusDropdown from '@/pages/common/dropdowns/testing/status-dropdown/StatusDropdown.vue'
import TypeDropdown from '@/pages/common/dropdowns/testing/type-dropdown/TypeDropdown.vue'
import DifficultyDropdown from '@/pages/common/dropdowns/testing/difficulty-dropdown/DifficultyDropdown.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import TestsListDropdown from '@/pages/common/dropdowns/tests/TestsListDropdown.vue'

export const dropdownResultsTestsAllComponents = {
  GroupsDropdown,
  StudentsDropdown,
  StatusDropdown,
  TypeDropdown,
  DifficultyDropdown,
  SubjectsDropdown,
  ClassesDropdown,
  TestsListDropdown,
}
