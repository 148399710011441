import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'

export const CertificatesGeneratorTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID серт',
    width: '100px',
  },
  {
    name: 'student_id',
    sortField: 'student_id',
    title: 'ID ученика',
    width: '160px',
  },
  {
    name: 'student_elk_id',
    sortField: 'student_id',
    title: 'ID ELK',
    width: '160px',
  },
  {
    name: 'student_name',
    sortField: 'student_name',
    title: 'Ученик',
    width: '160px',
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    title: 'Класс',
    width: '120px',
  },
  {
    name: 'sample',
    sortField: 'sample',
    title: 'Шаблон',
    width: '150px',
  },
  {
    name: 'course',
    sortField: 'course',
    title: 'Курс/Олимпиада',
    width: '200px',
  },
  {
    name: 'certificate_link',
    sortField: 'certificate_link',
    title: 'Сертификат',
    width: '160px',
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    width: '180px',
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создано',
    width: '160px',
    formatter(created_at: string): string {
      return created_at ? dayjs(created_at).format('DD.MM.YYYY') : '-'
    },
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID сертификата' },
  { name: 'elk_id', title: 'ID ELK' },
  { name: 'studentId', title: 'ID ученика' },
  { name: 'templateName', title: 'Шаблон сертификата' },
  { name: 'group', title: 'Группа' },
  { name: 'course', title: 'Курс' },
  { name: 'createdBy', title: 'Создатель' },
]
