
























import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseInput from '@/ui/input/BaseInput.vue'
import { Size } from '@/pages/common/parts/tasks/types'

export default Vue.extend({
  name: `SizeInput`,
  components: {
    Icon,
    BaseInput,
  },
  props: {
    size: {
      type: Object as PropType<Size>,
      required: true,
    },
  },
  data: () => ({
    relative: true,
    lastScale: 1,
  }),
  methods: {
    changeWidth(size: number) {
      const width = +size
      this.lastScale = this.size.width / this.size.height || 1
      if (this.relative) {
        this.$emit('change', {
          width,
          height: parseFloat((width / this.lastScale).toFixed(1)),
        })
        return
      }
      this.$emit('change', {
        ...this.size,
        width,
      })
    },
    changeHeight(size: number) {
      const height = +size
      this.lastScale = this.size.width / this.size.height || 1
      if (this.relative) {
        this.$emit('change', {
          height,
          width: parseFloat((height * this.lastScale).toFixed(1)),
        })
        return
      }
      this.$emit('change', {
        ...this.size,
        height,
      })
    },
  },
})
