






















































































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import {
  $isLoading,
  requestDeletePartTimeTest,
  $refreshPage,
  partTimeTestPageMounted,
  setIsLoading,
} from '@/pages/exams/part-time-test/list/model/part-time-exams-tests-page.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { loadRequestDeleteModal } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { $permissions } from '@/features/session'
import {
  RefsType,
  HttpOptionsType,
  DisplayContextMenuPayload,
  CommonInteractedItemParams,
} from '@/pages/common/types'
import { navigatePush } from '@/features/navigation/navigationMethods'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { TableHeaderItem } from '@/pages/common/parts/table-header/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import {
  partTimeTestTableFields,
  searchFieldsDataTimeTest,
} from '@/pages/exams/part-time-test/list/model/constants'
import PartTimeTestHeader from '@/pages/exams/part-time-test/list/parts/PartTimeTestHeader.vue'
import TooltipFilter from '@/pages/exams/part-time-test/list/parts/TooltipFilter.vue'
import {
  partTimeTestFilters,
  partTimeTestPageParams,
  exportPartTimeTestList,
} from '@/pages/exams/part-time-test/list/model/part-time-exams-tests-filters.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import {
  toggleVisibility,
  $visibility,
} from '@/pages/exams/part-time-test/list/model/tooltip-filter.model'
import BaseButton from '@/ui/button/BaseButton.vue'
import { formationMapData } from '@/pages/exams/part-time-test/create/parts/FormationDropdown/constant'
import { tasksFormationMapData } from '@/pages/exams/part-time-test/create/parts/TasksFormationDropdown/constant'
import { axiosClient } from '@/lib/request'
import { getPartTimeTestsActions } from '@/pages/exams/part-time-test/constants'
import { getActionsDisplayConditions } from '@/pages/common'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'PartTimeTestPage',
  components: {
    Vuetable,
    VuetablePagination,
    PartTimeTestHeader,
    TableHeader,
    GeneralFilter,
    TooltipFilter,
    TooltipCell,
    ContextMenu,
    ActionsButton,
    NoDataContent,
    ConfirmDeleteModal,
    RequestDeleteModal,
    LoaderBig,
    BaseButton,
  },
  effector: {
    $visibility,
    $isLoading,
    $refreshPage,
    $filterParams: partTimeTestFilters.store.$filterParams,
    $pageParams: partTimeTestPageParams.store.$pageParams,
    $treeView: partTimeTestPageParams.store.treeView,
    $currentPage: partTimeTestPageParams.store.currentPage,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      searchFields: searchFieldsDataTimeTest,
      fields: partTimeTestTableFields,
      isFilters: false,
      total: 0,
      selectedRows: [] as number[],
      showDeleteModalType: 'exams',
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/exams-app/part-time/tests/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length && !this.$treeView) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): TableHeaderItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getPartTimeTestsActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $refreshPage: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.removeSelection()
      },
    },
  },
  methods: {
    changeFilter: partTimeTestFilters.methods.changeFilter,
    resetFilters: partTimeTestFilters.methods.resetFilters,
    applyFilters: partTimeTestFilters.methods.applyFilters,
    toggleTreeView: partTimeTestPageParams.methods.toggleTreeView,
    changePage: partTimeTestPageParams.methods.changePage,
    queryToParams: partTimeTestPageParams.methods.queryToParams,
    toggleVisibility,
    exportPartTimeTestList,
    setIsLoading,
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const request = axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
      return request
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.isFilters = true
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.isFilters = false
      this.resetFilters()
      toggleVisibility(false)
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onRemove() {
      const payload = this.selectedIds
      this.showDeleteModalType = 'exams'
      // TODO: уточнить $permissions
      this.$permissions!.users_group_groups.can_edit
        ? loadConfirmDeleteModal(payload)
        : loadRequestDeleteModal(payload)
    },
    onEdit() {
      const payload = this.selectedIds[0]
      navigatePush({
        name: 'part-time-exams-tests-edit',
        params: { id: `${payload}` },
      })
    },
    async handleConfirmDelete(ids: number[], type: string) {
      if (type === 'exams') {
        await requestDeletePartTimeTest({ tests: ids })
        this.$refs.vuetable.reload()
        this.removeSelection()
      }
    },
    async sendRequestDeletePartTimeTest(comment: string, ids: number[]) {
      await requestDeletePartTimeTest({ tests: ids, ticket_comment: comment })
      this.$refs.vuetable.reload()
      this.removeSelection()
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    headerActionsButtonClick(event: MouseEvent) {
      this.handleActionsClick({
        data: { id: this.selectedIds[0] },
        event,
        type: 'header-actions',
      })
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: { id: number }) {
      this.interactedItemId = data.id
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    endLoading() {
      setTimeout(() => {
        this.setIsLoading(false)
      }, 700)
    },
    getFormation(formation: number) {
      return formationMapData[String(formation)]?.title || ''
    },
    getTasksFormation(tasks_formation: number) {
      return tasksFormationMapData[String(tasks_formation)]?.title || ''
    },
  },
  mounted() {
    partTimeTestPageMounted()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
