





















import Vue, { PropType } from 'vue'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import BaseButton from '@/ui/button/BaseButton.vue'

export default Vue.extend({
  name: 'InstructionsHeader',
  components: {
    DownloadButton,
    GridPageHead,
    HeaderPopup,
    BaseButton,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<number[]> },
  },
})
