import { TableField } from '@/pages/applications/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'

export const testsResultDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID результата',
    width: '140px',
  },
  {
    name: 'test_pk',
    sortField: 'test_pk',
    title: 'ID теста',
    width: '120px',
  },
  {
    name: 'test_generator',
    sortField: 'test_generator',
    title: 'Тип ген.',
    width: '120px',
  },
  {
    name: 'subject_name',
    sortField: 'subject_name',
    title: 'Предмет',
    width: '120px',
  },
  {
    name: 'test_name',
    sortField: 'test_name',
    dataClass: 'wrap',
    title: 'Название',
    width: '170px',
  },
  {
    name: 'test_difficulty',
    sortField: 'test_difficulty',
    dataClass: 'wrap',
    title: 'Уровень',
    width: '120px',
    formatter(difficulty: number) {
      switch (difficulty) {
        case 0:
          return 'Базовый'
        case 1:
          return 'Продвинутый'
        default:
          return '-'
      }
    },
  },
  {
    name: 'study_year_number',
    sortField: 'study_year_number',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Класс',
    width: '100px',
  },
  {
    name: 'groups',
    sortField: 'groups',
    title: 'Группы',
    width: '150px',
  },
  {
    name: 'student_pk',
    sortField: 'student_pk',
    title: 'ID абитуриента',
    width: '150px',
  },
  {
    name: 'student_elk_id',
    sortField: 'student_elk_id',
    title: 'ID ЕЛК',
    width: '90px',
  },
  {
    name: 'student_name',
    sortField: 'student_name',
    title: 'Абитуриент',
    width: '150px',
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус',
    width: '120px',
    formatter(status: number) {
      switch (status) {
        case 1:
          return 'Продолжается'
        case 2:
          return 'Завершен'
        default:
          return '-'
      }
    },
  },
  {
    name: 'started_at',
    sortField: 'started_at',
    title: 'Дата начала',
    width: '180px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'finished_at',
    sortField: 'finished_at',
    title: 'Дата завершения',
    width: '180px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'test_duration',
    sortField: 'test_duration',
    dataClass: 'center aligned',
    title: 'Дл. теста',
    width: '130px',
    formatter(test_duration: number) {
      return test_duration > 60
        ? Math.round(test_duration / 60)
        : Number(test_duration / 60).toFixed(1)
    },
  },
  {
    name: 'themes_amount',
    sortField: 'themes_amount',
    dataClass: 'center aligned',
    title: 'Кол. тем',
    width: '120px',
  },
  {
    name: 'progress',
    sortField: 'progress',
    dataClass: 'center aligned',
    title: 'Прогресс',
    width: '120px',
  },
  {
    name: 'progress_percent',
    sortField: 'progress_percent',
    dataClass: 'center aligned',
    title: 'Прогресс (%)',
    width: '140px',
  },
  {
    name: 'result_score',
    sortField: 'result_score',
    dataClass: 'center aligned',
    title: 'Результат',
    width: '120px',
  },
  {
    name: 'result_percent',
    sortField: 'result_percent',
    dataClass: 'center aligned',
    title: 'Результат (%)',
    width: '140px',
  },
  {
    name: 'trainings_correct_themes_percent',
    sortField: 'trainings_correct_themes_percent',
    dataClass: 'center aligned',
    title: 'Прогресс по трен.',
    width: '170px',
  },
  {
    name: 'trainings_until_complete',
    sortField: 'trainings_until_complete',
    dataClass: 'center aligned',
    title: 'Количество трен. до 100%',
    width: '230px',
  },
  {
    name: 'trainings_amount',
    sortField: 'trainings_amount',
    dataClass: 'center aligned',
    title: 'Общее кол-во трен.',
    width: '190px',
  },
  {
    name: 'correct_themes_percent_until_complete',
    sortField: 'correct_themes_percent_until_complete',
    dataClass: 'center aligned',
    title: 'Суммарный результат (%)',
    width: '230px',
  },
  {
    name: 'completed_themes',
    sortField: 'completed_themes',
    dataClass: 'wrap',
    title: 'Темы теста освоенные',
    width: '220px',
  },
  {
    name: 'not_completed_themes',
    sortField: 'not_completed_themes',
    dataClass: 'wrap',
    title: 'Темы теста неосвоенные',
    width: '220px',
  },
  {
    name: 'assignments_ids_all',
    sortField: 'assignments_ids_all',
    dataClass: 'wrap',
    title: 'Задания (все)',
    width: '170px',
  },
  {
    name: 'assignments_ids_correct',
    sortField: 'assignments_ids_correct',
    dataClass: 'wrap',
    title: 'Задания (верно решены)',
    width: '230px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'test_id', title: 'ID теста' },
  { name: 'test_name', title: 'Название теста' },
  { name: 'subject_name', title: 'Предмет' },
  // TODO: нет параметров поиска по группам
  { name: 'group', title: 'Учебная группа' },
  { name: 'student_id', title: 'ID абитуриента' },
  { name: 'student_name', title: 'Именя и фамилия абитуриента' },
  { name: 'result_score', title: 'Результаты (баллы)' },
  { name: 'result_percent', title: 'Результаты (проценты)' },
]
