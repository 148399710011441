import { createEvent, forward, restore, sample, split } from 'effector-root'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $groupsSendForm } from '@/pages/users/groups/create/model/form-fileds.model'
import { createGroupFx } from '@/features/api/groups/post-new-group'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { condition } from 'patronum'
import { $editedGroupId, changeGroupId } from '@/pages/users/groups/create/model/edit-group.model'
import { updateGroupFx } from '@/features/api/groups/patch-edit-group'

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  createGroupFx.fail,
])

export const getPageParams = createEvent()

const createGroup = createEvent()
const updateGroup = createEvent<string>()

const groupId = sample({
  source: $editedGroupId,
  clock: getPageParams,
  fn: (id) => (id.length > 0 ? id : false),
})

split({
  source: groupId,
  match: {
    post: (payload) => payload === false,
    patch: (payload) => payload !== false,
  },
  cases: {
    post: createGroup,
    patch: updateGroup,
  },
})

sample({
  source: $groupsSendForm,
  clock: createGroup,
  fn: (form) => form,
  target: createGroupFx,
})

sample({
  source: $groupsSendForm,
  clock: updateGroup,
  fn: (form, id) => ({ body: form, id }),
  target: updateGroupFx,
})

forward({
  from: createGroupFx.done,
  to: successToastEvent('Группа создана'),
})

forward({
  from: updateGroupFx.done,
  to: successToastEvent('Группа изменена'),
})

forward({
  from: [createGroupFx.fail, updateGroupFx.fail],
  to: errorToastEvent('Что-то пошло не так'),
})

const $redirectHandler = sample({
  clock: [
    createGroupFx.doneData.map((res) => res.body.id),
    updateGroupFx.doneData.map((res) => res.body.id),
  ],
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'groups-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => {
    changeGroupId(String(payload.id))
    return {
      name: 'edit-group',
      params: { id: `${payload.id}` },
    }
  }),
})
