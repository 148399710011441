





























































import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import CertificatesTemplatesListDropdown from '@/pages/results/certificates-generator/create-certificates/components/CertificatesTemplatesListDropdown.vue'
import {
  setFiles,
  $addFileErrors,
  setAddFileErrors,
  $files,
  $isAddErrors,
  setTemplate,
  $template,
} from '@/pages/results/certificates-generator/create-certificates/model/form-fields.model'
import CertificatesFileInput from '@/pages/results/certificates-generator/create-certificates/components/CertficatesFileInput.vue'

export default Vue.extend({
  name: 'CreateCertificatesForm',
  components: {
    CertificatesFileInput,
    CertificatesTemplatesListDropdown,
    Icon,
  },
  effector: {
    $files,
    $addFileErrors,
    $isAddErrors,
    $template,
  },
  methods: {
    setFiles,
    setTemplate,
    closeErrors() {
      setAddFileErrors([])
    },
  },
})
