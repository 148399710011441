import { createApiEffect } from '@/features/api/common/create-api-effect'
import { PartTimeCreateFxParams, PartTimeCreateType } from '@/features/api/part-time-test/types'

export const updatePartTimeFx = createApiEffect<
  { body: PartTimeCreateType; id: string },
  PartTimeCreateFxParams
>({
  requestMapper: ({ body, id }) => ({
    method: 'PATCH',
    url: `/api/exams-app/part-time/tests/${id}/`,
    body,
  }),
})
