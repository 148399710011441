import { createEvent, createStore, guard } from 'effector-root'
import { resetFields } from '@/pages/exams/part-time-test/create/model/form-fileds.model'
import { getPartTimeTestIdFx } from '@/features/api/part-time-test/get-part-time-dy-id'

export const changePartTimeTestId = createEvent<string | never>()

export const $editedPartTimeTestId = createStore<string>('')
  .on(changePartTimeTestId, (state, payload) => payload || state)
  .reset(resetFields)

guard({
  source: changePartTimeTestId,
  filter: (payload) => !!payload,
  target: getPartTimeTestIdFx,
})
