import { createApiEffect } from '@/features/api/common/create-api-effect'
import TDefaultFiltersSectionKind from '@/features/api/filters/types/TDefaultFiltersSectionKind'
import TDefaultFilterItem from '@/features/api/filters/types/TDefaultFilterItem'

export const getDefaultFiltersFx = createApiEffect<
  TDefaultFiltersSectionKind,
  TDefaultFilterItem[]
>({
  requestMapper: (kind) => ({
    method: 'GET',
    url: `/api/content-app/filters/default?kind=${kind}`,
  }),
})
