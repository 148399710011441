












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  $studentsType,
  studentsTypeDropdownModule,
} from '@/pages/common/dropdowns/exams/appointments-students-type/appointments-students-type.model'
import { mapStudentsType } from '@/pages/exams/appointments/create/model/constants'

export default Vue.extend({
  name: 'AppointmentsStudentsTypeDropdown',
  components: {
    FilterDropdown,
  },
  effector: {
    $studentsType,
    ...studentsTypeDropdownModule.store,
  },
  methods: {
    ...studentsTypeDropdownModule.methods,
    onSelectItem(item: DropdownItem) {
      this.$emit('setItem', item)
    },
  },
  beforeMount() {
    if (!this.$item) {
      this.itemChanged(mapStudentsType[0])
    }
  },
})
