import { attach, combine, createEvent, forward, restore, sample, createEffect } from 'effector-root'
import { condition } from 'patronum'
import { DEFAULT_ID } from '@/pages/common/constants'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { uploadMediaFx } from '@/features/api/media/upload-media'
import {
  $selectedType,
  resetSelectedType,
  typeDropdownModule,
} from '@/pages/common/dropdowns/content/onboarding/type/type-dropdown.model'
import { createSlideFx } from '@/features/api/content/onboarding/create-slide'
import { UploadMediaResponse } from '@/features/api/media/types'

const saveSlideFx = attach({
  effect: createSlideFx,
})
const uploadMediaResourceFx = attach({
  effect: uploadMediaFx,
})

export const clearFields = createEvent<void>()

export const create = createEvent<void>()

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

export const resetName = createEvent<void>()
export const nameChanged = createEvent<string>()
export const $name = restore(nameChanged, '').reset(resetName)

export const resetTitle = createEvent<void>()
export const titleChanged = createEvent<string>()
export const $title = restore(titleChanged, '').reset(resetTitle)

export const resetText = createEvent<void>()
export const textChanged = createEvent<string>()
export const $text = restore(textChanged, '').reset(resetText)

export const resetMediaFile = createEvent<void>()
export const setMediaFile = createEvent<UploadMediaResponse | null>()
export const $mediaFile = restore(setMediaFile, null).reset(resetMediaFile)

export const toggleIsActive = createEvent<boolean>()
export const $isActive = restore(toggleIsActive, false).reset(clearFields)

export const toggleShowTitle = createEvent<boolean>()
export const $showTitle = restore(toggleShowTitle, false).reset(clearFields)

export const toggleShowText = createEvent<boolean>()
export const $showText = restore(toggleShowText, false).reset(clearFields)

export const toggleShowMedia = createEvent<boolean>()
export const $showMedia = restore(toggleShowMedia, false).reset(clearFields)

forward({
  from: clearFields,
  to: [
    resetName,
    resetTitle,
    resetText,
    resetMediaFile,
    typeDropdownModule.methods.resetDropdown,
    resetSelectedType,
  ],
})

export const $disabledSaveButtons = combine(
  $title,
  $name,
  $text,
  $selectedType,
  (title, name, text, type) => !title || !name || !text || !type?.name
)

export const $formToSend = combine({
  id: DEFAULT_ID,
  name: $name,
  title: $title,
  text: $text,
  media_id: $mediaFile.map((data) => (data && data!.id) || null),
  active: $isActive,
  show_title: $showTitle,
  show_text: $showText,
  show_media: $showMedia,
})

sample({
  clock: create,
  source: $formToSend,
  target: saveSlideFx,
})

const $ifRedirectTheme = sample({
  clock: saveSlideFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

forward({
  from: saveSlideFx.doneData,
  to: successToastEvent('Слайд успешно создан!'),
})

condition({
  source: $ifRedirectTheme,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'slides-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'slides-edit',
    params: { id: `${payload.id}` },
  })),
})

export const uploadResource = createEvent<FileList>()

const uploadResourcesFx = createEffect<FileList, void>({
  handler: (files) => {
    Array.from(files).forEach((file) => {
      const data = new FormData()
      data.append('file', file)
      const type = file.type.includes('image') ? 'img' : 'video'
      data.append('file_type', type)
      uploadMediaResourceFx(data)
    })
  },
})

forward({
  from: uploadResource,
  to: uploadResourcesFx,
})

forward({
  from: uploadMediaResourceFx.doneData.map((res) => res.body),
  to: setMediaFile,
})
