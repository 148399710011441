

















































































import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import {
  $cmsCommentary,
  $internalName,
  $IsActive,
  $name,
  $instruction,
  $resume,
  $information,
  setInformation,
  setResume,
  setInstruction,
  setName,
  setCmsCommentary,
  setInternalName,
  setIsActive,
} from '@/pages/exams/full-time-exam-test/create/model/form-fields.model'
import ExamTestsFolders from '@/pages/common/dropdowns/exams/exams-tests-folders/ExamTestsFolders.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'

export default Vue.extend({
  name: 'CreateExamTestTab1',
  components: {
    FormInput,
    BaseSwitch,
    BaseTextarea,
    Wysiwyg,
    ExamTestsFolders,
    SubjectsDropdown,
    ClassesDropdown,
  },
  effector: {
    $internalName,
    $IsActive,
    $cmsCommentary,
    $name,
    $instruction,
    $resume,
    $information,
  },
  methods: {
    setInternalName,
    setIsActive,
    setCmsCommentary,
    setName,
    setInstruction,
    setInformation,
    setResume,
  },
})
