
































import Vue, { PropType } from 'vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { tagsPageParams } from '@/pages/tags/tags-page.model'
import { modalVisibilityChanged } from '@/pages/tags/parts/modals/tag-creation/tag-creation.modal'
import { $permissions } from '@/features/session'

export default Vue.extend({
  components: {
    BaseSwitch,
    BaseButton,
    DownloadButton,
    HeaderPopup,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
  },
  effector: {
    $treeView: tagsPageParams.store.treeView,
    $permissions,
  },
  methods: {
    modalVisibilityChanged,
    toggleTreeView: tagsPageParams.methods.toggleTreeView,
  },
})
