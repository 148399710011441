

















































































































































import Vue from 'vue'
import {
  $taskScores,
  toggleTaskScores,
  setTasksBlock,
  $tasksBlock,
  setCurrentEditTasks,
} from '@/pages/common/parts/tests/manual-generation/manual-generation-form.model'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Icon from '@/ui/icon/Icon.vue'
import TestEditTaskModal from '@/pages/testing/tests/common/parts/modals/test-edit-task/TestEditTaskModal.vue'
import Draggable from 'vuedraggable'
import { TaskBlock } from '@/pages/common/parts/tests/types'
import { modalVisibilityChanged } from '@/pages/testing/tests/common/parts/modals/test-edit-task/test-edit-task-modal.model'
import { $token } from '@/features/api/common/request'
import { formatTitle } from '@/features/lib'
import { setSelectedThemes } from '@/pages/common/parts/tests/automatic-generation/parts/themes-dropdown/themes-dropdown.model'
import { testsAssignmentDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/tests-assignment-dropdown-filter/tests-assignment-dropdown.model'

export default Vue.extend({
  name: 'ManualGenerationForm',
  components: {
    BaseSwitch,
    BaseButton,
    Icon,
    TestEditTaskModal,
    Draggable,
  },
  effector: {
    $taskScores,
    $tasksBlock,
    $token,
  },
  data() {
    return {
      info: 'В этом задании баллы начисляются за каждый верный ответ. Если вы хотите учитывать только полностью верный ответ, переведите тумблер “Использовать баллы за задание” в неактивное состояние.',
    }
  },
  methods: {
    toggleTaskScores,
    setTasksBlock,
    setCurrentEditTasks,
    addTask() {
      setTasksBlock([
        ...this.$tasksBlock,
        {
          id: this.$tasksBlock.length,
          tasks: [],
          theme: {
            name: '',
            title: '',
          },
          pointsForTask: 1,
          isNew: true,
        },
      ])
    },
    handleEditTask(task: TaskBlock) {
      setCurrentEditTasks(task)
      setSelectedThemes([task.theme])
      testsAssignmentDropdownModel.methods.setSelectedItems(task.tasks)
      modalVisibilityChanged(true)
    },
    removeTask(id: number) {
      setTasksBlock([...this.$tasksBlock.filter((block, index: number) => index !== id)])
    },
    handlerEnd() {
      setTasksBlock(JSON.parse(JSON.stringify(this.$tasksBlock)))
    },
    handleAddTasks(task: TaskBlock) {
      setCurrentEditTasks(task)
      modalVisibilityChanged(true)
    },
    getScore(score: number) {
      if (score) {
        return formatTitle(score, ['балл', 'балла', 'баллов'])
      }
      return ''
    },
    setScore(value: string, id: number) {
      if (value) {
        setTasksBlock(
          this.$tasksBlock.map((item, index) =>
            index !== id ? item : { ...item, pointsForTask: Number(value) }
          )
        )
      }
    },
  },
})
