import { combine, createEvent, restore, forward } from 'effector-root'
import { spread } from 'patronum'
import { getPartTimeTestIdFx } from '@/features/api/part-time-test/get-part-time-dy-id'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { tasksChoiceDropdownModel } from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/tasks-choice-dropdown.model'
import { formationDropdownModel } from '@/pages/exams/part-time-test/create/parts/FormationDropdown/formation-dropdown.model'
import { testsDropdownModel } from '@/pages/exams/part-time-test/create/parts/TestsDropdown/test-list-dropdown.model'
import {
  $selectedThemes,
  resetThemes,
  setSelectedThemes,
} from '@/pages/exams/part-time-test/create/parts/ThemesDropdown/themes-dropdown.model'
import { tasksFormationDropdownModel } from '@/pages/exams/part-time-test/create/parts/TasksFormationDropdown/tasks-formation-dropdown.model'
import { Test } from '@/features/api/test/types'
import { DropdownItem } from '@/pages/common/types'

const themesReorder = (items: { id: number; name: string }[]): DropdownItem[] => {
  return items.map((item, index) => ({
    title: item.name,
    name: `${item.id}`,
    id: index,
  }))
}

export const resetFields = createEvent()

// step1
export const setInternalPartTimeName = createEvent<string>()
export const $internalPartTimeName = restore(setInternalPartTimeName, '').reset(resetFields)

export const setPartTimeIsActive = createEvent<boolean>()
export const $partTimeIsActive = restore(setPartTimeIsActive, false).reset(resetFields)

export const setThemesAmount = createEvent<number>()
export const $themesAmount = restore(setThemesAmount, 1).reset(resetFields)

export const setDurationMin = createEvent<number>()
export const $durationMin = restore(setDurationMin, null).reset(resetFields)

export const setCmsCommentary = createEvent<string>()
export const $cmsCommentary = restore(setCmsCommentary, '').reset(resetFields)

// step2
export const setPartTimeName = createEvent<string>()
export const $partTimeName = restore(setPartTimeName, '').reset(resetFields)

export const setPartTimeInstruction = createEvent<string>()
export const $partTimeInstruction = restore(setPartTimeInstruction, '').reset(resetFields)

export const setPartTimeResume = createEvent<string>()
export const $partTimeResume = restore(setPartTimeResume, '').reset(resetFields)

export const $disabledSaveButtons = combine(
  $partTimeName,
  classesDropdownModule.store.$item,
  subjectsDropdownModel.store.$item,
  tasksChoiceDropdownModel.store.$item,
  formationDropdownModel.store.$item,
  testsDropdownModel.store.$item,
  $selectedThemes,
  tasksFormationDropdownModel.store.$item,
  (name, classes, subject, tasksChoice, formation, test, selectedThemes, tasks_formation) => {
    const formationLogic = formation?.name === '0' ? !test?.title : selectedThemes.length < 1
    return (
      !name ||
      !classes?.title ||
      !subject?.title ||
      !tasksChoice?.title ||
      formationLogic ||
      !tasks_formation?.title
    )
  }
)

const $basePartTimeSendForm = combine(
  $partTimeName,
  $partTimeIsActive,
  $cmsCommentary,
  $internalPartTimeName,
  $partTimeInstruction,
  $partTimeResume,
  $durationMin,
  (name, is_active, cms_commentary, internal_name, instruction, resume, duration_min) => {
    return {
      name,
      is_active,
      cms_commentary,
      internal_name,
      instruction,
      resume,
      duration_min,
    }
  }
)

export const $partTimeSendForm = combine(
  $basePartTimeSendForm,
  classesDropdownModule.store.$item,
  subjectsDropdownModel.store.$item,
  tasksChoiceDropdownModel.store.$item,
  formationDropdownModel.store.$item,
  tasksFormationDropdownModel.store.$item,
  $themesAmount,
  testsDropdownModel.store.$item,
  $selectedThemes,
  (
    base,
    study_year,
    subject,
    tasks_choice,
    formation,
    tasks_formation,
    themes_amount,
    tests,
    themes
  ) => {
    return {
      ...base,
      study_year_id: study_year ? study_year.name : null,
      subject_id: subject ? subject.name : null,
      tasks_choice: tasks_choice ? tasks_choice.name : null,
      formation: formation ? formation.name : null,
      ...(formation && formation.name === '0'
        ? { test_id: tests?.name || null }
        : { themes_ids: themes.map((theme) => theme.name) || null }),
      tasks_formation: tasks_formation ? tasks_formation.name : null,
      ...(tasks_formation && tasks_formation.name === '0' ? { themes_amount } : {}),
    }
  }
)

spread({
  source: getPartTimeTestIdFx.doneData.map((res) => ({
    ...res.body,
    name: res.body.name,
    subject: res.body.subject,
    study_year: res.body.study_year,
    tasks_choice: res.body.tasks_choice,
    formation: res.body.formation,
    tasks_formation: res.body.tasks_formation,
    themes_amount: res.body.themes_amount,
    duration_min: res.body.duration_min,
    cms_commentary: res.body.cms_commentary,
    internal_name: res.body.internal_name,
    test: res.body.test,
    instruction: res.body.instruction,
    resume: res.body.resume,
    is_active: res.body.is_active,
    themes: res.body.themes,
  })),
  targets: {
    name: $partTimeName,
    subject: subjectsDropdownModel.methods.itemChanged.prepend(
      (payload: { id: number; name: string } | null) => {
        if (payload)
          return {
            name: `${payload.id}`,
            title: payload.name,
          }
        return null
      }
    ),
    study_year: classesDropdownModule.methods.itemChanged.prepend(
      (payload: { id: number; name: string } | null) => {
        if (payload)
          return {
            name: `${payload.id}`,
            title: payload.name,
          }
        return null
      }
    ),
    tasks_choice: tasksChoiceDropdownModel.methods.itemChanged.prepend((payload: number) => {
      if (payload === 0)
        return {
          name: '0',
          title: 'Свободный',
        }
      return {
        name: '1',
        title: 'Последовательный',
      }
    }),
    formation: formationDropdownModel.methods.itemChanged.prepend((payload: number) => {
      if (payload === 0)
        return {
          name: '0',
          title: 'Из освоенных учеником тем в тесте',
        }
      return {
        name: '1',
        title: 'Самостоятельный выбор тем',
      }
    }),
    tasks_formation: tasksFormationDropdownModel.methods.itemChanged.prepend((payload: number) => {
      if (payload === 0)
        return {
          name: '0',
          title: 'Случайный выбор n тем',
        }
      return {
        name: '1',
        title: 'Все темы из доступных',
      }
    }),
    themes_amount: $themesAmount,
    duration_min: $durationMin,
    cms_commentary: $cmsCommentary,
    internal_name: $internalPartTimeName,
    test: testsDropdownModel.methods.itemChanged.prepend((payload: Test) => {
      if (payload)
        return {
          name: `${payload.id}`,
          title: `(${payload.id}) ${payload.name}`,
        }
      return null
    }),
    instruction: $partTimeInstruction,
    resume: $partTimeResume,
    is_active: $partTimeIsActive,
    themes: setSelectedThemes.prepend((themes: { id: number; name: string }[]) =>
      themes ? themesReorder(themes) : []
    ),
  },
})

forward({
  from: resetFields,
  to: [
    formationDropdownModel.methods.resetDropdown,
    tasksFormationDropdownModel.methods.resetDropdown,
    tasksChoiceDropdownModel.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    testsDropdownModel.methods.resetDropdown,
    resetThemes,
  ],
})
