import { createEvent, restore } from 'effector-root'
import { coursesDropdownModel } from '@/pages/common/dropdowns/courses/courses-name-dropdown/courses-dropdown.model'
import { lessonsDropdownModule } from '@/pages/common/dropdowns/lessons/lesson-dropdown.model'
import { usersDropdownModel } from '@/pages/common/dropdowns/users/users-dropdown/users-dropdown.model'
import { shownDropdownModel } from '@/pages/callback/comments-on-lessons/parts/dropdowns/shown-dropdown/shown-dropdown.model'

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false)

export const dropdownCommentsModules = {
  coursesDropdownModel,
  lessonsDropdownModule,
  usersDropdownModel,
  shownDropdownModel,
}
