import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportAssignmentQueryParams } from '@/features/api/assignment/types/types'

export const exportStudentsListFx = createApiEffect<
  ExportAssignmentQueryParams,
  { task_id: string }
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/user-app/student/list/export/',
    query,
  }),
})
