import { createEvent, forward, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { debounce } from 'patronum'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { getStudentsListFx } from '@/features/api/part-time-answer/get-students-list'
import { StudentExamAnswerData } from '@/features/api/part-time-answer/types'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/exams-tests-filter/exams-test-filter.model'

export const studentsExamAnswerDropdownModel =
  createDropdownModel<StudentExamAnswerData>(getStudentsListFx)

export const loadStudents = createEvent<void>()
export const searchStudents = createEvent<void>()

sample({
  clock: searchStudents,
  source: {
    search: studentsExamAnswerDropdownModel.store.$searchString,
    tests: examTestsDropdownModel.store.$selectedItems,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search ? { search: params.search, search_area: 'search_student' } : {}),
    ...(params.tests && params.tests.length > 0
      ? { tests: params.tests.map((group) => group.name) }
      : {}),
  }),
  target: getStudentsListFx,
})

sample({
  clock: loadStudents,
  source: {
    $nextPage: studentsExamAnswerDropdownModel.store.$nextPage,
    search: studentsExamAnswerDropdownModel.store.$searchString,
    tests: examTestsDropdownModel.store.$selectedItems,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search, search_area: 'search_student' }),
    ...(params.tests && params.tests.length > 0
      ? { tests: params.tests.map((group) => group.name) }
      : {}),
  }),
  target: getStudentsListFx,
})

const debounced = debounce({
  source: studentsExamAnswerDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchStudents,
})

forward({
  from: [
    studentsExamAnswerDropdownModel.methods.canLoadNextPage,
    groupsDropdownModel.store.$selectedItems,
    examTestsDropdownModel.store.$selectedItems,
  ],
  to: loadStudents,
})

sample({
  clock: getStudentsListFx.doneData,
  source: {
    items: studentsExamAnswerDropdownModel.store.$items,
  },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((user) => ({
      name: `${user.id}`,
      title: `(${user.id}) ${user.first_name} ${user.last_name}`,
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: studentsExamAnswerDropdownModel.store.$items,
})
