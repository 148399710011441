






















import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'BaseSwitch',
  props: {
    checked: { type: Boolean as PropType<boolean> },
    disabled: { type: Boolean as PropType<boolean>, default: false },
    blue: { type: Boolean, default: false },
  },
  model: {
    event: 'change',
    prop: 'checked',
  },
})
