





















































































































































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import { $token } from '@/features/api/common/request'
import PageHeader from '@/pages/bank/test-tasks/list/parts/PageHeader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ThemesFilter from '@/pages/bank/test-tasks/list/parts/test-tasks-filter/ThemesFilter.vue'
import ModeratorSelectModal from '@/pages/bank/common/modals/moderator-select/ModeratorSelectModal.vue'
import TasksTree from '@/pages/bank/test-tasks/list/parts/tasks-tree/TasksTree.vue'
import {
  loadTree,
  loadTreeLight,
  $tasksTreeTotal,
  deleteAssignments,
  requestDeleteAssignments,
  sendAssignmentsPublish,
  testTaskPageParams,
  duplicateAssignment,
  $canRefreshAfterDuplicate,
  $isLoading,
  taskLoader,
} from '@/pages/bank/test-tasks/list/tasks-page.model'
import {
  toggleVisibility,
  $visibility,
  testTasksFilters,
} from '@/pages/bank/test-tasks/list/parts/test-tasks-filter/test-tasks-filter.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  TestTasksTableFields,
  searchFieldsData,
  getTestTasksActions,
} from '@/pages/bank/test-tasks/list/constants'
import { DEFAULT_ID, mapTaskTypeTo } from '@/pages/common/constants'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { loadRequestDeleteModal } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { $currentUser, $permissions } from '@/features/session'
import { deleteThemes } from '@/pages/dictionary/themes/list/themes-page.model'
import {
  RefsType,
  HttpOptionsType,
  DisplayContextMenuPayload,
  ActionsItem,
} from '@/pages/common/types'
import TasksTypesModal from '@/pages/common/modals/tasks-bank/tasks-types/TasksTypesModal.vue'
import TasksUpdateModal from '@/pages/bank/test-tasks/list/parts/modals/tasks-update/TasksUpdateModal.vue'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import { $canRefreshAfterMultiChanges } from '@/pages/bank/test-tasks/list/parts/modals/tasks-update/tasks-update-modal.model'
import {
  $canRefreshAfterSendingToReview,
  loadModalToSendForCheck,
} from '@/pages/bank/common/modals/moderator-select/moderator-select.model'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import {
  combineRouteQueries,
  computeSortParam,
  isQueryParamsEquelToPage,
  removeHtmlTags,
} from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import DuplicateModal from '@/pages/bank/common/modals/duplicate/DuplicateModal.vue'
import { loadDuplicateModal } from '@/pages/bank/common/modals/duplicate/duplicate.model'
import {
  TestTasksInteractedItemData,
  TestTasksInteractedItemParams,
} from '@/pages/bank/test-tasks/list/types'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import { clearTreeStates } from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { getActionsDisplayConditions } from '@/pages/common'
import qs from 'qs'
import { Employee } from '@/features/api/employee/types'
import { axiosClient } from '@/lib/request'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'TestTasksList',
  components: {
    DuplicateModal,
    Vuetable,
    VuetablePagination,
    PageHeader,
    TableHeader,
    GeneralFilter,
    ThemesFilter,
    TooltipCell,
    ContextMenu,
    TasksTree,
    NoDataContent,
    TasksTypesModal,
    TasksUpdateModal,
    ConfirmDeleteModal,
    RequestDeleteModal,
    ModeratorSelectModal,
    LoaderBig,
    ActionsButton,
  },
  effector: {
    $token,
    $visibility,
    $tasksTreeTotal,
    $isLoading,
    $filterParams: testTasksFilters.store.$filterParams,
    $canRefreshAfterDuplicate,
    $canRefreshAfterMultiChanges,
    $canRefreshAfterSendingToReview,
    $pageParams: testTaskPageParams.store.$pageParams,
    $treeView: testTaskPageParams.store.treeView,
    $currentPage: testTaskPageParams.store.currentPage,
    $permissions,
    $currentUser,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'items' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      searchFields: searchFieldsData,
      fields: TestTasksTableFields,
      total: 0,
      selectedRows: [] as number[],
      subject: DEFAULT_ID as number,
      studyYear: DEFAULT_ID as number,
      isPrerequisite: false as boolean,
      theme: DEFAULT_ID as number,
      showDeleteModalType: 'task',
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/assignment-app/test-assignment/list/`
    },
    canRemoveTask(): boolean {
      const hasPermissionToRemove =
        this.$permissions!.assignments_testassignment_difficulty_base.can_edit ||
        this.$permissions!.assignments_testassignment_difficulty_pro.can_edit ||
        this.$permissions!.assignments_testassignment_difficulty_exam.can_edit
      return hasPermissionToRemove && this.$currentUser!.role !== 'METHODIST'
    },
    selectedIds(): number[] {
      if (this.selectedRows.length && !this.$treeView) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getTestTasksActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $canRefreshAfterDuplicate: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
    $canRefreshAfterMultiChanges: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
      },
    },
    $canRefreshAfterSendingToReview: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.removeSelection()
      },
    },
  },
  methods: {
    changeFilter: testTasksFilters.methods.changeFilter,
    resetFilters: testTasksFilters.methods.resetFilters,
    applyFilters: testTasksFilters.methods.applyFilters,
    toggleTreeView: testTaskPageParams.methods.toggleTreeView,
    changePage: testTaskPageParams.methods.changePage,
    queryToParams: testTaskPageParams.methods.queryToParams,
    loadTree,
    toggleVisibility,
    duplicateAssignment,
    loadDuplicateModal,
    correctCreatedUpdatedByTitle(rowData: Employee) {
      return (rowData && `${rowData.first_name} ${rowData.last_name}`) || '-'
    },
    showPreview() {
      this.$router.push({
        name: 'preview-task',
        query: {
          questions: this.selectedIds.join(','),
          fromPage: 'tasks',
          taskType: 'test-assignment',
          token: this.$token,
        },
      })
    },
    clearWording(str: string) {
      return removeHtmlTags(str)
    },
    getCorrectIconType(type: string) {
      return mapTaskTypeTo[type].icon
    },
    getCorrectDescriptionType(type: string) {
      return mapTaskTypeTo[type].description
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const request = axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' })
        },
      })
      return request
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      clearTreeStates()
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onCreateTask() {
      navigatePush({
        name: 'test-tasks-create',
        params: {
          subject: `${this.subject}`,
          studyYear: `${this.studyYear}`,
          theme: `${this.selectedIds[0]}`,
        },
      })
    },
    onCreateTheme() {
      navigatePush({
        name: 'themes-create',
        params: {
          subject: `${this.subject}`,
          studyYear: `${this.studyYear}`,
          theme: `${this.selectedIds[0]}`,
        },
      })
    },
    onRemoveTask() {
      this.showDeleteModalType = 'task'
      this.canRemoveTask
        ? loadConfirmDeleteModal(this.selectedIds)
        : loadRequestDeleteModal(this.selectedIds)
    },
    handleConfirmDelete(ids: number[], type: string) {
      if (type === 'task') {
        this.removeSelectedTask(ids)
      }
      if (type === 'theme') {
        this.removeSelectedTheme(ids)
      }
    },
    async removeSelectedTask(ids: number[]) {
      await deleteAssignments(ids)
      await Vue.nextTick(() => this.$refs.vuetable.reload())
      this.removeSelection()
    },
    async sendRequestDeleteTask(comment: string, ids: number[]) {
      await requestDeleteAssignments({ assignments: ids, ticket_comment: comment })
      this.removeSelection()
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    onRemoveTheme() {
      this.showDeleteModalType = 'theme'
      this.$permissions!.subjects_theme_themes.can_edit
        ? loadConfirmDeleteModal(this.selectedIds)
        : loadRequestDeleteModal(this.selectedIds)
    },
    async removeSelectedTheme(ids: number[]) {
      await deleteThemes(ids)
    },
    handleEditTask() {
      const routeData = this.$router.resolve({
        name: 'test-tasks-edit',
        query: {
          fromPage: 'tasks',
          questions: this.selectedIds.join(','),
        },
        params: { id: `${this.selectedIds[0]}` },
      })
      window.open(routeData.href, '_blank')
    },
    handleEditTheme() {
      navigatePush({ name: 'themes-edit', params: { id: `${this.selectedIds[0]}` } })
    },
    async publishAssignments() {
      await sendAssignmentsPublish({ assignments: this.selectedIds })
      await Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    sendToReview() {
      loadModalToSendForCheck(this.selectedIds)
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    headerActionsButtonClick(event: MouseEvent) {
      this.handleActionsClick({
        data: { id: this.selectedIds[0] },
        event,
        type: 'header-actions',
      })
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: TestTasksInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type = 'item' }: TestTasksInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: TestTasksInteractedItemData) {
      this.subject = typeof data.subject === 'number' ? data.subject : DEFAULT_ID
      this.studyYear = typeof data.studyYear === 'number' ? data.studyYear : DEFAULT_ID
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length,
        this.$treeView,
        this.isPrerequisite
      )
      this.contextMenuItems = getTestTasksActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    exportTable() {
      taskLoader.effect.exportFx(null)
      taskLoader.methods.setTaskLoading(true)
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!/create/g.test(from.path) && !/edit/g.test(from.path) && !/preview/g.test(from.path)) {
      clearTreeStates()
    }
    next()
  },
  mounted() {
    loadTreeLight()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
