







































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $editedPartTimeTestId } from '@/pages/exams/part-time-test/create/model/edit-part-time-test.model'

export default Vue.extend({
  name: 'CreateProctorsHeader',
  components: {
    Icon,
    BaseButton,
  },
  props: {
    disabled: { type: Boolean as PropType<boolean>, default: true },
  },
  effector: {
    id: $editedPartTimeTestId,
  },
  methods: {
    goToList() {
      navigatePush({ name: 'part-time-exams-tests-list' })
    },
    onSaveClick() {
      this.$emit('save')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
