import { answer_obj } from '../types'
import { BaseAssignment } from '@/features/api/assignment/types/types'

export enum ElementType {
  file = 'file',
  assignment = 'assignment',
  text = 'text',
  link = 'link',
  answer_text_field = 'answer_text_field',
  answer_file_field = 'answer_file_field',
}

type CorrectAnswer = {
  id: number
  score: number | null
}

export type courseStudentResultItem = {
  answer: { answer: string }
  answer_obj: answer_obj | null
  task?: BaseAssignment
  answer_dt: string
  answer_media: string | null
  commentary: string | null
  correct_answer: CorrectAnswer[]
  description: string
  element_type: string
  id: number
  mark: number
  score: number
  number: number
  question: string
  status: string
}
