import { statusDropdownModule } from '@/pages/common/dropdowns/bank/lesson-tasks/status-dropdown/status-dropdown.model'
import { typeDropdownModule } from '@/pages/common/dropdowns/bank/lesson-tasks/type-dropdown/type-dropdown.model'
import { courseDropdownModule } from '@/pages/common/dropdowns/bank/lesson-tasks/course-dropdown/course-dropdown.model'
import { foldersDropdownModule } from '@/pages/common/dropdowns/bank/lesson-tasks/position-dropdown/position-dropdown.model'

export const dropdownModules = {
  statusDropdownModule,
  typeDropdownModule,
  courseDropdownModule,
  foldersDropdownModule,
}
