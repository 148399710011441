



























import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  $selectedIds,
  modalVisibilityChanged,
} from '@/pages/applications/modals/delete/delete.model'

export default Vue.extend({
  name: 'DeleteModal',
  components: {
    Modal,
    BaseButton,
  },
  effector: {
    $modalVisibility,
    $selectedIds,
  },
  computed: {
    correctTitle() {
      return this.$selectedIds.length === 1
        ? `Элемент ${this.$selectedIds[0]} будет удален.`
        : `Элементы ${this.$selectedIds.join(', ')} будут удалены.`
    },
  },
  methods: {
    modalVisibilityChanged,
    handleClick() {
      this.$emit('delete', this.$selectedIds)
      modalVisibilityChanged(false)
    },
  },
})
