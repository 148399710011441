














































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { lessonAssignmentEditFolderModal } from '@/pages/common/modals/tasks-bank/editing-folder/editing-folder-modal.model'
import PositionDropdown from '@/pages/common/dropdowns/bank/lesson-tasks/position-dropdown/PositionDropdown.vue'

export default Vue.extend({
  name: 'EditingLessonFolderModal',
  components: {
    Modal,
    Icon,
    FormInput,
    BaseButton,
    PositionDropdown,
  },
  effector: {
    $modalVisibility: lessonAssignmentEditFolderModal.store.$modalVisibility,
    titleError: lessonAssignmentEditFolderModal.store.$titleErrorModule.store.$error,
    $folderTitle: lessonAssignmentEditFolderModal.store.$folderTitle,
  },
  methods: {
    modalVisibilityChanged: lessonAssignmentEditFolderModal.methods.modalVisibilityChanged,
    checkIfFolderCanBeSend: lessonAssignmentEditFolderModal.methods.checkIfFolderCanBeSend,
    folderTitleChanged: lessonAssignmentEditFolderModal.methods.folderTitleChanged,
  },
})
