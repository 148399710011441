import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams } from '@/features/api/types'
import { IFullCertificatesTemplate } from '@/features/api/results/certificates-generator/types/IFullCertificatesTemplate'

export const getCertificatesTemplatesListFx = createApiEffect<
  GetListQueryParams,
  IFullCertificatesTemplate[]
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/certificate-app/samples/',
    query,
  }),
})
