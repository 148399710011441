










import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  name: `Spinner`,
  components: { Icon },
  props: {
    full: {
      type: Boolean,
      default: false,
    },
  },
})
