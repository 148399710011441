import { classesDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/classes-filter-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/subject-filter-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { studentsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/student-filter-dropdown/studentsFilterDropdown.model'
import { applicationsCourseTypeDropdownModule } from '@/pages/learning/learning-courses/list/parts/filter/parts/course-submit-applications-filter/course-submit-applications-filter.model'
import { courseTypeDropdownModule } from '@/pages/learning/learning-courses/list/parts/filter/parts/course-type-filter/coursesType.model'
import { courseCourseTaskCheckTypeDropdownModule } from '@/pages/learning/learning-courses/list/parts/filter/parts/course-task-check-type-filter/coursesTaskCheckType.model'
import { statusDropdownModule } from '@/pages/common/dropdowns/courses/status-dropdown/status-dropdown.model'
import { coursesNameDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/courses-name-dropdown-filter/courses-filter-dropdown.model'

export const dropdownModules = {
  classesDropdownModel,
  subjectsDropdownModel,
  groupsDropdownModel,
  studentsDropdownModel,
  applicationsCourseTypeDropdownModule,
  courseTypeDropdownModule,
  courseCourseTaskCheckTypeDropdownModule,
  coursesNameDropdownModel,
  statusApplicationsDropdownModule: statusDropdownModule,
}
