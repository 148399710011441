











































































































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuItem, ContextMenuType } from '@/pages/common/parts/context-menu/types'
import {
  $isLoading,
  $refreshPage,
  examsAnswersPageMounted,
  setIsLoading,
} from '@/pages/exams/part-time-answers/list/model/exams-answers-page.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { $permissions } from '@/features/session'
import { $token } from '@/features/api/common/request'
import {
  RefsType,
  HttpOptionsType,
  DisplayContextMenuPayload,
  CommonInteractedItemParams,
} from '@/pages/common/types'
import { navigatePush } from '@/features/navigation/navigationMethods'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { TableHeaderItem } from '@/pages/common/parts/table-header/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import {
  examsAnswersTableFields,
  searchFieldsData,
} from '@/pages/exams/part-time-answers/list/model/constants'
import ExamsAnswersHeader from '@/pages/exams/part-time-answers/list/parts/ExamsAnswersHeader.vue'
import ExamsAnswerFilter from '@/pages/exams/part-time-answers/list/parts/ExamsAnswerFilter.vue'
import {
  examsAnswersFilters,
  examsAnswersPageParams,
  exportExamsAnswersList,
} from '@/pages/exams/part-time-answers/list/model/exams-answers-filters.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import {
  toggleVisibility,
  $visibility,
} from '@/pages/exams/part-time-answers/list/model/tooltip-filter.model'
import { axiosClient } from '@/lib/request'
import Answers from '@/pages/results/answer-types/Answers.vue'
import ComparingAnswerModal from '@/pages/common/modals/comparing-answer-modal/ComparingAnswerModal.vue'
import {
  comparingAnswerModalVisibilityChanged,
  setStudentAnswer,
  setCorrectAnswer,
} from '@/pages/common/modals/comparing-answer-modal/comparing-answer-modal.model'
import { studentsExamAnswerDropdownModel } from '@/pages/exams/part-time-answers/list/parts/students-exams-answer-filter/students-exams-answer-filter.model'
import {
  AttemptsDropdownModel,
  getStartDate,
} from '@/pages/exams/part-time-answers/list/parts/attempts-filter/attempts-filter.model'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'ExamsAnswersPage',
  components: {
    Vuetable,
    VuetablePagination,
    ExamsAnswersHeader,
    TableHeader,
    GeneralFilter,
    ExamsAnswerFilter,
    TooltipCell,
    ContextMenu,
    ActionsButton,
    NoDataContent,
    LoaderBig,
    Answers,
    ComparingAnswerModal,
  },
  effector: {
    $visibility,
    $isLoading,
    $refreshPage,
    $token,
    $filterParams: examsAnswersFilters.store.$filterParams,
    $pageParams: examsAnswersPageParams.store.$pageParams,
    $treeView: examsAnswersPageParams.store.treeView,
    $currentPage: examsAnswersPageParams.store.currentPage,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      searchFields: searchFieldsData,
      fields: examsAnswersTableFields,
      isFilters: false,
      total: 0,
      selectedRows: [] as number[],
      showDeleteModalType: 'examsAnswers',
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/exams-app/part-time/answers/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length && !this.$treeView) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): TableHeaderItem[] {
      const items = []
      if (this.selectedRows.length === 1) {
        items.push(
          { name: 'showExamsTest', title: 'Предпросмотр задания', action: 'onShowExamsTest' },
          { name: 'compareAnswers', title: 'Сравнить ответы', action: 'onCompareAnswers' },
          { name: 'removeSelection', title: 'Снять выделение', action: 'onRemoveSelection' }
        )
      }
      if (this.selectedRows.length > 1) {
        items.push({
          name: 'removeSelection',
          title: 'Снять выделение',
          action: 'onRemoveSelection',
        })
      }

      return items
    },
    contextMenuItems(): ContextMenuItem[] {
      return [
        { name: 'showExamsTest', title: 'Предпросмотр задания', action: 'onShowExamsTest' },
        { name: 'openExamsTest', title: 'Открыть экзаменационный тест', action: 'onOpenExamsTest' },
      ]
    },
  },
  watch: {
    $refreshPage: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.removeSelection()
      },
    },
  },
  methods: {
    changeFilter: examsAnswersFilters.methods.changeFilter,
    resetFilters: examsAnswersFilters.methods.resetFilters,
    removeParamsFilter: examsAnswersFilters.methods.removeParamsFilter,
    applyFilters: examsAnswersFilters.methods.applyFilters,
    toggleTreeView: examsAnswersPageParams.methods.toggleTreeView,
    changePage: examsAnswersPageParams.methods.changePage,
    queryToParams: examsAnswersPageParams.methods.queryToParams,
    toggleVisibility,
    exportExamsAnswersList,
    setIsLoading,
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      if (Object.keys(httpOptions.params).length < 5) {
        return new Promise((resolve) => {
          resolve({ data: [] })
        })
      }
      const request = axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
      return request
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.isFilters = true
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.isFilters = false
      this.resetFilters()
      toggleVisibility(false)
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onShowExamsTest() {
      const currentItem = this.$refs.vuetable.tableData.filter((item: { id: number }) =>
        this.selectedIds.includes(item.id)
      )
      const id = currentItem[0].assignment
      this.$router.push({
        name: 'preview-task',
        query: {
          questions: `${id}`,
          fromPage: 'exams-answers-list',
          taskType: 'test-assignment',
          token: this.$token,
        },
      })
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    headerActionsButtonClick(event: MouseEvent) {
      this.handleActionsClick({
        data: { id: this.selectedIds[0] },
        event,
        type: 'header-actions',
      })
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: { id: number }) {
      this.interactedItemId = data.id
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    endLoading() {
      setTimeout(() => {
        this.setIsLoading(false)
      }, 700)
    },
    onCompareAnswers() {
      const currentItem = this.$refs.vuetable.tableData.filter((item: { id: number }) =>
        this.selectedIds.includes(item.id)
      )
      if (currentItem && currentItem.length > 0) {
        const item = {
          answer_files: [],
          answer: currentItem[0].answer,
          correct_answer: currentItem[0].correct_answer,
          type: currentItem[0].type,
          question_data: currentItem[0].question_data,
        }
        setStudentAnswer(item)
        setCorrectAnswer(item)
        comparingAnswerModalVisibilityChanged(true)
      }
    },
    onOpenExamsTest() {
      const currentItem = this.$refs.vuetable.tableData.filter((item: { id: number }) =>
        this.selectedIds.includes(item.id)
      )
      const id = currentItem[0].test_id
      navigatePush({
        name: 'part-time-exams-tests-edit',
        params: { id },
      })
    },
  },
  mounted() {
    examsAnswersPageMounted()
  },
  created() {
    this.queryToParams(this.$route.query)

    // логика установки параметров, если на страницу перешли из Попыток
    const {
      params: { attempts, students },
    } = this.$route
    if (attempts && students) {
      const student: { id: string; first_name: string; last_name: string } = JSON.parse(students)
      const attempt: { id: string; started_at: string; status: string } = JSON.parse(attempts)
      studentsExamAnswerDropdownModel.methods.itemChanged({
        name: String(student.id),
        title: `(${student.id}) ${student.first_name} ${student.last_name}`,
      })
      AttemptsDropdownModel.methods.itemChanged({
        name: `${attempt.id}`,
        title: `(${attempt.id})${attempt.started_at ? ` ${getStartDate(attempt.started_at)}` : ''}${
          Number(attempt.status) === 1 ? ' (процесс)' : ' завершена'
        }`,
      })
      // @ts-ignore
      this.changeFilter({ students: student.id, attempts: attempt.id })
    }
  },
  destroyed() {
    this.resetFilters()
  },
})
