import { createEvent, forward, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { dropdownModules } from '@/pages/results/courses-all/parts/filter/parts/dropdown-modules'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/subject-filter-dropdown.model'
import { classesDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/classes-filter-dropdown.model'
import { coursesNameDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/courses-name-dropdown-filter/courses-filter-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'

export const resultCoursesFilters = createFiltersModel({}, dropdownModules)

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

forward({
  from: reset,
  to: [
    subjectsDropdownModel.methods.resetSelectedItems,
    classesDropdownModel.methods.resetSelectedItems,
    coursesNameDropdownModel.methods.resetSelectedItems,
    groupsDropdownModel.methods.resetSelectedItems,
  ],
})
