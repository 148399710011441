











import Vue from 'vue'
import ThemeHeader from '@/pages/dictionary/themes/create/parts/header/Header.vue'
import ThemeContent from '@/pages/dictionary/themes/create/parts/Content.vue'
import {
  create,
  clearFields,
  redirectAfterSaveChanged,
} from '@/pages/dictionary/themes/create/theme-creation-page.model'
import { $isPrerequisite } from '@/pages/dictionary/themes/create/parts/header/header.model'
import {
  subjectsDropdownModel,
  setSelectedSubject,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import {
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { themesDropdownModule } from '@/pages/dictionary/themes/create/parts/position/position.model'
import { getSubjectFx } from '@/features/api/subject/get-subject'
import { getThemeFx } from '@/features/api/subject/get-theme'
import { getUserStudyYearFx } from '@/features/api/study_year/get-user-study-year'

export default Vue.extend({
  name: 'ThemeCreationPage',
  effector: {
    $isPrerequisite,
  },
  components: {
    ThemeHeader,
    ThemeContent,
  },
  computed: {
    correctTitle() {
      return `Создание ${this.$isPrerequisite ? 'пререквизита' : 'темы'}`
    },
  },
  methods: {
    redirectAfterSaveChanged,
    clearFields,
    create,
    createWithRedirect() {
      redirectAfterSaveChanged(true)
      create()
    },
  },
  mounted() {
    if (this.$route.params) {
      const { subject, studyYear, theme } = this.$route.params
      subject &&
        getSubjectFx(+subject).then(({ body }) =>
          subjectsDropdownModel.methods.itemChanged({ name: `${body.id}`, title: body.name })
        )
      subject && setSelectedSubject({ id: +subject, name: subject, title: subject })
      studyYear &&
        getUserStudyYearFx(+studyYear).then(({ body }) =>
          classesDropdownModule.methods.itemChanged({ name: `${body.id}`, title: body.name })
        )
      studyYear && setSelectedClass({ id: +studyYear, name: studyYear, title: studyYear })
      !isNaN(+theme) &&
        getThemeFx(+theme).then(({ body }) =>
          themesDropdownModule.methods.itemChanged({ name: `${body.id}`, title: body.name })
        )
    }
  },
  beforeDestroy() {
    redirectAfterSaveChanged(false)
    clearFields()
  },
})
