

















import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import SelectedItemsList from '@/pages/common/dropdowns/multiselectDropdown/parts/SelectedItemsList.vue'
import {
  coursesDropdownModel,
  loadCourses,
} from '@/pages/users/employes/edit/parts/courses-dropdown/courses-dropdown.model'

export default Vue.extend({
  components: {
    FilterDropdown,
    SelectedItemsList,
  },
  props: {
    label: {
      type: String,
      default: 'Доступные пользователю курсы',
    },
  },
  effector: {
    ...coursesDropdownModel.store,
  },
  methods: {
    ...coursesDropdownModel.methods,
    onItemChanged(item: DropdownItem | null) {
      if (item && item.name === 'all') {
        this.setSelectedItems([{ name: 'all', title: 'Все' }])
      } else if (item && !this.$selectedItems.find((i) => i.name === item.name)) {
        this.setSelectedItems([...this.$selectedItems].filter((i) => i.name !== 'all'))
      }
    },
    onDeleteItem(item: string) {
      this.deleteSelectedItem(item)
    },
  },
  mounted() {
    if (this.$items.length === 0) loadCourses()
    if (this.$selectedItems.length === 0) this.setSelectedItems([{ name: 'all', title: 'Все' }])
  },
})
