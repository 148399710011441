










































































































































































































































































































































































































import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import ElementTypesDropdown from '@/pages/common/dropdowns/add-assignment/ElementTypesDropdown.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import Icon from '@/ui/icon/Icon.vue'
import Draggable from 'vuedraggable'
import CustomFileInput from '@/ui/custom-file-input/CustomFileInput.vue'
import {
  $introDurMin,
  $introUnitBlocks,
  $IsIntroPart,
  changeIntroBlock,
  deleteIntroBlock,
  setIntroBlocks,
  setIntroDurationMin,
  setIsIntroPart,
} from '@/pages/exams/full-time-exam-test/create/model/form-fields.model'
import ExamAssignmentContent from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/ExamAssignmentContent.vue'
import { ElementTypeNames, FieldNames } from '@/pages/learning/learning-lessons/create/model/types'
import { OptionalExamElements } from '@/features/api/full-time-tests/types'
import { uploadMedia } from '@/pages/learning/learning-lessons/create/model/form-fileds.model'
import { selectBlockElement } from '@/pages/common/dropdowns/add-assignment/assignment-elements.model'
// import { ExamTestsAssignBlock } from "@/pages/learning/learning-lessons/create/model/assignment-block.model";

export default Vue.extend({
  name: 'IntroTab',
  components: {
    ExamAssignmentContent,
    FormInput,
    BaseSwitch,
    BaseButton,
    ElementTypesDropdown,
    Draggable,
    Icon,
    CustomFileInput,
    Wysiwyg,
  },
  effector: {
    $introUnitBlocks,
    $IsIntroPart,
    $introDurMin,
  },
  data: () => ({
    filterElement: [
      ElementTypeNames.link,
      ElementTypeNames.file,
      ElementTypeNames.answer_file_field,
      ElementTypeNames.answer_text_field,
    ],
    fieldType: FieldNames,
    answerBlockInfo: 'Текстовое описание отображается в интерфейсе ученика',
    CMSBlockInfo: 'Комментарий отображается только в CMS',
    blockType: ElementTypeNames,
  }),
  methods: {
    selectBlockElement,
    deleteIntroBlock,
    setIsIntroPart,
    setIntroDurationMin,
    handlerBlockPosition() {
      const blocks = JSON.parse(JSON.stringify(this.$introUnitBlocks))
      const newBlocks = blocks.map((block: OptionalExamElements, id: number) => ({
        ...block,
        blockId: id,
      }))
      setIntroBlocks(newBlocks)
    },
    async uploadFileToBlock(files: any, blockId: number, type: ElementTypeNames) {
      if (files) {
        const formData = new FormData()
        formData.append('file', files[0])
        formData.append('file_type', 'file')
        const res = await uploadMedia(formData)
        this.changeFieldValue(type, blockId, res.body, 'file')
      } else {
        this.changeFieldValue(type, blockId, null, 'file')
      }
    },
    changeFieldValue(type: ElementTypeNames, blockId: number, value: any, field: string) {
      const obj = {}
      obj[field] = value
      changeIntroBlock({ ...obj, blockId, element_type: type })
    },
  },
  mounted() {
    // AssignBlock.methods.loadAssignmentDropdownOptions(DropdownAssignmentBlockNames.subject)
    // AssignBlock.methods.loadAssignmentDropdownOptions(DropdownAssignmentBlockNames.classes)
  },
})
