import { render, staticRenderFns } from "./CommonListTextAnswer.vue?vue&type=template&id=7e10e808&scoped=true&"
import script from "./CommonListTextAnswer.vue?vue&type=script&lang=ts&"
export * from "./CommonListTextAnswer.vue?vue&type=script&lang=ts&"
import style0 from "./CommonListTextAnswer.vue?vue&type=style&index=0&id=7e10e808&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e10e808",
  null
  
)

export default component.exports