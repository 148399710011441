import { DropdownItem } from '@/pages/common/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'
import { DEFAULT_ID } from '@/pages/common/constants'

export const searchFieldsData: DropdownItem[] = [{ name: 'all', title: 'Искать везде' }]

export const getSystemFilesActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions,
  elementToCopyId: number
) => {
  const actions = [
    {
      name: 'create',
      title: 'Создать новую папку',
      action: 'onCreate',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee:
        permissions.assignments_testassignment_difficulty_base.can_edit ||
        permissions.assignments_testassignment_difficulty_pro.can_edit ||
        permissions.assignments_testassignment_difficulty_exam.can_edit,
    },
    {
      name: 'rename',
      title: 'Переименовать',
      action: 'onRename',
      isDisplayed: displayOptions.isTreeFolder || displayOptions.isTreeItem,
      hasPermissionToSee: permissions.media_media_media.can_edit,
    },
    {
      name: 'copy',
      title: 'Копировать',
      action: 'onCopy',
      isDisplayed: displayOptions.isTreeFolder || displayOptions.isTreeItem,
      hasPermissionToSee: permissions.media_media_media.can_edit,
    },
    {
      name: 'delete',
      title: 'Удалить',
      action: 'onDelete',
      isDisplayed: displayOptions.isTreeFolder || displayOptions.isTreeItem,
      hasPermissionToSee: true,
    },
    {
      name: 'upload',
      title: 'Загрузить файл',
      action: 'onUpload',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: permissions.media_media_media.can_edit,
    },
    {
      name: 'paste',
      title: 'Вставить',
      action: 'onPaste',
      isDisplayed: displayOptions.isTreeFolder && elementToCopyId !== DEFAULT_ID,
      hasPermissionToSee: permissions.media_media_media.can_edit,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
