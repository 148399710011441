import {
  attach,
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector-root'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { TreeData } from '@/features/api/types'
import { requestDeleteExamsAssignmentsFx } from '@/features/api/assignment/exam-assignment/delete-exam-assignment'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { condition } from 'patronum'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { FiltersParams } from '@/pages/common/types'
import { mergeTreeData, sortTreeLeaves } from '@/features/lib'
import {
  GetAssignmentTreeQueryParams,
  RequestDeleteAssignmentsParams,
  RequestDeleteFolderParams,
} from '@/features/api/assignment/types/types'
import {
  $nDuplicate,
  changedDuplicateModalVisibility,
} from '@/pages/bank/common/modals/duplicate/duplicate.model'
import { duplicateExamsAssignmentsFx } from '@/features/api/assignment/exam-assignment/duplicate-exam-assignment'
import { LessonAssignmentsBulkUpdate } from '@/features/api/assignment/types/lesson-assignments-types'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { exportExamAssignmentListFx } from '@/features/api/assignment/exam-assignment/export-exam-assignment-list'
import { examTasksFilters } from '@/pages/bank/exam-tasks/list/parts/exam-tasks-filter.model'
import {
  getExamAssignmentTreeFx,
  getExamAssignmentTreeLightFx,
  getExamTreeInfoFx,
} from '@/features/api/assignment/exam-assignment/get-exam-tasks-tree'
import {
  deleteExamAssignmentFolderFx,
  requestDeleteExamFolderFx,
} from '@/features/api/assignment/exam-assignment/folder/delete-folder'
import { createTaskModule } from '@/features/api/tasks/tasks-module'

const getExamsTree = attach({
  effect: getExamAssignmentTreeFx,
})

const getExamsTreeLight = attach({
  effect: getExamAssignmentTreeLightFx,
})

const getFilteredTree = attach({
  effect: getExamAssignmentTreeFx,
})

const getExamTreeInfo = attach({
  effect: getExamTreeInfoFx,
})

/* export const deleteAssignments = createEffect({
  handler: (ids: number[]): Promise<number[]> => {
    return new Promise((resolve) => {
      deleteExamAssignmentFolderFx(ids).then(() => {
        resolve(ids)
      })
    })
  },
}) */

export const requestDeleteAssignments = attach({
  effect: requestDeleteExamsAssignmentsFx,
  mapParams: (payload: RequestDeleteAssignmentsParams): RequestDeleteAssignmentsParams => {
    return {
      assignments: payload.assignments,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const duplicateAssignment = attach({
  effect: duplicateExamsAssignmentsFx,
  source: $nDuplicate,
  mapParams: (id: number[], n: number): LessonAssignmentsBulkUpdate => ({
    assignments: id,
    number_of_duplicates: n,
  }),
})

export const canRefreshAfterDuplicateChanged = createEvent<boolean>()
export const $canRefreshAfterDuplicate = restore<boolean>(canRefreshAfterDuplicateChanged, false)

export const loadList = createEvent<GetAssignmentTreeQueryParams>()

forward({
  from: duplicateAssignment,
  to: canRefreshAfterDuplicateChanged.prepend(() => false),
})

forward({
  from: duplicateAssignment.doneData,
  to: [
    changedDuplicateModalVisibility.prepend(() => false),
    successToastEvent('Задание было успешно дублировано!'),
    canRefreshAfterDuplicateChanged.prepend(() => true),
    loadList.prepend(() => ({})),
  ],
})

export const deleteFolder = attach({
  effect: deleteExamAssignmentFolderFx,
})

// ToDo нужны права с бэка
export const requestDeleteFolder = attach({
  effect: requestDeleteExamFolderFx,
  mapParams: (payload: RequestDeleteFolderParams): RequestDeleteFolderParams => {
    return {
      assignment_folders: payload.assignment_folders,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

const exportExamAssignmentList = attach({
  effect: exportExamAssignmentListFx,
  source: [examTasksFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

export const taskLoader = createTaskModule(exportExamAssignmentList, 'examAssignmentList')

export const examTaskPageParams = createPageParamsModel()

export const loadTree = createEvent<FiltersParams>()
export const loadTreeLight = createEvent<void>()
const loadFilteredTree = createEvent<FiltersParams>()
const rewriteTree = createEvent<TreeData[] | null>()
export const setExamsTree = createEvent<TreeData[] | null>()
export const $examsTree = createStore<TreeData[] | null>(null)
  .on(setExamsTree, (state, data) => mergeTreeData(state!, data!))
  .on(rewriteTree, (state, payload) => sortTreeLeaves(payload!))
export const setExamsTreeTotal = createEvent<number>()
export const $examsTreeTotal = restore<number>(setExamsTreeTotal, 0)

const showDeleteAssignmentsToast = createEvent<number[]>()
const showDeleteFolderToast = createEvent<void>()

export const $isLoading = combine(
  getFilteredTree.pending,
  getExamsTreeLight.pending,
  // getLessonAssignmentListFx.pending,
  (tree, light) => tree || light
)

forward({
  from: loadTreeLight,
  to: [getExamsTreeLight, getExamTreeInfo],
})

sample({
  source: examTasksFilters.store.$filterParams,
  clock: loadTree,
  fn: (filterParams, treeParams) => ({ ...filterParams, ...treeParams }),
  target: getExamsTree,
})

forward({
  from: loadFilteredTree,
  to: getFilteredTree,
})

forward({
  from: getExamTreeInfo.doneData.map(({ body }) => body.total_amount),
  to: setExamsTreeTotal,
})

forward({
  from: getExamsTreeLight.doneData,
  to: rewriteTree.prepend(({ body }) => body.data),
})

forward({
  from: getFilteredTree.doneData,
  to: [
    rewriteTree.prepend(({ body }) => body.data),
    setExamsTreeTotal.prepend(({ body }) => body.total),
  ],
})

forward({
  from: getExamsTree.doneData,
  to: setExamsTree.prepend(({ body }) => body.data),
})

/* forward({
  from: deleteAssignments.doneData,
  to: [
    loadTreeLight.prepend(() => ({})),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    showDeleteAssignmentsToast,
  ],
}) */

condition({
  source: showDeleteAssignmentsToast,
  if: (ids: number[]) => ids.length === 1,
  then: successToastEvent('Задание было успешно удалено!'),
  else: successToastEvent('Задания были успешно удалены!'),
})

forward({
  from: deleteFolder.doneData,
  to: [
    loadTreeLight.prepend(() => ({})),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    showDeleteFolderToast,
  ],
})

forward({
  from: showDeleteFolderToast,
  to: successToastEvent('Папка была успешно удалена!'),
})

forward({
  from: [requestDeleteAssignments.doneData, requestDeleteFolder.doneData],
  to: [
    loadTreeLight.prepend(() => ({})),
    successToastEvent('Отправлена заявка на удаление'),
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
  ],
})

guard({
  clock: examTasksFilters.methods.resetFilters,
  filter: examTaskPageParams.store.treeView,
  target: loadTreeLight.prepend(() => ({})),
})

guard({
  clock: examTaskPageParams.store.treeView,
  source: examTasksFilters.store.$filterParams,
  filter: (filterParams, treeView) => {
    return treeView && Object.keys.length === 3
  },
  target: loadFilteredTree,
})

guard({
  clock: examTasksFilters.methods.applyFilters,
  filter: examTaskPageParams.store.treeView,
  source: examTasksFilters.store.$filterParams,
  target: loadFilteredTree,
})
