











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { debounce } from 'throttle-debounce'
import { GroupCourseType } from '@/pages/learning/learning-courses/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    label: String,
    blockId: Number,
    selectedGroup: Object,
    groupsList: Array,
  },
  data(): {
    searchString: string
    itemsDropdown: GroupCourseType[]
  } {
    return {
      searchString: '',
      itemsDropdown: [],
    }
  },
  watch: {
    searchString: {
      handler() {
        this.searchGroup()
      },
    },
  },
  methods: {
    filterItems(str: string, list: any[]): any[] {
      return list
        .map((item) => {
          if (item.leaves) {
            item.leaves = this.filterItems(str, item.leaves)
          }

          const matches = item.title.toLowerCase().includes(str.toLowerCase())
          const hasLeaves = item.leaves && item.leaves.length > 0
          return matches || hasLeaves ? item : null
        })
        .filter((item) => item !== null)
    },
    setItems(items: GroupCourseType[]) {
      this.itemsDropdown = items
    },
    resetItem() {
      this.$emit('item-changed', null)
      this.resetSearchString()
    },
    resetSearchString() {
      this.searchString = ''
      this.itemsDropdown = []
    },
    searchStringChanged(value: string) {
      this.searchString = value
      if (value.trim() === '') {
        this.resetItem()
      }
    },
    itemChanged(group: GroupCourseType) {
      console.log('itemChanged', group)
      this.resetSearchString()
      this.onSelectItem(group)
    },
    setGroupsList(group: GroupCourseType | null, search: string) {
      this.itemsDropdown = this.filterItems(search, group as any)
    },
    onSelectItem(group: GroupCourseType | null) {
      this.$emit('item-changed', group)
    },
    searchGroup: debounce(300, false, function getDebounceTask(this: any) {
      this.setGroupsList(this.groupsList, this.searchString)
    }),
  },
})
