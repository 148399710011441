












import Vue, { PropType } from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  statusDropdownModule,
  loadStatus,
  $status,
} from '@/pages/common/dropdowns/testing/status-dropdown/status-dropdown.model'
import { DropdownItem } from '@/pages/common/types'
import { LoadType } from '@/features/api/test/get-test-status-list'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    type: { type: String as PropType<LoadType>, default: 'tests' },
  },
  effector: {
    $status,
    ...statusDropdownModule.store,
  },
  methods: {
    ...statusDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    loadStatus(this.$props.type)
  },
})
