import { createEvent, createStore, combine, createEffect, attach, forward } from 'effector-root'
import { ChangeNewsFormFieldType, EditNewsPageFields } from '@/features/api/content/types'
import { spread } from 'patronum'
import { UploadMediaResponse } from '@/features/api/media/types'
import { uploadMediaFx } from '@/features/api/media/upload-media'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import { getNewsByIdFx } from '@/features/api/content/news/get-news-by-id'
import { DEFAULT_ID } from '@/pages/common/constants'
import url_slug from '@/lib/utils/url_slug'
import { tagsDropdownModel } from '@/pages/common/dropdowns/content/news/TagsDropdown/tags-dropdown.model'

export const deleteImage = createEvent<void>()

export const clearFields = createEvent<void>()

const uploadMedia = attach({
  effect: uploadMediaFx,
})

const uploadLessonCoverFx = createEffect({
  handler: (files: FileList | null): Promise<UploadMediaResponse[]> =>
    Promise.all(
      Array.from(files || []).map(
        (file) =>
          new Promise<UploadMediaResponse>((resolve) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('file_type', 'image')
            const res = uploadMedia(formData).then((r) => r.body)
            resolve(res)
          })
      )
    ),
})

export const $title = createStore<string>('').reset(clearFields)

export const $slug = createStore<string>('').reset(clearFields)

export const $date = createStore<Date | null>(null).reset(clearFields)

export const $text = createStore<string>('').reset(clearFields)

export const $seoTitle = createStore<string>('').reset(clearFields)

export const $isShowed = createStore<boolean>(true).reset(clearFields)

export const $seoDescription = createStore<string>('').reset(clearFields)

export const $imageInfo = createStore<UploadMediaResponse>({
  duration_sec: DEFAULT_ID,
  file: '',
  file_name: '',
  file_type: 'img',
  id: DEFAULT_ID,
  owner: DEFAULT_ID,
})
  .on(uploadLessonCoverFx.doneData, (_, res) => ({ ...res[0] }))
  .reset([deleteImage, clearFields])

export const changeFieldValue = createEvent<ChangeNewsFormFieldType>()

spread({
  source: changeFieldValue.map((data) => {
    const obj = {}
    obj[data.type] = data.value
    return obj
  }),
  targets: {
    [EditNewsPageFields.title]: $title,
    [EditNewsPageFields.slug_field]: $slug,
    [EditNewsPageFields.publish_date]: $date,
    [EditNewsPageFields.tags_ids]: tagsDropdownModel.store.$selectedItems,
    [EditNewsPageFields.text]: $text,
    [EditNewsPageFields.seo_title]: $seoTitle,
    [EditNewsPageFields.seo_description]: $seoDescription,
    [EditNewsPageFields.is_showed]: $isShowed,
    [EditNewsPageFields.image]: [
      uploadLessonCoverFx,
      addToast.prepend(() => ({ type: 'loading', message: 'Идет загрузка файла(ов)' })),
    ],
  },
})

spread({
  source: getNewsByIdFx.doneData.map((res) => {
    const tempArr = res.body.tags.map((tag) => ({ name: tag.id, title: tag.name }))
    tagsDropdownModel.methods.setSelectedItems(tempArr)
    return {
      ...res.body,
      [EditNewsPageFields.publish_date]: res.body[EditNewsPageFields.publish_date]
        ? new Date(res.body[EditNewsPageFields.publish_date])
        : null,
    }
  }),
  targets: {
    [EditNewsPageFields.title]: $title,
    [EditNewsPageFields.slug_field]: $slug,
    [EditNewsPageFields.publish_date]: $date,
    [EditNewsPageFields.text]: $text,
    [EditNewsPageFields.seo_title]: $seoTitle,
    [EditNewsPageFields.seo_description]: $seoDescription,
    [EditNewsPageFields.image]: $imageInfo,
    [EditNewsPageFields.is_showed]: $isShowed,
  },
})

export const $disabledSaveButtons = combine($title, $date, (title, date) => !title || !date)

export const $createNewsPageForm = combine(
  $title,
  $slug,
  $date,
  $text,
  tagsDropdownModel.store.$selectedItems,
  $seoDescription,
  $seoTitle,
  $imageInfo,
  $isShowed,
  (title, slug, date, text, tags, seoDescription, seoTitle, imageInfo, isShowed) => ({
    [EditNewsPageFields.title]: title,
    [EditNewsPageFields.slug_field]: slug ? url_slug(slug) : url_slug(title),
    [EditNewsPageFields.publish_date]: date,
    [EditNewsPageFields.text]: text,
    [EditNewsPageFields.tags_ids]: tags.map((tag) => tag.name),
    [EditNewsPageFields.seo_title]: seoTitle,
    [EditNewsPageFields.seo_description]: seoDescription,
    [EditNewsPageFields.image]: imageInfo,
    [EditNewsPageFields.image_id]: imageInfo.id,
    [EditNewsPageFields.is_showed]: isShowed,
  })
)

forward({
  from: uploadLessonCoverFx.done,
  to: successToastEvent('Загрузка фото завершена'),
})
