import { render, staticRenderFns } from "./EditStudentHeader.vue?vue&type=template&id=83183e14&scoped=true&"
import script from "./EditStudentHeader.vue?vue&type=script&lang=ts&"
export * from "./EditStudentHeader.vue?vue&type=script&lang=ts&"
import style0 from "./EditStudentHeader.vue?vue&type=style&index=0&id=83183e14&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83183e14",
  null
  
)

export default component.exports