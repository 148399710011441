





































































import Vue from 'vue'
import { TaskLabelNames, TaskTypeNames } from '@/pages/learning/learning-lessons/create/model/types'
import { LessonsAssignBlock } from '@/pages/learning/learning-lessons/create/model/assignment-block.model'
import FormInput from '@/ui/input/FormInput.vue'
import TasksDropdown from '@/pages/learning/learning-lessons/create/parts/assignment-block/TasksDropdown.vue'
import SubjectsDropdown from '@/pages/learning/learning-lessons/create/parts/assignment-block/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/learning/learning-lessons/create/parts/assignment-block/ClassesDropdown.vue'
import ThemesDropdown from '@/pages/learning/learning-lessons/create/parts/assignment-block/ThemesDropdown.vue'
import RadioGroup from '@/ui/radio/RadioGroup.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import InternalLoader from '@/pages/common/parts/loader/InternalLoader.vue'

export default Vue.extend({
  name: 'LessonAssigmentContent',
  components: {
    InternalLoader,
    ThemesDropdown,
    TasksDropdown,
    SubjectsDropdown,
    ClassesDropdown,
    RadioGroup,
    FormInput,
    BaseSwitch,
  },
  props: {
    blockId: Number,
  },
  data: () => ({
    taskType: TaskTypeNames,
    taskLabel: TaskLabelNames,
    tasksLoading: false,
  }),
  effector: {
    $radioButtonValue: LessonsAssignBlock.store.$taskType,
    $useTaskScore: LessonsAssignBlock.store.$useTaskScore,
    $taskScore: LessonsAssignBlock.store.$taskScore,
    $formToGetThemeList: LessonsAssignBlock.store.$formToGetThemeList,
    $selectedTask: LessonsAssignBlock.store.$selectedTask,
  },
  methods: {
    onToggleTaskType(value: TaskTypeNames) {
      LessonsAssignBlock.methods.toggleTaskType({ id: this.blockId, task: value })
    },
    onToggleTaskScore() {
      LessonsAssignBlock.methods.toggleTaskScore({ id: this.blockId })
    },
    onSetTaskScore(value: number | string) {
      LessonsAssignBlock.methods.setTaskScore({ id: this.blockId, value: String(value) })
    },
    changeTaskScore(value: number) {
      if (!value || value < 1) {
        this.onSetTaskScore(0)
        this.onSetTaskScore(1)
      } else if (value > 32767) {
        this.onSetTaskScore(1)
        this.onSetTaskScore(32767)
      } else {
        this.onSetTaskScore(value)
      }
    },
    clearTaskScore() {
      this.onSetTaskScore('')
    },
    taskLoading(value: boolean) {
      this.tasksLoading = value
    },
  },
})
