






import Vue from 'vue'

export default Vue.extend({
  name: 'ColorHighlightAnswer',
  props: {
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    correctAnswer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    answerData() {
      try {
        if (this.answerType === 'student') {
          if (Array.isArray(this.answer)) {
            let str = this.question.parsedText
            // @ts-ignore
            Object.entries(this.answer[0]).forEach((el: [string, string[]]) => {
              str = str.replace(
                new RegExp(`........"(${el[1].join('|')})"`, 'g'),
                `style="background-color: ${this.question.colors[el[0]]};"`
              )
            })
            return str
          }
        }
        if (this.answerType === 'correct') {
          if (typeof this.correctAnswer === 'object') {
            return this.correctAnswer.text
          }
        }

        return this.answerType === 'student' ? this.answer : this.correctAnswer
      } catch (e) {
        console.error(e)
        return []
      }
    },
  },
})
