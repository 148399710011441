
































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { loadModalForMultiChanges } from '@/pages/bank/olympiad-tasks/list/parts/modals/tasks-update/tasks-update-modal.model'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { exportOlympiadAssignmentsListFx } from '@/features/api/assignment/olympiad-assignment/export-olympiad-assignment'
import { $permissions } from '@/features/session'

export default Vue.extend({
  components: {
    Icon,
    BaseButton,
    HeaderPopup,
    DownloadButton,
  },
  effector: {
    isLoadingExport: exportOlympiadAssignmentsListFx.pending,
    $permissions,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<number[]> },
  },
  methods: { loadModalForMultiChanges },
})
