






























































import Vue, { PropType } from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseCheckbox from '@/ui/checkbox/BaseCheckbox.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import {
  $allSelected,
  $columnsModalVisibility,
  $setColumns,
  $setColumnsNames,
  changeSetColumnsColumns,
  changeColumnsModalVisibility,
  initSetColumnsModalStores,
  toggleSelectAll,
  uncheckAll,
  setColumnsModalDestroy,
} from '@/pages/common/modals/table-settings/table-colunm-edit/table-colunm-edit-modal.model'

const ClickOutside = require('vue-click-outside')

export default Vue.extend({
  components: {
    Modal,
    Icon,
    BaseSwitch,
    BaseCheckbox,
    BaseButton,
  },
  directives: {
    ClickOutside,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    visibility: { type: Boolean as PropType<boolean> },
  },
  effector: {
    $columnsModalVisibility,
    $allSelected,
    $setColumns,
    $setColumnsNames,
  },
  methods: {
    changeColumnsModalVisibility,
    toggleSelectAll,
    changeSetColumnsColumns,
    uncheckAll,
    clickOutside(evt: any) {
      if (evt.target.closest('#btn-columns')) return
      changeColumnsModalVisibility(false)
    },
  },
  mounted() {
    initSetColumnsModalStores(this.tableColumns)
  },
  beforeDestroy() {
    setColumnsModalDestroy()
  },
})
