



























import Vue, { PropType } from 'vue'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { examTestsFolderModal } from '@/pages/common/modals/tasks-bank/creating-folder/creating-folder-modal.model'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { examTestsPageParams } from '@/pages/exams/full-time-exam-test/list/parts/exam-tests-page.model'
import { $permissions } from '@/features/session'

export default Vue.extend({
  name: 'PageHeader',
  components: {
    BaseButton,
    GridPageHead,
    DownloadButton,
    HeaderPopup,
  },
  effector: {
    $treeView: examTestsPageParams.store.treeView,
    $permissions,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<number[]> },
  },
  methods: {
    modalVisibilityChanged: examTestsFolderModal.methods.modalVisibilityChanged,
  },
})
