





































import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import Icon from '@/ui/icon/Icon.vue'
import NumericInput from '@/ui/input/NumericInput.vue'
import {
  $clues,
  resetCluesList,
  setClues,
} from '@/pages/common/parts/tasks/parts/clues/clues.model'
import { getRandomId } from '@/pages/common/parts/tasks/utils'

export default Vue.extend({
  components: {
    BaseButton,
    Wysiwyg,
    Icon,
    NumericInput,
  },
  effector: {
    $clues,
    resetCluesList,
  },
  methods: {
    addClue() {
      setClues([
        ...this.$clues,
        {
          id: getRandomId(),
          text: '',
          scores: 1,
          number: this.$clues.length,
        },
      ])
    },
    handleClueTextChanged({ id, text }: { id: number; text: string }) {
      const clues = this.$clues.map((clue) => (clue.id === id ? { ...clue, text } : clue))
      setClues(clues)
    },
    removeClue({ id }: { id: number }) {
      const cluesList = this.$clues.filter((clue) => clue.id !== id)
      setClues(cluesList)
    },
    setCluePrice({ id, scores }: { id: number; scores: number }) {
      const clues = this.$clues.map((clue) => (clue.id === id ? { ...clue, scores } : clue))
      setClues(clues)
    },
  },
  beforeDestroy() {
    resetCluesList()
  },
})
