import { getLessonsListFx } from '@/features/api/learning/lessons/get-lessons-list'
import { attach, createEvent, forward, combine, createStore } from 'effector-root'
import { RequestDeleteLessonsParams } from '@/pages/learning/learning-lessons/list/model/types'
import {
  requestDeleteLessonsFx,
  deleteOneLessonFx,
} from '@/features/api/learning/lessons/delete-lessons'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { exportLessonsListFx } from '@/features/api/learning/lessons/export-lessons-list'
import { lessonsFilters } from './learn-lessons-filters.model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'
import { lessonPageMounted } from './tooltip-filter.model'

const getLessonsList = attach({
  effect: getLessonsListFx,
  mapParams: () => ({}),
})

export const $isLoading = combine(getLessonsListFx.pending, (list) => list)

export const requestDeleteLessons = attach({
  effect: requestDeleteLessonsFx,
  mapParams: (payload: RequestDeleteLessonsParams): RequestDeleteLessonsParams => {
    return {
      lessons: payload.lessons,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const exportLessonsList = attach({
  effect: exportLessonsListFx,
  source: [lessonsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportLessonsList.doneData.watch((res) => fileDownload(res.body, 'lessonsList.xlsx'))

const needRefresh = createEvent<boolean>()

forward({
  from: [requestDeleteLessons.done, deleteOneLessonFx.done],
  to: [
    getLessonsList,
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    needRefresh.prepend(() => true),
  ],
})

export const $refreshPage = createStore(false)
  .on(needRefresh, (_, payload) => payload)
  .reset(lessonPageMounted)

forward({
  from: lessonPageMounted,
  to: getLessonsList,
})
