import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import {
  $selectedClass,
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  $selectedSubject,
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import {
  $selectedTheme,
  setSelectedTheme,
  themesDropdownModule,
} from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import { condition } from 'patronum'
import { createLabelFx } from '@/features/api/assignment/labels/create-label'
import { getLabelsTreeInfo, getLabelsTreeLight } from '@/pages/labels/labels-page.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { createError } from '@/lib/effector/error-generator'
import { getUserStudyYearFx } from '@/features/api/study_year/get-user-study-year'
import { getSubjectFx } from '@/features/api/subject/get-subject'
import { getThemeFx } from '@/features/api/subject/get-theme'
import { CreateLabelFromTree } from '@/pages/labels/types'

const getTheme = attach({
  effect: getThemeFx,
})

const getSubject = attach({
  effect: getSubjectFx,
})

const getUserStudyYear = attach({
  effect: getUserStudyYearFx,
})

export const createLabel = attach({
  effect: createLabelFx,
})

export const createLabelFromTree = createEvent<CreateLabelFromTree>()

export const checkIfThemeCanBeSend = createEvent<void>()
export const clearFields = createEvent<void>()

export const modalVisibilityChanged = createEvent<boolean>()
export const $modalVisibility = restore(modalVisibilityChanged, false)

export const labelTitleChanged = createEvent<string>()
const labelTitleReset = createEvent<void>()
export const $labelTitle = restore(labelTitleChanged, '').reset(labelTitleReset)

export const $subjectErrorModule = createError()

export const $classErrorModule = createError()

export const $themeErrorModule = createError()

export const $titleErrorModule = createError()

const $form = combine({
  name: $labelTitle,
  study_year_id: $selectedClass.map((data) => (data && data.name ? +data.name : null)),
  subject_id: $selectedSubject.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  theme_id: $selectedTheme.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  is_prerequisite: $selectedTheme.map((data) => data && data.is_prerequisite),
})

sample({
  source: $form,
  clock: checkIfThemeCanBeSend,
  fn: ({ name, subject_id, study_year_id, theme_id, is_prerequisite }) => {
    if (
      name.trim().length &&
      subject_id !== DEFAULT_ID &&
      theme_id !== DEFAULT_ID &&
      (is_prerequisite || study_year_id)
    ) {
      createLabel({
        name,
        subject_id,
        theme_id,
        study_year_id,
      })
    } else {
      if (!name.trim().length) $titleErrorModule.methods.setError(true)
      if (study_year_id === DEFAULT_ID) $classErrorModule.methods.setError(true)
      if (subject_id === DEFAULT_ID) $subjectErrorModule.methods.setError(true)
      if (theme_id === DEFAULT_ID) $themeErrorModule.methods.setError(true)
      errorToastEvent('Необходимо заполнить все обязательные поля')
    }
  },
})

sample({
  clock: createLabelFromTree,
  fn: ({ class_id, subject_id, theme_id, is_prerequisite }) => {
    class_id &&
      getUserStudyYear(class_id).then(({ body }) =>
        classesDropdownModule.methods.itemChanged({ name: `${body.id}`, title: body.name })
      )
    class_id &&
      setSelectedClass({
        name: `${class_id}`,
        title: `${class_id}`,
      })
    getSubject(subject_id).then(({ body }) =>
      subjectsDropdownModel.methods.itemChanged({ name: `${body.id}`, title: body.name })
    )
    setSelectedSubject({
      name: `${subject_id}`,
      title: `${subject_id}`,
    })
    getTheme(theme_id).then(({ body }) => {
      themesDropdownModule.methods.itemChanged({ name: `${body.id}`, title: body.name })
      setSelectedTheme({
        name: `${body.id}`,
        title: body.name,
        is_prerequisite,
      })
    })
    modalVisibilityChanged(true)
  },
})

forward({
  from: labelTitleChanged,
  to: $titleErrorModule.methods.resetError,
})

forward({
  from: subjectsDropdownModel.methods.itemChanged,
  to: $subjectErrorModule.methods.resetError,
})

forward({
  from: classesDropdownModule.methods.itemChanged,
  to: $classErrorModule.methods.resetError,
})

forward({
  from: themesDropdownModule.methods.itemChanged,
  to: $themeErrorModule.methods.resetError,
})

condition({
  source: modalVisibilityChanged,
  if: (payload: boolean) => !payload,
  then: clearFields,
})

forward({
  from: clearFields,
  to: [
    labelTitleReset,
    subjectsDropdownModel.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    themesDropdownModule.methods.resetDropdown,
    $titleErrorModule.methods.resetError,
    $subjectErrorModule.methods.resetError,
    $classErrorModule.methods.resetError,
    $themeErrorModule.methods.resetError,
    setSelectedClass.prepend(() => null),
    setSelectedSubject.prepend(() => null),
    setSelectedTheme.prepend(() => null),
  ],
})

forward({
  from: createLabelFx.doneData,
  to: [
    successToastEvent('Метка была успешно создана!'),
    getLabelsTreeLight.prepend(() => ({})),
    getLabelsTreeInfo,
    modalVisibilityChanged.prepend(() => false),
  ],
})
