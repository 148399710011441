import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import ExamsTestsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/exams-tests-filter/ExamsTestsFilterDropdown.vue'
import AttemptsPassStatusFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/attempts-pass-status-dropdown/AttemptsPassStatusFilterDropdown.vue'
import AttemptsStatusFilterDropdown from '@/pages/common/dropdowns/exams/attempts-status-dropdown/AttemptsStatusFilterDropdown.vue'
import GroupsFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/GroupsFilterDropdown.vue'
import StudentsAttemptsFilter from '@/pages/exams/attempts/list/parts/filter/parts/students-attempts-filter/StudentsAttemptsFilter.vue'

export const dropdownAttemptAllComponents = {
  GroupsFilterDropdown,
  StudentsAttemptsFilter,
  SubjectsDropdown,
  ClassesDropdown,
  ExamsTestsFilterDropdown,
  AttemptsStatusFilterDropdown,
  AttemptsPassStatusFilterDropdown,
}
