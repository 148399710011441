import { render, staticRenderFns } from "./LabelCreationModal.vue?vue&type=template&id=9d771704&scoped=true&"
import script from "./LabelCreationModal.vue?vue&type=script&lang=ts&"
export * from "./LabelCreationModal.vue?vue&type=script&lang=ts&"
import style0 from "./LabelCreationModal.vue?vue&type=style&index=0&id=9d771704&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d771704",
  null
  
)

export default component.exports