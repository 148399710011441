







































import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import Icon from '@/ui/icon/Icon.vue'
import {
  coursesDropdownModel,
  $courses,
  unselectAllCourses,
  $isSelectedAllCourses,
  $selectedCourses,
  setSelectedCourses,
  deleteSelectedCourse,
  loadCourses,
  selectAllCourses,
} from '@/pages/users/students/list/parts/courses-dropdown/courses-dropdown.model'

export default Vue.extend({
  components: {
    FilterDropdown,
    Icon,
  },
  effector: {
    ...coursesDropdownModel.store,
    $courses,
    $selectedCourses,
    $isSelectedAllCourses,
  },
  props: {
    label: String,
  },
  data() {
    return {
      coursesDropdownMethods: coursesDropdownModel.methods,
    }
  },
  methods: {
    ...coursesDropdownModel.methods,
    unselectAllCourses,
    onSelectItem(item: DropdownItem | null, selectedItems: DropdownItem[]) {
      if (item !== null) {
        const queryParams = selectedItems.map((element) => element.name).join()
        item.name === 'all' ? selectAllCourses() : setSelectedCourses(item)
        this.$emit('setItem', queryParams.length > 0 ? `${item.name},${queryParams}` : item.name)
      }
    },
    deleteCourse(event: MouseEvent, item: DropdownItem) {
      event.stopPropagation()
      event.preventDefault()
      deleteSelectedCourse(item)
    },
  },
  mounted() {
    loadCourses()
  },
  destroyed() {
    unselectAllCourses()
  },
})
