














import Vue, { PropType } from 'vue'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { olympiadResultsFilters } from '@/pages/results/olympiad-results/model/olympiad-results-filter.model'
import { exportOlympiadResultsListFx } from '@/features/api/results/export-olympiad-results'
import { $permissions } from '@/features/session'

export default Vue.extend({
  name: 'OlympiadHeader',
  components: {
    DownloadButton,
    GridPageHead,
    HeaderPopup,
  },
  effector: {
    $filterParams: olympiadResultsFilters.store.$filterParams,
    $permissions,
    isLoadingExport: exportOlympiadResultsListFx.pending,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<number[]> },
  },
})
