import { createEvent, createStore, guard } from 'effector-root'
import { getInstructionIdFx } from '@/features/api/instructions/get-instructon-dy-id'
import { resetFields } from '@/pages/dictionary/instructions/create/model/form-fileds.model'

export const changeInstructionsId = createEvent<string | never>()

export const $editedInstructionId = createStore<string>('')
  .on(changeInstructionsId, (state, payload) => payload || state)
  .reset(resetFields)

guard({
  source: changeInstructionsId,
  filter: (payload) => !!payload,
  target: getInstructionIdFx,
})
