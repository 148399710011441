





































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import Switchers from '@/pages/bank/lesson-tasks/list/parts/modals/tasks-update/parts/switchers/Switchers.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  $tasksIds,
  tasksIdsChanged,
  submitForm,
  cancelForm,
  $tasksIdsErrorModule,
} from '@/pages/bank/lesson-tasks/list/parts/modals/tasks-update/tasks-update-modal.model'

export default Vue.extend({
  name: 'TasksUpdateModal',
  components: {
    Modal,
    BaseTextarea,
    Switchers,
    BaseButton,
  },
  effector: {
    $modalVisibility,
    $tasksIds,
    idsError: $tasksIdsErrorModule.store.$error,
  },
  methods: {
    modalVisibilityChanged,
    tasksIdsChanged,
    submitForm,
    cancelForm,
  },
})
