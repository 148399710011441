import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import { condition } from 'patronum'
import { DEFAULT_ID } from '@/pages/common/constants'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { successToastEvent } from '@/features/toasts/toasts.model'
import {
  $selectedType,
  resetSelectedType,
  typeDropdownModule,
} from '@/pages/common/dropdowns/content/banners/type/type-dropdown.model'
import { createBannerFx } from '@/features/api/content/banners/create-banner'
import { isLinkValid } from '@/lib/validators/url'

const saveBannerFx = attach({
  effect: createBannerFx,
})

export const clearFields = createEvent<void>()

export const create = createEvent<void>()

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

export const resetName = createEvent<void>()
export const nameChanged = createEvent<string>()
export const $name = restore(nameChanged, '').reset(resetName)

export const resetText = createEvent<void>()
export const textChanged = createEvent<string>()
export const $text = restore(textChanged, '').reset(resetText)

export const resetBtnTitle = createEvent<void>()
export const btnTitleChanged = createEvent<string>()
export const $btnTitle = restore(btnTitleChanged, '').reset(resetBtnTitle)

export const resetBtnUrl = createEvent<void>()
export const btnUrlChanged = createEvent<string>()
export const $btnUrl = restore(btnUrlChanged, '').reset(resetBtnUrl)

export const resetTimerTarget = createEvent<void>()
export const timerTargetChanged = createEvent<Date | string | null>()
export const $timerTarget = restore(timerTargetChanged, '').reset(resetTimerTarget)

export const toggleIsActive = createEvent<boolean>()
export const $isActive = restore(toggleIsActive, false).reset(clearFields)

forward({
  from: clearFields,
  to: [
    resetName,
    resetBtnTitle,
    resetText,
    resetBtnUrl,
    typeDropdownModule.methods.resetDropdown,
    resetSelectedType,
  ],
})

export const $disabledSaveButtons = combine(
  $name,
  $text,
  $selectedType,
  $btnTitle,
  $btnUrl,
  $timerTarget,
  (name, text, type, btnTitle, btnUrl, time) =>
    !name ||
    !text ||
    !type?.name ||
    !btnTitle ||
    !btnUrl ||
    !isLinkValid(btnUrl) ||
    (type.name === 'timer' && !time)
)

export const $formToSend = combine(
  $selectedType,
  $name,
  $text,
  $btnTitle,
  $btnUrl,
  $isActive,
  $timerTarget,
  (type, name, text, button_title, button_url, active, timerTarget) => {
    return {
      id: DEFAULT_ID,
      banner_type: type?.name || 'no_timer',
      name,
      text,
      button_title,
      button_url,
      active,
      timer_target: timerTarget ? new Date(timerTarget) : null,
    }
  }
)

sample({
  clock: create,
  source: $formToSend,
  target: saveBannerFx,
})

const $ifRedirectTheme = sample({
  clock: saveBannerFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

forward({
  from: saveBannerFx.doneData,
  to: successToastEvent('Баннер успешно создан!'),
})

condition({
  source: $ifRedirectTheme,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'banners-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'banners-edit',
    params: { id: `${payload.id}` },
  })),
})
