











import Vue from 'vue'
import { DropdownItem } from '@/pages/common/types'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  resultValueDropdownModel,
  setResultValueData,
} from '@/pages/common/dropdowns/value-change-dropdowns/result-value-dropdown/result-value-dropdown.model'

export default Vue.extend({
  name: 'ResultValueDropdown',
  components: {
    FilterDropdown,
  },
  effector: {
    ...resultValueDropdownModel.store,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Выберите',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      searchString: '',
    }
  },
  watch: {
    maxValue(value) {
      setResultValueData(value)
    },
  },
  methods: {
    ...resultValueDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('onChange', item)
    },
    searchStChanged(val: string) {
      this.searchString = val
      this.searchStringChanged(val)
    },
    resetSearchStr() {
      this.searchString = ''
      this.resetSearchString()
    },
  },
  mounted() {
    setResultValueData(this.maxValue)
  },
})
