













import Vue from 'vue'
import { DropdownItem } from '@/pages/common/types'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  studentsDropdownModule,
  loadStudents,
} from '@/pages/results/tests-students/parts/filter/parts/students-tests-result/students-dropdown.model'

export default Vue.extend({
  name: 'StudentsTests',
  components: {
    FilterDropdown,
  },
  effector: {
    ...studentsDropdownModule.store,
  },
  methods: {
    ...studentsDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (this.$items.length === 0) loadStudents()
  },
})
