





























































































import Vue, { VueConstructor } from 'vue'
import { config } from '@/config'
import { $token } from '@/features/api/common/request'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import {
  searchFieldsData,
  outgoingApplicationsDataFields,
  getOutgoingTicketsActions,
} from '@/pages/applications/outgoing/constants'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import PageHeader from '@/pages/applications/outgoing/parts/header/PageHeader.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ApplicationsFilter from '@/pages/applications/outgoing/parts/filter/Filter.vue'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  cancelApplicationsFx,
  loadList,
  $canUpdateTable,
  outgoingApplicationsPageParams,
  $isLoading,
  exportTicketsList,
} from '@/pages/applications/outgoing/outgoing-applications-page.model'
import {
  toggleVisibility,
  $visibility,
  outgoingApplicationsFilters,
} from '@/pages/applications/outgoing/parts/filter/filter.model'
import { $permissions } from '@/features/session'
import CancelModal from '@/pages/applications/modals/cancel/CancelModal.vue'
import { loadModal } from '@/pages/applications/modals/cancel/cancel.model'
import OutgoingModal from '@/pages/applications/modals/outgoing-comment/OutgoingComment.vue'
import {
  RefsType,
  HttpOptionsType,
  CommonInteractedItemParams,
  DisplayContextMenuPayload,
  ActionsItem,
} from '@/pages/common/types'
import { loadCommentModal } from '@/pages/applications/modals/outgoing-comment/outgoing-comment.model'
import { loadApplicationsTasks } from '@/pages/preview-tasks/parts/tasks-dropdown/tasks-dropdown.model'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import { DEFAULT_ID } from '@/pages/common/constants'
import TooltipCell from '@/pages/applications/outgoing/parts/table/TooltipCell.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { getActionsDisplayConditions } from '@/pages/common'
import { axiosClient } from '@/lib/request'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    NoDataContent,
    PageHeader,
    GeneralFilter,
    ApplicationsFilter,
    TableHeader,
    ActionsButton,
    ContextMenu,
    Vuetable,
    VuetablePagination,
    CancelModal,
    OutgoingModal,
    LoaderBig,
    TooltipCell,
  },
  effector: {
    $visibility,
    $token,
    canRefreshTableAfterCancel: $canUpdateTable,
    $filterParams: outgoingApplicationsFilters.store.$filterParams,
    $pageParams: outgoingApplicationsPageParams.store.$pageParams,
    $currentPage: outgoingApplicationsPageParams.store.currentPage,
    $isLoading,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      searchFields: searchFieldsData,
      total: 1,
      fields: outgoingApplicationsDataFields,
      selectedRows: [] as number[],
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/ticket-app/moderation-ticket/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getOutgoingTicketsActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    canRefreshTableAfterCancel: {
      handler(newVal) {
        if (newVal) {
          this.$refs.vuetable.reload()
          this.removeSelection()
        }
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    changeFilter: outgoingApplicationsFilters.methods.changeFilter,
    resetFilters: outgoingApplicationsFilters.methods.resetFilters,
    applyFilters: outgoingApplicationsFilters.methods.applyFilters,
    changePage: outgoingApplicationsPageParams.methods.changePage,
    queryToParams: outgoingApplicationsPageParams.methods.queryToParams,
    toggleVisibility,
    loadList,
    reset,
    exportTicketsList,
    async showPreview() {
      const values = await this.getCorrectValues(this.selectedIds)
      const applications = values.map((el) => el.application)
      const tasks = values.map((el) => el.assignment)
      navigatePush({
        name: 'preview-task',
        query: {
          applications: applications.join(','),
          questions: tasks.join(','),
          fromPage: 'applications',
          taskType: 'test-assignment',
          token: this.$token,
        },
      })
    },
    async editApplications() {
      const values = await this.getCorrectValues(this.selectedIds)
      const applications = values.map((el) => el.application)
      const tasks = values.map((el) => el.assignment)
      navigatePush({
        name: 'test-tasks-edit',
        query: {
          applications: applications.join(','),
          questions: tasks.join(','),
          fromPage: 'applications',
        },
        params: { id: `${tasks[0]}` },
      })
    },
    async getCorrectValues(applicationIds: number[]) {
      const values = await loadApplicationsTasks(applicationIds).then((res) => {
        return res.body.data
          .sort((a, b) => {
            return (
              applicationIds.indexOf(a.test_assignment.id) -
              applicationIds.indexOf(b.test_assignment.id)
            )
          })
          .map((data) => ({ application: data.id, assignment: data.test_assignment.id }))
      })
      return values
    },
    cancelApplications() {
      loadModal(this.selectedIds)
    },
    submitCancelApplications(ids: number[]) {
      cancelApplicationsFx({ tickets: ids })
    },
    showComments() {
      loadCommentModal(this.selectedIds[0])
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const request = axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
      return request
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      reset() // search string and field
      this.resetFilters()
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getOutgoingTicketsActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
      event.preventDefault()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  mounted() {
    loadList({})
  },
  destroyed() {
    this.resetFilters()
  },
})
