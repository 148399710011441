<template>
  <div class='content'>
    <div class='base'>
      <div class='left'>
        <SubjectDropdown class="field" @setItem="setSubject" />
        <ClassDropdown class="field" @setItem="setClass" />
        <ScoreDropdown class="field" @setItem="setScore" />
        <TagsDropdown class="field" :disabled="!$canSetTags" />
        <TaskTypesDropdown class="field" @setItem="setTaskType" />
        <component
          v-if="$taskType"
          :is="taskComponent"
        />
        <div
          v-if="$taskType"
          class="field"
        >
          <Clues />
        </div>
        <div
          v-if="$taskType"
          class="field"
        >
          <ShowAnswerBlock
            :show-solution-enabled="$showSolutionEnabled"
            :solution-text="$solutionText"
            @showSolutionEnabledChanged="showSolutionEnabledChanged"
            @set-solution="setSolutionText"
          />
        </div>
        <LanguagesDropdown
          class="field"
          :value="$language"
          @click="setLanguage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SubjectDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import ScoreDropdown from '@/pages/common/dropdowns/bank/olympiad-tasks/score-dropdown/ScoreDropdown.vue'
import TaskTypesDropdown from '@/pages/common/dropdowns/bank/task-types-dropdown/TaskTypesDropdown.vue'
import TagsDropdown from '@/pages/common/dropdowns/multiselectDropdown/tags-dropdown-filter/TagsFilterDropdown.vue'
import Clues from '@/pages/common/parts/tasks/parts/clues/Clues.vue'
import ShowAnswerBlock from '@/pages/common/parts/tasks/parts/show-answer-block/ShowAnswerBlock.vue'
import LanguagesDropdown from '@/pages/bank/olympiad-tasks/create/parts/languages-dropdown/LanguagesDropdown.vue'
import {
  setSubject,
  setClass,
  setScore,
  $taskType,
  setTaskType,
  $language,
  setLanguage,
  $showSolutionEnabled,
  showSolutionEnabledChanged,
  $solutionText,
  setSolutionText,
  $canSetTags,
} from '@/pages/bank/olympiad-tasks/create/task-creation-page.model'
import * as tasks from '@/pages/common/parts/tasks/'
import { mapTaskTypeTo } from '@/pages/common/constants'
import { resetCounters } from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/moving-images-on-image-answer-form.model'
import { resetCounters as resetImagesOnTextCounters } from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'

export default Vue.extend({
  name: 'TaskContent',
  components: {
    SubjectDropdown,
    ClassDropdown,
    ScoreDropdown,
    TaskTypesDropdown,
    TagsDropdown,
    Clues,
    ShowAnswerBlock,
    LanguagesDropdown,
  },
  effector: {
    $taskType,
    $language,
    $showSolutionEnabled,
    $solutionText,
    $canSetTags,
  },
  computed: {
    taskComponent() {
      return this.$taskType ? tasks[mapTaskTypeTo[this.$taskType].componentName] : null
    },
  },
  watch: {
    taskComponent: {
      handler() {
        resetCounters()
        resetImagesOnTextCounters()
      },
    },
  },
  methods: {
    setSubject,
    setClass,
    setScore,
    setTaskType,
    setLanguage,
    showSolutionEnabledChanged,
    setSolutionText,
  },
})
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #fff;
  border-radius: 3px;
}
.base {
  display: flex;
  justify-content: space-between;
}
.left,
.right {
  display: flex;
  flex-direction: column;
}
.left {
  @mixin bank-base-left;
}
.field {
  margin-bottom: 20px;
}
@media screen and (max-width: 1825px) {
  .left {
    flex-basis: 65%;
  }
}
@media screen and (max-width: 1600px) {
  .right {
    flex-basis: 0;
  }
}
</style>
