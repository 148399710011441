














import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  DropdownAssignmentBlockNames,
  formToGetThemeList,
  TaskTypeNames,
} from '@/pages/learning/learning-lessons/create/model/types'
import { LessonsAssignBlock } from '@/pages/learning/learning-lessons/create/model/assignment-block.model'
import { getLessonsThemesTreeListFx } from '@/features/api/learning/lessons/get-theme-tree-list'
import { formatData, formatFoldersData, sortTreeLeaves } from '@/features/lib'
import { debounce } from 'throttle-debounce'
import { getFolderTreeFx } from '@/features/api/assignment/folder/get-folder-tree'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    label: String,
    blockId: Number,
  },
  effector: {
    ...LessonsAssignBlock.modules.themesDropdownModel.store,
    $themes: LessonsAssignBlock.store.$themes,
    $selectedTheme: LessonsAssignBlock.store.$selectedTheme,
    $selectedClass: LessonsAssignBlock.store.$selectedClass,
    $selectedSubject: LessonsAssignBlock.store.$selectedSubject,
    $formToGetThemeList: LessonsAssignBlock.store.$formToGetThemeList,
    $radioButtonValue: LessonsAssignBlock.store.$taskType,
  },
  data: () => ({
    loading: false,
    taskType: TaskTypeNames,
    searchString: '',
  }),
  computed: {
    disabled(): boolean {
      return this.$radioButtonValue[this.blockId] !== this.taskType.lesson
        ? !this.$selectedClass[this.blockId]?.title || !this.$selectedSubject[this.blockId]?.title
        : false
    },
  },
  watch: {
    $formToGetThemeList: {
      handler(newVal, oldVal) {
        if (newVal.taskType[this.blockId] !== oldVal.taskType[this.blockId]) {
          this.resetSearchString()
        }
        if (newVal.taskType[this.blockId] !== TaskTypeNames.olympiad) {
          this.loading = true
          LessonsAssignBlock.methods.setThemesList({ data: [], id: this.blockId })
          /* const study_year = newVal.study_year[this.blockId]?.name
            ? Number(newVal.study_year[this.blockId].name)
            : undefined
          const subject = newVal.subject[this.blockId]?.name
            ? Number(newVal.subject[this.blockId].name)
            : undefined
          const query = {
            ...(study_year ? { study_year } : {}),
            ...(subject ? { subject } : {}),
            is_not_prerequisite: true,
          } */
          this.setThemeList(newVal, null)
        }
      },
    },
    searchString: {
      handler() {
        this.searchTheme()
      },
    },
  },
  methods: {
    ...LessonsAssignBlock.modules.themesDropdownModel.methods,
    calculateQueryParams(val: formToGetThemeList | null, search: string | null) {
      const study_year = val?.study_year[this.blockId]?.name
        ? Number(val.study_year[this.blockId].name)
        : null
      const subject = val?.subject[this.blockId]?.name
        ? Number(val.subject[this.blockId].name)
        : null
      const query = {
        ...(study_year ? { study_year } : {}),
        ...(subject ? { subject } : {}),
        ...(search ? { search, search_area: 'search_all' } : {}),
        is_not_prerequisite: true,
      }
      return query
    },
    resetSearchString() {
      this.searchString = ''
    },
    searchStringChanged(value: string) {
      this.searchString = value
    },
    async setThemeList(form: formToGetThemeList, search: string | null) {
      let themesData = null
      if (form.taskType[this.blockId] === TaskTypeNames.lesson) {
        themesData = await getFolderTreeFx(this.calculateQueryParams(null, search)).then((res) => {
          // @ts-ignore
          return formatFoldersData(sortTreeLeaves(res.body.data), false)
        })
      } else {
        themesData = await getLessonsThemesTreeListFx(this.calculateQueryParams(form, search)).then(
          (res) => {
            return formatData(res.body, false)
          }
        )
      }
      this.loading = false
      themesData && LessonsAssignBlock.methods.setThemesList({ data: themesData, id: this.blockId })
    },
    onSelectItem(item: DropdownItem | null) {
      LessonsAssignBlock.methods.setSelectedType({
        value: item ? { name: item.name, title: item.title } : null,
        type: DropdownAssignmentBlockNames.theme,
        id: this.blockId,
      })
    },
    searchTheme: debounce(300, false, function getDebounceTask(this: any) {
      this.setThemeList(this.$formToGetThemeList, this.searchString)
    }),
  },
})
