import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { dropdownModules } from '@/pages/results/courses-all/parts/filter/parts/dropdown-modules'
import { createEvent, forward, restore } from 'effector-root'
import { combine } from 'effector/compat'
import { groupsDropdownModule } from '@/pages/common/dropdowns/groups/groups-dropdown.model'
import { lessonsDropdownModule } from '@/pages/common/dropdowns/lessons/lesson-dropdown.model'
import { studentsDropdownModule } from '@/pages/common/dropdowns/students/students-dropdown.model'
import { courseDropdownModule } from '@/pages/common/dropdowns/bank/lesson-tasks/course-dropdown/course-dropdown.model'

export const resultStudentsFilters = createFiltersModel({}, dropdownModules)

export const setFindCourseId = createEvent<number>()
export const $findCourseId = restore(setFindCourseId, 0).reset(
  resultStudentsFilters.methods.resetFilters
)

export const setFindLessonId = createEvent<number>()
export const $findLessonId = restore(setFindLessonId, 0).reset([
  setFindCourseId,
  resultStudentsFilters.methods.resetFilters,
])

export const setFindStatus = createEvent<string>()
export const $findStatus = restore(setFindStatus, '').reset([
  setFindLessonId,
  resultStudentsFilters.methods.resetFilters,
])

export const setFindGroup = createEvent<number>()
export const $findGroup = restore(setFindGroup, 0).reset([
  setFindLessonId,
  resultStudentsFilters.methods.resetFilters,
])

export const $CourseLessonId = combine(
  $findCourseId,
  $findLessonId,
  $findStatus,
  $findGroup,
  (course, lesson, status, group) => {
    return {
      course,
      lesson,
      ...(status ? { status } : {}),
      ...(group ? { group } : {}),
    }
  }
)

forward({
  from: resultStudentsFilters.methods.resetFilters,
  to: [
    courseDropdownModule.methods.resetDropdown,
    lessonsDropdownModule.methods.resetDropdown,
    groupsDropdownModule.methods.resetDropdown,
    studentsDropdownModule.methods.resetDropdown,
  ],
})

forward({
  from: setFindCourseId,
  to: [
    lessonsDropdownModule.methods.resetDropdown,
    groupsDropdownModule.methods.resetDropdown,
    studentsDropdownModule.methods.resetDropdown,
  ],
})

forward({
  from: setFindLessonId,
  to: [groupsDropdownModule.methods.resetDropdown, studentsDropdownModule.methods.resetDropdown],
})
