import { combine, createEvent, createStore, sample } from 'effector-root'
import { spread } from 'patronum'
import {
  ChangeEmployeeField,
  ChangeTableColumn,
  CreateEmployeeTable,
  EditEmployeeFields,
  EmployeePermissions,
  EmployeeRole,
  CreateEmployeeTableCell,
} from '@/features/api/employee/types'
import {
  $isSelectedAllSubjects,
  $selectedSubjects,
} from '@/pages/users/employes/edit/model/dropdowns/subjects-dropdown.model'
import { rolesDropdownModel } from '@/pages/users/employes/list/parts/roles-dropdown/roles-dropdown.model'
import { getEmployeeByIdFx } from '@/features/api/employee/get-employee-by-id'
import {
  $isSelectedAllClasses,
  $selectedClasses,
} from '@/pages/users/students/list/parts/classes-dropdown/classes-dropdown.model'
import {
  $isSelectedAllGroups,
  $selectedGroups,
} from '@/pages/users/students/list/parts/groups-dropdown/groups-dropdown.model'
import { coursesDropdownModel } from '@/pages/users/employes/edit/parts/courses-dropdown/courses-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export const changeFieldValue = createEvent<ChangeEmployeeField>()

export const clearFields = createEvent<void>()

export const $firstName = createStore<string>('').reset(clearFields)
export const $lastName = createStore<string>('').reset(clearFields)
export const $email = createStore<string>('').reset(clearFields)
export const $password = createStore<string>('').reset(clearFields)
export const $isActiveUser = createStore<boolean>(true).reset(clearFields)
export const $internalComment = createStore<string>('').reset(clearFields)

export const $disabledSaveButtons = combine(
  rolesDropdownModel.store.$item,
  $firstName,
  $lastName,
  $email,
  (role, firstName, lastName, email) => !role || !firstName || !lastName || !email
)

spread({
  source: changeFieldValue.map((data) => {
    const obj = {}
    obj[data.type] = data.value
    return obj
  }),
  targets: {
    [EditEmployeeFields.first_name]: $firstName,
    [EditEmployeeFields.last_name]: $lastName,
    [EditEmployeeFields.email]: $email,
    [EditEmployeeFields.password]: $password,
    [EditEmployeeFields.active_user]: $isActiveUser,
    [EditEmployeeFields.internal_cms_comment]: $internalComment,
  },
})

export const $employeeForm = combine(
  rolesDropdownModel.store.$item,
  $firstName,
  $lastName,
  $email,
  $password,
  $isActiveUser,
  $internalComment,
  $selectedSubjects,
  $selectedGroups,
  coursesDropdownModel.store.$selectedItems,
  $selectedClasses,
  (
    role,
    firstName,
    lastName,
    email,
    password,
    isActive,
    comment,
    selectedSubjects,
    selectedGroups,
    selectedCourses,
    selectedStudyYears
  ) => ({
    [EditEmployeeFields.first_name]: firstName,
    [EditEmployeeFields.last_name]: lastName,
    [EditEmployeeFields.email]: email,
    [EditEmployeeFields.password]: password,
    [EditEmployeeFields.active_user]: isActive,
    [EditEmployeeFields.internal_cms_comment]: comment,
    [EditEmployeeFields.available_subjects]: [
      ...new Set(selectedSubjects.map((el) => Number(el.name))),
    ],
    [EditEmployeeFields.available_groups]: [
      ...new Set(selectedGroups.map((el) => Number(el.name))),
    ],
    [EditEmployeeFields.available_courses]: [
      ...new Set(
        selectedCourses.filter((course) => course.name !== 'all').map((el) => Number(el.name))
      ),
    ],
    [EditEmployeeFields.available_study_years]: [
      ...new Set(selectedStudyYears.map((el) => Number(el.name))),
    ],
    [EditEmployeeFields.role]: role ? role.name : '',
  })
)

spread({
  source: getEmployeeByIdFx.doneData.map((res) => ({
    ...res.body,
    [EditEmployeeFields.internal_cms_comment]: res.body[EditEmployeeFields.internal_cms_comment]
      ? res.body[EditEmployeeFields.internal_cms_comment]
      : '',
    [EditEmployeeFields.ro_available_courses]: res.body[
      EditEmployeeFields.ro_available_courses
    ].map((el: { id: number; name: string }) => ({ name: `${el.id}`, title: el.name })),
    [EditEmployeeFields.ro_available_groups]: res.body[EditEmployeeFields.ro_available_groups].map(
      (el: { id: number; name: string }) => ({ name: `${el.id}`, title: el.name })
    ),
    [EditEmployeeFields.ro_available_study_years]: res.body[
      EditEmployeeFields.ro_available_study_years
    ].map((el: { id: number; name: string }) => ({ name: `${el.id}`, title: el.name })),
    [EditEmployeeFields.ro_available_subjects]: res.body[
      EditEmployeeFields.ro_available_subjects
    ].map((el: { id: number; name: string }) => ({ name: `${el.id}`, title: el.name })),
  })),
  targets: {
    [EditEmployeeFields.first_name]: $firstName,
    [EditEmployeeFields.last_name]: $lastName,
    [EditEmployeeFields.email]: $email,
    [EditEmployeeFields.password]: $password,
    [EditEmployeeFields.active_user]: $isActiveUser,
    [EditEmployeeFields.internal_cms_comment]: $internalComment,
    [EditEmployeeFields.available_subjects_all]: $isSelectedAllSubjects,
    [EditEmployeeFields.available_study_years_all]: $isSelectedAllClasses,
    [EditEmployeeFields.available_groups_all]: $isSelectedAllGroups,
    [EditEmployeeFields.role]: rolesDropdownModel.methods.itemChanged.prepend(
      (payload: EmployeeRole) => {
        switch (payload) {
          case 'ADMIN':
            return {
              name: payload,
              title: 'администратор',
            }
          case 'METHODIST':
            return {
              name: payload,
              title: 'методист',
            }
          case 'SUPER_ADMIN':
            return {
              name: payload,
              title: 'Супер администратор',
            }
          default:
            return null
        }
      }
    ),
    [EditEmployeeFields.ro_available_courses]:
      coursesDropdownModel.methods.setSelectedItems.prepend((payload: DropdownItem[]) => {
        if (payload.length === 0) {
          return [{ name: 'all', title: 'Все' }]
        }
        return payload
      }),
    [EditEmployeeFields.ro_available_groups]: $selectedGroups,
    [EditEmployeeFields.ro_available_study_years]: $selectedClasses,
    [EditEmployeeFields.ro_available_subjects]: $selectedSubjects,
  },
})

export const fetchTableData = createEvent<EmployeePermissions>()

export const changeTableCanEditColumn = createEvent<ChangeTableColumn>()

export const changeTableCanViewColumn = createEvent<ChangeTableColumn>()

const $tableData = createStore<CreateEmployeeTable>({
  data: [
    {
      id: 0,
      type: 'tickets_moderationticket_income_tickets',
      name: 'test',
      available_to_methodist: false,
      available_to_admin: false,
      available_to_superadmin: false,
      can_edit: false,
      can_view: false,
    },
  ],
})
  .on(fetchTableData, (_, payload) => ({
    data: Object.entries(payload).map((arr, i) => ({
      ...arr[1],
      type: arr[0],
      id: i,
      can_edit: false,
      can_view: false,
    })),
  }))
  .on(getEmployeeByIdFx.doneData, (_, res) => ({
    data: Object.entries(res.body.permissions).map((arr, i) => ({
      ...arr[1],
      type: arr[0],
      id: i,
    })),
  }))
  .reset(clearFields)

export const $filteredTableData = createStore<CreateEmployeeTable>({
  data: [
    {
      id: 0,
      type: 'tickets_moderationticket_income_tickets',
      name: 'test',
      available_to_methodist: false,
      available_to_admin: false,
      available_to_superadmin: false,
      can_edit: false,
      can_view: false,
    },
  ],
})
  .on(changeTableCanEditColumn, (state, payload) => {
    return {
      data: state.data.map((el: any) => {
        if (el.id !== payload.data.id) {
          return { ...el }
        }
        return { ...el, can_edit: !el.can_edit }
      }),
    }
  })
  .on(changeTableCanViewColumn, (state, payload) => {
    return {
      data: state.data.map((el: any) => {
        if (el.id !== payload.data.id) {
          return { ...el }
        }
        return { ...el, can_view: !el.can_view }
      }),
    }
  })
  .reset(clearFields)

sample({
  source: $tableData,
  clock: rolesDropdownModel.store.$item,
  fn: (tableData, role) => {
    switch (role?.name) {
      case 'METHODIST':
        return {
          data: tableData.data.filter((el: CreateEmployeeTableCell) => el.available_to_methodist),
        }
      case 'ADMIN':
        return {
          data: tableData.data
            .filter((el: CreateEmployeeTableCell) => el.available_to_admin)
            .map((el: CreateEmployeeTableCell) => ({ ...el, can_edit: true, can_view: true })),
        }
      case 'SUPER_ADMIN':
        return {
          data: tableData.data
            .filter((el: CreateEmployeeTableCell) => el.available_to_superadmin)
            .map((el: CreateEmployeeTableCell) => ({ ...el, can_edit: true, can_view: true })),
        }
      default:
        return tableData
    }
  },
  target: $filteredTableData,
})

export const $permissions = $filteredTableData.map((table) => {
  return table.data.reduce(
    (o, key) =>
      Object.assign(o, { [key.type]: { can_edit: key.can_edit, can_view: key.can_view } }),
    {}
  )
})
