import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'

export const examsAnswersTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'number',
    title: '№',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'attempt',
    sortField: 'attempt',
    title: 'ID попытки',
    width: '130px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID ответа',
    width: '130px',
  },
  {
    name: 'order_number',
    sortField: 'order_number',
    titleClass: 'wrap',
    title: '№ шага процессинга экзамена',
    width: '190px',
  },
  {
    name: 'subject',
    sortField: 'subject',
    title: 'Предмет',
    width: '145px',
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    title: 'Класс',
    width: '145px',
  },
  {
    name: 'test_name',
    sortField: 'test_name',
    titleClass: 'wrap',
    title: 'Экзаменационный тест',
    width: '180px',
  },
  {
    name: 'groups',
    sortField: 'groups',
    title: 'Группа',
    width: '140px',
  },
  {
    name: 'student_id',
    sortField: 'student_id',
    title: 'ID ученика',
    width: '130px',
  },
  {
    name: 'student_name',
    sortField: 'student_name',
    title: 'Ученик',
    width: '170px',
  },
  {
    name: 'theme',
    sortField: 'theme',
    title: 'Тема',
    width: '170px',
  },
  {
    name: 'assignment',
    sortField: 'assignment',
    title: 'ID задания',
    width: '170px',
  },
  {
    name: 'is_correct',
    sortField: 'is_correct',
    title: 'Ответ верен',
    width: '170px',
    formatter(isCorrect: boolean) {
      return isCorrect ? 'Да' : 'Нет'
    },
  },
  {
    name: 'wording',
    sortField: 'wording',
    titleClass: 'wrap',
    title: 'Формулировка задания',
    width: '210px',
  },
  {
    name: 'answer',
    sortField: 'answer',
    dataClass: 'wrap',
    title: 'Ответ ученика',
    width: '400px',
  },
  {
    name: 'correct_answer',
    sortField: 'correct_answer',
    dataClass: 'wrap',
    title: 'Правильный ответ',
    width: '400px',
  },
  {
    name: 'status',
    sortField: 'status',
    dataClass: 'wrap',
    title: 'Статус',
    width: '210px',
    formatter(status: number) {
      return status ? 'Дан ответ' : 'Выдан вопрос'
    },
  },
  {
    name: 'question_started_at',
    sortField: 'question_started_at',
    dataClass: 'wrap',
    title: 'Дата и время выдачи вопроса',
    width: '210px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : ''
    },
  },
  {
    name: 'answer_finished_at',
    sortField: 'answer_finished_at',
    dataClass: 'wrap',
    title: 'Дата и время ввода ответа',
    width: '210px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : ''
    },
  },
  {
    name: 'duration',
    sortField: 'duration',
    dataClass: 'wrap',
    title: 'Затраченное время (мм:сс:мс)',
    width: '210px',
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'attempt_id', title: 'ID попытки' },
  { name: 'answer_id', title: 'ID ответа' },
  { name: 'test', title: 'Название теста' },
  { name: 'subject', title: 'Предмет' },
  { name: 'student_id', title: 'ID абитуриента' },
  { name: 'student_name', title: 'Имя и фамилия абитуриента' },
  { name: 'group', title: 'Группа' },
]
