import { render, staticRenderFns } from "./GroupsPage.vue?vue&type=template&id=b3b8acd2&scoped=true&"
import script from "./GroupsPage.vue?vue&type=script&lang=ts&"
export * from "./GroupsPage.vue?vue&type=script&lang=ts&"
import style0 from "./GroupsPage.vue?vue&type=style&index=0&id=b3b8acd2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3b8acd2",
  null
  
)

export default component.exports