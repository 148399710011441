import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import {
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import {
  setSelectedClass,
  classesDropdownModule,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  $selectedTheme,
  setSelectedTheme,
  themesDropdownModule,
} from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import {
  $selectedType,
  setSelectedType,
  typeDropdownModule,
} from '@/pages/dictionary/resources/create/parts/type/type-dropdown.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import {
  $fileData,
  fileDataChanged,
  uploadFileFx,
} from '@/pages/dictionary/resources/create/parts/file-upload/file-upload.model'
import { isLinkValid } from '@/lib/validators/url'
import { createResourceFx } from '@/features/api/media/create-resource'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { createError } from '@/lib/effector/error-generator'
import { getThemeFx } from '@/features/api/subject/get-theme'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { Theme } from '@/features/api/subject/types'

type InitAssignment = {
  theme: number
  subject: number
  class: number
}

export const getTheme = attach({
  effect: getThemeFx,
})

const createResource = attach({
  effect: createResourceFx,
})

export const clearFields = createEvent<void>()

export const create = createEvent<void>()
const checkIfResourceCanBeSend = createEvent<void>()

export const resourceDescriptionChanged = createEvent<string>()
export const $resourceDescription = restore(resourceDescriptionChanged, '').reset(clearFields)

export const linkChanged = createEvent<string>()
export const resetLink = createEvent<void>()
export const $link = restore(linkChanged, '').reset(resetLink)

export const playerLinkChanged = createEvent<void>()
export const resetPlayerLink = createEvent<void>()
export const $playerLink = restore(playerLinkChanged, null).reset(resetPlayerLink)

const saveResource = createEvent<void>()

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

export const initAssignment = createEvent<InitAssignment>()

forward({
  from: initAssignment,
  to: getTheme.prepend(({ theme }) => theme),
})

sample({
  clock: getTheme.done,
  source: getThemeFx.doneData.map((data) => data.body),
  fn: (theme: Theme) => {
    setSelectedSubject({
      name: `${theme.subject.id}`,
      title: `${theme.subject.name}`,
    })
    subjectsDropdownModel.methods.itemChanged({
      name: `${theme.subject.id}`,
      title: `${theme.subject.name}`,
    })
    setSelectedClass({
      name: `${theme.study_year.id}`,
      title: `${theme.study_year.name}`,
    })
    classesDropdownModule.methods.itemChanged({
      name: `${theme.study_year.id}`,
      title: `${theme.study_year.name}`,
    })
    setSelectedTheme({
      name: `${theme.id}`,
      title: theme.name,
    })
    themesDropdownModule.methods.itemChanged({
      name: `${theme.id}`,
      title: theme.name,
    })
  },
})

forward({
  from: clearFields,
  to: [
    classesDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    themesDropdownModule.methods.resetDropdown,
    typeDropdownModule.methods.resetDropdown,
    setSelectedSubject.prepend(() => null),
    setSelectedClass.prepend(() => null),
    fileDataChanged.prepend(() => null),
  ],
})

export const $formToSend = combine({
  id: DEFAULT_ID,
  text: $resourceDescription,
  link: $link,
  player_link: $playerLink,
  theme: $selectedTheme.map((data) => (data ? +data.name : DEFAULT_ID)),
  media_id: $fileData.map((data) => (data ? data.id : null)),
  resource_type: $selectedType.map((data) => (data ? data.name : '')),
})

export const $typeErrorModule = createError()

export const $themeErrorModule = createError()

export const $descriptionErrorModule = createError()

export const $linkErrorModule = createError()

export const $fileErrorModule = createError()

const resetErrors = createEvent<void>()

forward({
  from: resetErrors,
  to: [
    $typeErrorModule.methods.resetError,
    $themeErrorModule.methods.resetError,
    $descriptionErrorModule.methods.resetError,
    resetLink,
    $fileErrorModule.methods.resetError,
  ],
})

forward({
  from: create,
  to: checkIfResourceCanBeSend,
})

sample({
  source: $formToSend,
  clock: checkIfResourceCanBeSend,
  fn: (obj) => {
    let errors = 0
    if (!obj.resource_type) {
      $typeErrorModule.methods.setError(true)
      errors += 1
    }
    if (obj.theme === DEFAULT_ID) {
      $themeErrorModule.methods.setError(true)
      errors += 1
    }
    if (obj.resource_type === 'text' && !obj.text.trim().length) {
      $descriptionErrorModule.methods.setError(true)
      errors += 1
    } else if (
      (obj.resource_type === 'link' || obj.resource_type === 'video') &&
      (!obj.link.trim().length || !isLinkValid(obj.link))
    ) {
      $linkErrorModule.methods.setError(true)
      errors += 1
    } else if (obj.resource_type === 'file' && !obj.media_id) {
      $fileErrorModule.methods.setError(true)
      errors += 1
    }
    if (errors === 0) {
      saveResource()
    } else if (errors > 0) errorToastEvent('Необходимо заполнить все обязательные поля')
  },
})

sample({
  clock: saveResource,
  source: $formToSend,
  target: createResource,
})

const $ifRedirect = sample({
  clock: createResource,
  source: $redirectAfterSave,
  fn: (isRedirect: boolean) => isRedirect,
})

sample({
  source: $ifRedirect,
  clock: createResourceFx.doneData.map((data) => data.body.id),
  fn: (ifRedirect: boolean, id: number) => {
    successToastEvent('Обучающий ресурс успешно создан!')
    if (ifRedirect) navigatePush({ name: 'resources-list' })
    else navigatePush({ name: 'resources-edit', params: { id: `${id}` } })
  },
})

forward({
  from: setSelectedType,
  to: resetErrors,
})
forward({
  from: typeDropdownModule.methods.itemChanged,
  to: $typeErrorModule.methods.resetError,
})

forward({
  from: themesDropdownModule.methods.itemChanged,
  to: $themeErrorModule.methods.resetError,
})

forward({
  from: resourceDescriptionChanged,
  to: $descriptionErrorModule.methods.resetError,
})

forward({
  from: linkChanged,
  to: $linkErrorModule.methods.resetError,
})

forward({
  from: playerLinkChanged,
  to: $linkErrorModule.methods.resetError,
})

forward({
  from: uploadFileFx,
  to: $fileErrorModule.methods.resetError,
})
