import { config } from '@/config'
import { createQueryString } from '@/lib/query'
import axios, { ResponseType, AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import { logout } from '@/features/session'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { setTokenForRequest } from '@/features/api/common/request'
import { isReadyToken } from '@/client'
import resetAccessAndRefreshTokens from '@/lib/request/utils/resetAccessAndRefreshTokens'

export type RequestParams = {
  url: string
  method:
    | 'get'
    | 'GET'
    | 'delete'
    | 'DELETE'
    | 'head'
    | 'HEAD'
    | 'options'
    | 'OPTIONS'
    | 'post'
    | 'POST'
    | 'put'
    | 'PUT'
    | 'patch'
    | 'PATCH'
    | 'purge'
    | 'PURGE'
    | 'link'
    | 'LINK'
    | 'unlink'
    | 'UNLINK'
    | undefined
  body?: BodyInit | any
  query?: Record<string, any>
  headers?: Record<string, string>
  responseType?: ResponseType
}

export type Response<T = any> = {
  body: T
  headers: Headers
  config: AxiosRequestConfig
  status: number
  ok: boolean
}

export const axiosClient = axios.create({
  ...(config.TOKEN_KEY
    ? { headers: { Authorization: `Bearer ${Cookies.get(config.TOKEN_KEY)}` } }
    : {}),
})

axiosClient.interceptors.request.use((request) => {
  if (config.TOKEN_KEY) {
    request.headers.Authorization = `Bearer ${Cookies.get(config.TOKEN_KEY)}`
  }
  return request
})

const refreshAuthLogic = async (failedRequest: any) => {
  const refreshToken = Cookies.get(config.REFRESH_TOKEN_KEY)
  const refreshTokenExp = Cookies.get(config.REFRESH_TOKEN_EXP)
  if (refreshToken && isReadyToken(refreshTokenExp)) {
    axios
      .post(`${config.BACKEND_URL}/api/user-app/token/refresh/`, {
        refresh: refreshToken,
      })
      .then((response) => {
        const { access } = response.data

        resetAccessAndRefreshTokens(access)
        setTokenForRequest(access)

        if (failedRequest) {
          failedRequest.response.config.headers.Authorization = `Bearer ${response.data.access}`
        }

        return Promise.resolve()
      })
      .catch(() => {
        logout()
      })
  } else {
    logout()
  }
}

createAuthRefreshInterceptor(axiosClient, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
})

export const request = async (params: RequestParams) => {
  const headers = new Headers(params.headers)
  if (!headers.has('content-type')) {
    headers['content-type'] = 'application/json; charset=utf-8'
  }
  if (params.body instanceof FormData && headers.has('content-type')) {
    delete headers['content-type']
  }

  const query = createQueryString(params.query)
  const body = headers.get('content-type')?.includes('application/json')
    ? JSON.stringify(params.body)
    : (params.body as BodyInit | undefined)
  const url = `${config.BACKEND_URL}${params.url}${query}`
  const response = await axiosClient(url, {
    ...(params.responseType && { responseType: params.responseType }),
    method: params.method,
    headers: {
      ...headers,
    },
    data: body,
    withCredentials: false,
    validateStatus(status) {
      return status < 500
    },
  })

  const answer = {
    body: response.data,
    status: response.status,
    ok:
      response.statusText === 'OK' ||
      response.statusText === 'Created' ||
      response.statusText === 'Accepted' ||
      response.status === 204,
    headers: response.headers,
    config: response.config,
  }

  if (answer.ok) return answer
  if (answer.status === 401) await refreshAuthLogic(null)
  throw answer
}
