

















import Vue from 'vue'
import TaskHeader from '@/pages/bank/common/parts/Header.vue'
import TaskContent from '@/pages/bank/test-tasks/create/parts/Content.vue'
import TaskFooter from '@/pages/bank/common/parts/Footer.vue'
import {
  save,
  $canSave,
  setSubject,
  setClass,
  setTheme,
  setRedirectAfterSave,
  clearFields,
  componentMounted,
  componentDestroyed,
} from '@/pages/bank/test-tasks/create/task-creation-page.model'
import {
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import {
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  setSelectedTheme,
  themesDropdownModule,
} from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import { getSubjectFx } from '@/features/api/subject/get-subject'
import { getUserStudyYearFx } from '@/features/api/study_year/get-user-study-year'
import { getThemeFx } from '@/features/api/subject/get-theme'
import { resetCounters } from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/moving-images-on-image-answer-form.model'
import { createTestAssignmentFx } from '@/features/api/assignment/test-assignment/create-test-assignment'

export default Vue.extend({
  name: 'TaskCreationPage',
  components: {
    TaskHeader,
    TaskContent,
    TaskFooter,
  },
  effector: {
    $canSave,
    isLoading: createTestAssignmentFx.pending,
  },
  methods: {
    save,
    saveTask(isRedirect: boolean) {
      if (isRedirect) setRedirectAfterSave(true)
      save()
    },
  },
  mounted() {
    componentMounted()
    if (this.$route.params) {
      const { subject, studyYear, theme } = this.$route.params
      subject &&
        getSubjectFx(+subject).then(({ body }) => {
          subjectsDropdownModel.methods.itemChanged({ name: `${body.id}`, title: body.name })
          setSelectedSubject({ name: `${body.id}`, title: body.name })
        })
      setSubject(+subject)
      !isNaN(+studyYear) &&
        getUserStudyYearFx(+studyYear).then(({ body }) => {
          classesDropdownModule.methods.itemChanged({ name: `${body.id}`, title: body.name })
          setSelectedClass({ name: `${body.id}`, title: body.name })
        })
      setClass(+studyYear)
      theme &&
        getThemeFx(+theme).then(({ body }) => {
          themesDropdownModule.methods.itemChanged({ name: `${body.id}`, title: body.name })
          setSelectedTheme({ name: `${body.id}`, title: body.name })
        })
      setTheme(+theme)
    }
  },
  beforeDestroy() {
    componentDestroyed()
    clearFields()
    resetCounters()
  },
})
