import { createApiEffect } from '@/features/api/common/create-api-effect'
import { SaveResultLessonParams } from '@/features/api/results/types'

export const saveResultLessonFx = createApiEffect<SaveResultLessonParams, void>({
  requestMapper: (params) => {
    const body = {
      ...(params?.commentary ? { commentary: params.commentary } : {}),
      ...(params?.status ? { status: params.status } : {}),
    }
    return {
      method: 'PATCH',
      url: `/api/results-app/results/courses/${params.course}/${params.lesson}/${params.student}/scores/`,
      body,
    }
  },
})
