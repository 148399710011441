
























































































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetableFieldCheckbox } from 'vuetable-2'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import ClassesDropdown from '@/pages/users/students/list/parts/classes-dropdown/ClassesDropdown.vue'
import GroupsDropdown from '@/pages/users/students/list/parts/groups-dropdown/GroupsDropdown.vue'
import CoursesDropdown from '@/pages/users/employes/edit/parts/courses-dropdown/CoursesDropdown.vue'
import SubjectsDropdown from '@/pages/users/employes/edit/model/dropdowns/SubjectsDropdown.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import FormInput from '@/ui/input/FormInput.vue'
import RolesDropdown from '@/pages/users/employes/list/parts/roles-dropdown/RolesDropdown.vue'
import CheckboxCell from '@/pages/users/employes/edit/parts/CheckboxCell.vue'
import { $editedStudentId } from '@/pages/users/students/edit/model/edit-student.model'
import { EditEmployeeFields } from '@/features/api/employee/types'
import { config } from '@/config'
import { RefsType } from '@/pages/common/types'
import { CreateEmployeeTableFields } from '@/pages/users/employes/edit/model/constants'
import {
  $email,
  $filteredTableData,
  $firstName,
  $internalComment,
  $isActiveUser,
  $lastName,
  $password,
  changeFieldValue,
  changeTableCanEditColumn,
  changeTableCanViewColumn,
  fetchTableData,
} from '@/pages/users/employes/edit/model/form-fields.model'
import { selectAllSubjects } from '@/pages/users/employes/edit/model/dropdowns/subjects-dropdown.model'
import { selectAllCourses } from '@/pages/users/students/list/parts/courses-dropdown/courses-dropdown.model'
import { selectAllClasses } from '@/pages/users/students/list/parts/classes-dropdown/classes-dropdown.model'
import { selectAllGroups } from '@/pages/users/students/list/parts/groups-dropdown/groups-dropdown.model'
import { $editedEmployeeId } from '@/pages/users/employes/edit/model/edit-employee.model'
import { rolesDropdownModel } from '@/pages/users/employes/list/parts/roles-dropdown/roles-dropdown.model'
import { axiosClient } from '@/lib/request'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'EditEmployesContent',
  components: {
    Vuetable,
    FormInput,
    CoursesDropdown,
    ClassesDropdown,
    GroupsDropdown,
    SubjectsDropdown,
    CheckboxCell,
    Wysiwyg,
    BaseSwitch,
    RolesDropdown,
  },
  effector: {
    $firstName,
    $lastName,
    $email,
    $editedStudentId,
    $internalComment,
    $isActiveUser,
    $password,
    $filteredTableData,
    $editedEmployeeId,
    $currentSelectedRole: rolesDropdownModel.store.$item,
  },
  data: () => ({
    fieldType: EditEmployeeFields,
    fields: CreateEmployeeTableFields,
    password: '',
  }),
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/user-app/employee/filter/permissions/`
    },
  },
  methods: {
    changeField(type: EditEmployeeFields, value: string) {
      changeFieldValue({ type, value })
      if (type === EditEmployeeFields.password) {
        this.password = value
      }
    },
    passwordFocus() {
      this.password = ''
    },
    async myFetch(apiUrl: string) {
      if (this.$editedEmployeeId.length === 0) {
        const request = await axiosClient.get(apiUrl, {
          params: {},
        })
        const obj = request.data.permissions
        fetchTableData(obj)
      }
    },
    handleCellClick(payload: any) {
      payload.type === 'can_view'
        ? changeTableCanViewColumn(payload)
        : changeTableCanEditColumn(payload)
    },
  },
  mounted() {
    this.myFetch(this.apiUrl)
    if (this.$editedEmployeeId.length === 0) {
      selectAllSubjects()
      selectAllCourses()
      selectAllClasses()
      selectAllGroups()
    } else {
      this.password = '12345678'
    }
  },
})
