import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams } from '@/features/api/types'
import { FilterName } from '@/features/api/results/types'

export const lessonsNameListFx = createApiEffect<GetListQueryParams, FilterName[]>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/lesson-app/lessons/filter/names/',
    query,
  }),
})
