import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ProctorCreateFxParams, ProctorCreateType } from '@/features/api/proctors/types'

export const createProctorsFx = createApiEffect<ProctorCreateType, ProctorCreateFxParams>({
  requestMapper: (body) => ({
    method: 'POST',
    url: `/api/exams-app/proctors/`,
    body,
  }),
})
