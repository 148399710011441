import { TableField } from '@/pages/dictionary/themes/list/types'

export const VideoTableFields: TableField[] = [
  {
    name: 'filename',
    title: 'Название',
    titleClass: 'center aligned',
    dataClass: 'left aligned',
  },
  {
    name: 'link',
    title: 'Ссылка',
    titleClass: 'center aligned',
    dataClass: 'left aligned',
  },
]
