

























import Vue, { PropType } from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'

export default Vue.extend({
  name: 'DateInput',
  components: {
    DatePicker,
  },
  props: {
    value: { type: [String, Date] as PropType<string | Date>, default: '' },
    label: { type: String as PropType<string>, default: '' },
    labelVisible: { type: Boolean as PropType<boolean>, default: true },
    placeholder: { type: String as PropType<string>, default: '' },
    type: { type: String as PropType<string>, default: 'date' },
    disabled: { type: Boolean as PropType<boolean> },
    errorMessage: { type: String as PropType<string>, default: '' },
  },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    }
  },
  computed: {
    hasError() {
      return this.$slots.error || this.errorMessage
    },
  },
  methods: {
    onChange(val: any) {
      this.$emit('input', val)
    },
  },
})
