















import Vue, { PropType } from 'vue'

export type Corner = 'center' | 'start' | 'end'
export type Position = 'top' | 'bottom' | 'left' | 'right'

type ArrowConfig =
  | false
  | {
      position: Position
      corner?: Corner
    }
  | {
      position: Position
      ref: HTMLElement
    }

export default Vue.extend({
  name: 'Tooltip',
  props: {
    arrow: { type: Object as PropType<ArrowConfig> },
  },
  data: () => ({
    refStyles: {},
  }),

  computed: {
    getArrowCornerPosition(): { [key in Position]?: string } {
      if (!this.$props.arrow || this.$props.arrow.ref) return {}
      const { position, corner = 'center' } = this.$props.arrow
      let cssProperty
      if (position === 'top' || position === 'bottom') {
        cssProperty = corner === 'end' ? 'right' : 'left'
      } else {
        cssProperty = corner === 'end' ? 'bottom' : 'top'
      }

      return { [cssProperty]: corner === 'center' ? '50%' : '0' }
    },
    getArrowYTranslationValue(): string {
      if (!this.arrow) return '0'
      const { position, corner } = this.$props.arrow

      switch (position) {
        case 'left':
        case 'right':
          return corner === 'center' ? '-50%' : '0'
        case 'bottom':
          return '100%'
        case 'top':
        default:
          return '-100%'
      }
    },
    getArrowXTranslationValue(): string {
      if (!this.arrow) return '0'
      // @ts-ignore
      const { position, corner } = this.arrow

      switch (position) {
        case 'right':
          return '100%'
        case 'left':
          return '-100%'
        case 'top':
        case 'bottom':
        default:
          return corner === 'center' ? '-50%' : '-10%'
      }
    },
    getArrowStyles(): any {
      if (!this.arrow) return {}

      const { position = 'top' } = this.arrow
      const mainOffset = '1px'

      let styles: any = {
        [position]: mainOffset,
        transform: `translate3d(${this.getArrowXTranslationValue}, ${this.getArrowYTranslationValue}, 0)`,
      }

      // @ts-ignore
      if (!this.arrow.ref) {
        styles = {
          ...styles,
          ...this.getArrowCornerPosition,
        }
      }

      return styles
    },
  },

  methods: {
    calculateRefStyles() {
      const { position, ref } = this.$props.arrow
      if (!this.arrow || !ref) return

      // @ts-ignore
      const refEl = ref instanceof Vue ? ref.$el : ref

      const { x: elX, y: elY } = this.$el.getBoundingClientRect()
      const { x: refX, y: refY, width: refWidth, height: refHeight } = refEl.getBoundingClientRect()

      // @ts-ignore
      const { width: arrowWidth } = this.$refs.arrow.getBoundingClientRect()
      if (position === 'top' || position === 'bottom') {
        const diffX = (refWidth - arrowWidth) / 2
        this.refStyles = {
          left: `${refX - elX + diffX}px`,
        }
      } else {
        const diffY = (refHeight - arrowWidth) / 2
        this.refStyles = {
          top: `${refY - elY + diffY}px`,
        }
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.calculateRefStyles()
    })
  },
})
