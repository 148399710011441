























































import Vue, { VueConstructor } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  uploadFile,
  $fileData,
  deleteMedia,
} from '@/pages/dictionary/resources/create/parts/file-upload/file-upload.model'
import { deleteMediaFx } from '@/features/api/media/delete-media'
import { RefsType } from '@/pages/common/types'

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    BaseButton,
  },
  effector: {
    $fileData,
  },
  computed: {
    formatName() {
      return this.$fileData && this.$fileData.name.slice(this.$fileData.name.lastIndexOf('/') + 1)
    },
    fileExtenstion() {
      return this.$fileData && this.$fileData.name.slice(this.$fileData.name.lastIndexOf('.') + 1)
    },
  },
  methods: {
    uploadFile,
    deleteMedia,
    imitInputClick() {
      document.getElementById('fileInput')!.click()
    },
    handleFileUpload() {
      const { fileInput } = this.$refs
      uploadFile(fileInput!.files)
    },
    replaceFile(id: number) {
      deleteMediaFx(id)
      this.imitInputClick()
    },
  },
})
