import { $isAuthed } from '@/features/session/index'
import { createEffect, createEvent } from 'effector-root'
import { condition } from 'patronum'
import { NavigationGuard } from 'vue-router/types/router'
import { navigateReplace } from '@/features/navigation/navigationMethods'

type NextFn = () => void

const checkAuth = createEvent<NextFn>()
const checkGuest = createEvent<NextFn>()

const resolveNavigationFx = createEffect({
  handler: (next: NextFn) => next(),
})

condition({
  source: checkAuth,
  if: $isAuthed,
  then: resolveNavigationFx,
  else: navigateReplace.prepend(() => ({ name: 'auth.login' })),
})

condition({
  source: checkGuest,
  if: $isAuthed,
  then: navigateReplace.prepend(() => ({ name: 'admin' })),
  else: resolveNavigationFx,
})

export const checkUserAuthedMiddleware: NavigationGuard = (to, from, next) => checkAuth(next)

export const checkUserGuestMiddleware: NavigationGuard = (to, from, next) => checkGuest(next)
