import {
  attach,
  createEffect,
  createEvent,
  forward,
  restore,
  sample,
  combine,
  createStore,
  guard,
} from 'effector-root'
import { gethThemesTreeLightFx, getThemesTreeFx } from '@/features/api/subject/get-themes-tree'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { TreeData } from '@/features/api/types'
import { GetThemesTreeQueryParams, RelationsType } from '@/features/api/subject/types'
import { deleteThemesFx, requestDeleteThemesFx } from '@/features/api/subject/delete-themes'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { condition } from 'patronum'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { isObjectEmpty, mergeTreeData, sortTreeLeaves } from '@/features/lib'
import { getThemesInfoFx } from '@/features/api/subject/get-themes-tree-info'
import { FiltersParams } from '@/pages/common/types'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { loadTreeLight as loadTasksTreeLight } from '@/pages/bank/test-tasks/list/tasks-page.model'
import { getThemesListFx } from '@/features/api/subject/get-themes-list'
import { RequestDeleteThemesParams } from '@/features/api/assignment/types/types'
import { themesFilters } from '@/pages/dictionary/themes/list/parts/themes-filter/themes-filter.model'
import { checkThemeRelationsFx } from '@/features/api/subject/check-theme-relations'
import { exportThemesListFx } from '@/features/api/subject/export-themes-list'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'

const getThemesList = attach({
  effect: getThemesListFx,
})

const getThemesTree = attach({
  effect: getThemesTreeFx,
})

const getThemesLightTree = attach({
  effect: gethThemesTreeLightFx,
})

export const getFilteredTree = attach({
  effect: getThemesTreeFx,
})

const getThemesTreeInfo = attach({
  effect: getThemesInfoFx,
})

export const deleteThemes = createEffect({
  handler: (ids: number[]): Promise<number[]> => {
    return new Promise((resolve) => {
      deleteThemesFx(ids).then(() => {
        resolve(ids)
      })
    })
  },
})

export const requestDeleteThemes = attach({
  effect: requestDeleteThemesFx,
  mapParams: (payload: RequestDeleteThemesParams): RequestDeleteThemesParams => {
    return {
      themes: payload.themes,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const exportThemesList = attach({
  effect: exportThemesListFx,
  source: [themesFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportThemesList.doneData.watch((res) => fileDownload(res.body, 'themesList.xlsx'))

export const resetCannotDeleteData = createEvent<void>()
export const setCannotDeleteData = createEvent<RelationsType>()
export const $cannotDeleteData = createStore<RelationsType[]>([])

export const resetDataToDelete = createEvent<void>()
const setDataToDelete = createEvent<number>()
export const $dataToDelete = createStore<number[]>([]).reset(resetDataToDelete)

const setCanManipulateDeleteData = createEvent<boolean>()
export const $canManipulateDeleteData = restore(setCanManipulateDeleteData, false)

export const checkBeforeDeletion = createEffect({
  handler: (data: { ids: number[]; rights: boolean }): Promise<any[]> =>
    Promise.all(
      data.ids.map(async (id) => {
        await checkThemeRelationsFx(id).then(async (r) => {
          if (
            r.body.child_themes.length > 0 ||
            r.body.prerequisites.length > 0 ||
            r.body.test_assignments.length > 0
          ) {
            setCannotDeleteData(r.body)
          } else setDataToDelete(r.body.id)
        })
      })
    ),
})

forward({
  from: checkBeforeDeletion,
  to: setCanManipulateDeleteData.prepend(() => false),
})
forward({
  from: checkBeforeDeletion.doneData,
  to: setCanManipulateDeleteData.prepend(() => true),
})

sample({
  clock: resetDataToDelete,
  source: $dataToDelete,
  fn: (list) => {
    list = []
    return [...list]
  },
  target: $dataToDelete,
})
sample({
  clock: resetCannotDeleteData,
  source: $cannotDeleteData,
  fn: (list) => {
    list = []
    return [...list]
  },
  target: $cannotDeleteData,
})

sample({
  clock: setDataToDelete,
  source: $dataToDelete,
  fn: (oldData, newData) => {
    oldData.push(newData)
    return [...oldData]
  },
  target: $dataToDelete,
})

sample({
  clock: setCannotDeleteData,
  source: $cannotDeleteData,
  fn: (oldData, newData) => {
    oldData.push(newData)
    return [...oldData]
  },
  target: $cannotDeleteData,
})

export const themesPageParams = createPageParamsModel()

export const loadTreeLight = createEvent<void>()
export const loadTree = createEvent<GetThemesTreeQueryParams>()
export const loadFilteredTree = createEvent<FiltersParams>()
const rewriteThemesTree = createEvent<TreeData[] | null>()
export const setThemesTree = createEvent<TreeData[] | null>()
export const $themesTree = createStore<TreeData[] | null>(null)
  .on(setThemesTree, (state, data) => {
    if (state === null) return data
    return mergeTreeData(state, data!)
  })
  .on(rewriteThemesTree, (state, payload) => sortTreeLeaves(payload!))

export const setThemesTreeTotal = createEvent<number>()
export const $themesTreeTotal = restore<number>(setThemesTreeTotal, 0)

const showDeleteThemesToast = createEvent<number[]>()

export const $isLoading = combine(
  getFilteredTree.pending,
  gethThemesTreeLightFx.pending,
  getThemesList.pending,
  (tree, light, list) => tree || light || list
)

forward({
  from: loadTree,
  to: [getThemesTree, getThemesTreeInfo.prepend(() => ({}))],
})

forward({
  from: loadTreeLight,
  to: [getThemesLightTree, getThemesTreeInfo],
})

forward({
  from: loadFilteredTree,
  to: getFilteredTree,
})

forward({
  from: getThemesTreeInfo.doneData.map(({ body }) => body.total_amount),
  to: setThemesTreeTotal,
})

forward({
  from: getFilteredTree.doneData,
  to: [
    rewriteThemesTree.prepend(({ body }) => body.data),
    setThemesTreeTotal.prepend(({ body }) => body.total),
  ],
})

forward({
  from: getThemesTree.doneData,
  to: setThemesTree.prepend(({ body }) => body.data),
})

forward({
  from: getThemesLightTree.doneData,
  to: rewriteThemesTree.prepend(({ body }) => body.data),
})

forward({
  from: deleteThemes.doneData,
  to: [
    loadTreeLight.prepend(() => ({})),
    loadTasksTreeLight.prepend(() => ({})),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    showDeleteThemesToast,
  ],
})

condition({
  source: showDeleteThemesToast,
  if: (ids: number[]) => ids.length === 1,
  then: successToastEvent('Тема была успешно удалена!'),
  else: successToastEvent('Темы были успешно удалены!'),
})

forward({
  from: requestDeleteThemes.doneData,
  to: [
    successToastEvent('Отправлена заявка на удаление'),
    requestDeleteModalVisibilityChanged.prepend(() => false),
  ],
})

guard({
  clock: themesFilters.methods.resetFilters,
  filter: themesPageParams.store.treeView,
  target: loadTreeLight.prepend(() => ({})),
})

guard({
  clock: themesPageParams.store.treeView,
  source: themesFilters.store.$filterParams,
  filter: (filterParams, treeView) => treeView && !isObjectEmpty(filterParams),
  target: loadFilteredTree,
})

guard({
  clock: themesFilters.methods.applyFilters,
  filter: themesPageParams.store.treeView,
  source: themesFilters.store.$filterParams,
  target: loadFilteredTree,
})
