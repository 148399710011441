import { attach } from 'effector'
import { createEvent, forward, restore } from 'effector-root'
import { createCertificatesFx } from '@/features/api/results/certificates-generator/create-certificates'
import { addToast } from '@/features/toasts/toasts.model'
import fileDownload from 'js-file-download'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { createCertificatesPreviewFx } from '@/features/api/results/certificates-generator/create-certificates-preview'
import { DropdownItem } from '@/pages/common/types'
import handleCreateBlobFileErrors from '@/pages/results/certificates-generator/create-certificates/model/utils/handleCreateBlobFileErrors'
import IAddFileError from '@/pages/results/certificates-generator/create-certificates/model/interfaces/IAddFileError'

// FIELDS
export const resetFields = createEvent()

// FILES
export const setFiles = createEvent<File[] | null>()
export const $files = restore<File[] | null>(setFiles, null).reset(resetFields)

// TEMPLATE
export const setTemplate = createEvent<DropdownItem | null>()
export const $template = restore(setTemplate, null).reset(resetFields)

// ERRORS
export const setAddFileErrors = createEvent<IAddFileError[]>()
export const $addFileErrors = restore(setAddFileErrors, []).reset(resetFields)

export const $isAddErrors = $addFileErrors.map((data) => {
  return !!data.length
})

// CREATE
export const createCertificates = attach({
  effect: createCertificatesFx,
  source: [$files, $template],
  mapParams: (_, [files, template]) => {
    const formData = new FormData()

    if (files) {
      files.forEach((file) => {
        formData.append('file', file)
      })
    }

    if (template) {
      formData.append('sample_id', template.name)
    }

    return formData
  },
})

forward({
  from: createCertificates,
  to: [
    setAddFileErrors.prepend(() => []),
    addToast.prepend(() => ({ type: 'loading', message: 'Идет процесс создания сертификатов' })),
  ],
})

forward({
  from: createCertificates.doneData,
  to: [
    addToast.prepend(() => ({ type: 'success', message: 'Создание завершено!' })),
    resetFields,
    navigatePush.prepend(() => ({ name: 'certificates-generator-table' })),
  ],
})

forward({
  from: createCertificates.fail,
  to: [
    addToast.prepend(() => ({ type: 'error', message: 'При создании возникла ошибка' })),
    setAddFileErrors.prepend((res) => res.error.body.row_errors),
  ],
})

// CREATE PREVIEW
export const createCertificatesPreview = attach({
  effect: createCertificatesPreviewFx,
  source: [$files, $template],
  mapParams: (_, [files, template]) => {
    const formData = new FormData()

    if (files) {
      files.forEach((file) => {
        formData.append('file', file)
      })
    }

    if (template) {
      formData.append('sample_id', template.name)
    }

    return formData
  },
})

forward({
  from: createCertificatesPreview,
  to: [
    setAddFileErrors.prepend(() => []),
    addToast.prepend(() => ({ type: 'loading', message: 'Идет процесс создания превью' })),
  ],
})

forward({
  from: createCertificatesPreview.doneData,
  to: [addToast.prepend(() => ({ type: 'success', message: 'Создание завершено!' })), resetFields],
})

forward({
  from: createCertificatesPreview.fail,
  to: [
    addToast.prepend(() => ({ type: 'error', message: 'При создании возникла ошибка' })),
    setAddFileErrors.prepend((res) => handleCreateBlobFileErrors(res.error.body)),
  ],
})

createCertificatesPreview.doneData.watch((res) => {
  fileDownload(res.body, 'certificates.zip')
})
