













import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import { testsTextVcDropdownModel } from '@/pages/common/dropdowns/value-change-dropdowns/tests-text-vc/tests-text-vc-dropdown.model'

export default Vue.extend({
  name: 'TestTextVcDropdown',
  components: {
    FilterDropdown,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      searchString: '',
    }
  },
  effector: {
    ...testsTextVcDropdownModel.store,
  },
  methods: {
    ...testsTextVcDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.searchString = ''
      this.searchStringChanged('')
      this.$emit('onChange', item)
    },
    setSearchString(search: string | null) {
      this.searchString = search || ''
      this.searchStringChanged(search || '')
    },
  },
  mounted() {
    if (this.$items.length === 0) this.load()
  },
})
