




















import Vue, { PropType } from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { CheckBeforeDeletionResponseType } from '@/features/api/ticket/types'
import { MultipleCannotDeleteType } from '@/pages/applications/types'

export default Vue.extend({
  name: 'CannotDeleteModal',
  components: {
    Modal,
    BaseButton,
  },
  props: {
    showModal: { type: Boolean as PropType<boolean> },
    value: { type: Object as PropType<CheckBeforeDeletionResponseType> },
    multipleValue: {
      type: Object as PropType<MultipleCannotDeleteType>,
    },
    multiple: { type: Boolean, default: false },
  },
  computed: {
    correctTitle(): string {
      if (this.multiple) return this.correctTitleForMultiple
      if (!this.value) return ''
      if (this.value.object_type === 'subject') return this.correctSubjectTitle
      if (this.value.object_type === 'theme') return this.correctThemeTitle
      return ''
    },
    correctTitleForMultiple(): string {
      if (this.multipleValue && this.multipleValue.object_type === 'theme') {
        const isSignle = this.multipleValue.ids.length === 1
        return `${
          isSignle
            ? `Тема  ${this.multipleValue.ids} не может быть удалена`
            : `Темы  ${this.multipleValue.ids} не могут быть удалены`
        } пока к ${
          isSignle ? 'ней' : 'ним'
        } привязаны элементы. Пожалуйста, открепите их и повторите попытку`
      }
      return ''
    },
    correctSubjectTitle(): string {
      const themes = Array.from(this.value.subject.themes).splice(0, 3)
      return `Предмет не может быть удален, пока к нему привязаны темы ${themes}...`
    },
    correctThemeTitle(): string {
      const { study_resources, test_assignment, child_themes, prerequisites } = this.value.theme
      return `Тема ${this.value.theme.id} не может быть удалена, пока к ней привязаны ${
        child_themes ? `другие темы ${child_themes.slice(0, 3)}` : ''
      } ${study_resources.length > 0 ? `обучающие ресурсы ${study_resources.slice(0, 3)}` : ''} ${
        test_assignment ? `задания ${test_assignment.slice(0, 3)}` : ''
      } ${prerequisites ? `пререквизиты ${prerequisites.slice(0, 3)}` : ''}`
    },
  },
})
