import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { StudyYear } from '@/features/api/subject/types'
import { Test } from '@/features/api/test/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const appointmentsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '85px',
  },
  {
    name: 'attempts_count',
    title: 'Количество назначений',
    width: '250px',
    titleClass: 'wrap',
  },
  {
    name: 'trials',
    title: 'Кол-во попыток для прохождения ',
    width: '250px',
    titleClass: 'wrap',
    formatter: (trials: string | number) => {
      const strTrials = String(trials)
      return strTrials === '0' ? 'Неограниченно' : '1'
    },
  },
  {
    name: 'cms_name',
    sortField: 'cms_name',
    title: 'Название CMS',
    width: '160px',
  },
  {
    name: 'cms_commentary',
    sortField: 'cms_commentary',
    title: 'Заметка CMS',
    width: '170px',
  },
  {
    name: 'subject',
    sortField: 'subject',
    title: 'Предмет',
    width: '130px',
    formatter: (subject) => {
      return subject || '–'
    },
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    title: 'Класс',
    width: '130px',
    formatter: (study_year: StudyYear) => {
      return study_year || '–'
    },
  },
  {
    name: 'groups',
    sortField: 'groups',
    title: 'Группа',
    width: '130px',
    formatter: (groups: { id: string; name: string }[] | string) => {
      if (Array.isArray(groups)) {
        return groups?.map((group) => group.name).join(', ') || '–'
      }
      return groups
    },
  },
  {
    name: 'test',
    sortField: 'test',
    title: 'Экзаменационный тест',
    width: '170px',
    titleClass: 'wrap',
    formatter: (test: Test) => {
      return test?.internal_name || '-'
    },
  },
  {
    name: 'available_from',
    sortField: 'available_from',
    title: 'Дата начала доступности',
    width: '160px',
    titleClass: 'wrap',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'available_to',
    sortField: 'available_to',
    title: 'Дата окончания доступности',
    width: '160px',
    titleClass: 'wrap',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm:ss') : '–'
    },
  },
  {
    name: 'is_proctoring_activated',
    sortField: 'is_proctoring_activated',
    title: 'Прокторинг',
    width: '130px',
    formatter(isProctoring: boolean) {
      return isProctoring ? 'Да' : 'Нет'
    },
  },
  {
    name: 'import_file',
    sortField: 'import_file',
    title: 'Импортированный файл с назначениями',
    titleClass: 'wrap',
    width: '240px',
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    width: '210px',
    formatter: (data) => {
      return data && data.first_name && data.last_name
        ? `${data.first_name} ${data.last_name}`
        : '–'
    },
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '120px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID' },
  { name: 'name', title: 'Название' },
  { name: 'class', title: 'Класс' },
  { name: 'subject', title: 'Предмет' },
  { name: 'file_name', title: 'Название файла' },
]

export const getExamAppointmentActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'toTry',
      title: 'Переход к попыткам',
      action: 'onToTry',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.exams_appointment_appointments.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
