










































import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import Icon from '@/ui/icon/Icon.vue'
import PrerequisiteMenu from '@/pages/dictionary/themes/edit/parts/prerequisites/PrerequisiteMenu.vue'
import {
  $selectedPrerequisites,
  setSelectedPrerequisites,
  prerequisitesDropdownModel,
  deletePrerequisite,
  componentMounted,
  componentDestroyed,
} from '@/pages/dictionary/themes/edit/parts/prerequisites/prerequisites.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
    Icon,
    PrerequisiteMenu,
  },
  effector: {
    $selectedPrerequisites,
    ...prerequisitesDropdownModel.store,
  },
  data() {
    return {
      prerequisiteModuleMethods: prerequisitesDropdownModel.methods,
    }
  },
  methods: {
    deletePrerequisite,
    setSelectedPrerequisites,
    ...prerequisitesDropdownModel.methods,
    onItemChanged(item: DropdownItem) {
      if (!item) return
      const existedItem = this.$selectedPrerequisites.find(
        (prerequisite: DropdownItem) => prerequisite.name === item.name
      )
      if (existedItem) {
        const prerequisites = this.$selectedPrerequisites.filter(
          (prerequisite: DropdownItem) => prerequisite.name !== item.name
        )
        setSelectedPrerequisites(prerequisites)
      } else {
        setSelectedPrerequisites([item, ...this.$selectedPrerequisites])
      }
    },
    showPrerequisiteMenu(itemId: number) {
      let currentComponent = ''
      Object.keys(this.$refs).forEach((el) => {
        if (!el.includes(`menu-${itemId}`) && (this.$refs[el] as any)[0].opened)
          (this.$refs[el] as any)[0].$refs.tooltip.hideTooltip()
        if (el.includes(`menu-${itemId}`)) currentComponent = el
      })
      ;(this.$refs[currentComponent] as any)[0].$refs.tooltip.toggleTooltip()
    },
  },
  mounted() {
    componentMounted()
  },
  beforeDestroy() {
    componentDestroyed()
    this.dropdownDestroy()
  },
})
