import { createEvent, forward, restore, sample } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { formatSelectedItems } from '@/pages/common/dropdowns/multiselectDropdown/types'

export const createFiltersModel = () => {
  const baseModel = createFilter()

  const resetSelectedItems = createEvent<void>()
  const setSelectedItems = createEvent<DropdownItem[]>()
  const $selectedItems = restore<DropdownItem[]>(setSelectedItems, []).reset(resetSelectedItems)
  const deleteSelectedItem = createEvent<string>()

  forward({
    from: baseModel.methods.resetDropdown,
    to: resetSelectedItems,
  })

  sample({
    source: $selectedItems,
    clock: deleteSelectedItem,
    fn: (list, id: string) => list.filter((el: any) => el.name !== id),
    target: $selectedItems,
  })

  sample({
    source: {
      selected: $selectedItems,
      all: baseModel.store.$itemsDropdown,
      searchString: baseModel.store.$searchString,
    },
    clock: baseModel.methods.itemChanged,
    fn: (list, element) => {
      return formatSelectedItems(list, element)
    },
    target: $selectedItems,
  })
  return {
    store: {
      ...baseModel.store,
      $selectedItems,
    },
    methods: {
      ...baseModel.methods,
      setSelectedItems,
      resetSelectedItems,
      deleteSelectedItem,
    },
  }
}
