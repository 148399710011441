













































































































import Vue, { VueConstructor } from 'vue'
import { config } from '@/config'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { getTagsActions, searchFieldsData, tagsDataFields } from '@/pages/tags/constants'
import {
  toggleVisibility,
  $visibility,
  tagsFilters,
} from '@/pages/tags/parts/tags-filter/tags-filter.model'
import {
  loadTreeLight,
  loadTree,
  $tagsTreeTotal,
  deleteTags,
  tagsPageParams,
  $isLoading,
  collectTaskData,
  exportTagsList,
  $canRefreshTableAfterDeletion,
} from '@/pages/tags/tags-page.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import PageHeader from '@/pages/tags/parts/header/PageHeader.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import TagsFilter from '@/pages/tags/parts/tags-filter/TagsFilter.vue'
import TooltipCell from '@/pages/tags/parts/table/TooltipCell.vue'
import TagsTree from '@/pages/tags/parts/tree/TagsTree.vue'
import TagCreationModal from '@/pages/tags/parts/modals/tag-creation/TagCreationModal.vue'
import TagEditionModal from '@/pages/tags/parts/modals/tag-edition/TagEditionModal.vue'
import TasksModal from '@/pages/tags/parts/modals/tasks/TasksModal.vue'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import { loadModal } from '@/pages/tags/parts/modals/tasks/tasks.model'
import {
  loadModalToEdit,
  $canRefreshTable,
} from '@/pages/tags/parts/modals/tag-edition/tag-edition.modal'
import {
  $canRefreshTableAfterCreation,
  createTagFromTree,
} from '@/pages/tags/parts/modals/tag-creation/tag-creation.modal'
import {
  RefsType,
  HttpOptionsType,
  DisplayContextMenuPayload,
  ActionsItem,
} from '@/pages/common/types'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { changeTasks } from '@/pages/preview-tasks/parts/tasks-dropdown/tasks-dropdown.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import { TagsTasksInteractedItemData, TagsTasksInteractedItemParams } from '@/pages/tags/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { clearTreeStates } from '@/pages/common/parts/tree/data-to-update-tree/data-to-update-tree.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { getActionsDisplayConditions } from '@/pages/common'
import { $permissions } from '@/features/session'
import { axiosClient } from '@/lib/request'
import Cookies from 'js-cookie'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    PageHeader,
    GeneralFilter,
    TagsFilter,
    TableHeader,
    TooltipCell,
    ActionsButton,
    TagsTree,
    ContextMenu,
    TagCreationModal,
    TagEditionModal,
    TasksModal,
    Vuetable,
    VuetablePagination,
    ConfirmDeleteModal,
    NoDataContent,
    LoaderBig,
  },
  effector: {
    $isLoading,
    $visibility,
    $tagsTreeTotal,
    $canRefreshTable,
    $canRefreshTableAfterCreation,
    $canRefreshTableAfterDeletion,
    $filterParams: tagsFilters.store.$filterParams,
    $pageParams: tagsPageParams.store.$pageParams,
    $treeView: tagsPageParams.store.treeView,
    $currentPage: tagsPageParams.store.currentPage,
    $permissions,
  },
  data() {
    return {
      searchFields: searchFieldsData,
      total: 0,
      fields: tagsDataFields,
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'items' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      selectedRows: [] as number[],
      subject_id: DEFAULT_ID as number,
      class_id: DEFAULT_ID as number,
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/assignment-app/olympiad-tags/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length && !this.$treeView) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getTagsActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $canRefreshTable: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
      },
    },
    $canRefreshTableAfterCreation: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
      },
    },
    $canRefreshTableAfterDeletion: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
    $treeView: {
      handler(newVal) {
        if (newVal) this.removeSelection()
      },
    },
  },
  methods: {
    changeFilter: tagsFilters.methods.changeFilter,
    resetFilters: tagsFilters.methods.resetFilters,
    applyFilters: tagsFilters.methods.applyFilters,
    changePage: tagsPageParams.methods.changePage,
    queryToParams: tagsPageParams.methods.queryToParams,
    loadTree,
    toggleVisibility,
    reset,
    loadModalToEdit,
    createTagFromTree,
    exportTagsList,
    showTasks() {
      loadModal(this.selectedIds[0])
    },
    editTag() {
      loadModalToEdit(this.selectedIds[0])
    },
    onShowTasks() {
      createTagFromTree({
        subject_id: this.subject_id,
        class_id: this.class_id,
      })
    },
    onCreateTag() {
      createTagFromTree({
        class_id: this.class_id,
        subject_id: this.subject_id,
      })
    },
    myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      return axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      reset() // search string and field
      this.resetFilters()
      clearTreeStates()
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (!res.response) noInternetToastEvent()
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id, subject_id: DEFAULT_ID, class_id: DEFAULT_ID },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: TagsTasksInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type = 'item' }: TagsTasksInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: TagsTasksInteractedItemData) {
      this.subject_id = data.subject_id
      this.class_id = data.class_id
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length,
        this.$treeView
      )
      this.contextMenuItems = getTagsActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    onRemoveTags() {
      loadConfirmDeleteModal(this.selectedIds)
    },
    async removeSelectedTags(ids: number[]) {
      await deleteTags(ids)
      await Vue.nextTick(() => this.$refs.vuetable.reload())
      this.removeSelection()
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    showPreview(ids: number[]) {
      this.transferSelectedApps(ids)
      navigatePush({
        name: 'preview-task',
        query: {
          questions: ids.join(','),
          taskType: 'olympiad-assignment',
          token: Cookies.get(config.TOKEN_KEY) || '',
          fromPage: 'tags',
        },
      })
    },
    transferSelectedApps(ids: number[]) {
      collectTaskData(ids).then((value) => changeTasks(value))
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!/create/g.test(from.path) && !/edit/g.test(from.path) && !/preview/g.test(from.path)) {
      clearTreeStates()
    }
    next()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  mounted() {
    loadTreeLight()
  },
  destroyed() {
    this.resetFilters()
  },
})
