import { StudyYear } from '@/features/api/subject/types'
import { DropdownItem } from '@/pages/common/types'
import { TableField } from '@/pages/dictionary/themes/list/types'
import dayjs from 'dayjs'
import { mapTaskStatus } from '@/pages/common/parts/status-controller/constants'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const olympiadTasksDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID задания',
    width: '130px',
  },
  {
    name: 'subject',
    sortField: 'subject__name',
    title: 'Предмет',
    width: '140px',
    formatter: (data) => {
      return (data && data.name) || '–'
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус',
    width: '140px',
    formatter(status: any) {
      return mapTaskStatus[status] || '-'
    },
  },
  {
    name: 'study_year',
    sortField: 'study_year__number',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Класс',
    width: '100px',
    formatter(study_year: StudyYear) {
      return (study_year && study_year.number) || '-'
    },
  },
  {
    name: 'wording',
    sortField: 'wording',
    title: 'Форм. задания',
    width: '180px',
  },
  {
    name: 'type',
    sortField: 'type',
    title: 'Тип задания',
    width: '140px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
  },
  {
    name: 'score',
    sortField: 'score',
    title: 'Баллы',
    width: '110px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(text: string) {
      return text || '-'
    },
  },
  {
    name: 'tags_string',
    sortField: 'tags_amount',
    title: 'Теги',
    width: '150px',
  },
  {
    name: 'interface_language',
    sortField: 'interface_language',
    title: 'Язык',
    width: '110px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(text: string) {
      return text || '-'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by__first_name',
    title: 'Создатель',
    width: '220px',
    formatter: (data) => {
      return data && data.first_name && data.last_name
        ? `${data.first_name} ${data.last_name}`
        : '–'
    },
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '200px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : '–'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Обновлено',
    width: '200px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : '–'
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by__first_name',
    title: 'Посл. изменения',
    width: '220px',
    formatter: (data) => {
      return data && data.first_name && data.last_name
        ? `${data.first_name} ${data.last_name}`
        : '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '120px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'id Задания' },
  { name: 'score', title: 'Баллы' },
  { name: 'created_by', title: 'Создатель' },
]

export const getOlympiadTasksActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'edit',
      title: 'Редактирование',
      headerTitle: 'Редактировать',
      action: 'onEdit',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.assignments_olympiadassignment_assignments.can_edit,
    },
    {
      name: 'delete',
      title: displayOptions.isTableItems ? 'Удалить выделенные задания' : 'Удалить задание',
      headerTitle: 'Удалить',
      action: 'onDelete',
      isDisplayed:
        displayOptions.isTableItem || displayOptions.isTableItems || displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
    {
      name: 'duplicate',
      title: 'Дублировать задание',
      action: 'onDuplicate',
      isDisplayed: displayOptions.isHeaderActions || displayOptions.isTableItem,
      hasPermissionToSee: permissions.assignments_olympiadassignment_assignments.can_edit,
    },
    {
      name: 'duplicateNTimes',
      title: 'Дублировать задание n раз',
      action: 'onDuplicateNTimes',
      isDisplayed: displayOptions.isHeaderActions || displayOptions.isTableItem,
      hasPermissionToSee: permissions.assignments_olympiadassignment_assignments.can_edit,
    },
    {
      name: 'preview',
      title: 'Предпросмотр',
      action: 'onPreview',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isTreeItem,
      hasPermissionToSee: permissions.assignments_olympiadassignment_assignments.can_view,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
