


































import Vue from 'vue'
import FormLabel from '@/ui/label/FormLabel.vue'
import {
  $droppableImages,
  replaceDroppableImage,
  removeDroppableImage,
} from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'
import AttachingImagesItem from './AttachingImagesItem.vue'

export default Vue.extend({
  name: `AreasForAttachingImages`,
  components: {
    FormLabel,
    AttachingImagesItem,
  },
  effector: {
    $droppableImages,
  },
  methods: {
    replaceDroppableImage,
    removeDroppableImage,
  },
})
