import { attach, createEvent, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { dropdownFullExamsAnswerModules } from '@/pages/exams/full-time-answers/list/model/dropdown-modules'
import { exportFullExamsAnswersListFx } from '@/features/api/full-attempts/export-full-time-answer'
import { createTaskModule } from '@/features/api/tasks/tasks-module'
import dayjs from 'dayjs'

export const examsAnswersFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownFullExamsAnswerModules
)

export const examsAnswersPageParams = createPageParamsModel()

export const resetExamsAnswersFilters = createEvent<void>()

export const setQuestionStartedAt = createEvent<string>()
export const $questionStartedAt = restore(setQuestionStartedAt, '').reset(reset)

export const setQuestionFinishedAt = createEvent<string>()
export const $questionFinishedAt = restore(setQuestionFinishedAt, '').reset(reset)

export const setShowCorrect = createEvent<boolean>()
export const $showCorrect = restore(setShowCorrect, false).reset(reset)

export const setShowNotCorrect = createEvent<boolean>()
export const $showNotCorrect = restore(setShowNotCorrect, false).reset(reset)

export const setShowYesAnswer = createEvent<boolean>()
export const $showYesAnswer = restore(setShowYesAnswer, false).reset(reset)

export const setShowNotAnswer = createEvent<boolean>()
export const $showNotAnswer = restore(setShowNotAnswer, false).reset(reset)

const exportExamsAnswersList = attach({
  effect: exportFullExamsAnswersListFx,
  source: [examsAnswersFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    const formatedFilters = {}
    Object.keys(filters).forEach((key) => {
      if (key === 'period_from' || key === 'period_to') {
        if (filters[key]) {
          formatedFilters[key] = dayjs(filters[key]).toISOString()
        }
      } else {
        formatedFilters[key] = filters[key]
      }
    })
    return { ...formatedFilters, ...exportedColumns }
  },
})

export const taskLoader = createTaskModule(exportExamsAnswersList, 'examsAnswersList')
