import { DropdownItem } from '@/pages/common/types'
import { StudyYear } from '@/features/api/subject/types'

export const searchFieldsDataCreateGroup: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  // { name: 'name', title: 'Название' },
  // { name: 'subject', title: 'Предмет' },
  // { name: 'study_year', title: 'Класс' },
]

export type StudentData = {
  email: string
  fullname: string
  id: number
  study_year: StudyYear
}
