import { TableField } from '@/pages/dictionary/themes/list/types'
import dayjs from 'dayjs'
import { ActionsItem } from '@/pages/common/types'
import { $currentUser } from '@/features/session'

export const SubscriberTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'email',
    title: 'Почта',
    titleClass: 'center aligned',
    dataClass: 'left aligned',
  },
  {
    name: 'subscribed_at',
    title: 'Подписался в',
    titleClass: 'center aligned',
    dataClass: 'left aligned',
    formatter: (value: string) => dayjs(value).format('DD-MM-YYYY, HH:mm'),
  },
]

export const getSubscribersActions = (selectedRowsLength: number): ActionsItem[] => {
  const user = $currentUser.getState()
  const isDefaultShow = user?.role !== 'METHODIST'

  const actions = [
    {
      name: 'delete',
      title: 'Удалить',
      headerTitle: 'Удалить',
      action: 'onDelete',
      isDisplayed: selectedRowsLength === 1,
      hasPermissionToSee: isDefaultShow,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: selectedRowsLength > 0,
      hasPermissionToSee: isDefaultShow,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
