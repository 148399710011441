














































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ExamTestsFolders from '@/pages/common/dropdowns/exams/exams-tests-folders/ExamTestsFolders.vue'
import { examTestsFolderModal } from '@/pages/common/modals/tasks-bank/creating-folder/creating-folder-modal.model'

export default Vue.extend({
  name: 'CreatingExamTestFolderModal',
  components: {
    Modal,
    Icon,
    FormInput,
    BaseButton,
    ExamTestsFolders,
  },
  effector: {
    $modalVisibility: examTestsFolderModal.store.$modalVisibility,
    titleError: examTestsFolderModal.store.$titleErrorModule.store.$error,
    $folderTitle: examTestsFolderModal.store.$folderTitle,
  },
  methods: {
    ...examTestsFolderModal.methods,
  },
})
