<template>
  <div class="question-answers-form">
    <div class="left-border" />
    <BaseSwitch
      class="switch toggler"
      :checked="$marksEnabled"
      @change="toggleMarksEnabling"
    >
      <p>Указать количество баллов за каждый верный ответ</p>
    </BaseSwitch>
    <div
      v-for="(qa, questionId) in $questionsAnswers"
      :key="qa.id"
      class="question-answers"
    >
      <BaseTextarea
        :label="questionId === 0 ? 'Вопрос' : ''"
        :value="qa.question"
        clear-btn
        placeholder="Введите вопрос"
        class="textarea"
        @clear="() => handleQuestionChange({ id: qa.id, question: '' })"
        @input="(question) => handleQuestionChange({ id: qa.id, question })"
      />
      <div class="answers">
        <div
          v-for="(answer, answerId) in qa.answers"
          :key="answerId"
          class="answer-container"
        >
          <FormInput
            :label="questionId === 0 && answerId === 0 ? 'Ответ' : ''"
            :value="answer"
            placeholder="Введите ответ"
            class="answer-input"
            @input="(value) => handleAnswerChange({ questionId: qa.id, answerId, value })"
          />
          <div
            v-if="answerId === 0"
            :class="{ 'icon-btn': true, 'first-icon': questionId === 0 && answerId === 0 }"
            @click="addAnswer({ questionId: qa.id })"
          >
            <Icon
              class="icon-plus"
              type="plus"
              size="16"
            />
          </div>
          <FormInput
            v-if="$marksEnabled && !answerId"
            :label="!questionId ? 'Баллы' : ''"
            type="number"
            :value="qa.score"
            placeholder="Баллов"
            class="answer-score"
            @input="(score) => handleMarkChange({ questionId: qa.id, score })"
          />
          <div
            v-if="qa.answers.length > 1 || $questionsAnswers.length > 1"
            :class="{ transparent: true, 'icon-btn': true, 'first-icon': questionId === 0 && answerId === 0 }"
            @click="removeAnswerOrQuestion({ questionId: qa.id, id: answerId, removeQuestion: qa.answers.length === 1 })"
          >
            <Icon
              class="icon-close"
              type="close"
              size="8"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="add-question">
      <BaseButton @click="addQuestion">Добавить вопрос</BaseButton>
    </div>
  </div>
</template>

<script>
/* eslint-disable prettier-vue/prettier */
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import {
  $marksEnabled,
  toggleMarksEnabling,
  $questionsAnswers,
  setQuestionsAnswers,
} from '@/pages/common/parts/tasks/multiple-short-closed-answer/multiple-short-closed-answer.model'
import { getRandomId } from '@/pages/common/parts/tasks/utils'

export default Vue.extend({
  name: 'CorrectAnswerForm',
  components: {
    Icon,
    FormInput,
    BaseSwitch,
    BaseButton,
    BaseTextarea,
  },
  effector: {
    $marksEnabled,
    $questionsAnswers,
  },
  methods: {
    toggleMarksEnabling,
    handleQuestionChange({ id, question }) {
      const questionsAnswers = this.$questionsAnswers.map((qa) =>
        qa.id === id ? { ...qa, question } : qa
      )
      setQuestionsAnswers(questionsAnswers)
    },
    handleAnswerChange({ questionId, answerId, value }) {
      const questionsAnswers = this.$questionsAnswers.map((qa) =>
        qa.id === questionId
          ? {
            ...qa,
            answers: qa.answers.map((ans, id) => {
              return id === answerId ? value : ans
            }),
          }
          : qa
      )
      setQuestionsAnswers(questionsAnswers)
    },
    handleMarkChange({ questionId, score }) {
      const questionsAnswers = this.$questionsAnswers.map((qa) =>
        qa.id === questionId ? { ...qa, score: +score } : qa
      )
      setQuestionsAnswers(questionsAnswers)
    },
    addAnswer({ questionId }) {
      const questionsAnswers = this.$questionsAnswers.map((qa) => {
        return qa.id === questionId
          ? {
            ...qa,
            answers: [...qa.answers, ''],
          }
          : qa
      })
      setQuestionsAnswers(questionsAnswers)
    },
    removeAnswerOrQuestion({ questionId, id, removeQuestion }) {
      let questionsAnswers = this.$questionsAnswers.map((qa) =>
        qa.id === questionId
          ? {
            ...qa,
            answers: qa.answers.filter((_, index) => index !== id),
          }
          : qa
      )
      if (removeQuestion) {
        questionsAnswers = this.$questionsAnswers.filter(qa => qa.id !== questionId)
      }
      setQuestionsAnswers(questionsAnswers)
    },
    addQuestion() {
      setQuestionsAnswers([
        ...this.$questionsAnswers,
        { id: getRandomId(), question: '', answers: [''], score: null }
      ])
    }
  },
})
</script>

<style scoped>
.question-answers-form {
  position: relative;
  display: flex;
  flex-direction: column;
}

.left-border {
  position: absolute;
  left: -30px;
  width: 4px;
  height: 100%;
  background-color: var(--c-yellow-1);
}

.question-answers {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--c-grey-11);
}
.answer-container {
  max-width: 425px;
}
.answer-container,
.question-answers {
  display: flex;

  & + .question-answers {
    margin-top: 10px;
  }
}

.answers {
  display: flex;
  flex-direction: column;
}

.toggler {
  margin-top: 0px;
  margin-bottom: 20px;
}

.textarea {
  flex-grow: 1;
}

.answer-score,
.icon-btn {
  max-width: 100px;
  margin-left: 10px;
}

::v-deep .inner-input {
  padding: 0 15px;
}

.answer-input {
  max-width: 150px;
  margin-left: 10px;
}

.icon-btn {
  margin-top: 5px;
  cursor: pointer;
  min-width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: var(--base-text-primary);
}

.first-icon {
  margin-top: 19px;
}

.transparent {
  background-color: transparent;
}

.icon-plus {
  fill: #fff;
}
.icon-close {
  fill: var(--c-grey-3);
}

.add-question {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
