import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetExportTestsForIdParams } from '@/features/api/results/types'

export const exportResultStudentsTestingsListFx = createApiEffect<GetExportTestsForIdParams, Blob>({
  requestMapper: (params) => ({
    method: 'GET',
    url: `/api/results-app/results/test/students/${params.student}/${params.test}/${params.sample}/export/`,
    responseType: 'blob',
  }),
})
