import { createApiEffect } from '@/features/api/common/create-api-effect'
import { Test, UpdateTestFxParams } from '@/features/api/test/types'

export const updateTestFx = createApiEffect<UpdateTestFxParams, Test>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: `/api/test-app/test/${body.id}/`,
    body,
  }),
})
