import { createEvent, forward, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { dropdownModules } from '@/pages/results/courses-check/parts/filter/parts/dropdown-modules'
import { coursesNameDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/courses-name-dropdown-filter/courses-filter-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { lessonsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/lessons-dropdown-filter/lessons-filter-dropdown.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'

export const resultCoursesCheckFilters = createFiltersModel({}, dropdownModules)

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

forward({
  from: reset,
  to: [
    coursesNameDropdownModel.methods.resetSelectedItems,
    groupsDropdownModel.methods.resetSelectedItems,
    lessonsDropdownModel.methods.resetSelectedItems,
  ],
})
