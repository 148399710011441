import { createEvent, restore } from 'effector'
import { TaskBlock } from '@/pages/common/parts/tests/types'
import { combine } from 'effector-root'

export const clearManualGenerationFields = createEvent<void>()

export const toggleTaskScores = createEvent<boolean>()
export const $taskScores = restore(toggleTaskScores, false).reset(clearManualGenerationFields)

export const setTasksBlock = createEvent<TaskBlock[]>()
export const $tasksBlock = restore(setTasksBlock, []).reset(clearManualGenerationFields)

export const setCurrentEditTasks = createEvent<TaskBlock | null>()
export const $currentEditTasks = restore(setCurrentEditTasks, null).reset(
  clearManualGenerationFields
)

export const $maxPoints = combine($taskScores, $tasksBlock, (taskScores, tasksBlock) => {
  // Если использовать баллы из заданий - перебираем все блоки, в них все задания и суммируем.
  // Иначе берем только баллы блоков
  if (taskScores) {
    return tasksBlock.reduce((sum, block) => {
      return (
        sum +
        block.tasks.reduce((sumTask, task) => {
          return sumTask + (task.score ? task.score : 0)
        }, 0)
      )
    }, 0)
  }
  return tasksBlock.reduce((sum, block) => {
    return sum + block.pointsForTask
  }, 0)
})
