import { DropdownItem } from '@/pages/common/types'

export const attemptsCountMapData: DropdownItem[] = [
  {
    name: '0',
    title: 'Не ограничено',
  },
  {
    name: '1',
    title: 'Одна',
  },
]
