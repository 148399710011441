import { attach, createEvent, forward, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'
import { getProctorsFx } from '@/features/api/proctors/get-proctors'
import { GetProctorsType } from '@/features/api/proctors/types'

const getProctors = attach({
  effect: getProctorsFx,
  mapParams: (params: GetListQueryParams) => params,
})

export const proctorsDropdownModel = createDropdownMultiselectModel<GetProctorsType>(getProctors)

export const loadProctors = createEvent<void>()

sample({
  clock: loadProctors,
  source: { $nextPage: proctorsDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getProctors,
})

forward({
  from: proctorsDropdownModel.methods.canLoadNextPage,
  to: loadProctors,
})

sample({
  clock: getProctors.doneData,
  source: { items: proctorsDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newTags = res.body.data.map((field) => ({
      name: `${field?.id || '0'}`,
      title: field.login,
    }))
    return [...items, ...newTags]
  },
  target: proctorsDropdownModel.store.$items,
})
