import { createEvent, sample, restore } from 'effector-root'
import { createAchievementFx } from '@/features/api/settings/achievements/create-achievement'

export const reset = createEvent<void>()

export const saveAchievement = createEvent<void>()

export const changeAchievementText = createEvent<string>()

export const $achievementText = restore(changeAchievementText, '').reset(reset)

sample({
  source: { text: $achievementText },
  clock: saveAchievement,
  target: createAchievementFx,
})
