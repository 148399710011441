import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=6104209a&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.vue?vue&type=style&index=0&id=6104209a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6104209a",
  null
  
)

export default component.exports