import dayjs from 'dayjs'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'

export const EmployersTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '70px',
  },
  {
    name: 'full_name',
    sortField: 'full_name',
    title: 'Пользователь',
    width: '170px',
  },
  {
    name: 'email',
    sortField: 'email',
    title: 'Логин(email)',
    width: '170px',
  },
  {
    name: 'role',
    sortField: 'role',
    title: 'Роль',
    width: '170px',
    formatter: (data) => {
      switch (data) {
        case 'ADMIN':
          return 'администратор'
        case 'METHODIST':
          return 'методист'
        case 'SUPER_ADMIN':
          return 'Супер администратор'
        default:
          return '-'
      }
    },
  },
  {
    name: 'is_active',
    sortField: 'is_active',
    title: 'Активный пользователь',
    width: '220px',
    formatter: (data) => {
      return data ? 'Да' : 'Нет'
    },
  },
  {
    name: 'date_joined',
    sortField: 'date_joined',
    title: 'Создано',
    width: '200px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'internal_cms_comment',
    sortField: 'internal_cms_comment',
    title: 'Комментарий',
    width: '180px',
    formatter: (data) => {
      return data || '–'
    },
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID' },
  { name: 'full_name', title: 'Пользователь' },
  { name: 'email', title: 'Логин(email)' },
]
