<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="lessons-filter"
  >
    <div class="row">
      <CoursesDropdown class="half-third" @setItem="(val) => setItem({ courses: val })" />
      <LessonsDropdown class="half-third" @setItem="(val) => setItem({ lessons: val })" />
      <UsersDropdown class="half-third" @setItem="(val) => setItem({ created_by: val })" />
    </div>
    <div class="row">
      <ShownDropdown class="half-second" @setItem="(val) => setItem({ is_shown: val })" />
      <div class="labels-input-relative-align">
        <div class="buttons">
          <div class="btn">
            <BaseButton small @click="applyFilters"> Применить </BaseButton>
          </div>
          <div class="btn">
            <BaseButton
              class="borderless"
              small
              border-without-bg
              @click="resetFilters"
            >
              Сбросить фильтры
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <Icon
      type="close"
      class="close-icon"
      size="10"
      @click="toggleVisibility(false)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import { toggleVisibility } from '@/pages/callback/comments-on-lessons/model/tooltip-filter.model'
import CoursesDropdown from '@/pages/common/dropdowns/courses/courses-name-dropdown/CoursesDropdown.vue'
import LessonsDropdown from '@/pages/common/dropdowns/lessons/LessonDropdown.vue'
import UsersDropdown from '@/pages/common/dropdowns/users/users-dropdown/UsersDropdown.vue'
import ShownDropdown from '@/pages/callback/comments-on-lessons/parts/dropdowns/shown-dropdown/ShownDropdown.vue'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ToolTipFilter',
  components: {
    ShownDropdown,
    Icon,
    BaseButton,
    CoursesDropdown,
    LessonsDropdown,
    UsersDropdown,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  methods: {
    toggleVisibility,
    closeFilter(event) {
      if (!event.target.closest('#filter-icon')) {
        toggleVisibility(false)
      }
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
    },
  },
})
</script>

<style scoped>
.lessons-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.half-second {
  width: 100%;
  margin-right: 30px;
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}

.labels-input-relative-align {
  width: 100%;
  display: flex;
  align-self: flex-end;
  height: 46px;

  .buttons {
    display: flex;
    align-items: center;
    margin-left: auto;

    .btn:first-child {
      margin-right: 38px;
    }
  }
}

.btn {
  width: max-content;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}
</style>
