































import Vue from 'vue'
import Card from '@/ui/card/Card.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $isModatory,
  toggleIsMondatory,
} from '@/pages/dictionary/subjects/common/create-edit.model'

export default Vue.extend({
  components: {
    Card,
    BaseSwitch,
    BaseButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  effector: { $isModatory },
  methods: { toggleIsMondatory },
})
