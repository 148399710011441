














































import Vue, { PropType } from 'vue'
import Card from '@/ui/card/Card.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Icon from '@/ui/icon/Icon.vue'

export default Vue.extend({
  components: {
    Card,
    BaseSwitch,
    BaseButton,
    Icon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    title: { type: String as PropType<string> },
    text: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
})
