import { UserRelated } from '@/features/api/user/types'
import { SubjectRelated } from '@/features/api/subject/types'
import { UploadMediaResponse } from '@/features/api/media/types'
import { AssignmentType, DifficultyType } from '@/features/api/assignment/types/types'
import { DropdownItem } from '@/pages/common/types'

export enum FieldNames {
  lessonName = 'lessonName',
  lessonNumber = 'lessonNumber',
  subject = 'subject',
  theme = 'theme',
  course = 'course',
  instruction = 'instruction',
  lessonResult = 'lessonResult',
  notShowAnswers = 'notShowAnswers',
  lessonIsDuration = 'lessonIsDuration',
  lessonDuration = 'lessonDuration',
}

// eslint-disable-next-line no-shadow
export enum ElementTypeNames {
  file = 'file',
  assignment = 'assignment',
  text = 'text',
  link = 'link',
  player_link = 'player_link',
  answer_text_field = 'answer_text_field',
  answer_file_field = 'answer_file_field',
}

export enum TaskTypeNames {
  test = 'test',
  olympiad = 'olympiad',
  lesson = 'lesson',
  exam = 'exam',
}

export enum TaskLabelNames {
  test = 'Тестовое задание',
  olympiad = 'Олимпиадное задание',
  lesson = 'Задание для урока',
}

export type TaskDataType = {
  wording: string
  difficulty: DifficultyType | null
  score: number | null
  id: number
  type: AssignmentType
  blockId?: number
  useInternalScores?: boolean
}

export type TasksFilterDropdownStore = {
  $item: TaskDataType | null
  $itemsDropdown: TaskDataType[]
  $searchString: string
}

export type LessonElementsType = {
  blockId?: number
  assigmentBlockId?: number
  id?: number
  number?: number
  element_type: ElementTypeNames
  task_type?: TaskTypeNames
  score?: number
  lesson_assigment?: number
  test_assigment?: number
  olympiad_assignment?: number
  text?: string
  link?: string
  player_link?: string
  additional_files?: boolean
  description?: string
  media_id?: number
  lesson_assignment_id?: number
  test_assignment_id?: number
  olympiad_assignment_id?: number
  el_id?: number
  media?: UploadMediaResponse | null
  file?: UploadMediaResponse | null
  use_task_score?: boolean
}

export type LessonUpdateAssigmentsType = {
  name: string
  number: number
  course?: number
  instruction?: string
  results?: string
  created_by?: UserRelated
  updated_by?: UserRelated
  subject?: SubjectRelated
  theme_id: number
  subject_id: number
  lesson_elements?: LessonElementsType[]
}

export type GetLessonType = {
  id: number
  name: string
  number: number
  course: null
  cover: UploadMediaResponse
  instruction: string
  results: string
  not_show_answers: boolean
  created_by: UserRelated
  creation_datetime: string
  updated_by: UserRelated
  update_datetime: string
  subject: SubjectRelated
  duration: null | number
  theme: {
    id: number
    name: string
    subject_id: number
  }
  lesson_elements: LessonElementsType[]
}

export type ElementsDropdownType = {
  name: ElementTypeNames
  title: string
}

export type ChangeFieldType = {
  type: FieldNames
  value: string
}

export type UploadBlockMediaResponse = UploadMediaResponse & { blockId: number }

export enum DropdownAssignmentBlockNames {
  theme = 'theme',
  classes = 'classes',
  subject = 'subject',
  tasks = 'tasks',
}

export type YouTubeLinkBlockType = {
  blockId: number
  link?: string
  element_type?: ElementTypeNames.link
}

export type TextBlockType = {
  blockId: number
  text?: string
  element_type?: ElementTypeNames.text
}

export type FileBLockType = {
  blockId: number
  description?: string
  file?: { id: number; link: string }
  element_type?: ElementTypeNames.file
}

export type AnswerTextBlockType = {
  blockId: number
  description?: string
  text?: string
  score?: number
  element_type?: ElementTypeNames.answer_text_field
}

export type AnswerFileBlockType = {
  blockId: number
  description?: string
  text?: string
  score?: number
  element_type?: ElementTypeNames.answer_file_field
}

export type formToGetTaskList = {
  study_year: { [key: number]: DropdownItem }
  subject: { [key: number]: DropdownItem }
  theme: { [key: number]: DropdownItem }
  taskType: { [key: number]: TaskTypeNames }
}

export type formToGetThemeList = {
  study_year: { [key: number]: DropdownItem }
  subject: { [key: number]: DropdownItem }
  taskType: { [key: number]: TaskTypeNames }
}

export type formAssignmentBlock = {
  task: { [key: number]: TaskDataType }
  task_type: { [key: number]: TaskTypeNames }
  use_task_score: { [key: number]: boolean }
  score: { [key: number]: string }
}
