import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { StudyYear, Subject } from '@/features/api/subject/types'
import { Employee } from '@/features/api/employee/types'
import { tasksChoiceMapData } from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/constant'

export const partTimeTestTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '85px',
  },
  {
    name: 'name',
    sortField: 'name',
    title: 'Название теста для ученика',
    dataClass: 'wrap',
    titleClass: 'wrap',
    width: '170px',
  },
  {
    name: 'internal_name',
    sortField: 'internal_name',
    title: 'Внутреннее название  теста в CMS',
    titleClass: 'wrap',
    dataClass: 'wrap',
    width: '180px',
  },
  {
    name: 'subject',
    sortField: 'subject',
    title: 'Предмет',
    width: '140px',
    formatter(subject: Subject) {
      return subject && subject.name ? subject.name : '-'
    },
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    title: 'Класс',
    width: '100px',
    formatter(year: StudyYear) {
      return year ? year.name : '-'
    },
  },
  {
    name: 'is_active',
    sortField: 'is_active',
    title: 'Доступен для прохождения',
    titleClass: 'wrap',
    width: '140px',
    formatter(isActive: boolean) {
      return isActive ? 'Да' : 'Нет'
    },
  },
  {
    name: 'duration_min',
    sortField: 'duration_min',
    title: 'Продолжительность теста',
    titleClass: 'wrap',
    width: '180px',
    formatter(duration_min: number) {
      return duration_min ? `${duration_min} мин` : ''
    },
  },
  {
    name: 'formation',
    sortField: 'formation',
    title: 'Логика формирования экзамена',
    titleClass: 'wrap',
    width: '190px',
  },
  {
    name: 'tasks_formation',
    sortField: 'tasks_formation',
    title: 'Логика выбора тем',
    titleClass: 'wrap',
    width: '170px',
  },
  {
    name: 'tasks_choice',
    sortField: 'tasks_choice',
    title: 'Переход между заданиями в тесте',
    titleClass: 'wrap',
    width: '160px',
    formatter(tasks_choice: number) {
      return tasksChoiceMapData[String(tasks_choice)]?.title || ''
    },
  },
  {
    name: 'tasks_amount',
    sortField: 'tasks_amount',
    title: 'Количество заданий в тесте',
    titleClass: 'wrap',
    width: '150px',
  },
  {
    name: 'themes_amount',
    sortField: 'themes_amount',
    title: 'Количество тем в тесте',
    titleClass: 'wrap',
    width: '150px',
  },
  {
    name: 'content',
    sortField: 'content',
    title: 'Состав экзаменационного теста',
    titleClass: 'wrap',
    width: '220px',
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '110px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Создатель',
    titleClass: 'wrap',
    width: '220px',
    formatter(user: Employee | null) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Обновлено',
    width: '110px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : ''
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Посл. изменение',
    titleClass: 'wrap',
    width: '140px',
    formatter(user: Employee | null) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'cms_commentary',
    sortField: 'cms_commentary',
    title: 'Заметка CMS',
    titleClass: 'wrap',
    width: '140px',
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsDataTimeTest: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'name', title: 'Название' },
  { name: 'subject', title: 'Предмет' },
  { name: 'study_year', title: 'Класс' },
]
