import { checkUserAuthedMiddleware, checkUserGuestMiddleware } from '@/features/session/middlewares'
import LoginLayout from '@/layouts/LoginLayout.vue'
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import LoginPage from '@/pages/login/LoginPage.vue'
import HomePage from '@/pages/home/HomePage.vue'
import bankRoutes from '@/pages/bank/bank-routes'
import dictionaryRoutes from '@/pages/dictionary/dictionary-routes'
import tagsRoutes from '@/pages/tags/tags-routes'
import labelsRoutes from '@/pages/labels/labels-routes'
import applicationsRoutes from '@/pages/applications/applications-routes'
import learningRoutes from '@/pages/learning/learning-routes'
import PreviewTasksPage from '@/pages/preview-tasks/PreviewTasksPage.vue'
import testingRoutes from './pages/testing/testing-routes'
import resultsRoutes from './pages/results/results-routes'
import usersRoutes from './pages/users/users-routes'
import contentRoutes from './pages/content/content-routes'
import settingRoutes from './pages/settings/settings-routes'
import examsRoutes from '@/pages/exams/exams-routes'
import callbackRoutes from '@/pages/callback/callback-routes'

export const routes: any = [
  {
    path: '/auth',
    component: LoginLayout,
    redirect: { name: 'auth.login' },
    beforeEnter: checkUserGuestMiddleware,
    children: [
      {
        name: 'auth.login',
        path: 'login',
        component: LoginPage,
        meta: {
          title: 'Вход - Школа Летово',
        },
      },
    ],
  },
  {
    path: '',
    component: DashboardLayout,
    redirect: { name: 'home' },
    beforeEnter: checkUserAuthedMiddleware,
    children: [
      {
        name: 'home',
        path: '/home',
        component: HomePage,
        meta: {
          title: 'Стартовая страница - Школа Летово',
        },
      },
      ...applicationsRoutes,
      ...bankRoutes,
      ...testingRoutes,
      ...learningRoutes,
      ...dictionaryRoutes,
      ...labelsRoutes,
      ...tagsRoutes,
      ...resultsRoutes,
      ...usersRoutes,
      ...callbackRoutes,
      ...contentRoutes,
      ...settingRoutes,
      ...examsRoutes,
      {
        name: 'preview-task',
        path: '/preview',
        component: PreviewTasksPage,
        meta: {
          title: 'Предпросмотр задания - Школа Летово',
        },
      },
      { name: 'default-route', path: '*', redirect: { name: 'home' } },
    ],
  },
]
