import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import { FAQType } from '@/features/api/content/types'

export const getFAQListFx = createApiEffect<void, TableDataResponse<FAQType[]>>({
  requestMapper: () => ({
    method: 'GET',
    url: '/api/content-app/faq/list/',
  }),
})
