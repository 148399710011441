
















import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { selectItemHeight } from '@/pages/common/parts/context-menu/constants'

export default Vue.extend({
  name: 'SelectItem',
  components: {
    Icon,
  },
  props: {
    active: { type: Boolean as PropType<boolean> },
    withIcon: { type: Boolean as PropType<boolean> },
    subTitle: { type: Boolean as PropType<boolean> },
  },
  data: () => ({
    selectItemHeight,
  }),
})
