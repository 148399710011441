import { attach, createEvent, createStore, forward, guard, restore, sample } from 'effector-root'
import { updateTicketBulkFx } from '@/features/api/ticket/moderation/update-ticket-bulk'
import { UpdateTicketBulkType } from '@/features/api/ticket/types'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { DropdownItem } from '@/pages/common/types'
import { debounce, every, spread } from 'patronum'
import { canRefreshTableChanged } from '@/pages/applications/incoming/incoming-applications-page.model'
import { getEmployeesListFx } from '@/features/api/employee/get-employee-list'
import { EmployeeList, GetEmployeeListQueryParams } from '@/features/api/employee/types'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { updateApplicationsCounters } from '@/pages/common/navigation/navigation.model'

const getModeratorsFx = attach({
  effect: getEmployeesListFx,
  mapParams: (params: GetEmployeeListQueryParams): GetEmployeeListQueryParams => ({
    ...params,
    search_area: 'search_all',
    per_page: 10,
  }),
})

const setToModerator = attach({
  effect: updateTicketBulkFx,
  mapParams: (params: UpdateTicketBulkType) => ({
    ...params,
    accept: null,
    send_to_revision: null,
    comment_id: null,
    set_moderator: true,
    cancel_outcome: null,
  }),
})

export const moderatorsDropdownModel = createDropdownModel<EmployeeList>(getModeratorsFx)

export const submit = createEvent<number>()
export const clearFields = createEvent<void>()

export const loadModeratorModal = createEvent<number[]>()
export const $selectedIds = restore(loadModeratorModal, []).reset(clearFields)

const setModerators = createEvent<EmployeeList[]>()
export const $moderators = createStore<DropdownItem[]>([]).reset(clearFields)

export const modalVisibilityChanged = createEvent<boolean>()
export const $modalVisibility = restore<boolean>(modalVisibilityChanged, false)

export const selectedPageChanged = createEvent<number>()
export const $selectedPage = restore<number>(selectedPageChanged, 1).reset(clearFields)

export const nextPageChanged = createEvent<string | null>()
export const prevPageChanged = createEvent<string | null>()
export const $nextPage = restore<string | null>(nextPageChanged, null)
export const $prevPage = restore<string | null>(prevPageChanged, null)
export const countChanged = createEvent<number | null>()
export const $count = restore<number | null>(countChanged, null)

export const clearSearchString = createEvent<void>()
export const searchStringChanged = createEvent<string>()
export const $moderatorSearchString = restore(searchStringChanged, '').reset(clearFields)

forward({
  from: clearSearchString,
  to: searchStringChanged.prepend(() => ''),
})

forward({
  from: loadModeratorModal,
  to: [
    modalVisibilityChanged.prepend(() => true),
    getModeratorsFx.prepend(() => ({})),
    canRefreshTableChanged.prepend(() => false),
  ],
})

spread({
  source: getModeratorsFx.doneData.map(({ body }) => body),
  targets: {
    data: setModerators,
    next_page_url: nextPageChanged,
    prev_page_url: prevPageChanged,
    total: countChanged,
  },
})

forward({
  from: setModerators.map((res) =>
    res.map((employee) => ({
      name: `${employee.id}`,
      title: `${employee.full_name}`,
    }))
  ),
  to: $moderators,
})

const searchStringChangedDebounced = debounce({
  source: searchStringChanged,
  timeout: 450,
})

sample({
  clock: searchStringChangedDebounced,
  source: $moderatorSearchString,
  fn: (searchString): GetEmployeeListQueryParams => ({
    search: searchString,
    page: 1,
  }),
  target: getModeratorsFx,
})

const $canSetToModerator = every({
  stores: [$moderators],
  predicate: (value) => value.length > 0,
})

sample({
  clock: selectedPageChanged,
  source: { $moderatorSearchString, $selectedPage },
  fn: (params): GetEmployeeListQueryParams => ({
    search: params.$moderatorSearchString,
    page: params.$selectedPage,
  }),
  target: getModeratorsFx,
})

sample({
  clock: guard({ source: submit, filter: $canSetToModerator }),
  source: $selectedIds,
  fn: (tickets, moderator_id) => ({ tickets, moderator_id }),
  target: setToModerator,
})

forward({
  from: setToModerator.doneData,
  to: [
    successToastEvent('Заявка назначена'),
    modalVisibilityChanged.prepend(() => false),
    clearFields,
    canRefreshTableChanged.prepend(() => true),
    updateApplicationsCounters,
  ],
})

guard({
  clock: modalVisibilityChanged,
  filter: $modalVisibility.map((visible) => !visible),
  target: clearFields,
})
