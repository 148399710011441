

























import Vue from 'vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'

export default Vue.extend({
  name: 'ShowAnswerBlock',
  components: {
    BaseSwitch,
    Wysiwyg,
  },
  props: {
    showSolutionEnabled: { type: Boolean, default: false },
    solutionText: { type: String },
  },
})
