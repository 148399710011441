import { createEvent, forward, attach, restore, sample } from 'effector-root'
import { StudentTestResultList } from '@/features/api/student/types'
import { DropdownItem } from '@/pages/common/types'
import { GetListQueryParams } from '@/features/api/types'
import { debounce } from 'patronum'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { getStudentsForTestsListFx } from '@/features/api/student/get-student-for-tests-list'

const getStudents = attach({
  effect: getStudentsForTestsListFx,
})

export const studentsDropdownModule = createDropdownModel<StudentTestResultList>(getStudents)

export const setSelectedStudents = createEvent<DropdownItem | null>()
export const $selectedStudents = restore(setSelectedStudents, null)

export const searchStudents = createEvent<void>()
export const loadStudents = createEvent<void>()

sample({
  clock: loadStudents,
  source: {
    $nextPage: studentsDropdownModule.store.$nextPage,
    search: studentsDropdownModule.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search }),
  }),
  target: getStudents,
})

sample({
  clock: searchStudents,
  source: {
    search: studentsDropdownModule.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search && { search: params.search }),
  }),
  target: getStudents,
})

const debounced = debounce({
  source: studentsDropdownModule.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchStudents,
})

forward({
  from: studentsDropdownModule.methods.canLoadNextPage,
  to: loadStudents,
})

sample({
  clock: getStudents.doneData,
  source: {
    items: studentsDropdownModule.store.$items,
    $nextPage: studentsDropdownModule.store.$nextPage,
    search: studentsDropdownModule.store.$searchString,
  },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((field) => ({
      name: `${field.id}`,
      title: `${field?.elk_id !== null ? `${field.elk_id} ` : ''}(${field.id}) ${field.fullname} (${
        field.tests_amount
      })`,
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: studentsDropdownModule.store.$items,
})

forward({
  from: studentsDropdownModule.methods.resetDropdown,
  to: setSelectedStudents.prepend(() => null),
})
