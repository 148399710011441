import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { attach, createEvent, createStore, forward, guard } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { getCoursesListFx } from '@/features/api/learning/courses/get-courses-list'

export const coursesDropdownModel = createFilter()

export const loadCourses = createEvent<void>()

export const setSelectedCourses = createEvent<DropdownItem>()

export const deleteSelectedCourse = createEvent<DropdownItem>()

const getCourses = attach({
  effect: getCoursesListFx,
  mapParams: () => ({}),
})

forward({
  from: loadCourses,
  to: getCourses,
})

export const $courses = createStore<DropdownItem[]>([]).on(getCourses.doneData, (_, res) => [
  ...res.body.data.map((payload) => ({ name: `${payload.id}`, title: payload.name })),
  { name: 'all', title: 'Все' },
])

export const unselectAllCourses = createEvent<void>()

export const selectAllCourses = createEvent<void>()

export const $isSelectedAllCourses = createStore<boolean>(false)
  .on(unselectAllCourses, () => false)
  .on(selectAllCourses, () => true)

guard({
  clock: setSelectedCourses,
  filter: (payload) => payload?.name !== 'all',
  target: unselectAllCourses,
})

export const $selectedCourses = createStore<DropdownItem[]>([])
  .on(setSelectedCourses, (state, payload) => [...state, payload])
  .on(deleteSelectedCourse, (state, payload) =>
    state.filter((element) => element.name !== payload.name)
  )
  .reset([coursesDropdownModel.methods.resetDropdown])
