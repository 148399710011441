



























































































import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import FormInput from '@/ui/input/FormInput.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import FileInput from '@/ui/audio-file-input/AudioFileInput.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  $date,
  $imageInfo,
  $seoDescription,
  $seoTitle,
  $slug,
  $text,
  $title,
  changeFieldValue,
  deleteImage,
} from '@/pages/content/news/edit/model/form-fields.model'
import { EditNewsPageFields } from '@/features/api/content/types'
import TagsDropdown from '@/pages/common/dropdowns/content/news/TagsDropdown/TagsDropdown.vue'

export default Vue.extend({
  name: 'EditNewsContent',
  components: {
    TagsDropdown,
    FormInput,
    Wysiwyg,
    DatePicker,
    FileInput,
    Icon,
  },
  effector: {
    $title,
    $slug,
    $date,
    $text,
    $seoTitle,
    $seoDescription,
    $imageInfo,
  },
  data: () => ({
    fieldType: EditNewsPageFields,
    slugInfo:
      'Если оставить пустым, то SLUG сгенерируется на основе введенного названия. Разрешенные символы: латиницы в нижнем регистре [a-z], цифры 0-1 и дефис. Максимальная длина 150 символов.',
  }),
  methods: {
    deleteImage,
    changeField(type: EditNewsPageFields, value: string) {
      changeFieldValue({ type, value })
    },
  },
})
