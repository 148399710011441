import dayjs from 'dayjs'
import { StudyYear, Subject } from '@/features/api/subject/types'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import { Employee, EmployeePermissions } from '@/features/api/employee/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { $currentUser } from '@/features/session'

export const themesTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID Темы',
    width: '110px',
  },
  {
    name: 'subject',
    sortField: 'subject__name',
    title: 'Предмет',
    width: '140px',
    formatter(subject: Subject) {
      return subject ? subject.name : '-'
    },
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Класс',
    width: '100px',
    formatter(year: StudyYear) {
      return year ? year.name : '-'
    },
  },
  {
    name: 'name',
    sortField: 'name',
    title: 'Название',
    width: '250px',
  },
  {
    name: 'is_prerequisite',
    sortField: 'is_prerequisite',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(value: boolean) {
      return value ? 'Да' : 'Нет'
    },
    title: 'Пререквизит',
    width: '150px',
  },
  {
    name: 'creation_datetime',
    sortField: 'creation_datetime',
    title: 'Создано',
    width: '130px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '-'
    },
  },
  {
    name: 'created_by',
    sortField: 'created_by',
    title: 'Кем',
    width: '200px',
    formatter(user: Employee) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'update_datetime',
    sortField: 'update_datetime',
    title: 'Обновлено',
    width: '130px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '-'
    },
  },
  {
    name: 'updated_by',
    sortField: 'updated_by',
    title: 'Кем',
    width: '200px',
    formatter(user: Employee) {
      return user ? `${user.first_name} ${user.last_name}` : '-'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'ID Темы' },
  { name: 'name', title: 'Название' },
  { name: 'study_year', title: 'Класс' },
  { name: 'subject', title: 'Предмет' },
]

// todo: Добавить обработку поведения для пререквизитов
/* https://paper.dropbox.com/doc/2021.02.03-CMS-.-2--BNqnuEpl~7pbk6S62vOQUcpSAg-YxtbuGcqW1Qgem78oxMfL#:h2=3.1.1.4.-%D0%94%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D0%B8%D1%8F-%D1%81-%D1%82%D0%B5%D0%BC%D0%B0%D0%BC%D0%B8 */
export const getThemesActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const user = $currentUser.getState()
  const isDefaultShow = user?.role !== 'METHODIST'

  const actions = [
    {
      name: 'create',
      title: 'Создать тему',
      action: 'onCreate',
      isDisplayed: displayOptions.isTreeItem || displayOptions.isTreeStudyYearFolder,
      hasPermissionToSee: permissions.subjects_theme_themes.can_edit,
    },
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed:
        displayOptions.isTableItem || displayOptions.isHeaderItem || displayOptions.isTreeItem,
      hasPermissionToSee: permissions.subjects_theme_themes.can_edit,
    },
    {
      name: 'delete',
      title: displayOptions.isTableItems ? 'Удалить выделенные темы' : 'Удалить',
      headerTitle: 'Удалить',
      action: 'onDelete',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isTreeItem,
      hasPermissionToSee: isDefaultShow,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
