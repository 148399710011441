




















































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { TreeData } from '@/features/api/types'
import BaseCheckbox from '@/ui/checkbox/BaseCheckbox.vue'

export default Vue.extend({
  name: 'ThemeSelectItemRecursive',
  components: {
    Icon,
    BaseCheckbox,
  },
  props: {
    item: {
      type: Object as PropType<{ id: number; leaves: TreeData[]; name: string; title: string }>,
    },
    active: { type: Boolean as PropType<boolean> },
    withIcon: { type: Boolean as PropType<boolean> },
    depth: { type: Number as PropType<number> },
    selectedItemId: { type: Number as PropType<number> },
    selectedItemsIds: { type: Array as PropType<number[]> },
    handleClick: { type: Function },
    disabledElement: { type: Number },
    isModal: { type: Boolean as PropType<boolean>, default: false },
  },
  computed: {
    indent() {
      return { paddingLeft: `${(this.depth + 1) * 20}px` }
    },
    showTick() {
      return this.selectedItemsIds.includes(this.item.id)
    },
  },
})
