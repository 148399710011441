import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const examsAnswersTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'number',
    title: '№',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'attempt',
    sortField: 'attempt',
    title: 'ID попытки',
    width: '130px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID ответа',
    width: '130px',
  },
  {
    name: 'subject',
    sortField: 'subject',
    title: 'Предмет',
    width: '145px',
  },
  {
    name: 'test_name',
    sortField: 'test_name',
    titleClass: 'wrap',
    title: 'Экзаменационный тест',
    width: '180px',
  },
  {
    name: 'groups',
    sortField: 'groups',
    title: 'Группа',
    width: '140px',
  },
  {
    name: 'study_year',
    sortField: 'study_year',
    title: 'Класс',
    width: '145px',
  },
  {
    name: 'student_id',
    sortField: 'student_id',
    title: 'ID ученика',
    width: '130px',
  },
  {
    name: 'student_name',
    sortField: 'student_name',
    title: 'Ученик',
    width: '170px',
  },
  {
    name: 'part',
    sortField: 'part',
    titleClass: 'wrap',
    title: 'Часть экзамена',
    width: '150px',
    formatter(part: string) {
      if (part === 'intro') return 'Вводная часть'
      if (part === 'main') return 'Основная часть'
      if (part === 'load') return 'Загрузка файлов'
      return part
    },
  },
  {
    name: 'unit_name',
    sortField: 'unit_name',
    titleClass: 'wrap',
    title: '№. Название блока',
    width: '170px',
  },
  {
    name: 'page_name',
    sortField: 'page_name',
    titleClass: 'wrap',
    title: '№. Название страницы',
    width: '170px',
  },
  {
    name: 'element_type',
    sortField: 'element_type',
    titleClass: 'wrap',
    title: 'Тип элемента',
    width: '170px',
  },
  {
    name: 'status',
    sortField: 'status',
    dataClass: 'wrap',
    title: 'Статус',
    width: '210px',
    formatter(status: number) {
      return status ? 'Дан ответ' : 'Выдан вопрос'
    },
  },
  {
    name: 'is_auto',
    sortField: 'is_auto',
    title: 'Автоматизированное задание',
    titleClass: 'wrap',
    width: '210px',
    formatter(isCorrect: boolean) {
      return isCorrect ? 'Да' : 'Нет'
    },
  },
  {
    name: 'theme',
    sortField: 'theme',
    title: 'Тема',
    width: '170px',
  },
  {
    name: 'assignment',
    sortField: 'assignment',
    title: 'ID задания',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '170px',
    formatter(id: number | null) {
      return typeof id === 'number' ? id : ''
    },
  },
  {
    name: 'assignment_type',
    sortField: 'assignment_type',
    title: 'Тип задания',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '170px',
  },
  {
    name: 'wording',
    sortField: 'wording',
    titleClass: 'wrap',
    title: 'Формулировка задания',
    width: '210px',
  },
  {
    name: 'answer',
    sortField: 'answer',
    dataClass: 'wrap',
    title: 'Ответ ученика',
    width: '400px',
  },
  {
    name: 'correct_answer',
    sortField: 'correct_answer',
    dataClass: 'wrap',
    title: 'Правильный ответ',
    width: '400px',
  },
  {
    name: 'is_correct',
    sortField: 'is_correct',
    title: 'Ответ верен',
    width: '170px',
    formatter(isCorrect: string) {
      if (isCorrect === 'not_set') return 'Не установлено'
      return isCorrect === 'yes' ? 'Да' : 'Нет'
    },
  },
  {
    name: 'score',
    sortField: 'score',
    titleClass: 'wrap',
    title: 'Балл ученика за автоматизированное задание',
    width: '210px',
  },
  {
    name: 'max_task_score',
    sortField: 'max_task_score',
    titleClass: 'wrap',
    title: 'Максимальный балл за автоматизированное задание',
    width: '210px',
  },
  {
    name: 'question_started_at',
    sortField: 'question_started_at',
    dataClass: 'wrap',
    title: 'Дата и время выдачи вопроса',
    width: '210px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : ''
    },
  },
  {
    name: 'answer_finished_at',
    sortField: 'answer_finished_at',
    dataClass: 'wrap',
    title: 'Дата и время ввода ответа',
    width: '210px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY HH:mm') : ''
    },
  },
  {
    name: 'duration',
    sortField: 'duration',
    dataClass: 'wrap',
    titleClass: 'wrap',
    title: 'Затраченное время (мм:сс:мс)',
    width: '210px',
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'attempt_id', title: 'ID попытки' },
  { name: 'answer_id', title: 'ID ответа' },
  { name: 'test', title: 'Название теста' },
  { name: 'subject', title: 'Предмет' },
  { name: 'student_id', title: 'ID абитуриента' },
  { name: 'student_name', title: 'Имя и фамилия абитуриента' },
  { name: 'group', title: 'Группа' },
]

export const getExamAnswersActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'showExamsTest',
      title: 'Предпросмотр задания',
      action: 'onShowExamsTest',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.exams_attempt_attempts.can_view,
    },
    {
      name: 'compareAnswers',
      title: 'Сравнить ответы',
      action: 'onCompareAnswers',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
    {
      name: 'openShowAnswer',
      title: 'Просмотреть ответ',
      action: 'onOpenShowAnswer',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.exams_attempt_attempts.can_view,
    },
    {
      name: 'openExamsTest',
      title: 'Открыть экзаменационный тест',
      action: 'onOpenExamsTest',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.exams_attempt_attempts.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
