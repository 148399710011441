import { createEvent, restore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { clearFieldsCourseCreate } from '@/pages/learning/learning-courses/create/courses-create-page.model'

export const mapCoursesType = [
  {
    name: 'С заявкой',
    title: 'С заявкой',
  },
  {
    name: 'Назначаемый',
    title: 'Назначаемый',
  },
]

export const mapCoursesTypeFilter = [
  {
    name: 'Показать все',
    title: 'Показать все',
  },
  ...mapCoursesType,
]

export const setSelectedCourseType = createEvent<DropdownItem | null>()
export const $selectedCourseType = restore(setSelectedCourseType, {
  name: 'Назначаемый',
  title: 'Назначаемый',
}).reset(clearFieldsCourseCreate)
