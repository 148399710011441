import { createEvent, restore, forward, attach } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import {
  olympiadTasksDropdownModule,
  resultStatusesDropdownModel,
  studentsDropdownModule,
  statusesDropdownModel,
} from '@/pages/results/olympiad-results/model/tooltip-filter.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { taskTypesDropdownModule } from '@/pages/common/dropdowns/bank/task-types-dropdown/task-types-dropdown.model'
import { scoreDropdownModule } from '@/pages/common/dropdowns/bank/olympiad-tasks/score-dropdown/score-dropdown.model'
import { tagsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/tags-dropdown-filter/tags-filter-dropdown.model'
import { exportOlympiadResultsListFx } from '@/features/api/results/export-olympiad-results'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'

const dropdownModules = {
  olympiadTasksDropdownModule,
  resultStatusesDropdownModel,
  statusesDropdownModel,
  subjectsDropdownModel,
  studentsDropdownModule,
  classesDropdownModule,
  taskTypesDropdownModule,
  scoreDropdownModule,
  tagsDropdownModel,
}

export const olympiadResultsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownModules
)

export const olympiadResultsPageParams = createPageParamsModel()

export const reset = createEvent<void>()

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

forward({
  from: reset,
  to: [
    olympiadTasksDropdownModule.methods.resetDropdown,
    resultStatusesDropdownModel.methods.resetDropdown,
    statusesDropdownModel.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    studentsDropdownModule.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    taskTypesDropdownModule.methods.resetDropdown,
    scoreDropdownModule.methods.resetDropdown,
    tagsDropdownModel.methods.resetDropdown,
  ],
})

export const exportOlympiadResultsList = attach({
  effect: exportOlympiadResultsListFx,
  source: [olympiadResultsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportOlympiadResultsList.doneData.watch((res) =>
  fileDownload(res.body, 'olympiadResultsList.xlsx')
)
