import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportAssignmentQueryParams } from '@/features/api/assignment/types/types'

export const exportTestsListFx = createApiEffect<ExportAssignmentQueryParams, Blob>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/test-app/test/list/export/',
    responseType: 'arraybuffer',
    query,
  }),
})
