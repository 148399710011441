import {
  attach,
  combine,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from 'effector-root'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { TreeData } from '@/features/api/types'
import { requestDeleteExamsTestFx } from '@/features/api/full-time-tests/delete-exam-tests'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { condition } from 'patronum'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { FiltersParams } from '@/pages/common/types'
import { mergeTreeData, sortTreeLeaves } from '@/features/lib'
import {
  GetAssignmentTreeQueryParams,
  RequestDeleteAssignmentsParams,
  RequestDeleteFolderParams,
} from '@/features/api/assignment/types/types'
import { examTasksFilters } from '@/pages/bank/exam-tasks/list/parts/exam-tasks-filter.model'
import {
  getExamTestsTreeLightFx,
  getExamTestsInfoFx,
  getExamTestsTreeFx,
} from '@/features/api/full-time-tests/get-exam-tests-tree'
import {
  deleteExamTestsFolderFx,
  requestDeleteExamTestsFx,
} from '@/features/api/full-time-tests/delete-folder'
import { getExamFullTimeTestsFx } from '@/features/api/exams/full-time/getExamFullTimeTestsFx'
import { examTestsFilters } from '@/pages/exams/full-time-exam-test/list/parts/exam-tests-filter.model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'

const getExamsTree = attach({
  effect: getExamTestsTreeFx,
})

const getExamsTreeLight = attach({
  effect: getExamTestsTreeLightFx,
})

const getFilteredTree = attach({
  effect: getExamTestsTreeFx,
})

const getExamTreeInfo = attach({
  effect: getExamTestsInfoFx,
})

/* export const deleteAssignments = createEffect({
  handler: (ids: number[]): Promise<number[]> => {
    return new Promise((resolve) => {
      deleteExamAssignmentFolderFx(ids).then(() => {
        resolve(ids)
      })
    })
  },
}) */

export const exportFullTimeExamTestsList = attach({
  effect: getExamFullTimeTestsFx,
  source: [examTestsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportFullTimeExamTestsList.doneData.watch((res) =>
  fileDownload(res.body, 'fullTimeExamTestsList.xlsx')
)

export const requestDeleteExamTests = attach({
  effect: requestDeleteExamsTestFx,
  mapParams: (
    payload: RequestDeleteAssignmentsParams
  ): {
    tests: number[]
    ticket_comment?: string
  } => {
    return {
      tests: payload.assignments,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const canRefreshAfterDuplicateChanged = createEvent<boolean>()
export const $canRefreshAfterDuplicate = restore<boolean>(canRefreshAfterDuplicateChanged, false)

export const loadList = createEvent<GetAssignmentTreeQueryParams>()

export const deleteFolder = attach({
  effect: deleteExamTestsFolderFx,
})

// ToDo нужны права с бэка
export const requestDeleteFolder = attach({
  effect: requestDeleteExamTestsFx,
  mapParams: (payload: RequestDeleteFolderParams): RequestDeleteFolderParams => {
    return {
      assignment_folders: payload.assignment_folders,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const examTestsPageParams = createPageParamsModel()

export const loadTree = createEvent<FiltersParams>()
export const loadTreeLight = createEvent<void>()
const loadFilteredTree = createEvent<FiltersParams>()
const rewriteTree = createEvent<TreeData[] | null>()
export const setExamsTree = createEvent<TreeData[] | null>()
export const $examsTree = createStore<TreeData[] | null>(null)
  .on(setExamsTree, (state, data) => mergeTreeData(state!, data!))
  .on(rewriteTree, (state, payload) => sortTreeLeaves(payload!))
export const setExamsTreeTotal = createEvent<number>()
export const $examsTreeTotal = restore<number>(setExamsTreeTotal, 0)

const showDeleteAssignmentsToast = createEvent<number[]>()
const showDeleteFolderToast = createEvent<void>()

export const $isLoading = combine(
  getFilteredTree.pending,
  getExamsTreeLight.pending,
  // getLessonAssignmentListFx.pending,
  (tree, light) => tree || light
)

forward({
  from: loadTreeLight,
  to: [getExamsTreeLight, getExamTreeInfo],
})

sample({
  source: examTasksFilters.store.$filterParams,
  clock: loadTree,
  fn: (filterParams, treeParams) => ({ ...filterParams, ...treeParams }),
  target: getExamsTree,
})

forward({
  from: loadFilteredTree,
  to: getFilteredTree,
})

forward({
  from: getExamTreeInfo.doneData.map(({ body }) => body.total_amount),
  to: setExamsTreeTotal,
})

forward({
  from: getExamsTreeLight.doneData,
  to: rewriteTree.prepend(({ body }) => body.data),
})

forward({
  from: getFilteredTree.doneData,
  to: [
    rewriteTree.prepend(({ body }) => body.data),
    setExamsTreeTotal.prepend(({ body }) => body.total),
  ],
})

forward({
  from: getExamsTree.doneData,
  to: setExamsTree.prepend(({ body }) => body.data),
})

/* forward({
  from: deleteAssignments.doneData,
  to: [
    loadTreeLight.prepend(() => ({})),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    showDeleteAssignmentsToast,
  ],
}) */

condition({
  source: showDeleteAssignmentsToast,
  if: (ids: number[]) => ids.length === 1,
  then: successToastEvent('Задание было успешно удалено!'),
  else: successToastEvent('Задания были успешно удалены!'),
})

forward({
  from: deleteFolder.doneData,
  to: [
    loadTreeLight.prepend(() => ({})),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    showDeleteFolderToast,
  ],
})

forward({
  from: showDeleteFolderToast,
  to: successToastEvent('Папка была успешно удалена!'),
})

forward({
  from: [requestDeleteExamTests.doneData, requestDeleteFolder.doneData],
  to: [
    loadTreeLight.prepend(() => ({})),
    successToastEvent('Отправлена заявка на удаление'),
    requestDeleteModalVisibilityChanged.prepend(() => false),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
  ],
})

guard({
  clock: examTasksFilters.methods.resetFilters,
  filter: examTestsPageParams.store.treeView,
  target: loadTreeLight.prepend(() => ({})),
})

guard({
  clock: examTestsPageParams.store.treeView,
  source: examTasksFilters.store.$filterParams,
  filter: (filterParams, treeView) => {
    return treeView && Object.keys.length === 3
  },
  target: loadFilteredTree,
})

guard({
  clock: examTasksFilters.methods.applyFilters,
  filter: examTestsPageParams.store.treeView,
  source: examTasksFilters.store.$filterParams,
  target: loadFilteredTree,
})
