


























































import Vue from 'vue'
import Card from '@/ui/card/Card.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import DatePicker from 'vue2-datepicker'
import TypeDropdown from '@/pages/common/dropdowns/content/banners/type/TypeDropdown.vue'
import {
  $name,
  nameChanged,
  resetName,
  $btnTitle,
  btnTitleChanged,
  resetBtnTitle,
  $btnUrl,
  btnUrlChanged,
  resetBtnUrl,
  $text,
  textChanged,
  resetText,
  $timerTarget,
  timerTargetChanged,
  resetTimerTarget,
  $formToSend,
} from '@/pages/content/banners/create/banner-creation-page.model'
import { $selectedType } from '@/pages/common/dropdowns/content/banners/type/type-dropdown.model'

export default Vue.extend({
  components: {
    Card,
    FormInput,
    DatePicker,
    TypeDropdown,
    BaseTextarea,
  },
  effector: {
    $name,
    $btnTitle,
    $text,
    $btnUrl,
    $timerTarget,
    $formToSend,
    $selectedType,
  },
  watch: {
    $selectedType: {
      deep: true,
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal) && oldVal) {
          resetTimerTarget()
        }
      },
    },
  },
  methods: {
    nameChanged,
    resetName,
    btnTitleChanged,
    btnUrlChanged,
    resetBtnUrl,
    resetBtnTitle,
    textChanged,
    resetText,
    timerTargetChanged,
  },
})
