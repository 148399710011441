























import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'RadioButton',
  props: {
    value: { type: [String, Boolean] as PropType<string | boolean>, required: true },
    option: { type: [String, Boolean] as PropType<string | boolean>, required: true },
    name: { type: String as PropType<string> },
    disabled: { type: Boolean as PropType<boolean> },
  },
  model: {
    prop: 'value',
    event: 'change',
  },

  computed: {
    isChecked() {
      return this.value === this.option ? 'checked' : ''
    },
  },
})
