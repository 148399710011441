import { DropdownItem } from '@/pages/common/types'

export const tasksFormationMapData: DropdownItem[] = [
  {
    name: '0',
    title: 'Случайный выбор n тем',
  },
  {
    name: '1',
    title: 'Все темы из доступных',
  },
]
