import { ActionsDisplayConditions } from '@/pages/common'
import { ActionsItem } from '@/pages/common/types'

export const getCertificatesGeneratorTableActions = (
  displayOptions: ActionsDisplayConditions
): ActionsItem[] => {
  const actions = [
    {
      name: 'onRemoveCertificates',
      title: 'Удалить',
      action: 'onRemoveCertificates',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
