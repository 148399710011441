


















































































































import Vue from 'vue'
import LearningFooter from '@/pages/learning/learning-courses-application/edit/common/Footer.vue'
import LearningHeader from '@/pages/learning/learning-courses-application/edit/common/Header.vue'
import InternalLoader from '@/pages/common/parts/loader/InternalLoader.vue'
import {
  save,
  changeIfRedirect,
  clearFieldsApplicationsCourseEdit,
  loadApplicationsCourse,
  $isLoadingApplicationsCourse,
  $ifRedirect,
  $isDisabledAccessDtChanged,
  $lessonAccessDt,
  lessonAccessDtChanged,
  applicationsCourseCommentChanged,
  $applicationsCourseComment,
  $studentInfo,
  $courseInfo,
  $questions,
  $canSaveCourse,
  setStatus,
  $isSaveApplicationsCourse,
} from '@/pages/learning/learning-courses-application/edit/applications-courses-edit-page.model'
import BaseButton from '@/ui/button/BaseButton.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import StatusFilterDropdown from '@/pages/common/dropdowns/courses/status-dropdown/StatusFilterDropdown.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'

export default Vue.extend({
  name: 'ApplicationsCoursesEditPage',
  components: {
    LearningHeader,
    LearningFooter,
    InternalLoader,
    BaseButton,
    DatePicker,
    StatusFilterDropdown,
    BaseTextarea,
  },
  effector: {
    $isLoadingApplicationsCourse,
    $ifRedirect,
    $isDisabledAccessDtChanged,
    $lessonAccessDt,
    $applicationsCourseComment,
    $studentInfo,
    $courseInfo,
    $questions,
    $canSaveCourse,
    $isSaveApplicationsCourse,
  },
  methods: {
    setStatus,
    applicationsCourseCommentChanged,
    lessonAccessDtChanged,
    updateCourse(isRedirect: boolean) {
      changeIfRedirect(isRedirect)
      save()
    },
  },
  beforeDestroy() {
    clearFieldsApplicationsCourseEdit()
  },
  created() {
    loadApplicationsCourse(+this.$route.params.id)
  },
})
