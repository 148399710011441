import { attach, createEvent } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { dropdownModules } from '@/pages/users/groups/list/model/tooltip-filter.model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'
import { exportProctorsListFx } from '@/features/api/proctors/export-proctors-list'

export const proctorsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownModules
)

export const proctorsPageParams = createPageParamsModel()

export const resetProctorsFilters = createEvent<void>()

export const exportProctorsList = attach({
  effect: exportProctorsListFx,
  source: [proctorsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => ({ ...filters, ...exportedColumns }),
})

exportProctorsListFx.doneData.watch((res) => fileDownload(res.body, 'proctorsList.xlsx'))
