
















import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'

export default Vue.extend({
  name: 'PageHeader',
  components: {
    BaseButton,
  },
})
