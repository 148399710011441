















































import Vue, { PropType } from 'vue'
import BaseInput from '@/ui/input/BaseInput.vue'
import { Position, Size } from '@/pages/common/parts/tasks/types'
import SizeInput from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/drag-and-drop-image-container/SizeInput.vue'
import ColorPicker from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/parts/areas-for-attaching-images/ColorPicker.vue'

export default Vue.extend({
  name: `ImageContextMenu`,
  components: {
    ColorPicker,
    SizeInput,
    BaseInput,
  },
  props: {
    cords: {
      type: Object as PropType<Position>,
      required: true,
    },
    size: {
      type: Object as PropType<Size>,
      required: true,
    },
    position: {
      type: Object as PropType<Position>,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    styles() {
      return {
        top: `${this.cords.y}px`,
        left: `${this.cords.x}px`,
      }
    },
  },
  methods: {
    changeSize(value: Size) {
      this.$emit('changeSize', value)
    },
    changeColor(value: string) {
      this.$emit('changeColor', value)
    },
    changePosition(value: Partial<Position>) {
      this.$emit('changePosition', {
        ...this.position,
        ...value,
      })
    },
  },
})
