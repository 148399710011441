<template>
  <div class='content'>
    <div class='base'>
      <div :class="$taskType === 'CONNECT_LINES_ANSWER' ? 'left' : 'left-base'">
        <SubjectDropdown class="field" @setItem="setSubject" />
        <ClassDropdown class="field" @setItem="setClass" />
        <ThemesDropdown class="field" @setItem="setTheme" />
        <DifficultiesDropdown class="field" @setItem="setDifficulty" />
        <LabelsDropdown class="field" is-disabled />
        <TaskTypesDropdown class="field" @setItem="setTaskType" />
        <component
          v-if="$taskType"
          :is="taskComponent"
        />
        <LanguagesDropdown
          class="field"
          :value="$language"
          @click="setLanguage"
        />
      </div>
      <div class='right'>
        <p class="text"> Количество </p>
        <div :class="baseRightStyle">
          <NumericInput :value="+$count" @input="setCount" />
          <BaseButton
            class="btn"
            big
            @click="duplicateAssignment"
          >
            Дублировать
          </BaseButton>
        </div>
        <BaseSwitch
          v-if="![ 'new', 'archive' ].includes($status)"
          class="switch field"
          :checked="$isArchive"
          @change="setIsArchive"
        >
          <p>Архив</p>
        </BaseSwitch>
        <BaseSwitch
          v-if="[ 'reserve', 'archive' ].includes($status)"
          class="switch field"
          :checked="$isPublished"
          @change="setIsPublished"
        >
          <p>Опубликовано</p>
        </BaseSwitch>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import NumericInput from '@/ui/input/NumericInput.vue'
import DifficultiesDropdown from '@/pages/bank/test-tasks/edit/parts/difficulties-dropdown/DifficultiesDropdown.vue'
import LabelsDropdown from '@/pages/common/dropdowns/labels/LabelsDropdown.vue'
import TaskTypesDropdown from '@/pages/common/dropdowns/bank/task-types-dropdown/TaskTypesDropdown.vue'
import SubjectDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import ThemesDropdown from '@/pages/common/dropdowns/themes-tree/ThemesDropdown.vue'
import LanguagesDropdown from '@/pages/bank/test-tasks/edit/parts/languages-dropdown/LanguagesDropdown.vue'
import {
  setSubject,
  setClass,
  setTheme,
  setDifficulty,
  $language,
  setLanguage,
  $count,
  setCount,
  $taskType,
  setTaskType,
  duplicateAssignment,
} from '@/pages/bank/test-tasks/edit/task-edition-page.model'
import * as tasks from '@/pages/common/parts/tasks'
import { mapTaskTypeTo } from '@/pages/common/constants'
import {
  $isArchive,
  $isPublished,
  $status,
  setIsArchive,
  setIsPublished,
} from '@/pages/common/parts/status-controller/status.model'
import { resetCounters } from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/moving-images-on-image-answer-form.model'
import { resetCounters as resetImagesOnTextCounters } from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'

export default Vue.extend({
  name: 'TaskContent',
  components: {
    BaseSwitch,
    BaseButton,
    DifficultiesDropdown,
    LabelsDropdown,
    TaskTypesDropdown,
    SubjectDropdown,
    ClassDropdown,
    ThemesDropdown,
    NumericInput,
    LanguagesDropdown,
  },
  effector: {
    $count,
    $taskType,
    $language,
    $status,
    $isArchive,
    $isPublished,
  },
  data() {
    return {
      display: false,
    }
  },
  computed: {
    taskComponent() {
      return this.$taskType ? tasks[mapTaskTypeTo[this.$taskType].componentName] : null
    },
    baseRightStyle() {
      return this.$taskType === 'CONNECT_LINES_ANSWER' && !this.display ? 'wrapper' : 'wrapper-base'
    },
  },
  watch: {
    taskComponent: {
      handler(newVal, oldVal) {
        if (oldVal) {
          resetCounters()
          resetImagesOnTextCounters()
        }
      },
    },
  },
  methods: {
    setSubject,
    setClass,
    setTheme,
    setDifficulty,
    setCount,
    setTaskType,
    setLanguage,
    setIsArchive,
    setIsPublished,
    duplicateAssignment,
    onResize() {
      this.display = window.innerWidth > 1600
    },
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
})
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #fff;
  border-radius: 3px;
}
.base {
  display: flex;
  justify-content: space-between;
}
.left,
.right {
  display: flex;
  flex-direction: column;
}
.left-base {
  flex-basis: 60%;
}
.left {
  @mixin bank-base-left;
}
.right {
  @mixin bank-base-right-wrapper;
}
.tasks-count {
  display: flex;
  align-items: center;
}
.field {
  margin-bottom: 20px;
}
.switch {
  display: flex;
  margin-top: 0px;
}
@media screen and (max-width: 1825px) {
  .left {
    flex-basis: 65%;
  }
}
@media screen and (max-width: 1600px) {
  .right {
    flex-basis: 0;
  }
}
</style>
