import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TreeDataInfoResponse, TreeDataResponse } from '@/features/api/types'
import { FiltersParams } from '@/pages/common/types'

export const getExamAssignmentTreeFx = createApiEffect<FiltersParams, TreeDataResponse>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/assignment-app/exam-assignment/tree/',
    query,
  }),
})

export const getExamAssignmentTreeLightFx = createApiEffect<void, TreeDataResponse>({
  requestMapper: () => ({
    method: 'GET',
    url: '/api/assignment-app/exam-assignment/tree/light/',
  }),
})

export const getExamTreeInfoFx = createApiEffect<void, TreeDataInfoResponse>({
  requestMapper: () => ({
    method: 'GET',
    url: '/api/assignment-app/exam-assignment/tree/info/',
  }),
})
