import { TableField } from '@/pages/applications/types'
import { DropdownItem } from '@/pages/common/types'
import dayjs from 'dayjs'
import { mapObjectType } from '@/pages/applications/constants'
import { mapApplicationsStatus } from '@/pages/common/parts/status-controller/constants'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const incomingDeletionApplicationsDataFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '50px',
  },
  {
    name: 'object_id',
    sortField: 'object_id',
    title: 'ID элемента',
    width: '130px',
  },
  {
    name: 'object_type',
    sortField: 'object_type',
    title: 'Тип элемента',
    width: '140px',
    formatter(text: string) {
      return mapObjectType[text] || '-'
    },
  },
  {
    name: 'object_name',
    sortField: 'object_name',
    title: 'Наименование',
    width: '200px',
    formatter(text: string) {
      return text || ''
    },
  },
  {
    name: 'comment',
    sortField: 'comment',
    title: 'Комментарий',
    width: '200px',
    formatter(text: string) {
      return text || '–'
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус',
    width: '150px',
    formatter(status: any) {
      return mapApplicationsStatus[status] || '-'
    },
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создана',
    width: '160px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'moderated_at',
    sortField: 'moderated_at',
    title: 'Обработана',
    width: '160px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'moderate_by',
    sortField: 'moderate_by',
    title: 'Проверяющий',
    width: '200px',
    formatter: (data) => {
      return data && data.first_name && data.last_name
        ? `${data.first_name} ${data.last_name}`
        : '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '120px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'id', title: 'id Задания' },
  { name: 'created_by', title: 'Создатель' },
  { name: 'moderate_by', title: 'Проверяющий' },
  { name: 'status', title: 'Статус' },
  { name: 'comment', title: 'Комментарий' },
  { name: 'object_name', title: 'Наименование' },
  { name: 'object_type', title: 'Тип элемента' },
]

export const getIncomingDeletionTicketsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const commonIsDisplayed =
    displayOptions.isTableItem ||
    displayOptions.isTableItems ||
    displayOptions.isHeaderItem ||
    displayOptions.isHeaderItems
  const actions = [
    {
      name: 'accept',
      title: 'Принять',
      action: 'onAccept',
      isDisplayed: commonIsDisplayed,
      hasPermissionToSee: permissions.tickets_deletionticket_income_tickets.can_edit,
    },
    {
      name: 'reject',
      title: 'Отклонить',
      action: 'onReject',
      isDisplayed: commonIsDisplayed,
      hasPermissionToSee: permissions.tickets_deletionticket_income_tickets.can_edit,
    },
    {
      name: 'open',
      title: 'Открыть',
      action: 'onOpen',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.tickets_deletionticket_income_tickets.can_view,
    },
    {
      name: 'see-comment',
      title: 'Посмотреть комментарии',
      action: 'onSeeComment',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.tickets_deletionticket_income_tickets.can_view,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
