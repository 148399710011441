import { attach, createEvent, restore } from 'effector-root'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { exportResultCoursesCheckListFx } from '@/features/api/results/export-result-courses-check'
import { resultCoursesCheckFilters } from '@/pages/results/courses-check/parts/filter/course-check-result-filter.model'
import fileDownload from 'js-file-download'

export const incomingResultCoursesCheckPageParams = createPageParamsModel()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore<boolean>(setIsLoading, false)

export const canRefreshTableChanged = createEvent<boolean>()
export const $canRefreshTable = restore(canRefreshTableChanged, false)

export const exportResultCoursesCheckList = attach({
  effect: exportResultCoursesCheckListFx,
  source: [resultCoursesCheckFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportResultCoursesCheckList.doneData.watch((res) =>
  fileDownload(res.body, 'resultCoursesCheckList.xlsx')
)
