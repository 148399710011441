
















import Vue, { PropType } from 'vue'
import PaginationButton from '@/ui/pagination/PaginationButton.vue'

export default Vue.extend({
  components: {
    PaginationButton,
  },
  props: {
    selectedPage: { type: Number as PropType<number> },
    count: { type: Number as PropType<number> },
  },
  computed: {
    pagesAmount() {
      if (this.count) return Math.ceil(this.count / 10)
      return 1
    },
  },
})
