













































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  toggleModalVisibility,
  $modalVisibility,
  $correctAnswer,
  $studentAnswer,
} from '@/pages/results/olympiad-results/model/compare-answers.model'
import Answers from '@/pages/results/answer-types/Answers.vue'

export default Vue.extend({
  name: 'CompareAnswerModal',
  components: {
    Modal,
    BaseButton,
    Answers,
  },
  effector: {
    $modalVisibility,
    $correctAnswer,
    $studentAnswer,
  },
  methods: {
    toggleModalVisibility,
  },
})
