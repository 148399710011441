import { TableField } from '@/pages/dictionary/themes/list/types'

export const studentsFullAppointmentsTableFields: TableField[] = [
  {
    name: 'checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '55px',
  },
  {
    name: 'fullname',
    sortField: 'fullname',
    title: 'Имя',
    width: '170px',
  },
  {
    name: 'email',
    sortField: 'email',
    title: 'E-mail',
    width: '130px',
  },
  {
    name: 'study_year.name',
    sortField: 'study_year',
    title: 'Класс',
    width: '90px',
  },
  {
    name: 'button',
    title: '',
    dataClass: 'aligned',
    width: '80px',
  },
]

export const mapStudentsType = [
  {
    name: '1',
    title: 'Всех пользователей',
  },
  {
    name: '2',
    title: 'Только выбранных для добавления',
  },
]
