import { createApiEffect } from '@/features/api/common/create-api-effect'
import { CreateEmployeeType, Employee } from '@/features/api/employee/types'

export const createEmployeeFx = createApiEffect<Partial<CreateEmployeeType>, Employee, any>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/user-app/employee/',
    body,
  }),
})
