import { createEvent, forward, restore } from 'effector-root'
import {
  partTimeTestFilters,
  resetPartTimeTestFilters,
} from '@/pages/exams/part-time-test/list/model/part-time-exams-tests-filters.model'

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(resetPartTimeTestFilters)

export const dropdownModules = {
  // classesDropdownModel,
}

forward({
  from: [resetPartTimeTestFilters, toggleVisibility],
  to: [
    // classesDropdownModel.methods.resetDropdown,
  ],
})

forward({
  from: toggleVisibility,
  to: partTimeTestFilters.methods.resetFilters,
})
