import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { attach, createStore, createEvent, forward } from 'effector-root'
import { getRegistrationFilterFx } from '@/features/api/student/get-filter-register-by-service'
import { DropdownItem } from '@/pages/common/types'

export const registrationsDropdownModel = createFilter()

export const loadRegistration = createEvent<void>()

export const selectRegistration = createEvent<DropdownItem>()

const getRegistrations = attach({
  effect: getRegistrationFilterFx,
  mapParams: () => ({}),
})

export const $registrations = createStore<DropdownItem[]>([]).on(
  getRegistrations.doneData,
  (_, res) => res.body.map((payload) => ({ name: `${payload.name}`, title: payload.name }))
)

forward({
  from: loadRegistration,
  to: getRegistrations,
})

forward({
  from: selectRegistration,
  to: registrationsDropdownModel.methods.itemChanged,
})
