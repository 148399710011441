import { createEvent, forward, restore } from 'effector-root'
import {
  proctorsFilters,
  resetProctorsFilters,
} from '@/pages/exams/proctors/list/model/proctors-filters.model'

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(resetProctorsFilters)

export const dropdownModules = {
  // classesDropdownModel,
}

forward({
  from: [resetProctorsFilters, toggleVisibility],
  to: [
    // classesDropdownModel.methods.resetDropdown,
  ],
})

forward({
  from: toggleVisibility,
  to: proctorsFilters.methods.resetFilters,
})
