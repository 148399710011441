import { attach, createEvent, createStore, forward, restore, sample } from 'effector'
import { getMediaListFx } from '@/features/api/media/get-media-list'
import { UploadMediaResponse } from '@/features/api/media/types'
import { condition, debounce, spread } from 'patronum'
import { FiltersParams } from '../types'

const getMediaList = attach({
  effect: getMediaListFx,
  mapParams: (params: FiltersParams): FiltersParams => ({
    ...params,
    file_type: 'img,file',
    per_page: 10,
  }),
})

export const storageVisibilityChanged = createEvent<boolean>()
export const $storageVisibility = restore(storageVisibilityChanged, false)

export const setImages = createEvent<UploadMediaResponse[]>()
export const $images = restore(setImages, [])

export const loadImages = createEvent<void>()

const clearFields = createEvent<void>()

const resetPage = createEvent<void>()

export const selectedPageChanged = createEvent<number>()
export const $selectedPage = restore<number>(selectedPageChanged, 1).reset(resetPage)

export const countChanged = createEvent<number | null>()
export const $count = restore<number | null>(countChanged, null)

export const clearSearchString = createEvent<void>()
export const searchStringChanged = createEvent<string>()
export const $mediaSearchString = restore(searchStringChanged, '').reset(clearFields)

const nextPageTrigger = createEvent<void>()

const $currentPage = createStore<number>(1)
  .on(getMediaListFx.doneData, (_, res) => {
    return res.body.current_page
  })
  .reset(resetPage)
const $nextPage = createStore<number>(1).reset(resetPage)
const $lastPage = createStore<number>(1)
  .on(getMediaListFx.doneData, (_, res) => {
    return res.body.last_page
  })
  .reset(resetPage)

const loaded = createEvent<boolean>()
export const $loading = restore<boolean>(loaded, false).reset(resetPage)

forward({
  from: clearSearchString,
  to: searchStringChanged.prepend(() => ''),
})

sample({
  clock: storageVisibilityChanged,
  target: condition({
    source: $storageVisibility,
    if: (visible: boolean) => visible,
    then: loadImages,
    else: resetPage,
  }),
})

forward({
  from: loadImages,
  to: getMediaList.prepend(() => ({ page: 1 })),
})

spread({
  source: getMediaListFx.doneData.map(({ body }) => body),
  targets: {
    data: setImages,
    total: countChanged,
  },
})

const searchStringChangedDebounced = debounce({
  source: searchStringChanged,
  timeout: 450,
})

sample({
  clock: searchStringChangedDebounced,
  source: $mediaSearchString,
  fn: (searchString) => ({
    search: searchString,
    page: 1,
  }),
  target: getMediaList,
})

sample({
  clock: selectedPageChanged,
  source: { $mediaSearchString, $selectedPage },
  fn: (params) => ({
    search: params.$mediaSearchString,
    page: params.$selectedPage,
  }),
  target: getMediaList,
})

sample({
  clock: nextPageTrigger,
  source: { $currentPage, $lastPage },
  fn: (source) => {
    if (source.$currentPage < source.$lastPage) {
      return source.$currentPage + 1
    }
    return 1
  },
  target: $nextPage,
})

forward({
  from: getMediaListFx,
  to: loaded.prepend(() => true),
})

forward({
  from: getMediaListFx.finally,
  to: loaded.prepend(() => false),
})
