









































import Vue from 'vue'
import { UploadMediaResponse } from '@/features/api/media/types'
import { downloadFileNoUrl } from '@/features/lib'
import { StudentFile } from '@/pages/common/modals/stedent-answer-modal/types'

export default Vue.extend({
  name: 'FileFieldBlock',
  props: {
    files: {
      type: Array as () => StudentFile[],
      default: () => [],
    },
  },
  computed: {
    imageArr() {
      return this.files.filter((file) => file && file?.file_type === 'img')
    },
    noImageArr() {
      return this.files.filter((file) => file && file?.file_type !== 'img')
    },
  },
  methods: {
    download(file: UploadMediaResponse) {
      downloadFileNoUrl(file.file, file.file_name)
    },
  },
})
