import { FinalNodeLinkCopy, transformTreeNode } from '@/features/lib'
import { ChildNodes } from '@/pages/common/types'

export const parseTemplate = (template: string) => {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = template
  const parsedTextStructure = document.createElement('div')
  let characterCounter = 1

  const rightAnswer = {}

  const wrapCharactersInSpan = (segment: string, color: string | null) => {
    const parsedSegment: HTMLSpanElement[] = []

    segment.split('').forEach((character) => {
      if (character !== '\n') {
        const span = document.createElement('span')
        span.setAttribute('id-char', `c${characterCounter}`)
        span.setAttribute('it-char', '')
        span.innerText = character
        parsedSegment.push(span)

        if (color) rightAnswer[`c${characterCounter}`] = color

        characterCounter++
      }
    })
    return parsedSegment
  }

  const parseText = (node: ChildNodes, finalNodeLinkCopy: FinalNodeLinkCopy): FinalNodeLinkCopy => {
    /* не храним спан обертки по причине совместимости с логикой превью */
    if (node.nodeName === 'SPAN') return finalNodeLinkCopy

    if (node?.nodeType === 3) {
      const closestHighlightedElement = node.parentElement?.closest('[it-fill]')
      let color = null
      if (closestHighlightedElement) color = closestHighlightedElement.getAttribute('it-fill')
      const wrappedCharacters = wrapCharactersInSpan(node.textContent!, color)

      wrappedCharacters.forEach((spanElement) => {
        finalNodeLinkCopy!.appendChild(spanElement)
      })
    } else {
      const cleanNodeCopy = node.cloneNode()
      finalNodeLinkCopy!.appendChild(cleanNodeCopy)
      return cleanNodeCopy
    }
    return null
  }

  transformTreeNode(wrapper, parseText, parsedTextStructure)
  return {
    /* just slicing two div wrappers that was used to convenience */
    $parsedText: parsedTextStructure.outerHTML.slice(10, -12),
    $rightAnswer: JSON.stringify(rightAnswer),
  }
}
