














































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { lessonAssignmentFolderModal } from '@/pages/common/modals/tasks-bank/creating-folder/creating-folder-modal.model'
import PositionDropdown from '@/pages/common/dropdowns/bank/lesson-tasks/position-dropdown/PositionDropdown.vue'

export default Vue.extend({
  name: 'CreatingLessonAssignmentFolderModal',
  components: {
    Modal,
    Icon,
    FormInput,
    BaseButton,
    PositionDropdown,
  },
  effector: {
    $modalVisibility: lessonAssignmentFolderModal.store.$modalVisibility,
    titleError: lessonAssignmentFolderModal.store.$titleErrorModule.store.$error,
    $folderTitle: lessonAssignmentFolderModal.store.$folderTitle,
  },
  methods: {
    ...lessonAssignmentFolderModal.methods,
  },
})
