import { OptionalExamElements } from '@/features/api/full-time-tests/types'
import { ElementTypeNames } from '@/pages/learning/learning-lessons/create/model/types'
import { ExamTestsAssignBlock } from '@/pages/learning/learning-lessons/create/model/assignment-block.model'

export const defineStateAfterAddIntroBlock = (
  state: OptionalExamElements[],
  payload: OptionalExamElements
) => {
  const newState = [...state]
  const currentElementID = newState.findIndex((item) => item.blockId === payload.blockId)
  if (currentElementID > -1) {
    newState[currentElementID] = {
      ...newState[currentElementID],
      ...payload,
    }
  }
  return newState
}

export const defineStateAfterDeleteIntroBlock = (state: OptionalExamElements[], id: number) => {
  const deleteElement = state.find((block) => block.blockId === id)
  if (deleteElement && deleteElement.element_type === ElementTypeNames.assignment) {
    ExamTestsAssignBlock.methods.deleteAssignmentBlock(deleteElement?.assigmentBlockId || 0)
  }
  return [...state]
    .filter((element, index) => index !== id)
    .map((element, i) => ({ ...element, blockId: i }))
}
