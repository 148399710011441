











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { LessonsAssignBlock } from '@/pages/learning/learning-lessons/create/model/assignment-block.model'
import { DropdownItem } from '@/pages/common/types'
import { DropdownAssignmentBlockNames } from '@/pages/learning/learning-lessons/create/model/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    label: String,
    blockId: Number,
  },
  effector: {
    ...LessonsAssignBlock.modules.classesDropdownModel.store,
    $classes: LessonsAssignBlock.store.$classes,
    $selectedClass: LessonsAssignBlock.store.$selectedClass,
  },
  methods: {
    ...LessonsAssignBlock.modules.classesDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      LessonsAssignBlock.methods.setSelectedType({
        value: {
          name: '',
          title: '',
        },
        type: DropdownAssignmentBlockNames.theme,
        id: this.blockId,
      })
      LessonsAssignBlock.methods.setSelectedType({
        value: item || null,
        type: DropdownAssignmentBlockNames.classes,
        id: this.blockId,
      })
    },
  },
})
