import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const getPartsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'onRemoveAttempts',
      title: 'Удалить',
      action: 'onRemoveAttempts',
      isDisplayed:
        displayOptions.isTableItems ||
        displayOptions.isTableItem ||
        displayOptions.isHeaderItems ||
        displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.exams_ptattempt_ptattempts.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems || displayOptions.isHeaderItem,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
