












































































































































































import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import { config } from '@/config'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import {
  $isLoading,
  requestDeleteAttempts,
  $refreshPage,
  attemptsPageMounted,
  setIsLoading,
  setNewTableFields,
  $tableFields,
  setDefaultTableFields,
} from '@/pages/exams/attempts/list/model/attempts-page.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { loadRequestDeleteModal } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { $permissions } from '@/features/session'
import {
  RefsType,
  DisplayContextMenuPayload,
  CommonInteractedItemParams,
  HttpOptionsType,
} from '@/pages/common/types'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import {
  combineRouteQueries,
  computeSortParam,
  isQueryParamsEquelToPage,
  removeHtmlTags,
} from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { TableHeaderItem } from '@/pages/common/parts/table-header/types'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import { searchFieldsData } from '@/pages/exams/attempts/list/model/constants'
import AttemptsHeader from '@/pages/exams/attempts/list/parts/AttemptsHeader.vue'
import AttemptFilter from '@/pages/exams/attempts/list/parts/filter/AttemptFilter.vue'
import AttemptsModalButtonFilter from '@/pages/exams/attempts/list/parts/filter/AttemptsModalButtonFilter.vue'
import {
  $visibilityFilters,
  attemptsFilters,
  attemptsPageParams,
  taskLoader,
  toggleVisibilityFilters,
} from '@/pages/exams/attempts/list/model/attempts-filters.model'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { Attempt } from '@/pages/exams/attempts/list/model/types'
import TableColumnEditModal from '@/pages/common/modals/table-settings/table-colunm-edit/TableColumnEditModal.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  $setColumns,
  changeColumnsModalVisibility,
} from '@/pages/common/modals/table-settings/table-colunm-edit/table-colunm-edit-modal.model'
import AttemptsFilterModal from '@/pages/common/modals/attempts-filter/AttemptsFilterModal.vue'
import AttemptsUpdateModal from '@/pages/exams/attempts/list/modals/attempts-update/AttemptsUpdateModal.vue'
import { $canRefreshAfterMultiChanges } from '@/pages/exams/attempts/list/modals/attempts-update/attempts-update-modal.model'
import qs from 'qs'
import {
  $filterBlock,
  $saveFilters,
} from '@/pages/common/modals/attempts-filter/attempts-filter-modal.model'
import { axiosClient } from '@/lib/request'
import { getActionsDisplayConditions } from '@/pages/common'
import { getPartsActions } from '@/pages/exams/attempts/list/parts/constants'

Vue.use(VueEvents)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'AttemptsPage',
  components: {
    Vuetable,
    VuetablePagination,
    AttemptsHeader,
    TableHeader,
    GeneralFilter,
    AttemptFilter,
    TooltipCell,
    ContextMenu,
    ActionsButton,
    NoDataContent,
    ConfirmDeleteModal,
    RequestDeleteModal,
    LoaderBig,
    TableColumnEditModal,
    Icon,
    AttemptsModalButtonFilter,
    AttemptsFilterModal,
    AttemptsUpdateModal,
  },
  effector: {
    $visibilityFilters,
    $isLoading,
    $refreshPage,
    $canRefreshAfterMultiChanges,
    $tableFields,
    $permissions,
    $setColumns,
    $filterBlock,
    $saveFilters,
    $filterParams: attemptsFilters.store.$filterParams,
    $pageParams: attemptsPageParams.store.$pageParams,
    $currentPage: attemptsPageParams.store.currentPage,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      searchFields: searchFieldsData,
      isFilters: false,
      total: 0,
      selectedRows: [] as number[],
      showDeleteModalType: 'attempts',
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/exams-app/part-time/attempts/list/`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): TableHeaderItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getPartsActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $refreshPage: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
    $canRefreshAfterMultiChanges: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    changeFilter: attemptsFilters.methods.changeFilter,
    resetFilters: attemptsFilters.methods.resetFilters,
    applyFilters: attemptsFilters.methods.applyFilters,
    removeParamsFilter: attemptsFilters.methods.removeParamsFilter,
    toggleTreeView: attemptsPageParams.methods.toggleTreeView,
    changePage: attemptsPageParams.methods.changePage,
    queryToParams: attemptsPageParams.methods.queryToParams,
    toggleVisibilityFilters,
    setIsLoading,
    changeColumnsModalVisibility,
    setNewTableFields,
    setDefaultTableFields,
    exportTable() {
      taskLoader.effect.exportFx(null)
      taskLoader.methods.setTaskLoading(true)
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const resultsParams = {
        result_fs: JSON.stringify(
          this.$filterBlock.map((block) => ({
            subject: block.subject ? block.subject.name : '',
            test: block.test ? block.test.name : '',
            sign: block.sign ? block.sign.name : '',
            mode: block.result_number ? 'r' : 'p',
            value: block.result_number ? block.result_number : block.result?.name || '',
          }))
        ),
      }
      const request = axiosClient.get(apiUrl, {
        params: {
          ...httpOptions.params,
          ...(this.$route.query?.appointment && { appointment: this.$route.query?.appointment }),
          sort: computeSortParam(httpOptions.params.sort),
          ...(this.$saveFilters ? resultsParams : {}),
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' })
        },
      })
      return request
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    onFilterSet() {
      this.isFilters = true
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.isFilters = false
      this.resetFilters()
      toggleVisibilityFilters(false)
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onRemoveAttempts() {
      const payload = this.selectedIds
      this.showDeleteModalType = 'attempts'
      // TODO: уточнить $permissions
      this.$permissions!.users_group_groups.can_edit
        ? loadConfirmDeleteModal(payload)
        : loadRequestDeleteModal(payload)
    },
    async handleConfirmDelete(ids: number[], type: string) {
      if (type === 'attempts') {
        await requestDeleteAttempts({ attempts: ids })
        this.$refs.vuetable.reload()
        this.removeSelection()
      }
    },
    async sendRequestDeleteAttempts(comment: string, ids: number[]) {
      await requestDeleteAttempts({ attempts: ids, ticket_comment: comment })
      this.$refs.vuetable.reload()
      this.removeSelection()
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    headerActionsButtonClick(event: MouseEvent) {
      this.handleActionsClick({
        data: { id: this.selectedIds[0] },
        event,
        type: 'header-actions',
      })
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleInteractedItemData(data: { id: number }) {
      this.interactedItemId = data.id
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    endLoading() {
      setTimeout(() => {
        this.setIsLoading(false)
      }, 700)
    },
    clearWording(str: string) {
      return removeHtmlTags(str)
    },
    getTestData(attempt: Attempt) {
      return `${attempt.id}:[18109]`
    },
    setColumns() {
      this.setNewTableFields(this.$setColumns)
      this.changeColumnsModalVisibility(false)
    },
    toAnswer(item: any) {
      const students = item?.student
      const attempts = {
        id: item?.id,
        started_at: item?.started_at,
        status: item?.status,
      }
      this.$router.push({
        name: 'exams-answers',
        params: { students: JSON.stringify(students), attempts: JSON.stringify(attempts) },
      })
    },
  },
  mounted() {
    attemptsPageMounted()
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.setDefaultTableFields()
    this.resetFilters()
  },
})
