











import Vue, { PropType } from 'vue'
import { DropdownItem } from '@/pages/common/types'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  $students,
  studentsDropdownModule,
  loadStudents,
  loadStudentsById,
} from '@/pages/common/dropdowns/students/students-dropdown.model'
import { GetStudentsForIdParams } from '@/features/api/results/types'

export default Vue.extend({
  name: 'StudentsCourses',
  components: {
    FilterDropdown,
  },
  props: {
    /**
     * isById true для фильтрации студентов в результаты - курсы по студентам
     * findId три id нужные для запроса: course, lesson и group
     * */
    isById: { type: Boolean, default: false },
    findId: {
      type: Object as PropType<GetStudentsForIdParams>,
      default: () => ({
        course: 0,
        lesson: 0,
      }),
    },
  },
  watch: {
    findId(value) {
      if (this.isById && value && value?.course && value?.lesson) {
        loadStudentsById(value)
      }
    },
  },

  effector: {
    $students,
    ...studentsDropdownModule.store,
  },
  methods: {
    ...studentsDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (!this.isById) loadStudents()
  },
})
