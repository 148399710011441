







































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import {
  $modalTasksTypesVisibility,
  modalTasksTypesVisibilityChanged,
} from '@/pages/common/modals/tasks-bank/tasks-types/tasks-types-modal.model'
import Icon from '@/ui/icon/Icon.vue'
import { tasksTypesData } from '@/pages/common/modals/tasks-bank/tasks-types/data'

export default Vue.extend({
  components: {
    Modal,
    Icon,
  },
  effector: {
    $modalTasksTypesVisibility,
  },
  computed: {
    types() {
      return tasksTypesData
    },
  },
  methods: {
    modalTasksTypesVisibilityChanged,
  },
})
