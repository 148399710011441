




























import Vue from 'vue'
import HobbiesHeader from '@/pages/settings/hobbies/parts/HobbiesHeader.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $hobbyText,
  changeHobbyText,
  reset,
  saveHobby,
} from '@/pages/settings/hobbies/model/hobbies-page.model'
import { createHobbieFx } from '@/features/api/settings/hobbies/create-hobbie'

export default Vue.extend({
  name: 'HobbiesPage',
  components: {
    HobbiesHeader,
    LoaderBig,
    Wysiwyg,
    BaseButton,
  },
  effector: {
    $isLoading: createHobbieFx.pending,
    $hobbyText,
  },
  methods: {
    changeHobbyText,
    saveHobby,
  },
  beforeDestroy() {
    reset()
  },
})
