










































import Vue, { VueConstructor } from 'vue'
import { Vuetable, VuetablePagination } from 'vuetable-2'
import {
  ActionsItem,
  CommonInteractedItemParams,
  DisplayContextMenuPayload,
  HttpOptionsType,
  RefsType,
} from '@/pages/common/types'
import SubscribersHeader from '@/pages/callback/subscribers/parts/SubscribersHeader.vue'
import {
  $isLoading,
  subscriberPageMounted,
  subscribersPageParams,
} from '@/pages/callback/subscribers/model/callback-comments-on-lessons-page.model'
import {
  getSubscribersActions,
  SubscriberTableFields,
} from '@/pages/callback/subscribers/model/constants'
import { commentsOnLessonsPageParams } from '@/pages/callback/comments-on-lessons/model/callback-comments-on-lessons-filters.model'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import { axiosClient } from '@/lib/request'
import { config } from '@/config'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { DEFAULT_ID } from '@/pages/common/constants'
import { combineRouteQueries, isQueryParamsEquelToPage } from '@/features/lib'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { deleteSubscribe } from '@/features/api/callback/delete-subscribe'

export default (Vue as VueConstructor<Vue & { $refs: RefsType }>).extend({
  name: 'Subscribers',
  components: {
    ContextMenu,
    NoDataContent,
    TableHeader,
    SubscribersHeader,
    Vuetable,
    VuetablePagination,
  },
  data: () => {
    return {
      fields: SubscriberTableFields,
      total: 0,
      selectedRows: [] as number[],
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      interactedItemId: DEFAULT_ID,
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/user-app/subscription`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    $isLoading() {
      return $isLoading
    },
    tableHeaderItems(): ActionsItem[] {
      return getSubscribersActions(this.selectedRows.length)
    },
  },
  effector: {
    $pageParams: subscribersPageParams.store.$pageParams,
    $currentPage: subscribersPageParams.store.currentPage,
  },
  watch: {
    $refreshPage: {
      handler(newVal) {
        if (newVal) this.$refs.vuetable.reload()
        this.removeSelection()
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    rowPolling() {
      const interval = setInterval(() => {
        if (this.$refs.vuetable) {
          this.$refs.vuetable.reload()
        } else {
          clearInterval(interval)
        }
      }, 10000)
    },
    changePage: commentsOnLessonsPageParams.methods.changePage,
    queryToParams: commentsOnLessonsPageParams.methods.queryToParams,
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      return axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, per_page: 25 },
      })
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    onRemove() {
      if (this.selectedIds[0]) {
        deleteSubscribe({ id: this.selectedIds[0] })
        this.$refs.vuetable.reload()
        this.removeSelection()
      }
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    headerActionsButtonClick(event: MouseEvent) {
      this.handleActionsClick({
        data: { id: this.selectedIds[0] },
        event,
        type: 'header-actions',
      })
    },
    handleInteractedItemData(data: { id: number }) {
      this.interactedItemId = data.id
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      event.preventDefault()
      this.handleInteractedItemData(data)
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
  },
  mounted: () => {
    subscriberPageMounted()
  },
  created() {
    this.rowPolling()
    this.queryToParams(this.$route.query)
  },
})
