import { attach, createEvent, forward, restore, createStore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { getUsersListCert } from '@/features/api/user/get-users-list'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'

export const certUsersDropdownModel = createFilter()

export const setSelectedUser = createEvent<DropdownItem | null>()
export const $selectedUser = restore(setSelectedUser, null)

const getUsers = attach({
  effect: getUsersListCert,
})

export const loadCertUsers = createEvent<void>()
export const $certUsers = createStore<DropdownItem[]>([])

forward({
  from: loadCertUsers,
  to: getUsers.prepend(() => ({})),
})

forward({
  from: getUsers.doneData.map((res) =>
    res.body.map((user) => ({ name: `${user.id}`, title: user.name }))
  ),
  to: $certUsers,
})

forward({
  from: certUsersDropdownModel.methods.resetDropdown,
  to: setSelectedUser.prepend(() => null),
})
