import { ResourceType } from '@/features/api/media/types'
import { TreeData } from '@/features/api/types'
import { FiltersParams } from '@/pages/common/types'
import Vue, { PropType } from 'vue'

type AutoOpenMixinConfig = {
  filters: {
    [key: string]: (item: TreeData, search: string) => boolean
  }
}
const mapResourceTypeToName = {
  video: 'text',
  link: 'text',
  text: 'text',
  file: 'file_name',
}

export default (config?: AutoOpenMixinConfig) =>
  Vue.extend({
    props: {
      filters: { type: Object as PropType<FiltersParams> },
    },
    data: () => ({
      opened: false,
      searchString: '',
    }),
    computed: {
      autoOpenMixinComputed() {
        return {
          search: this.filters.search?.toLowerCase() || '',
        }
      },
    },
    methods: {
      openFolderAfterActions(
        data: FiltersParams,
        node: TreeData,
        nodeId: number,
        resetCb: any,
        toggleCb: any,
        prerequisiteFolder = false,
        recursion_type?: 'folder' | 'theme'
      ) {
        Object.entries(data).forEach((el, index) => {
          if (
            !Object.entries(data).every((a) => a[1]) &&
            el[0] === 'subject' &&
            el[1] !== undefined
          ) {
            this.opened = prerequisiteFolder
            return
          }
          if (!prerequisiteFolder && node.element_type === el[0] && nodeId === el[1]) {
            this.opened = true
            if (index === Object.entries(data).length - 1) {
              resetCb()
              toggleCb(false)
            }
            return
          }
          if (recursion_type && nodeId !== el[1] && node.leaves.length > 0) {
            this.findChildById(node.leaves, +el[1]!, recursion_type)
          }
        })
      },
      findChildById(nodes: TreeData[], id: number, type: 'folder' | 'theme') {
        nodes.forEach((node) => {
          if (node.leaves.length > 0 && node.element_type === type && node[type]!.id !== id)
            this.findChildById(node.leaves, id, type)
          if (node.element_type === type && node[type]!.id === id) this.opened = true
        })
      },
      checkCustomFilter(node: TreeData) {
        if (!this.searchString) return false
        return config?.filters![this.searchString](node, this.autoOpenMixinComputed.search)
      },
      toggleData() {
        this.opened = !this.opened
      },
      checkChildren(nodes: TreeData[]): boolean {
        return nodes.some((n) => {
          if (this.checkCustomFilter(n)) {
            return true
          }
          let name = 'name'
          if (n.element_type === 'media') name = 'file_name'
          if (n.element_type === 'study_resource') {
            const type = n[n.element_type]?.resource_type!
            name = mapResourceTypeToName[type]
          }
          if (n.element_type === 'assignment') name = 'wording'
          return n[n.element_type]![name].includes(this.autoOpenMixinComputed.search)
        })
      },
      checkChildrenTypes(nodes: TreeData[], search: string) {
        return nodes.some((n) => n.element_type === search)
      },
      chooseName(node: TreeData) {
        const map = {
          media: 'file_name',
          study_resource:
            mapResourceTypeToName[(node[node.element_type] as ResourceType)?.resource_type!],
          assignment: 'wording',
        }
        return map[node.element_type] || 'name'
      },
      autoOpenFolders(nodes: TreeData[]) {
        this.searchString = this.searchString === 'all' ? '' : this.searchString
        nodes.forEach((node) => {
          let name = this.chooseName(node)
          if (this.searchString.length > 0) {
            if (
              node.element_type === this.searchString &&
              node[node.element_type]![name].toLowerCase().includes(
                this.autoOpenMixinComputed.search
              )
            ) {
              this.opened = false
              return
            }
            if (
              node.leaves &&
              (this.checkChildrenTypes(node.leaves, this.searchString) ||
                this.checkChildren(node.leaves))
            ) {
              this.opened = true
              return
            }
          }
          if (this.searchString.length === 0) {
            if (
              node[node.element_type]![name].toLowerCase().includes(
                this.autoOpenMixinComputed.search
              )
            ) {
              this.opened = true
              return
            }
            if (+this.autoOpenMixinComputed.search && node.element_type !== 'virtual_folder') {
              name = 'id'
              if (
                node[node.element_type]![name].toString().includes(
                  this.autoOpenMixinComputed.search
                )
              ) {
                this.opened = true
                return
              }
            }
            if (node.leaves && this.checkChildren(node.leaves)) {
              this.opened = true
              return
            }
          }
          if (this.checkCustomFilter(node)) {
            this.opened = true
            return
          }
          if (node.element_type !== this.searchString) {
            this.autoOpenFolders(node.leaves)
          }
        })
      },
    },
  })
