import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { TestList } from '@/features/api/test/types'

export const getTestsListFx = createApiEffect<GetListQueryParams, TableDataResponse<TestList[]>>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/test-app/test/list/',
    query,
  }),
})
