import SubjectFilterDropdown from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/SubjectFilterDropdown.vue'
import TasksChoiceDropdown from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/TasksChoiceDropdown.vue'
import ClassesDropdown from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/ClassesFilterDropdown.vue'
import ExamTestsFolders from '@/pages/common/dropdowns/exams/exams-tests-folders/ExamTestsFolders.vue'

export const dropdownComponents = {
  SubjectFilterDropdown,
  ExamTestsFolders,
  TasksChoiceDropdown,
  ClassesDropdown,
}
