import dayjs from 'dayjs'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { DropdownItem } from '@/pages/common/types'

export const OlympiadResultsTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: 'ID',
    width: '70px',
  },
  {
    name: 'subject_name',
    sortField: 'subject_name',
    title: 'Предмет',
    width: '120px',
  },
  {
    name: 'study_year_number',
    sortField: 'study_year_number',
    title: 'Класс',
    width: '120px',
    formatter: (data) => {
      return data ? `${data} класс` : '–'
    },
  },
  {
    name: 'assignment_id',
    sortField: 'assignment',
    title: 'ID задания',
    width: '150px',
  },
  {
    name: 'student_id',
    sortField: 'student',
    title: 'ID абитуриента',
    width: '150px',
    formatter: (data) => {
      return data || '–'
    },
  },
  {
    name: 'student_elk_id',
    sortField: 'student_elk_id',
    title: 'ID ЕЛК',
    width: '90px',
  },
  {
    name: 'student_name',
    sortField: 'student_name',
    title: 'Абитуриент',
    width: '150px',
    formatter: (data) => {
      return data || '–'
    },
  },
  {
    name: 'status',
    sortField: 'status',
    title: 'Статус',
    width: '120px',
    formatter: (data) => {
      if (!data) return '-'
      return data === 1 ? 'продолжается' : 'завершен'
    },
  },
  {
    name: 'assignment_score',
    sortField: 'assignment_score',
    title: 'Баллы',
    width: '100px',
    formatter: (data) => {
      return data || '–'
    },
  },
  {
    name: 'result_score',
    sortField: 'result_score',
    title: 'Результат',
    width: '120px',
    formatter: (data) => {
      return data
    },
  },
  {
    name: 'correct_answer',
    sortField: 'correct_answer',
    title: 'Правильное решение',
    width: '700px',
    formatter(data) {
      return data || 'Ошибка загрузки данных'
    },
  },
  {
    name: 'answer',
    sortField: 'answer',
    title: 'Ответ ученика',
    width: '700px',
    formatter(data) {
      return data || 'Ошибка загрузки данных'
    },
  },
  {
    name: 'started_at',
    sortField: 'started_at',
    title: 'Дата начала',
    width: '150px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'finished_at',
    sortField: 'finished_at',
    title: 'Дата завершения',
    width: '170px',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'clues',
    title: 'Использование подсказки',
    width: '240px',
    formatter(
      data: {
        id: number
        text: string
        scores: number
        number: number
      }[]
    ) {
      if (data.length > 0) {
        const newData = data.map((clue) => {
          return `Подсказка ${clue.number} (${clue.scores} балла)`
        })
        return newData.join(',')
      }
      return 'Нет'
    },
  },
  {
    name: 'answer_opened_before_complete',
    title: 'Просмотр решения',
    width: '160px',
    formatter(data: boolean | null) {
      switch (data) {
        case null:
          return '-'
        case true:
          return 'до выполнения'
        case false:
          return 'после выполнения'
        default:
          return '-'
      }
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'subject_name', title: 'Предмет' },
  { name: 'student_id', title: 'ID абитуриента' },
  { name: 'student_name', title: 'Абитуриент' },
  { name: 'assignment_answer_text', title: 'Правильное решение' },
  { name: 'answer', title: 'Ответ ученика' },
]
