import dayjs from 'dayjs'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { EmployeePermissions } from '@/features/api/employee/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { slideMediaType } from '@/features/api/content/types'

const mapFileTypes = {
  doc: 'Документ',
  zip: 'Архив',
  audio: 'Аудио',
  img: 'Картинка',
  video: 'Видео',
  file: 'Другое',
}

export const slidesTableFields: TableField[] = [
  {
    name: '__checkbox',
    title: '',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '70px',
  },
  {
    name: 'id',
    sortField: 'id',
    title: '№ Слайда',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    width: '120px',
  },
  {
    name: 'active',
    sortField: 'active',
    title: 'Отображать',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter: (data) => {
      return data ? 'Да' : 'Нет'
    },
    width: '130px',
  },
  {
    name: 'name',
    sortField: 'name',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    title: 'Название  (для CMS)',
    width: '200px',
  },
  {
    name: 'media',
    sortField: 'media',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(media: slideMediaType) {
      return media ? mapFileTypes[media.file_type] : 'Не указано'
    },
    title: 'Тип медиа содержимого',
    width: '220px',
  },
  {
    name: 'show_text',
    sortField: 'show_text',
    title: 'Текст содержимого',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter: (data) => {
      return data ? 'Да' : 'Нет'
    },
    width: '220px',
  },
  {
    name: 'show_title',
    sortField: 'show_title',
    title: 'Заголовок',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter: (data) => {
      return data ? 'Да' : 'Нет'
    },
    width: '200px',
  },
  {
    name: 'show_buttons',
    sortField: 'show_buttons',
    title: 'Кнопки переключения',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter: (data) => {
      return data ? 'Да' : 'Нет'
    },
    width: '200px',
  },
  {
    name: 'created_at',
    sortField: 'created_at',
    title: 'Создано',
    width: '200px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'updated_at',
    sortField: 'updated_at',
    title: 'Обновлено',
    width: '130px',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    formatter(datetime: string) {
      return datetime ? dayjs(datetime).format('DD.MM.YYYY') : '–'
    },
  },
  {
    name: 'actions',
    title: '',
    dataClass: 'actions-cell right aligned',
    width: '125px',
  },
]

// todo: Добавить обработку поведения для пререквизитов
/* https://paper.dropbox.com/doc/2021.02.03-CMS-.-2--BNqnuEpl~7pbk6S62vOQUcpSAg-YxtbuGcqW1Qgem78oxMfL#:h2=3.1.1.4.-%D0%94%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D0%B8%D1%8F-%D1%81-%D1%82%D0%B5%D0%BC%D0%B0%D0%BC%D0%B8 */
export const getSlidesActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed: displayOptions.isTableItem || displayOptions.isHeaderItem,
      hasPermissionToSee: permissions.content_slider_slider.can_edit,
    },
    {
      name: 'delete',
      title: displayOptions.isTableItems ? 'Удалить выделенные слайды' : 'Удалить',
      headerTitle: 'Удалить',
      action: 'onDelete',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
