import { createEvent, forward, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { debounce } from 'patronum'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { studentsExamAnswerDropdownModel } from '@/pages/exams/part-time-answers/list/parts/students-exams-answer-filter/students-exams-answer-filter.model'
import { AttemptsListFx } from '@/features/api/part-time-answer/get-attempts'
import { AttemptsList } from '@/features/api/attempts/types'
import dayjs from 'dayjs'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/exams-tests-filter/exams-test-filter.model'

export const AttemptsDropdownModel = createDropdownModel<AttemptsList>(AttemptsListFx)

export const loadAttempts = createEvent<number | null>()
export const searchAttempts = createEvent<void>()

sample({
  clock: searchAttempts,
  source: {
    search: AttemptsDropdownModel.store.$searchString,
    students: studentsExamAnswerDropdownModel.store.$item,
    tests: examTestsDropdownModel.store.$selectedItems,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    search_area: 'search_all',
    ...(params.search ? { search: params.search } : {}),
    ...(params.students ? { students: params.students.name } : {}),
    ...(params.tests && params.tests.length > 0
      ? { tests: params.tests.map((group) => group.name) }
      : {}),
  }),
  target: AttemptsListFx,
})

sample({
  clock: loadAttempts,
  source: {
    $nextPage: AttemptsDropdownModel.store.$nextPage,
    search: AttemptsDropdownModel.store.$searchString,
    students: studentsExamAnswerDropdownModel.store.$item,
    tests: examTestsDropdownModel.store.$selectedItems,
  },
  fn: (params, isNoNextPage: number | null): GetListQueryParams => ({
    page: isNoNextPage ? 1 : params.$nextPage,
    search_area: 'search_all',
    ...(params.search && { search: params.search }),
    ...(params.students ? { students: params.students.name } : {}),
    ...(params.tests && params.tests.length > 0
      ? { tests: params.tests.map((group) => group.name) }
      : {}),
  }),
  target: AttemptsListFx,
})

const debounced = debounce({
  source: AttemptsDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchAttempts,
})

forward({
  from: [
    AttemptsDropdownModel.methods.canLoadNextPage,
    studentsExamAnswerDropdownModel.store.$item,
    examTestsDropdownModel.store.$selectedItems,
  ],
  to: loadAttempts.prepend((data: any) => {
    if (data?.$currentPage) return null
    return 1
  }),
})

export const getStartDate = (started_at: null | string) => {
  return dayjs(started_at).format('DD.MM.YYYY HH:mm')
}

sample({
  clock: AttemptsListFx.doneData,
  source: {
    items: AttemptsDropdownModel.store.$items,
  },
  fn: ({ items }, res) => {
    let showResult = false
    if (res.config.url) {
      const requiredParams = ['students', 'tests']
      requiredParams.forEach((param) => {
        if (res.config.url && res.config.url.includes(param)) {
          showResult = true
        }
      })
    }
    if (!showResult) {
      return []
    }
    const newData = res.body.data.map((attempt) => ({
      name: `${attempt.id}`,
      title: `(${attempt.id})${attempt.started_at ? ` ${getStartDate(attempt.started_at)}` : ''}${
        attempt.status === 1 ? ' (процесс)' : ' завершена'
      }`,
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: AttemptsDropdownModel.store.$items,
})
