















import Vue from 'vue'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import BaseButton from '@/ui/button/BaseButton.vue'

export default Vue.extend({
  name: 'NewsHeader',
  components: {
    GridPageHead,
    BaseButton,
  },
})
