











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  registrationsDropdownModel,
  $registrations,
  loadRegistration,
  selectRegistration,
} from '@/pages/users/students/list/parts/registration-dropdown/registration-dropdown.model'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...registrationsDropdownModel.store,
    $registrations,
  },
  props: {
    label: String,
  },
  data() {
    return {
      registrationDropdownMethods: registrationsDropdownModel.methods,
    }
  },
  methods: {
    ...registrationsDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      if (item !== null) {
        selectRegistration(item)
        this.$emit('setItem', item.name)
      }
    },
  },
  mounted() {
    loadRegistration()
  },
})
