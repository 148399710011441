








































import Vue from 'vue'
import { removeHtmlTags } from '@/features/lib'
import { getSrcFromHtml } from '@/pages/results/answer-types/wrappers/helpers'

export default Vue.extend({
  name: 'ConnectLinesAnswer',
  props: {
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    correctAnswer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    currentAnswer() {
      return this.answerType === 'student' ? this.answer : this.correctAnswer
    },
    answerData() {
      try {
        if (typeof this.answer === 'object' && this.answerType === 'student') {
          // @ts-ignore
          return Object.entries(this.answer).map((arr: [string, string[]], i) => ({
            markedTitle: arr[1].map((el: string) => removeHtmlTags(el)),
            text: `${i + 1}) ${removeHtmlTags(arr[0])}`,
            src: getSrcFromHtml(arr[0]),
          }))
        }
        if (Array.isArray(this.correctAnswer) && this.answerType === 'correct') {
          return this.correctAnswer.map(
            (obj: any, i) =>
              // @ts-ignore
              Object.entries(obj).map((arr: string[]) => ({
                markedTitle: removeHtmlTags(arr[1]),
                text: `${i + 1}) ${removeHtmlTags(arr[0])}`,
                src: getSrcFromHtml(arr[0]),
              }))[0]
          )
        }
        return this.answerType === 'student' ? this.answer : this.correctAnswer
      } catch (e) {
        console.error(e)
        return []
      }
    },
  },
})
