import { createEvent, forward, restore, createStore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'

export const shownDropdownModel = createFilter()

export const setSelectedShown = createEvent<DropdownItem | null>()
export const $selectedShown = restore(setSelectedShown, null)
export const $shown = createStore<DropdownItem[]>([
  { name: 'true', title: 'Да' },
  { name: 'false', title: 'Нет' },
])

forward({
  from: shownDropdownModel.methods.resetDropdown,
  to: setSelectedShown.prepend(() => null),
})
