import { createEvent, guard, restore, sample, forward, createStore, split } from 'effector-root'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import {
  $createQuestionsPageForm,
  clearFields,
} from '@/pages/content/faq/edit/model/form-fields.model'
import { createQuestionFx } from '@/features/api/content/faq/create-question'
import { getQuestionByIdFx } from '@/features/api/content/faq/get-question-by-id'
import { updateQuestionByIdFx } from '@/features/api/content/faq/patch-question-by-id'
import { condition } from 'patronum'

export const changeQuestionId = createEvent<string>()

export const $editedQuestionId = createStore<string>('')
  .on(changeQuestionId, (state, payload) => payload || state)
  .reset(clearFields)

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  createQuestionFx.fail,
  updateQuestionByIdFx.fail,
])

export const saveQuestionInfo = createEvent<void>()

const createQuestion = createEvent<void>()

const updateQuestion = createEvent<string>()

const questionId = sample({
  source: $editedQuestionId,
  clock: saveQuestionInfo,
  fn: (id) => (id.length > 0 ? id : false),
})

guard({
  source: changeQuestionId,
  filter: (payload) => !!payload,
  target: getQuestionByIdFx,
})

split({
  source: questionId,
  match: {
    post: (payload) => payload === false,
    patch: (payload) => payload !== false,
  },
  cases: {
    post: createQuestion,
    patch: updateQuestion,
  },
})

sample({
  source: $createQuestionsPageForm,
  clock: createQuestion,
  target: createQuestionFx,
})

sample({
  source: { body: $createQuestionsPageForm, id: $editedQuestionId },
  clock: updateQuestion,
  target: updateQuestionByIdFx,
})

const $redirectHandler = sample({
  clock: [
    createQuestionFx.doneData.map((res) => res.body.id),
    updateQuestionByIdFx.doneData.map((res) => res.body.id),
  ],
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'faq-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'edit-question',
    params: { id: `${payload.id}` },
  })),
})

guard({
  source: createQuestionFx.doneData.map((res) => res.body.id),
  filter: $redirectAfterSave.map((redirect) => !redirect),
  target: changeQuestionId.prepend((id) => String(id)),
})

forward({
  from: createQuestionFx.done,
  to: successToastEvent('Вопрос успешно сохранен'),
})

forward({
  from: updateQuestionByIdFx.done,
  to: successToastEvent('Вопрос успешно отредактирован'),
})

forward({
  from: [
    createQuestionFx.failData.map((res) => res.body),
    updateQuestionByIdFx.failData.map((res) => res.body),
  ],
  to: addToast.prepend((data: any) => ({
    type: 'error',
    message: data,
  })),
})
