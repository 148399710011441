export function movingImagesOnTextAddingInsertInputsRules(editor: any) {
  /*
    Решение бага в тикете https://trello.com/c/SA8u1z85
    Суть бага: при наведение картеки(курсора) на вставленный инпут он ведет себя так
    будто он выделен, то есть может быть удален нажатием любой клавишы при этом сама коретка пропадет из виду
    Данное поведение вытекает из на данного бага: https://github.com/froala/wysiwyg-editor/issues/3074
    В качестве решение заменяем значения атрибута contenteditable для инпутов на true
    За большим количесвтом информации по реализации:
    https://stackoverflow.com/questions/27691955/altering-ckeditor-html-content-after-instanceready-and-paste
  */
  editor.dataProcessor.dataFilter.addRules(
    {
      elements: {
        $(element: any) {
          if (element.hasClass('redactor-input') || element.hasClass('redactor-drop')) {
            element.attributes.contenteditable = 'true'
          }
        },
      },
    },
    { applyToAll: true }
  )

  editor.dataProcessor.htmlFilter.addRules(
    {
      elements: {
        $(element: any) {
          if (element.hasClass('redactor-input') || element.hasClass('redactor-drop')) {
            delete element.attributes.contenteditable
          }
        },
      },
    },
    { applyToAll: true }
  )
}
