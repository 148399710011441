import { createApiEffect } from '@/features/api/common/create-api-effect'
import { UpdateCommentOnLessonQueryBody } from '@/features/api/callback/types'

export const patchCommentOnLessonFX = createApiEffect<
  { body: UpdateCommentOnLessonQueryBody },
  null
>({
  requestMapper: ({ body }) => ({
    method: 'PATCH',
    url: '/api/lesson-app/lessons/commentaries/list/bulk-patch/',
    body,
  }),
})
