
















































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import SubjectDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ClassDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  $subjectErrorModule,
  $classErrorModule,
  $titleErrorModule,
  checkIfThemeCanBeSend,
  $tagTitle,
  tagTitleChanged,
} from '@/pages/tags/parts/modals/tag-edition/tag-edition.modal'

export default Vue.extend({
  components: {
    Modal,
    Icon,
    SubjectDropdown,
    ClassDropdown,
    BaseTextarea,
    BaseButton,
  },
  effector: {
    $modalVisibility,
    subjectError: $subjectErrorModule.store.$error,
    classError: $classErrorModule.store.$error,
    titleError: $titleErrorModule.store.$error,
    $tagTitle,
  },
  methods: {
    modalVisibilityChanged,
    checkIfThemeCanBeSend,
    tagTitleChanged,
  },
})
