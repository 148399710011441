import { combine, createEvent, forward, restore } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { spread } from 'patronum'
import { getGroupIdFx } from '@/features/api/groups/get-group-dy-id.ts'
import { StudentGroupFilter } from '@/features/api/groups/types'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { StudentAppointmentsDataMinimal } from '@/pages/exams/appointments/create/model/types'

type StudentsForForGroups = {
  [key: number]: StudentAppointmentsDataMinimal
}
export const resetFields = createEvent()

export const setGroupName = createEvent<string>()
export const $groupName = restore(setGroupName, '').reset(resetFields)

export const setGroupComment = createEvent<string>()
export const $groupComment = restore(setGroupComment, '').reset(resetFields)

export const setSearchStudent = createEvent<string>()
export const $searchStudent = restore<string>(setSearchStudent, '').reset(resetFields)

export const setSearchSelectedStudent = createEvent<string>()
export const $searchSelectedStudent = restore<string>(setSearchSelectedStudent, '').reset(
  resetFields
)

export const searchFieldGroupChanged = createEvent<DropdownItem>()
export const $searchFieldGroup = restore(searchFieldGroupChanged, {
  name: 'all',
  title: 'Искать везде',
}).reset(reset)

export const changeStudentsForGroup = createEvent<StudentsForForGroups>()
export const $studentsForGroup = restore<StudentsForForGroups>(changeStudentsForGroup, {}).reset(
  reset
)

export const $studentsForGroupCount = $studentsForGroup.map((data) => Object.keys(data).length)

export const $studentsForGroupFilterList = combine(
  $studentsForGroup,
  $searchSelectedStudent,
  (students, searchSelectedStudent) => {
    if (!searchSelectedStudent) return students
    const filtersStudent = {}
    Object.values(students)
      .filter((student) => {
        return (
          student.fullname?.includes(searchSelectedStudent) ||
          student.email?.includes(searchSelectedStudent) ||
          String(student.id).includes(searchSelectedStudent)
        )
      })
      .forEach((student) => {
        filtersStudent[student.id] = student
      })
    return filtersStudent
  }
)

export const $studentsForGroupsCount = $studentsForGroup.map((data) => Object.keys(data).length)

export const $studentsForGroupsIds = $studentsForGroup.map((data) =>
  Object.values(data).map((student) => student.id)
)

export const $disabledSaveButtons = combine($groupName, (name) => {
  return !name
})

export const $groupsSendForm = combine(
  $groupName,
  $studentsForGroup,
  $groupComment,
  classesDropdownModule.store.$item,
  (name, students, comment, study_year) => {
    const studentsArr = Object.values(students)
    const student_ids = studentsArr.length > 0 ? studentsArr.map((student) => student.id) : []
    return {
      name,
      student_ids,
      comment,
      ...(study_year?.name ? { study_year_id: study_year.name } : {}),
    }
  }
)

spread({
  source: getGroupIdFx.doneData.map((res) => ({
    ...res.body,
    name: res.body.name,
    comment: res.body?.comment || '',
    study_year: res.body?.study_year,
    students: res.body.students,
  })),
  targets: {
    name: $groupName,
    comment: $groupComment,
    study_year: classesDropdownModule.methods.itemChanged.prepend(
      (payload: { id: number; name: string } | null) => {
        if (payload)
          return {
            name: `${payload.id}`,
            title: payload.name,
          }
        return null
      }
    ),
    students: changeStudentsForGroup.prepend((students: StudentGroupFilter[]) => {
      const studentsObj = {}
      students.forEach((student) => {
        studentsObj[student.id] = student
      })
      return studentsObj
    }),
  },
})

forward({
  from: reset,
  to: resetFields,
})
