import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import {
  GetSampleStudentTestResultListQueryParams,
  SampleStudentTestResult,
} from '@/features/api/results/types'

export const getSampleStudentTestResultByStudentId = createApiEffect<
  GetSampleStudentTestResultListQueryParams,
  TableDataResponse<SampleStudentTestResult[]>
>({
  requestMapper: (params) => {
    const { student, test, ...query } = params
    return {
      method: 'GET',
      url: `/api/results-app/results/test/students/${student}/${test}`,
      query,
    }
  },
})
