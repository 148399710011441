



















































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  resetAnswers,
  $studentAnswer,
  $comparingStudentAnswerModalVisibility,
  comparingStudentAnswerModalVisibilityChanged,
  $student,
} from '@/pages/common/modals/stedent-answer-modal/student-answer-modal.model'
import Answers from '@/pages/results/answer-types/Answers.vue'
import FileFieldBlock from '@/pages/common/modals/stedent-answer-modal/file-field-block/FileFieldBlock.vue'
import ScrollContainer from '@/ui/scroll-container/ScrollContainer.vue'
import { UploadMediaResponse } from '@/features/api/media/types'
import { downloadFileNoUrl } from '@/features/lib'

export default Vue.extend({
  name: 'StudentAnswerModal',
  components: {
    Modal,
    BaseButton,
    Answers,
    FileFieldBlock,
    ScrollContainer,
  },
  effector: {
    $comparingStudentAnswerModalVisibility,
    $studentAnswer,
    $student,
  },
  methods: {
    comparingStudentAnswerModalVisibilityChanged,
    getCorrectAnswerType(data: any) {
      switch (data.element_type) {
        case 'answer_file_field':
          return 'BROAD_FILE_ANSWER'
        case 'answer_text_field':
          return 'BROAD_OPEN_ANSWER'
        default:
          return data.task.type
      }
    },
    studentAnswer() {
      if (!this.$studentAnswer)
        return {
          type: null,
          files: null,
          question: null,
          answer: null,
          correctAnswer: null,
        }
      return {
        type: this.$studentAnswer.type,
        files: this.$studentAnswer.answer_files,
        question: this.$studentAnswer.question_data,
        answer: this.$studentAnswer.answer,
        correctAnswer: this.$studentAnswer.correct_answer,
      }
    },
    downloadAllFiles(files: UploadMediaResponse[]) {
      if (files && files.length) {
        files.forEach((file) => {
          downloadFileNoUrl(file.file, file.file_name)
        })
      }
    },
  },
  deactivated() {
    resetAnswers()
  },
})
