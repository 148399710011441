












import Toast from '@/features/toasts/Toast.vue'
import { $toasts } from '@/features/toasts/toasts.model'

export default {
  name: 'ToastsContainer',
  effector: {
    $toasts,
  },
  components: { Toast },
}
