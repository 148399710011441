import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams, TableDataResponse } from '@/features/api/types'
import { TestAssignment } from '@/features/api/assignment/types/test-assignments-types'
import { OlympiadAssignment } from '@/features/api/assignment/types/olympiad-assignments-types'
import { LessonAssignment } from '@/features/api/assignment/types/lesson-assignments-types'

export const getTestAssignmentListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<TestAssignment[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/assignment-app/test-assignment/list/',
    query,
  }),
})

export const getOlympiadAssignmentListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<OlympiadAssignment[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/assignment-app/olympiad-assignment/list/',
    query,
  }),
})

export const getLessonTasksAssignmentListFx = createApiEffect<
  GetListQueryParams,
  TableDataResponse<LessonAssignment[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/assignment-app/lesson-assignment/list/',
    query,
  }),
})
