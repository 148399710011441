














import Vue from 'vue'

export default Vue.extend({
  name: 'Tabs',
  props: {
    currentTab: { type: Number, required: true },
    /**
     * Массив табов:
     * @param {Array} tabsArr.
     * [{
     *   tab: number,
     *   title: string
     * }]
     */
    tabsArr: { type: Array, default: () => [] },
  },
})
