import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import { GetSubscribersOnEmailsListQueryParams, Subscriber } from '@/features/api/callback/types'

export const getSubscribersListFx = createApiEffect<
  GetSubscribersOnEmailsListQueryParams,
  TableDataResponse<Subscriber[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/user-app/subscription',
    query,
  }),
})
