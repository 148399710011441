



























import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $modalVisibility,
  $selectedIds,
  modalVisibilityChanged,
} from '@/pages/applications/modals/reject/reject.model'

export default Vue.extend({
  name: 'RejectModal',
  components: {
    Modal,
    BaseButton,
  },
  effector: {
    $modalVisibility,
    $selectedIds,
  },
  computed: {
    correctTitle() {
      return this.$selectedIds.length === 1 ? 'Заявка будет отклонена.' : 'Заявки будут отклонены.'
    },
  },
  methods: { modalVisibilityChanged },
})
