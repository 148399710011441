

















import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  $themes,
  themesDropdownModule,
  setSelectedTheme,
  loadFolderThemes,
  componentMounted,
  componentDestroyed,
} from '@/pages/common/dropdowns/themes-assigment-tree/themes-assigment-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  name: 'ThemesAssigmentDropdown',
  components: {
    FilterDropdown,
  },
  props: {
    isDisabled: { type: Boolean, default: false },
    isPreload: { type: Boolean, default: false },
    isShowPrerequisites: { type: Boolean, default: true },
    isResetDestroy: { type: Boolean, default: true },
  },
  effector: {
    $themes,
    ...themesDropdownModule.store,
  },
  methods: {
    ...themesDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedTheme(item)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    componentMounted()
    if (this.isPreload) loadFolderThemes()
  },
  beforeDestroy() {
    if (this.isResetDestroy) {
      componentDestroyed()
    }
  },
})
