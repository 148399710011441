import { createApiEffect } from '@/features/api/common/create-api-effect'
import { FinalText, UpdateTestGroupFxParams } from '@/features/api/test/types'
import { createEffect } from 'effector'

export const updateGroupsFx = createApiEffect<UpdateTestGroupFxParams, FinalText>({
  requestMapper: (data) => {
    const body = {
      available_from: data.available_from,
      available_to: data.available_to,
    }
    return {
      method: 'PUT',
      url: `/api/test-app/test-group/${data.testId}/${data.groupId}/`,
      body,
    }
  },
})

export const removeGroupsFx = createApiEffect<{ groupId: number; testId: number }>({
  requestMapper: (data) => ({
    method: 'DELETE',
    url: `/api/test-app/test-group/${data.testId}/${data.groupId}/`,
  }),
})

export const updateGroupsArrFx = createEffect(async (data: UpdateTestGroupFxParams[]) => {
  return Promise.all(
    data.map(async (textData) => {
      await updateGroupsFx(textData)
    })
  )
})

export const resendGroupsArrFx = createEffect(async (data: UpdateTestGroupFxParams[]) => {
  const dataIds = data.map((item) => item.groupId)
  const oldIds = data.length > 0 ? data[0].oldId : []
  const testId = data.length > 0 ? data[0].testId : 0
  const deleteItems = oldIds.filter((item) => !dataIds.includes(item.toString()))

  return Promise.all([
    ...data.map(async (group) => {
      await updateGroupsFx(group)
    }),
    ...deleteItems.map(async (deleteId) => {
      if (deleteId) {
        await removeGroupsFx({ groupId: deleteId, testId })
      }
    }),
  ])
})
