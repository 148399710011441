import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeletePartTimeTest } from '@/pages/exams/part-time-test/list/model/types'

export const requestDeletePartTimeTestFx = createApiEffect<RequestDeletePartTimeTest, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/exams-app/part-time/tests/bulk-delete/',
    body,
  }),
})
