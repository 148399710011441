


















import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { storageVideoVisibilityChanged } from '@/ui/wysiwyg/videos-storage.model'
import VideoStorage from '@/ui/wysiwyg/VideoStorage.vue'

export default Vue.component(
  'VideoOpenModal',
  Vue.extend({
    components: { VideoStorage, BaseButton },
    props: {
      value: { type: Object, default: null },
      depth: { type: String, default: '' },
    },
    data() {
      return {
        localValue: this.value || null,
        localDepth: this.depth || '',
        isModalOpen: false,
      }
    },
    watch: {
      value(newValue) {
        this.localValue = newValue // Обновляем локальное значение при изменении пропа
      },
    },
    methods: {
      storageVideoVisibilityChanged,
      onVideoSelected(val: any) {
        this.localValue = val ? { name: val.link, title: val.filename } : null
        this.$emit('input', this.localValue, this.depth)
      },
      clear() {
        this.localValue = null
        this.$emit('clear', this.depth)
      },
      toggleModal(val: boolean) {
        this.isModalOpen = val
      },
    },
  })
)
