

























import Vue, { PropType } from 'vue'
import TreeNode from '@/pages/exams/full-time-exam-test/list/parts/TreeNode.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { FiltersParams } from '@/pages/common/types'
import { $examsTree } from '@/pages/exams/full-time-exam-test/list/parts/exam-tests-page.model'

export default Vue.extend({
  name: 'ExamTestTree',
  components: {
    TreeNode,
    NoDataContent,
  },
  props: {
    filters: { type: Object as PropType<FiltersParams> },
  },
  effector: { $examsTree },
})
