




























import Vue from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import {
  $appointmentsCmsCommentary,
  $appointmentsIsProctoringActivated,
  $nameCmsCommentary,
  setAppointmentsCmsCommentary,
  setAppointmentsIsProctoringActivated,
  setNameCmsCommentary,
} from '@/pages/exams/full-appointments/create/model/form-fileds.model'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'

export default Vue.extend({
  name: 'FullAppointmentsPage1',
  components: {
    FormInput,
    BaseTextarea,
  },
  effector: {
    $nameCmsCommentary,
    $appointmentsCmsCommentary,
    $appointmentsIsProctoringActivated,
  },
  methods: {
    setNameCmsCommentary,
    setAppointmentsCmsCommentary,
    setAppointmentsIsProctoringActivated,
  },
})
