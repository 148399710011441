





































































































import Vue, { VueConstructor } from 'vue'
import { config } from '@/config'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import {
  searchFieldsData,
  outgoingDeletionApplicationsDataFields,
  getOutgoingDeletionTicketsActions,
} from '@/pages/applications/outgoing-deletion/constants'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import PageHeader from '@/pages/applications/outgoing-deletion/parts/header/PageHeader.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import ApplicationsFilter from '@/pages/applications/outgoing-deletion/parts/filter/Filter.vue'
import TooltipCell from '@/pages/applications/outgoing-deletion/parts/table/TooltipCell.vue'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  loadList,
  cancelApplicationFx,
  $canRefreshTable,
  outgoingDeletionPageParams,
  $isLoading,
  getTicket,
  exportTicketsDeletionList,
} from '@/pages/applications/outgoing-deletion/outgoing-deletion-applications-page.model'
import {
  toggleVisibility,
  $visibility,
  outgoingDeletionFilters,
} from '@/pages/applications/outgoing-deletion/parts/filter/filter.model'
import { $permissions } from '@/features/session'
import CancelModal from '@/pages/applications/modals/cancel/CancelModal.vue'
import { loadModal } from '@/pages/applications/modals/cancel/cancel.model'
import CommentModal from '@/pages/applications/modals/comment/CommentModal.vue'
import { loadCommentModal } from '@/pages/applications/modals/comment/comment.model'
import {
  CommonInteractedItemParams,
  ActionsItem,
  DisplayContextMenuPayload,
  RefsType,
} from '@/pages/common/types'
import { mapApplicationTypeToRoute } from '@/pages/applications/constants'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import { DEFAULT_ID } from '@/pages/common/constants'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { getActionsDisplayConditions } from '@/pages/common'
import { axiosClient } from '@/lib/request'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    NoDataContent,
    PageHeader,
    GeneralFilter,
    ApplicationsFilter,
    TableHeader,
    TooltipCell,
    ActionsButton,
    ContextMenu,
    Vuetable,
    VuetablePagination,
    CancelModal,
    CommentModal,
    LoaderBig,
  },
  effector: {
    $visibility,
    canRefreshAfterCancel: $canRefreshTable,
    $filterParams: outgoingDeletionFilters.store.$filterParams,
    $pageParams: outgoingDeletionPageParams.store.$pageParams,
    $currentPage: outgoingDeletionPageParams.store.currentPage,
    $isLoading,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID,
      contextMenuType: 'item' as ContextMenuType,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuItems: [] as ActionsItem[],
      searchFields: searchFieldsData,
      total: 1,
      fields: outgoingDeletionApplicationsDataFields,
      selectedRows: [] as number[],
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/ticket-app/deletion-ticket/list/?exclude_accepted=true`
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getOutgoingDeletionTicketsActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    canRefreshAfterCancel: {
      handler(newVal) {
        if (newVal) {
          this.$refs.vuetable.reload()
          this.removeSelection()
        }
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    changeFilter: outgoingDeletionFilters.methods.changeFilter,
    resetFilters: outgoingDeletionFilters.methods.resetFilters,
    applyFilters: outgoingDeletionFilters.methods.applyFilters,
    changePage: outgoingDeletionPageParams.methods.changePage,
    queryToParams: outgoingDeletionPageParams.methods.queryToParams,
    toggleVisibility,
    loadList,
    reset,
    cancelApplicationFx,
    exportTicketsDeletionList,
    async getCurrentTicketData(id: number) {
      const ticketData = await getTicket(id).then((res) => {
        return res.body
      })
      return ticketData
    },
    async openApplication() {
      const currentTicketData = await this.getCurrentTicketData(this.selectedIds[0])
      const taskType = currentTicketData.object_type
      const taskId = currentTicketData[taskType].id

      navigatePush({
        name: `${mapApplicationTypeToRoute[taskType]}-edit`,
        params: { id: `${taskId}` },
      })
    },
    cancelApplications() {
      loadModal(this.selectedIds)
    },
    showComment() {
      loadCommentModal(this.selectedIds[0])
    },
    myFetch(apiUrl: string, httpOptions: any) {
      return axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
      })
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      reset() // search string and field
      this.resetFilters()
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
      event.preventDefault()
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getOutgoingDeletionTicketsActions(
        displayConditions,
        this.$permissions!
      )
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  mounted() {
    loadList({})
  },
  destroyed() {
    this.resetFilters()
  },
})
