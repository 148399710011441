








































































































































import Vue, { VueConstructor } from 'vue'
import qs from 'qs'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import PageHeader from '@/pages/results/tests-students/parts/header/PageHeader.vue'
import ResultTestsFilter from '@/pages/results/tests-students/parts/filter/ResultStudentFilter.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import {
  combineRouteQueries,
  computeSortParam,
  isQueryParamsEquelToPage,
  removeHtmlTags,
} from '@/features/lib'
import { config } from '@/config'
import { $token } from '@/features/api/common/request'
import { $permissions } from '@/features/session'
import { DEFAULT_ID, mapTaskTypeTo } from '@/pages/common/constants'
import {
  studentsResultDataFields,
  getResultTestsStudentActions,
} from '@/pages/results/tests-students/constants'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  $canRefreshTable,
  incomingResultStudentsPageParams,
  canRefreshTableChanged,
  $isLoading,
  setNewTableFields,
  $tableFields,
  exportResultStudentsTestingsList,
  setIsLoading,
  saveResultStudentsCourse,
  $editData,
  setEditData,
  $testId,
} from '@/pages/results/tests-students/result-tests-student.model'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import {
  RefsType,
  CommonInteractedItemParams,
  DisplayContextMenuPayload,
  HttpOptionsFiltersType,
  ActionsItem,
} from '@/pages/common/types'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { resultTestsStudentsFilters } from '@/pages/results/tests-students/parts/filter/result-student-filter.model'
import { getActionsDisplayConditions } from '@/pages/common'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import { courseStudentResultItem } from './types'
import ComparingAnswerModal from '@/pages/common/modals/comparing-answer-modal/ComparingAnswerModal.vue'
import {
  comparingAnswerModalVisibilityChanged,
  $studentAnswer,
  $correctAnswer,
  setStudentAnswer,
  setCorrectAnswer,
} from '@/pages/common/modals/comparing-answer-modal/comparing-answer-modal.model'
import Answers from '@/pages/results/answer-types/Answers.vue'
import { axiosClient } from '@/lib/request'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    PageHeader,
    ResultTestsFilter,
    TableHeader,
    ActionsButton,
    ContextMenu,
    Vuetable,
    VuetablePagination,
    NoDataContent,
    LoaderBig,
    TooltipCell,
    ComparingAnswerModal,
    Answers,
  },
  effector: {
    $token,
    $canRefreshTable,
    $filterParams: resultTestsStudentsFilters.store.$filterParams,
    $pageParams: incomingResultStudentsPageParams.store.$pageParams,
    $currentPage: incomingResultStudentsPageParams.store.currentPage,
    $isLoading,
    $tableFields,
    $permissions,
    $editData,
    test: $testId,
    $studentAnswer,
    $correctAnswer,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID as number,
      total: 1,
      fields: studentsResultDataFields,
      selectedRows: [] as number[],
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuType: 'item' as ContextMenuType,
      contextMenuItems: [] as ActionsItem[],
      currentEditMark: 0,
      currentEditComment: 0,
      studentId: 0,
    }
  },
  computed: {
    isFilters(): boolean {
      return (
        'student' in this.$filterParams &&
        'test' in this.$filterParams &&
        'sample' in this.$filterParams
      )
    },
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/results-app/results/test/students/`
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getResultTestsStudentActions(displayConditions, this.$permissions!)
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
  },
  watch: {
    $canRefreshTable: {
      handler(newVal) {
        if (newVal === true) {
          this.$refs.vuetable.reload()
          canRefreshTableChanged(false)
        }
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    changeFilter: resultTestsStudentsFilters.methods.changeFilter,
    resetFilters: resultTestsStudentsFilters.methods.resetFilters,
    applyFilters: resultTestsStudentsFilters.methods.applyFilters,
    removeParamsFilter: resultTestsStudentsFilters.methods.removeParamsFilter,
    changePage: incomingResultStudentsPageParams.methods.changePage,
    queryToParams: incomingResultStudentsPageParams.methods.queryToParams,
    setNewTableFields,
    reset,
    exportResultStudentsTestingsList,
    setIsLoading,
    saveResultStudentsCourse,
    comparingAnswerModalVisibilityChanged,
    async myFetch(apiUrl: string, httpOptions: HttpOptionsFiltersType) {
      const { sample, student, test, ...otherParams } = httpOptions.params
      const url = `${apiUrl}${student}/${test}/${sample}/`
      if (!sample || !student || !test) {
        return new Promise((resolve) => {
          resolve({ data: [] })
        })
      }
      this.studentId = student ? Number(student) : 0
      const request = axiosClient.get(url, {
        params: { ...otherParams, sort: computeSortParam(otherParams?.sort || '') },
        paramsSerializer(params) {
          const modParams = { ...params }
          return qs.stringify(modParams, { arrayFormat: 'comma' })
        },
      })
      return request
    },
    onFilterSet() {
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.resetFilters()
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (res && !res.response) {
        noInternetToastEvent()
      }
    },
    handleRightClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
      event.preventDefault()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      if (res.event.target.closest('.button-edit')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    clearWording(str: string) {
      if (str) return removeHtmlTags(str)
      return ''
    },
    getComment(data: courseStudentResultItem) {
      let str = data.commentary
      if (data.id in this.$editData) {
        str = this.$editData[data.id].commentary
      }
      if (str) return removeHtmlTags(str)
      return '-'
    },
    tableActionsButtonClick(event: MouseEvent, id: number, element_type: string) {
      this.handleActionsClick({
        data: { id, element_type },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getResultTestsStudentActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    getSendItemObject(data: courseStudentResultItem) {
      if (data.id in this.$editData) {
        return this.$editData[data.id]
      }
      return {
        answer_obj_id: data.answer_obj?.id || 0,
        question: data.id,
        student: this.studentId,
        score: data.score,
        mark: data.mark,
        commentary: data.commentary || '',
        status: data.status || '',
      }
    },
    onEditMark(data: courseStudentResultItem) {
      setEditData({ ...this.$editData, [data.id]: this.getSendItemObject(data) })
      this.currentEditMark = data.id
    },
    handleUpdateMark(val: number, data: courseStudentResultItem) {
      const { id } = data
      setEditData({ ...this.$editData, [id]: { ...this.$editData[id], mark: val } })
    },
    hideMarkInput() {
      this.currentEditMark = 0
    },
    onEditComment(data: courseStudentResultItem) {
      setEditData({ ...this.$editData, [data.id]: this.getSendItemObject(data) })
      this.currentEditComment = data.id
    },
    handleUpdateComment(val: string) {
      if (this.currentEditComment) {
        const id = this.currentEditComment
        setEditData({ ...this.$editData, [id]: { ...this.$editData[id], commentary: val } })
      }
    },
    hideCommentInput() {
      this.currentEditComment = 0
    },
    saveEditInfo() {
      saveResultStudentsCourse(Object.values(this.$editData))
    },
    getCorrectIconType(type: string) {
      return mapTaskTypeTo[type].icon
    },
    getCorrectDescriptionType(type: string) {
      return mapTaskTypeTo[type].description
    },
    editTest() {
      this.$router.push({ name: 'test-edit-page', params: { id: `${this.test}` } })
    },
    unique(arr: string[]): string[] {
      const result: string[] = []

      for (const str of arr) {
        if (!result.includes(str)) {
          result.push(str)
        }
      }
      return result
    },
    viewAssignment() {
      const currentItem = this.$refs.vuetable.tableData.filter((item: { id: number }) =>
        this.selectedIds.includes(item.id)
      )
      this.$router.push({
        name: 'preview-task',
        query: {
          questions: this.unique(
            currentItem.map((item: { assignment: string }) => item.assignment)
          ).join(','),
          fromPage: 'tasks',
          taskType: 'test-assignment',
          token: this.$token,
        },
      })
    },
    editAssignment() {
      const currentItem = this.$refs.vuetable.tableData.filter((item: { id: number }) =>
        this.selectedIds.includes(item.id)
      )
      this.$router.push({
        name: 'test-tasks-edit',
        params: {
          id: currentItem[0].assignment,
        },
        query: {
          questions: `${currentItem
            .map((item: { assignment: string }) => item.assignment)
            .join(',')}`,
        },
      })
    },
    compareAnswers() {
      const currentItem = this.$refs.vuetable.tableData.filter((item: { id: number }) =>
        this.selectedIds.includes(item.id)
      )
      if (currentItem && currentItem.length > 0) {
        setStudentAnswer(currentItem[0])
        setCorrectAnswer(currentItem[0])
        comparingAnswerModalVisibilityChanged(true)
      }
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
    reset()
  },
})
