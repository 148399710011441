



































import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $requestDeleteModalVisibility,
  requestDeleteModalVisibilityChanged,
  $comment,
  commentChanged,
  $selectedIds,
} from '@/pages/common/modals/request-delete/request-delete-modal.model'

export default Vue.extend({
  name: 'DeleteRequestModal',
  components: {
    Modal,
    BaseTextarea,
    BaseButton,
  },
  props: {
    title: {
      type: String,
      default: 'Будет отправлена заявка на удаление. <br> Продолжить?',
    },
  },
  effector: {
    $selectedIds,
    $requestDeleteModalVisibility,
    $comment,
  },
  methods: {
    requestDeleteModalVisibilityChanged,
    commentChanged,
  },
})
