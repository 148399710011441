import ClassDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import SubjectDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import ThemesDropdown from '@/pages/common/dropdowns/themes-tree/ThemesDropdown.vue'
import TypeDropdown from '@/pages/dictionary/resources/list/parts/resources-filter/parts/type/TypeDropdown.vue'

export const dropdownComponents = {
  ClassDropdown,
  SubjectDropdown,
  ThemesDropdown,
  TypeDropdown,
}
