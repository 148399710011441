














import Vue, { VueConstructor } from 'vue'
import VueEvents from 'vue-events'
import { RefsType } from '@/pages/common/types'
import CreateCertificatesTemplateHeader from '@/pages/results/certificates-generator/create-template/CreateCertificatesTemplateHeader.vue'
import CreateCertificatesTemplateFooter from '@/pages/results/certificates-generator/create-template/CreateCertificatesTemplateFooter.vue'
import CreateCertificatesTemplateForm from '@/pages/results/certificates-generator/create-template/CreateCertificatesTemplateForm.vue'
import { $templateName } from '@/pages/results/certificates-generator/create-template/model/form-fields.model'

Vue.use(VueEvents)

export default (Vue as VueConstructor<Vue & { $refs: RefsType }>).extend({
  name: 'CertificatesGeneratorCreateTemplate',
  components: {
    CreateCertificatesTemplateForm,
    CreateCertificatesTemplateFooter,
    CreateCertificatesTemplateHeader,
  },
  effector: {
    $templateName,
  },
  computed: {
    disabledCreateTemplate() {
      return !this.$templateName.length
    },
  },
  methods: {
    saveAndBackToList() {
      console.log('click: ', $templateName.getState())
    },
  },
})
