


























































































































































































import Vue, { PropType } from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import ElementTypesDropdown from '@/pages/common/dropdowns/add-assignment/ElementTypesDropdown.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Wysiwyg from '@/ui/wysiwyg/Wysiwyg.vue'
import Icon from '@/ui/icon/Icon.vue'
import Draggable from 'vuedraggable'
import { changeMainBlocksPagesPosition } from '@/pages/exams/full-time-exam-test/create/model/form-fields.model'
import ExamAssignmentContent from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/ExamAssignmentContent.vue'
import { ElementTypeNames } from '@/pages/learning/learning-lessons/create/model/types'
import { OptionalExamElements, UnitType } from '@/features/api/full-time-tests/types'
import { DropdownItem } from '@/pages/common/types'
import VideoOpenModal from '@/pages/common/dropdowns/video/VideoOpenModal/VideoOpenModal.vue'

export default Vue.extend({
  name: 'ExamTestPage',
  components: {
    VideoOpenModal,
    ExamAssignmentContent,
    FormInput,
    BaseButton,
    ElementTypesDropdown,
    Draggable,
    Icon,
    Wysiwyg,
  },
  props: {
    pageName: { type: String },
    pageElements: { type: Array as PropType<OptionalExamElements[]> },
    pageId: { type: Number },
    blockId: { type: Number },
  },
  data: () => ({
    blockType: ElementTypeNames,
    filterElement: [ElementTypeNames.link, ElementTypeNames.file],
    currentElement: null as DropdownItem | null,
  }),
  methods: {
    handlerBlockPosition(e: { moved: { oldIndex: number; newIndex: number } }) {
      changeMainBlocksPagesPosition({
        newIndex: e.moved.newIndex,
        oldIndex: e.moved.oldIndex,
        pageId: this.pageId,
        blockId: this.blockId,
      })
    },
    changeFieldValue(type: UnitType, depth: string, value: number | string | any, field: string) {
      this.$emit('onPageFieldChange', type, depth, value, field)
    },
    deleteUnit(type: UnitType, depth: string) {
      this.$emit('onDelete', type, depth)
      this.$forceUpdate()
    },
    onAddUnit(type: UnitType, depth: string) {
      if (this.currentElement === null && type === 'element') return
      this.$emit('onAdd', type, depth, this.currentElement?.name)
      this.currentElement = null
      this.$forceUpdate()
    },
    setCurrentItem(item: DropdownItem | null) {
      this.currentElement = item
    },
  },
})
