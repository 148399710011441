import { createEvent, restore } from 'effector-root'
import { AttemptResultExtraFilterElement } from '@/pages/common/modals/attempts-filter/type'
import { reset } from '@/pages/common/general-filter/general-filter.model'

export const attemptsFilterModalVisibilityChanged = createEvent<boolean>()
export const $attemptsFilterModalModalVisibility = restore(
  attemptsFilterModalVisibilityChanged,
  false
)
export const saveFiltersChanged = createEvent<boolean>()
export const $saveFilters = restore(saveFiltersChanged, false).reset(reset)

export const resetFilterBlock = createEvent<boolean>()

export const filterBlockChanged = createEvent<AttemptResultExtraFilterElement[]>()
export const $filterBlock = restore(filterBlockChanged, [
  new AttemptResultExtraFilterElement(),
]).reset(resetFilterBlock, reset)
