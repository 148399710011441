import { createEvent, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { dropdownResultsTestsAllModules } from '@/pages/results/tests-all/parts/filter/parts/dropdown-modules'
import { reset } from '@/pages/common/general-filter/general-filter.model'

export const resultTestsAllFilters = createFiltersModel({}, dropdownResultsTestsAllModules)

export const setDateStarted = createEvent<string | null>()
export const $dateStarted = restore(setDateStarted, null).reset(reset)

export const setDateFinished = createEvent<string | null>()
export const $dateFinished = restore(setDateFinished, null).reset(reset)
