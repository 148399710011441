import { attach, combine, createEvent, forward, restore, sample, createEffect } from 'effector-root'
import { condition, spread } from 'patronum'
import { DEFAULT_ID } from '@/pages/common/constants'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { uploadMediaFx } from '@/features/api/media/upload-media'
import {
  $selectedType,
  setSelectedType,
  resetSelectedType,
  typeDropdownModule,
} from '@/pages/common/dropdowns/content/onboarding/type/type-dropdown.model'
import { getSlideFx } from '@/features/api/content/onboarding/get-slide'
import { UploadMediaResponse } from '@/features/api/media/types'
import { updateSlideFx } from '@/features/api/content/onboarding/update-slide'

export const loadSlideFx = attach({
  effect: getSlideFx,
})
const saveSlideFx = attach({
  effect: updateSlideFx,
})
const uploadMediaResourceFx = attach({
  effect: uploadMediaFx,
})

export const clearFields = createEvent<void>()

export const create = createEvent<void>()

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

export const resetName = createEvent<void>()
export const nameChanged = createEvent<string>()
export const $name = restore(nameChanged, '').reset(resetName)

export const resetTitle = createEvent<void>()
export const titleChanged = createEvent<string>()
export const $title = restore(titleChanged, '').reset(resetTitle)

export const resetText = createEvent<void>()
export const textChanged = createEvent<string>()
export const $text = restore(textChanged, '').reset(resetText)

const setSlideId = createEvent<number>()
const $slideId = restore(setSlideId, DEFAULT_ID)

export const resetMediaFile = createEvent<void>()
export const setMediaFile = createEvent<UploadMediaResponse | null>()
export const $mediaFile = restore(setMediaFile, null).reset(resetMediaFile)

export const toggleIsActive = createEvent<boolean>()
export const $isActive = restore(toggleIsActive, false).reset(clearFields)

export const toggleShowTitle = createEvent<boolean>()
export const $showTitle = restore(toggleShowTitle, false).reset(clearFields)

export const toggleShowText = createEvent<boolean>()
export const $showText = restore(toggleShowText, false).reset(clearFields)

export const toggleShowMedia = createEvent<boolean>()
export const $showMedia = restore(toggleShowMedia, false).reset(clearFields)

forward({
  from: clearFields,
  to: [
    resetName,
    resetTitle,
    resetText,
    resetMediaFile,
    typeDropdownModule.methods.resetDropdown,
    resetSelectedType,
  ],
})

export const $disabledSaveButtons = combine(
  $title,
  $name,
  $text,
  $selectedType,
  $mediaFile,
  (title, name, text, type, media) =>
    !title || !name || !text || !type?.name || (type.name !== 'void' && !media)
)

export const $formToSend = combine({
  id: $slideId,
  name: $name,
  title: $title,
  text: $text,
  media_id: $mediaFile.map((data) => (data && data!.id) || null),
  active: $isActive,
  show_title: $showTitle,
  show_text: $showText,
  show_media: $showMedia,
})

spread({
  source: loadSlideFx.doneData.map((res) => res.body),
  targets: {
    id: setSlideId,
    name: nameChanged,
    title: titleChanged,
    text: textChanged,
    active: toggleIsActive,
    show_title: toggleShowTitle,
    show_text: toggleShowText,
    show_media: toggleShowMedia,
    media: setMediaFile,
  },
})

sample({
  clock: loadSlideFx.doneData.map((res) => res.body),
  fn: (slide) => {
    if (slide.media) {
      const name = slide.media.file_type
      const title = name === 'img' ? 'Изображение' : 'Видео'
      return { name, title }
    }
    return { name: 'void', title: 'Не указано' }
  },
  target: [setSelectedType, typeDropdownModule.methods.itemChanged],
})

sample({
  clock: create,
  source: $formToSend,
  target: saveSlideFx,
})

const $ifRedirectTheme = sample({
  clock: saveSlideFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

forward({
  from: saveSlideFx.doneData,
  to: successToastEvent('Слайд успешно обновлен!'),
})

condition({
  source: $ifRedirectTheme,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'slides-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'slides-edit',
    params: { id: `${payload.id}` },
  })),
})

export const uploadResource = createEvent<FileList>()

const uploadResourcesFx = createEffect<FileList, void>({
  handler: (files) => {
    Array.from(files).forEach((file) => {
      const data = new FormData()
      data.append('file', file)
      const type = file.type.includes('image') ? 'img' : 'video'
      data.append('file_type', type)
      uploadMediaResourceFx(data)
    })
  },
})

forward({
  from: uploadResource,
  to: uploadResourcesFx,
})

forward({
  from: uploadMediaResourceFx.doneData.map((res) => res.body),
  to: setMediaFile,
})
