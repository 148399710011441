import { createEvent, createStore, guard } from 'effector-root'
import { resetFields } from '@/pages/learning/learning-lessons/create/model/form-fileds.model'
import { getLessonByIdFx } from '@/features/api/learning/lessons/lesson-by-id-end-points'

export const changeLessonId = createEvent<string | never>()

export const $editedLessonId = createStore<string>('')
  .on(changeLessonId, (state, payload) => payload || state)
  .reset(resetFields)

guard({
  source: changeLessonId,
  filter: (payload) => !!payload,
  target: getLessonByIdFx,
})
