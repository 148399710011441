import { createEvent, forward, attach, sample } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { debounce } from 'patronum'
import { TestList } from '@/features/api/test/types'
import { getExamsTestsListFx } from '@/features/api/part-time-test/get-part-time-list'

const getTests = attach({
  effect: getExamsTestsListFx,
})

export const testsDropdownModel = createDropdownModel<TestList>(getTests)

export const loadTests = createEvent<void>()

export const searchTests = createEvent<void>()

sample({
  clock: loadTests,
  source: {
    $nextPage: testsDropdownModel.store.$nextPage,
    search: testsDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search }),
  }),
  target: getTests,
})

sample({
  clock: searchTests,
  source: {
    search: testsDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search && { search: params.search }),
  }),
  target: getTests,
})

const debounced = debounce({
  source: testsDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchTests,
})

forward({
  from: testsDropdownModel.methods.canLoadNextPage,
  to: loadTests,
})

sample({
  clock: getTests.doneData,
  source: {
    items: testsDropdownModel.store.$items,
    $nextPage: testsDropdownModel.store.$nextPage,
    search: testsDropdownModel.store.$searchString,
  },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((field) => ({
      name: `${field.id}`,
      title: `(${field.id}) ${field.name}`,
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: testsDropdownModel.store.$items,
})
