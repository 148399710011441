import { createApiEffect } from '@/features/api/common/create-api-effect'
import { InstructionCreateFxParams, InstructionCreateType } from '@/features/api/instructions/types'

export const updateInstructionsFx = createApiEffect<
  { body: InstructionCreateType; id: string },
  InstructionCreateFxParams
>({
  requestMapper: ({ body, id }) => ({
    method: 'PATCH',
    url: `/api/assignment-app/olympiad-instructions/${id}/`,
    body,
  }),
})
