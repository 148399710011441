import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExamTestType } from '@/features/api/full-time-tests/types'

export const editExamTestFx = createApiEffect<
  { body: Partial<ExamTestType>; id: string },
  { id: number }
>({
  requestMapper: ({ body, id }) => ({
    method: 'PUT',
    url: `/api/exams-app/tests/${id}/`,
    body,
  }),
})
