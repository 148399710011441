import { createEvent, forward, restore, sample, split } from 'effector-root'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { condition } from 'patronum'
import {
  $editedInstructionId,
  changeInstructionsId,
} from '@/pages/dictionary/instructions/create/model/edit-instructions.model'
import { $instructionSendForm } from '@/pages/dictionary/instructions/create/model/form-fileds.model'
import { updateInstructionsFx } from '@/features/api/instructions/patch-edit-instructon'
import { createInstructionsFx } from '@/features/api/instructions/post-new-instructions'

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  createInstructionsFx.fail,
])

export const getPageParams = createEvent()

const createInstruction = createEvent()
const updateInstruction = createEvent<string>()

const instructionId = sample({
  source: $editedInstructionId,
  clock: getPageParams,
  fn: (id) => (id.length > 0 ? id : false),
})

split({
  source: instructionId,
  match: {
    post: (payload) => payload === false,
    patch: (payload) => payload !== false,
  },
  cases: {
    post: createInstruction,
    patch: updateInstruction,
  },
})

sample({
  source: $instructionSendForm,
  clock: createInstruction,
  fn: (form) => form,
  target: createInstructionsFx,
})

sample({
  source: $instructionSendForm,
  clock: updateInstruction,
  fn: (form, id) => ({ body: form, id }),
  target: updateInstructionsFx,
})

forward({
  from: createInstructionsFx.done,
  to: successToastEvent('Инструкция создана'),
})

forward({
  from: updateInstructionsFx.done,
  to: successToastEvent('Инструкция изменена'),
})

forward({
  from: [createInstructionsFx.fail, updateInstructionsFx.fail],
  to: errorToastEvent('Что-то пошло не так'),
})

const $redirectHandler = sample({
  clock: [
    createInstructionsFx.doneData.map((res) => res.body.id),
    updateInstructionsFx.doneData.map((res) => res.body.id),
  ],
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'instructions-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => {
    changeInstructionsId(String(payload.id))
    return {
      name: 'instruction-edit',
      params: { id: `${payload.id}` },
    }
  }),
})
