import { createEvent, forward, restore, sample, split } from 'effector-root'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { condition } from 'patronum'
import {
  $editedPartTimeTestId,
  changePartTimeTestId,
} from '@/pages/exams/part-time-test/create/model/edit-part-time-test.model'
import { $partTimeSendForm } from '@/pages/exams/part-time-test/create/model/form-fileds.model'
import { createPartTimeFx } from '@/features/api/part-time-test/post-new-part-time'
import { updatePartTimeFx } from '@/features/api/part-time-test/patch-edit-part-time'

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  createPartTimeFx.fail,
])

export const getPageParams = createEvent()

const createPartTimeTest = createEvent()
const updatePartTimeTest = createEvent<string>()

const proctorId = sample({
  source: $editedPartTimeTestId,
  clock: getPageParams,
  fn: (id) => (id.length > 0 ? id : false),
})

split({
  source: proctorId,
  match: {
    post: (payload) => payload === false,
    patch: (payload) => payload !== false,
  },
  cases: {
    post: createPartTimeTest,
    patch: updatePartTimeTest,
  },
})

sample({
  source: $partTimeSendForm,
  clock: createPartTimeTest,
  fn: (form) => form,
  target: createPartTimeFx,
})

sample({
  source: $partTimeSendForm,
  clock: updatePartTimeTest,
  fn: (form, id) => ({ body: form, id }),
  target: updatePartTimeFx,
})

forward({
  from: createPartTimeFx.done,
  to: successToastEvent('Экзаменационный тест создан'),
})

forward({
  from: updatePartTimeFx.done,
  to: successToastEvent('Экзаменационный тест изменен'),
})

forward({
  from: [createPartTimeFx.fail, updatePartTimeFx.fail],
  to: errorToastEvent('Что-то пошло не так'),
})

const $redirectHandler = sample({
  clock: [
    createPartTimeFx.doneData.map((res) => res.body.id),
    updatePartTimeFx.doneData.map((res) => res.body.id),
  ],
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

condition({
  source: $redirectHandler,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'part-time-exams-tests-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => {
    changePartTimeTestId(String(payload.id))
    return {
      name: 'part-time-exams-tests-edit',
      params: { id: `${payload.id}` },
    }
  }),
})
