import {
  attach,
  createEffect,
  createEvent,
  forward,
  restore,
  combine,
  sample,
  split,
} from 'effector-root'
import { successToastEvent } from '@/features/toasts/toasts.model'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { condition, debounce } from 'patronum'
import { requestDeleteModalVisibilityChanged } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { getThemesListFx } from '@/features/api/subject/get-themes-list'
import { getShowOnboardingFx } from '@/features/api/content/onboarding/get-show-onboarding'
import { patchShowOnboardingFx } from '@/features/api/content/onboarding/patch-show-onboarding'
import { setShowOnboardingFx } from '@/features/api/content/onboarding/set-show-onboarding'
import {
  deleteSlidesFx,
  requestDeleteSlidesFx,
} from '@/features/api/content/onboarding/delete-slides'
import { RequestDeleteSlidesParams } from '@/features/api/content/types'

const getSlidesList = attach({
  effect: getThemesListFx,
})

const updateShowOnboardingData = attach({
  effect: patchShowOnboardingFx,
})

export const getShowOnboarding = attach({
  effect: getShowOnboardingFx,
})

const createOnboardingFrontendCode = attach({
  effect: setShowOnboardingFx,
})

export const deleteSlides = createEffect({
  handler: (ids: number[]): Promise<number[]> => {
    return new Promise((resolve) => {
      deleteSlidesFx(ids).then(() => {
        resolve(ids)
      })
    })
  },
})

export const requestDeleteSlides = attach({
  effect: requestDeleteSlidesFx,
  mapParams: (payload: RequestDeleteSlidesParams): RequestDeleteSlidesParams => {
    return {
      sliders: payload.sliders,
      ticket_comment: payload.ticket_comment?.trim() !== '' ? payload.ticket_comment : undefined,
    }
  },
})

export const setShowOnboarding = createEvent<boolean>()
export const toggleShowOnboarding = createEvent<boolean>()
export const $showOnboarding = restore(toggleShowOnboarding, false).on(
  setShowOnboarding,
  (_, payload) => payload
)

const debounced = debounce({
  source: toggleShowOnboarding,
  timeout: 150,
})

sample({
  clock: debounced,
  source: $showOnboarding,
  fn: (show_onboarding) => ({ frontend_code: 'onboarding', data: { show_onboarding } }),
  target: updateShowOnboardingData,
})

forward({
  from: getShowOnboardingFx.doneData.map((res) => res.body),
  to: setShowOnboarding.prepend(({ data }) => data.show_onboarding),
})
const { codeNotFound } = split(getShowOnboardingFx.failData, {
  codeNotFound: ({ status }) => status === 404,
})

sample({
  clock: codeNotFound,
  source: $showOnboarding,
  fn: (show_onboarding) => ({ frontend_code: 'onboarding', data: { show_onboarding } }),
  target: createOnboardingFrontendCode,
})

export const slidesPageParams = createPageParamsModel()

const showDeleteSlidesToast = createEvent<number[]>()

export const $isLoading = combine(getSlidesList.pending, (list) => list)

forward({
  from: deleteSlides.doneData,
  to: [confirmDeleteModalVisibilityChanged.prepend(() => false), showDeleteSlidesToast],
})

condition({
  source: showDeleteSlidesToast,
  if: (ids: number[]) => ids.length === 1,
  then: successToastEvent('Слайд был успешно удален!'),
  else: successToastEvent('Слайды были успешно удалены!'),
})

forward({
  from: requestDeleteSlides.doneData,
  to: [
    successToastEvent('Отправлена заявка на удаление'),
    requestDeleteModalVisibilityChanged.prepend(() => false),
  ],
})
