import { attach, createEvent, restore } from 'effector-root'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { applicationsCoursesFilters } from '@/pages/learning/learning-courses-application/list/parts/filter/applications-course-filter.model'
import { createStore } from 'effector'
import { TableField } from '@/pages/applications/types'
import { applicationsCoursesDataFields } from '@/pages/learning/learning-courses-application/constants'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { exportApplicationsCoursesListFx } from '@/features/api/learning/course-applications/export-courses-applications'
import { createTaskModule } from '@/features/api/tasks/tasks-module'
import dayjs from 'dayjs'

type SetColumnsColumns = {
  key?: boolean
}

export const incomingPageParams = createPageParamsModel()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore<boolean>(setIsLoading, false)

export const canRefreshTableChanged = createEvent<boolean>()
export const $canRefreshTable = restore(canRefreshTableChanged, false)

export const setNewTableFields = createEvent<SetColumnsColumns>()
export const setDefaultTableFields = createEvent<void>()
export const $tableFields = createStore<TableField[]>(applicationsCoursesDataFields)
  .on(setNewTableFields, (fields, settings) =>
    applicationsCoursesDataFields.filter((field) => settings[field.name])
  )
  .reset(setDefaultTableFields)

const exportApplicationsCoursesList = attach({
  effect: exportApplicationsCoursesListFx,
  source: [applicationsCoursesFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    const formatSensColumns = {
      fields: exportedColumns.fields.map((field: string) => {
        if (field === 'student') return 'study_year'
        if (field === 'studentId') return 'created_by.id'
        if (field === 'studentElkId') return 'created_by.elk_id'
        if (field === 'studentName') return 'created_by'
        if (field === 'questions') return 'questions.questions,questions.answers'
        return field
      }),
    }
    const formatedFilters = {}
    Object.keys(filters).forEach((key) => {
      if (key === 'accept_dt_from' || key === 'accept_dt_to') {
        if (filters[key]) {
          formatedFilters[key] = dayjs(filters[key]).toISOString()
        }
      } else {
        formatedFilters[key] = filters[key]
      }
    })
    return { ...formatedFilters, ...formatSensColumns }
  },
})

export const taskLoader = createTaskModule(exportApplicationsCoursesList, 'applicationCoursesList')
