import ClassesDropdown from '@/pages/common/dropdowns/class/ClassesDropdown.vue'
import SubjectsDropdown from '@/pages/common/dropdowns/subject/SubjectsDropdown.vue'
import DifficultyDropdown from '@/pages/common/dropdowns/bank/olympiad-tasks/difficulty-dropdown/DifficultyDropdown.vue'
import StatusDropdown from '@/pages/common/dropdowns/bank/olympiad-tasks/status-dropdown/StatusDropdown.vue'
import TypeDropdown from '@/pages/common/dropdowns/bank/olympiad-tasks/type-dropdown/TypeDropdown.vue'
import LanguageDropdown from '@/pages/common/dropdowns/bank/olympiad-tasks/language-dropdown/LanguageDropdown.vue'
import TagsDropdown from '@/pages/common/dropdowns/multiselectDropdown/tags-dropdown-filter/TagsFilterDropdown.vue'

export const dropdownComponents = {
  ClassesDropdown,
  SubjectsDropdown,
  DifficultyDropdown,
  StatusDropdown,
  TypeDropdown,
  LanguageDropdown,
  TagsDropdown,
}
