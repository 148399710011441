










import Vue from 'vue'
import FullAppointmentsPage1 from '@/pages/exams/full-appointments/create/parts/FullAppointmentsPage1.vue'
import FullAppointmentsPage2 from '@/pages/exams/full-appointments/create/parts/FullAppointmentsPage2.vue'
import FullAppointmentsPage3 from '@/pages/exams/full-appointments/create/parts/FullAppointmentsPage3.vue'

export default Vue.extend({
  name: 'CreateFullAppointmentsContent',
  components: {
    FullAppointmentsPage1,
    FullAppointmentsPage2,
    FullAppointmentsPage3,
  },
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    tabsArr: {
      type: Array,
      default: () => [],
    },
  },
})
