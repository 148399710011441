import { createEvent, forward, attach, restore, sample } from 'effector-root'
import { DropdownItem } from '@/pages/common/types'
import { GetListQueryParams } from '@/features/api/types'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { debounce } from 'patronum'
import { getForWhoFx } from '@/features/api/course/get-for-who'

const getForWho = attach({
  effect: getForWhoFx,
})

export const forWhoDropdownModel = createDropdownModel<{ value: string; display: string }>(
  getForWho as any
)

export const setSelectedForWho = createEvent<DropdownItem | null>()
export const $selectedForWho = restore(setSelectedForWho, null)

export const loadForWho = createEvent<void>()

export const searchDifficult = createEvent<void>()

sample({
  clock: loadForWho,
  source: {
    $nextPage: forWhoDropdownModel.store.$nextPage,
    search: forWhoDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search }),
  }),
  target: getForWho,
})

sample({
  clock: searchDifficult,
  source: {
    search: forWhoDropdownModel.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search && { search: params.search }),
  }),
  target: getForWho,
})

const debounced = debounce({
  source: forWhoDropdownModel.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchDifficult,
})

forward({
  from: forWhoDropdownModel.methods.canLoadNextPage,
  to: loadForWho,
})

sample({
  clock: getForWho.doneData,
  source: {
    items: forWhoDropdownModel.store.$items,
    $nextPage: forWhoDropdownModel.store.$nextPage,
    search: forWhoDropdownModel.store.$searchString,
  },
  fn: ({ items }, res) => {
    const newData = res.body.map((field) => ({
      name: `${field.value}`,
      title: field.display,
    }))
    return [...items, ...newData]
  },
  target: forWhoDropdownModel.store.$items,
})

forward({
  from: forWhoDropdownModel.methods.resetDropdown,
  to: setSelectedForWho.prepend(() => null),
})
