













































































import Vue, { VueConstructor } from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseTextarea from '@/ui/input/BaseTextarea.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FileElement from '@/pages/common/modals/applications/parts/FileElement.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
  $comment,
  commentChanged,
  $commentErrorModule,
  uploadFileFx,
  $imagesPreview,
  deleteMedia,
  checkIfFormCanBeSend,
  $ticketForm,
} from '@/pages/applications/modals/send-for-moderation/send-for-moderation.model'
import { RefsType } from '@/pages/common/types'
import { TEXTAREA_MAX_LENGTH } from '@/pages/applications/constants'

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    Modal,
    Icon,
    BaseTextarea,
    BaseButton,
    FileElement,
  },
  effector: {
    $modalVisibility,
    $comment,
    $imagesPreview,
    commentError: $commentErrorModule.store.$error,
    $ticketForm,
  },
  data() {
    return {
      textareaMaxLength: TEXTAREA_MAX_LENGTH,
    }
  },
  computed: {
    images() {
      return []
    },
  },
  methods: {
    modalVisibilityChanged,
    commentChanged,
    deleteMedia,
    checkIfFormCanBeSend,
    handleFileUpload() {
      const { fileInput } = this.$refs
      uploadFileFx(fileInput!.files)
    },
  },
})
