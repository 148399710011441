/*
 * legacy
 * Тут лежит двусторонняя привязка ckeditor и модели для
 * "Заданий с перемещением текста и картинок в текст"
 * */

import { createEffect } from 'effector-root'
import {
  DraggableImage,
  MovingOnTextDroppableImage,
  MovingOnTextDroppableInput,
} from '@/pages/common/parts/tasks/types'
import {
  inputsCounter,
  droppableImagesCounter,
} from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'
import { DEFAULT_ID } from '@/pages/common/constants'

export type InputTemplateFxType = {
  template: string
  oldTemplate: string
  droppableImages: MovingOnTextDroppableImage[]
  draggableImages: DraggableImage[]
  inputs: MovingOnTextDroppableInput[]
}

const getNumber = (str: any) => +str.replace(/^\D+/g, '') || 0

let checking = false

export const setMainTemplateFx = createEffect((data: InputTemplateFxType) => {
  if (data.oldTemplate === data.template || checking) {
    return Promise.reject()
  }
  checking = true
  let formattedTemplate = data.template

  function changeImagesBindings(images: DraggableImage[], deletedId: number) {
    images.forEach((img) => {
      if (img.value !== 0 && img.value > deletedId) {
        img.value -= 1
      } else if (img.value === deletedId) {
        img.value = 0
      }
    })
  }

  function updateIds(
    oldHtmlData: any[],
    htmlData: NodeListOf<HTMLInputElement>,
    newDataType: 'inputs' | 'images'
  ) {
    oldHtmlData.forEach((el) => {
      const placeholder = getNumber(el.getAttribute('placeholder'))
      // есть в прошлом массиве, но нет в текущем
      const oldIndex = Object.values(htmlData).findIndex(
        (item) => getNumber(item.getAttribute('placeholder')) === placeholder
      )
      if (oldIndex === DEFAULT_ID) {
        // обновляем сопоставления с картинками
        if (newDataType === 'images') {
          changeImagesBindings(data.draggableImages, placeholder)
        }
        // обновляем placeholder в html структуре
        Object.values(htmlData)
          .filter((item) => getNumber(item.getAttribute('placeholder')) > placeholder)
          .map((item) => {
            const oldId = getNumber(item.getAttribute('placeholder')) - 1
            const newId = newDataType === 'images' ? `A${oldId}` : `B${oldId}`
            formattedTemplate = formattedTemplate.replace(
              item.getAttribute('placeholder') || '',
              newId
            )
            return item.setAttribute('placeholder', newId)
          })
        /*
          Поведение кода внутри блока ниже крайне не очевидно, измененный массив никуда не присваивается,
          а меняются постфиксным дикрементом (--) только данные id и value внутри исходного массива
        */
        if (newDataType === 'images') {
          // обновляем id в data.droppableImages
          data.droppableImages
            .filter((item) => item.id! > placeholder)
            .map((item) => {
              return {
                ...item,
                id: item.id!--,
                value: item.value!--,
              }
            })
        } else {
          // обновляем id в data.inputs
          data.inputs
            .filter((item) => item.id! > placeholder)
            .map((item) => ({
              ...item,
              id: item.id!--,
            }))
        }
      }
    })
  }

  const container: HTMLElement = document.createElement('DIV')
  container.innerHTML = data.template

  const oldContainer: HTMLElement = document.createElement('DIV')
  oldContainer.innerHTML = data.oldTemplate

  const htmlInputs = container.querySelectorAll<HTMLInputElement>('.redactor-input')
  const oldHtmlInputs = oldContainer.querySelectorAll<HTMLInputElement>('.redactor-input')
  const reversedOldHtmlInputs = Array.prototype.slice.call(oldHtmlInputs).reverse()
  const newInputs: MovingOnTextDroppableInput[] = []

  htmlInputs.forEach((input) => {
    const placeholder = getNumber(input.getAttribute('placeholder'))
    // уже есть в новом массиве
    const newInputsIndex = newInputs.findIndex((item) => item.id === placeholder)
    if (newInputsIndex !== DEFAULT_ID) {
      const copy = JSON.parse(JSON.stringify(newInputs[newInputsIndex]))
      const id = inputsCounter.next()
      newInputs.push({
        ...copy,
        id,
      })
      formattedTemplate = formattedTemplate.replace(
        input.getAttribute('placeholder') || '',
        `B${id}`
      )
      return
    }
    // есть в прошлом массиве
    const oldInputsIndex = data.inputs.findIndex((item) => item.id === placeholder)
    if (oldInputsIndex !== DEFAULT_ID) {
      newInputs.push(data.inputs[oldInputsIndex])
      return
    }
    newInputs.push({
      size: {
        width: 100,
        height: 20,
      },
      value: [
        {
          value: '',
          id: 1,
        },
      ],
      id: placeholder,
      color: '#000',
    })
  })

  updateIds(reversedOldHtmlInputs, htmlInputs, 'inputs')

  const htmlImages = container.querySelectorAll<HTMLInputElement>('.redactor-drop')
  const oldHtmlImages = oldContainer.querySelectorAll<HTMLInputElement>('.redactor-drop')
  const reversedOldHtmlImages = Array.prototype.slice.call(oldHtmlImages).reverse()
  const newImages: MovingOnTextDroppableImage[] = []

  htmlImages.forEach((image) => {
    const placeholder = getNumber(image.getAttribute('placeholder'))
    // уже есть в новом массиве
    const newImagesIndex = newImages.findIndex((item) => item.id === placeholder)
    if (newImagesIndex !== DEFAULT_ID) {
      const copy = JSON.parse(JSON.stringify(newImages[newImagesIndex]))
      const id = droppableImagesCounter.next()
      newImages.push({
        ...copy,
        value: id,
        id,
      })
      formattedTemplate = formattedTemplate.replace(
        image.getAttribute('placeholder') || '',
        `A${id}`
      )
      return
    }
    // есть в прошлом массиве
    const oldImagesIndex = data.droppableImages.findIndex((item) => item.id === placeholder)
    if (oldImagesIndex !== DEFAULT_ID) {
      newImages.push(data.droppableImages[oldImagesIndex])
      return
    }
    newImages.push({
      size: {
        width: 30,
        height: 30,
      },
      pin: {
        x: 0,
        y: 0,
      },
      value: placeholder,
      id: placeholder,
      color: '#000',
    })
  })
  updateIds(reversedOldHtmlImages, htmlImages, 'images')

  checking = false
  const nextTemplate = formattedTemplate.split(`type="text">`).join(`type="text" />`)
  return {
    template: nextTemplate,
    inputs: newInputs,
    images: newImages,
  }
})

const setStyles = (element: HTMLElement, styles: any) => {
  Object.entries(styles).forEach(([key, style]) => {
    element.style[key] = style
  })
}

export const generateNewTemplateFx = createEffect((data: InputTemplateFxType) => {
  const container: HTMLElement = document.createElement('DIV')
  container.innerHTML = data.template

  const htmlInputs = container.querySelectorAll<HTMLInputElement>('.redactor-input')

  htmlInputs.forEach((htmlInput) => {
    const placeholder = getNumber(htmlInput.getAttribute('placeholder'))
    const input = data.inputs.find((item) => item.id === placeholder)
    if (input) {
      setStyles(htmlInput, {
        width: `${input.size.width}px`,
        height: `${input.size.height}px`,
        color: input.color,
      })
      return
    }
    htmlInput.remove()
  })

  const htmlImages = container.querySelectorAll<HTMLInputElement>('.redactor-drop')
  htmlImages.forEach((htmlImage) => {
    const placeholder = getNumber(htmlImage.getAttribute('placeholder'))
    const image = data.droppableImages.find((item) => item.id === placeholder)
    if (image) {
      setStyles(htmlImage, {
        width: `${image.size.width}px`,
        height: `${image.size.height}px`,
        color: image.color,
      })
      return
    }
    htmlImage.remove()
  })

  return container.innerHTML.split(`type="text">`).join(`type="text" />`)
})
