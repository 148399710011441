

































































import Vue, { PropType } from 'vue'
import StartToEndDatetime from '@/pages/learning/learning-courses/parts/inputs/datetime-picker/StartToEndDatetime.vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import Icon from '@/ui/icon/Icon.vue'
import { DatetimeLessonsGroup } from '@/features/api/learning/types'
import {
  loadGroups,
  groupDropdownModule,
} from '@/pages/testing/tests/common/parts/groups-test-block/tests-groups-block.model'
import { GroupTestsType } from '@/pages/testing/tests/types'

export default Vue.extend({
  name: 'TestsGroupsBlock',
  components: {
    StartToEndDatetime,
    FilterDropdown,
    Icon,
  },
  props: {
    groups: {
      type: Array as PropType<GroupTestsType[]>,
      default: () => [],
    },
  },
  effector: {
    ...groupDropdownModule.store,
  },
  computed: {
    correctValue(): string {
      const currentItem = this.$item?.title
      return currentItem || this.$searchString
    },
  },
  methods: {
    loadGroups,
    ...groupDropdownModule.methods,
    onSetDatetime(datetimes: DatetimeLessonsGroup, group: GroupTestsType) {
      this.$emit('changeGroup', {
        ...group,
        available_from: datetimes.start,
        available_to: datetimes.end,
      })
    },
    setSelectedGroup(group: GroupTestsType | null) {
      if (group) this.$emit('addGroup', group)
    },
    clear() {
      this.resetItem()
      this.resetSearchString()
    },
    addGroup() {
      this.$emit('addGroup')
    },
    removeGroup(id: number) {
      this.$emit('removeGroup', id)
    },
  },
  mounted() {
    if (this.$itemsDropdown.length === 0) loadGroups()
  },
})
