import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'

export const getGroupsActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const actions = [
    {
      name: 'delete',
      title: 'Удалить',
      action: 'onRemoveGroups',
      isDisplayed:
        displayOptions.isTableItem ||
        displayOptions.isTableItems ||
        displayOptions.isTreeItem ||
        displayOptions.isHeaderItem ||
        displayOptions.isHeaderItems,
      hasPermissionToSee: permissions.users_group_groups.can_edit,
    },
    {
      name: 'editGroup',
      title: 'Редактировать',
      action: 'onEditGroup',
      isDisplayed:
        displayOptions.isTableItem || displayOptions.isHeaderItem || displayOptions.isTreeItem,
      hasPermissionToSee: permissions.users_group_groups.can_edit,
    },
    {
      name: 'removeSelection',
      title: 'Снять выделение',
      action: 'onRemoveSelection',
      isDisplayed: displayOptions.isHeaderItems,
      hasPermissionToSee: true,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
