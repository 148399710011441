import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteNews } from '@/features/api/content/types'

export const requestDeleteNewsFx = createApiEffect<RequestDeleteNews, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/content-app/news/list/bulk-delete/',
    body,
  }),
})
