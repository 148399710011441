import { typesDropdownModule } from '@/pages/applications/incoming-deletion/parts/filter/parts/type-dropdown/types-dropdown.model'
import { statusDropdownModule } from '@/pages/applications/incoming-deletion/parts/filter/parts/status-dropdown/status-dropdown.model'
import { creatorsDropdownModel } from '@/pages/common/dropdowns/application/creators/creators-dropdown.model'
import { moderatorsDropdownModel } from '@/pages/common/dropdowns/application/moderators/moderators-dropdown.model'

export const dropdownModules = {
  typesDropdownModule,
  statusDropdownModule,
  moderatorsDropdownModel,
  creatorsDropdownModel,
}
