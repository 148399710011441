import { render, staticRenderFns } from "./CreateProctorsHeader.vue?vue&type=template&id=2c1e6fcc&scoped=true&"
import script from "./CreateProctorsHeader.vue?vue&type=script&lang=ts&"
export * from "./CreateProctorsHeader.vue?vue&type=script&lang=ts&"
import style0 from "./CreateProctorsHeader.vue?vue&type=style&index=0&id=2c1e6fcc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c1e6fcc",
  null
  
)

export default component.exports