import { attach, createEvent, forward, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import {
  exportAttemptsArchiveFx,
  exportFullAttemptsListFx,
} from '@/features/api/attempts/export-attempts-list.ts'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { coursesNameDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/courses-name-dropdown-filter/courses-filter-dropdown.model'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/exams-tests-filter/exams-test-filter.model'
import { dropdownFullExamAttemptAllModules } from '@/pages/exams/full-attempts/list/parts/filter/parts/dropdown-modules'
import { attemptsStatusPassDropdownModule } from '@/pages/common/dropdowns/multiselectDropdown/attempts-pass-status-dropdown/attempts-status-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { createTaskModule } from '@/features/api/tasks/tasks-module'
import { proctorsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/proctors-dropdown-filter/proctors-filter-dropdown.model'
import dayjs from 'dayjs'

export const attemptsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownFullExamAttemptAllModules
)

const exportAttemptsList = attach({
  effect: exportFullAttemptsListFx,
  source: [attemptsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    const formatColumns = {
      fields: exportedColumns.fields.map((field: string) => {
        if (field === 'student_answer') return 'answers_stat'
        return field
      }),
    }
    const formattedFilters = {}
    Object.keys(filters).forEach((key) => {
      if (
        key === 'available_from' ||
        key === 'available_to' ||
        key === 'test_passed_from' ||
        key === 'test_passed_to'
      ) {
        if (filters[key]) {
          formattedFilters[key] = dayjs(filters[key]).toISOString()
        }
      } else {
        formattedFilters[key] = filters[key]
      }
    })
    return { ...formattedFilters, ...formatColumns }
  },
})

const exportArchive = attach({
  effect: exportAttemptsArchiveFx,
  source: [attemptsFilters.store.$filterParams],
  mapParams: (_, [filters]) => {
    const formattedFilters = {}
    Object.keys(filters).forEach((key) => {
      if (
        key === 'available_from' ||
        key === 'available_to' ||
        key === 'test_passed_from' ||
        key === 'test_passed_to'
      ) {
        if (filters[key]) {
          formattedFilters[key] = dayjs(filters[key]).toISOString()
        }
      } else {
        formattedFilters[key] = filters[key]
      }
    })
    return { ...formattedFilters }
  },
})

export const attemptsPageParams = createPageParamsModel()

export const resetAttemptsFilters = createEvent<void>()

export const toggleVisibilityModal = createEvent<boolean>()
export const $visibilityModal = restore(toggleVisibilityModal, false).reset(reset)

export const toggleVisibilityFilters = createEvent<boolean>()
export const $visibilityFilters = restore(toggleVisibilityFilters, false)

export const setAttemptsFiltersAvailableTo = createEvent<string>()
export const $attemptsFiltersAvailableTo = restore(setAttemptsFiltersAvailableTo, '').reset(reset)

export const setAttemptsFiltersAvailableFrom = createEvent<string>()
export const $attemptsFiltersAvailableFrom = restore(setAttemptsFiltersAvailableFrom, '').reset(
  reset
)

export const setTestPassedTo = createEvent<string>()
export const $testPassedTo = restore(setTestPassedTo, '').reset(reset)

export const setTestPassedFrom = createEvent<string>()
export const $testPassedFrom = restore(setTestPassedFrom, '').reset(reset)

export const setAttemptScored = createEvent<boolean>()
export const $attemptScored = restore(setAttemptScored, false).reset(reset)

export const setProctoringActivated = createEvent<boolean>()
export const $proctoringActivated = restore(setProctoringActivated, false).reset(reset)

forward({
  from: reset,
  to: [
    subjectsDropdownModel.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    coursesNameDropdownModel.methods.resetDropdown,
    groupsDropdownModel.methods.resetDropdown,
    examTestsDropdownModel.methods.resetDropdown,
    attemptsStatusPassDropdownModule.methods.resetDropdown,
    proctorsDropdownModel.methods.resetDropdown,
  ],
})

export const taskLoader = createTaskModule(exportAttemptsList, 'attemptsList')

export const archiveTaskLoader = createTaskModule(exportArchive, 'attemptsArchive', true, true)
