import { deleteLabelsFx } from '@/features/api/assignment/labels/delete-label'
import { getLabelsTreeFx } from '@/features/api/assignment/labels/get-labels-tree'
import { getLabelsTreeLightFx } from '@/features/api/assignment/labels/get-labels-tree-light'
import { getLabelsInfoFx } from '@/features/api/assignment/labels/get-labels-info'
import { TreeData } from '@/features/api/types'
import { cropString, mergeTreeData, parseError, sortTreeLeaves } from '@/features/lib'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import {
  attach,
  createEffect,
  createEvent,
  forward,
  restore,
  sample,
  combine,
  createStore,
  split,
} from 'effector-root'
import { confirmDeleteModalVisibilityChanged } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { FiltersParams } from '@/pages/common/types'
import { getTestAssignmentFx } from '@/features/api/assignment/test-assignment/get-test-assignment'
import { labelsFilters } from '@/pages/labels/parts/labels-filter/labels-filter.model'

export const getLabelsTree = attach({
  effect: getLabelsTreeFx,
  mapParams: (params: FiltersParams) => ({
    study_year: params.study_year,
    subject: params.subject,
    theme: params.theme,
  }),
})

export const getFilteredTree = attach({
  effect: getLabelsTreeFx,
  mapParams: (params: FiltersParams) => ({
    ...params,
    study_year: params.study_year,
    subject: params.subject,
    theme: params.theme,
  }),
})

export const getLabelsTreeLight = attach({
  effect: getLabelsTreeLightFx,
})

export const getLabelsTreeInfo = attach({
  effect: getLabelsInfoFx,
})

export const deleteLabels = createEffect({
  handler: (ids: number[]): Promise<number[]> => {
    return new Promise((resolve) => {
      deleteLabelsFx(ids).then(() => {
        resolve(ids)
      })
    })
  },
})

export const loadTreeLight = createEvent<void>()
export const loadTree = createEvent<FiltersParams>()
export const loadFilteredTree = createEvent<FiltersParams>()
const rewriteLabelsTree = createEvent<TreeData[] | null>()
export const setLabelsTree = createEvent<TreeData[] | null>()
export const $labelsTree = createStore<TreeData[] | null>(null)
  .on(setLabelsTree, (state, data) => mergeTreeData(state!, data!))
  .on(rewriteLabelsTree, (state, payload) => sortTreeLeaves(payload!))

export const setLabelsTreeTotal = createEvent<number>()
export const $labelsTreeTotal = restore<number>(setLabelsTreeTotal, 0)

export const collectTaskData = createEffect({
  handler: (ids: number[]): Promise<any[]> =>
    Promise.all(
      ids.map(
        async (id) =>
          new Promise<any>((resolve) => {
            const res = getTestAssignmentFx(id).then((r) => {
              return {
                id: r.body.id,
                name: `${r.body.id}`,
                title: `[id${r.body.id}] - ${cropString(r.body.wording, 34)}`,
              }
            })
            resolve(res)
          })
      )
    ),
})

forward({
  from: loadTreeLight,
  to: [getLabelsTreeLight, getLabelsTreeInfo],
})

sample({
  source: labelsFilters.store.$filterParams,
  clock: loadFilteredTree,
  fn: (filterParams, treeParams) => {
    return { ...filterParams, ...treeParams }
  },
  target: getFilteredTree,
})

forward({
  from: loadTree,
  to: getLabelsTree,
})

export const $isLoading = combine(
  getFilteredTree.pending,
  getLabelsTreeLightFx.pending,
  (tree, light) => tree || light
)

forward({
  from: getLabelsInfoFx.doneData.map(({ body }) => body.total_amount),
  to: setLabelsTreeTotal,
})

forward({
  from: getLabelsTreeLight.doneData,
  to: rewriteLabelsTree.prepend(({ body }) => body.data),
})

forward({
  from: getFilteredTree.doneData,
  to: [
    rewriteLabelsTree.prepend(({ body }) => body.data),
    setLabelsTreeTotal.prepend(({ body }) => body.total),
  ],
})

forward({
  from: getLabelsTree.doneData,
  to: setLabelsTree.prepend(({ body }) => body.data),
})

const { elementNotFound } = split(deleteLabelsFx.failData, {
  elementNotFound: ({ status }) => status === 400,
})

forward({
  from: elementNotFound,
  to: [
    addToast.prepend((data: any) => ({ type: 'error', message: parseError(data.body) })),
    loadTreeLight.prepend(() => ({})),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
  ],
})

forward({
  from: deleteLabels.doneData,
  to: [
    loadTreeLight.prepend(() => ({})),
    confirmDeleteModalVisibilityChanged.prepend(() => false),
    successToastEvent('Метка была успешно удалена!'),
  ],
})

forward({
  from: labelsFilters.methods.resetFilters,
  to: loadTreeLight.prepend(() => ({})),
})

sample({
  clock: labelsFilters.methods.applyFilters,
  source: labelsFilters.store.$filterParams,
  target: loadFilteredTree,
})
