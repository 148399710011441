









































































































import Vue, { VueConstructor } from 'vue'
import qs from 'qs'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import PageHeader from '@/pages/results/courses-check/parts/header/PageHeader.vue'
import ResultCourseCheckFilter from '@/pages/results/courses-check/parts/filter/ResultCourseCheckFilter.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import { combineRouteQueries, computeSortParam, isQueryParamsEquelToPage } from '@/features/lib'
import { config } from '@/config'
import { $permissions } from '@/features/session'
import { DEFAULT_ID } from '@/pages/common/constants'
import {
  coursesCheckResultDataFields,
  searchFieldsData,
  maxLengthText,
} from '@/pages/results/courses-check/constants'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  $canRefreshTable,
  incomingResultCoursesCheckPageParams,
  canRefreshTableChanged,
  $isLoading,
  exportResultCoursesCheckList,
  setIsLoading,
} from '@/pages/results/courses-check/course-check-result-filter.model'
import { RefsType, HttpOptionsType } from '@/pages/common/types'
import {
  toggleVisibility,
  $visibility,
  resultCoursesCheckFilters,
} from '@/pages/results/courses-check/parts/filter/course-check-result-filter.model'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { axiosClient } from '@/lib/request'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    PageHeader,
    GeneralFilter,
    ResultCourseCheckFilter,
    TableHeader,
    Vuetable,
    VuetablePagination,
    NoDataContent,
    LoaderBig,
  },
  effector: {
    $canRefreshTable,
    $filterParams: resultCoursesCheckFilters.store.$filterParams,
    $pageParams: incomingResultCoursesCheckPageParams.store.$pageParams,
    $currentPage: incomingResultCoursesCheckPageParams.store.currentPage,
    $isLoading,
    $visibility,
    $permissions,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID as number,
      searchFields: searchFieldsData,
      total: 1,
      isFilters: false,
      fields: coursesCheckResultDataFields,
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/results-app/results/lessons/list/`
    },
  },
  watch: {
    $canRefreshTable: {
      handler(newVal) {
        if (newVal === true) {
          this.$refs.vuetable.reload()
          canRefreshTableChanged(false)
        }
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    toggleVisibility,
    changeFilter: resultCoursesCheckFilters.methods.changeFilter,
    resetFilters: resultCoursesCheckFilters.methods.resetFilters,
    applyFilters: resultCoursesCheckFilters.methods.applyFilters,
    changePage: incomingResultCoursesCheckPageParams.methods.changePage,
    queryToParams: incomingResultCoursesCheckPageParams.methods.queryToParams,
    reset,
    exportResultCoursesCheckList,
    setIsLoading,
    maxLengthText,
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const request = await axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' })
        },
      })
      return request
    },
    onFilterSet() {
      this.isFilters = true
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.isFilters = false
      this.resetFilters()
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
