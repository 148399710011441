









































































import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import BaseButton from '@/ui/button/BaseButton.vue'

import {
  reset,
  themesFilters,
  toggleVisibility,
} from '@/pages/dictionary/themes/list/parts/themes-filter/themes-filter.model'
import ClickOutside from '@/features/directives/click-outside'
import { dropdownComponents } from '@/pages/dictionary/themes/list/parts/themes-filter/parts/dropdown-components'
import { FiltersParams } from '@/pages/common/types'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ThemesFilter',
  components: {
    Icon,
    BaseSwitch,
    BaseButton,
    AuthorsDropdown: dropdownComponents.AuthorsDropdown,
    ClassesDropdown: dropdownComponents.ClassesDropdown,
    SubjectsDropdown: dropdownComponents.SubjectsDropdown,
  },
  effector: {
    $filterParams: themesFilters.store.$filterParams,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  methods: {
    toggleVisibility,
    closeFilter(event: any) {
      // check for general filter icon
      if (!event.target.closest('#filter-icon')) {
        toggleVisibility(false)
      }
    },
    setItem(filter: FiltersParams) {
      this.$emit('changeFilter', filter)
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibility(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
      reset() // togglers and visibility
    },
    hidePrerequisites(switcherState: boolean) {
      if (this.$filterParams.is_prerequisite) {
        this.setItem({ is_prerequisite: false })
      }
      this.setItem({ is_not_prerequisite: switcherState })
    },
    showPrerequisitesOnly(switcherState: boolean) {
      if (this.$filterParams.is_not_prerequisite) {
        this.setItem({ is_not_prerequisite: false })
      }
      this.setItem({ is_prerequisite: switcherState })
    },
    hideWithAssignments(switcherState: boolean) {
      if (this.$filterParams.has_assignment) {
        this.setItem({ has_assignment: false })
      }
      this.setItem({ has_no_assignment: switcherState })
    },
    showOnlyWithAssignments(switcherState: boolean) {
      if (this.$filterParams.has_no_assignment) {
        this.setItem({ has_no_assignment: false })
      }
      this.setItem({ has_assignment: switcherState })
    },
  },
})
