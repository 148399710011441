import { createEvent, forward, attach, sample, guard } from 'effector-root'
import { GetListQueryParams } from '@/features/api/types'
import { debounce } from 'patronum'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { SampleStudentTestResult } from '@/features/api/results/types'
import { $studentId, $testId } from '@/pages/results/tests-students/result-tests-student.model'
import { getSampleStudentTestResultByStudentId } from '@/features/api/results/get-sample-student-test-result-list'
import dayjs from 'dayjs'

const getAttempt = attach({
  effect: getSampleStudentTestResultByStudentId,
  source: { student: $studentId, test: $testId },
  mapParams: (params: GetListQueryParams, ids) => {
    return Object.assign(params, ids)
  },
})

export const attemptDropdownModule = createDropdownModel<SampleStudentTestResult>(getAttempt)

export const searchAttempt = createEvent<void>()
export const loadAttempt = createEvent<void>()

sample({
  clock: loadAttempt,
  source: {
    $nextPage: attemptDropdownModule.store.$nextPage,
    search: attemptDropdownModule.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
    ...(params.search && { search: params.search }),
  }),
  target: getAttempt,
})

sample({
  clock: searchAttempt,
  source: {
    search: attemptDropdownModule.store.$searchString,
  },
  fn: (params): GetListQueryParams => ({
    page: 1,
    ...(params.search && { search: params.search }),
  }),
  target: getAttempt,
})

const debounced = debounce({
  source: attemptDropdownModule.store.$searchString,
  timeout: 300,
})

forward({
  from: debounced,
  to: searchAttempt,
})

forward({
  from: attemptDropdownModule.methods.canLoadNextPage,
  to: loadAttempt,
})

const getDateTime = (date: string | null): string => {
  if (!date) return 'ошибка даты'
  return dayjs(date).format('DD.MM.YYYY HH:MM:ss')
}

sample({
  clock: getAttempt.doneData,
  source: {
    items: attemptDropdownModule.store.$items,
    $nextPage: attemptDropdownModule.store.$nextPage,
    search: attemptDropdownModule.store.$searchString,
  },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((field) => ({
      name: `${field.id}`,
      title: `(${field.id}) ${getDateTime(field.started_at)}, ${
        field.status ? 'продолажается' : 'завершен'
      }, ${field.is_training ? 'тренировочная попытка' : 'базовая попытка'}, (${
        field.trainings_amount || ''
      })`,
    }))
    if (res.body.current_page === 1) {
      return [...newData]
    }
    return [...items, ...newData]
  },
  target: attemptDropdownModule.store.$items,
})

guard({
  clock: $studentId,
  filter: (studentId) => studentId !== 0,
  target: [attemptDropdownModule.methods.resetDropdown],
})

guard({
  clock: $testId,
  filter: (testId) => testId !== 0,
  target: [attemptDropdownModule.methods.resetDropdown, getAttempt.prepend(() => ({}))],
})
