import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteQuestions } from '@/features/api/content/types'

export const requestDeleteQuestionsFx = createApiEffect<RequestDeleteQuestions, void>({
  requestMapper: (body) => ({
    method: 'PATCH',
    url: '/api/content-app/faq/list/bulk-delete/',
    body,
  }),
})
