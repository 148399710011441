import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import { condition } from 'patronum'
import {
  $selectedSubject,
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import {
  $selectedClass,
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  $selectedThemes,
  isPrerequisiteChanged as themesPrerequisiteChanged,
  setSelectedThemes,
} from '@/pages/dictionary/themes/edit/parts/themes/themes.model'
import {
  setThemeToSelect,
  themesDropdownModule as positionDropdownModule,
} from '@/pages/dictionary/themes/edit/parts/position/position.model'
import {
  $selectedPrerequisites,
  isPrerequisiteChanged,
  $isPrerequisite,
  setSelectedPrerequisites,
} from '@/pages/dictionary/themes/edit/parts/prerequisites/prerequisites.model'
import { Theme } from '@/features/api/subject/types'
import { updateThemeFx } from '@/features/api/subject/update-theme'
import { getThemeFx } from '@/features/api/subject/get-theme'
import { DEFAULT_ID } from '@/pages/common/constants'
import { createError } from '@/lib/effector/error-generator'
import { setTeachingResources } from '@/pages/dictionary/themes/edit/parts/teaching-resources/teaching-resources-page.model'
import { navigatePush } from '@/features/navigation/navigationMethods'

const updateThemeDataFx = attach({
  effect: updateThemeFx,
})

const updatePrerequisiteFx = attach({
  effect: updateThemeFx,
})

export const getThemeToUpdate = attach({
  effect: getThemeFx,
})

const updateTheme = createEvent<void>()
const updatePrerequisite = createEvent<void>()

export const clearFields = createEvent<void>()

export const edit = createEvent<void>()
const checkIfThemeCanBeSend = createEvent<void>()
const checkIfPrerequisiteCanBeSend = createEvent<void>()

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

export const resetThemeTitle = createEvent<void>()
export const themeTitleChanged = createEvent<string>()
export const $themeTitle = restore(themeTitleChanged, '').reset(resetThemeTitle)

export const themeIdChanged = createEvent<number>()
export const $themeId = restore(themeIdChanged, DEFAULT_ID)

export const resetPrerequisiteTitle = createEvent<void>()
export const prerequisiteTitleChanged = createEvent<string>()
export const $prerequisiteTitle = restore(prerequisiteTitleChanged, '').reset(
  resetPrerequisiteTitle
)

forward({
  from: clearFields,
  to: [
    resetThemeTitle,
    resetPrerequisiteTitle,
    classesDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    positionDropdownModule.methods.resetDropdown,
    setSelectedSubject.prepend(() => null),
    setSelectedClass.prepend(() => null),
  ],
})

export const $formToSend = combine({
  id: DEFAULT_ID,
  name: $themeTitle,
  is_prerequisite: $isPrerequisite,
  study_year_id: $selectedClass.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  subject_id: $selectedSubject.map((data) => (data && data.name ? +data.name : DEFAULT_ID)),
  themes_ids: $selectedThemes.map((arr) => arr.map((data) => data && +data.name)),
  parent_theme_id: positionDropdownModule.store.$item.map((data) =>
    data !== null ? +data!.name : null
  ),
  prerequisites_ids: $selectedPrerequisites.map((arr) => arr.map(({ name }) => +name)),
})

export const $formToSendPrerequisite = combine({
  id: DEFAULT_ID,
  name: $prerequisiteTitle,
  is_prerequisite: $isPrerequisite,
  subject_id: $selectedSubject.map((data) => (data ? +data.name : DEFAULT_ID)),
  themes_ids: $selectedThemes.map((arr) => arr.filter((data) => data).map((data) => +data.name)),
})

export const redirectToResourceCreate = createEvent()

sample({
  source: $formToSend,
  clock: redirectToResourceCreate,
  fn: ({ id, subject_id, study_year_id }) => {
    navigatePush({
      name: 'resources-create',
      params: {
        theme: `${id}`,
        subject: `${subject_id}`,
        class: `${study_year_id}`,
      },
    })
  },
})

sample({
  clock: getThemeToUpdate.done,
  source: getThemeFx.doneData.map((data) => data.body),
  fn: (theme: Theme) => {
    isPrerequisiteChanged(theme.is_prerequisite)
    themesPrerequisiteChanged(theme.is_prerequisite)
    themeTitleChanged(theme.name)
    themeIdChanged(theme.id)
    prerequisiteTitleChanged(theme.name)
    theme.subject &&
      subjectsDropdownModel.methods.itemChanged({
        name: `${theme.subject.id}`,
        title: theme.subject.name,
      })
    theme.subject &&
      setSelectedSubject({
        name: `${theme.subject.id}`,
        title: theme.subject.name,
      })
    classesDropdownModule.methods.itemChanged(
      theme.study_year ? { name: `${theme.study_year.number}`, title: theme.study_year.name } : null
    )
    theme.study_year &&
      setSelectedClass({
        name: `${theme.study_year.id}`,
        title: theme.study_year.name,
      })
    theme.parent_theme &&
      setThemeToSelect({
        name: `${theme.parent_theme.id}`,
        title: theme.parent_theme.name,
      })
    const prerequisites = theme.prerequisites.map((el) => ({ name: `${el.id}`, title: el.name }))
    setSelectedPrerequisites(prerequisites)
    theme.is_prerequisite &&
      theme.themes &&
      setSelectedThemes(theme.themes.map((item) => ({ name: `${item.id}`, title: item.name })))
    $formToSend.map((el) => (el.id = theme.id))
    $formToSendPrerequisite.map((el) => (el.id = theme.id))
    setTeachingResources(theme.study_resources)
  },
})

export const $themeTitleErrorModule = createError()

export const $prerequisiteTitleErrorModule = createError()
export const $classErrorModule = createError()

export const $positionErrorModule = createError()

export const $subjectErrorModule = createError()

const resetErrors = createEvent<void>()

forward({
  from: resetErrors,
  to: [
    $themeTitleErrorModule.methods.resetError,
    $prerequisiteTitleErrorModule.methods.resetError,
    $classErrorModule.methods.resetError,
    $positionErrorModule.methods.resetError,
    $subjectErrorModule.methods.resetError,
  ],
})

const $saveMethodFired = sample({
  source: $isPrerequisite,
  clock: edit,
  fn: (isPrerequisite) => isPrerequisite,
})

condition({
  source: $saveMethodFired,
  if: (isPrerequisite) => isPrerequisite,
  then: checkIfPrerequisiteCanBeSend.prepend(() => ({})),
  else: checkIfThemeCanBeSend.prepend(() => ({})),
})

sample({
  source: $formToSend,
  clock: checkIfThemeCanBeSend,
  fn: (obj) => {
    if (obj.name.trim().length && obj.study_year_id !== DEFAULT_ID && obj.subject_id !== DEFAULT_ID)
      updateTheme()
    else {
      if (!obj.name.trim().length) $themeTitleErrorModule.methods.setError(true)
      if (obj.study_year_id === DEFAULT_ID) $classErrorModule.methods.setError(true)
      if (obj.subject_id === DEFAULT_ID) $subjectErrorModule.methods.setError(true)
      errorToastEvent('Необходимо заполнить все обязательные поля')
    }
  },
})

sample({
  clock: updateTheme,
  source: $formToSend,
  target: updateThemeDataFx,
})

sample({
  source: $formToSendPrerequisite,
  clock: updatePrerequisite,
  target: updatePrerequisiteFx,
})

const $ifRedirectTheme = sample({
  clock: updateThemeDataFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

const $ifRedirectPrerequisite = sample({
  clock: updatePrerequisiteFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

forward({
  from: updateThemeDataFx.doneData,
  to: successToastEvent('Тема успешно обновлена!'),
})
forward({
  from: updatePrerequisiteFx.doneData,
  to: successToastEvent('Пререквизит успешно обновлён!'),
})

condition({
  source: $ifRedirectTheme,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'themes-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'themes-edit',
    params: { id: `${payload.id}` },
  })),
})
condition({
  source: $ifRedirectPrerequisite,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'themes-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'themes-edit',
    params: { id: `${payload.id}` },
  })),
})

sample({
  source: $formToSendPrerequisite,
  clock: checkIfPrerequisiteCanBeSend,
  fn: (obj) => {
    if (obj.name.length && obj.subject_id !== DEFAULT_ID) updatePrerequisite()
    else {
      if (obj.name.length === 0) $prerequisiteTitleErrorModule.methods.setError(true)
      if (obj.subject_id === DEFAULT_ID) $subjectErrorModule.methods.setError(true)
      errorToastEvent('Необходимо заполнить все обязательные поля')
    }
  },
})

forward({
  from: themeTitleChanged,
  to: $themeTitleErrorModule.methods.resetError,
})

forward({
  from: prerequisiteTitleChanged,
  to: $prerequisiteTitleErrorModule.methods.resetError,
})

forward({
  from: classesDropdownModule.methods.itemChanged,
  to: $classErrorModule.methods.resetError,
})

forward({
  from: positionDropdownModule.methods.itemChanged,
  to: $positionErrorModule.methods.resetError,
})

forward({
  from: subjectsDropdownModel.methods.itemChanged,
  to: $subjectErrorModule.methods.resetError,
})
