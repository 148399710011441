































import Vue from 'vue'
import DateInput from '@/ui/input/DateInput.vue'
import {
  $appointmentsAvailableFrom,
  setAppointmentsAvailableFrom,
  $appointmentsAvailableTo,
  setAppointmentsAvailableTo,
} from '@/pages/exams/appointments/create/model/form-fileds.model'
import ExamsTestsListDropdown from '@/pages/common/dropdowns/exams/exams-tests/ExamsTestsListDropdown.vue'

export default Vue.extend({
  name: 'AppointmentsPage2',
  components: {
    DateInput,
    ExamsTestsListDropdown,
  },
  effector: {
    $appointmentsAvailableFrom,
    $appointmentsAvailableTo,
  },
  methods: {
    setAppointmentsAvailableFrom,
    setAppointmentsAvailableTo,
  },
})
