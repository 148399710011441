import { createStore, guard } from 'effector-root'
import { createEffect, createEvent, forward } from 'effector'

export const contextMenuDestroy = createEvent<void>()

export const showContextMenu = createEffect(async () => {
  await new Promise((resolve) => setTimeout(resolve, 100))
  return true
})
export const hideContextMenu = createEvent<void>()
export const canHide = createEvent<void>()
export const toggleContextMenu = createEvent<void>()
export const $contextMenuVisible = createStore(false)
  .on(showContextMenu.done, () => true)
  .on(canHide, () => false)
  .reset(contextMenuDestroy)

export const $contextMenuSettled = createStore<boolean>(false)
  .on(showContextMenu.done, () => true)
  .on(canHide, () => false)
  .reset(contextMenuDestroy)

guard({
  clock: showContextMenu,
  filter: $contextMenuVisible,
  target: canHide,
})

guard({
  clock: hideContextMenu,
  filter: $contextMenuSettled,
  target: canHide,
})

forward({
  from: canHide,
  to: contextMenuDestroy,
})
