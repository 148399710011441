import { createEvent, createStore, forward, attach, restore } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getFilterLessonsFx } from '@/features/api/learning/courses/get-filter-lessons-list'
import { getFilterLessonsByIdFx } from '@/features/api/results/get-filters-result-list'

export const lessonsDropdownModule = createFilter()

export const setSelectedLessons = createEvent<DropdownItem | null>()
export const $selectedLessons = restore(setSelectedLessons, null)

const getLessons = attach({
  effect: getFilterLessonsFx,
})

const getLessonsById = attach({
  effect: getFilterLessonsByIdFx,
})

export const loadLessons = createEvent<void>()
export const loadLessonsById = createEvent<number>()
export const $lessons = createStore<DropdownItem[]>([])

forward({
  from: loadLessons,
  to: getLessons.prepend(() => ({})),
})

forward({
  from: loadLessonsById,
  to: getLessonsById,
})

forward({
  from: getLessons.doneData.map((res) =>
    res.body.data.map((lesson) => ({ name: `${lesson.id}`, title: lesson.name }))
  ),
  to: $lessons,
})

forward({
  from: getLessonsById.doneData.map((res) =>
    res.body.lessons.map((lesson) => ({
      name: `${lesson.id}`,
      title: `(${lesson.id}) ${lesson.number ? `№${lesson.number} ` : ''}${lesson.name}`,
    }))
  ),
  to: $lessons,
})

forward({
  from: lessonsDropdownModule.methods.resetDropdown,
  to: setSelectedLessons.prepend(() => null),
})
