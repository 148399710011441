import { createApiEffect } from '@/features/api/common/create-api-effect'
import {
  AutoItem,
  CreateTestAutoItemFxParams,
  UpdateTestAutoItemFxParams,
} from '@/features/api/test/types'
import { createEffect } from 'effector'

export const createTestAutoItemFx = createApiEffect<CreateTestAutoItemFxParams, AutoItem>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/test-app/auto-item/',
    body,
  }),
})

export const updateTestAutoItemFx = createApiEffect<UpdateTestAutoItemFxParams, AutoItem>({
  requestMapper: (body) => ({
    method: 'PUT',
    url: `/api/test-app/auto-item/${body.id}`,
    body,
  }),
})

export const removeTestAutoItemFx = createApiEffect<number>({
  requestMapper: (id) => ({
    method: 'DELETE',
    url: `/api/test-app/auto-item/${id}`,
  }),
})

export const createTestAutoItemsArrFx = createEffect(async (data: CreateTestAutoItemFxParams[]) => {
  return Promise.all(
    data.map(async (autoItem) => {
      await createTestAutoItemFx(autoItem)
    })
  )
})

export const resendTestAutoItemsArrFx = createEffect(async (data: UpdateTestAutoItemFxParams[]) => {
  const dataIds = data.map((item) => item.id)
  const oldIds = data.length > 0 ? data[0].oldId : []
  const deleteItems = oldIds.filter((item) => !dataIds.includes(item))
  return Promise.all([
    ...data.map(async (autoItem) => {
      if (autoItem.id === 0) {
        await createTestAutoItemFx(autoItem)
      }
    }),
    ...deleteItems.map(async (id) => {
      if (id) {
        await removeTestAutoItemFx(id)
      }
    }),
  ])
})
