












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { tasksChoiceDropdownModel } from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/tasks-choice-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  name: 'TasksChoiceDropdown',
  components: {
    FilterDropdown,
  },
  props: {
    label: { type: String, default: 'Переход ученика между заданиями в тесте' },
  },
  effector: {
    ...tasksChoiceDropdownModel.store,
  },
  data() {
    return {
      searchString: '',
    }
  },
  methods: {
    ...tasksChoiceDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
})
