
























import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  $modalVisibility,
  modalVisibilityChanged,
} from '@/pages/applications/modals/rejected/rejected.model'

export default Vue.extend({
  name: 'RejectedModal',
  components: {
    Modal,
    BaseButton,
    Icon,
  },
  effector: {
    $modalVisibility,
  },
  computed: {
    correctTitle() {
      return 'Заявка(и) отклонена(ы).'
    },
  },
  methods: { modalVisibilityChanged },
})
