import { render, staticRenderFns } from "./MultipleShortClosedAnswer.vue?vue&type=template&id=5af53568&scoped=true&"
import script from "./MultipleShortClosedAnswer.vue?vue&type=script&lang=js&"
export * from "./MultipleShortClosedAnswer.vue?vue&type=script&lang=js&"
import style0 from "./MultipleShortClosedAnswer.vue?vue&type=style&index=0&id=5af53568&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5af53568",
  null
  
)

export default component.exports