import { createApiEffect } from '@/features/api/common/create-api-effect'
import { CreateEmployeeType } from '@/features/api/employee/types'

export const changeEmployeeInfoByIdFx = createApiEffect<
  { id: string; body: Partial<CreateEmployeeType> },
  void,
  any
>({
  requestMapper: ({ body, id }) => ({
    method: 'PATCH',
    url: `/api/user-app/employee/${id}/`,
    body,
  }),
})
