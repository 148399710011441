




























import Vue, { PropType } from 'vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { coursesFilters } from '@/pages/learning/learning-courses/list/parts/filter/course-filter.model'
import { FiltersParams } from '@/pages/common/types'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { exportCoursesListFx } from '@/features/api/learning/courses/export-courses-list'
import { $permissions } from '@/features/session'

export default Vue.extend({
  components: {
    BaseButton,
    HeaderPopup,
    DownloadButton,
  },
  effector: {
    $filterParams: coursesFilters.store.$filterParams,
    isLoadingExport: exportCoursesListFx.pending,
    $permissions,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
  },
  methods: {
    setItem(filter: FiltersParams) {
      this.$emit('changeFilter', filter)
      this.$emit('setFilter')
    },
  },
})
