import { attach, createEvent, restore } from 'effector-root'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { createStore } from 'effector'
import { TableField } from '@/pages/applications/types'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { testsResultDataFields } from '@/pages/results/tests-all/constants'
import { resultTestsAllFilters } from '@/pages/results/tests-all/parts/filter/tests-result-filter.model'
import { SetColumnsColumns } from '@/pages/common/modals/table-settings/table-colunm-edit/types'
import { exportResultTestsListFx } from '@/features/api/results/export-tests-courses-all'
import {
  $resultFilterBlock,
  $saveFilters,
} from '@/pages/common/modals/result-tests-filter/result-tests-filter-modal.model'
import { createTaskModule } from '@/features/api/tasks/tasks-module'

export const incomingResultTestsPageParams = createPageParamsModel()

export const setIsLoading = createEvent<boolean>()
export const $isLoading = restore<boolean>(setIsLoading, false)

export const canRefreshTableChanged = createEvent<boolean>()
export const $canRefreshTable = restore(canRefreshTableChanged, false)

export const setNewTableFields = createEvent<SetColumnsColumns>()
export const setDefaultTableFields = createEvent<void>()
export const $tableFields = createStore<TableField[]>(testsResultDataFields)
  .on(setNewTableFields, (fields, settings) =>
    testsResultDataFields.filter((field) => settings[field.name])
  )
  .reset(setDefaultTableFields)

const exportResultTestsList = attach({
  effect: exportResultTestsListFx,
  source: [
    resultTestsAllFilters.store.$filterParams,
    $exportColumnsQueryParam,
    $resultFilterBlock,
    $saveFilters,
  ],
  mapParams: (_, [filters, exportedColumns, resultFilters, save]) => {
    const resultsParams = {
      get_query_filters: JSON.stringify(
        resultFilters.map((block) => ({
          subject: block.subject ? block.subject.name : '',
          test: block.test ? block.test.name : '',
          sign: block.sign ? block.sign.name : '',
          value: block.value ? block.value.name : '',
        }))
      ),
    }
    return { ...filters, ...exportedColumns, ...(save && resultsParams) }
  },
})

export const taskLoader = createTaskModule(exportResultTestsList, 'resultTestsList')
