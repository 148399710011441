














import Vue from 'vue'
import {
  attemptsResultStatusDropdownModule,
  mapAttemptsStatus,
} from '@/pages/exams/full-attempts/list/parts/filter/parts/results-status-filter/results-status-filter.model'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  name: 'ResultsStatusAttemptsFilter',
  components: {
    FilterDropdown,
  },
  props: {
    label: {
      type: String,
      default: 'Статус отправки результатов в систему проверки',
    },
  },
  effector: {
    ...attemptsResultStatusDropdownModule.store,
  },
  methods: {
    ...attemptsResultStatusDropdownModule.methods,
    onItemChanged(item: DropdownItem | null) {
      this.$emit('setItem', item?.name)
    },
  },
  mounted() {
    this.setItems(mapAttemptsStatus)
  },
})
