













import Vue from 'vue'
import {
  loadOlympiadTasks,
  olympiadTasksDropdownModule,
} from '@/pages/results/olympiad-results/model/tooltip-filter.model'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    label: String,
  },
  effector: {
    ...olympiadTasksDropdownModule.store,
  },
  methods: {
    ...olympiadTasksDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  beforeDestroy() {
    this.dropdownDestroy()
  },
  mounted() {
    loadOlympiadTasks()
  },
})
