import { combine, createEvent, forward, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { dropdownModules } from '@/pages/users/groups/list/model/tooltip-filter.model'
import {
  $studentsForAppointmentsIds,
  resetFields,
} from '@/pages/exams/full-appointments/create/model/form-fileds.model'
import { errorToastEvent } from '@/features/toasts/toasts.model'
import { attach } from 'effector'
import { getStudentsListFx } from '@/features/api/full-appointments/get-students-list'
import { StudentAppointmentsDataMinimal } from '@/pages/exams/full-appointments/create/model/types'

export const studentAppointmentsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 2000,
  },
  dropdownModules
)

export const getStudentsList = attach({
  effect: getStudentsListFx,
  source: studentAppointmentsFilters.store.$filterParams,
  mapParams: (_, filters) => {
    return filters
  },
})

export const loadStudents = createEvent()

export const setAppointmentsStudentsList = createEvent<StudentAppointmentsDataMinimal[]>()
export const $appointmentsStudentsList = restore<StudentAppointmentsDataMinimal[]>(
  setAppointmentsStudentsList,
  []
).reset(resetFields)

export const setSearchAppointmentsStudent = createEvent<string>()
export const $searchAppointmentsStudent = restore<string>(setSearchAppointmentsStudent, '').reset(
  resetFields
)

export const setStudentIds = createEvent<string>()
export const $studentIds = restore<string>(setStudentIds, '').reset(resetFields)

export const studentAppointmentsPageParams = createPageParamsModel()

export const resetAppointmentsGroupFilters = createEvent<void>()

export const showIdsFormatError = createEvent()

export const $studentsForTable = combine(
  $appointmentsStudentsList,
  $studentsForAppointmentsIds,
  (list, ids) => {
    return {
      list: list.map((item) => {
        return {
          ...item,
          isChecked: ids.includes(item.id),
        }
      }),
      total: list.length,
    }
  }
)

forward({
  from: showIdsFormatError,
  to: errorToastEvent('ID студентов указаны в неправильном формате'),
})

forward({
  from: getStudentsListFx.doneData.map(({ body }) =>
    body.data.map((student) => {
      return {
        ...student,
        study_year: student.study_year.name,
        proctor: null,
      }
    })
  ),
  to: setAppointmentsStudentsList,
})

forward({
  from: loadStudents,
  to: getStudentsList,
})

forward({
  from: resetFields,
  to: studentAppointmentsFilters.methods.resetFilters,
})
