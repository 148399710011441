import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { difficultyDropdownModule } from '@/pages/common/dropdowns/testing/difficulty-dropdown/difficulty-dropdown.model'
import { statusDropdownModule } from '@/pages/common/dropdowns/testing/status-dropdown/status-dropdown.model'
import { typeDropdownModule } from '@/pages/common/dropdowns/testing/type-dropdown/type-dropdown.model'
import { groupsDropdownModule } from '@/pages/common/dropdowns/groups/groups-dropdown.model'

export const dropdownModules = {
  classesDropdownModule,
  difficultyDropdownModule,
  statusDropdownModule,
  subjectsDropdownModel,
  typeDropdownModule,
  groupsDropdownModule,
}
