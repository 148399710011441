import { attach, createEvent, forward, sample } from 'effector-root'
import { SubjectGrid } from '@/features/api/subject/types'
import { getSubjectsListFx } from '@/features/api/subject/get-subjects-list'
import { GetListQueryParams } from '@/features/api/types'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'

const getSubjects = attach({
  effect: getSubjectsListFx,
  mapParams: (params: GetListQueryParams) => params,
})

export const subjectsDropdownModel = createDropdownMultiselectModel<SubjectGrid>(getSubjects)

export const loadSubjects = createEvent<void>()

sample({
  clock: loadSubjects,
  source: { $nextPage: subjectsDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getSubjects,
})

forward({
  from: subjectsDropdownModel.methods.canLoadNextPage,
  to: loadSubjects,
})

sample({
  clock: getSubjects.doneData,
  source: { items: subjectsDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newTags = res.body.data.map((field) => ({ name: `${field.id}`, title: field.name }))
    return [...items, ...newTags]
  },
  target: subjectsDropdownModel.store.$items,
})
