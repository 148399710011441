








































import Vue, { PropType } from 'vue'
import FormInput from '@/ui/input/FormInput.vue'
import Icon from '@/ui/icon/Icon.vue'
import { DroppableInput } from '@/pages/common/parts/tasks/types'
import { inputsValuesCounter } from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/moving-images-on-image-answer-form.model'
import BaseButton from '@/ui/button/BaseButton.vue'

export default Vue.extend({
  name: `CorrectTextAnswerItem`,
  components: {
    BaseButton,
    Icon,
    FormInput,
  },
  props: {
    answer: {
      type: Object as PropType<DroppableInput>,
      required: true,
    },
  },
  methods: {
    addAnswer() {
      this.$emit('change', {
        ...this.answer,
        value: [
          ...this.answer.value,
          {
            value: '',
            id: inputsValuesCounter.next(),
          },
        ],
      })
    },
    removeAnswer(i: number) {
      if (this.answer.value.length === 1) {
        this.$emit('remove', this.answer)
        return
      }

      const value = [...this.answer.value]
      value.splice(i, 1)

      this.$emit('change', {
        ...this.answer,
        value,
      })
    },
    editAnswer(i: number, newValue: string) {
      const value = [...this.answer.value]
      const { id } = value[i]
      value.splice(i, 1, {
        value: newValue,
        id,
      })

      this.$emit('change', {
        ...this.answer,
        value,
      })
    },
  },
})
