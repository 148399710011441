<template>
  <div
    class="result-filter"
  >
    <div class="row">
      <StudentsDropdown
        class="half"
        @setItem="setStudents"
      />
      <DifficultyDropdown
        class="half"
        placeholder="Не выбрана"
        @setItem="val => setItem({'difficulty': val})"
      />
    </div>
    <div class="row">
      <TestsListDropdown
        class="half"
        placeholder="Не выбран"
        @setItem="setTests"
      />
      <TestsAttemptDropdown
        class="half"
        @setItem="setAttempt"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { resultTestsStudentsFilters } from '@/pages/results/tests-students/parts/filter/result-student-filter.model'
import {
  setAttemptId,
  setStudentId,
  setTestId,
} from '@/pages/results/tests-students/result-tests-student.model'
import ClickOutside from '@/features/directives/click-outside.ts'
import { dropdownResultCoursesComponents } from '@/pages/results/tests-students/parts/filter/parts/dropdown-components'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'ResultTestsFilter',
  components: {
    StudentsDropdown: dropdownResultCoursesComponents.StudentsDropdown,
    DifficultyDropdown: dropdownResultCoursesComponents.DifficultyDropdown,
    TestsListDropdown: dropdownResultCoursesComponents.TestsListDropdown,
    TestsAttemptDropdown: dropdownResultCoursesComponents.TestsAttemptDropdown,
  },
  effector: {
    $filterParams: resultTestsStudentsFilters.store.$filterParams,
  },
  methods: {
    setStudents(id) {
      setStudentId(id)
      this.setItem({ student: id })
    },
    setTests(id) {
      setTestId(id)
      this.setItem({ test: id })
    },
    setAttempt(id) {
      setAttemptId(id)
      this.setItem({ sample: id })
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
      setTimeout(() => {
        this.$emit('setFilter')
      })
    },
    applyFilters() {
      this.$emit('setFilter')
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
    },
  },
})
</script>

<style scoped>
.result-filter {
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-bottom: 20px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.half {
  width: calc(50% - 20px);
  flex-shrink: 0;
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}

.two-third {
  width: calc(100% / 3 * 2 - 20px);
  flex-shrink: 0;
}

.btn-col {
  display: flex;
  align-items: flex-end;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}

.arrow-up {
  @mixin arrow-up;
}
</style>

