import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { createEvent, attach, createStore, forward, sample } from 'effector-root'
import { createDropdownModel } from '@/pages/common/filters/create-dropdown-model'
import { getOlympiadAssignmentListFx } from '@/features/api/learning/lessons/get-lesson-task-list'
import { OlympiadAssignment } from '@/features/api/assignment/types/olympiad-assignments-types'
import { getStudentsListFx } from '@/features/api/student/get-student-list'
import { StudentList } from '@/features/api/student/types'
import { DropdownItem } from '@/pages/common/types'
import { getOlympiadResultStatusesFx } from '@/features/api/results/get-olympiad-results-statuses'
import { GetListQueryParams } from '@/features/api/types'

const getStatuses = attach({
  effect: getOlympiadResultStatusesFx,
})

const getOlympiadTasks = attach({
  effect: getOlympiadAssignmentListFx,
})

export const loadStatuses = createEvent()

export const loadOlympiadTasks = createEvent<void>()

forward({
  from: loadStatuses,
  to: getStatuses,
})

export const statusesDropdownModel = createFilter()

export const resultStatusesDropdownModel = createFilter()

export const olympiadTasksDropdownModule = createDropdownModel<OlympiadAssignment>(getOlympiadTasks)

export const studentsDropdownModule = createDropdownModel<StudentList>(getStudentsListFx)

export const $statuses = createStore<DropdownItem[]>([]).on(getStatuses.doneData, (_, res) =>
  res.body.map((payload) => ({ name: `${payload.code}`, title: payload.name }))
)

sample({
  clock: loadOlympiadTasks,
  source: { $nextPage: olympiadTasksDropdownModule.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getOlympiadTasks,
})

forward({
  from: olympiadTasksDropdownModule.methods.canLoadNextPage,
  to: loadOlympiadTasks,
})

sample({
  clock: getOlympiadTasks.doneData,
  source: { items: olympiadTasksDropdownModule.store.$items },
  fn: ({ items }, res) => {
    const newData = res.body.data.map((payload) => ({
      name: `${payload.id}`,
      title: `(${payload.id}) ${payload.wording}`,
    }))
    return [...items, ...newData]
  },
  target: olympiadTasksDropdownModule.store.$items,
})
