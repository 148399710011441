














import Vue, { PropType } from 'vue'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { $permissions } from '@/features/session'

export default Vue.extend({
  name: 'CommentsOnLessonsHeader',
  components: {
    DownloadButton,
    HeaderPopup,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<number[]> },
  },
  effector: {
    $permissions,
  },
})
