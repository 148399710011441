
























import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'

export default Vue.extend({
  name: 'CreateCertificateFooter',
  components: { BaseButton },
  props: {
    disabled: { type: Boolean as PropType<boolean>, default: true },
  },
  methods: {
    goToList() {
      navigatePush({ name: 'certificates-generator-table' })
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
