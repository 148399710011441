import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import { condition, spread } from 'patronum'
import { DEFAULT_ID } from '@/pages/common/constants'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { successToastEvent } from '@/features/toasts/toasts.model'
import {
  $selectedType,
  resetSelectedType,
  setSelectedType,
  typeDropdownModule,
} from '@/pages/common/dropdowns/content/banners/type/type-dropdown.model'
import { isLinkValid } from '@/lib/validators/url'
import { getBannerFx } from '@/features/api/content/banners/get-banner'
import { updateBannerFx } from '@/features/api/content/banners/update-banner'

const saveBannerFx = attach({
  effect: updateBannerFx,
})

export const loadBannerFx = attach({
  effect: getBannerFx,
})

export const clearFields = createEvent<void>()

export const create = createEvent<void>()

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

const setBannerId = createEvent<number>()
const $bannerId = restore(setBannerId, DEFAULT_ID)

export const resetName = createEvent<void>()
export const nameChanged = createEvent<string>()
export const $name = restore(nameChanged, '').reset(resetName)

export const resetText = createEvent<void>()
export const textChanged = createEvent<string>()
export const $text = restore(textChanged, '').reset(resetText)

export const resetBtnTitle = createEvent<void>()
export const btnTitleChanged = createEvent<string>()
export const $btnTitle = restore(btnTitleChanged, '').reset(resetBtnTitle)

export const resetBtnUrl = createEvent<void>()
export const btnUrlChanged = createEvent<string>()
export const $btnUrl = restore(btnUrlChanged, '').reset(resetBtnUrl)

export const resetTimerTarget = createEvent<void>()
export const timerTargetChanged = createEvent<Date | string | null>()
export const $timerTarget = restore(timerTargetChanged, '').reset(resetTimerTarget)

export const toggleIsActive = createEvent<boolean>()
export const $isActive = restore(toggleIsActive, false).reset(clearFields)

forward({
  from: clearFields,
  to: [
    resetName,
    resetBtnTitle,
    resetText,
    resetBtnUrl,
    typeDropdownModule.methods.resetDropdown,
    resetSelectedType,
  ],
})

spread({
  source: getBannerFx.doneData.map((res) => res.body),
  targets: {
    id: setBannerId,
    banner_type: [
      setSelectedType.prepend((date: 'timer' | 'no_timer') => ({
        name: date,
        title: date === 'timer' ? 'Баннер с обратным отсчётом' : 'Баннер без обратного отсчёта',
      })),
      typeDropdownModule.methods.itemChanged.prepend((date: 'timer' | 'no_timer') => ({
        name: date,
        title: date === 'timer' ? 'Баннер с обратным отсчётом' : 'Баннер без обратного отсчёта',
      })),
    ],
    name: nameChanged,
    text: textChanged,
    active: toggleIsActive,
    button_title: btnTitleChanged,
    button_url: btnUrlChanged,
    timer_target: timerTargetChanged.prepend((data: string | null) =>
      data ? new Date(data) : null
    ),
  },
})

export const $disabledSaveButtons = combine(
  $name,
  $text,
  $selectedType,
  $btnTitle,
  $btnUrl,
  $timerTarget,
  (name, text, type, btnTitle, btnUrl, time) =>
    !name ||
    !text ||
    !type?.name ||
    !btnTitle ||
    !btnUrl ||
    !isLinkValid(btnUrl) ||
    (type.name === 'timer' && !time)
)

export const $formToSend = combine(
  $bannerId,
  $selectedType,
  $name,
  $text,
  $btnTitle,
  $btnUrl,
  $isActive,
  $timerTarget,
  (id, type, name, text, button_title, button_url, active, timerTarget) => {
    return {
      id,
      banner_type: type?.name || 'no_timer',
      name,
      text,
      button_title,
      button_url,
      active,
      timer_target: timerTarget ? new Date(timerTarget) : null,
    }
  }
)

sample({
  clock: create,
  source: $formToSend,
  target: saveBannerFx,
})

const $ifRedirectTheme = sample({
  clock: saveBannerFx.doneData.map((res) => res.body.id),
  source: $redirectAfterSave,
  fn: (redirect, id) => ({ redirect, id }),
})

forward({
  from: saveBannerFx.doneData,
  to: successToastEvent('Баннер успешно создан!'),
})

condition({
  source: $ifRedirectTheme,
  if: (payload: { redirect: boolean; id: number }) => payload.redirect,
  then: navigatePush.prepend(() => ({ name: 'banners-list' })),
  else: navigatePush.prepend((payload: { redirect: boolean; id: number }) => ({
    name: 'banners-edit',
    params: { id: `${payload.id}` },
  })),
})
