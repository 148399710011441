

















































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { TreeData } from '@/features/api/types'
import BaseCheckbox from '@/ui/checkbox/BaseCheckbox.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import { mapTaskTypeTo } from '@/pages/common/constants'
import { formatTitle } from '@/features/lib'
import { $token } from '@/features/api/common/request'

export default Vue.extend({
  components: {
    Icon,
    BaseCheckbox,
    TooltipCell,
  },
  effector: {
    $token,
  },
  props: {
    item: {
      type: Object as PropType<{ id: number; leaves: TreeData[]; name: string; title: string }>,
    },
    selectedItemsIds: { type: Array as PropType<number[]> },
    handleClick: { type: Function },
  },
  data() {
    return {
      info: 'В этом задании баллы начисляются за каждый верный ответ. Если вы хотите учитывать только полностью верный ответ, переведите тумблер “Использовать баллы за задание” в неактивное состояние.',
    }
  },
  computed: {
    showTick() {
      return this.selectedItemsIds.includes(this.item.id)
    },
  },
  methods: {
    getCorrectIconType(type: string) {
      return mapTaskTypeTo[type].icon
    },
    getCorrectDescriptionType(type: string) {
      return mapTaskTypeTo[type].description
    },
    getScore(score: number) {
      if (score) {
        return formatTitle(score, ['балл', 'балла', 'баллов'])
      }
      return ''
    },
  },
})
