import { createApiEffect } from '@/features/api/common/create-api-effect'
import { LessonUpdateAssigmentsType } from '@/pages/learning/learning-lessons/create/model/types'
import { UpdateCourseFxParams } from '@/features/api/learning/types'

export const createLessonFx = createApiEffect<LessonUpdateAssigmentsType, UpdateCourseFxParams>({
  requestMapper: (body) => ({
    method: 'POST',
    url: `/api/lesson-app/lessons/`,
    body,
  }),
})
