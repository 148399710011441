





















import Vue from 'vue'
import SlideHeader from '@/pages/content/common/header/Header.vue'
import SlideContent from '@/pages/content/onboarding/edit/parts/Content.vue'
import SlideFooter from '@/pages/content/common/footer/Footer.vue'
import {
  create,
  clearFields,
  redirectAfterSaveChanged,
  loadSlideFx,
  $isActive,
  toggleIsActive,
  $disabledSaveButtons,
} from '@/pages/content/onboarding/edit/slide-edition-page.model'
import { navigatePush } from '@/features/navigation/navigationMethods'

export default Vue.extend({
  name: 'SlideEditionPage',
  components: {
    SlideHeader,
    SlideContent,
    SlideFooter,
  },
  effector: {
    $isActive,
    $disabledSaveButtons,
  },
  methods: {
    toggleIsActive,
    redirectAfterSaveChanged,
    clearFields,
    create,
    navigatePush,
    createWithRedirect() {
      redirectAfterSaveChanged(true)
      create()
    },
  },
  mounted() {
    loadSlideFx(+this.$route.params.id)
  },
  beforeDestroy() {
    redirectAfterSaveChanged(false)
    clearFields()
  },
})
