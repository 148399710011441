















import Vue, { PropType } from 'vue'
import { AssignmentType } from '@/features/api/assignment/types/types'
import { UploadMediaResponse } from '@/features/api/media/types'
import Default from '@/pages/results/answer-types/wrappers/Default.vue'
import BroadFileAnswer from '@/pages/results/answer-types/wrappers/BroadFileAnswer.vue'
import ShortClosedAnswer from '@/pages/results/answer-types/wrappers/ShortClosedAnswer.vue'
import MultipleShortClosedAnswer from '@/pages/results/answer-types/wrappers/MultipleShortClosedAnswer.vue'
import MultipleChoiceOneOrManyAnswers from '@/pages/results/answer-types/wrappers/MultipleChoiceOneOrManyAnswers.vue'
import MultipleChoiceOneAnswer from '@/pages/results/answer-types/wrappers/MultipleChoiceOneAnswer.vue'
import BroadOpenAnswer from '@/pages/results/answer-types/wrappers/BroadOpenAnswer.vue'
import CommonListStringAnswer from '@/pages/results/answer-types/wrappers/CommonListStringAnswer.vue'
import CorrectSequenceAnswer from '@/pages/results/answer-types/wrappers/CorrectSequenceAnswer.vue'
import ConnectLinesAnswer from '@/pages/results/answer-types/wrappers/ConnectLinesAnswer.vue'
import MovingImagesImageInputAnswer from '@/pages/results/answer-types/wrappers/MovingImagesImageInputAnswer.vue'
import MovingImagesTextInputAnswer from '@/pages/results/answer-types/wrappers/MovingImagesTextInputAnswer.vue'
import CommonListTextAnswer from '@/pages/results/answer-types/wrappers/CommonListTextAnswer.vue'
import MultipleListTextAnswer from '@/pages/results/answer-types/wrappers/MultipleListTextAnswer.vue'
import ColorHighlightAnswer from '@/pages/results/answer-types/wrappers/ColorHighlightAnswer.vue'

export default Vue.extend({
  name: 'Answers',
  components: {
    BroadFileAnswer,
    ShortClosedAnswer,
    MultipleShortClosedAnswer,
    MultipleChoiceOneOrManyAnswers,
    MultipleChoiceOneAnswer,
    BroadOpenAnswer,
    CommonListStringAnswer,
    CorrectSequenceAnswer,
    ConnectLinesAnswer,
    MovingImagesImageInputAnswer,
    MovingImagesTextInputAnswer,
    CommonListTextAnswer,
    MultipleListTextAnswer,
    ColorHighlightAnswer,
    Default,
  },
  props: {
    type: { type: String as PropType<AssignmentType> },
    files: { type: Array as PropType<UploadMediaResponse[]> },
    question: { type: [Array, Object] },
    answer: { type: [Array, Object, Number] },
    correctAnswer: { type: [Array, Object, Number] },
    rowId: { type: Number },
    answerType: { type: String, default: 'student' },
  },
  computed: {
    answerExist() {
      if (this.answer === null && this.files.length === 0 && this.answerType === 'student') {
        return false
      }
      if (this.correctAnswer === null && this.files.length === 0 && this.answerType === 'correct') {
        return false
      }
      return true
    },
  },
  methods: {
    handleRightClick(event: any) {
      event.preventDefault()
      this.$emit('onRightClick', { data: { id: this.$props.rowId }, event })
    },
    getAssignmentComponent(assignment: AssignmentType) {
      switch (assignment) {
        case 'SHORT_CLOSED_ANSWER':
          return 'ShortClosedAnswer'
        case 'MULTIPLE_SHORT_CLOSED_ANSWER':
          return 'MultipleShortClosedAnswer'
        case 'MULTIPLE_CHOICE_ONE_OR_MANY_ANSWERS':
          return 'MultipleChoiceOneOrManyAnswers'
        case 'MULTIPLE_CHOICE_ONE_ANSWER':
          return 'MultipleChoiceOneAnswer'
        case 'BROAD_OPEN_ANSWER':
          return 'BroadOpenAnswer'
        case 'COMMON_LIST_STRING_ANSWER':
          return 'CommonListStringAnswer'
        case 'CORRECT_SEQUENCE_ANSWER':
          return 'CorrectSequenceAnswer'
        case 'CONNECT_LINES_ANSWER':
          return 'ConnectLinesAnswer'
        case 'MOVING_IMAGES_IMAGE_INPUT_ANSWER':
          return 'MovingImagesImageInputAnswer'
        case 'MOVING_IMAGES_TEXT_INPUT_ANSWER':
          return 'MovingImagesTextInputAnswer'
        case 'COMMON_LIST_TEXT_ANSWER':
          return 'CommonListTextAnswer'
        case 'MULTIPLE_LIST_TEXT_ANSWER':
          return 'MultipleListTextAnswer'
        case 'BROAD_FILE_ANSWER':
          return 'BroadFileAnswer'
        case 'COLOR_HIGHLIGHT_ANSWER':
          return 'ColorHighlightAnswer'
        default:
          return 'Default'
      }
    },
  },
  mounted() {
    const container = document.querySelector(`#cell-${this.$props.rowId}-${this.$props.dataId}`)
    container && container.addEventListener('contextmenu', this.handleRightClick)
  },
  beforeDestroy() {
    const container = document.querySelector(`#cell-${this.$props.rowId}-${this.$props.dataId}`)
    container && container.removeEventListener('contextmenu', this.handleRightClick)
  },
})
