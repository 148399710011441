









































import Vue, { PropType } from 'vue'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Icon from '@/ui/icon/Icon.vue'
import { loadModalForMultiChanges } from '@/pages/bank/lesson-tasks/list/parts/modals/tasks-update/tasks-update-modal.model'
import { lessonAssignmentFolderModal } from '@/pages/common/modals/tasks-bank/creating-folder/creating-folder-modal.model'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import { lessonTaskPageParams } from '@/pages/bank/lesson-tasks/list/lesson-page.model'
import { $permissions } from '@/features/session'

export default Vue.extend({
  name: 'PageHeader',
  components: {
    BaseButton,
    Icon,
    GridPageHead,
    DownloadButton,
    HeaderPopup,
  },
  effector: {
    $treeView: lessonTaskPageParams.store.treeView,
    $permissions,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<number[]> },
  },
  methods: {
    loadModalForMultiChanges,
    modalVisibilityChanged: lessonAssignmentFolderModal.methods.modalVisibilityChanged,
  },
})
