






























































































































































import Vue, { VueConstructor } from 'vue'
import qs from 'qs'
import { Vuetable, VuetablePagination, VuetableFieldCheckbox } from 'vuetable-2'
import PageHeader from '@/pages/learning/learning-courses/list/parts/header/PageHeader.vue'
import CourseFilter from '@/pages/learning/learning-courses/list/parts/filter/CourseFilter.vue'
import TableHeader from '@/pages/common/parts/table-header/TableHeader.vue'
import ActionsButton from '@/pages/common/parts/actions/ActionsButton.vue'
import ContextMenu from '@/pages/common/parts/context-menu/ContextMenu.vue'
import LoaderBig from '@/pages/common/parts/internal-loader-blocks/BigLoader.vue'
import TooltipCell from '@/pages/common/parts/tooltip-cell/TooltipCell.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import ConfirmDeleteModal from '@/pages/common/modals/confirm-delete/ConfirmDeleteModal.vue'
import RequestDeleteModal from '@/pages/common/modals/request-delete/RequestDeleteModal.vue'
import GeneralFilter from '@/pages/common/general-filter/GeneralFilter.vue'
import Icon from '@/ui/icon/Icon.vue'
import TableColumnEditModal from '@/pages/common/modals/table-settings/table-colunm-edit/TableColumnEditModal.vue'
import CoursesSortModal from '@/pages/learning/modals/courses-sort-modal/CoursesSortModal.vue'
import ContextCell from '@/pages/common/parts/context-cell/ContextCell.vue'
import {
  combineRouteQueries,
  computeSortParam,
  isQueryParamsEquelToPage,
  removeHtmlTags,
} from '@/features/lib'
import { config } from '@/config'
import { $permissions } from '@/features/session'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { DEFAULT_ID } from '@/pages/common/constants'
import {
  searchFieldsData,
  coursesLearningDataFields,
  getCoursesActions,
} from '@/pages/learning/learning-courses/constants'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { noInternetToastEvent } from '@/features/toasts/toasts.model'
import {
  $canRefreshTable,
  incomingCoursesPageParams,
  canRefreshTableChanged,
  $isLoading,
  setNewTableFields,
  $tableFields,
  deleteCourse,
  exportCoursesList,
  requestDeleteCourse,
  setIsLoading,
} from '@/pages/learning/learning-courses/list/courses-page.model'
import {
  toggleVisibility,
  $visibility,
  coursesFilters,
} from '@/pages/learning/learning-courses/list/parts/filter/course-filter.model'
import {
  $setColumns,
  changeColumnsModalVisibility,
} from '@/pages/common/modals/table-settings/table-colunm-edit/table-colunm-edit-modal.model'
import { changeCoursesSortModalVisibility } from '@/pages/learning/modals/courses-sort-modal/courses-sort-modalmodel'
import { loadConfirmDeleteModal } from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'
import { loadRequestDeleteModal } from '@/pages/common/modals/request-delete/request-delete-modal.model'
import { showContextMenu } from '@/pages/common/parts/context-menu/context-menu.model'
import {
  RefsType,
  HttpOptionsType,
  CommonInteractedItemParams,
  DisplayContextMenuPayload,
  ActionsItem,
} from '@/pages/common/types'
import { ContextMenuType } from '@/pages/common/parts/context-menu/types'
import { getActionsDisplayConditions } from '@/pages/common'
import { axiosClient } from '@/lib/request'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  components: {
    PageHeader,
    GeneralFilter,
    CourseFilter,
    TableHeader,
    ActionsButton,
    ContextMenu,
    Vuetable,
    VuetablePagination,
    LoaderBig,
    TooltipCell,
    BaseButton,
    NoDataContent,
    Icon,
    TableColumnEditModal,
    CoursesSortModal,
    ConfirmDeleteModal,
    RequestDeleteModal,
    ContextCell,
  },
  effector: {
    $visibility,
    $permissions,
    $canRefreshTable,
    $filterParams: coursesFilters.store.$filterParams,
    $pageParams: incomingCoursesPageParams.store.$pageParams,
    $currentPage: incomingCoursesPageParams.store.currentPage,
    $isLoading,
    $setColumns,
    $tableFields,
  },
  data() {
    return {
      interactedItemId: DEFAULT_ID as number,
      searchFields: searchFieldsData,
      total: 1,
      fields: coursesLearningDataFields,
      selectedRows: [] as number[],
      isFilters: false,
      contextMenuClickedCoordinates: { x: 0, y: 0 },
      contextMenuType: 'table_theme' as ContextMenuType,
      contextMenuItems: [] as ActionsItem[],
      deleteModalTitle: [
        'Будет отправлена заявка на удаление курса. Продолжить?',
        'Будет отправлена заявка на удаление курсов. Продолжить?',
      ],
    }
  },
  computed: {
    apiUrl(): string {
      return `${config.BACKEND_URL}/api/course-app/courses/list/`
    },
    tableHeaderItems(): ActionsItem[] {
      const displayConditions = getActionsDisplayConditions('tableHeader', this.selectedRows.length)
      return getCoursesActions(displayConditions, this.$permissions!)
    },
  },
  watch: {
    $canRefreshTable: {
      handler(newVal) {
        if (newVal === true) {
          this.$refs.vuetable.reload()
          canRefreshTableChanged(false)
        }
      },
    },
    $pageParams: {
      handler(newVal) {
        if (!isQueryParamsEquelToPage(this.$route.query, newVal)) {
          this.$router.replace(combineRouteQueries(this.$route.query, newVal))
        }
      },
    },
  },
  methods: {
    changeFilter: coursesFilters.methods.changeFilter,
    resetFilters: coursesFilters.methods.resetFilters,
    applyFilters: coursesFilters.methods.applyFilters,
    removeParamsFilter: coursesFilters.methods.removeParamsFilter,
    changePage: incomingCoursesPageParams.methods.changePage,
    queryToParams: incomingCoursesPageParams.methods.queryToParams,
    changeColumnsModalVisibility,
    setNewTableFields,
    toggleVisibility,
    reset,
    deleteCourse,
    exportCoursesList,
    changeCoursesSortModalVisibility,
    requestDeleteCourse,
    setIsLoading,
    editCourse() {
      navigatePush({
        name: 'courses-edit-page',
        params: { id: `${this.selectedIds()}` },
      })
    },
    async myFetch(apiUrl: string, httpOptions: HttpOptionsType) {
      const request = axiosClient.get(apiUrl, {
        params: { ...httpOptions.params, sort: computeSortParam(httpOptions.params.sort) },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' })
        },
      })
      return request
    },
    endLoading() {
      setTimeout(() => {
        this.setIsLoading(false)
      }, 700)
    },
    onFilterSet() {
      this.isFilters = true
      this.applyFilters()
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.isFilters = false
      this.resetFilters()
      reset() // search string and field
      Vue.nextTick(() => this.$refs.vuetable && this.$refs.vuetable.reload())
    },
    onPaginationData(paginationData: any) {
      this.total = paginationData.total
      this.$refs.pagination.setPaginationData(paginationData)
      this.removeSelection()
    },
    onChangePage(page: any) {
      this.$refs.vuetable.changePage(page)
      this.changePage(page)
    },
    handleLoadError(res: any) {
      if (!res.response) {
        noInternetToastEvent()
      }
    },
    handleRightClick({ data, event, type = 'item' }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
      event.preventDefault()
    },
    handleRowClick(res: any) {
      if (res.event.target.closest('.actions-activator')) return
      const { selectedTo } = this.$refs.vuetable
      if (selectedTo.length === 0) selectedTo.push(res.data.id)
      else if (selectedTo.find((el: number) => el === res.data.id)) {
        selectedTo.splice(selectedTo.indexOf(res.data.id), 1)
      } else selectedTo.push(res.data.id)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    allToggled(isSelected: boolean) {
      if (isSelected) this.selectedRows = this.$refs.vuetable.selectedTo
      else this.selectedRows = []
    },
    removeSelection() {
      this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
    },
    setColumns() {
      this.setNewTableFields(this.$setColumns)
      this.changeColumnsModalVisibility(false)
    },
    deleteCourses() {
      this.$permissions!.courses_course_courses.can_edit
        ? loadConfirmDeleteModal(this.selectedIds())
        : loadRequestDeleteModal(this.selectedIds())
    },
    clearWording(str: string) {
      return removeHtmlTags(str)
    },
    tableActionsButtonClick(event: MouseEvent, id: number) {
      this.handleActionsClick({
        data: { id },
        event,
        type: 'item',
      })
    },
    handleActionsClick({ data, event, type }: CommonInteractedItemParams) {
      this.displayContextMenu({ id: data.id, type, coordinates: { x: event.x, y: event.y } })
    },
    setContextMenuItems() {
      const displayConditions = getActionsDisplayConditions(
        this.contextMenuType,
        this.selectedRows.length
      )
      this.contextMenuItems = getCoursesActions(displayConditions, this.$permissions!)
    },
    displayContextMenu({ id, type, coordinates: { x, y } }: DisplayContextMenuPayload) {
      this.interactedItemId = id
      this.contextMenuType = type
      this.contextMenuClickedCoordinates = { x, y }
      this.setContextMenuItems()
      showContextMenu()
    },
    selectedIds(): number[] {
      if (this.selectedRows.length) {
        return this.selectedRows
      }
      if (this.interactedItemId !== DEFAULT_ID) {
        return [this.interactedItemId]
      }
      return []
    },
    handleRequestDeleteCourse(comment: string, ids: number[]) {
      requestDeleteCourse({
        courses: ids,
        ticket_comment: comment,
      })
    },
  },
  created() {
    this.queryToParams(this.$route.query)
  },
  destroyed() {
    this.resetFilters()
  },
})
