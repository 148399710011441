import { DropdownItem } from '@/pages/common/types'
import { ActionsDisplayConditions } from '@/pages/common'
import { EmployeePermissions } from '@/features/api/employee/types'
import { $currentUser } from '@/features/session'

export const searchFieldsData: DropdownItem[] = [
  { name: 'all', title: 'Искать везде' },
  { name: 'subject', title: 'Предмет' },
  { name: 'study_year', title: 'Класс' },
  { name: 'theme', title: 'Тема' },
]

export const getResourcesActions = (
  displayOptions: ActionsDisplayConditions,
  permissions: EmployeePermissions
) => {
  const user = $currentUser.getState()
  const isDefaultShow = user?.role !== 'METHODIST'

  const actions = [
    {
      name: 'create',
      title: 'Создать обучающий ресурс',
      action: 'onCreate',
      isDisplayed: displayOptions.isTreeFolder,
      hasPermissionToSee: permissions.media_studyresource_study_resources.can_edit,
    },
    {
      name: 'edit',
      title: 'Редактировать',
      action: 'onEdit',
      isDisplayed: displayOptions.isTreeItem,
      hasPermissionToSee: permissions.media_studyresource_study_resources.can_edit,
    },
    {
      name: 'delete',
      title: 'Удалить',
      action: 'onDelete',
      isDisplayed: displayOptions.isTreeItem,
      hasPermissionToSee: isDefaultShow,
    },
  ]

  return actions.filter((action) => action.isDisplayed && action.hasPermissionToSee)
}
