import { render, staticRenderFns } from "./CreateCertificatesForm.vue?vue&type=template&id=183f64a0&scoped=true&"
import script from "./CreateCertificatesForm.vue?vue&type=script&lang=ts&"
export * from "./CreateCertificatesForm.vue?vue&type=script&lang=ts&"
import style0 from "./CreateCertificatesForm.vue?vue&type=style&index=0&id=183f64a0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "183f64a0",
  null
  
)

export default component.exports