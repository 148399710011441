<template>
  <div
    v-click-outside="closeFilter"
    v-if="$props.visible"
    class="themes-filter"
  >
    <div class="row">
      <SubjectFilterDropdown
        class="half-third"
        @setItem="val => setItem({'subjects': val})"
      />
      <ClassesFilterDropdown
        class="half-third"
        @setItem="val => setItem({'classes': val})"
      />
      <ExamsFullTestsListDropdown
        class="half-third"
        :is-reset-destroy="false"
        @setItem="setTests"
      />
    </div>
    <div class="row">
      <AttemptsPassStatusFilterDropdown
        class="half-third"
        label="Статус попытки"
        @setItem="val => setItem({'status': val})"
      />
      <GroupsFilterDropdown
        class="half-third"
        @setItem="setGroups"
      />
      <StudentsDropdown
        class="half-third"
        @setItem="setStudent"
      />
    </div>
    <div class="row">
      <div class="half-third">
        <div class="label">Экзамен можно начать с</div>
        <DatePicker
          :value="$attemptsFiltersAvailableFrom"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setAvailableFrom"
        />
      </div>
      <div class="half-third">
        <div class="label">Экзамен можно начать до</div>
        <DatePicker
          :value="$attemptsFiltersAvailableTo"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="setAvailableTo"
        />
      </div>
      <div class="half-third switch-wrap">
        <BaseSwitch
          class="switch"
          :checked="$proctoringActivated"
          @change="setActiveProctoring"
        >
          <p>Прокторинг активирован</p>
        </BaseSwitch>
      </div>

    </div>
    <div class="row">
      <div class="half-third">
        <div class="label">Экзамен пройден с</div>
        <DatePicker
          :value="$testPassedFrom"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="onSetTestPassedFrom"
        />
      </div>
      <div class="half-third">
        <div class="label">Экзамен пройден до</div>
        <DatePicker
          :value="$testPassedTo"
          class="date-picker"
          type="datetime"
          placeholder="Выберите дату и время"
          @input="onSetTestPassedTo"
        />
      </div>
      <div class="half-third">
        <ProctorsFilterDropdown @setItem="val => setItem({'proctor': val})" />
      </div>
    </div>
    <div class="row">
      <div class="half-third switch-wrap">
        <BaseSwitch
          class="switch"
          :checked="$attemptScored"
          @change="setScored"
        >
          <p>Зачтены без прохождения</p>
        </BaseSwitch>
      </div>
      <ResultsStatusAttemptsFilter
        class="half-third"
        @setItem="val => setItem({'results_sent': val})"
      />
      <div class="half-third btn-col">
        <BaseButton
          small
          @click="applyFilters"
        >
          Применить
        </BaseButton>
        <BaseButton
          class="borderless"
          small
          border-without-bg
          @click="resetFilters"
        >
          Сбросить фильтры
        </BaseButton>
      </div>
    </div>
    <div  class="close-icon" @click="toggleVisibilityFilters(false)">
      <Icon
        type="close"
        size="10"
      />
    </div>
    <div class="arrow-up" />
  </div>
</template>

<script>
import Vue from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import ClickOutside from '@/features/directives/click-outside.ts'
import { reset } from '@/pages/common/general-filter/general-filter.model'
import { dropdownAttemptAllComponents } from './parts/dropdown-components'
import {
  $attemptsFiltersAvailableFrom,
  $attemptsFiltersAvailableTo,
  $proctoringActivated,
  $testPassedFrom,
  $testPassedTo,
  attemptsFilters,
  setAttemptsFiltersAvailableFrom,
  setAttemptsFiltersAvailableTo,
  setProctoringActivated,
  setTestPassedFrom,
  setTestPassedTo,
  toggleVisibilityFilters,
  $attemptScored,
  setAttemptScored,
} from '@/pages/exams/full-attempts/list/model/full-attempts-filters.model'
import DatePicker from 'vue2-datepicker'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import isCloseCalendar from '@/lib/utils/no_close_calendar'

Vue.directive('click-outside', ClickOutside)

export default Vue.extend({
  name: 'AttemptFilter',
  components: {
    Icon,
    BaseButton,
    BaseSwitch,
    DatePicker,
    SubjectFilterDropdown: dropdownAttemptAllComponents.SubjectsDropdown,
    ClassesFilterDropdown: dropdownAttemptAllComponents.ClassesDropdown,
    ExamsFullTestsListDropdown: dropdownAttemptAllComponents.ExamsFullTestsListDropdown,
    GroupsFilterDropdown: dropdownAttemptAllComponents.GroupsFilterDropdown,
    StudentsDropdown: dropdownAttemptAllComponents.StudentsExamsFullAnswerFilter,
    AttemptsPassStatusFilterDropdown: dropdownAttemptAllComponents.AttemptsPassStatusFilterDropdown,
    ResultsStatusAttemptsFilter: dropdownAttemptAllComponents.ResultsStatusAttemptsFilter,
    ProctorsFilterDropdown: dropdownAttemptAllComponents.ProctorsFilterDropdown,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  effector: {
    $filterParams: attemptsFilters.store.$filterParams,
    $attemptsFiltersAvailableTo,
    $attemptsFiltersAvailableFrom,
    $testPassedTo,
    $testPassedFrom,
    $proctoringActivated,
    $attemptScored,
  },
  methods: {
    toggleVisibilityFilters,
    setAttemptsFiltersAvailableFrom,
    setAttemptsFiltersAvailableTo,
    setTestPassedTo,
    setTestPassedFrom,
    setProctoringActivated,
    setAttemptScored,
    closeFilter(event) {
      // check for general filter icon
      if (isCloseCalendar(event)) {
        toggleVisibilityFilters(false)
      }
    },
    setItem(filter) {
      this.$emit('changeFilter', filter)
    },
    setStudent(val) {
      if (val) {
        this.setItem({ students: val.name })
      } else {
        this.setItem({ students: undefined })
      }
    },
    setGroups(val) {
      if (val) {
        if (typeof val === 'string') {
          this.setItem({ groups: val })
        } else if (Array.isArray(val)) {
          this.setItem({ groups: val.join(',') })
        }
      } else {
        this.setItem({ groups: undefined })
      }
    },
    setTests(val) {
      if (val) {
        if (typeof val === 'string') {
          this.setItem({ tests: val })
        } else if (Array.isArray(val)) {
          this.setItem({ tests: val.join(',') })
        }
      } else {
        this.setItem({ tests: undefined })
      }
    },
    applyFilters() {
      this.$emit('setFilter')
      toggleVisibilityFilters(false)
    },
    resetFilters() {
      this.$emit('resetFilter') // general filter
      reset() // togglers and visibility
    },
    setAvailableFrom(value) {
      this.setAttemptsFiltersAvailableFrom(value)
      this.setItem({ available_from: value })
    },
    setAvailableTo(value) {
      this.setAttemptsFiltersAvailableTo(value)
      this.setItem({ available_to: value })
    },
    onSetTestPassedFrom(value) {
      this.setTestPassedFrom(value)
      this.setItem({ test_passed_from: value })
    },
    onSetTestPassedTo(value) {
      this.setTestPassedTo(value)
      this.setItem({ test_passed_to: value })
    },
    setActiveProctoring(value) {
      this.setProctoringActivated(value)
      this.setItem({ is_proctoring_activated: value })
    },
    setScored(value) {
      this.setAttemptScored(value)
      this.setItem({ is_passed: value })
    },
  },
  beforeDestroy() {
    this.resetFilters()
  },
})
</script>

<style scoped>
.themes-filter {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  .row {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 15px;
    right: 21px;
    z-index: 999999;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}
.row:last-of-type {
  justify-content: flex-end;
}
.buttons-row {
  justify-content: flex-end;
}
.half {
  width: calc(50% - 20px);
  flex-shrink: 0;
}

.half-third {
  width: calc(100% / 3 - 20px);
  flex-shrink: 0;
}

.two-third {
  width: calc(100% / 3 * 2 - 10px);
  flex-shrink: 0;
}

.btn-col {
  display: flex;
  align-items: flex-end;
}

.borderless {
  border-color: transparent !important;
  @mixin underline-text;
}

.close-icon {
  cursor: pointer;
  fill: var(--c-grey-3);
}

.arrow-up {
  @mixin arrow-up;
}
.date-picker {
  width: 100%;
}
.date-picker /deep/ .mx-input {
  height: 46px;
}
.label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.switch-wrap {
  display: flex;
  align-items: center;
}
.switch {
  margin-top: 17px;
}
</style>

