import { attach, createEvent, forward, sample } from 'effector-root'
import { StudyYear } from '@/features/api/subject/types'
import { getUserStudyYearsListFx } from '@/features/api/study_year/get-user-study-years-list'
import { GetListQueryParams } from '@/features/api/types'
import { createDropdownMultiselectModel } from '@/pages/common/filters/create-dropdown-multiselect-model'

const getClasses = attach({
  effect: getUserStudyYearsListFx,
  mapParams: (params: GetListQueryParams) => params,
})

export const classesDropdownModel = createDropdownMultiselectModel<StudyYear>(getClasses)

export const loadClasses = createEvent<void>()

sample({
  clock: loadClasses,
  source: { $nextPage: classesDropdownModel.store.$nextPage },
  fn: (params): GetListQueryParams => ({
    page: params.$nextPage,
  }),
  target: getClasses,
})

forward({
  from: classesDropdownModel.methods.canLoadNextPage,
  to: loadClasses,
})

sample({
  clock: getClasses.doneData,
  source: { items: classesDropdownModel.store.$items },
  fn: ({ items }, res) => {
    const newTags = res.body.data.map((field) => ({ name: `${field.id}`, title: field.name }))
    return [...items, ...newTags]
  },
  target: classesDropdownModel.store.$items,
})
