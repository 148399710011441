

























import Vue, { PropType } from 'vue'
import TreeNode from '@/pages/labels/parts/tree/TreeNode.vue'
import { $labelsTree, $labelsTreeTotal } from '@/pages/labels/labels-page.model'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { FiltersParams } from '@/pages/common/types'

export default Vue.extend({
  name: 'LabelsTree',
  components: {
    TreeNode,
    NoDataContent,
  },
  props: {
    filters: { type: Object as PropType<FiltersParams> },
  },
  effector: { $labelsTree, $labelsTreeTotal },
})
