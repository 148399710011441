import StatusDropdown from '@/pages/common/dropdowns/bank/lesson-tasks/status-dropdown/StatusDropdown.vue'
import TypeDropdown from '@/pages/common/dropdowns/bank/lesson-tasks/type-dropdown/TypeDropdown.vue'
import CourseDropdown from '@/pages/common/dropdowns/bank/lesson-tasks/course-dropdown/CourseDropdown.vue'
import PositionDropdown from '@/pages/common/dropdowns/bank/lesson-tasks/position-dropdown/PositionDropdown.vue'

export const dropdownComponents = {
  StatusDropdown,
  TypeDropdown,
  CourseDropdown,
  PositionDropdown,
}
