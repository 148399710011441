import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { examTestsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/exams-tests-filter/exams-test-filter.model'
import { attemptsStatusDropdownModule } from '@/pages/common/dropdowns/exams/attempts-status-dropdown/attempts-status-dropdown.model'
import { attemptsStatusPassDropdownModule } from '@/pages/common/dropdowns/multiselectDropdown/attempts-pass-status-dropdown/attempts-status-dropdown.model'
import { groupsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/groups-dropdown-filter/groupsFilterDropdown.model'
import { studentsDropdownModel } from '@/pages/exams/attempts/list/parts/filter/parts/students-attempts-filter/students-attempts-filter.model'

export const dropdownAttemptAllModules = {
  groupsDropdownModel,
  studentsDropdownModel,
  subjectsDropdownModel,
  classesDropdownModule,
  examTestsDropdownModel,
  attemptsStatusDropdownModule,
  attemptsStatusPassDropdownModule,
}
