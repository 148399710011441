export const mapDeleteModalTypeToTitle = {
  task: {
    typeWord: {
      singular: 'Задание',
      plural: 'Задания',
    },
    deleteWord: {
      singular: 'удалено',
      plural: 'удалены',
    },
  },
  theme: {
    typeWord: {
      singular: 'Тема',
      plural: 'Темы',
    },
    deleteWord: {
      singular: 'удалена',
      plural: 'удалены',
    },
  },
  subject: {
    typeWord: {
      singular: 'Предмет',
      plural: 'Предметы',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  resource: {
    typeWord: {
      singular: 'Обучающий ресурс',
      plural: 'Обучающие ресурсы',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  label: {
    typeWord: {
      singular: 'Метка',
      plural: 'Метки',
    },
    deleteWord: {
      singular: 'удалена',
      plural: 'удалены',
    },
  },
  tag: {
    typeWord: {
      singular: 'Тег',
      plural: 'Теги',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  test: {
    typeWord: {
      singular: 'Тест',
      plural: 'Тесты',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  folder: {
    typeWord: {
      singular: 'Папка',
      plural: 'Папки',
    },
    deleteWord: {
      singular: 'удалена со всем содержимым',
      plural: 'удалены со всем содержимым',
    },
  },
  file: {
    typeWord: {
      singular: 'Файл',
      plural: 'Файлы',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  lessons: {
    typeWord: {
      singular: 'Урок',
      plural: 'Уроки',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  courses: {
    typeWord: {
      singular: 'Курс',
      plural: 'Курсы',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  students: {
    typeWord: {
      singular: 'Ученик',
      plural: 'Ученики',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  employers: {
    typeWord: {
      singular: 'Пользователь',
      plural: 'Пользователи',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  news: {
    typeWord: {
      singular: 'Новость',
      plural: 'Новости',
    },
    deleteWord: {
      singular: 'удалена',
      plural: 'удалены',
    },
  },
  questions: {
    typeWord: {
      singular: 'Вопрос',
      plural: 'Вопрос',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  slides: {
    typeWord: {
      singular: 'Слайд',
      plural: 'Слайды',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  banners: {
    typeWord: {
      singular: 'Баннер',
      plural: 'Баннеры',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  groups: {
    typeWord: {
      singular: 'Группа',
      plural: 'Группы',
    },
    deleteWord: {
      singular: 'удалена',
      plural: 'удалены',
    },
  },
  instructions: {
    typeWord: {
      singular: 'Инструкиця',
      plural: 'Инструкици',
    },
    deleteWord: {
      singular: 'удалена',
      plural: 'удалены',
    },
  },
  proctors: {
    typeWord: {
      singular: 'Проктор',
      plural: 'Прокторы',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  exams: {
    typeWord: {
      singular: 'Экзамен',
      plural: 'Экзамены',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  appointments: {
    typeWord: {
      singular: 'Назначение',
      plural: 'Назначения',
    },
    deleteWord: {
      singular: 'удалено',
      plural: 'удалены',
    },
  },
  attempts: {
    typeWord: {
      singular: 'Попытка',
      plural: 'Попытки',
    },
    deleteWord: {
      singular: 'удалена',
      plural: 'удалены',
    },
  },
  cert: {
    typeWord: {
      singular: 'Сертификат',
      plural: 'Сертификаты',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
  'lesson-results': {
    typeWord: {
      singular: 'Результат ученика',
      plural: 'Результаты ученика',
    },
    deleteWord: {
      singular: 'удален',
      plural: 'удалены',
    },
  },
}
