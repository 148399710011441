import { createEvent, createStore, forward, attach, restore } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'
import { getGroupsFiltersListFx } from '@/features/api/learning/courses/get-groups-filter-list'
import { getFilterGroupsByIdFx } from '@/features/api/results/get-filters-result-list'
import { GetGroupsForIdParams } from '@/features/api/results/types'

export const groupsDropdownModule = createFilter()

export const setSelectedGroups = createEvent<DropdownItem | null>()
export const $selectedGroups = restore(setSelectedGroups, null)

const getGroups = attach({
  effect: getGroupsFiltersListFx,
})

const getGroupsById = attach({
  effect: getFilterGroupsByIdFx,
})

export const loadGroups = createEvent<void>()
export const loadGroupsById = createEvent<GetGroupsForIdParams>()
export const $groups = createStore<DropdownItem[]>([])

forward({
  from: loadGroups,
  to: getGroups.prepend(() => ({})),
})

forward({
  from: loadGroupsById,
  to: getGroupsById,
})

forward({
  from: getGroups.doneData.map((res) =>
    res.body.map((lesson) => ({ name: `${lesson.id}`, title: lesson.name }))
  ),
  to: $groups,
})

forward({
  from: getGroupsById.doneData.map((res) =>
    res.body.map((group) => ({ name: `${group.id}`, title: `(${group.id})${group.name}` }))
  ),
  to: $groups,
})

forward({
  from: groupsDropdownModule.methods.resetDropdown,
  to: setSelectedGroups.prepend(() => null),
})
