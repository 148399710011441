import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ExportTicketsDeletionListQueryParams } from '@/features/api/ticket/types'

export const exportTicketsDeletionListFx = createApiEffect<
  ExportTicketsDeletionListQueryParams,
  Blob
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/ticket-app/deletion-ticket/list/export/',
    responseType: 'blob',
    query,
  }),
})
