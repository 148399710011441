import { router } from '@/features/navigation/index'
import { createEvent } from 'effector-root'

type Params = Record<string, string>
type Navigation = { query?: Params; params?: Params }
type UrlNavigate = { url: string } & Navigation
type NamedNavigate = { name: string } & Navigation
export type Navigate = UrlNavigate | NamedNavigate
const isNamedNavigation = (nav: Navigate): nav is NamedNavigate => !!(nav as NamedNavigate).name
export const navigatePush = createEvent<Navigate>()
export const navigateReplace = createEvent<Navigate>()
export const navigateFactory = (navigationMethod: 'push' | 'replace') => (navigate: Navigate) => {
  const defaultOptions = { query: navigate.query, params: navigate.params }
  if (isNamedNavigation(navigate)) {
    router[navigationMethod]({ ...defaultOptions, name: navigate.name }).catch(() => ({}))
  } else {
    router[navigationMethod]({ ...defaultOptions, path: navigate.url })
  }
}

navigatePush.watch(navigateFactory('push'))
navigateReplace.watch(navigateFactory('replace'))
