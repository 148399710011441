



































import Vue, { VueConstructor } from 'vue'
import Controller from '@/pages/preview-tasks/Controller.vue'
import Card from '@/ui/card/Card.vue'
import { config } from '@/config'
import { $isPreview, toggleIsPreview } from '@/pages/preview-tasks/controller.model'
import { acceptApplicationsFx } from '@/pages/applications/incoming/incoming-applications-page.model'
import {
  $canRefreshAfterSendingToRevision,
  loadModal,
} from '@/pages/applications/modals/send-for-moderation/send-for-moderation.model'
import SendForModerationModal from '@/pages/applications/modals/send-for-moderation/SendForModerationModal.vue'
import { loadCommentModal } from '@/pages/applications/modals/outgoing-comment/outgoing-comment.model'
import OutgoingModal from '@/pages/applications/modals/outgoing-comment/OutgoingComment.vue'
import {
  loadTestTask,
  loadOlympiadTask,
  loadLessonTask,
  loadApplication,
  loadExamTask,
} from '@/pages/preview-tasks/preview-tasks-page.model'
import {
  loadModalToSendForCheck,
  $canRefreshAfterSendingToReview,
} from '@/pages/bank/common/modals/moderator-select/moderator-select.model'
import ModeratorSelectModal from '@/pages/bank/common/modals/moderator-select/ModeratorSelectModal.vue'
import SelectTask from '@/pages/preview-tasks/parts/select-task/SelectTask.vue'
import {
  $currentIndex,
  $currentQuestion,
} from '@/pages/preview-tasks/parts/select-task/select-task.model'
import { combineRouteQueries } from '@/features/lib'
import { FromPage, TaskType } from '@/pages/common/types'
import { navigateReplace } from '@/features/navigation/navigationMethods'

type IframeData = {
  activeTask: string | null
  token: string | null
  taskType: string | null
}

export default (
  Vue as VueConstructor<
    Vue & {
      questions: string[]
      token: null | string
      taskType: TaskType
      applications: number[]
      fromPage: FromPage
    }
  >
).extend({
  name: 'PreviewTasksPage',
  components: {
    SelectTask,
    Controller,
    Card,
    SendForModerationModal,
    OutgoingModal,
    ModeratorSelectModal,
  },
  data: () => ({
    questions: [] as string[],
    token: null as null | string,
    taskType: '' as TaskType | null,
    applications: [] as number[] | null,
    iframeHeight: 500 as number,
    fromPage: '' as FromPage,
  }),
  effector: {
    $isPreview,
    $canRefreshAfterSendingToReview,
    $canRefreshAfterSendingToRevision,
    $currentIndex,
    $currentQuestion,
  },
  computed: {
    activeTask(): string | null {
      return this.questions.length && typeof this.$currentIndex === 'number'
        ? this.questions[this.$currentIndex]
        : null
    },
    activeApplication(): number | null {
      return this.applications.length && typeof this.$currentIndex === 'number'
        ? this.applications[this.$currentIndex]
        : null
    },
    iframeLink(): string {
      const { activeTask, token, taskType }: IframeData = this
      return activeTask && token && taskType
        ? `${config.PREVIEW_URL}?questionId=${activeTask}&type=${taskType}&token=${token}`
        : ''
    },
  },
  watch: {
    $isPreview(value) {
      if (!value) {
        switch (this.taskType) {
          case 'test-assignment':
            navigateReplace({
              name: 'test-tasks-edit',
              query: {
                questions: this.questions.join(','),
                applications: this.applications.join(','),
                fromPage: this.fromPage,
                currentQuestion: String(this.$currentQuestion),
              },
              params: { id: this.activeTask || '1' },
            })
            break
          case 'olympiad-assignment':
            navigateReplace({
              name: 'olympiad-tasks-edit',
              query: {
                questions: this.questions.join(','),
                fromPage: this.fromPage,
                currentQuestion: String(this.$currentQuestion),
              },
              params: { id: this.activeTask || '1' },
            })
            break
          case 'lesson-assignment':
            navigateReplace({
              name: 'lesson-tasks-edit',
              query: {
                questions: this.questions.join(','),
                fromPage: this.fromPage,
                currentQuestion: String(this.$currentQuestion),
              },
              params: { id: this.activeTask || '1' },
            })
            break
          case 'exam-assignment':
            navigateReplace({
              name: 'exam-tasks-edit',
              query: {
                questions: this.questions.join(','),
                fromPage: this.fromPage,
                currentQuestion: String(this.$currentQuestion),
              },
              params: { id: this.activeTask || '1' },
            })
            break
          default:
            navigateReplace({
              name: 'test-tasks-edit',
              query: {
                fromPage: this.fromPage,
                currentQuestion: String(this.$currentQuestion),
              },
              params: { id: this.activeTask || '1' },
            })
        }
      } else {
        this.$router.go(-1)
      }
    },
    activeTask(value) {
      this.loadSpecificTask(value)
    },
    activeApplication(value) {
      if (value && this.taskType !== 'exam-assignment') loadApplication(value)
    },
    $canRefreshAfterSendingToReview(newValue) {
      if (newValue) {
        this.refreshPage()
      }
    },
    $canRefreshAfterSendingToRevision(newValue) {
      if (newValue) {
        this.refreshPage()
      }
    },
  },
  methods: {
    toEditPage() {
      let nameRoute = 'test-assignment'
      if (this.taskType === 'olympiad-assignment') nameRoute = 'olympiad-tasks-edit'
      if (this.taskType === 'test-assignment') nameRoute = 'lesson-tasks-edit'
      if (this.taskType === 'exam-assignment') nameRoute = 'exam-tasks-edit'
      this.$router.push({ name: nameRoute, params: { id: `${this.activeTask}` } })
    },
    async acceptApplications() {
      if (this.activeApplication) {
        await acceptApplicationsFx({ tickets: [+this.activeApplication] })
        this.refreshPage()
      }
    },
    sendToRevision() {
      if (this.activeApplication) loadModal([+this.activeApplication])
    },
    showComments() {
      if (this.activeTask) loadCommentModal(parseInt(`${this.activeApplication}`, 10))
    },
    sendToReview() {
      if (this.activeTask) loadModalToSendForCheck([parseInt(this.activeTask, 10)])
    },
    refreshPage() {
      if (this.activeTask) this.loadSpecificTask(+this.activeTask)
      if (this.activeApplication && this.taskType !== 'exam-assignment')
        loadApplication(+this.activeApplication)
    },
    loadSpecificTask(value: number) {
      if (value && this.taskType) {
        switch (this.taskType) {
          case 'test-assignment':
            loadTestTask(value)
            break
          case 'olympiad-assignment':
            loadOlympiadTask(value)
            break
          case 'lesson-assignment':
            loadLessonTask(value)
            break
          case 'exam-assignment':
            loadExamTask(value)
            break
          default:
            loadLessonTask(value)
        }
      }
    },
    goToSelectedItem() {
      this.$router.replace(
        combineRouteQueries(this.$route.query, { currentQuestion: String(this.$currentQuestion) })
      )
    },
    async setIframeInitialHeight() {
      await this.$nextTick()
      const iframe = this.$refs.iframe as HTMLIFrameElement
      const contentContainer = this.$el.parentElement as HTMLElement
      this.iframeHeight =
        contentContainer.getBoundingClientRect().bottom - iframe.getBoundingClientRect().top - 60
    },
  },
  beforeRouteEnter(to, from, next) {
    const { questions, token, taskType, applications, fromPage } = to.query
    if (
      questions &&
      typeof questions === 'string' &&
      token &&
      typeof token === 'string' &&
      taskType &&
      (taskType === 'test-assignment' ||
        taskType === 'olympiad-assignment' ||
        taskType === 'lesson-assignment' ||
        taskType === 'exam-assignment') &&
      fromPage &&
      (fromPage === 'applications' ||
        fromPage === 'tasks' ||
        fromPage === 'labels' ||
        fromPage === 'tags' ||
        fromPage === 'results-tests-students' ||
        fromPage === 'exams-answers-list')
    ) {
      next((vm) => {
        vm.questions = questions.split(',')
        vm.token = token
        vm.taskType = taskType
        if (applications && typeof applications === 'string') {
          vm.applications = applications.split(',').map((appId) => Number(appId))
        }
        vm.fromPage = fromPage
      })
    } else {
      next({ name: 'home' })
    }
  },
  mounted() {
    this.setIframeInitialHeight()
    window.addEventListener('resize', this.setIframeInitialHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setIframeInitialHeight)
    toggleIsPreview(true)
  },
})
