import { createApiEffect } from '@/features/api/common/create-api-effect'
import { CreateCourseFxParams, Courses } from '@/features/api/learning/types'

export const createCoursesFx = createApiEffect<CreateCourseFxParams, Courses>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/course-app/courses/',
    body,
  }),
})
