
























































































import Vue from 'vue'
import { DropdownItem } from '@/pages/common/types'
import GroupsDropdown from '@/pages/learning/learning-courses/parts/inputs/groups-lessons-block/GroupsBlock.vue'
import LessonsDropdown from '@/pages/learning/learning-courses/parts/inputs/groups-lessons-block/LessonsDropdown.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  defaultGroup,
  LessonsCourseType,
  LessonsGroupCourseType,
} from '@/pages/learning/learning-courses/types'
import {
  $lessons,
  lessonsDropdownModule,
} from '@/pages/learning/learning-courses/create/parts/lessons-courses/lesson-dropdown.model'
import {
  $lessonsCourse,
  setLessonsCourse,
} from '@/pages/learning/learning-courses/create/courses-create-page.model'
import Draggable from 'vuedraggable'
import { DatetimeLessonsGroup } from '@/features/api/learning/types'
import { config } from '@/config'
import { $token } from '@/features/api/common/request'

export default Vue.extend({
  name: 'LessonsCourses',
  components: {
    GroupsDropdown,
    LessonsDropdown,
    Icon,
    Draggable,
  },
  effector: {
    $lessons,
    ...lessonsDropdownModule.store,
    $lessonsCourse,
    setLessonsCourse,
    token: $token,
  },
  computed: {
    correctValue(): string {
      const currentItem = this.$item?.title
      return currentItem || this.$searchString
    },
  },
  methods: {
    ...lessonsDropdownModule.methods,
    onSetDatetime(datetimes: DatetimeLessonsGroup, lesson: LessonsCourseType) {
      setLessonsCourse(
        this.$lessonsCourse.map((l) => (l.id === lesson.id ? { ...lesson, datetimes } : l))
      )
    },
    setSelectedLesson(lesson: DropdownItem, idx: number) {
      if (!lesson) {
        setLessonsCourse(
          this.$lessonsCourse.map((l) =>
            l.blockId === idx ? { ...l, id: 0, name: '', title: '' } : l
          )
        )
      } else {
        setLessonsCourse(
          this.$lessonsCourse.map((l) =>
            l.blockId === idx
              ? { ...l, id: Number(lesson.name), name: lesson.title, title: lesson.title }
              : l
          )
        )
      }
    },
    setItem(lesson: LessonsCourseType, idx: number) {
      setLessonsCourse(
        this.$lessonsCourse.map((l, index) => (index === idx ? { ...l, ...lesson } : l))
      )
    },
    clear() {
      this.resetItem()
      this.resetSearchString()
    },
    addLesson() {
      setLessonsCourse([
        ...this.$lessonsCourse,
        {
          id: 0,
          blockId: this.$lessonsCourse.length,
          name: '',
          title: '',
          groups: [defaultGroup()],
        },
      ])
    },
    addGroups(lesson: LessonsCourseType) {
      const newLesson = lesson
      const highestID = Math.max.apply(
        null,
        newLesson.groups.map((el) => el.blockId || 0)
      )
      newLesson.groups = [...newLesson.groups, defaultGroup(highestID + 1)]
      setLessonsCourse(
        this.$lessonsCourse.map((l) => (l.blockId === lesson.blockId ? { ...l, ...newLesson } : l))
      )
    },
    removeGroup(lesson: LessonsCourseType, blockId: number) {
      const newLesson = lesson
      newLesson.groups = lesson.groups.filter((g) => g.blockId !== blockId)
      if (newLesson.groups.length === 0) {
        newLesson.groups = [defaultGroup()]
      }
      setLessonsCourse(
        this.$lessonsCourse.map((l) => (l.blockId === lesson.blockId ? { ...l, ...newLesson } : l))
      )
    },
    changeGroup(
      lesson: LessonsCourseType,
      idx: number,
      group: LessonsGroupCourseType,
      groupIdx: number
    ) {
      const newLesson = lesson
      newLesson.groups = lesson.groups.map((g, i) => (i !== groupIdx ? g : group))
      setLessonsCourse(
        this.$lessonsCourse.map((l, index) => (index === idx ? { ...l, ...newLesson } : l))
      )
    },
    removeLesson(lesson: LessonsCourseType) {
      if (this.$lessonsCourse.length > 1) {
        setLessonsCourse(this.$lessonsCourse.filter((l) => l.blockId !== lesson.blockId))
      }
    },
    handlerLessonsEnd() {
      const lessons = [...this.$lessonsCourse]
      lessons.map((lesson: LessonsCourseType, index: number) => ({ ...lesson, blockId: index }))
      setLessonsCourse(lessons)
    },
    getItems(lesson: LessonsCourseType) {
      return this.$lessons.findIndex((l) => l.title === lesson.title)
    },
    openLesson(id: string) {
      window.open(`${config.OLD_INTERFACE_DOMAIN}/lesson/${id}/preview?token=${this.token}`)
    },
  },
})
