import { createApiEffect } from '@/features/api/common/create-api-effect'
import { GetListQueryParams } from '@/features/api/types'

export const getForWhoFx = createApiEffect<
  GetListQueryParams,
  { value: string; display: string }[]
>({
  requestMapper: () => ({
    method: 'GET',
    url: '/api/course-app/courses/filter/students-type/',
  }),
})
