






















import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import {
  $draggableImages,
  removeDraggableImage,
  replaceDraggableImage,
  $droppableImages,
} from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'
import ImageMatchItem from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/parts/correct-images-match-picker/ImageMatchItem.vue'

export default Vue.extend({
  name: `CorrectImagesMatchPicker`,
  components: {
    BaseButton,
    ImageMatchItem,
  },
  effector: {
    $draggableImages,
    $droppableImages,
  },
  methods: {
    replaceDraggableImage,
    removeDraggableImage,
  },
})
