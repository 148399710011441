
































































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import Divider from '@/ui/divider/Divider.vue'
import StatusController from '@/pages/common/parts/status-controller/StatusContoller.vue'
import CourseTypeDropdown from '@/pages/learning/learning-courses/parts/inputs/course-type/CourseTypeDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import { courseWithTicketChanged } from '@/pages/learning/learning-courses/create/courses-create-page.model'
import { courseWithTicketChangedEdit } from '@/pages/learning/learning-courses/edit/courses-edit-page.model'
import { navigatePush } from '@/features/navigation/navigationMethods'

export default Vue.extend({
  name: 'LearningHeader',
  components: {
    Icon,
    BaseButton,
    BaseSwitch,
    Divider,
    StatusController,
    CourseTypeDropdown,
  },
  props: {
    title: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean>, default: true },
    isPreview: { type: Boolean as PropType<boolean> },
    fromPage: { type: String as PropType<string> },
    taskType: { type: String as PropType<string> },
    statusController: { type: Boolean as PropType<boolean>, default: false },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToList() {
      switch (this.$route.name) {
        case 'courses-creation-page':
          navigatePush({ name: 'courses-list' })
          break
        case 'courses-edit-page':
          navigatePush({ name: 'courses-list' })
          break
        default:
          navigatePush({ name: 'courses-list' })
      }
    },
    setCourseType(item: DropdownItem) {
      courseWithTicketChanged(item.name === 'С заявкой')
      courseWithTicketChangedEdit(item.name === 'С заявкой')
    },
  },
})
