import { createApiEffect } from '@/features/api/common/create-api-effect'
import { OnboardingCustomizationType } from '@/features/api/content/types'

export const setShowOnboardingFx = createApiEffect<OnboardingCustomizationType, void>({
  requestMapper: (params) => ({
    method: 'POST',
    url: '/api/content-app/customization/',
    body: params,
  }),
})
