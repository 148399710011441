import { createApiEffect } from '@/features/api/common/create-api-effect'
import { CreateFolderType, FolderType } from '@/features/api/assignment/types/types'

export const createExamFolderFx = createApiEffect<CreateFolderType, FolderType>({
  requestMapper: (params) => ({
    method: 'POST',
    url: '/api/assignment-app/exam-folder/',
    body: params,
  }),
})

export const updateExamFolderFx = createApiEffect<
  { id: number; body: CreateFolderType },
  FolderType
>({
  requestMapper: ({ id, body }) => ({
    method: 'PATCH',
    url: `/api/assignment-app/exam-folder/${id}/`,
    body,
  }),
})
