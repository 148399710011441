import DefaultLayout from '@/layouts/DefaultLayout.vue'
import StudentsPage from '@/pages/users/students/list/StudentsPage.vue'
import GroupsPage from '@/pages/users/groups/list/GroupsPage.vue'
import EditStudentPage from '@/pages/users/students/edit/EditStudentPage.vue'
import EmployersPage from '@/pages/users/employes/list/EmployersPage.vue'
import EditEmployesPage from '@/pages/users/employes/edit/EditEmployesPage.vue'
import CreateGroupPage from '@/pages/users/groups/create/CreateGroupPage.vue'

export default [
  {
    name: 'users',
    path: '/users',
    redirect: { name: 'users-list' },
    component: DefaultLayout,
    children: [
      {
        name: 'employers',
        path: 'employers',
        component: DefaultLayout,
        redirect: { name: 'employers-list' },
        children: [
          {
            name: 'employers-list',
            path: 'employers-list',
            component: EmployersPage,
            meta: {
              title: 'Сотрудники - Школа Летово',
            },
          },
          {
            name: 'create-employee',
            path: 'create-employee',
            component: EditEmployesPage,
            meta: {
              title: 'Создание сотрудника - Школа Летово',
            },
          },
          {
            name: 'edit-employee',
            path: 'create/:id',
            component: EditEmployesPage,
            meta: {
              title: 'Редактирование сотрудника - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'students',
        path: 'students',
        component: DefaultLayout,
        redirect: { name: 'users-list' },
        children: [
          {
            name: 'users-list',
            path: 'users-list',
            component: StudentsPage,
            meta: {
              title: 'Ученики - Школа Летово',
            },
          },
          {
            name: 'edit-student',
            path: 'create/:id',
            component: EditStudentPage,
            meta: {
              title: 'Редактирование пользователя - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'groups',
        path: 'groups',
        component: DefaultLayout,
        redirect: { name: 'groups-list' },
        children: [
          {
            name: 'groups-list',
            path: 'groups-list',
            component: GroupsPage,
            meta: {
              title: 'Группы - Школа Летово',
            },
          },
          {
            name: 'create-group',
            path: 'create',
            component: CreateGroupPage,
            meta: {
              title: 'Создание группы - Школа Летово',
            },
          },
          {
            name: 'edit-group',
            path: 'create/:id',
            component: CreateGroupPage,
            meta: {
              title: 'Редактирование группы - Школа Летово',
            },
          },
        ],
      },
    ],
  },
]
