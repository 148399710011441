







import Vue from 'vue'
import InternalLoader from '@/pages/common/parts/loader/InternalLoader.vue'

export default Vue.extend({
  components: { InternalLoader },
})
