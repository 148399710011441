export const TEXTAREA_MAX_LENGTH = 1000

export const mapObjectType = {
  theme: 'Тема',
  subject: 'Предмет',
  test_assignment: 'Тестовое задание',
  olympiad_assignment: 'Олимпиадное задание',
  lesson_assignment: 'Урочное задание',
  assignment_folder: 'Папка урочных заданий',
}

export const mapApplicationTypeToRoute = {
  subject: 'subjects',
  theme: 'themes',
  lesson_assignment: 'lesson-tasks',
  olympiad_assignment: 'olympiad-tasks',
  test_assignment: 'test-tasks',
}
