




















import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { saveAchievement } from '@/pages/settings/achievements/model/achievements-page.model'

export default Vue.extend({
  name: 'AchievementHeader',
  components: {
    BaseButton,
  },
  props: {
    title: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean>, default: true },
    fromPage: { type: String as PropType<string> },
  },
  methods: {
    saveAchievement,
  },
})
