



























import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Divider from '@/ui/divider/Divider.vue'
import Icon from '@/ui/icon/Icon.vue'
import Loader from '@/pages/common/parts/loader/Loader.vue'
import {
  $exportPopupVisibility,
  changeExportPopupVisibility,
} from '@/pages/common/parts/header/header-popup/header-popup.model'

export default Vue.extend({
  name: 'DownloadButton',
  components: {
    BaseButton,
    Divider,
    Icon,
    Loader,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
  effector: {
    $exportPopupVisibility,
  },
  methods: { changeExportPopupVisibility },
})
