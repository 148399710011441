












import ToastsContainer from '@/features/toasts/ToastsContainer.vue'
import { $isReadyMounted, loadCurrentSessionFx } from '@/pages/login/login.model'
import InternalLoader from '@/pages/common/parts/loader/InternalLoader.vue'

export default {
  components: { ToastsContainer, InternalLoader },
  effector: {
    isLoading: loadCurrentSessionFx.pending,
    isReady: $isReadyMounted,
  },
}
