import { NavItem } from '@/pages/common/navigation/types'

export const data: NavItem[] = [
  {
    id: 'requests',
    title: 'Заявки',
    icon: 'success',
    type: 'applications',
    children: [
      { title: 'Входящие заявки', link: 'incoming-list', type: 'incoming-applications' },
      { title: 'Исходящие заявки', link: 'outgoing-list', type: 'outgoing-applications' },
      {
        title: 'Входящие заявки на удаление',
        link: 'incoming-deletion-list',
        type: 'incoming-deletion-applications',
      },
      {
        title: 'Исходящие заявки на удаление',
        link: 'outgoing-deletion-list',
        type: 'outgoing-deletion-applications',
      },
    ],
  },
  {
    id: 'tasks',
    title: 'Банк заданий',
    icon: 'tasks',
    children: [
      { title: 'Тестовые задания', link: 'test-tasks-list' },
      { title: 'Олимпиадные задания', link: 'olympiad-tasks-list' },
      { title: 'Задания для уроков', link: 'lesson-tasks-list' },
      { title: 'Задания для очных экзаменов', link: 'exam-tasks-list' },
    ],
  },
  {
    id: 'tests',
    title: 'Тестирование',
    icon: 'check',
    children: [
      { title: 'Тесты', link: 'tests-list' },
      { title: 'Метки для тестовых заданий', link: 'no-page' },
    ],
  },
  {
    id: 'olympiads',
    title: 'Олимпиады',
    icon: 'olympiad',
    children: [
      { title: 'Олимпиады', link: 'no-page' },
      { title: 'Метки олимпиад', link: 'no-page' },
    ],
  },
  {
    id: 'learning',
    title: 'Обучение',
    icon: 'book',
    children: [
      { title: 'Уроки', link: 'lessons-list' },
      { title: 'Курсы', link: 'courses-list' },
      { title: 'Заявки', link: 'courses-application-list' },
      { title: 'Ученики', link: 'no-page' },
    ],
  },
  {
    id: 'exams',
    title: 'Экзамены',
    icon: 'list',
    children: [
      {
        id: 'exams-part-time',
        title: 'Заочный',
        isSubmenu: true,
        children: [
          { title: 'Тесты', link: 'part-time-exams-tests-list' },
          { title: 'Назначения', link: 'exams-appointment-list' },
          { title: 'Попытки', link: 'exams-attempts-list' },
          { title: 'Ответы', link: 'exams-answers-list' },
        ],
      },
      {
        id: 'exams-full-time',
        title: 'Очный',
        isSubmenu: true,
        children: [
          { title: 'Тесты', link: 'full-exam-tests-list' },
          { title: 'Назначения', link: 'full-appointment-list' },
          { title: 'Попытки', link: 'exams-full-attempts-list' },
          { title: 'Ответы', link: 'exams-full-answers-list' },
        ],
      },
      { title: 'Прокторы', link: 'proctors-list' },
    ],
  },
  {
    id: 'results',
    title: 'Результаты',
    icon: 'statistics',
    children: [
      { title: 'Тесты (все)', link: 'results-tests-all' },
      { title: 'Тесты (по ученикам)', link: 'results-tests-students' },
      { title: 'Экзамен', link: 'no-page' },
      { title: 'Олимпиадные задания', link: 'results-olympiad-tasks' },
      {
        id: 'results-courses',
        title: 'Курсы',
        isSubmenu: true,
        children: [
          { title: 'Курсы - Общие', link: 'results-courses-list' },
          { title: 'Курсы - По ученикам', link: 'results-courses-student-list' },
          { title: 'Управление проверкой урока', link: 'results-courses-check-list' },
          { title: 'Генерация сертификатов', link: 'certificates-generator-table' },
        ],
      },
      { title: 'Сводные результаты по пользователю', link: 'no-page' },
    ],
  },
  {
    id: 'users',
    title: 'Пользователи',
    icon: 'user',
    children: [
      { title: 'Сотрудники', link: 'employers-list' },
      { title: 'Ученики', link: 'users-list' },
      { title: 'Группы пользователей', link: 'groups-list' },
      { title: 'Статусы заявок учеников', link: 'no-page' },
    ],
  },
  {
    id: 'callback',
    title: 'Обратная связь',
    icon: 'callback',
    children: [
      { title: 'Комментарии к урокам', link: 'comments-on-lessons-list' },
      { title: 'Подписчики на рассылку', link: 'subscribers-on-email' },
    ],
  },
  {
    id: 'catalog',
    title: 'Справочник',
    icon: 'folder',
    children: [
      { title: 'Темы', link: 'themes-list' },
      { title: 'Предметы', link: 'subjects-list' },
      { title: 'Библиотека ресурсов', link: 'resources-list' },
      { title: 'Системные файлы', link: 'files-list' },
      { title: 'Метки для тестовых заданий', link: 'labels-list' },
      { title: 'Теги для олимпиадных заданий', link: 'tags-list' },
      { title: 'Инструкции для олимпиад', link: 'instructions-list' },
    ],
  },
  {
    id: 'content',
    title: 'Контент',
    icon: 'display',
    children: [
      { title: 'Новости', link: 'news-list' },
      { title: 'FAQ', link: 'faq-list' },
      { title: 'Слайдеры', link: 'slides-list' },
      { title: 'Баннеры', link: 'banners-list' },
    ],
  },
  {
    id: 'settings',
    title: 'Настройки',
    icon: 'filter',
    children: [
      { title: 'Страница достижений', link: 'achievement-page' },
      { title: 'Страница кружков', link: 'hobbies-page' },
      { title: 'Страница поступления', link: 'no-page' },
    ],
  },
  {
    id: 'backups',
    title: 'Бэкапы',
    icon: 'backup',
    children: null,
  },
]
