import { DropdownItem } from '@/pages/common/types'

export class TestResultExtraFilterElement {
  subject: DropdownItem | null = null

  test: DropdownItem | null = null

  sign: DropdownItem | null = null

  value: DropdownItem | null = null
}
