

























import Vue, { PropType } from 'vue'
import TreeNode from '@/pages/dictionary/resources/list/parts/tree/parts/TreeNode.vue'
import {
  $resourcesTree,
  $resourcesTreeTotal,
} from '@/pages/dictionary/resources/list/resources-page.model'
import NoDataContent from '@/pages/common/parts/no-data-content/NoDataContent.vue'
import { FiltersParams } from '@/pages/common/types'

export default Vue.extend({
  name: 'ResourcesTree',
  components: {
    TreeNode,
    NoDataContent,
  },
  props: {
    filters: { type: Object as PropType<FiltersParams> },
  },
  effector: { $resourcesTree, $resourcesTreeTotal },
})
