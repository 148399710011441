import { attach } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { dropdownCommentsModules } from '@/pages/callback/comments-on-lessons/model/tooltip-filter.model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import { exportCommentsOnLessonsListFX } from '@/features/api/callback/export-comments-on-lessons-list'
import fileDownload from 'js-file-download'

export const callbackCommentsOnLessonsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownCommentsModules
)

export const commentsOnLessonsPageParams = createPageParamsModel()

export const exportCommentsOnLessonsList = attach({
  effect: exportCommentsOnLessonsListFX,
  source: [callbackCommentsOnLessonsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => {
    return { ...filters, ...exportedColumns }
  },
})

exportCommentsOnLessonsList.doneData.watch((res) =>
  fileDownload(res.body, 'commentsOnLessonsList.xlsx')
)
