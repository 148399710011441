



































import Vue from 'vue'
import BaseHeader from '@/ui/header/BaseHeader.vue'
import Icon from '@/ui/icon/Icon.vue'
import HeaderLogo from '@/pages/common/parts/header/HeaderLogo.vue'
import { $isOpened, changeNavState } from '@/pages/common/navigation/navigation.model'
import { modalLogoutVisibilityChanged } from '@/pages/common/modal-logout/modal-logout.model'
import { $currentUser } from '@/features/session'

export default Vue.extend({
  name: 'PageHeader',
  components: { BaseHeader, Icon, HeaderLogo },
  effector: {
    $isOpened,
    $currentUser,
  },
  computed: {
    userName() {
      return this.$currentUser && `${this.$currentUser.first_name} ${this.$currentUser.last_name} `
    },
  },
  methods: {
    changeNavState,
    modalLogoutVisibilityChanged,
  },
})
