import { createEvent, createStore, restore, combine, forward } from 'effector-root'
import { spread } from 'patronum'
import { getExamTestByIdFx } from '@/features/api/full-time-tests/get-exam-test-by-id'
import { foldersDropdownModule } from '@/pages/common/dropdowns/exams/exams-tests-folders/folder-dropdown.model'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { classesDropdownModule } from '@/pages/common/dropdowns/class/classes-dropdown.model'
import {
  examDurationDropdownModel,
  $durationMin,
  examDurationMapData,
  setDuration,
} from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/exam-duration-dropdown/exam-duration-dropdown.model'
import { tasksChoiceDropdownModel } from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/tasks-choice-dropdown.model'
import { tasksChoiceMapData } from '@/pages/exams/part-time-test/create/parts/TasksChoiceDropdown/constant'
import {
  ElementTypeNames,
  TaskDataType,
  TaskTypeNames,
} from '@/pages/learning/learning-lessons/create/model/types'
import {
  MainUnitType,
  OptionalExamElements,
  OptionalMainUnitType,
  SpreadedValueType,
  UnitType,
} from '@/features/api/full-time-tests/types'
import { ExamTestsAssignBlock } from '@/pages/learning/learning-lessons/create/model/assignment-block.model'
import {
  defineStateAfterAddIntroBlock,
  defineStateAfterDeleteIntroBlock,
} from '@/pages/exams/full-time-exam-test/create/model/state-definitors'

export const resetFields = createEvent()

// step1
export const setInternalName = createEvent<string>()
export const $internalName = restore(setInternalName, '').reset(resetFields)

export const setName = createEvent<string>()
export const $name = restore(setName, '').reset(resetFields)

export const setInformation = createEvent<string>()
export const $information = restore(setInformation, '').reset(resetFields)

export const setInstruction = createEvent<string>()
export const $instruction = restore(setInstruction, '').reset(resetFields)

export const setResume = createEvent<string>()
export const $resume = restore(setResume, '').reset(resetFields)

export const setIsActive = createEvent<boolean>()
export const $IsActive = restore(setIsActive, false).reset(resetFields)

export const setCmsCommentary = createEvent<string>()
export const $cmsCommentary = restore(setCmsCommentary, '').reset(resetFields)

export const $step1Fields = combine(
  $internalName,
  $name,
  $instruction,
  $information,
  $resume,
  $IsActive,
  $cmsCommentary,
  foldersDropdownModule.store.$item,
  subjectsDropdownModel.store.$item,
  classesDropdownModule.store.$item,
  (
    internal_name,
    name,
    instruction,
    information,
    resume,
    is_active,
    cms_commentary,
    folder,
    subject,
    study
  ) => {
    return {
      internal_name,
      name,
      instruction,
      information,
      resume,
      is_active,
      cms_commentary,
      study_year_id: study?.name,
      subject_id: subject?.name,
      folder_id: folder?.name,
    }
  }
)

// step2

// intro_part_tab
export const setIsIntroPart = createEvent<boolean>()
export const $IsIntroPart = restore(setIsIntroPart, false).reset(resetFields)

export const setIntroDurationMin = createEvent<string | number>()
export const $introDurMin = restore(setIntroDurationMin, '').reset(resetFields)

export const changeIntroBlock = createEvent<OptionalExamElements>()
export const addIntroBlock = createEvent<ElementTypeNames>()
export const setIntroBlocks = createEvent<OptionalExamElements[]>()
const loadIntroBlocks = createEvent<OptionalMainUnitType>()
export const deleteIntroBlock = createEvent<number>()

const $introBLockID = createStore<number>(0).on(loadIntroBlocks, (_, res) => res.id)

export const $introUnitBlocks = createStore<OptionalExamElements[]>([])
  .on(setIntroBlocks, (state, payload) => payload)
  .on(changeIntroBlock, (state, payload) => defineStateAfterAddIntroBlock(state, payload))
  .on(addIntroBlock, (state, element_type) => {
    const newState = [...state]
    if (element_type === 'assignment') {
      const assignmentBloksLength = state.filter(
        (blocks) => blocks.element_type === 'assignment'
      ).length
      newState.push({
        blockId: state.length,
        element_type,
        player_link: undefined,
        assigmentBlockId: assignmentBloksLength,
      })
      ExamTestsAssignBlock.methods.addAssignmentBlock({ id: assignmentBloksLength })
    } else {
      newState.push({ blockId: state.length, element_type })
    }
    return newState
  })
  .on(loadIntroBlocks, (state, payload) => {
    const newState: OptionalExamElements[] = []
    payload?.elements.forEach((block, index) => {
      if (block.element_type === ElementTypeNames.assignment) {
        const assignmentBloksLength = block.assignment || index
        newState.push({
          ...block,
          blockId: assignmentBloksLength,
          assigmentBlockId: assignmentBloksLength,
        })
        const task: TaskDataType = {
          score: block.score ? block.score : null,
          id: assignmentBloksLength,
          blockId: assignmentBloksLength,
          difficulty: null,
          wording: '',
          type: 'SHORT_CLOSED_ANSWER',
        }
        const AssignmentBlockData = {
          id: assignmentBloksLength,
          taskType: TaskTypeNames.exam,
          ...(block.assignment ? { task } : {}),
          useTaskScore: block.use_task_score,
          score: !block.use_task_score ? String(block.score || 0) : '',
        }
        ExamTestsAssignBlock.methods.addAssignmentBlock(AssignmentBlockData)
      } else if (block.element_type === ElementTypeNames.file) {
        newState.push({
          ...block,
          ...(block.media ? { media: block.media } : {}),
          blockId: block.id,
        })
      } else {
        newState.push({ ...block, blockId: block.id })
      }
    })
    return newState
  })
  .on(deleteIntroBlock, (state, id) => defineStateAfterDeleteIntroBlock(state, id))
  .reset(resetFields)

export const $step2FieldIntroTab = combine(
  $IsIntroPart,
  $introDurMin,
  $introUnitBlocks,
  $introBLockID,
  (intro_part, intro_dur_min, intro_unit, intro_unit_id) => {
    if (intro_part) {
      return {
        intro_part,
        intro_dur_min: Number(intro_dur_min),
        intro_unit: {
          ...(intro_unit_id !== 0 && { id: intro_unit_id }),
          elements: intro_unit,
          name: 'intro_unit',
        },
      }
    }

    return { intro_part }
  }
)

// main_part_tab
export const changeMainBlock = createEvent<{
  type: UnitType
  depth: string
  obj: SpreadedValueType
}>()
export const addMainBlock = createEvent<{
  type: UnitType
  depth: string
  element_type: ElementTypeNames | null
}>()
export const setMainBlocks = createEvent<OptionalExamElements[]>()
const loadMainBlocks = createEvent<OptionalExamElements[]>()
export const deleteMainBlock = createEvent<{ type: UnitType; depth: string }>()
export const changeMainBlocksPagesPosition =
  createEvent<{ newIndex: number; oldIndex: number; blockId: number; pageId: number }>()

export const $mainBlocks = createStore<Partial<MainUnitType>[]>([])
  .on(changeMainBlocksPagesPosition, (state, payload) => {
    const newState = [...state]
    // @ts-ignore
    const newElements = [...newState[payload.blockId].pages[payload.pageId].elements]
    // @ts-ignore
    const temp = newElements[payload.oldIndex]
    // @ts-ignore
    const temp2 = newElements[payload.newIndex]
    // @ts-ignore
    newState[payload.blockId].pages[payload.pageId].elements[payload.oldIndex] = { ...temp2 }
    // @ts-ignore
    newState[payload.blockId].pages[payload.pageId].elements[payload.newIndex] = { ...temp }
    return newState
  })
  .on(setMainBlocks, (state, payload) => payload)
  .on(changeMainBlock, (state, payload) => {
    const newState = [...state]
    const ids = payload.depth.split(',')
    switch (payload.type) {
      case 'block':
        newState[ids[0]] = { ...newState[ids[0]], ...payload.obj }
        return newState
      case 'page':
        newState[ids[0]].pages[ids[1]] = { ...newState[ids[0]].pages[ids[1]], ...payload.obj }
        return newState
      case 'element':
        newState[ids[0]].pages[ids[1]].elements[ids[2]] = {
          ...newState[ids[0]].pages[ids[1]].elements[ids[2]],
          ...payload.obj,
        }
        return newState
      default:
        return newState
    }
  })
  .on(addMainBlock, (state, payload) => {
    const newState = [...state]
    const ids = payload.type !== 'block' ? payload.depth.split(',') : payload.depth
    switch (payload.type) {
      case 'block':
        newState.push({
          // blockId: state.length,
          pages: [],
          is_tr_allowed: false,
          duration_min: null,
          name: '',
        })
        return newState
      case 'page':
        newState[ids[0]].pages.push({
          // pageId: newState[ids[0]].exam-pages.length,
          description: '',
          name: '',
          elements: [],
        })
        return newState
      case 'element':
        newState[ids[0]].pages[ids[1]].elements.push({
          element_type: payload.element_type,
          // id: newState[ids[0]].exam-pages[ids[1]].elements,
        })
        return newState
      default:
        return newState
    }
  })
  .on(loadMainBlocks, (_, payload) => {
    return payload
  })
  .on(deleteMainBlock, (state, payload) => {
    const newState = [...state]
    const ids = payload.depth.split(',').map((el) => Number(el))
    switch (payload.type) {
      case 'block':
        newState.splice(ids[0], 1)
        return newState
      case 'page':
        // @ts-ignore
        newState[ids[0]].pages.splice(ids[1], 1)
        return newState
      case 'element':
        // @ts-ignore
        newState[ids[0]].pages[ids[1]].elements.splice(ids[2], 1)
        return newState
      default:
        return newState
    }
  })
  .reset(resetFields)

export const $step2FieldsMainTab = combine(
  examDurationDropdownModel.store.$item,
  tasksChoiceDropdownModel.store.$item,
  $durationMin,
  $mainBlocks,
  (examDuration, tasks_choice, main_dur_min, main_unit) => {
    return {
      tasks_choice: tasks_choice?.name,
      ...(main_unit.length > 0 && { main_unit }),
      main_part_duration_type: examDuration?.name,
      ...(examDuration?.name === 'overall' && { main_dur_min }),
    }
  }
)

// upload files tab

export const setLoadPartText = createEvent<string>()
export const $loadPartText = restore(setLoadPartText, '').reset(resetFields)

export const setIsLoadPart = createEvent<boolean>()
export const $isLoadPart = restore(setIsLoadPart, false).reset(resetFields)

export const setLoadDurationMin = createEvent<string | number>()
export const $loadDurMin = restore(setLoadDurationMin, '').reset(resetFields)

export const changeUploadBlock = createEvent<{ value: string; id: number }>()
export const addUploadBlock = createEvent<void>()
const loadUploadBlocks = createEvent<OptionalMainUnitType>()
export const deleteUploadBlock = createEvent<number>()
export const changeUploadBlocksPosition = createEvent<{ newIndex: number; oldIndex: number }>()

const $uploadFilesBLockID = createStore<number>(0).on(loadUploadBlocks, (_, res) => res.id)

export const $uploadFilesBlock = createStore<OptionalExamElements[]>([])
  .on(changeUploadBlocksPosition, (state, payload) => {
    const newState = [...state]
    const element = state[payload.oldIndex]
    newState.splice(payload.oldIndex, 1)
    newState.splice(payload.newIndex, 0, element)
    return newState
  })
  .on(changeUploadBlock, (state, payload) => {
    const newState = [...state]
    newState[payload.id] = { ...newState[payload.id], text: payload.value }
    return newState
  })
  .on(addUploadBlock, (state) => {
    const newState = [...state]
    newState.push({ text: '', element_type: ElementTypeNames.answer_file_field })
    return newState
  })
  .on(loadUploadBlocks, (_, res) => {
    if (res) return res.elements
    return []
  })
  .on(deleteUploadBlock, (state, id) => {
    const newState = [...state]
    newState.splice(id, 1)
    return newState
  })
  .reset(resetFields)

export const $step2FieldsLoadTab = combine(
  $isLoadPart,
  $loadDurMin,
  $uploadFilesBlock,
  $uploadFilesBLockID,
  (load_part, load_dur_min, load_unit, load_unit_id) => {
    if (load_part) {
      return {
        load_part,
        load_dur_min: Number(load_dur_min),
        load_unit: {
          ...(load_unit_id !== 0 && { id: load_unit_id }),
          elements: load_unit,
          name: 'load_unit',
        },
      }
    }
    return { load_part }
  }
)

spread({
  source: getExamTestByIdFx.doneData.map((res) => ({
    ...res.body,
  })),
  targets: {
    // step1
    name: $name,
    subject: subjectsDropdownModel.methods.itemChanged.prepend(
      (payload: { id: number; name: string }) => ({
        name: `${payload.id}`,
        title: payload.name,
      })
    ),
    study_year: classesDropdownModule.methods.itemChanged.prepend(
      (payload: { id: number; name: string }) => ({
        name: `${payload.id}`,
        title: payload.name,
      })
    ),
    folder: foldersDropdownModule.methods.itemChanged.prepend(
      (payload: { id: number; name: string }) => ({
        name: `${payload.id}`,
        title: payload.name,
      })
    ),
    cms_commentary: $cmsCommentary,
    internal_name: $internalName,
    instruction: $instruction,
    information: $information,
    resume: $resume,
    is_active: $IsActive,
    // step2 intro
    intro_part: $IsIntroPart,
    intro_dur_min: $introDurMin,
    intro_unit: [
      loadIntroBlocks,
      setIntroDurationMin.prepend((unit: OptionalMainUnitType) => unit?.duration_min || ''),
    ],
    // step2 main
    main_unit: loadMainBlocks,
    tasks_choice: tasksChoiceDropdownModel.methods.itemChanged.prepend(
      (payload: number) => tasksChoiceMapData[payload]
    ),
    main_dur_min: $durationMin,
    main_part_duration_type: examDurationDropdownModel.methods.itemChanged.prepend(
      (payload: string | null) => {
        return payload === 'per_block' ? examDurationMapData[0] : examDurationMapData[1]
      }
    ),
    // step2 load
    load_part: $isLoadPart,
    load_dur_min: $loadDurMin,
    load_unit: loadUploadBlocks,
    load_part_text: $loadPartText,
  },
})

forward({
  from: resetFields,
  to: [
    foldersDropdownModule.methods.resetDropdown,
    tasksChoiceDropdownModel.methods.resetDropdown,
    classesDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    examDurationDropdownModel.methods.resetDropdown,
    setDuration.prepend(() => ''),
  ],
})
