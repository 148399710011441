










import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'BaseButton',
  props: {
    borderWithoutBg: { type: Boolean },
    yellow: { type: Boolean },
    big: { type: Boolean },
    small: { type: Boolean },
    disabled: { type: Boolean as PropType<boolean>, default: false },
  },
})
