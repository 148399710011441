
























import Vue from 'vue'
import LearningFooter from '@/pages/learning/learning-courses/list/common/Footer.vue'
import LearningHeader from '@/pages/learning/learning-courses/list/common/Header.vue'
import LeftBlock from '@/pages/learning/learning-courses/create/common/LeftBlock.vue'
import RightBlock from '@/pages/learning/learning-courses/create/common/RightBlock.vue'
import {
  $canSaveCourse,
  save,
  clearFieldsCourseCreate,
  changeIfRedirect,
} from '@/pages/learning/learning-courses/create/courses-create-page.model'

export default Vue.extend({
  name: 'CoursesCreationPage',
  components: {
    LearningHeader,
    LearningFooter,
    LeftBlock,
    RightBlock,
  },
  effector: {
    $canSaveCourse,
  },
  methods: {
    updateCourse(isRedirect: boolean) {
      changeIfRedirect(isRedirect)
      save()
    },
  },
  beforeDestroy() {
    clearFieldsCourseCreate()
  },
})
