import { combine, createEvent, forward, restore } from 'effector-root'
import { spread } from 'patronum'
import { getProctorIdFx } from '@/features/api/proctors/get-proctor-dy-id'
import { serviceDropdownModule } from '@/pages/common/dropdowns/proctors/service-dropdown/service-dropdown.model'

export const resetFields = createEvent()

export const setProctorsLogin = createEvent<string>()
export const $proctorsLogin = restore(setProctorsLogin, '').reset(resetFields)

export const setProctorsPassword = createEvent<string>()
export const $proctorsPassword = restore(setProctorsPassword, '').reset(resetFields)

export const setProctorsCmsCommentary = createEvent<string>()
export const $proctorsCmsCommentary = restore(setProctorsCmsCommentary, '').reset(resetFields)

export const setProctorsNeedAdd = createEvent<boolean>()
export const $proctorsNeedAdd = restore(setProctorsNeedAdd, false).reset(resetFields)

export const setProctorsIsActive = createEvent<boolean>()
export const $proctorsIsActive = restore(setProctorsIsActive, true).reset(resetFields)

export const $disabledSaveButtons = combine(
  $proctorsLogin,
  $proctorsPassword,
  serviceDropdownModule.store.$item,
  (login, password, service) => {
    return !login || !password || !service
  }
)

export const $proctorSendForm = combine(
  $proctorsLogin,
  $proctorsPassword,
  serviceDropdownModule.store.$item,
  $proctorsCmsCommentary,
  $proctorsIsActive,
  $proctorsNeedAdd,
  (login, password, service, cms_commentary, is_active, need_add) => {
    return {
      is_active,
      login,
      password,
      cms_commentary,
      need_add,
      service: service?.name || '',
    }
  }
)

spread({
  source: getProctorIdFx.doneData.map((res) => ({
    ...res.body,
    login: res.body.login,
    password: res.body.password,
    cms_commentary: res.body.cms_commentary,
    need_add: res.body.need_add,
    is_active: res.body.is_active,
    service: res.body.service,
  })),
  targets: {
    login: $proctorsLogin,
    password: $proctorsPassword,
    cms_commentary: $proctorsCmsCommentary,
    is_active: $proctorsIsActive,
    need_add: $proctorsNeedAdd,
    service: serviceDropdownModule.methods.itemChanged.prepend(
      (payload: { id: number; name: string } | null) => {
        if (payload)
          // TODO: Веременно, пока одно значение
          // return {
          //   name: `${payload.id}`,
          //   title: payload.name,
          // }
          return {
            name: 'proctoredu',
            title: 'ProctorEdu',
          }
        return null
      }
    ),
  },
})
forward({
  from: resetFields,
  to: serviceDropdownModule.methods.resetDropdown,
})
