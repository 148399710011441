




















import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { $permissions } from '@/features/session'

export default Vue.extend({
  name: 'PageHeader',
  effector: {
    $permissions,
  },
  components: {
    BaseButton,
  },
})
