import { Subject } from '@/features/api/subject/types'

export type Lesson = {
  id: number
  name: string
  number: number
  subject: Subject
  theme: { id: number; name: string }
  course: string
}

export type GetNameOptionsResponseType = {
  id: number
  name: string
}

export type RequestDeleteLessonsParams = {
  lessons: number[]
  ticket_comment?: string
}

// eslint-disable-next-line no-shadow
export enum DropdownLessonsEnum {
  theme = 'theme',
  course = 'course',
  subject = 'subject',
}
