export const getSrcFromHtml = (str: string) => {
  const matched = str.match(new RegExp('src="([^">]+)"', 'g'))
  const sliced = matched && matched.length > 0 ? matched[0].slice(5, -1) : null
  return sliced
}

export const cropString = (str: string) => {
  let cropped = str.substring(str.indexOf('"') + 1)
  cropped = cropped.substring(0, cropped.length - 2)
  return cropped
}

export const urlEncoder = (url: string) =>
  url && url.includes('storage.yandex') ? encodeURI(url) : url
