
























import Vue from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import FormLabel from '@/ui/label/FormLabel.vue'
import {
  $draggableText,
  replaceDraggableText,
  addDraggableText,
  removeDraggableText,
} from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/moving-images-on-image-answer-form.model'
import TextMatchItem from './TextMatchItem.vue'

export default Vue.extend({
  name: `CorrectTextMatchPicker`,
  effector: {
    $draggableText,
  },
  components: {
    FormLabel,
    BaseButton,
    TextMatchItem,
  },
  methods: {
    add: addDraggableText,
    replace: replaceDraggableText,
    remove: removeDraggableText,
  },
})
