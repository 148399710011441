import { createApiEffect } from '@/features/api/common/create-api-effect'
import { TableDataResponse } from '@/features/api/types'
import { GetStudentsListQueryParams, StudentList } from '@/features/api/student/types'

export const getStudentsListFx = createApiEffect<
  GetStudentsListQueryParams,
  TableDataResponse<StudentList[]>
>({
  requestMapper: (query) => ({
    method: 'GET',
    url: '/api/user-app/student/list/',
    query,
  }),
})
