











import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  $service,
  serviceDropdownModule,
} from '@/pages/common/dropdowns/proctors/service-dropdown/service-dropdown.model'

export default Vue.extend({
  name: 'ServiceFilterDropdown',
  components: {
    FilterDropdown,
  },
  effector: {
    $service,
    ...serviceDropdownModule.store,
  },
  methods: {
    ...serviceDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
})
