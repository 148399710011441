import { createApiEffect } from '@/features/api/common/create-api-effect'
import { OnboardingCustomizationType } from '@/features/api/content/types'

export const patchShowOnboardingFx = createApiEffect<OnboardingCustomizationType, void>({
  requestMapper: (params) => ({
    method: 'PATCH',
    url: '/api/content-app/customization/onboarding/',
    body: params,
  }),
})
