









































import Vue, { VueConstructor } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { Vuetable } from 'vuetable-2'
import Modal from '@/ui/modal/Modal.vue'
import { $inputValue, inputValueChanged } from '@/ui/wysiwyg/videos-storage.model'
import { VideoTableFields } from '@/ui/wysiwyg/video-storage-fields'
import FormInput from '@/ui/input/FormInput.vue'
import axios from 'axios'
import { RefsType } from '@/pages/common/types'
// import { config } from '@/config'

export default (Vue as VueConstructor<Vue & { $refs: RefsType }>).extend({
  name: 'VideoStorage',
  components: {
    FormInput,
    Icon,
    Modal,
    Vuetable,
  },
  props: {
    isOpen: { type: Boolean },
  },
  data() {
    return {
      apiUrl: `https://api.dtechs.dev/video-converter-api/api/v1/hls/ready-link/all`,
      fields: VideoTableFields,
    }
  },
  computed: {
    $inputValue() {
      return $inputValue
    },
  },
  effector: {
    $inputValue,
  },
  methods: {
    inputValueChanged,
    onVideoSelected(row: { data: { filename: string; link: string } }) {
      this.$emit('toggleModal', false)
      this.inputValueChanged('')
      this.$emit('onVideoSelected', row.data)
    },

    async fetch(api: string) {
      return axios.post(api, { filename: this.$inputValue || '' })
    },
    transform(data: any) {
      return { data: data.content }
    },
    onChangeInput(value: string) {
      this.inputValueChanged(value)
      this.$refs.vuetable.reload()
    },
    async onClear() {
      this.inputValueChanged('')
      this.$refs.vuetable.reload()
    },
    toggleModal(val: boolean) {
      this.$emit('toggleModal', val)
    },
  },
})
