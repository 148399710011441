














































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $editedNewsId } from '@/pages/content/news/edit/model/edit-news.model'
import { $isShowed, changeFieldValue } from '@/pages/content/news/edit/model/form-fields.model'
import { EditNewsPageFields } from '@/features/api/content/types'

export default Vue.extend({
  name: 'EditNewsHeader',
  components: {
    Icon,
    BaseButton,
    BaseSwitch,
  },
  props: {
    title: { type: String as PropType<string>, default: '' },
    disabled: { type: Boolean as PropType<boolean>, default: true },
    fromPage: { type: String as PropType<string> },
  },
  effector: {
    newsId: $editedNewsId,
    $isShowed,
  },
  data: () => ({
    fieldType: EditNewsPageFields,
  }),
  methods: {
    goToList() {
      switch (this.$route.name) {
        default:
          navigatePush({ name: 'news-list' })
      }
    },
    onSaveClick() {
      this.$emit('save')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
    changeShowed(type: EditNewsPageFields, value: string) {
      changeFieldValue({ type, value })
    },
  },
})
