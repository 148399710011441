<template>
  <div class='content'>
    <div class='base'>
      <div class='left'>
        <PositionDropdown class="field" @setItem="setFolder" />
        <NumericInput
          class="field"
          :value="+$score"
          label="Баллы"
          placeholder="Введите количество баллов"
          @input="setScore"
        />
        <TaskTypesDropdown class="field" @setItem="setTaskType" />
        <component
          v-if="$taskType"
          :is="taskComponent"
        />
        <LanguagesDropdown
          class="field"
          :value="$language"
          @click="setLanguage"
        />
      </div>
      <div class='right'>
        <BaseSwitch
          class="switch field"
          :checked="$needDuplicate"
          @change="toggleNeedDuplicate"
        >
          <p>Дублировать</p>
        </BaseSwitch>
        <NumericInput
          :value="+$count"
          label="Количество"
          @input="setCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import PositionDropdown from '@/pages/common/dropdowns/bank/lesson-tasks/position-dropdown/PositionDropdown.vue'
import TaskTypesDropdown from '@/pages/common/dropdowns/bank/task-types-dropdown/TaskTypesDropdown.vue'
import LanguagesDropdown from '@/pages/bank/lesson-tasks/create/parts/languages-dropdown/LanguagesDropdown.vue'
import BaseSwitch from '@/ui/switch/BaseSwitch.vue'
import NumericInput from '@/ui/input/NumericInput.vue'
import {
  setFolder,
  setScore,
  $score,
  $taskType,
  setTaskType,
  $language,
  setLanguage,
  $needDuplicate,
  toggleNeedDuplicate,
  $count,
  setCount,
} from '@/pages/bank/lesson-tasks/create/task-creation-page.model'
import * as tasks from '@/pages/common/parts/tasks/'
import { mapTaskTypeTo } from '@/pages/common/constants'
import { resetCounters } from '@/pages/common/parts/tasks/moving-images-on-image-input-answer/form/moving-images-on-image-answer-form.model'
import { resetCounters as resetImagesOnTextCounters } from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/form/moving-images-on-text-input-answer-form.model'

export default Vue.extend({
  name: 'TaskContent',
  components: {
    PositionDropdown,
    TaskTypesDropdown,
    LanguagesDropdown,
    BaseSwitch,
    NumericInput,
  },
  effector: {
    $score,
    $taskType,
    $language,
    $needDuplicate,
    $count,
  },
  computed: {
    taskComponent() {
      return this.$taskType ? tasks[mapTaskTypeTo[this.$taskType].componentName] : null
    },
  },
  watch: {
    taskComponent: {
      handler() {
        resetCounters()
        resetImagesOnTextCounters()
      },
    },
  },
  methods: {
    setFolder,
    setScore,
    setTaskType,
    setLanguage,
    toggleNeedDuplicate,
    setCount,
  },
})
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #fff;
  border-radius: 3px;
}
.base {
  display: flex;
  justify-content: space-between;
}
.left,
.right {
  display: flex;
  flex-direction: column;
}
.left {
  @mixin bank-base-left;
}
.right {
  flex-basis: 30%;
  .wrapper {
    @mixin flex-row-central;
    .numeric-input-wrap {
      max-width: 190px;
      height: 46px;
      box-sizing: border-box;
      margin-right: 20px;
      & ::v-deep input {
        width: 110px;
      }
    }
  }
}
.tasks-count {
  display: flex;
  align-items: center;
}
.field {
  margin-bottom: 20px;
}
.text {
  font-weight: 600;
}
@media screen and (max-width: 1825px) {
  .left {
    flex-basis: 65%;
  }
}
@media screen and (max-width: 1600px) {
  .right {
    flex-basis: 0;
  }
}
</style>
