






















import Vue from 'vue'

export default Vue.extend({
  name: 'CommonListStringAnswer',
  props: {
    question: { type: [Array, Object] },
    answer: [Object, Array, Number],
    correctAnswer: [Object, Array, Number],
    answerType: { type: String, default: 'student' },
  },
  computed: {
    currentAnswer() {
      return this.answerType === 'student' ? this.answer : this.correctAnswer
    },
    answerData() {
      try {
        if (this.answerType === 'student') {
          if (typeof this.answer === 'object') {
            return Object.entries(this.answer).map((arr) => `${arr[1]}:${arr[0]}`)
          }
          if (Array.isArray(this.answer)) {
            return this.answer.map((el: any) =>
              Object.entries(el).map((arr) => `${arr[1]}:${arr[0]}`)
            )
          }
        }
        if (this.answerType === 'correct') {
          if (Array.isArray(this.correctAnswer)) {
            return this.correctAnswer.map(
              (el: any) => Object.entries(el).map((arr) => `${arr[1]}:${arr[0]}`)[0]
            )
          }
        }
        return this.answerType === 'student' ? this.answer : this.correctAnswer
      } catch (e) {
        console.error(e)
        return []
      }
    },
  },
})
