












































import Vue from 'vue'
import Card from '@/ui/card/Card.vue'
import FormInput from '@/ui/input/FormInput.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Loader from '@/pages/common/parts/loader/Loader.vue'
import {
  $form,
  emailChanged,
  passwordChanged,
  $errors,
  $isFormValid,
  submitForm,
  $isLoading,
  $attemptsInfo,
  setAttemptsInfo,
  getAttemptsInfo,
  startAttemptsTimer,
} from '@/pages/login/login.model'
import declOfNum from '@/lib/utils/declOfNum'

export default Vue.extend({
  name: 'LoginPage',
  components: {
    Card,
    FormInput,
    BaseButton,
    Loader,
  },
  effector: {
    $form,
    $errors,
    $isFormValid,
    $isLoading,
    $attemptsInfo,
  },
  methods: {
    emailChanged,
    passwordChanged,
    submitForm,
    setAttemptsInfo,
    declOfNum,
    getAttemptsInfo,
    startAttemptsTimer,
  },
  mounted() {
    this.setAttemptsInfo(this.getAttemptsInfo())

    if (this.$attemptsInfo.timer) {
      this.startAttemptsTimer()
    }
  },
})
