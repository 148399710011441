













































import Vue, { PropType, VueConstructor } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import { $images } from '@/pages/applications/modals/outgoing-comment/outgoing-comment.model'
import SwiperCore, { Navigation, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { RefsType } from '@/pages/common/types'

SwiperCore.use([Navigation, Controller])

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: RefsType
    }
  >
).extend({
  name: 'CommentModal',
  components: {
    Icon,
    Swiper,
    SwiperSlide,
  },
  effector: {
    $images,
  },
  props: {
    currentSlide: { type: Number as PropType<number>, default: 0 },
  },
  data: () => ({
    swiper: {} as SwiperCore,
    clickedSlideIndex: 0,
  }),
  computed: {
    isNavigationVisible(): boolean {
      return !!this.$images && this.$images.length > this.swiperOptions.slidesPerView
    },
    swiperOptions() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const instance = this

      return {
        loop: true,
        isNavigation: true,
        scrollbar: false,
        slidesPerView: 4,
        spaceBetween: 20,
        navigation: {
          nextEl: '.main-slider-button-next',
          prevEl: '.main-slider-button-prev',
        },

        on: {
          init(swiper: SwiperCore) {
            instance.swiper = swiper
          },
          beforeInit(swiper: SwiperCore) {
            /* брутфорс, нужно перед каждым переоткрытием, не срабатывает на первый инит,
                потому что на данный момент изображения еще не загрузились */
            if (
              !!instance.$images &&
              instance.$images.length < instance.swiperOptions.slidesPerView
            ) {
              swiper.params.loop = false
            }
          },
          progress() {
            // не работает эвент клика в бесконечном списке
            // из за дублей слайдера
            document.querySelectorAll('.main-comment-slider .image').forEach((el) => {
              el.removeEventListener('click', instance.openFullSizeHack)
              el.addEventListener('click', instance.openFullSizeHack)
            })
          },
        },
      }
    },
  },
  watch: {
    currentSlide(value) {
      if (value !== this.swiper.activeIndex) {
        this.swiper.slideTo(value, 0)
      }
    },
    $images() {
      /* брутфорс, нужно перед загрузкой слайдера, срабатывает только один раз
        и при переоткрытии опция не работает */
      if (!!this.$images && this.$images.length < this.swiperOptions.slidesPerView) {
        this.$refs.swiper.options.loop = false
      }
    },
  },
  methods: {
    openFullSizeHack(e: Event) {
      const target = e.target as HTMLDivElement
      this.clickedSlideIndex = +target.getAttribute('data-index')! + 1
      this.$emit('onSlideClick', this.clickedSlideIndex)
    },
  },
})
