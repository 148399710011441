













import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  subjectsDropdownModel,
  loadSubjects,
  setSelectedSubject,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    ...subjectsDropdownModel.store,
  },
  methods: {
    ...subjectsDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedSubject(item || null)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (this.$items.length === 0) loadSubjects()
  },
})
