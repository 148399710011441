import { createStore } from 'effector-root'
import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { DropdownItem } from '@/pages/common/types'

export const mapApplicationsStatusCourse = [
  {
    name: 'declined',
    title: 'Отклонена',
  },
  {
    name: 'accepted',
    title: 'Одобрена',
  },
  {
    name: 'waiting',
    title: 'Ожидает',
  },
  {
    name: 'excluded',
    title: 'Исключена',
  },
  {
    name: 'suspended',
    title: 'Приостановлена',
  },
]

export const statusDropdownModule = createFilter()

export const $status = createStore<DropdownItem[]>(mapApplicationsStatusCourse)
