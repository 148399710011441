import { attach, createEvent } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { dropdownModules } from '@/pages/users/groups/list/model/tooltip-filter.model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'
import { exportInstructionsListFx } from '@/features/api/instructions/export-instructions-list'

export const instructionsFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownModules
)

export const instructionsPageParams = createPageParamsModel()

export const resetInstructionsFilters = createEvent<void>()

export const exportInstructionsList = attach({
  effect: exportInstructionsListFx,
  source: [instructionsFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => ({ ...filters, ...exportedColumns }),
})

exportInstructionsListFx.doneData.watch((res) => fileDownload(res.body, 'instructionsList.xlsx'))
