var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GridPageHead',{attrs:{"title":"Тестовые задания"}},[_c('div',{staticClass:"buttons"},[(
        _vm.$permissions.assignments_testassignment_difficulty_base.can_edit ||
          _vm.$permissions.assignments_testassignment_difficulty_pro.can_edit ||
          _vm.$permissions.assignments_testassignment_difficulty_exam.can_edit
      )?_c('RouterLink',{attrs:{"to":{ name: 'test-tasks-create' }}},[_c('BaseButton',{staticClass:"btn",attrs:{"yellow":""}},[_vm._v(" Создать задание ")])],1):_vm._e(),_vm._v(" "),(_vm.$permissions.subjects_theme_themes.can_edit)?_c('RouterLink',{attrs:{"to":{ name: 'themes-create' }}},[_c('BaseButton',{staticClass:"btn",attrs:{"yellow":""}},[_vm._v(" Создание темы ")])],1):_vm._e(),_vm._v(" "),(_vm.$permissions.assignments_olympiadassignment_assignments.can_edit)?_c('BaseButton',{staticClass:"btn --square",on:{"click":function($event){return _vm.loadModalForMultiChanges(_vm.selectedRows)}}},[_c('Icon',{staticClass:"icon",attrs:{"type":"edit","size":"20"}})],1):_vm._e(),_vm._v(" "),(
        !_vm.$treeView &&
          (_vm.$permissions.assignments_testassignment_difficulty_base.can_edit ||
            _vm.$permissions.assignments_testassignment_difficulty_pro.can_edit ||
            _vm.$permissions.assignments_testassignment_difficulty_exam.can_edit)
      )?_c('DownloadButton',{attrs:{"loading":_vm.$exportTaskLoading}}):_vm._e()],1),_vm._v(" "),_c('HeaderPopup',{staticClass:"popup",attrs:{"table-columns":_vm.tableColumns},on:{"onExport":function($event){return _vm.$emit('onExport')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }