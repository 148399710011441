















import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  loadModerators,
  moderatorsDropdownModel,
  setSelectedModerator,
} from '@/pages/common/dropdowns/users/moderator-dropdown/moderator-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  props: {
    disabled: { type: Boolean },
    smallMenu: { type: Boolean, default: false },
  },
  effector: {
    ...moderatorsDropdownModel.store,
  },
  methods: {
    ...moderatorsDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedModerator(item)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (!this.$items.length) loadModerators()
  },
})
