
















































import Vue, { PropType } from 'vue'
import GridPageHead from '@/pages/common/grid-parts/GridPageHead.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import Icon from '@/ui/icon/Icon.vue'
import { loadModalForMultiChanges } from '@/pages/bank/test-tasks/list/parts/modals/tasks-update/tasks-update-modal.model'
import HeaderPopup from '@/pages/common/parts/header/header-popup/HeaderPopup.vue'
import { TableField } from '@/pages/dictionary/themes/list/types'
import DownloadButton from '@/pages/common/parts/header/DownloadButton.vue'
import { testTaskPageParams, taskLoader } from '@/pages/bank/test-tasks/list/tasks-page.model'
import { $permissions } from '@/features/session'
import { DifficultyType } from '@/features/api/assignment/types/types'

export default Vue.extend({
  name: 'PageHeader',
  components: {
    DownloadButton,
    BaseButton,
    Icon,
    GridPageHead,
    HeaderPopup,
  },
  props: {
    tableColumns: { type: Array as PropType<TableField[]> },
    selectedRows: { type: Array as PropType<{ id: number; type: DifficultyType }[]> },
  },
  effector: {
    $treeView: testTaskPageParams.store.treeView,
    $exportTaskLoading: taskLoader.store.$exportTaskLoading,
    $permissions,
  },
  methods: {
    loadModalForMultiChanges,
  },
})
