import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ResultCoursesAll from '@/pages/results/courses-all/ResultCoursesAll.vue'
import ResultCoursesStudents from '@/pages/results/courses-students/ResultCoursesStudents.vue'
import ResultCoursesCheck from '@/pages/results/courses-check/ResultCoursesCheck.vue'
import OlympiadResultsPage from '@/pages/results/olympiad-results/OlympiadResultsPage.vue'
import ResultTestsAll from '@/pages/results/tests-all/ResultTestsAll.vue'
import ResultTestsStudents from '@/pages/results/tests-students/ResultTestsStudents.vue'
import CertificatesGeneratorTable from '@/pages/results/certificates-generator/table/CertificatesGeneratorTable.vue'
import CertificatesGenerateCreateCertificates from '@/pages/results/certificates-generator/create-certificates/CertificatesGeneratorCreateCertificates.vue'
import CertificatesGenerateCreateTemplate from '@/pages/results/certificates-generator/create-template/CertificatesGeneratorCreateTemplate.vue'

export default [
  {
    name: 'results',
    path: '/results',
    meta: {
      title: 'Результаты - Школа Летово',
    },
    component: DefaultLayout,
    children: [
      {
        name: 'results-tests-all',
        path: 'tests',
        component: ResultTestsAll,
        meta: {
          title: 'Тесты (все) - Школа Летово',
        },
      },
      {
        name: 'results-tests-students',
        path: 'tests-students',
        component: ResultTestsStudents,
        meta: {
          title: 'Тесты (по ученикам) - Школа Летово',
        },
      },
      {
        name: 'results-exam',
        path: 'exam',
        component: DefaultLayout,
        meta: {
          title: 'Экзамен - Школа Летово',
        },
      },
      {
        name: 'results-olympiad-tasks',
        path: 'olympiad-tasks',
        component: OlympiadResultsPage,
        meta: {
          title: 'Результаты Олимпиадных заданий - Школа Летово',
        },
      },
      {
        name: 'results-courses',
        path: 'courses',
        component: DefaultLayout,
        redirect: { name: 'results-courses-list' },
        meta: {
          title: 'Курсы - Школа Летово',
        },
        children: [
          {
            name: 'results-courses-list',
            path: 'list',
            component: ResultCoursesAll,
            meta: {
              title: 'Результаты по курсам - Школа Летово',
            },
          },
          {
            name: 'results-courses-student-list',
            path: 'student-list',
            component: ResultCoursesStudents,
            meta: {
              title: 'Результаты курсов по студентам - Школа Летово',
            },
          },
          {
            name: 'results-courses-check-list',
            path: 'check-list',
            component: ResultCoursesCheck,
            meta: {
              title: 'Управление проверкой урока - Школа Летово',
            },
          },
          {
            name: 'certificates-generator',
            path: 'certificates-generator',
            component: DefaultLayout,
            redirect: { name: 'certificates-generator-table' },
            meta: {
              title: 'Сертификаты - Школа Летово',
            },
            children: [
              {
                name: 'certificates-generator-table',
                path: 'table',
                component: CertificatesGeneratorTable,
                meta: {
                  title: 'Генерация сертификатов - Школа Летово',
                },
              },
              {
                name: 'certificates-generator-create-template',
                path: 'create-template',
                component: CertificatesGenerateCreateTemplate,
                meta: {
                  title: 'Создание шаблона сертификатов - Школа Летово',
                },
              },
              {
                name: 'certificates-generator-create-certificates',
                path: 'create-certificates',
                component: CertificatesGenerateCreateCertificates,
                meta: {
                  title: 'Создание сертификатов - Школа Летово',
                },
              },
            ],
          },
        ],
      },
      {
        name: 'results-all',
        path: 'all',
        component: DefaultLayout,
        meta: {
          title: 'Сводные результаты - Школа Летово',
        },
      },
    ],
  },
]
