



























import Vue, { PropType } from 'vue'
import { TableField } from '@/pages/applications/types'
import { SortFieldsType } from '@/pages/results/types'

export default Vue.extend({
  name: 'TableColumns',
  props: {
    fields: { type: Array as PropType<TableField[]>, default: [] },
    sortedBy: { type: Array as PropType<SortFieldsType[]>, default: [] },
    wrap: { type: String, default: 'no-wrap' },
  },
  data() {
    return {
      sorted: {},
    }
  },
  computed: {
    currentCellTitleStyle() {
      switch (this.wrap) {
        case 'no-wrap':
          return 'cell-title'
        case 'wrap':
          return 'cell-title-wrap'
        default:
          return 'cell-title'
      }
    },
  },
  methods: {
    handleClick(field: TableField, index: number) {
      const value = this.sorted[index] && this.sorted[index].filter
      switch (value) {
        case 'asc':
          this.sorted[index] = { filter: 'desc', className: 'sort-icon blue chevron down icon' }
          break
        case 'desc':
          this.sorted[index] = { filter: 'asc', className: 'sort-icon blue chevron up icon' }
          break
        default:
          this.sorted[index] = { filter: 'desc', className: 'sort-icon blue chevron down icon' }
      }
      if (this.fields[index].sortField) {
        this.$emit('changeTableParams', {
          sortField: this.fields[index].sortField,
          direction: this.sorted[index].filter,
        })
      }
    },
    handleCheckboxClick(e: PointerEvent) {
      // @ts-ignore
      this.$emit('handleCheckbox', !!e.target?.checked)
    },
    getIconClass(item: TableField) {
      if (this.sortedBy[0]?.sortField === item.sortField) {
        switch (this.sortedBy[0]?.direction) {
          case 'asc':
            return 'sort-icon blue chevron up icon'
          case 'desc':
            return 'sort-icon blue chevron down icon'
          default:
            return 'sort-icon grey sort icon'
        }
      } else {
        return 'sort-icon grey sort icon'
      }
    },
  },
})
