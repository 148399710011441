







































import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import Icon from '@/ui/icon/Icon.vue'
import {
  $subjects,
  $selectedSubjects,
  addSubject,
  subjectsDropdownModule,
  deleteSubject,
  loadSubjects,
  $isSelectedAllSubjects,
  unselectAllSubjects,
  selectAllSubjects,
} from '@/pages/users/employes/edit/model/dropdowns/subjects-dropdown.model'

export default Vue.extend({
  components: {
    FilterDropdown,
    Icon,
  },
  effector: {
    ...subjectsDropdownModule.store,
    $subjects,
    $selectedSubjects,
    $isSelectedAllSubjects,
  },
  props: {
    label: String,
  },
  data() {
    return {
      subjectsDropdownMethods: subjectsDropdownModule.methods,
    }
  },
  methods: {
    ...subjectsDropdownModule.methods,
    unselectAll: unselectAllSubjects,
    onSelectItem(item: DropdownItem | null, selectedItems: DropdownItem[]) {
      if (item !== null) {
        const queryParams = selectedItems.map((element) => element.name).join()
        item.name === 'all' ? selectAllSubjects() : addSubject(item)
        this.$emit('setItem', queryParams.length > 0 ? `${item.name},${queryParams}` : item.name)
      }
    },
    deleteCourse(event: MouseEvent, item: DropdownItem) {
      event.stopPropagation()
      event.preventDefault()
      deleteSubject(item)
    },
  },
  mounted() {
    loadSubjects()
  },
  destroyed() {
    subjectsDropdownModule.methods.resetDropdown()
    unselectAllSubjects()
  },
})
