import { createApiEffect } from '@/features/api/common/create-api-effect'
import { CreateFolderType, FolderType } from '@/features/api/assignment/types/types'

export const createExamTestFolderFx = createApiEffect<CreateFolderType, FolderType>({
  requestMapper: (params) => ({
    method: 'POST',
    url: '/api/exams-app/folders/',
    body: params,
  }),
})

export const updateExamTestFolderFx = createApiEffect<
  { id: number; body: CreateFolderType },
  FolderType
>({
  requestMapper: ({ id, body }) => ({
    method: 'PATCH',
    url: `/api/exams-app/folders/${id}/`,
    body,
  }),
})
