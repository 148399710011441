



































import Vue from 'vue'
import DateInput from '@/ui/input/DateInput.vue'
import {
  $appointmentsAvailableFrom,
  setAppointmentsAvailableFrom,
  $appointmentsAvailableTo,
  setAppointmentsAvailableTo,
} from '@/pages/exams/full-appointments/create/model/form-fileds.model'
import ExamsFullTestsListDropdown from '@/pages/common/dropdowns/exams/exams-full-tests/ExamsFullTestsListDropdown.vue'
import AttemptsCount from '@/pages/exams/full-appointments/create/parts/AttemptsCount/AttemptsCount.vue'

export default Vue.extend({
  name: 'FullAppointmentsPage2',
  components: {
    DateInput,
    ExamsFullTestsListDropdown,
    AttemptsCount,
  },
  effector: {
    $appointmentsAvailableFrom,
    $appointmentsAvailableTo,
  },
  methods: {
    setAppointmentsAvailableFrom,
    setAppointmentsAvailableTo,
  },
})
