











import Vue from 'vue'
import { DropdownItem } from '@/pages/common/types'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  $lessons,
  lessonsDropdownModule,
  loadLessons,
  setSelectedLessons,
  loadLessonsById,
} from '@/pages/common/dropdowns/lessons/lesson-dropdown.model'

export default Vue.extend({
  name: 'LessonsCourses',
  components: {
    FilterDropdown,
  },
  props: {
    isById: { type: Boolean, default: false },
    findId: { type: Number, default: 0 },
  },
  watch: {
    findId(value) {
      if (this.isById && value) {
        loadLessonsById(value)
      }
    },
  },
  effector: {
    $lessons,
    ...lessonsDropdownModule.store,
  },
  methods: {
    ...lessonsDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      setSelectedLessons(item)
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (!this.isById) loadLessons()
  },
})
