export type QuestionsCourseType = {
  id: number
  question: string
}

export type GroupCourseType = {
  id: number
  blockId?: number
  name: string
  title: string
  accept_dt_from: Date | null
  accept_dt_to: Date | null
}

export type LoadingGroupCourseType = {
  id: number
  name: string
  accept_dt_from: Date | null
  accept_dt_to: Date | null
}

export type LessonsGroupCourseType = {
  id: number
  blockId?: number
  name: string
  title: string
  start: Date | null
  end: Date | null
}

export const defaultGroup = (blockId = 0): LessonsGroupCourseType => ({
  blockId,
  id: 0,
  name: '',
  title: '',
  start: null,
  end: null,
})

export type LessonsCourseType = {
  blockId?: number
  id: number
  name: string
  title: string
  groups: LessonsGroupCourseType[]
}
