







































import Vue, { PropType } from 'vue'
import Icon from '@/ui/icon/Icon.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { $editedExamTestID } from '@/pages/exams/full-time-exam-test/create/model/create-exam-tests.model'

export default Vue.extend({
  name: 'ExamTestsHeader',
  components: {
    Icon,
    BaseButton,
  },
  props: {
    disabled: { type: Boolean as PropType<boolean>, default: true },
  },
  effector: {
    id: $editedExamTestID,
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    onSaveClick() {
      this.$emit('save')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
