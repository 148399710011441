import { createEvent, guard, restore, sample, forward, split } from 'effector-root'
import { createNewsFx } from '@/features/api/content/news/create-news'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $createNewsPageForm, clearFields } from '@/pages/content/news/edit/model/form-fields.model'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import { updateNewsByIdFx } from '@/features/api/content/news/patch-news-by-id'
import { getNewsByIdFx } from '@/features/api/content/news/get-news-by-id'

export const changeNewsId = createEvent<string>()

export const $editedNewsId = restore<string>(changeNewsId, '').reset(clearFields)

export const setRedirectAfterSave = createEvent<boolean>()
export const $redirectAfterSave = restore(setRedirectAfterSave, false).reset([
  navigatePush,
  createNewsFx.fail,
  updateNewsByIdFx.fail,
])

export const saveNewsInfo = createEvent<void>()

const createNews = createEvent<void>()

const updateNews = createEvent<string>()

const newsId = sample({
  source: $editedNewsId,
  clock: saveNewsInfo,
  fn: (id) => (id.length > 0 ? id : false),
})

guard({
  source: changeNewsId,
  filter: (payload) => !!payload,
  target: getNewsByIdFx,
})

split({
  source: newsId,
  match: {
    post: (payload) => payload === false,
    patch: (payload) => payload !== false,
  },
  cases: {
    post: createNews,
    patch: updateNews,
  },
})

sample({
  source: $createNewsPageForm,
  clock: createNews,
  target: createNewsFx,
})

sample({
  source: { body: $createNewsPageForm, id: $editedNewsId },
  clock: updateNews,
  target: updateNewsByIdFx,
})

guard({
  source: createNewsFx.done,
  filter: $redirectAfterSave,
  target: navigatePush.prepend(() => ({ name: 'news-list' })),
})

guard({
  source: updateNewsByIdFx.done,
  filter: $redirectAfterSave,
  target: navigatePush.prepend(() => ({ name: 'news-list' })),
})

forward({
  from: createNewsFx.done,
  to: successToastEvent('Новость успешна сохранена'),
})

forward({
  from: updateNewsByIdFx.done,
  to: successToastEvent('Новость успешна отредактирована'),
})

forward({
  from: [
    createNewsFx.failData.map((res) => res.body),
    updateNewsByIdFx.failData.map((res) => res.body),
  ],
  to: addToast.prepend((data: any) => ({
    type: 'error',
    message: data,
  })),
})
