import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ProctorCreateFxParams, ProctorCreateType } from '@/features/api/proctors/types'

export const updateProctorsFx = createApiEffect<
  { body: ProctorCreateType; id: string },
  ProctorCreateFxParams
>({
  requestMapper: ({ body, id }) => ({
    method: 'PATCH',
    url: `/api/exams-app/proctors/${id}/`,
    body,
  }),
})
