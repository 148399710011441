import DefaultLayout from '@/layouts/DefaultLayout.vue'

// proctors
import ProctorsPage from '@/pages/exams/proctors/list/ProctorsPage.vue'
import CreateProctorsPage from '@/pages/exams/proctors/create/CreateProctorsPage.vue'

// part-time-test
import PartTimeTestList from '@/pages/exams/part-time-test/list/PartTimeTestList.vue'
import PartTimeTestPage from '@/pages/exams/part-time-test/create/PartTimeTestPage.vue'

// appointments
import AppointmentPage from '@/pages/exams/appointments/list/AppointmentPage.vue'
import CreateAppointmentsPage from '@/pages/exams/appointments/create/CreateAppointmentPage.vue'

// attempts
import AttemptsPage from '@/pages/exams/attempts/list/AttemptsPage.vue'

// ExamsAnswers
import ExamsAnswersPage from '@/pages/exams/part-time-answers/list/ExamsAnswersPage.vue'

// full appointments
import FullAppointmentPage from '@/pages/exams/full-appointments/list/FullAppointmentPage.vue'
import CreateFullAppointmentsPage from '@/pages/exams/full-appointments/create/CreateFullAppointmentPage.vue'
import FullTimeExamTestsPage from '@/pages/exams/full-time-exam-test/list/FullTimeExamTestsListPage.vue'
import CreateExamTestPage from '@/pages/exams/full-time-exam-test/create/CreateExamTestPage.vue'

// fullattempts
import FullAttemptsPage from '@/pages/exams/full-attempts/list/FullAttemptsPage.vue'

// ExamsFullAnswersPage
import ExamsFullAnswersPage from '@/pages/exams/full-time-answers/list/ExamsFullAnswersPage.vue'

export default [
  {
    name: 'exams',
    path: '/exams',
    redirect: { name: 'proctors-list' },
    component: DefaultLayout,
    children: [
      {
        name: 'proctors',
        path: 'proctors',
        meta: {
          title: 'Прокторы - Школа Летово',
        },
        redirect: { name: 'proctors-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'proctors-list',
            path: 'list',
            component: ProctorsPage,
            meta: {
              title: 'Предметы - Школа Летово',
            },
          },
          {
            name: 'proctors-create',
            path: 'create',
            component: CreateProctorsPage,
            meta: {
              title: 'Создание предмета - Школа Летово',
            },
          },
          {
            name: 'proctors-edit',
            path: 'create/:id',
            component: CreateProctorsPage,
            meta: {
              title: 'Редактирование предмета - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'tests',
        path: 'tests',
        meta: {
          title: 'Экзамены - Школа Летово',
        },
        redirect: { name: 'part-time-exams-tests-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'part-time-exams-tests-list',
            path: 'list',
            component: PartTimeTestList,
            meta: {
              title: 'Экзаменационные тесты - Школа Летово',
            },
          },
          {
            name: 'part-time-exams-tests-create',
            path: 'create',
            component: PartTimeTestPage,
            meta: {
              title: 'Создание экзаменационного теста - Школа Летово',
            },
          },
          {
            name: 'part-time-exams-tests-edit',
            path: 'create/:id',
            component: PartTimeTestPage,
            meta: {
              title: 'Редактирование экзаменационного теста - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'appointment',
        path: 'appointment',
        meta: {
          title: 'Назначения - Школа Летово',
        },
        redirect: { name: 'exams-appointment-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'exams-appointment-list',
            path: 'list',
            component: AppointmentPage,
            meta: {
              title: 'Назначения для экзамена - Школа Летово',
            },
          },
          {
            name: 'exams-appointment-create',
            path: 'create',
            component: CreateAppointmentsPage,
            meta: {
              title: 'Создание назначения - Школа Летово',
            },
          },
          {
            name: 'exams-appointment-edit',
            path: 'create/:id',
            component: CreateAppointmentsPage,
            meta: {
              title: 'Редактирование назначения - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'attempts',
        path: 'attempts',
        meta: {
          title: 'Попытки - Школа Летово',
        },
        redirect: { name: 'exams-attempts-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'exams-attempts-list',
            path: 'list',
            component: AttemptsPage,
            meta: {
              title: 'Попытки - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'exams-answers',
        path: 'exams-answers',
        meta: {
          title: 'Ответы - Школа Летово',
        },
        redirect: { name: 'exams-answers-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'exams-answers-list',
            path: 'list',
            component: ExamsAnswersPage,
            meta: {
              title: 'Ответы - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'full-appointment',
        path: 'full-appointment',
        meta: {
          title: 'Назначения - Школа Летово',
        },
        redirect: { name: 'full-appointment-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'full-appointment-list',
            path: 'list',
            component: FullAppointmentPage,
            meta: {
              title: 'Назначения для экзамена - Школа Летово',
            },
          },
          {
            name: 'full-appointment-create',
            path: 'create',
            component: CreateFullAppointmentsPage,
            meta: {
              title: 'Создание назначения - Школа Летово',
            },
          },
          {
            name: 'full-appointment-edit',
            path: 'create/:id',
            component: CreateFullAppointmentsPage,
            meta: {
              title: 'Редактирование назначения - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'full-exam-test',
        path: 'full-exam-test',
        meta: {
          title: 'Экзаменационные тесты для очного этапа - Школа Летово',
        },
        redirect: { name: 'full-exam-tests-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'full-exam-tests-list',
            path: 'list',
            component: FullTimeExamTestsPage,
            meta: {
              title: 'Экзаменационные тесты для очного этапа - Школа Летово',
            },
          },
          {
            name: 'full-exam-test-create',
            path: 'create',
            component: CreateExamTestPage,
            meta: {
              title: 'Создание экзаменационного теста - Школа Летово',
            },
          },
          {
            name: 'full-exam-test-edit',
            path: 'create/:id',
            component: CreateExamTestPage,
            meta: {
              title: 'Редактирование экзаменационного теста - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'full-attempts',
        path: 'full-attempts',
        meta: {
          title: 'Попытки - Школа Летово',
        },
        redirect: { name: 'exams-full-attempts-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'exams-full-attempts-list',
            path: 'list',
            component: FullAttemptsPage,
            meta: {
              title: 'Попытки - Школа Летово',
            },
          },
        ],
      },
      {
        name: 'full-answer',
        path: 'full-answer',
        meta: {
          title: 'Попытки - Школа Летово',
        },
        redirect: { name: 'exams-full-answers-list' },
        component: DefaultLayout,
        children: [
          {
            name: 'exams-full-answers-list',
            path: 'list',
            component: ExamsFullAnswersPage,
            meta: {
              title: 'Ответы - Школа Летово',
            },
          },
        ],
      },
    ],
  },
]
