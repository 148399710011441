



























import Vue from 'vue'
import Modal from '@/ui/modal/Modal.vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { mapDeleteModalTypeToTitle } from '@/pages/common/modals/confirm-delete/constants'
import {
  $confirmDeleteModalVisibility,
  $selectedIds,
  confirmDeleteModalVisibilityChanged,
} from '@/pages/common/modals/confirm-delete/confirm-delete-modal.model'

export default Vue.extend({
  name: 'ConfirmDeleteModal',
  components: {
    Modal,
    BaseButton,
  },
  effector: {
    $confirmDeleteModalVisibility,
    $selectedIds,
  },
  props: {
    type: { type: String, required: true },
  },
  computed: {
    correctTitle(): string {
      if (this.$selectedIds.length === 1) {
        return `${mapDeleteModalTypeToTitle[this.type].typeWord.singular} ${this.$selectedIds.join(
          ', '
        )} будет ${mapDeleteModalTypeToTitle[this.type].deleteWord.singular}.`
      }
      return `${mapDeleteModalTypeToTitle[this.type].typeWord.plural} будут ${
        mapDeleteModalTypeToTitle[this.type].deleteWord.plural
      }.`
    },
  },
  methods: {
    confirmDeleteModalVisibilityChanged,
  },
})
