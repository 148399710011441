import { createEvent, restore, forward, guard, combine } from 'effector-root'
import { every } from 'patronum'
import {
  $selectedTheme,
  setThemeToSelect,
} from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { dropdownModules } from '@/pages/bank/test-tasks/list/parts/test-tasks-filter/parts/dropdown-modules'
import {
  $selectedClass,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'

export const testTasksFilters = createFiltersModel({}, dropdownModules)

export const reset = createEvent<void>()

export const setPrerequisite = createEvent<void>()

export const toggleVisibility = createEvent<boolean>()
export const $visibility = restore(toggleVisibility, false).reset(reset)

export const $canSetLabels = every({
  predicate: (value) => value !== null,
  stores: [$selectedTheme],
})

forward({
  from: [
    dropdownModules.classesDropdownModule.methods.itemChanged,
    dropdownModules.classesDropdownModule.methods.resetItem,
    dropdownModules.subjectsDropdownModel.methods.itemChanged,
    dropdownModules.subjectsDropdownModel.methods.resetItem,
  ],
  to: [
    dropdownModules.themesDropdownModule.methods.resetItem,
    testTasksFilters.methods.removeParamsFilter.prepend(() => 'theme'),
  ],
})

forward({
  from: [
    dropdownModules.themesDropdownModule.methods.itemChanged,
    dropdownModules.themesDropdownModule.methods.resetItem,
  ],
  to: [
    dropdownModules.labelsDropdownModel.methods.resetDropdown,
    testTasksFilters.methods.removeParamsFilter.prepend(() => 'labels'),
  ],
})

guard({
  clock: setPrerequisite,
  filter: combine($selectedTheme, $selectedClass, (selectedTheme, selectedClass) => {
    return !!selectedTheme?.is_prerequisite && selectedClass !== null
  }),
  target: [
    setSelectedClass.prepend(() => null),
    dropdownModules.classesDropdownModule.methods.itemChanged.prepend(() => null),
    setThemeToSelect.prepend(() => null),
    testTasksFilters.methods.removeParamsFilter.prepend(() => 'study_year'),
  ],
})
