import { createApiEffect } from '@/features/api/common/create-api-effect'
import { RequestDeleteCoursesParams } from '@/features/api/learning/types'

export const deleteCourseBulkFx = createApiEffect<number[], void>({
  requestMapper: (courses) => ({
    method: 'PATCH',
    url: '/api/course-app/courses/bulk-delete/',
    body: courses,
  }),
})

export const requestDeleteCourseBulkFx = createApiEffect<
  RequestDeleteCoursesParams,
  RequestDeleteCoursesParams
>({
  requestMapper: (courses) => ({
    method: 'PATCH',
    url: '/api/course-app/courses/bulk-delete/',
    body: courses,
  }),
})
