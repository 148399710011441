





















import Vue, { PropType } from 'vue'
import Card from '@/ui/card/Card.vue'
import TasksDropdown from '@/pages/preview-tasks/parts/tasks-dropdown/TasksDropdown.vue'
import Icon from '@/ui/icon/Icon.vue'
import {
  $currentQuestion,
  $questionAmount,
  setCurrentIndex,
  prev,
  next,
  setCurrentQuestion,
  selectTaskDestroy,
  setQuestions,
} from '@/pages/preview-tasks/parts/select-task/select-task.model'
import {
  $tasks,
  loadApplicationsTasks,
  loadLessonTasks,
  loadOlympiadTasks,
  loadTestTasks,
  loadExamTasks,
} from '@/pages/preview-tasks/parts/tasks-dropdown/tasks-dropdown.model'
import { FromPage, TaskType } from '@/pages/common/types'

export default Vue.extend({
  name: 'SelectTask',
  components: {
    Card,
    TasksDropdown,
    Icon,
  },
  props: {
    disabled: { type: Boolean as PropType<boolean>, default: false },
    questions: { type: Array as PropType<string[]>, required: true },
    applications: { type: Array as PropType<number[]>, required: false },
    fromPage: { type: String as PropType<FromPage>, required: true },
    taskType: {
      type: String as PropType<TaskType>,
      required: true,
    },
  },
  effector: {
    $currentQuestion,
    $questionAmount,
    $tasks,
  },
  methods: {
    onPrev() {
      prev()
      this.$emit('selectedItemChanged')
    },
    onNext() {
      next()
      this.$emit('selectedItemChanged')
    },
    prev,
    next,
    onSelectTask(val: string) {
      const index = this.$tasks.findIndex((item) => item.name === val)
      if (index !== -1) {
        setCurrentIndex(index)
      }
      this.$emit('selectedItemChanged')
    },
    loadTasks() {
      if (this.fromPage === 'applications') {
        loadApplicationsTasks(this.applications)
      }
      if (this.fromPage === 'labels') loadTestTasks(this.questions)
      if (this.fromPage === 'tags') loadOlympiadTasks(this.questions)
      if (this.fromPage === 'tasks') {
        switch (this.taskType) {
          case 'test-assignment':
            loadTestTasks(this.questions)
            break
          case 'olympiad-assignment':
            loadOlympiadTasks(this.questions)
            break
          case 'lesson-assignment':
            loadLessonTasks(this.questions)
            break
          case 'exam-assignment':
            loadExamTasks(this.questions)
            break
          default:
            loadTestTasks(this.questions)
        }
      }
    },
  },
  beforeDestroy() {
    selectTaskDestroy()
  },
  async mounted() {
    await this.$nextTick()
    const { currentQuestion } = this.$route.query

    if (this.questions.length > 1) {
      setQuestions(this.questions.map((question) => Number(question)))
    }

    if (currentQuestion && typeof +currentQuestion === 'number') {
      setCurrentQuestion(+currentQuestion)
    } else {
      setCurrentQuestion(1)
    }
    this.loadTasks()
    if (this.$currentQuestion !== +currentQuestion) {
      this.$emit('selectedItemChanged')
    }
  },
})
