












import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import {
  taskTypesDropdownModule,
  $taskTypes,
} from '@/pages/common/dropdowns/bank/task-types-dropdown/task-types-dropdown.model'
import { DropdownItem } from '@/pages/common/types'

export default Vue.extend({
  components: {
    FilterDropdown,
  },
  effector: {
    $taskTypes,
    ...taskTypesDropdownModule.store,
  },
  methods: {
    ...taskTypesDropdownModule.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
})
