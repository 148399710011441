













import Vue from 'vue'
import FilterDropdown from '@/pages/common/filter-dropdown/FilterDropdown.vue'
import { DropdownItem } from '@/pages/common/types'
import {
  loadTests,
  testsDropdownModel,
} from '@/pages/common/dropdowns/exams/exams-tests/exams-test-list-dropdown.model'

export default Vue.extend({
  name: 'ExamsTestsListDropdown',
  components: {
    FilterDropdown,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Выберите тест',
    },
    label: {
      type: String,
      default: 'Тест',
    },
  },
  effector: {
    ...testsDropdownModel.store,
  },
  methods: {
    ...testsDropdownModel.methods,
    onSelectItem(item: DropdownItem | null) {
      this.$emit('setItem', item ? item.name : null)
    },
  },
  mounted() {
    if (this.$items.length === 0) loadTests()
  },
})
