import { createApiEffect } from '@/features/api/common/create-api-effect'
import { ListType } from '@/features/api/test/types'

export type LoadType = 'tests' | 'results'

export const getTestStatusListFx = createApiEffect<LoadType, ListType[]>({
  requestMapper: (type) => ({
    method: 'GET',
    url:
      type === 'results'
        ? '/api/results-app/results/test/filter/status/'
        : '/api/test-app/test/filter/statuses/',
  }),
})
