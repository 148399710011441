






























import Vue, { PropType } from 'vue'
import BaseButton from '@/ui/button/BaseButton.vue'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { $editedProctorId } from '@/pages/exams/proctors/create/model/edit-proctors.model'

export default Vue.extend({
  name: 'CreateProctorsFooter',
  components: {
    BaseButton,
  },
  props: {
    disabled: { type: Boolean as PropType<boolean>, default: true },
    page: {
      type: Number,
      default: 1,
    },
  },
  effector: {
    id: $editedProctorId,
  },
  methods: {
    goToList() {
      navigatePush({ name: 'part-time-exams-tests-list' })
    },
    onSaveClick() {
      this.$emit('save')
    },
    onSaveAndBackClick() {
      this.$emit('saveAndBackToList')
    },
  },
})
