





















import Vue from 'vue'
import { TaskLabelNames, TaskTypeNames } from '@/pages/learning/learning-lessons/create/model/types'
import { ExamTestsAssignBlock } from '@/pages/learning/learning-lessons/create/model/assignment-block.model'
import ExamTasksDropdown from '@/pages/exams/full-time-exam-test/create/parts/parts-tabs/exam-tasks-dropdown/ExamTasksDropdown.vue'
import InternalLoader from '@/pages/common/parts/loader/InternalLoader.vue'

export default Vue.extend({
  name: 'ExamAssignmentContent',
  components: {
    InternalLoader,
    ExamTasksDropdown,
  },
  props: {
    blockId: Number,
    value: {
      type: Number,
      default: 0,
    },
    externalValue: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    taskType: TaskTypeNames,
    taskLabel: TaskLabelNames,
    tasksLoading: false,
  }),
  effector: {
    $useTaskScore: ExamTestsAssignBlock.store.$useTaskScore,
    $taskScore: ExamTestsAssignBlock.store.$taskScore,
    $formToGetThemeList: ExamTestsAssignBlock.store.$formToGetThemeList,
    $selectedTask: ExamTestsAssignBlock.store.$selectedTask,
  },
  methods: {
    onToggleTaskType(value: TaskTypeNames) {
      ExamTestsAssignBlock.methods.toggleTaskType({ id: this.blockId, task: value })
    },
    onToggleTaskScore() {
      ExamTestsAssignBlock.methods.toggleTaskScore({ id: this.blockId })
    },
    onSetTaskScore(value: number | string) {
      ExamTestsAssignBlock.methods.setTaskScore({ id: this.blockId, value: String(value) })
    },
    changeTaskScore(value: number) {
      if (!value || value < 1) {
        this.onSetTaskScore(0)
        this.onSetTaskScore(1)
      } else if (value > 32767) {
        this.onSetTaskScore(1)
        this.onSetTaskScore(32767)
      } else {
        this.onSetTaskScore(value)
      }
    },
    clearTaskScore() {
      this.onSetTaskScore('')
    },
    taskLoading(value: boolean) {
      this.tasksLoading = value
    },
    onTaskSelect(id: number) {
      this.$emit('setItem', id)
    },
  },
})
