import { attach, createEvent, restore } from 'effector-root'
import { createFiltersModel } from '@/pages/common/filters/create-filters-model'
import { createPageParamsModel } from '@/pages/common/page-params/create-page-params-model'
import { $exportColumnsQueryParam } from '@/pages/common/parts/header/header-popup/header-popup.model'
import fileDownload from 'js-file-download'
import { exportExamsAnswersListFx } from '@/features/api/part-time-answer/export-part-time-answer'
import { dropdownExamsAnswerModules } from '@/pages/exams/part-time-answers/list/model/dropdown-modules'
import { reset } from '@/pages/common/general-filter/general-filter.model'

export const examsAnswersFilters = createFiltersModel(
  {
    search_area: 'search_all',
    per_page: 25,
  },
  dropdownExamsAnswerModules
)

export const examsAnswersPageParams = createPageParamsModel()

export const resetExamsAnswersFilters = createEvent<void>()

export const setQuestionStartedAt = createEvent<string>()
export const $questionStartedAt = restore(setQuestionStartedAt, '').reset(reset)

export const setQuestionFinishedAt = createEvent<string>()
export const $questionFinishedAt = restore(setQuestionFinishedAt, '').reset(reset)

export const setShowCorrect = createEvent<boolean>()
export const $showCorrect = restore(setShowCorrect, false).reset(reset)

export const setShowNotCorrect = createEvent<boolean>()
export const $showNotCorrect = restore(setShowNotCorrect, false).reset(reset)

export const setHideAuto = createEvent<boolean>()
export const $hideAuto = restore(setHideAuto, false).reset(reset)

export const setShowYesAnswer = createEvent<boolean>()
export const $showYesAnswer = restore(setShowYesAnswer, false).reset(reset)

export const setShowNotAnswer = createEvent<boolean>()
export const $showNotAnswer = restore(setShowNotAnswer, false).reset(reset)

export const exportExamsAnswersList = attach({
  effect: exportExamsAnswersListFx,
  source: [examsAnswersFilters.store.$filterParams, $exportColumnsQueryParam],
  mapParams: (_, [filters, exportedColumns]) => ({ ...filters, ...exportedColumns }),
})

exportExamsAnswersListFx.doneData.watch((res) => fileDownload(res.body, 'examsAnswersList.xlsx'))
