import { createFiltersModel } from '@/pages/common/filters/create-filters-multiselect-model'

export const mapAttemptsPassStatus = [
  {
    name: '0',
    title: 'Назначен',
  },
  {
    name: '1',
    title: 'В процессе',
  },
  {
    name: '2',
    title: 'Завершён',
  },
  {
    name: '3',
    title: 'Пропущен',
  },
]

export const attemptsStatusPassDropdownModule = createFiltersModel()
