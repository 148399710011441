import { createApiEffect } from '@/features/api/common/create-api-effect'
import { NewsList } from '@/features/api/content/types'

export const createNewsFx = createApiEffect<Partial<NewsList>, NewsList>({
  requestMapper: (body) => ({
    method: 'POST',
    url: '/api/content-app/news/',
    body,
  }),
})
